import React from 'react';
import { theme } from '@pfmam/pfmam';
import moment from 'moment';
import { amountFormat, numberFormat } from '../../utils/numberFormat';
import { Box } from '../../styles/globalStyle';
import ListWrapperComponent from './listWrapper';
import _ from 'lodash';
import { percentFormat } from '../../utils/numberFormat';
const DetailsModalContent = ({ selectedRecord }) => {
  const amtKeys = [
    'AMOUNT',
    'VALUE AT MATURITY',
    'INVESTMENT AMOUNT',
    'MARKET VALUE',
    'CHECK AMOUNT',
    'ORIGINAL COST',
    'ACCRUED INTEREST',
    'TOTAL AMOUNT',
    'PAR',
    'PRINCIPAL PROCEEDS',
    'SHARE PRICE',
    'UNREAL GL ON COST',
    'AVG COST',
    'MARKET PRICE PER SHARE',
  ];

  const numberKey = [
    //'COUPON RATE',
    'QUANTITY OF SHARES',
    //'YTM AT COST',
    'SHARES',
    'SHARE AMOUNT',
  ];

  const dateKeys = [
    'DATE',
    'CHECK DATE',
    'REQUESTED DATE',
    'EXPIRATION DATE',
    'SETTLEMENT DATE',
    'TRADE DATE',
    'MATURITY DATE',
    'DATED DATE',
    'REPORT DATE',
  ];
  const ExcludedValues = ['AccountUniqueID', 'id', 'InvestmentTypeClassDesc', 'TransactionSource','EffectiveDuration','YtmAtMkt'];
  const PercentKey = ['YTM AT COST', 'RATE', 'COUPON RATE'];
  return (
    <>
      {/* <Text
        label={title ? title : 'Transaction Amount'}
        color={`${theme.colors.black_tertiary}`}
        fontSize={`${theme.font_size.size_xl}`}
        lineheight="24px"
        padding="0 0 8px 0"
      />
      <Box display="flex" alignitems="center" margin="0 0 32px 0">
        <Text
          label={amountFormat(selectedRecord.Amount)}
          color={`${theme.colors.clean_black}`}
          fontSize="36px"
          lineheight="44px"
        />
      </Box> */}
      {/* <ListWrapperComponent
        label="Confirmation #"
        value={selectedRecord.conformation}
        className="auto-width-text"
      />
      <ListWrapperComponent
        label="Transfer Type"
        value={selectedRecord.transferType}
        className="auto-width-text"
      />
      <ListWrapperComponent
        label="Transfer From"
        value={selectedRecord.transferFrom}
        className="auto-width-text"
      />
      <ListWrapperComponent
        label="Transfer To"
        value={selectedRecord.transferTo}
        className="auto-width-text"
      />
      <ListWrapperComponent
        label="Trade Date"
        value={moment(selectedRecord.date).format('MMM DD, YYYY')}
        className="auto-width-text"
      />
      <ListWrapperComponent
        label="Settle Date"
        value={moment(selectedRecord.date).format('MMM DD, YYYY')}
      /> */}
      {Object.entries(selectedRecord).map(([key, val]) => {
        if (!ExcludedValues.includes(key)) {
          key = _.upperCase(key);
          if (amtKeys.includes(key)) {
            val = amountFormat(val);
          } else if (dateKeys.includes(key)) {
            val = moment(val).format('MMM DD, YYYY');
          } else if (numberKey.includes(key)) {
            val = numberFormat(val);
          } else if (PercentKey.includes(key)) {
            val = (
              <Box
                display="inline-block"
                boxcolor={`${theme.colors.green_light}`}
                padding="2px 8px"
                radius="2px"
              >
                {percentFormat(val)}
              </Box>
            );
          }
          return (
            <ListWrapperComponent
              // label={key}
              label={key === 'S P RATING' ? 'S&P RATING' : key}
              value={val}
              key={key}
              className="auto-width-text"
            />
          );
        }
      })}
    </>
  );
};

export default DetailsModalContent;
