
import React from 'react';
import NoDataFound from '../../common/components/noDataFound';
import { Container } from '../../styles/globalStyle';
import styled from 'styled-components';
import {
  ButtonAnt,
  theme,
  Text,
  TableComponent,
} from '@pfmam/pfmam';

const TableWrapper = styled.div`
  .ant-table {
    border-radius: 4px 4px 0 0;
    .ant-table-tbody {
      & > tr {
        &:hover {
          &.ant-table-row:not(.ant-table-expanded-row) {
            background: linear-gradient(
                0deg,
                ${(props) => props.rgbaPrimaryHover},
                ${(props) => props.rgbaPrimaryHover}
              ),
              ${theme.colors.white};
          }
        }
        cursor: pointer;
        & > td {
          vertical-align: top;
          padding: 7px 20px;
          &.ant-table-column-sort {
            background: ${theme.colors.transparent};
          }
          &.ant-table-row-expand-icon-cell {
            padding: 0;
            & > span {
              display: none;
            }
          }
        }
        &:last-child {
          td {
            border-bottom-color: ${theme.colors.transparent};
          }
        }
        &.inactive-accounts {
          & > td {
            background-color: ${theme.colors.grey_faint};
          }
        }
      }
    }
    .ant-table-thead {
      & > tr {
        & > th {
          background: ${theme.colors.light_grey};
          font-family: 'Inter';
          padding-left: 10px;
          font-weight: normal;
          line-height: 20px;
          &:not(:last-child) {
            &:not(.ant-table-selection-column) {
              &:not(.ant-table-row-expand-icon-cell) {
                &:not([colspan]) {
                  &::before {
                    background-color: ${theme.colors.transparent};
                  }
                }
              }
            }
          }
        }
      }
    }
    .ant-table-thead {
      & > tr {
        & > th:first-child {
          .ant-table-column-title {
            flex: 0 0 auto;
          }
        }
      }
    }
    &.ant-table.ant-table-middle {
      .ant-table-thead {
        .ant-table-column-sorters {
          padding: 0;
          .ant-table-column-sorter-inner .anticon {
            color: ${theme.colors.grey_stone};
          }
          .ant-table-column-sorter-up.active,
          .ant-table-column-sorter-down.active {
            color: ${theme.colors.black_sorted};
          }
        }
        .ant-table-column-sorter-inner {
          margin-top: 0;
        }
      }
    }
    .sort-space-none {
      .ant-table-column-sorters {
        justify-content: flex-start;
      }
     
    }
  }
  .ant-table-title {
    padding: 0 !important;
  }
  .table-primary {
    &:first-child {
      margin-bottom: 24px;
    }
  }
  .ant-table {
    &.ant-table-middle {
      .ant-table-tbody {
        .ant-table-wrapper {
          &:only-child {
            .ant-table {
              margin: -19px -32px;
            }
          }
        }
        .ant-table-container {
          .ant-table-tbody {
            .ant-table-row {
              .ant-table-cell {
                padding: 14px 32px;
                background-color: ${theme.colors.grey_faint};
              }
              &:hover {
                .ant-table-cell {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const TransactionInstructionTable = ({
  columns,
  handleAction,
  data,
  noRecordsText
}) => {
  return (
    <>
      {/* <Container> */}
        <TableWrapper rgbaPrimaryHover={`${theme.colors.light_grey}`}>
          <TableComponent
            className="table-primary"
            columns={columns}
            dataSource={data}
            locale={{
              emptyText: (
                <NoDataFound
                  text={noRecordsText}
                  padding="12px 0"
                />
              ),
            }}
            showSorterTooltip={false}
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                handleAction(record);
              },
            })}
          />
        </TableWrapper>
      {/* </Container> */}
    </>
  );
}

export default TransactionInstructionTable;