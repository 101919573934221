import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import {
  theme,
  Text,
  ButtonAnt,
  InputComponent,
  Modal,
} from '@pfmam/pfmam';
import {
  GetWebUserPassword,
  UpdateAuthenticationAttempt,
  SubmitACHRedemption,
  SubmitWireRedemption,
  GetTransactionDefaultDay,
  IsDuplicateTransactionSingleAccount
} from '../../../actions/actions';
import { Space, Form } from 'antd';
import { Box } from '../../../styles/globalStyle';
import ListWrapperComponent from '../../../common/components/listWrapper';
import moment from 'moment';
import { amountFormat } from '../../../utils/numberFormat';
import { CheckCircleOutlined, PrinterOutlined } from '@ant-design/icons';
import { Prompt } from 'react-router';
import { useHistory } from 'react-router-dom';
import DuplicateTransactionModalContent from '../../common/duplicateTransactionModalContent';

const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
  }
`;
const ButtonWrapper = styled(Space) `
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
  &.done-button-wrapper {
    .primary {
      width: 120px;
    }
  }
`;
const SendWireButton = styled(ButtonAnt) `
  font-size: ${theme.font_size.size_xl};
  margin: 0 0 32px;
  color: ${(props) => props.color};
  padding: 0;
  height: auto;
  line-height: 24px;
  border: 0;
  &:hover {
    color: ${(props) => props.color};
  }
  ${(props) =>
    props.spacing &&
    css`
      padding: 0 0 0 54px;
    `}
  ${(props) =>
    props.spacing &&
    css`
      margin: 0;
    `}
`;
const bcrypt = require('bcryptjs');
const ReviewForm = ({
  form,
  formData,
  amount,
  selected,
  setSelected,
  selectedPage,
  setFormType,
  defaultDate,
  setDefaultDate
}) => {
  const history = useHistory();
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const [submitted, setSubmitted] = useState(false);
  const [confirmationID, setConfirmationID] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [cutoffTimeDetails, setCutOffTimeDetails] = useState({
    isCutOffTimePassed: false,
    curOffTime: ''
  });
  const [multiEntryConfirmationNumbers, setMultiEntryConfirmationNumbers] = useState([]);
  const [isDuplicateTrans, setIsDuplicateTrans] = useState();
  const [isDuplicateTransModalVisible, setIsDuplicateTransModalVisible] = useState(false);
  const [duplicateTransData, setDuplicateTransData] = useState([]);

  const duplicateTransColumns = [
    {
      title: 'Confirmation #',
      dataIndex: 'Confirm #',
      key: 'Confirm #',
      width: 180,
      align: 'left',
    },
    {
      title: 'Account Number',
      dataIndex: 'Account Number',
      key: 'Account Number',
      width: 210,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={text}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    },
    {
      title: 'Posting Date',
      dataIndex: 'Posting Date',
      key: 'Posting Date',
      width: 212,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={moment(text).format('MMM DD, YYYY')}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'Created Date',
      key: 'Created Date',
      width: 212,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={moment(text).format('MMM DD, YYYY h:mm A')}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
          <Text
            label={record['Created By']}
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_xl}`}
            margin="4px 0 0"
            lineheight="20px"
          />
        </>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      width: 148,
      align: 'right',
      render: (text) => <>{amountFormat(text)}</>,
    },
  ];

  if (selectedPage === 'MultiRedemptionForm') {
    setSelected('MultiRedemptionForm');
  }

  const ValidateDuplicateTransaction = () =>{
    // For single amount ACH redemption
    if (!submitted && selected === 'single' && formData.hasOwnProperty('date') && selectedPage !== 'WireRedemptionForm') {
      IsDuplicateTransactionSingleAccount(formData.date.format('YYYY-MM-DD'), formData.accountinvestmentType.AccountUniqueID, amount, 2)  // 2 is ACH redemption
        .then((response) => {
          if (response.data !== null && response.data.Table.length > 0) {
            setDuplicateTransData(response.data.Table);
            setIsDuplicateTransModalVisible(true);
            setIsDuplicateTrans(true);
          }
          else {
            VerifyCutOffTime();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // For Wire redemption
    if (!submitted && selected === 'single' && formData.hasOwnProperty('date') && selectedPage === 'WireRedemptionForm') {
      IsDuplicateTransactionSingleAccount(formData.date.format('YYYY-MM-DD'), formData.accountinvestmentType.AccountUniqueID, amount, 4)  // 4 is Wire redemption
        .then((response) => {
          if (response.data !== null && response.data.Table.length > 0) {
            setDuplicateTransData(response.data.Table);
            setIsDuplicateTransModalVisible(true);
            setIsDuplicateTrans(true);
          }
          else {
            VerifyCutOffTime();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // For Muiltiple amount ACH redemption
    if (!submitted && selected === 'multiple' && formData.hasOwnProperty('multiDate')) {
      var dup = false;
      var dupList = [];
      var duplicateIncrement = 0;
      formData.multiDate.map((obj, index) => {
        IsDuplicateTransactionSingleAccount(obj.date.format('YYYY-MM-DD'), formData.accountinvestmentType.AccountUniqueID, obj.amount, 2)  // 2 is ACH redemption
          .then((response) => {
            duplicateIncrement++;
            if (response.data !== null && response.data.Table.length > 0) {
              dupList = dupList.concat(response.data.Table);
              setDuplicateTransData(dupList);
              dup = true;
            }
            if (duplicateIncrement === formData.multiDate.length) {
              if(dup){
                setIsDuplicateTransModalVisible(dup);
                setIsDuplicateTrans(dup);
              } else {
                VerifyCutOffTime();
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
        });
    }
    // For Multiple Account and Amount ACH redemption
    if (!submitted && selected === 'MultiRedemptionForm' && formData.hasOwnProperty('multiAccount')) {
      var dup = false;
      var dupList = [];
      var duplicateIncrement = 0;

      formData.multiAccount.map((obj, index) => {
        IsDuplicateTransactionSingleAccount(formData.date.format('YYYY-MM-DD'), formData.accountinvestmentType.AccountUniqueID, obj.amount, 2)  // 2 is ACH redemption
          .then((response) => {
            duplicateIncrement++;
            if (response.data !== null && response.data.Table.length > 0) {
              dupList = dupList.concat(response.data.Table);
              setDuplicateTransData(dupList);
              dup = true;
            }
            if (duplicateIncrement === formData.multiAccount.length) {
              if(dup){
                setIsDuplicateTransModalVisible(dup);
                setIsDuplicateTrans(dup);
              } else {
                VerifyCutOffTime();
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
    }
  }

  const handleDuplicateModalSubmit = () => {
    setIsDuplicateTransModalVisible(false);
    VerifyCutOffTime();
  };

  const handleDuplicateModalCancel = () => {
    setIsDuplicateTransModalVisible(false);
    setDisableSubmit(false);
  };

  function onDuplicateModalCancel() {
    setIsDuplicateTransModalVisible(false);
    setDisableSubmit(false);
  }

  const submitRedemption = () => {
    var x = formData;
    var confirmIDList = [];
    // For single amount ACH redemption
    if (!submitted && selected === 'single' && formData.hasOwnProperty('date') && selectedPage !== 'WireRedemptionForm') {
      SubmitACHRedemption(formData.date.format('YYYY-MM-DD'), amount, formData.instructionID, formData.accountinvestmentType.type.FundUniqueID, formData.accountinvestmentType.AccountUniqueID) // ACH Redemptions
        .then((response) => {
          if (response.data !== null && response.data.length > 0) {
            confirmIDList = [...confirmationID];
            confirmIDList.push(response.data);
            setConfirmationID(confirmIDList);
            setSubmitted(true);
            setDisableSubmit(false);
          }
        })
        .catch((error) => {
          setDisableSubmit(false);
          console.log(error);
        });
    }
    // For Wire redemption
    if (!submitted && selected === 'single' && formData.hasOwnProperty('date') && selectedPage === 'WireRedemptionForm') {
      SubmitWireRedemption(formData.date.format('YYYY-MM-DD'), amount, formData.instructionID, formData.accountinvestmentType.type.FundUniqueID, formData.accountinvestmentType.AccountUniqueID) // Wire Redemptions
        .then((response) => {
          if (response.data !== null && response.data.length > 0) {
            setConfirmationID([response.data]);
            setSubmitted(true);
            setDisableSubmit(false);
          }
        })
        .catch((error) => {
          setDisableSubmit(false);
          console.log(error);
        });
    }

    // For Muiltiple amount ACH redemption
    if (!submitted && selected === 'multiple' && formData.hasOwnProperty('multiDate')) {
      let multiEntryConfirs = [];
      formData.multiDate.map((obj, index) => {
        SubmitACHRedemption(obj.date.format('YYYY-MM-DD'), obj.amount, formData.instructionID, formData.accountinvestmentType.type.FundUniqueID, formData.accountinvestmentType.AccountUniqueID) // 3 Indicates Wire notification
          .then((response) => {
            if (response.data !== null && response.data.length > 0) {
              multiEntryConfirs.push({ confirmationID: response.data, date: obj.date, amount: obj.amount });
              if (formData.multiDate.length === multiEntryConfirs.length) {
                setMultiEntryConfirmationNumbers(multiEntryConfirs.sort((a, b) => (a.confirmationID > b.confirmationID ? 1 : -1)));
                setSubmitted(true);
                setDisableSubmit(false);
              }
              //setConfirmationID(prevArray => [...prevArray, response.data]);
              //setDisableSubmit(false);
            }
          })
          .catch((error) => {
            setDisableSubmit(false);
            console.log(error);
          });

      })
    }
    // For Multiple Account and Amount ACH redemption
    if (!submitted && selected === 'MultiRedemptionForm' && formData.hasOwnProperty('multiAccount')) {
      let multiAccountConfirms = [];
      formData.multiAccount.map((obj, index) => {
        SubmitACHRedemption(formData.date.format('YYYY-MM-DD'), obj.amount, obj.multiBankAccount.ACHInstructionID, formData.accountinvestmentType.type.FundUniqueID, formData.accountinvestmentType.AccountUniqueID) // 3 Indicates Wire notification
          .then((response) => {
            if (response.data !== null && response.data.length > 0) {
              multiAccountConfirms.push({ confirmationID: response.data, DisplayName: obj.multiBankAccount.DisplayName, amount: obj.amount, AddendumRedemptionDescription: obj.multiBankAccount.AddendumRedemptionDescription, NickName: obj.multiBankAccount.NickName });
              if (formData.multiAccount.length === multiAccountConfirms.length) {
                setMultiEntryConfirmationNumbers(multiAccountConfirms.sort((a, b) => (a.confirmationID > b.confirmationID ? 1 : -1)));
                setSubmitted(true);
                setDisableSubmit(false);
              }
              // setConfirmationID(prevArray => [...prevArray, response.data]);
              // setDisableSubmit(false);
            }
          })
          .catch((error) => {
            setDisableSubmit(false);
            console.log(error);
          });

      })
    }

    //setSubmitted(true);
  };

  const  VerifyCutOffTime= ()=> {
    setCutOffTimeDetails({ isCutOffTimePassed: false, curOffTime: '' });
    var PendingTransactionTypeClassID = selectedPage === 'WireRedemptionForm'? 4:2
    GetTransactionDefaultDay(formData.accountinvestmentType.type.FundUniqueID, PendingTransactionTypeClassID)
      .then((response) => {
        if (response.data !== null) {
          let invalidTransactions = [];
          let defaultDay = new Date(response.data);
          defaultDay.setHours(0, 0, 0, 0);

          if (formData.hasOwnProperty('multiDate')) {
              let multiEntryConfirmsLength = 0;
              formData.multiDate.map((obj) => {
                multiEntryConfirmsLength = multiEntryConfirmsLength + 1;
                let transEntryDateString = obj.date.format('MMM DD, YYYY');
                let transEntryDate = new Date(transEntryDateString);
                transEntryDate.setHours(0, 0, 0, 0);
                if (transEntryDate < defaultDay) {
                  invalidTransactions.push(false);
                  setCutOffTimeDetails({ isCutOffTimePassed: true, curOffTime: response.data });
                  setDisableSubmit(false);
                }
                if (multiEntryConfirmsLength === formData.multiDate.length && invalidTransactions.length === 0) {
                  submitRedemption();
                }
              });
            
          } else {
            let transEntryDateString = formData.date.format('MMM DD, YYYY');
            let transEntryDate = new Date(transEntryDateString);
            transEntryDate.setHours(0, 0, 0, 0);
            if (transEntryDate < defaultDay) {
              setCutOffTimeDetails({ isCutOffTimePassed: true, curOffTime: response.data });
              setDisableSubmit(false);
            } else {
              submitRedemption();
            }
          }
        }
      })
      .catch((error) => {
        setDisableSubmit(false);
        console.log(error);
      });
  }

  return (
    <>
    <Prompt
      when={submitted === false}
      message={JSON.stringify({
        header: 'Discard Transaction?',
        content:
          'Leaving this page will discard this transaction. Are you sure you wantto discard this transaction?',
      })}
    />
    <Box display="flex" alignitems="flex-start">
      {submitted && (
        <CheckCircleOutlined
          style={{
            color: `${theme.colors.pie_green}`,
            fontSize: '38px',
            marginTop: '6px',
          }}
        />
      )}
      <Text
        fontSize="36px"
        color={`${theme.colors.black_secondary}`}
        lineheight="44px"
        label={
          submitted
            ? selectedPage === 'WireRedemptionForm'
              ? 'Wire Redemption Submitted'
              : 'ACH Redemption Submitted'
            : selectedPage === 'WireRedemptionForm'
              ? 'Review Redemption'
              : 'Review ACH Redemption'
        }
        icon={false}
        margin={submitted ? '0 0 32px 15px' : '0 0 32px'}
      />
    </Box>

    <Box
      boxcolor={`${theme.colors.white}`}
      padding="32px 32px 48px"
      radius="4px"
    >
      <Box
        display="flex"
        justifycontent="space-between"
        alignitems="flex-start"
        width="100%"
      >
        <Box flex="1" margin="0 0 8px">
          <Text
            label={
              selectedPage === 'WireRedemptionForm'
                ? 'Wire Redemption Amount'
                : 'Total ACH Redemption Amount'
            }
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
        {submitted && (
          <SendWireButton
            type="link"
            label="Print Confirmation"
            icon={<PrinterOutlined />}
            color={`${theme.colors.blue_primay}`}
            onClick={() => window.print()}
            spacingnone
          />
        )}
      </Box>
      <Text
        label={amountFormat(amount)}
        color={`${theme.colors.black_secondary}`}
        fontSize="36px"
        lineheight="44px"
        margin="0 0 32px"
      />
      <Box>
        {selected === 'single' && submitted && (
          <ListWrapperComponent label="Confirmation #" value={confirmationID[0]} />
        )}
        <ListWrapperComponent
          label="Transaction Type"
          value={selectedPage === 'WireRedemptionForm'
            ? "Wire Redemption"
            : 'ACH Redemption'}
        />
        <ListWrapperComponent
          label={
            selectedPage === 'WireRedemptionForm'
              ? 'Product'
              : 'Account/Investment Type'
          }
          value={
            <>
            <Text
              label={formData.accountinvestmentType.AccName}
              color={`${theme.colors.black_secondary}`}
              fontSize={`${theme.font_size.size_xl}`}
              lineheight="24px"
            />
            <Text
              label={formData.accountinvestmentType.type.FundShortName}
              color={`${theme.colors.grey_tertiary}`}
              fontSize={`${theme.font_size.size_xl}`}
              margin="4px 0 0"
              lineheight="20px"
            />
            </>
          }
        />
        {selected === 'MultiRedemptionForm' ? (
          <Box>
            <ListWrapperComponent
              label="Date"
              value={moment(formData.date).format('MMM DD, YYYY')}
            />
          </Box>
        ) : (
            <>
            <ListWrapperComponent
              label={
                selectedPage === 'WireRedemptionForm'
                  ? 'Wire Account'
                  : 'Bank Account'
              }
              value={
                selectedPage === 'WireRedemptionForm' ? (
                  <>
                  <Text
                    label={formData.bankAccount.BankName}
                    color={`${theme.colors.black_secondary}`}
                    fontSize={`${theme.font_size.size_xl}`}
                    lineheight="24px"
                  />
                  <Text
                    label={formData.bankAccount.BankAccountNumber.trim().length > 4? '*' + formData.bankAccount.BankAccountNumber.trim().substring(formData.bankAccount.BankAccountNumber.trim().length - 4) : '*' + formData.bankAccount.BankAccountNumber.trim() }
                    color={`${theme.colors.grey_tertiary}`}
                    fontSize={`${theme.font_size.size_xl}`}
                    margin="4px 0 0"
                    lineheight="20px"
                  />
                  </>
                ) : (
                    formData.bankAccount
                  )
              }
            />
            {selectedPage !== 'WireRedemptionForm' && (
            <>
              <ListWrapperComponent
              label="Nick Name"
              value={formData.NickName}
            />
              <ListWrapperComponent
              label="Addendum Desc"
              value={formData.addendumRedemptionDescription}
            />
            </>
            )}
            {selectedPage == 'WireRedemptionForm' && (
              <ListWrapperComponent
              label="Nick Name"
              value={formData.bankAccount.NickName}
            />
            )}
            </>
          )}

        {selected === 'single' ? (
          <>
          <ListWrapperComponent
            label="Date Scheduled"
            value={moment(formData.date).format('MMM DD, YYYY')}
          />
          </>
        ) : selected === 'multiple' ? (
          <Box padding="34px 0 0">
            {submitted && (
              multiEntryConfirmationNumbers.map((obj) => {
              return (
                <Box
                  boxcolor={`${theme.colors.grey_faint}`}
                  padding="8px 16px"
                  radius="4px"
                  margin="16px 0 0"
                >
                  {submitted && (
                    <ListWrapperComponent
                      label="Confirmation #"
                      value={obj.confirmationID}
                    />
                  )}
                  <ListWrapperComponent
                    label="Date"
                    value={moment(obj.date).format('MMM DD, YYYY')}
                  />
                  <ListWrapperComponent
                    label="Amount"
                    value={amountFormat(obj.amount, null)}
                  />
                </Box>
              );
            }))}
            {!submitted && (
              formData.multiDate.map((obj, index) => {
                return (
                  <Box
                    boxcolor={`${theme.colors.grey_faint}`}
                    padding="8px 16px"
                    radius="4px"
                    margin="16px 0 0"
                  >
                    {submitted && (
                      <ListWrapperComponent
                        label="Confirmation #"
                        value={confirmationID[index]}
                      />
                    )}
                    <ListWrapperComponent
                      label="Date"
                      value={moment(obj.date).format('MMM DD, YYYY')}
                    />
                    <ListWrapperComponent
                      label="Amount"
                      value={amountFormat(obj.amount, null)}
                    />
                  </Box>
                );
              })
            )
            }
          </Box>
        ) : (
              <Box padding="16px 0 0 0">
                {submitted && (
                  multiEntryConfirmationNumbers.map((obj) => {
                  return (
                    <Box
                      boxcolor={`${theme.colors.grey_faint}`}
                      margin="16px 0 0"
                      padding="8px 16px"
                      radius="4px"
                    >
                      {submitted && (
                        <ListWrapperComponent
                          label="Confirmation #"
                          value={obj.confirmationID}
                        />
                      )}
                      <ListWrapperComponent
                        label="Bank Account"
                        value={obj.DisplayName}
                      />
                      <ListWrapperComponent
                        label="Nick Name"
                        value={obj.NickName}
                      />
                      <ListWrapperComponent
                        label="Amount"
                        value={amountFormat(obj.amount, null)}
                      />
                      <ListWrapperComponent
                        label="Addendum Desc"
                        value={obj.AddendumRedemptionDescription}
                      />
                    </Box>
                  );
                }))}
                {!submitted && (
                  formData.multiAccount.map((obj, index) => {
                    return (
                      <Box
                        boxcolor={`${theme.colors.grey_faint}`}
                        margin="16px 0 0"
                        padding="8px 16px"
                        radius="4px"
                      >
                        {submitted && (
                          <ListWrapperComponent
                            label="Confirmation #"
                            value={confirmationID[index]}
                          />
                        )}
                        <ListWrapperComponent
                          label="Bank Account"
                          value={obj.multiBankAccount.DisplayName}
                        />
                        <ListWrapperComponent
                          label="Nick Name"
                          value={obj.multiBankAccount.NickName}
                        />
                        <ListWrapperComponent
                          label="Amount"
                          value={amountFormat(obj.amount, null)}
                        />
                        <ListWrapperComponent
                          label="Addendum Desc"
                          value={obj.multiBankAccount.AddendumRedemptionDescription}
                        />
                      </Box>
                    );
                  })
                )
                }
              </Box>
            )}
        {!submitted ? (
          <Form
            onFinish={ValidateDuplicateTransaction}
            validateTrigger="onFinish"
            className="forms"
          >
            <Text
              label="Password"
              padding="32px 0 8px 0"
              color={`${theme.colors.black_secondary}`}
              fontSize={`${theme.font_size.size_l}`}
              lineheight="20px"
            />
            <Form.Item
              name="password"
              rules={[
               // { required: true, message: 'Please input your password!' },
                {
                  validator: (rule, value, callback) => {
                    setDisableSubmit(true);
                    //Get the users hashed password from DB to compare with the input password
                    if (value !== "" && value !== undefined) {
                      GetWebUserPassword()
                        .then((response) => {
                          bcrypt.compare(value, response.data, (err, res) => {
                            if (res) {
                              Promise.resolve();
                              ValidateDuplicateTransaction();
                              return;
                            }
                            else {
                              //Update the SerialFailedAuthenticationAttemptCount
                              UpdateAuthenticationAttempt(true);
                              callback('Please enter correct Password.');
                              setDisableSubmit(false);
                              return;
                            }
                          });
                        })
                        .catch((error) => {
                          setDisableSubmit(false);
                          callback('Server Error. Please try after sometime');
                          return;
                        });
                    }
                    else{
                      callback('Please input your password!');
                      setDisableSubmit(false);
                    }
                  },
                },
              ]}
            >
              <InputComponent
                allowClear
                onChange={() => { }}
                placeholder="Enter Password"
                type="password"
                autoFocus
              />
            </Form.Item>
            <Form.Item>
              <ButtonWrapper size={12} spacing>
                <ButtonAnt
                  type="secondary"
                  label="Back"
                  onClick={() => {
                    setDefaultDate(defaultDate)
                    setFormType('redeem')
                  }
                   }
                />
                <OverrideButtonAnt linkTextColor={primary_color} type="primary" label="Submit" htmlType="submit" disabled={disableSubmit} />
              </ButtonWrapper>
            </Form.Item>
          </Form>
        ) : null}
      </Box>
      {cutoffTimeDetails.isCutOffTimePassed && (
          <Box display="flex" alignitems="flex-start">
            <Text
              fontSize="16px"
              color={`${theme.colors.danger_red}`}
              lineheight="18px"
              label={'The cutoff time for the date of this transaction has passed. Please enter a date on or after ' + moment(cutoffTimeDetails.curOffTime).format('MMM DD, YYYY')}
              icon={false}
              margin={'0 0 0 0'}
            />
          </Box>
        )

        }

        {isDuplicateTransModalVisible && (
        <Modal
          title="Possible Duplicate Transaction"
          width={750}
          bodyStyle={{
            padding: '32px 0 40px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          footer={null}
          visible={isDuplicateTransModalVisible}
          onCancel={onDuplicateModalCancel}
          content={
            <DuplicateTransactionModalContent
              columns={duplicateTransColumns}
              data={duplicateTransData}
              handleDuplicateModalCancel={handleDuplicateModalCancel}
              handleDuplicateModalSubmit={handleDuplicateModalSubmit}
            />
          }
          centered={true}
        />
      )}

      {submitted && (
        <ButtonWrapper size={12} spacing className="done-button-wrapper">
          <ButtonAnt
            label="Make Another Transaction"
            type="secondary"
            htmlType="button"
            onClick={() => {
              form.resetFields();
              sessionStorage.setItem('showMoveFundsModal', true);
              window.location.reload();
            }}
          />
         <OverrideButtonAnt 
            linkTextColor={primary_color} 
            label="Done"
            type="primary"
            htmlType="submit"
            onClick={() => {
              //window.history.back();
              history.push('/dashboard');
            }}
            widthmd
          />
        </ButtonWrapper>
      )}
    </Box> 
    </>
  );
};

export default ReviewForm;
