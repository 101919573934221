import React, { useEffect } from 'react';
import { Form, DatePicker } from 'antd';
import { InputNumberComponent } from '@pfmam/pfmam';
import moment from 'moment';
import { validateAmount } from '../../utils/common';
import { amountFormat } from '../../utils/numberFormat';
import {
  disabledDate,
  numberFormat,
  validateCheckAmount,
} from './commonFunctions';
import { toLower } from 'lodash';
const SingleDate = ({
  vals,
  setFormTotal,
  amountLabel = 'Amount',
  dateLabel = 'Transaction Date',
  availableBal,
  formTotal,
  disabledDate,
}) => {
  useEffect(() => {
    let data = vals.amount;
    if (data) {
      setFormTotal(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vals]);

  return (
    <>
      <Form.Item
        label={amountLabel}
        name="amount"
        rules={[
          {
            validator: (_, value) =>
              value && validateAmount(value)
                ? validateCheckAmount(
                    value,
                    formTotal,
                    availableBal,
                    amountLabel
                  )
                : value
                ? Promise.reject(`Please enter a valid ${toLower(amountLabel)}`)
                : Promise.reject(`${amountLabel} Required`),
          },
        ]}
      >
        <InputNumberComponent
          placeholder="$ Enter Amount"
          showHandleButton={false}
          defaultValue={null}
          formatter={(value, info) => {
            if(!info.userTyping)
            {
              let val = value !== '' ? numberFormat(value) : null;
              if(val != null){
                if (String(value).includes('.')) {
                  let length = String(value).split('.')[1].length;
                  if(length > 2){
                    return "$" + val;
                  }
                }
                val = amountFormat(val.toString().replace(/,/g,''));
              }
              return val;
            } else {
              return value !== '' ? '$' + numberFormat(value) : null;
            }
          }}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        />
      </Form.Item>

      <Form.Item
        label={dateLabel}
        name="date"
        rules={[
          {
            type: 'object',
            required: true,
            message: `${dateLabel} Required`,
          },
        ]}
      >
        <DatePicker
         format="MMM DD, YYYY"
         allowClear={false}
         className="suffix-left"
         dropdownClassName="single-date-picker"
         defaultValue={moment()}
         disabledDate={disabledDate}
        />
      </Form.Item>
    </>
  );
};

export default SingleDate;
