/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Space } from 'antd';
import { theme } from '@pfmam/pfmam';
import { Box, Container } from '../../styles/globalStyle';
import moment from 'moment';
import statements from '../../data/statements.json';
import MultiSelect from '../../common/components/multiSelect';
import RenderDatePicker from '../../common/components/datePicker';
import CheckboxImg from '../../assets/images/checkbox-blue.svg';
import CheckboxCheckedImg from '../../assets/images/checkbox-blue-checked.svg';
import ConsolidatedStatementsTable from './statements/consolidatedStatements';
import AccountStatementsTable from './statements/accountStatements';
import { GetMonthlyStatements } from '../../actions/actions';
import { useSelector, useDispatch } from 'react-redux';
import {
  storeFilteredRecipients,
  storeFilteredAccounts,
} from '../../services/monthlyStatementsSlice';
const Wrapper = styled.div`
  padding-top: 22px;
`;
const StatementsComponent = ({ recipientType }) => {
  const dispatch = useDispatch();
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const checkedRecipients = useSelector(
    (state) => state.monthlyStatementsConfirmsSlice.filteredRecipients
  );

  const checkedAccounts = useSelector(
    (state) => state.monthlyStatementsConfirmsSlice.filteredAccounts
  );
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);

  const [accountSelectedValues, setAccountSelectedValues] = useState([]);
  const [accountFilterClass, setAccountFilterClass] = useState('hide');
  const [recipientSelectedValues, setRecipientSelectedValues] = useState([]);
  const [recipientFilterClass, setRecipientFilterClass] = useState('hide');
  //DATE PICKER
  const [dates, setDates] = useState(null);
  const [dateValues, setDateValues] = useState([moment(), moment()]);
  const [dateOption, setDateOption] = useState('last3months');
  const [dateLabel, setDateLabel] = useState('Last 3 Months');
  const [dateOpen, setDateOpen] = useState(false);
  const programId = clientDetails.payload[0].programid;
  const ssnorTIN = clientDetails.payload[0].ssnortin;
  const [statementsData, setStatementsData] = useState({
    consolidatedStatements: [],
    accountStatements: [],
  });
  const [filteredStatementsData, setFilteredStatementsData] = useState({
    consolidatedStatements: [],
    accountStatements: [],
  });

  useEffect(() => {
    return function cleanup() {
      dispatch(storeFilteredRecipients([]));
      dispatch(storeFilteredAccounts([]));
    };
  }, []);

  useEffect(() => {
    GetMonthlyStatements(programId, ssnorTIN)
      .then((response) => {
        if (response.data != null) {
          setStatementsData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    applyFiltersToData();
  }, [checkedRecipients, checkedAccounts, statementsData]);

  function applyFiltersToData() {
    let FilteredAccountStatements = [];
    let FilteredConsolidatedStatements = [];
    let FilteredStatements = {
      consolidatedStatements: [],
      accountStatements: [],
    };

    if (checkedAccounts.length > 0 || checkedRecipients.length > 0) {
      if (checkedAccounts.length > 0) {
        //Account filter works only on account statements as per requirement.
        //First filter the month/s which has selected account in it.
        FilteredAccountStatements = statementsData.accountStatements.filter(
          function (accountStatements) {
            var returnVal = false;
            accountStatements.accounts.map((account) => {
              if (checkedAccounts.includes(account.accName)) {
                returnVal = true;
              }
            });
            return returnVal;
          }
        );
        //On the resultant now keep only the accounts that are selected
        let resultantAccountStatements = [];
        FilteredAccountStatements.map((stmnt) => {
          let resAccArray = stmnt.accounts.filter((f) =>
            checkedAccounts.includes(f.accName)
          );
          resultantAccountStatements.push({
            key: stmnt.key,
            date: stmnt.date,
            accounts: resAccArray,
          });
        });
        FilteredAccountStatements = resultantAccountStatements;
        FilteredStatements.accountStatements = FilteredAccountStatements;
      }
      if (checkedRecipients.length > 0) {
        //Recipient filter works only on consolidated statements as per requirement.
        //First filter the month/s which has selected recipient in it.
        FilteredConsolidatedStatements =
          statementsData.consolidatedStatements.filter(function (
            consolidatedStatements
          ) {
            var returnVal = false;
            consolidatedStatements.recipients.map((recipient) => {
              if (checkedRecipients.includes(recipient.recipientName)) {
                returnVal = true;
              }
            });
            return returnVal;
          });
        //On the resultant, keep only the recipient that are selected
        let resultantConsolidatedStatements = [];
        FilteredConsolidatedStatements.map((stmnt) => {
          let resAccArray = stmnt.recipients.filter((f) =>
            checkedRecipients.includes(f.recipientName)
          );
          resultantConsolidatedStatements.push({
            key: stmnt.key,
            date: stmnt.date,
            recipients: resAccArray,
          });
        });
        FilteredConsolidatedStatements = resultantConsolidatedStatements;
        FilteredStatements.consolidatedStatements =
          FilteredConsolidatedStatements;
      }

      if (checkedAccounts.length === 0) {
        FilteredStatements.accountStatements = statementsData.accountStatements;
      }
      if (checkedRecipients.length === 0) {
        FilteredStatements.consolidatedStatements =
          statementsData.consolidatedStatements;
      }
    } else {
      FilteredStatements.accountStatements = statementsData.accountStatements;
      FilteredStatements.consolidatedStatements =
        statementsData.consolidatedStatements;
    }

    setFilteredStatementsData(FilteredStatements);
  }

  const handleAccountChange = (name) => {
    if (accountSelectedValues.includes(name)) {
      const values = accountSelectedValues.filter(
        (selectedValue) => selectedValue !== name
      );
      setAccountSelectedValues([...values]);
      dispatch(storeFilteredAccounts([...values]));
    } else {
      setAccountSelectedValues([...accountSelectedValues, name]);
      dispatch(storeFilteredAccounts([...accountSelectedValues, name]));
    }
  };

  const handleRecipientChange = (name) => {
    if (recipientSelectedValues.includes(name)) {
      const values = recipientSelectedValues.filter(
        (selectedValue) => selectedValue !== name
      );
      setRecipientSelectedValues([...values]);
      dispatch(storeFilteredRecipients([...values]));
    } else {
      setRecipientSelectedValues([...recipientSelectedValues, name]);
      dispatch(storeFilteredRecipients([...recipientSelectedValues, name]));
    }
  };

  const getUniqueAccounts = (data) => {
    const uniqueAccountsList = [];
    data.accountStatements.map((statements) => {
      statements.accounts.map((account) => {
        if (!uniqueAccountsList.some((item) => item.name === account.accName)) {
          uniqueAccountsList.push({
            name: account.accName,
          });
        }
      });
    });
    return uniqueAccountsList.sort((a, b) => (a.name > b.name ? 1 : -1));
  };

  const getUniqueRecipients = (data) => {
    const uniqueRecipientList = [];
    data.consolidatedStatements.map((statements) => {
      statements.recipients.map((recipient) => {
        if (
          !uniqueRecipientList.some(
            (item) => item.name === recipient.recipientName
          )
        ) {
          uniqueRecipientList.push({
            name: recipient.recipientName,
          });
        }
      });
    });
    return uniqueRecipientList.sort((a, b) => (a.name > b.name ? 1 : -1));
  };

  const getRecipientFilterOptions = () => {
    const filterOptions = [];
    getUniqueRecipients(statementsData).map((recipient) => {
      filterOptions.push(
        <li
          key={recipient.name}
          onClick={() => handleRecipientChange(recipient.name)}
        >
          <img
            src={
              recipientSelectedValues.includes(recipient.name)
                ? CheckboxCheckedImg
                : CheckboxImg
            }
            alt="Checkbox"
          />{' '}
          {recipient.name}
        </li>
      );
    });
    return filterOptions;
  };

  const getAccountFilterOptions = () => {
    const filterOptions = [];
    getUniqueAccounts(statementsData).map((account) => {
      filterOptions.push(
        <li
          key={account.name}
          onClick={() => handleAccountChange(account.name)}
        >
          <img
            src={
              accountSelectedValues.includes(account.name)
                ? CheckboxCheckedImg
                : CheckboxImg
            }
            alt="Checkbox"
          />{' '}
          {account.name}
        </li>
      );
    });
    return filterOptions;
  };

  const handleDateChange = () => {
    if (dates && dates.length > 0) {
      setDateValues(dates);
      setDates(null);
    }
    GetMonthlyStatements(programId, ssnorTIN, dateValues[0].format('YYYY-MM-01'), 
    dateValues[1].format("YYYY-MM-") + dateValues[1].daysInMonth() + ' 11:59:59 PM')
      .then((response) => {
        if (response.data != null) {
          setStatementsData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Container>
      <Wrapper>
        <Box margin="0 0 24px">
          <Space size={8}>
            <RenderDatePicker
              //dates={dates}
              //setDates={setDates}
              dateValues={dateValues}
              setDateValues={setDateValues}
              dateOption={dateOption}
              setDateOption={setDateOption}
              dateLabel={dateLabel}
              setDateLabel={setDateLabel}
              dateOpen={dateOpen}
              setDateOpen={setDateOpen}
              handleDateChange={handleDateChange}
              picker="month"
              monthPickerPanel={true}
            />
            {recipientType === 'multiple' &&
              statements.consolidatedStatements &&
              statements.consolidatedStatements.length > 0 && (
                <MultiSelect
                  storeArray={'clearAllRecipients'}
                  filterClass={recipientFilterClass}
                  setFilterClass={setRecipientFilterClass}
                  selectedValues={recipientSelectedValues}
                  setSelectedValues={setRecipientSelectedValues}
                  options={getRecipientFilterOptions()}
                  buttonLabel={'Recipient'}
                  height={'110px'}
                  selectedBgColor={`${primary_color}`}
                  selectedColor={`${theme.colors.white}`}
                />
              )}
            <MultiSelect
              storeArray={'clearAllAccounts'}
              filterClass={accountFilterClass}
              setFilterClass={setAccountFilterClass}
              selectedValues={accountSelectedValues}
              setSelectedValues={setAccountSelectedValues}
              options={getAccountFilterOptions()}
              buttonLabel={'Account'}
              height={'180px'}
              selectedBgColor={`${primary_color}`}
              selectedColor={`${theme.colors.white}`}
            />
          </Space>
        </Box>
        {filteredStatementsData.consolidatedStatements &&
          filteredStatementsData.consolidatedStatements.length > 0 && (
            <ConsolidatedStatementsTable
              data={filteredStatementsData.consolidatedStatements}
              recipientType={recipientType}
            />
          )}
        <Box margin="24px 0">
          <AccountStatementsTable
            data={filteredStatementsData.accountStatements}
          />
        </Box>
      </Wrapper>
    </Container>
  );
};

export default StatementsComponent;
