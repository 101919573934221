import React from 'react';
import styled from 'styled-components';
import { Space } from 'antd';
import { theme, Text, ButtonAnt } from '@pfmam/pfmam';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
const ButtonWrapper = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
`;

const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    border-color: ${(props) => props.linkTextColor};
  }
`;
const FormSubmittedComponent = ({ label, link, isAuthenticatedUser }) => {
  const history = useHistory();
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  return (
    <>
      <Text
        label="Thank you for contacting us. We will be in touch within one business day."
        fontSize={theme.font_size.size_l}
        lineheight="24px"
        color={`${theme.colors.clean_black}`}
        margin="0 0 40px"
      />
      <ButtonWrapper size={12}>
        <OverrideButtonAnt
          type="primary"
          label={label}
          linkTextColor={isAuthenticatedUser ? primary_color : '#EAE8E8'}
          onClick={() => history.push(link)}
          className={isAuthenticatedUser ? 'primary' : 'unauth-user-btn'}
        />
      </ButtonWrapper>
    </>
  );
};

export default FormSubmittedComponent;
