import config from '../config';

export function MFARoute({user}) {
    const secureAuthRealm = config.SecureAuth;
    if(user != null){
      const userName=user.profile.name;
      postMFA(secureAuthRealm, {
        UserID: userName,
        Mode: 'Public',
        SharedSecret: '111',
      });
    }
  }
  
  function postMFA(path, params, method = 'post') {
    const form = document.createElement('form');
    form.method = method;
    form.action = path;
  
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];
        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
  }
