/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Layout } from 'antd';
import { theme, Text, TableComponent } from '@pfmam/pfmam';
import { Container, Box, Banner } from '../styles/globalStyle';
import AppLayout from '../common/layouts/appLayout';
import NoDataFound from '../common/components/noDataFound';
//import data from '../data/faq.json';
import { FAQ } from '../actions/actions';
const { Content } = Layout;

const ContentWrapper = styled(Content)`
  .ant-table {
    border-radius: 4px;
    .ant-table-tbody {
      & > tr {
        cursor: pointer;
        &:hover {
          &.ant-table-row:not(.ant-table-expanded-row) {
            background: linear-gradient(
                0deg,
                rgba(23, 54, 93, 0.07),
                rgba(23, 54, 93, 0.07)
              ),
              ${theme.colors.white};
          }
        }
        & > td {
          vertical-align: top;
          padding: 24px 16px 24px 0;
          font-size: ${theme.font_size.size_3xl};
          line-height: 32px;
          letter-spacing: -0.02em;
          border-bottom-color: ${theme.colors.grey_fossil};
          &.ant-table-column-sort {
            background: ${theme.colors.transparent};
          }
        }
      }
      & > tr {
        &.ant-table-expanded-row {
          & > td {
            padding: 16px 32px 32px 36px;
            font-size: ${theme.font_size.size_xl};
            line-height: 24px;
            background: ${theme.colors.grey_faint};
          }
        }
      }
    }
    .ant-table-title {
      display: none;
    }
  }
`;

const ExpandButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  min-width: 32px;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 5px;
  & > span {
    height: 19px;
    min-width: 19px;
    width: 19px;
    margin: 0 auto;
    border: 2px solid ${theme.colors.grey};
    border-radius: 2px;
    padding: 5px;
    position: relative;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: '';
      position: absolute;
      top: 3px;
      background: ${theme.colors.black_secondary};
      height: 10px;
      width: 2px;
      transition: transform 0.3s ease-out;
      transform: rotate(0deg);
    }
    &::after {
      content: '';
      position: absolute;
      top: 7px;
      right: 2.3px;
      background: ${theme.colors.black_secondary};
      height: 2px;
      width: 10px;
      transition: transform 0.3s ease-out;
      transform: rotate(-180deg);
    }
  }
  &:focus {
    & > span {
      box-shadow: ${theme.shadows.input_focus};
      border-color: ${theme.colors.blue_primay};
    }
  }
  &.expanded {
    & > span {
      &::after {
        transform: rotate(0deg);
      }
      &::before {
        transform: rotate(90deg);
      }
    }
  }
`;

const Ul = styled.ul`
  list-style-type: disc;
  padding-left: 28px;
`;

const Li = styled.li`
  font-size: ${theme.font_size.size_xl};
  color: ${theme.colors.black_secondary};
  line-height: 24px;
`;

const FaqContainer = () => {
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const programId = clientDetails.payload[0].programid;
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const [faqData, setFAQData] = useState([]);
  useEffect(() => {
    FAQ(programId)
      .then((response) => {
        if (response.data.length > 0) {
          setFAQData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const columns = [
    {
      title: '',
      dataIndex: 'question',
      key: 'question',
      align: 'left',
    },
  ];

  const getFaqAnswers = (childRecord) => {
    if (childRecord.type && childRecord.type === 'list') {
      return (
        <Ul>
          {childRecord.answer &&
            childRecord.answer.map((ans) => {
              return (
                <Li>
                  <Text label={ans} />
                </Li>
              );
            })}
        </Ul>
      );
    } else {
      return (
        childRecord.answer &&
        childRecord.answer.map((ans, index) => {
          if (index === 0) {
            return <Text label={ans} />;
          } else {
            return <Text label={ans} margin="10px 0 0" />;
          }
        })
      );
    }
  };
  const expandable = {
    defaultExpandAllRows: false,
    expandedRowRender: (childRecord) => <>{getFaqAnswers(childRecord)}</>,
    expandIcon: ({ expanded, onExpand, record }) => (
      <ExpandButton
        className={expanded ? 'expanded' : ''}
        onClick={(e) => {
          e.stopPropagation();
          onExpand(record, e);
        }}
      >
        <span></span>
      </ExpandButton>
    ),
  };

  return (
    <AppLayout>
      <ContentWrapper>
        <Banner bgcolor={primary_color} height="124px">
          <Box
            display="flex"
            alignitems="center"
            height="100%"
            minwidth="100%"
            width="100%"
            zindex="3"
            position="relative"
            padding={'44px 0 36px 0'}
          >
            <Container>
              <Box
                display="flex"
                justifycontent="space-between"
                alignitems="flex-start"
                margin={'0'}
              >
                <Box flex="1">
                  <Text
                    color={`${theme.colors.white}`}
                    fontSize="36px"
                    lineheight="44px"
                    label="Frequently Asked Questions"
                  />
                </Box>
              </Box>
            </Container>
          </Box>
        </Banner>
        <Box padding="32px 0 0">
          <Container>
            <TableComponent
              showHeader={false}
              className="table-primary"
              columns={columns}
              dataSource={faqData}
              locale={{
                emptyText: (
                  <NoDataFound text="No available FAQ's" padding="18px 16px" />
                ),
              }}
              expandable={expandable}
            />
          </Container>
        </Box>
      </ContentWrapper>
    </AppLayout>
  );
};

export default FaqContainer;
