import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import config from '../src/config';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import moment from 'moment';
import {
  getProgramsClientsSummary,
  clientsFilteredSearch,
} from './services/programClientsSlice';
import { signoutRedirect } from '../src/services/userService';
import LoadSpinner from './common/components/LoadingSpinner/LoadSpinner';

function Home() {
  const [loading, setLoading] = useState(null);

  const ProgramsClientsSummary = useDispatch();
  const ProgramsClientsSummarySelected = useDispatch();
  const userClients = useSelector((state) => state.programsClientsSlice);
  const user_logout = useSelector((state) => state.userSlice.user.id_token);
  // const newExperience = useSelector(
  //   (state) => state.userSlice.user.profile.NewExperience
  // );
  useEffect(() => {
   
    const idltimeout = localStorage.getItem('CSG');
    if (idltimeout != '' && user_logout != null) {
      var mins = moment
        .utc(
          moment(new Date(), 'DD/MM/YYYY HH:mm:ss').diff(
            moment(idltimeout, 'DD/MM/YYYY HH:mm:ss')
          )
        )
        .format('mm');
      if (Number(mins) >= 20) {
        signoutRedirect(user_logout);
      }
    }

    ProgramsClientsSummary(getProgramsClientsSummary());
  }, [ProgramsClientsSummary]);

  useEffect(() => {
    if (userClients.programClients.length == 1) {
      ProgramsClientsSummarySelected(
        clientsFilteredSearch(userClients.programClients)
      );
    }
  }, [ProgramsClientsSummarySelected, userClients.programClients]);

  
  useEffect(() => {
    const root = document.getElementById('root');
    if (root !== null || root !== '') {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [loading])

  const getLink = () => {
    // if (newExperience == 'False') {
    //   window.localStorage.clear();
    //   window.location.replace(config.EON_URL);
    //   return null;
    // } 
    
    if (loading == true) {
      return <LoadSpinner></LoadSpinner>;
    }
    if (userClients.programClients.length == 1) {
      return <Redirect to="/dashboard" />;
    } else if (userClients.programClients.length >= 1) {
      return <Redirect to="/choose-client" />;
    }

    if (
      userClients.status == 'fulfilled' &&
      userClients.programClients.length == 0
    ) {
      return <Redirect to="/no-client" />;
    }
  };
  return <>{getLink()}</>;
}

export default Home;
