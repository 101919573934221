import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../config';
import { removeNull } from '../utils/common';

const baseApiUrl = config.BASE_API_URL;

export const getactivityHistory = createAsyncThunk(
  'activityHistory/getactivityHistory',
  async ({
    SSNorTIN,
    ProgramId,
    AccountUniqueID = null,
    EndSettlementDate = null,
    StartSettlementDate = null,
  }) => {
    const ActivityHist = await axios
      .request({
        method: 'POST',
        url: baseApiUrl + 'Transactions/ActivityHistory',
        data: {
          SSNorTIN,
          ProgramId,
          AccountUniqueID,
          EndSettlementDate,
          StartSettlementDate,
        },
      })
      .then((res) => removeNull(res.data));
    return ActivityHist;
  }
);

const activityHistorySlice = createSlice({
  name: 'activityHistory',
  initialState: {
    ActivityHistory: [],
    status: null,
  },
  extraReducers: {
    [getactivityHistory.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getactivityHistory.fulfilled]: (state, action) => {
      state.ActivityHistory = action.payload;
      state.status = 'success';
    },
    [getactivityHistory.rejected]: (state, action) => {
      state.status = 'failed';
    },
  },
});
export default activityHistorySlice.reducer;
//export const { getactivityHistory } = activityHistorySlice.actions;
