import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from './dashboard/dashboardContainer';
import DashboardGeneralFunds from './dashboard/generalFundsContainer';
import ClientActivityHistoryContainer from './activityHistory/clientActivityHistoryContainer';
import NotFoundContainer from './notFound/notFoundContainer';
import ChooseClient from './chooseClient/chooseClientContainer';
import NoClient from './chooseClient/noClientContainer';
import PrivateRoute from './utils/protectedRoute';
import SigninOidc from './signin-oidc';
import SignoutOidc from './signout-oidc';
import Login from './login';
import Home from './home';
import OrganisationalSettingsContainer from './organisationalSettings/organisationalSettingsContainer';
import ContactManagement from './contactManagement/ContactManagement';
import CurrentYieldsContainer from './dashboard/currentYieldsContainer';
import PurchaseTransaction from './transactionPages/purchaseFunds/purchaseContainer';
import StopPaymentsContainer from './activityHistory/stopPaymentsContainer';
import Redemption from './transactionPages/redemptions/redemptionContainer';
import Transfer from './transactionPages/transferFunds/transferContainer';
import LogoutContainer from './logout/logoutContainer';
import ProfileContainer from './profile/profileContainer';
import FaqContainer from './faq/faqContainer';
import SecureContactContainer from './secureContact/secureContactContainer';
import StatementsDocumentsContainer from './statementsDocuments/statementsDocumentsContainer';
import ImportantDisclosure from './footerPages/importantDisclosures';
import PrivacyPolicy from './footerPages/privacyPolicy';
import TermsConditions from './footerPages/termsConditions';
import WireContainer from './transactionPages/wireNotifications/wireContainer';
import { BrowserRouter } from 'react-router-dom';
import UserLeaveConfirmation from './common/components/UserLeaveConfirmation';
import IATErrorContainer from './common/components/iatError';
import EditContactContainer from './contactManagement/editContactContainer';
import CreateContactContainer from './contactManagement/createContactContainer';
//Sample test
const AuthRoutes = () => {
  const [confirmOpen, setConfirmOpen] = useState(true);
  return (
    <BrowserRouter
      getUserConfirmation={(message, callback) => {
        return UserLeaveConfirmation(
          message,
          callback,
          confirmOpen,
          setConfirmOpen
        );
      }}
    >
      <Switch>
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute
          exact
          path="/dashboard/details/:id"
          component={DashboardGeneralFunds}
        />
        <PrivateRoute
          exact
          strict
          path="/dashboard/settings/:id"
          render={(props) => (
            <OrganisationalSettingsContainer
              {...props}
              isGlobalSettingsPage={false}
            />
          )}
        />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute
          exact
          path="/dashboard/current-yields"
          component={CurrentYieldsContainer}
        />
        <PrivateRoute
          exact
          strict
          path="/settings"
          render={(props) => (
            <OrganisationalSettingsContainer
              {...props}
              isGlobalSettingsPage={true}
            />
          )}
        />
        <PrivateRoute exact path="/purchase" component={PurchaseTransaction} />
        <PrivateRoute exact path="/choose-client" component={ChooseClient} />
        <PrivateRoute exact path="/no-client" component={NoClient} />
        <PrivateRoute
          exact
          path="/activity"
          render={(props) => (
            <ClientActivityHistoryContainer {...props} isGlobal={true} />
          )}
        />

        <PrivateRoute exact path="/redemption" component={Redemption} />
        <PrivateRoute exact path="/transfer" component={Transfer} />

        <PrivateRoute
          exact
          path="/wire-notification"
          component={WireContainer}
        />
        <PrivateRoute
          exact
          path="/activity/stop-payments"
          render={(props) => (
            <StopPaymentsContainer {...props} isGlobalStopPayments={true} />
          )}
        />
        <PrivateRoute
          exact
          path="/dashboard/details/:id/stop-payments"
          render={(props) => (
            <StopPaymentsContainer {...props} isGlobalStopPayments={false} />
          )}
        />
        <Route path="/login" component={Login} />
        <Route path="/signout-oidc" component={SignoutOidc} />
        <Route path="/signin-oidc" component={SigninOidc} />
        <PrivateRoute path="/faq" component={FaqContainer} />
        <PrivateRoute
          exact
          path="/contact"
          render={(props) => (
            <SecureContactContainer {...props} isAuthenticatedUser={true} />
          )}
        />
        <PrivateRoute
          path="/statements-documents"
          component={StatementsDocumentsContainer}
        />
        <PrivateRoute path="/profile" component={ProfileContainer} />
        <Route path="/disclosures" component={ImportantDisclosure} />
        <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
        <Route path="/TermsandConditions" component={TermsConditions} />
        <PrivateRoute
          path="/privacy-policy"
          exact
          render={(props) => (
            <PrivacyPolicy {...props} isAuthenticatedUser={true} />
          )}
        />
        <PrivateRoute
          path="/terms-and-conditions"
          exact
          render={(props) => (
            <TermsConditions {...props} isAuthenticatedUser={true} />
          )}
        />
        <PrivateRoute
          path="/important-disclosures"
          exact
          render={(props) => (
            <ImportantDisclosure {...props} isAuthenticatedUser={true} />
          )}
        />
        <PrivateRoute 
          path="/edit-contact"
          component={EditContactContainer}

        />
         <PrivateRoute 
          path="/create-contact"
          component={CreateContactContainer}

        />
            <PrivateRoute
          exact
          path="/contact-management"
          component={ContactManagement}
        />
        <Route
          path="/Contactus"
          exact
          render={(props) => (
            <SecureContactContainer {...props} isAuthenticatedUser={false} />
          )}
        />
        <Route path="/logout" component={LogoutContainer} />
        <Route path="/404" component={NotFoundContainer} />
        <Route path="/IATError" component={IATErrorContainer} />
        <Redirect to="/404" />
      </Switch>
    </BrowserRouter>
  );
};

export default AuthRoutes;
