import React from 'react';
import ListWrapperComponent from './listWrapper';
import { amountFormat } from '../../../utils/numberFormat';

const DividendsCard = ({ monthlyAccountInfo }) => {
  let totalAmount = 0;
  return (
    <>
      {monthlyAccountInfo.map((dividend) => {
        totalAmount = dividend.YTDDividend + totalAmount;
        return (
          <ListWrapperComponent
            label={dividend.FundShortName}
            value={amountFormat(dividend.YTDDividend)}
          />
        );
      })}
      <ListWrapperComponent
        label={'Total'}
        value={amountFormat(totalAmount)}
        className={'strong'}
      />
    </>
  );
};

export default DividendsCard;
