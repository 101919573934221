import React from 'react';
import { theme, Text } from '@pfmam/pfmam';
import { Box } from '../../styles/globalStyle';

const ImportantDisclosureContent = ({ isAuthenticatedUser }) => {
  return (
    <Box padding="40px 0 0" width="100%" maxwidth="800px" margin="0 auto">
      <Box boxcolor={`${theme.colors.white}`} radius="2px">
        {isAuthenticatedUser && (
          <Box
            border={`1px solid ${theme.colors.grey}`}
            borderwidth="0 0 1px 0"
            padding="24px 32px"
          >
            <Text
              label="Important Disclosures"
              fontSize={`${theme.font_size.size_3xl}`}
              color={`${theme.colors.black_secondary}`}
              lineheight="32px"
            />
          </Box>
        )}
        <Box padding="43px 32px 48px">
          <Text
            label="This Website is for general information purposes only and is not intended to provide specific advice or recommendations."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          {/* <Text
            label="(“PFM”) is an investment advisor registered with the Securities and Exchange Commission, and is required to maintain a written disclosure statement of our background and business experience. If you would like to receive a copy of our current disclosure statement, please contact Service Operations at the address below."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          /> */}
          <Text
            label="Proxy Voting"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="We do not normally receive proxies to vote on behalf of clients. However, we do on occasion receive consent requests. In the event a consent request is received the portfolio manager contacts the client and then proceeds according to the client’s instructions. Our Proxy Voting Policy is available upon request by contacting the address below."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="Questions About an Account"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="This Website is intended to detail our investment advisory activity as well as the activity of any accounts held by clients in pools that are managed by us. The custodian bank maintains the control of assets and executes (i.e. settles) all investment transactions. The custodian statement is the official record of security and cash holdings and transactions. We recognize that clients may use this Website to facilitate record keeping and that the custodian bank statement and the Website should be reconciled, and any differences resolved. Many custodians use a settlement date basis which may result in the need to reconcile due to a timing difference."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="Account Control"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="We do not have the authority to withdraw funds from or deposit funds to the custodian unless specifically authorized by the client to deduct investment advisory fees in accordance with regulatory requirements.. Our clients retain responsibility for their internal accounting policies, implementing and enforcing internal controls and generating ledger entries or otherwise recording transactions."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="Market Value"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="Generally, market prices are derived from closing bid prices as of the last business day of the month as supplied by Refinitiv or Bloomberg. Where prices are not available from generally recognized sources the securities are priced using a yield-based matrix system to arrive at an estimated market value. Prices that fall between data points are interpolated. Non-negotiable Federal Deposit Insurance Corporation (“FDIC”)-insured bank certificates of deposit are priced at par. Although we believe the prices to be reliable, the values of the securities do not always represent the prices at which the securities could have been bought or sold. Explanation of the valuation methods for a registered investment company, local government investment pool, or TERM funds is contained in the appropriate fund offering documentation or information statement."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          {/* <Text
            label="Bloomberg. Where prices are not available from generally recognized sources the securities are priced using a yield-based matrix system to arrive at an"
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          /> */}
          {/* <Text
            label="estimated market value. Prices that fall between data points are interpolated. Non-negotiable FDIC-insured bank certificates of deposit are priced at par."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          /> */}
          {/* <Text
            label="Although PFM believes the prices to be reliable, the values of the securities do not always represent the prices at which the securities could have been bought or sold. Explanation of the valuation methods for a registered investment company, local government investment program,or TERM funds is contained in the appropriate fund offering documentation or information statement."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          /> */}
          <Text
            label="Amortized Cost"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="The original cost of the principal of the security is adjusted for the amount of the periodic reduction of any discount or premium from the purchase date until the date of the report."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Discount or premium with respect to short term securities (those with less than one year to maturity at time of issuance) is amortized on a straight-line basis. Such discount or premium with respect to longer term securities are amortized using the constant yield basis."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="Tax Reporting"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="Cost data and realized gains/losses are provided for information purposes only. Please review for accuracy and consult your tax advisor to determine the tax consequences of your security transactions. We do not report such information to the Internal Revenue Service (“IRS”) or other taxing authorities and is not responsible for the accuracy of such information that may be required to be reported to federal state or other taxing authorities."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="Financial Situation"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="In order to better serve you, we should be promptly notified of any material change in your investment objectives or financial situation."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="Callable Securities"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="Securities subject to redemption prior to maturity may be redeemed in whole or in part before maturity, which could affect the yield represented."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="Portfolio"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="The securities in this portfolio, including shares of mutual funds, are not guaranteed or otherwise protected by us, the FDIC (except for certain non-negotiable certificates of deposit) or any government agency. Investment in securities involves risks, including the possible loss of the amount invested. Actual settlement values, accrued interest, and amortized cost amounts may vary for securities subject to an adjustable interest rate or subject to principal paydowns."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="Rating"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="Information provided for ratings is based upon a good faith inquiry of selected sources, but its accuracy and completeness cannot be guaranteed."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Shares of some local government investment pool and TERM funds are distributed by U.S. Bancorp Investments, Inc. (“USBI”). USBI is a member of the Financial Industry Regulatory Authority (“FINRA”) and the Securities Investor Protection Corporation. You may reach FINRA by calling the FINRA Regulator Public Disclosure Hotline at 1-888-289-9999 or at the FINRA Regulation Internet Website address. (www.finra.org) A brochure describing the FINRA Regulation Public Disclosure Program is also available from FINRA upon request."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="Key Terms and Definitions"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="Dividends on local government investment pools or money market funds consist of interest earned, plus any discount ratably amortized to date of maturity, plus all realized gains and losses on the sale of securities prior to maturity, less ratable amortization of any premium and all accrued expenses to the fund. Dividends are accrued daily and may be paid either monthly or quarterly. The monthly earnings represent the estimated dividend accrued for the month for any program that distributes earnings on a quarterly basis. There is no guarantee that the estimated amount will be paid on the actual distribution date."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Current Yield is the net change, exclusive of capital changes and income other than investment income, in the value of a hypothetical fund account with a balance of one share over the seven-day base period including the statement date, expressed as a percentage of the value of one share (normally $1.00 per share) at the beginning of the seven-day period. This resulting net change in account value is then annualized by multiplying it by 365 and dividing the result by 7. The yields quoted should not be considered a representation of the yield of the fund in the future, since the yield is not fixed."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Average Maturity represents the average maturity of all securities and investments of a portfolio, determined by multiplying the par or principal value of each security or investment by its maturity (days or years), summing the products, and dividing the sum by the total principal value of the portfolio. The stated maturity date of mortgage- backed or callable securities is used. However, the actual maturity of these securities could vary depending on the level or prepayments on the underlying mortgages or whether a callable security has been, or is still able to be, called."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Monthly Distribution Yield represents the net change in the value of one share (normally $1.00 per share) resulting from all dividends declared during the month by a fund expressed as a percentage of the value of one share at the beginning of the month. The resulting net change is then annualized by multiplying it by 365 and dividing it by the number of calendar days in the month."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="YTM at Cost is the expected rate of return, based on the original cost, the annual interest receipts, maturity value and the time period from purchase date to maturity, stated as a percentage, on an annualized basis."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="YTM at Market is the rate of return, based on the current market value, the annual interest receipts, maturity value and the time period remaining until maturity, stated as a percentage on an annualized basis."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Managed Account is a portfolio of investments managed discretely by us according to the client’s specific investment policy and requirements. The investments are discretely owned by the client and held by the client’s custodian."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Unsettled Trade is a trade which has been executed however the final consummation of the security transaction and payment has not yet taken place."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Please review the information on this Website carefully. If you think your information is incorrect, missing account information or if you need more information about a transaction, please contact us immediately. If you have other concerns or questions regarding your account, you should contact a member of your client management team or PFMAM Service Operations at the address below:"
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          {/* <Text
            label="Please review the detail pages of this statement carefully. If you think your statement is wrong, missing account information, or if you need more"
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          /> */}
          {/* <Text
            label="information about a transaction, please contact PFM within 60 days of receipt. If you have other concerns or questions regarding your account you should contact a member of your client management team or PFM Service Operations at the address below."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          /> */}
          <Box width="220px">
            <Text
              label="PFM Asset Management Attn: Service Operations 213 Market Street Harrisburg, PA 17101"
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.grey_secondary}`}
              lineheight="30px"
            />
          </Box>
          <br></br>
          <Text
            label="PFM Asset Management (&quot;PFMAM&quot;) is a division of U.S. Bancorp Asset Management, Inc. (&quot;USBAM&quot;).  USBAM is an investment adviser registered with the U.S. Securities and Exchange Commission and a subsidiary of U.S. Bank National Association (&quot;U.S. Bank&quot;). U.S. Bank is a separate entity and subsidiary of U.S. Bancorp.  U.S. Bank is not responsible for and does not guarantee the products, services or performance of USBAM or PFMAM."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Oct 1, 2024"
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="50px"
          />
        </Box>
      </Box>
    </Box>
  );
};
export default ImportantDisclosureContent;
