import React, { useEffect, useState } from 'react';
import config from '../config';
import { useSelector } from 'react-redux';
import axios from 'axios';
//import styled from 'styled-components';
import moment from 'moment';
import {
  theme,
  Text,
  ButtonAnt,
  Modal,
  Tabs,
} from '@pfmam/pfmam';
import { Container, Box } from '../styles/globalStyle';
import { DownloadOutlined } from '@ant-design/icons';
import ProductDetailsModal from './components/holdings/productDetailsModal';
import BarCharts from './components/holdings/barChart';
import holdingsHistory from '../data/HoldingsHistory.json';
import { barDetailsFormatter, removeNull } from '../utils/common';
import DetailsModalContent from '../common/components/detailsModal';
import DownloadModal from '../common/components/downloadModal';
import InvestmentTypeComponent from './components/holdings/investmentTypeComponent';
import SecurityTypeComponent from './components/holdings/securityTypeComponent';

const HoldingsContainer = () => {
  // let barDates = [];
  // holdingsHistory.dates.forEach((val) => {
  //   debugger;
  //   barDates.push(moment(val).format("MMM YYYY"));
  // });
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [investTypeHoldings, setInvestTypeHoldings] = useState(null);
  const [investTypeBarDetails, setInvestTypeBarDetails] = useState(null);
  const [investTypeBarDates, setInvestTypeBarDates] = useState(null);
  const [sectorTypeHoldings, setSectorTypeHoldings] = useState(null);
  const [sectorTypeBarDetails, setSectorTypeBarDetails] = useState(null);
  const [sectorTypeBarDates, setSectorTypeBarDates] = useState(null);

  const primary_color = useSelector((state) => state.siteInfo.primaryColor);

  const selectedAccount = useSelector(
    (state) => state.accountProfileSlice.selectedAccount
  );
  const programType = selectedAccount.AccountType;
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('investment_type');
  const [type, setType] = useState(null);
  const handleAction = (data, type) => {
    setType(type);
    setIsDetailsModalVisible(!isDetailsModalVisible);
    setSelectedRecord(data);
  };

  const handleModalCancel = () => {
    setIsDetailsModalVisible(!isDetailsModalVisible);
    setSelectedRecord(null);
  };

  useEffect(() => {
    axios
      .request({
        method: 'GET',
        url:
          config.BASE_API_URL +
          'Holdings/HoldingsHistory/' +
          selectedAccount.AccountUniqueID,
      })
      .then((response) => {
        let ProductTypedates = [];
        let SectorTypedates = [];

        if (response.data.ProductTypeDateValues !== null) {
          response.data.ProductTypeDateValues.forEach((val) => {
            ProductTypedates.push(moment(val).format('MMM YYYY'));
          });
        }
        if (response.data.SectorTypeDateValues !== null) {
          response.data.SectorTypeDateValues.forEach((val) => {
            SectorTypedates.push(moment(val).format('MMM YYYY'));
          });
        }

        if (response.data.ProductClassTypesHoldings !== null) {
          response.data.ProductClassTypesHoldings.forEach((val) => {
            val.borderRadiusTopRight = '0';
            val.borderRadiusTopLeft = '0';
          });
        }

        if (response.data.SectorTypeHoldings !== null) {
          response.data.SectorTypeHoldings.forEach((val) => {
            val.borderRadiusTopRight = '0';
            val.borderRadiusTopLeft = '0';
          });
        }
        setInvestTypeBarDetails(response.data.ProductClassTypesHoldings);
        setInvestTypeBarDates(ProductTypedates);
        setSectorTypeBarDetails(response.data.SectorTypeHoldings);
        setSectorTypeBarDates(SectorTypedates);
      });

    axios
      .request({
        method: 'GET',
        url:
          config.BASE_API_URL +
          'Holdings/InvestmentTypeHoldings/' +
          selectedAccount.AccountUniqueID,
      })
      .then((response) => {
        setInvestTypeHoldings(removeNull(response.data.InvTypeClassHold));
        setSectorTypeHoldings(removeNull(response.data.SectorTypeHold));
      });
  }, []);

  const showModal = () => {
    setIsDownloadModalVisible(true);
  };

  const handleCancel = () => {
    setIsDownloadModalVisible(false);
  };
  return (
    <Container>
      <Box
        padding="36px 0 28px 0"
        display="flex"
        alignitems="center"
        justifycontent="space-between"
      >
        <Text
          label="Holdings"
          fontSize="36px"
          lineheight="44px"
          color={`${theme.colors.black_secondary}`}
        />
        <ButtonAnt
          icon={<DownloadOutlined />}
          label="Download"
          type="secondary"
          onClick={showModal}
        />
      </Box>
      {/* //<Box padding="20px 0px">
      //  <BarCharts details={barDetailsFormatter(barDetails)} date={barDates} />
      //</Box> */}
      {programType === 'HYBRID' ? (
        <Tabs
          defaultActiveKey={activeTab}
          activeKey={activeTab}
          activeTabColor={primary_color}
          onTabClick={(key) => setActiveTab(key)}
          tabsOptions={[
            {
              content: (
                <InvestmentTypeComponent
                  InvTypeBarDetails={investTypeBarDetails}
                  InvTypeBarDates={investTypeBarDates}
                  InvTypeHoldings={investTypeHoldings}
                  handleAction={handleAction}
                />
              ),
              key: 'investment_type',
              label: 'Investment Type',
            },
            {
              content: (
                <SecurityTypeComponent
                  SecTypeBarDates={sectorTypeBarDates}
                  SecTypeBarDetails={sectorTypeBarDetails}
                  SecTypeHoldings={sectorTypeHoldings}
                  handleAction={handleAction}
                />
              ),
              key: 'sector_type',
              label: 'Sector Type',
            },
          ]}
          type="nav-pills"
        />
      ) : (
        <>
          {programType === 'SMA-FI' || programType === 'SMA-MACM' ? (
            <SecurityTypeComponent
              SecTypeBarDates={sectorTypeBarDates}
              SecTypeBarDetails={sectorTypeBarDetails}
              SecTypeHoldings={sectorTypeHoldings}
              handleAction={handleAction}
            />
          ) : (
            <InvestmentTypeComponent
              InvTypeBarDetails={investTypeBarDetails}
              InvTypeBarDates={investTypeBarDates}
              InvTypeHoldings={investTypeHoldings}
              handleAction={handleAction}
            />
          )}
        </>
      )}
      {/* //Venkata to handle
          //<TableWrapper>
          //    <ProductsTable
          //        data={InvestmentTypeHoldings}
          //        handleAction={handleAction}
          //        type="pending"
          //    />
          //</TableWrapper> */}
      {isDetailsModalVisible && (
        <Modal
          title="Holdings Details"
          width={590}
          bodyStyle={{
            padding: '32px 32px 48px 32px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          content={
            <DetailsModalContent
              title={selectedRecord.Description}
              selectedRecord={selectedRecord}
            />
          }
          visible={isDetailsModalVisible}
          onCancel={() => handleModalCancel()}
          footer={null}
          centered={true}
        />
      )}
      <Modal
        title="Download File Type"
        width={590}
        bodyStyle={{
          padding: '32px 32px 34px 32px',
        }}
        maskStyle={{
          backgroundColor: `${theme.rgba.modal}`,
          zIndex: '1051',
        }}
        content={
          <DownloadModal
            downloadData={investTypeHoldings}
            onCancel={handleCancel}
            type="Holdings"
          />
        }
        visible={isDownloadModalVisible}
        onCancel={handleCancel}
        footer={null}
        centered={true}
      />
    </Container>
  );
};

export default HoldingsContainer;
