/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout, Row, Col, Tooltip } from 'antd';
import {
  InfoCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {
  theme,
  Text,
  CardAnt,
  ButtonAnt,
  Modal,
} from '@pfmam/pfmam';
import { Container, Elevation, Box, Banner } from '../styles/globalStyle';
import accountDetails from '../data/AccountDetails.json';
import { amountFormat, percentFormat } from '../utils/numberFormat';
import ActivityList from './components/activityList';
import ProductList from './components/productList';
import AccountsTable from './components/accountsTable';
import DonutChartComponent from './components/donutChartComponent';
import AppLayout from '../common/layouts/appLayout';
import CancelModalContent from '../common/components/cancelModal';
import DetailsModalContent from '../common/components/detailsModal';
import { getMenuPermissions } from '../services/menuPermissionSlice';
import {
  GetAccountProfileInfo,
  GetPendingActivity,
  GetPendingActivityAdditionalInfo,
  AddEonCachedAccounts,
  GetAlerts,
  DismissAlert,
  GetAsOfDate,
} from '../actions/actions';
import {
  getsiteConfigurationInfo,
  getUserPermissions,
} from '../services/siteConfigurationSlice';
import { getactivityHistory } from '../services/ActivityHistorySlice';

import { storeAccountProfile } from '../services/accountProfileSlice';
import { useSelector, useDispatch } from 'react-redux';
import AlertDetailsModalContent from '../common/components/alertDetailsModal';
import { InfoCircleFilled } from '@ant-design/icons';
import { sizes } from '../styles/mediaQuery';
import {
  GetPendingActivityDetailsData,
  GetEnvisionPendingActivityDetailsData,
} from '../utils/pendingActivityRenderFilter';
import MoveFundsModal from './components/moveFundsModal';
import config from '../config';
import ReactHtmlParser from 'react-html-parser';
const { Content } = Layout;

const ContentWrapper = styled(Content)`
  .product-card {
    &:first-child {
      margin-bottom: 24px;
    }
    .ant-card-head {
      padding: 0 32px;
      font-weight: normal;
    }
    .ant-card-head-title {
      font-size: ${theme.font_size.size_3xl};
      color: ${theme.colors.black_tertiary};
      line-height: 32px;
      padding: 24px 0;
    }
    .ant-card-body {
      padding: 24px 32px 40px;
    }
    &.ant-card {
      border-radius: 4px;
      border: 0;
    }
  }
  .pending-activity-card {
    .ant-card-body {
      padding: 0;
    }
  }
  .divider-horizontal {
    border-left: 1px solid ${theme.colors.grey_fossil};
  }
  .cd-label {
    color: ${theme.colors.grey_tertiary};
    font-size: ${theme.font_size.size_l};
    line-height: 24px;
    padding: 10px 0;
    .anticon {
      margin-left: 8px;
    }
  }
  .balance-summary-text {
    color: ${theme.colors.black_tertiary};
    font-size: ${theme.font_size.size_xl};
    line-height: 24px;
    margin: 0 0 9px 0;
    .anticon {
      color: ${theme.colors.grey_tertiary};
      margin-left: 5px;
    }
    @media ${sizes.mediaQuery('xs', 'xxl')} {
      font-size: 13px;
    }
  }
  .actual-balance {
    @media ${sizes.mediaQuery('xs', 'xxl')} {
      font-size: 30px;
    }
  }
  .highcharts-title {
    font-size: 30px !important;
    line-height: 40px;
  }
`;

const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
  }
`;

const Dashboard = () => {
  const siteConfigurationInfo = useDispatch();
  const userTransactPermission = useDispatch();
  const accountProfileDispatch = useDispatch();
  const activityHistoryDispatch = useDispatch();
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const showMoveFunds = useSelector(
    (state) => {
      return state.siteInfo.allowOnlineTransact && 
      (state.programsClientsSlice.selectedClient.payload[0].current_balance!=="( $0.00 )" || state.accountProfileSlice.accountProfile.AccountDetails.length > 0)}
  );

  const [showButtonFunds, setshowButtonFunds] = useState(false);
  const [closeCancelWindow, setCloseCancelWindow] = useState(false);
  const [isMoveFundsVisible, setIsMoveFundsVisible] = useState(false);
  const handleFundsModalCancel = () => {
    setIsMoveFundsVisible(!isMoveFundsVisible);
  };
  const userAccess = useSelector((state) => state.siteInfo.userAccess);
  const activityHistory = useSelector(
    (state) => state.activityHistorySlice.ActivityHistory
  );
  const accountProfile = useSelector(
    (state) => state.accountProfileSlice.accountProfile
  );
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const secondary_color = useSelector((state) => state.siteInfo.secondaryColor);
  const [pendingActivities, setPendingActivities] = useState([]);
  const rgbaPrimary = useSelector((state) => state.siteInfo.rgbaPrimary);
  const programId = clientDetails.payload[0].programid;
  const ssnorTIN = clientDetails.payload[0].ssnortin;
  const fundgroup_Id = useSelector((state) => state.siteInfo.fundGroupID);
  const menuPermissionOptions = useDispatch();
  const menuPermissions = useSelector((state) =>
    state.menuPermissions.menuPermissionOptions.includes(25)
  );
  const [alertObject, setAlertObject] = useState([]);
  useEffect(() => {
    menuPermissionOptions(getMenuPermissions({ fundgroup_Id }));
  }, [fundgroup_Id]);

  useEffect(() => {
    siteConfigurationInfo(getsiteConfigurationInfo({ programId }));
    AddEonCachedAccounts(programId, ssnorTIN).then((response)=> {
      if (ssnorTIN != null && programId != null) {
        activityHistoryDispatch(
          getactivityHistory({ SSNorTIN: ssnorTIN, ProgramId: programId })
        );
      }
    });
    GetAccountProfileInfo(programId, ssnorTIN)
      .then((response) => {
        if (response.data.AccountDetails != null)
        {
          accountProfileDispatch(storeAccountProfile(response.data));
        }
        else{

          GetAsOfDate().then((response)=>{
            var accountProfile= {
              Balance: 0,
              AccountDetails: [],
              BalanceChangeValue: 0,
              BalanceChangePercent: 0,
              Balance_Date: response.data,
              ProductDetails: [],
            }
            accountProfileDispatch(storeAccountProfile( accountProfile));

          })
        }

      })
      .catch((error) => {
        console.log(error);
      });
  }, [siteConfigurationInfo, clientDetails]);

  useEffect(() => {
    if (showMoveFunds == true) {
      userTransactPermission(getUserPermissions({ ssnorTIN }));
    }
  }, [userTransactPermission, showMoveFunds, clientDetails]);

  useEffect(() => {
    if (showMoveFunds == true) {
      if(programId != 999){
        GetPendingActivity(programId, ssnorTIN, 5)
        .then((response) => {
          if (response.data != null) {
            setPendingActivities(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
    }
    setshowButtonFunds(showMoveFunds);
  }, [showMoveFunds, clientDetails, closeCancelWindow]);

  useEffect(() => {
    GetAlerts(programId)
      .then((response) => {
        if (response.data != null) {
          var resultantAlerts = [];
          response.data.map(function (item) {
            resultantAlerts.push({
              id: item.NewsAndAlertID,
              className: 'warning',
              message: item.Subject,
              description: null,
              bgColor: '#FFF6DC',
              color: `${theme.colors.black_secondary}`,
              icon: <InfoCircleFilled />,
              isAlertVisible: true,
              html: item.Body,
              dismissable: item.Dismissable,
              onViewDetailsClick: (vals) => {
                onViewDetailsClick(vals);
              },
            });
          });
          setAlertObject(resultantAlerts);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [programId]);

  const [isAlertModalVisible, setIsAlertModalVisible] = useState(false);
  const [content, setContent] = useState();
  const [dismissable, setDismissable] = useState(false);
  const onViewDetailsClick = (vals) => {
    setContent(vals);
    setIsAlertModalVisible(true);
  };

  //const [alertObject, setAlertObject] = useState({ ...defaultAlertObject });
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({
    Amount: 0,
  });
  const [programType] = useState('fixed-pool');
  const [isCancelTransaction, setIsCancelTransaction] = useState(false);
  const [isCancelSuccess, setIsCancelSuccess] = useState(false);

  const handleAction = (type, data, rowClick = false) => {
    if (type === 'cancel' && data.ReferenceID.startsWith('ENV')) {
      setSelectedRecord(GetEnvisionPendingActivityDetailsData(data));
      setIsCancelModalVisible(true);
      !rowClick && setIsCancelTransaction(true);
    } else if (type === 'cancel') {
      GetPendingActivityAdditionalInfo(
        data.ReferenceID,
        data.PendingTransactionTypeID
      )
        .then((response) => {
          if (response.data != null) {
            setSelectedRecord(
              GetPendingActivityDetailsData(data, response.data)
            );
            setIsCancelModalVisible(true);
            !rowClick && setIsCancelTransaction(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (type === 'details') {
      setIsDetailsModalVisible(!isDetailsModalVisible);
      setSelectedRecord(data);
    }
  };

  const handleModalCancel = (type) => {
    if (type === 'cancel') {
      setIsCancelModalVisible(!isCancelModalVisible);
      setIsCancelTransaction(false);
      if (isCancelSuccess) {
        //Fetch the pending transaction once the transaction is successfully reversed. Else status wont be reflected in the UI.
        setCloseCancelWindow(!closeCancelWindow);
      }
      setIsCancelSuccess(false);
    }
    if (type === 'details') {
      setIsDetailsModalVisible(!isDetailsModalVisible);
    }
    setSelectedRecord(null);
  };

  const handleAlertModalCancel = (id) => {
    DismissAlert(programId, id);
    let filteredAlert = alertObject.filter((item) => item.id !== id);
    setAlertObject(filteredAlert);
    setIsAlertModalVisible(!isAlertModalVisible);
  };

  return (
    <AppLayout alertObject={alertObject}>
      <ContentWrapper>
        <Banner bgcolor={primary_color} height="254px" />
        <Container>
          <Elevation top="-214px" index="3">
            <Box
              display="flex"
              justifycontent="space-between"
              alignitems="center"
              margin="0 0 40px 0"
            >
              <Text
                color={`${theme.colors.white}`}
                fontSize="36px"
                lineheight="44px"
                label=" Accounts Dashboard"
              />
              {config.EnableTransaction &&
              showButtonFunds == true &&
              userAccess ? (
                <OverrideButtonAnt
                  icon={false}
                  label="Move Funds"
                  type="button"
                  onClick={() => {
                    setIsMoveFundsVisible(!isMoveFundsVisible);
                  }}
                  linkTextColor={secondary_color}
                />
              ) : null}
            </Box>
            <Row gutter={20}>
              <Col span={16}>
                <Box
                  boxcolor={`${theme.colors.white}`}
                  radius="4px"
                  margin="0 0 22px 0"
                >
                  <Row>
                    <Col span={12}>
                      <Box padding="32px">
                        <p className="balance-summary-text">
                          Balance Summary as of{' '}
                          {moment(accountProfile.Balance_Date).format(
                            'MMM DD, YYYY'
                          )}
                          <Tooltip
                            placement="top"
                            title="Aggregated account balance as of the close of business the prior day"
                            overlayClassName="tooltip"
                          >
                            <InfoCircleOutlined style={{ cursor: 'pointer' }} />
                          </Tooltip>
                        </p>
                        <Text
                          color={`${theme.colors.green_tertiary}`}
                          fontSize="36px"
                          lineheight="44px"
                          margin="0 0 20px 0"
                          label={amountFormat(accountProfile.Balance)}
                          className="actual-balance"
                        />

                        <Box
                          border={`1px solid ${theme.colors.grey_fossil}`}
                          borderwidth="0 0 1px 0"
                          display="flex"
                          alignitems="center"
                          justifycontent="space-between"
                          flexwrap="wrap"
                          padding="0 0 10px 0"
                        >
                          <Text
                            color={`${theme.colors.grey_tertiary}`}
                            fontSize={`${theme.font_size.size_l}`}
                            lineheight="24px"
                            padding="4px 16px 4px 0"
                            label="MTD Balance $ Change"
                            className="resizable-text"
                          />
                          <Text
                            color={`${theme.colors.black_tertiary}`}
                            fontSize={`${theme.font_size.size_l}`}
                            lineheight="20px"
                            padding="8px 0 6px"
                            textalign="right"
                            label={amountFormat(
                              accountProfile.BalanceChangeValue
                            )}
                            display="flex"
                            alignitems="center"
                            icon={
                              accountProfile.BalanceChangeValue >= 0 ? (
                                <CaretUpOutlined
                                  style={{
                                    color: `${theme.colors.pie_green}`,
                                    marginRight: '6px',
                                  }}
                                />
                              ) : (
                                <CaretDownOutlined
                                  style={{
                                    color: `${theme.colors.pie_red}`,
                                    marginRight: '6px',
                                  }}
                                />
                              )
                            }
                          />
                        </Box>
                        <Box
                          display="flex"
                          alignitems="center"
                          justifycontent="space-between"
                          flexwrap="wrap"
                          padding="10px 0 0 0"
                        >
                          <Text
                            color={`${theme.colors.grey_tertiary}`}
                            fontSize={`${theme.font_size.size_l}`}
                            lineheight="24px"
                            padding="4px 16px 4px 0"
                            label="MTD Balance % Change"
                            className="resizable-text"
                          />
                          <Text
                            color={`${theme.colors.black_tertiary}`}
                            fontSize={`${theme.font_size.size_l}`}
                            lineheight="20px"
                            textalign="right"
                            display="flex"
                            alignitems="center"
                            icon={
                              accountProfile.BalanceChangeValue >= 0 ? (
                                <CaretUpOutlined
                                  style={{
                                    color: `${theme.colors.pie_green}`,
                                    marginRight: '6px',
                                  }}
                                />
                              ) : (
                                <CaretDownOutlined
                                  style={{
                                    color: `${theme.colors.pie_red}`,
                                    marginRight: '6px',
                                  }}
                                />
                              )
                            }
                            label={percentFormat(
                              accountProfile.BalanceChangePercent
                            )}
                            padding="8px 0 0"
                          />
                        </Box>
                      </Box>
                    </Col>
                    <Col span={12} className="divider-horizontal">
                      <DonutChartComponent
                        accountDetails={accountProfile}
                        programType={programType}
                      />
                    </Col>
                  </Row>
                </Box>
                <AccountsTable
                  accData={accountProfile.AccountDetails}
                  linkTextColor={rgbaPrimary}
                  programType={programType}
                />
              </Col>

              {showMoveFunds == true ? (
                <Col span={8}>
                  {programId != 67 ?
                  <CardAnt
                    cardContent={<ProductList linkTextColor={rgbaPrimary} />}
                    className="basic-card product-card "
                    title="Investments & Yields"
                  />:null}
                  <CardAnt
                    cardContent={
                      <ActivityList
                        pendingActivity={
                          pendingActivities
                            ? pendingActivities.length > 5
                              ? pendingActivities.slice(0, 5)
                              : pendingActivities
                            : []
                        }
                        handleAction={handleAction}
                        type={'pending'}
                      />
                    }
                    className="basic-card product-card pending-activity-card"
                    title="Pending Activity"
                  />
                </Col>
              ) : (
                <Col span={8}>
                  <CardAnt
                    cardContent={
                      <ActivityList
                        handleAction={handleAction}
                        pendingActivity={
                          activityHistory
                            ? activityHistory.length > 5
                              ? activityHistory.slice(0, 5)
                              : activityHistory
                            : []
                        }
                        type={'recent'}
                      />
                    }
                    className="basic-card product-card pending-activity-card"
                    title="Recent Activity"
                  />
                </Col>
              )}
            </Row>
          </Elevation>
        </Container>
        {isCancelModalVisible && (
          <Modal
            title={
              isCancelSuccess
                ? 'Transaction Cancelled'
                : isCancelTransaction && selectedRecord.Cancellable
                ? 'Cancel Transaction?'
                : 'Activity Details'
            }
            content={
              <CancelModalContent
                selectedRecord={selectedRecord}
                isCancelTransaction={isCancelTransaction}
                setIsCancelTransaction={setIsCancelTransaction}
                handleModalCancel={handleModalCancel}
                isCancelSuccess={isCancelSuccess}
                setIsCancelSuccess={setIsCancelSuccess}
              />
            }
            visible={isCancelModalVisible}
            onCancel={() => handleModalCancel('cancel')}
            footer={null}
            width={590}
            bodyStyle={{
              padding: '32px 32px 48px 32px',
            }}
            maskStyle={{
              backgroundColor: `${theme.rgba.modal}`,
              zIndex: '1051',
            }}
            centered={true}
          />
        )}
        {isDetailsModalVisible && (
          <Modal
            title="Activity Details"
            width={590}
            bodyStyle={{
              padding: '32px 32px 48px 32px',
            }}
            maskStyle={{
              backgroundColor: `${theme.rgba.modal}`,
              zIndex: '1051',
            }}
            content={<DetailsModalContent selectedRecord={selectedRecord} />}
            visible={isDetailsModalVisible}
            onCancel={() => handleModalCancel('details')}
            footer={null}
            centered={true}
          />
        )}

        {isAlertModalVisible && (
          <Modal
            title={content !== null ? ReactHtmlParser(content.message) : null}
            content={
              <AlertDetailsModalContent
                handleAlertModalCancel={handleAlertModalCancel}
                content={content}
              />
            }
            visible={isAlertModalVisible}
            onCancel={() => {
              setIsAlertModalVisible(!isAlertModalVisible);
            }}
            footer={null}
            width={590}
            bodyStyle={{
              padding: '32px 32px 48px 32px',
            }}
            maskStyle={{
              backgroundColor: `${theme.rgba.modal}`,
              zIndex: '1051',
            }}
            centered={true}
          />
        )}
        {isMoveFundsVisible && (
          <Modal
            title="Move Funds"
            width={590}
            bodyStyle={{
              padding: '0 0 64px 0',
            }}
            maskStyle={{
              backgroundColor: `${theme.rgba.modal}`,
              zIndex: '1051',
            }}
            content={<MoveFundsModal />}
            visible={isMoveFundsVisible}
            onCancel={() => handleFundsModalCancel()}
            footer={null}
            centered={true}
          />
        )}
      </ContentWrapper>
    </AppLayout>
  );
};

export default Dashboard;
