import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  filteredInvestmentTypes: [],
  inputSearchText: '',
  filteredAccounts: [],
};

const stopPaymentSlice = createSlice({
  name: 'stoppayment',
  initialState,
  reducers: {
    storeFilteredProductTypes: (state, { payload }) => {
      state.filteredInvestmentTypes = payload;
    },
    storeInputSearch: (state, { payload }) => {
      state.inputSearchText = payload;
    },
    storeFilteredAccounts: (state, { payload }) => {
      state.filteredAccounts = payload;
    },
  },
});

export const {
  storeFilteredProductTypes,
  storeInputSearch,
  storeFilteredAccounts,
} = stopPaymentSlice.actions;
export default stopPaymentSlice.reducer;
