import React from 'react';
import styled from 'styled-components';
import {
  theme,
  Text,
  TableComponent
} from '@pfmam/pfmam';
import { Box, Container } from '../../../styles/globalStyle';
import NoDataFound from '../../../common/components/noDataFound';


const Wrapper = styled.div`
  padding-top: 40px;
`;

const ClientManagementContainer = ({
  clientManagementInfo
}) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      align: 'left',
      className: 'sort-space-none',
      width: 200,
      render: (text, record) => (
        <Box display="flex" alignitems="flex-start">
          <Box padding="0 0 0 0px">
            <Text
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.black_tertiary}`}
              lineheight="24px"
              label={` ${record.Name}`}
            />
          </Box>
        </Box>
      ),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'left',
      className: 'sort-space-none',
      width: 200,
      render: (text, record) => (
        <Box display="flex" alignitems="flex-start">
          <Box padding="0 0 0 0px">
            <Text
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.black_tertiary}`}
              lineheight="24px"
              label={` ${record.PhoneNumber}`}
            />
          </Box>
        </Box>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'left',
      className: 'sort-space-none',
      width: 200,
      render: (text, record) => (
        <Box display="flex" alignitems="flex-start">
          <Box padding="0 0 0 0px">
            <Text
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.black_tertiary}`}
              lineheight="24px"
              label={` ${record.EmailAddress}`}
            />
          </Box>
        </Box>
      ),
    }
  ];
  return (

        <TableComponent
          className="table-primary"
          title={
            <Box
              display="flex"
              justifycontent="space-between"
              alignitems="center"
              padding="24px 48px 24px 32px"
              border={`1px solid ${theme.colors.grey_fossil}`}
              borderwidth="0 0 1px 0"
            >
              <Text
                label="Client Management Team"
                fontSize={`${theme.font_size.size_3xl}`}
                lineheight="32px"
                color={`${theme.colors.black_tertiary}`}
              /> 
            </Box>
          }
          columns={columns}
          dataSource={clientManagementInfo}
          locale={{
            emptyText: <NoDataFound  textalign="center" text={clientManagementInfo.length===0?"No Client management members":""}/>,
          }}
          showSorterTooltip={false}
         
        />
  );
};

export default ClientManagementContainer;
