/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Layout, Form, Modal as antmodal } from 'antd';
import { theme, Text, Tabs, Modal } from '@pfmam/pfmam';
import { Container, Box, Banner } from '../styles/globalStyle';
import AppLayout from '../common/layouts/appLayout';
import EditContactComponent from './components/editContactComponent';
import EditContactPermissionsComponent from './components/editContactPermissionsComponent';
import { GetThirdPartyOptions, ThirdPartyDomainCheck, GetPendingContactInformation, GetContactInformation, GetContactsAcctPortPermissions, UpdateContactInformation, IsWebUserExists, GetStatesWithDescription, GetNewContactCreatedInfo, GetNewContactPendingInformation, GetNewContactsPendingAcctPortPermissions, GetStatementRecipientProfilesCount, HasConnectUserAccessToAllPortfolios, EditingUserHasFuturePortfolioAccess, CanSubmitEditedContact, IsContactAssociatedWithAnyClient } from '../actions/actions';
import { useSelector } from 'react-redux';
import phoneFormatter from 'phone-formatter';
import LoadSpinner from '../common/components/LoadingSpinner/LoadSpinner';
import { current } from '@reduxjs/toolkit';
import ThirdPartyCheckComponentModal from './components/thirdPartyCheckComponentModal';
import { useHistory } from 'react-router-dom';
import { Prompt } from 'react-router';
const { Content } = Layout;

export const OverrideStyles = createGlobalStyle`
  .helpful-dropdown-menu {
    .ant-dropdown-menu {
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px rgba(0, 0, 0, 0.08);
      border-radius: 2px;
      padding: 4px 0;
      .ant-dropdown-menu-item {
        font-size: ${theme.font_size.size_l};
        line-height: 20px;
        color: ${theme.colors.black_secondary};
        padding: 8px 12px;
        &:hover {
          background: ${theme.colors.light_grey};
        }
        .anticon {
          margin-right: 8px;
        }
      }
      .
    }
  }
  .rc-virtual-list-scrollbar-thumb {
    background: rgb(0, 0, 0, 0.2) !important;
  }
  .profile-card {
    border-color: ${theme.colors.transparent} !important;
    .ant-card-head {
      padding: 0 32px;
      border-color: ${theme.colors.grey_fossil};
    }
    .ant-card-head-title {
      font-size: ${theme.font_size.size_3xl};
      line-height: 32px;
      padding: 24px 0;
    }
    .ant-card-body {
      padding: 32px;
    }
  }
  .statement-dropdown {
    width: 210px !important;
    .ant-dropdown-menu {
      padding: 0;
      box-shadow: ${theme.shadows.dropdown};
    }
    .ant-select-item {
      font-weight: normal;
      font-size: ${theme.font_size.size_l};
      color: ${theme.colors.black_secondary};
      line-height: 20px;
      padding: 8px 12px;
      &:hover {
        background: ${theme.colors.light_grey} !important;
      }
      &.ant-select-item-option-active,
      &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        :not(.ant-select-item-option-disabled) {
          background: ${theme.colors.transparent};
        }
      }
    }
  }
`;

const ContentWrapper = styled(Content)`
  .ant-table {
    border-radius: 4px;
    .ant-table-tbody {
      & > tr {
        cursor: pointer;
        &:hover {
          &.ant-table-row:not(.ant-table-expanded-row) {
            background: linear-gradient(
                0deg,
                rgba(23, 54, 93, 0.07),
                rgba(23, 54, 93, 0.07)
              ),
              ${theme.colors.white};
          }
        }
        & > td {
          vertical-align: top;
          padding: 20px 32px;
          &.ant-table-column-sort {
            background: ${theme.colors.transparent};
          }
        }
        &:last-child {
          & > td {
            border-bottom-color: ${theme.colors.transparent};
          }
        }
      }
    }
    .ant-table-thead {
      & > tr {
        & > th {
          background: ${theme.colors.light_grey};
          font-family: 'Inter';
          padding: 0 32px;
          font-weight: normal;
          line-height: 20px;
          &:not(:last-child) {
            &:not(.ant-table-selection-column) {
              &:not(.ant-table-row-expand-icon-cell) {
                &:not([colspan]) {
                  &::before {
                    background-color: ${theme.colors.transparent};
                  }
                }
              }
            }
          }
        }
      }
    }
    &.ant-table.ant-table-middle {
      .ant-table-thead {
        .ant-table-column-sorters {
          padding: 0;
          .ant-table-column-sorter-inner .anticon {
            color: ${theme.colors.grey_stone};
          }
          .ant-table-column-sorter-up.active,
          .ant-table-column-sorter-down.active {
            color: ${theme.colors.black_sorted};
          }
        }
        .ant-table-column-sorter-inner {
          margin-top: 0;
        }
      }
    }
    .sort-space-none {
      .ant-table-column-sorters {
        justify-content: flex-start;
      }
      .ant-table-column-title {
        flex: 0 0 auto;
      }
      &.flex-end {
        .ant-table-column-sorters {
          justify-content: flex-end;
        }
      }
    }
    .ant-avatar {
      min-width: 40px;
    }
  }
  .ant-table-title {
    padding: 0 !important;
  }
  .ant-table {
    &.ant-table-middle {
      .ant-table-tbody {
        .ant-table-wrapper {
          &:only-child {
            .ant-table {
              margin: -19px -32px;
            }
          }
        }
        .ant-table-container {
          .ant-table-tbody {
            .ant-table-row {
              .ant-table-cell {
                padding: 20px 32px;
                background-color: ${theme.colors.grey_faint};
              }
              &:hover {
                .ant-table-cell {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const EditContactContainer = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const clientDetails = useSelector((state) => state.programsClientsSlice.selectedClient);
  const connectContactId = useSelector((state) => state.userSlice.user.profile.sub);
  const editingUserContactId = props.location.state.contactId;
  const editingWebuserKey = props.location.state.loginid;
  const isNewContact = props.location.state.isNewContact;
  const fromTeamMembers = props.location.state.fromTeamMembers;
  const ssnorTIN = clientDetails.payload[0].ssnortin;
  const programId = clientDetails.payload[0].programid;
  //const [activeTab, setActiveTab] = useState('editcontact');
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  //const webuserAccessType = useSelector((state) => state.userSlice.webUserType);
  const [formDisabled, setFormDisabled] = useState(false);
  const [webuserkeyDisabled, setWebserKeyDisabled] = useState(false);
  const [contactInitialStateBeforeMask, setContactInitialStateBeforeMask] = useState(null);
  const [contactInitialState, setContactInitialState] = useState(null);
  const [permisionsInitialState, setPermissionsInitialState] = useState(null);
  const [isPendingContact, setIsPendingContact] = useState(false);
  const [pendingContactModifiedBy, setPendingContactModifiedBy] = useState(null);
  const [pendingContactId, setPendingContactId] = useState(0);
  const loggedInWebUserKey = useSelector((state) => state.userSlice.user.profile.name);
  const [loading, setloading] = useState(true);
  const [permissionsModalVisible, setPermissionsModalVisible] = useState(false);
  const [permissionsDataSource, setPermissionsDataSource] = useState([]);
  const [isStatementRecipient, setIsStatementRecipient] = useState(false);
  const [isConnectUsernameMandatory, setIsConnectUsernameMandatory] = useState(false);
  const [editingContactWebUserKey, setEditingContactWebUserKey] = useState(null);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [submitToPendingStatus, setSubmitToPendingStatus] = useState(false);
  const [selectedThirdPartyRecordOption, setThirdPartySelectedRecordOption] = useState();
  const [isThirdPartyModalVisible, setIsThirdPartyModalVisible] = useState(false);
  const [isFinalConfirmation, setIsFinalConfirmation] = useState(false);
  const [disableStatmentRecipientModule, setDisableStatementRecipientMoule] = useState(false);
  const [statementRecipientProfilesCount, setStatementRecipientProfilesCount] = useState(0);
  const [thirdpartyOptionsData, setThirdPartyOptionsData] = useState([]);
  const [hasContactinfoChanged, setHasContactinfoChanged] = useState(false);
  const [hasAcctPortPermissionChanged, setHasAcctPortPermissionChanged] = useState(false);
  const [hasStatementRecipientPermissionsChanged, setHasStatementRecipientPermissionsChanged] = useState(false);
  const [hasStatementRecipientAddressChanged, setHasStatementRecipientAddressChanged] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [canShowClientLevelPortfolio, setCanShowClientLevelPortfolio] = useState(false);
  const [clientLevelPortfolioInitialPermission, setClientLevelPortfolioInitialPermission] = useState(0);
  const [clientLevelPortfolioPermission, setClientLevelPortfolioPermission] = useState(0);
  const [hasClientLevelPortfolioPermissionChanged, setHasClientLevelPortfolioPermissionChanged] = useState(false);
  const [isContactHasClientsAssociated, setIsContactHasClientsAssociated] = useState(false);
  const [statementRecipientDiabledMessage, setStatementRecipientDisabledMessage] = useState(null);
  const [canSubmitEditedContact, setCanSubmitEditedContact] = useState(false);
  const [contactAssociatedToAnyAcctPort, setContactAssociatedToAnyAcctPort] = useState(false);
  const [promptEnabled, setPromptEnabled] = useState(true);
  const [isTouched, setIsTouched] = useState(false);
  const [hasCheckVerifierPermission, setHasCheckVerifierPermission] = useState(false);
  const [checkVerifierCheckedPrimaryDisclaimer, setCheckVerifierCheckedPrimaryDisclaimer] = useState('I certify that the entered contact information is correct. Upon submitting, I acknowledge that PFM Asset Management reserves the right to request proof of authority in the form of election certification, board minutes, resolutions, fiduciary trusts agreement, etc. when updating permissions. I understand it is my sole responsibility to promptly notify PFM Asset Management of any changes to authorized Contacts. ');
  const [checkVerifierCheckedSecondaryDisclaimer, setCheckVerifierCheckedSecondaryDisclaimer] = useState('I agree that with the assignation of Check Verifier: (1) the Investor is responsible for designating check verifiers and reviewing checks presented against the account in accordance with the terms and conditions contained in the Pennsylvania Local Government Investment Trust Information Statement as well as any additional updates that may occur; (2) the Investor shall notify PLGIT Client Services of any checks that should not be paid by 12pm on the business day following the presentment of checks; (3) no action taken by the Investor by 12pm on the business day following the presentment of checks shall be understood as an affirmation that the checks presented should be paid.');
  const [checkVerifierUnCheckedDisclaimer, setCheckVerifierUnCheckedDisclaimer] = useState('I certify that the entered contact information is correct. Upon submitting, I acknowledge that PFM Asset Management reserves the right to request proof of authority in the form of election certification, board minutes, resolutions, fiduciary trusts agreement, etc. when updating permissions. I understand it is my sole responsibility to promptly notify PFM Asset Management of any changes to authorized Contacts.');

  const setFormValues = (values) => {
    return new Promise((resolve) => {
      form.setFieldsValue(values);
      setTimeout(() => {
        resolve();
      }, 0);
    });
  };

  const getFormValues = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(form.getFieldsValue());
      }, 0);
    });
  }

  useEffect(() => {

    if (isNewContact == 0) {
      IsContactAssociatedWithAnyClient(editingUserContactId).then((response) => {
        if (response.data[0].IsAssociated) {
          setIsContactHasClientsAssociated(true);
          GetEditingContactInformation(true);
        } else {
          GetEditingContactInformation(false);
        }
      }).catch((err) => {
        handleError(err);
      });
    } else {
      setIsContactHasClientsAssociated(false);
      GetEditingContactInformation(false);
    }

    if (programId == 999) {
      HasConnectUserAccessToAllPortfolios(ssnorTIN).then((response) => {
        if (response.data[0].HasAllPortfolioAccess) {
          setCanShowClientLevelPortfolio(true);
        }
      }).catch((err) => {
        handleError(err);
      });
    }

    GetThirdPartyOptions().then((response) => {
      if (response.data.Table.length > 0) {
        setThirdPartyOptionsData(response.data.Table)
      }
    }).catch((err) => {
      handleError(err);
    });

    GetStatementRecipientProfilesCount(editingUserContactId, ssnorTIN, isNewContact).then((response) => {
      setStatementRecipientProfilesCount(response.data[0].SRCount);
      if (response.data[0].SRCount > 1) {
        setDisableStatementRecipientMoule(true);
        setStatementRecipientDisabledMessage('There are multiple Statement Addresses on file, please reach out to Client Services Group to make any changes.');
      }
    }).catch((err) => {
      handleError(err);
    });


    //Get all US states
    GetStatesWithDescription().then((response) => {
      const stateList = [];
      if (response.data != null && response.data.length > 0) {
        response.data.map((val) => {
          const option = {};
          option.label = val.Description;
          option.value = val.Code;
          stateList.push(option);
        });
        setStates(stateList);
      }
    }).catch((err) => {
      handleError(err);
    });

    //Check if Contact is in pending
    if (isNewContact == 0) {
      GetPendingContactInformation(editingUserContactId).then((response) => {
        if (response.data.length > 0) {
          setPendingContactModifiedBy(response.data[0].ModifiedUserName);
          setIsPendingContact(true);
          setPendingContactId(response.data[0].PendingContactId);
          if (loggedInWebUserKey.toUpperCase() !== response.data[0].ModifiedUserName.toUpperCase()) {
            setFormDisabled(true);
          }
        }
        setloading(false);
      }).catch((err) => {
        setloading(false);
        handleError(err);
      });
    } else {
      GetNewContactCreatedInfo(editingUserContactId).then((response) => {
        if (response.data.length > 0) {
          setPendingContactModifiedBy(response.data[0].ModifiedUserName);
          setIsPendingContact(true);
          setPendingContactId(response.data[0].PendingContactId);
          if (loggedInWebUserKey.toUpperCase() !== response.data[0].ModifiedUserName.toUpperCase()) {
            setFormDisabled(true);
          }
        }
        setloading(false);
      }).catch((err) => {
        setloading(false);
        handleError(err);
      });
    }



  }, []);

  const GetEditingContactInformation = (isContactAsociatedWithAnyClient) => {
    //Get the editing contact information
    if (isNewContact == 0) {
      GetContactInformation(editingUserContactId, ssnorTIN)
        .then(async (response) => {
          if (response.data != null && response.data.length > 0) {
            setSelectedState(response.data[0].State);
            setEditingContactWebUserKey(response.data[0].LoginName);
            setContactInitialStateBeforeMask(response.data[0]);
            const profile = modifyContactProfile(response.data[0], isContactAsociatedWithAnyClient);
            await setFormValues(profile[0]);
            setContactInitialState(await getFormValues());
            if (profile[0].mvarLoginName != '' && profile[0].mvarLoginName != null) {
              setWebserKeyDisabled(true);
            }

            DoesEditingUserHasFuturePortfolioAccess(profile[0].mvarLoginName == null ? '' : profile[0].mvarLoginName);
            GetContactAccountportfolioPermissions(profile[0].mvarLoginName == null ? '' : profile[0].mvarLoginName);
            IsContactAssociatedToAnyAccountsOrPortfoliosOrStatementRecipient(profile[0].mvarLoginName == null ? '' : profile[0].mvarLoginName);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      GetNewContactPendingInformation(editingUserContactId, ssnorTIN)
        .then(async (response) => {
          if (response.data != null && response.data.length > 0) {
            setSelectedState(response.data[0].State);
            setEditingContactWebUserKey(response.data[0].LoginName);
            setContactInitialStateBeforeMask(response.data[0]);
            const profile = modifyContactProfile(response.data[0], isContactAsociatedWithAnyClient);
            await setFormValues(profile[0]);
            setContactInitialState(await getFormValues());
            if (profile[0].mvarLoginName != '' && profile[0].mvarLoginName != null) {
              setWebserKeyDisabled(true);
            }

            DoesEditingUserHasFuturePortfolioAccess(profile[0].mvarLoginName == null ? '' : profile[0].mvarLoginName);
            GetContactAccountportfolioPermissions(profile[0].mvarLoginName == null ? '' : profile[0].mvarLoginName);
            IsContactAssociatedToAnyAccountsOrPortfoliosOrStatementRecipient(profile[0].mvarLoginName == null ? '' : profile[0].mvarLoginName);
          }
        }).catch((error) => {
          handleError(error);
        });
    }
  };

  const IsContactAssociatedToAnyAccountsOrPortfoliosOrStatementRecipient = (webuserkey) => {
    CanSubmitEditedContact(editingUserContactId, webuserkey, ssnorTIN, programId, isNewContact).then((response) => {
      if (response.data[0].CanSubmitContact) {
        setContactAssociatedToAnyAcctPort(true);
        setCanSubmitEditedContact(true);
      }
    }).catch((err) => {
      handleError(err);
    });
  };

  const DoesEditingUserHasFuturePortfolioAccess = (webuserkey) => {
    EditingUserHasFuturePortfolioAccess(editingUserContactId, webuserkey, ssnorTIN, isNewContact).then((response) => {
      setClientLevelPortfolioInitialPermission(response.data[0].HasFuturePortfolioAccess);
      setClientLevelPortfolioPermission(response.data[0].HasFuturePortfolioAccess);
    }).catch((err) => {
      handleError(err);
    });
  };

  const GetContactAccountportfolioPermissions = (webuserkey) => {
    if (isNewContact == 0) {
      GetContactsAcctPortPermissions(connectContactId, editingUserContactId, ssnorTIN, webuserkey, programId).then((response) => {
        if (response.data.Table.length > 0) {
          setPermissionsInitialState(response.data.Table);
          setPermissionsDataSource(response.data.Table);
          SetIfStatementRecipient(response.data.Table);

          var isConnectUsernameMandatory = false;
          response.data.Table.map(items => {
            if (programId !== 999) {
              if (items.IsViewReports || items.IsEnterTransactions || items.IsModifyBankingInstructions || items.IsCloseAccounts || items.IsManageUsers || items.IsCheckVerifier) {
                isConnectUsernameMandatory = true;
              }
              if (items.IsCheckVerifier) {
                setHasCheckVerifierPermission(true);
              }
            } else {
              if (items.IsViewReports) {
                isConnectUsernameMandatory = true
              }
            }
          });
          setIsConnectUsernameMandatory(isConnectUsernameMandatory);
        }
      }).catch((err) => {
        handleError(err);
      });
    } else {
      GetNewContactsPendingAcctPortPermissions(connectContactId, editingUserContactId, ssnorTIN, webuserkey, programId).then((response) => {
        if (response.data.Table.length > 0) {
          setPermissionsInitialState(response.data.Table);
          setPermissionsDataSource(response.data.Table);
          SetIfStatementRecipient(response.data.Table);

          var isConnectUsernameMandatory = false;
          response.data.Table.map(items => {
            if (programId !== 999) {
              if (items.IsViewReports || items.IsEnterTransactions || items.IsModifyBankingInstructions || items.IsCloseAccounts || items.IsManageUsers || items.IsCheckVerifier) {
                isConnectUsernameMandatory = true;
              }
            } else {
              if (items.IsViewReports) {
                isConnectUsernameMandatory = true
              }
            }
          });
          setIsConnectUsernameMandatory(isConnectUsernameMandatory);
        }
      }).catch((err) => {
        handleError(err);
      });
    }
  };

  const SetIfStatementRecipient = (data) => {
    var isStatementReipient = false;
    data.map((item) => {
      if (item.IsStatementRecipient === 1) {
        isStatementReipient = true;
      }
    });
    setIsStatementRecipient(isStatementReipient);
  }

  const onSubmit = () => {
    setPromptEnabled(false);
    setFormDisabled(true);
    setSubmitted(true);
    var localhasContactinfoChanged = false;
    var localhasAcctPortPermissionChanged = false;
    var localhasStatementRecipientPermissionsChanged = false;
    var localhasStatementRecipientAddressChanged = false;
    var localhasClientLevelPortfolioPermissionChanged = false;

    const currentContactInformationValues = form.getFieldsValue();

    if (programId == 999) {
      if (clientLevelPortfolioInitialPermission !== clientLevelPortfolioPermission) {
        localhasClientLevelPortfolioPermissionChanged = true;
        setHasClientLevelPortfolioPermissionChanged(true);
      }
    }

    //Check if Account or Portfolios permssions have changed
    if (JSON.stringify(permissionsDataSource) !== JSON.stringify(permisionsInitialState)) {
      localhasAcctPortPermissionChanged = true;
      setHasAcctPortPermissionChanged(localhasAcctPortPermissionChanged);
    }

    const initialStatementRecipientsState = permisionsInitialState.map(({ IsStatementRecipient }) => ({ IsStatementRecipient }));
    const finalStatementRecipeintsState = permissionsDataSource.map(({ IsStatementRecipient }) => ({ IsStatementRecipient }));

    //Check if StatementRecipient permissions has changes
    if (JSON.stringify(finalStatementRecipeintsState) !== JSON.stringify(initialStatementRecipientsState)) {
      localhasStatementRecipientPermissionsChanged = true;
      setHasStatementRecipientPermissionsChanged(localhasStatementRecipientPermissionsChanged);
    }

    if (statementRecipientProfilesCount < 2 && (currentContactInformationValues['mvarAgency'] !== contactInitialState['mvarAgency']
      || currentContactInformationValues['mvarAddressOne'] !== contactInitialState['mvarAddressOne']
      || currentContactInformationValues['mvarAddressTwo'] !== contactInitialState['mvarAddressTwo']
      || currentContactInformationValues['mvarState'] !== contactInitialState['mvarState']
      || currentContactInformationValues['mvarCity'] !== contactInitialState['mvarCity']
      || currentContactInformationValues['mvarZipCode'] !== contactInitialState['mvarZipCode'])) {
      localhasStatementRecipientAddressChanged = true;
      setHasStatementRecipientAddressChanged(localhasStatementRecipientAddressChanged);
    }

    //Check if Contact information has changes
    if (Object.keys(currentContactInformationValues).some(key => (currentContactInformationValues[key] !== null ? currentContactInformationValues[key] : '') !== (contactInitialState[key] !== null ? contactInitialState[key] : ''))) {
      localhasContactinfoChanged = true;
      setHasContactinfoChanged(localhasContactinfoChanged);
    }

    if (((currentContactInformationValues.mvarLoginName != null && currentContactInformationValues.mvarLoginName != '') || isConnectUsernameMandatory) && editingContactWebUserKey == null) {
      IsWebUserExists(currentContactInformationValues.mvarLoginName).then((response) => {
        if (response.data) {
          antmodal.warning({
            title: 'Entered Connect username already exists. Please try another username.',
            //content: 'Entered Connect username already exists. Please try another username.',
            okText: 'Ok',
            onOk: handleDuplicateWebuserOk()
          });
        } else {
          //Check if Contact EmailAddress information has changes
          if (Object.keys(currentContactInformationValues).some(key => (currentContactInformationValues['mvarEmailAddress'] !== contactInitialState['mvarEmailAddress']))) {
            ThirdPartyDomainCheck(ssnorTIN, currentContactInformationValues.mvarEmailAddress).then((response) => {
              if (response.data == true) {
                if (isContactHasClientsAssociated && fromTeamMembers == 0) {
                  currentContactInformationValues.mvarEmailAddress = contactInitialStateBeforeMask.EmailAddress;
                  currentContactInformationValues.mvarPhoneNumber = contactInitialStateBeforeMask.PhoneNumber;
                  currentContactInformationValues.mvarMobileNumber = contactInitialStateBeforeMask.MobileNumber;
                  currentContactInformationValues.ext = contactInitialStateBeforeMask.PhoneAddionalInfo;
                }
                //Call the api to update contact information
                UpdateContactInformation(isPendingContact,
                  pendingContactId,
                  currentContactInformationValues,
                  permissionsDataSource,
                  localhasContactinfoChanged,
                  localhasAcctPortPermissionChanged,
                  true,
                  programId,
                  ssnorTIN,
                  editingUserContactId,
                  statementRecipientProfilesCount,
                  localhasStatementRecipientPermissionsChanged,
                  localhasStatementRecipientAddressChanged,
                  false,
                  0,
                  localhasClientLevelPortfolioPermissionChanged,
                  clientLevelPortfolioPermission).then((response) => {
                    setSubmitted(false);
                    setIsFinalConfirmation(true);
                  }).catch((err) => {
                    handleError(err);
                  });
              } else {
                setSubmitted(false);
                setIsThirdPartyModalVisible(true);
              }
            });
          } else {
            if (isContactHasClientsAssociated && fromTeamMembers == 0) {
              currentContactInformationValues.mvarEmailAddress = contactInitialStateBeforeMask.EmailAddress;
              currentContactInformationValues.mvarPhoneNumber = contactInitialStateBeforeMask.PhoneNumber;
              currentContactInformationValues.mvarMobileNumber = contactInitialStateBeforeMask.MobileNumber;
              currentContactInformationValues.ext = contactInitialStateBeforeMask.PhoneAddionalInfo;
            }
            //Call the api to update contact information
            UpdateContactInformation(isPendingContact,
              pendingContactId,
              currentContactInformationValues,
              permissionsDataSource,
              localhasContactinfoChanged,
              localhasAcctPortPermissionChanged,
              true,
              programId,
              ssnorTIN,
              editingUserContactId,
              statementRecipientProfilesCount,
              localhasStatementRecipientPermissionsChanged,
              localhasStatementRecipientAddressChanged,
              false,
              0,
              localhasClientLevelPortfolioPermissionChanged,
              clientLevelPortfolioPermission).then((response) => {
                setSubmitted(false);
                setIsFinalConfirmation(true);
              }).catch((err) => {
                handleError(err);
              });
          }
        }
      }).catch((err) => {
        handleError(err);
      });
    } else {
      //Check if Contact EmailAddress information has changes
      if (Object.keys(currentContactInformationValues).some(key => (currentContactInformationValues['mvarEmailAddress'] !== contactInitialState['mvarEmailAddress']))) {
        ThirdPartyDomainCheck(ssnorTIN, currentContactInformationValues.mvarEmailAddress).then((response) => {
          if (response.data == true) {
            if (isContactHasClientsAssociated && fromTeamMembers == 0) {
              currentContactInformationValues.mvarEmailAddress = contactInitialStateBeforeMask.EmailAddress;
              currentContactInformationValues.mvarPhoneNumber = contactInitialStateBeforeMask.PhoneNumber;
              currentContactInformationValues.mvarMobileNumber = contactInitialStateBeforeMask.MobileNumber;
              currentContactInformationValues.ext = contactInitialStateBeforeMask.PhoneAddionalInfo;
            }
            //Call the api to update contact information
            UpdateContactInformation(isPendingContact,
              pendingContactId,
              currentContactInformationValues,
              permissionsDataSource,
              localhasContactinfoChanged,
              localhasAcctPortPermissionChanged,
              false,
              programId,
              ssnorTIN,
              editingUserContactId,
              statementRecipientProfilesCount,
              localhasStatementRecipientPermissionsChanged,
              localhasStatementRecipientAddressChanged,
              false,
              0,
              localhasClientLevelPortfolioPermissionChanged,
              clientLevelPortfolioPermission).then((response) => {
                setSubmitted(false);
                setIsFinalConfirmation(true);
              }).catch((err) => {
                handleError(err);
              });
          }
          else {
            setSubmitted(false);
            setIsThirdPartyModalVisible(true);
          }
        }
        ).catch((err) => {
          handleError(err);
        });
      } else {
        if (isContactHasClientsAssociated && fromTeamMembers == 0) {
          currentContactInformationValues.mvarEmailAddress = contactInitialStateBeforeMask.EmailAddress;
          currentContactInformationValues.mvarPhoneNumber = contactInitialStateBeforeMask.PhoneNumber;
          currentContactInformationValues.mvarMobileNumber = contactInitialStateBeforeMask.MobileNumber;
          currentContactInformationValues.ext = contactInitialStateBeforeMask.PhoneAddionalInfo;
        }
        UpdateContactInformation(isPendingContact,
          pendingContactId,
          currentContactInformationValues,
          permissionsDataSource,
          localhasContactinfoChanged,
          localhasAcctPortPermissionChanged,
          false,
          programId,
          ssnorTIN,
          editingUserContactId,
          statementRecipientProfilesCount,
          localhasStatementRecipientPermissionsChanged,
          localhasStatementRecipientAddressChanged,
          false,
          0,
          localhasClientLevelPortfolioPermissionChanged,
          clientLevelPortfolioPermission).then((response) => {
            setSubmitted(false);
            setIsFinalConfirmation(true);
          }).catch((err) => {
            handleError(err);
          });
      }
    }
    //currentContactInformationValues.mvarLoginName.toUpperCase()
  };

  const handleError = (err) => {
    console.log(err);
    antmodal.warning({
      title: 'There was as an error processing the request. Please try again after sometime.',
      okText: 'Ok',
      onOk: () => {
        history.push("/dashboard");
      },
    });
  };

  const handleDuplicateWebuserOk = () => {
    setPromptEnabled(true);
    setFormDisabled(false);
    setSubmitted(false);
  };

  const maskEmail = (email, isContactAsociatedWithAnyClient) => {
    if (isContactAsociatedWithAnyClient && fromTeamMembers == 0) {
      if (email != null && email != '') {
        return email.replace(/^(.{2})[^@]+/, "$1xxxxxxxxxx");
      } else {
        return email;
      }
    } else {
      return email;
    }

  };

  const maskPhone = (value, isContactAsociatedWithAnyClient) => {
    if (isContactAsociatedWithAnyClient && fromTeamMembers == 0) {
      return "xxxxxxxxxx";
    } else {
      return value
    }
  };

  const maskExt = (value, isContactAsociatedWithAnyClient) => {
    if (isContactAsociatedWithAnyClient && fromTeamMembers == 0) {
      return "xxx";
    } else {
      return value
    }
  };

  function modifyContactProfile(profile, isContactAsociatedWithAnyClient) {
    var contactProfile = [];
    contactProfile.push({
      ext: maskExt(profile.PhoneAddionalInfo, isContactAsociatedWithAnyClient),
      mvarEmailAddress: maskEmail(profile.EmailAddress, isContactAsociatedWithAnyClient),
      mvarFaxNumber:
        profile.FaxNumber != '' && profile.FaxNumber != null
          ? phoneFormatter.format(profile.FaxNumber, '(NNN) NNN-NNNN')
          : null,
      mvarPhoneNumber:
        profile.PhoneNumber != '' && profile.PhoneNumber != null
          ? phoneFormatter.format(maskPhone(profile.PhoneNumber, isContactAsociatedWithAnyClient), '(NNN) NNN-NNNN')
          : maskPhone(null, isContactAsociatedWithAnyClient),
      mvarMobileNumber:
        profile.MobileNumber != '' && profile.MobileNumber != null
          ? phoneFormatter.format(maskPhone(profile.MobileNumber, isContactAsociatedWithAnyClient), '(NNN) NNN-NNNN')
          : maskPhone(null, isContactAsociatedWithAnyClient),
      mvarSalutation: profile.Salutation,
      mvarTitle: profile.Title,
      mvarFirstName: profile.FirstName,
      mvarLastName: profile.LastName,
      mvarLoginName: profile.LoginName,
      mvarIsTrustee: profile.IsTrustee,
      mvarAddressOne: profile.AddressOne,
      mvarAddressTwo: profile.AddressTwo,
      mvarAgency: profile.Agency,
      mvarState: profile.State,
      mvarCity: profile.City,
      mvarZipCode: profile.ZipCode
    });
    return contactProfile;
  }
  const getDetailsModalTitle = () => {
    return 'Verify Email domain';
  };

  const handleModalCancel = () => {
    setIsThirdPartyModalVisible(!isThirdPartyModalVisible);
    setThirdPartySelectedRecordOption(null);
    setSubmitted(false);
    setFormDisabled(false);
    setPromptEnabled(true);
  };

  const handleConfirmationModalCancel = () => {
    setIsFinalConfirmation(false);
    //navigate to Team Members screen.
    history.push("/settings", { showTeamMembers: true });
  };

  const handleThirdPartySubmit = (ThirdPartyCheckedOptionId) => {
    const currentContactInformationValues = form.getFieldsValue();
    if (ThirdPartyCheckedOptionId == 2) {
      currentContactInformationValues.mvarIsTrustee = 'Yes';
    }
    if (isContactHasClientsAssociated && fromTeamMembers == 0) {
      currentContactInformationValues.mvarEmailAddress = contactInitialStateBeforeMask.EmailAddress;
      currentContactInformationValues.mvarPhoneNumber = contactInitialStateBeforeMask.PhoneNumber;
      currentContactInformationValues.mvarMobileNumber = contactInitialStateBeforeMask.MobileNumber;
      currentContactInformationValues.ext = contactInitialStateBeforeMask.PhoneAddionalInfo;
    }
    if (((currentContactInformationValues.mvarLoginName != null && currentContactInformationValues.mvarLoginName != '') || isConnectUsernameMandatory) && editingContactWebUserKey == null) {
      UpdateContactInformation(isPendingContact,
        pendingContactId,
        currentContactInformationValues,
        permissionsDataSource,
        ThirdPartyCheckedOptionId == 2 ? true : hasContactinfoChanged,
        hasAcctPortPermissionChanged,
        true,
        programId,
        ssnorTIN,
        editingUserContactId,
        statementRecipientProfilesCount,
        hasStatementRecipientPermissionsChanged,
        hasStatementRecipientAddressChanged,
        true,
        ThirdPartyCheckedOptionId,
        hasClientLevelPortfolioPermissionChanged,
        clientLevelPortfolioPermission).then((response) => {
          setIsFinalConfirmation(true);
        }).catch((err) => {
          handleError(err);
        });
    } else {
      UpdateContactInformation(isPendingContact,
        pendingContactId,
        currentContactInformationValues,
        permissionsDataSource,
        ThirdPartyCheckedOptionId == 2 ? true : hasContactinfoChanged,
        hasAcctPortPermissionChanged,
        false,
        programId,
        ssnorTIN,
        editingUserContactId,
        statementRecipientProfilesCount,
        hasStatementRecipientPermissionsChanged,
        hasStatementRecipientAddressChanged,
        true,
        ThirdPartyCheckedOptionId,
        hasClientLevelPortfolioPermissionChanged,
        clientLevelPortfolioPermission).then((response) => {
          setIsFinalConfirmation(true);
        }).catch((err) => {
          handleError(err);
        });
    }

    setIsThirdPartyModalVisible(false);
    setIsFinalConfirmation(true);
  };

  const OnPermissionsOkClick = () => {
    //If user has atleast one permissions other than statement recipient, then connect username s mandatory.
    var isConnectUsernameMandatory = false;
    var hasUserGivenViewReportsOrSPAccess = false;
    var hasCheckVerifierPermission = false;
    permissionsDataSource.map(items => {
      if (programId !== 999) {
        if (items.IsViewReports || items.IsEnterTransactions || items.IsModifyBankingInstructions || items.IsCloseAccounts || items.IsManageUsers || items.IsCheckVerifier) {
          isConnectUsernameMandatory = true;
        }
      } else {
        if (items.IsViewReports) {
          isConnectUsernameMandatory = true
        }
      }


      if (items.IsViewReports || items.IsStatementRecipient) {
        hasUserGivenViewReportsOrSPAccess = true;
      }

      if (items.IsCheckVerifier) {
        hasCheckVerifierPermission = true;
      }

    });

    if (clientLevelPortfolioPermission) {
      hasUserGivenViewReportsOrSPAccess = true;
      isConnectUsernameMandatory = true;
    }

    if (!contactAssociatedToAnyAcctPort || isNewContact == 1) {
      setCanSubmitEditedContact(hasUserGivenViewReportsOrSPAccess);
    }

    setHasCheckVerifierPermission(hasCheckVerifierPermission);
    setIsConnectUsernameMandatory(isConnectUsernameMandatory);
    SetIfStatementRecipient(permissionsDataSource);
    setPermissionsModalVisible(false);
  };

  const OnPermissionsCancelClick = () => {
    var isConnectUsernameMandatory = false;
    var hasUserGivenViewReportsOrSPAccess = false;
    var hasCheckVerifierPermission = false;
    permisionsInitialState.map(items => {
      if (programId !== 999) {
        if (items.IsViewReports || items.IsEnterTransactions || items.IsModifyBankingInstructions || items.IsCloseAccounts || items.IsManageUsers || items.IsCheckVerifier) {
          isConnectUsernameMandatory = true;
        }
      } else {
        if (items.IsViewReports) {
          isConnectUsernameMandatory = true
        }
      }

      if (items.IsViewReports || items.IsStatementRecipient) {
        hasUserGivenViewReportsOrSPAccess = true;
      }

      if (items.IsCheckVerifier) {
        hasCheckVerifierPermission = true;
      }

    });

    if (clientLevelPortfolioPermission) {
      hasUserGivenViewReportsOrSPAccess = true;
      isConnectUsernameMandatory = true;
    }

    if (!contactAssociatedToAnyAcctPort) {
      setCanSubmitEditedContact(hasUserGivenViewReportsOrSPAccess);
    }

    setHasCheckVerifierPermission(hasCheckVerifierPermission);
    setIsConnectUsernameMandatory(isConnectUsernameMandatory);
    setPermissionsDataSource(permisionsInitialState);
    setClientLevelPortfolioPermission(clientLevelPortfolioInitialPermission);
    SetIfStatementRecipient(permisionsInitialState);
    setPermissionsModalVisible(false);
  };

  return (
    <AppLayout>
      <Prompt
        when={isTouched && promptEnabled}
        message={JSON.stringify({
          header: 'Discard Changes?',
          content:
            'Changes have not been submitted, leaving this page will result in no change of contact or permissions. Are you sure you want to leave this page?',
        })}
      />
      <OverrideStyles />
      <ContentWrapper>
        <Banner bgcolor={primary_color} height="124px">
          <Box
            display="flex"
            alignitems="center"
            height="100%"
            minwidth="100%"
            width="100%"
            zindex="3"
            position="relative"
            padding="44px 0 36px 0"
          >
            <Container>
              <Box
                display="flex"
                justifycontent="space-between"
                alignitems="flex-start"
                margin="0"
              >
                <Box flex="1">
                  <Text
                    color={`${theme.colors.white}`}
                    fontSize="36px"
                    lineheight="44px"
                    label="Edit Contact"
                  />
                </Box>
              </Box>
            </Container>
          </Box>
        </Banner>
        <>
          {loading === false ? (
            <>
              <EditContactComponent
                form={form}
                onSubmit={onSubmit}
                formDisabled={formDisabled}
                webuserkeyDisabled={webuserkeyDisabled}
                setPermissionsModalVisible={setPermissionsModalVisible}
                isStatementRecipient={isStatementRecipient}
                isConnectUsernameMandatory={isConnectUsernameMandatory}
                states={states}
                selectedState={selectedState}
                disableStatmentRecipientModule={disableStatmentRecipientModule}
                submitted={submitted}
                isContactHasClientsAssociated={isContactHasClientsAssociated}
                statementRecipientDiabledMessage={statementRecipientDiabledMessage}
                canSubmitEditedContact={canSubmitEditedContact}
                setIsTouched={setIsTouched}
                hasCheckVerifierPermission={hasCheckVerifierPermission}
                checkVerifierCheckedPrimaryDisclaimer={checkVerifierCheckedPrimaryDisclaimer}
                checkVerifierCheckedSecondaryDisclaimer={checkVerifierCheckedSecondaryDisclaimer}
                checkVerifierUnCheckedDisclaimer={checkVerifierUnCheckedDisclaimer}
                fromTeamMembers={fromTeamMembers}
              />

              <Modal
                // title="Permissions"
                width="auto"
                onOk={() => setPermissionsModalVisible(!permissionsModalVisible)}
                bodyStyle={{
                  // padding: '32px 32px 34px 32px',
                }}
                maskStyle={{
                  backgroundColor: `${theme.rgba.modal}`,
                  //zIndex: '1051',
                }}
                content={
                  <EditContactPermissionsComponent
                    permissionsDataSource={permissionsDataSource}
                    setPermissionsDataSource={setPermissionsDataSource}
                    OnPermissionsOkClick={OnPermissionsOkClick}
                    formDisabled={formDisabled}
                    OnPermissionsCancelClick={OnPermissionsCancelClick}
                    disableStatmentRecipientModule={disableStatmentRecipientModule}
                    canShowClientLevelPortfolio={canShowClientLevelPortfolio}
                    clientLevelPortfolioPermission={clientLevelPortfolioPermission}
                    setClientLevelPortfolioPermission={setClientLevelPortfolioPermission}
                    setIsTouched={setIsTouched}
                  />
                }
                visible={permissionsModalVisible}
                closable={false}
                footer={null}
                centered={true}
              />
            </>

          ) : (
            <LoadSpinner></LoadSpinner>
          )}

        </>
        <>
        </>
      </ContentWrapper>
      {isThirdPartyModalVisible && thirdpartyOptionsData.length > 0 && (
        <Modal
          title={getDetailsModalTitle()}
          width={800}
          bodyStyle={{
            padding: '32px 32px 34px 32px',
          }}

          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          content={<ThirdPartyCheckComponentModal
            selectedThirdPartyRecordOption={selectedThirdPartyRecordOption}
            setThirdPartySelectedRecordOption={setThirdPartySelectedRecordOption}
            handleModalCancel={handleModalCancel}
            handleThirdPartySubmit={handleThirdPartySubmit}
            thirdpartyOptionsData={thirdpartyOptionsData}
            isThirdPartyModalVisible={isThirdPartyModalVisible}
          />}
          visible={isThirdPartyModalVisible}
          onCancel={() => handleModalCancel()}
          footer={null}
          centered={true}
        />

      )}
      {isFinalConfirmation && (
        <Modal
          title={'Contact Changes Saved-Pending Approval'}
          width={590}
          bodyStyle={{
            padding: '32px 32px 34px 32px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          content={'Changes have been sent to PFM Asset Management for approval. Allow up to 48 hours for processing.'}
          visible={isFinalConfirmation}
          onCancel={handleConfirmationModalCancel}
          okText={'Ok'}
          footer={null}
          centered={true}
          closable={true}
          cancelButtonProps={{ style: { display: 'none' } }}
          maskClosable={false}
        />

      )}
    </AppLayout>

  );
};

export default EditContactContainer;
