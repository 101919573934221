import React from 'react';
import { Layout } from 'antd';
import {
  theme,CardAnt,Text,
} from '@pfmam/pfmam';
import HeaderComponent from '../common/components/Header';
import { Container,Box } from '../styles/globalStyle';
import RenderProfileForm from './components/renderProfileFormComponent';
import styled, { createGlobalStyle } from 'styled-components';
const { Content } = Layout;

const OverrideStyle = createGlobalStyle`
  .ant-form-item-control-input-content {
    & > span {
      width: 100%;
    }
    .primary.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 52px;
        width: 100%;
        background: ${theme.colors.white};
        color: ${theme.colors.black_secondary};
        border: 1px solid ${theme.colors.grey};
        padding: 14px 20px;
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: 20px;
        }
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
      .ant-select-arrow {
        font-size: ${theme.font_size.size_l};
        margin-top: 0;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
      }
      &.ant-select-focused {
        .ant-select-selector {
          box-shadow: ${theme.shadows.input_focus};
          border-color: ${theme.colors.blue_primay};
        }
          .ant-select-arrow {
          color: ${theme.colors.black_secondary};
        }
      } 
    }
  }
  
  .form-select-dropdown {
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      .ant-select-item {
        &.ant-select-item-group {
          color: ${theme.colors.grey_tertiary};
          min-height: 16px;
          line-height: 16px;
          padding: 6px 12px;
          &:hover {
            background: none;
          }
        }
      }
      .ant-select-item-option-selected,
      .ant-select-item-option-active {
        &:not(.ant-select-item-option-disabled) {
          background: ${theme.colors.white};
          font-weight: normal;
        }
      }
      .ant-select-item {
        min-height: 40px;
        line-height: 24px;
        padding: 8px 12px;
        font-size: ${theme.font_size.size_xl};
        color: ${theme.colors.black_secondary};
        font-family: 'Inter';
        &:hover {
        background: ${theme.colors.light_grey};
        font-family: 'Inter';
      }
      .ant-form-item-required span {
        display: none;
      }
      
      .ant-form-item-required:after {
        display: none;
      }
    }
  }
`;
const LayoutWrapper = styled(Layout)`
  background-color: ${theme.colors.light_grey};
  min-height: 100vh;
`;

const Wrpaper = styled(Content)`
padding-top: 32px;
padding-bottom: 32px;
`;

const RestrictToProfileScreen = (props) => {
  return (
    <>
  <LayoutWrapper>
   <HeaderComponent  timelineHeader={true} hasClients={false}  />
      <Wrpaper>
      <OverrideStyle />
        <Container>
        <CardAnt
          cardContent={<RenderProfileForm {...props}    />}
          className="profile-card basic-card"
          title={
              <Text
                label={"Incomplete Profile Information"}
                fontSize={`${theme.font_size.size_3xl}`}
                lineheight="32px"
                padding="0 425px 0"
                color={`${theme.colors.pie_red}`}
              />
          }
        />
        </Container>
      </Wrpaper>
    </LayoutWrapper>
    </>

  );
};

export default RestrictToProfileScreen;
