/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Row, Col, Form,Tooltip } from 'antd';
import {
  theme,
  CardAnt,
  ButtonAnt,
  InputComponent,
  SelectComponent,
  Text
} from '@pfmam/pfmam';
import { Container, Box } from '../../styles/globalStyle';
import { CaretDownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { MaskedInput } from 'antd-mask-input';


const ButtonWrapper = styled.div`
  .anticon-check-circle {
    margin-left: 30px;
    margin-top: 2px;
  }
`;
const OverrideStyle = createGlobalStyle`
  .ant-form-item-control-input-content {
    & > span {
      width: 100%;
    }
    .primary.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 52px;
        width: 100%;
        background: ${theme.colors.white};
        color: ${theme.colors.black_secondary};
        border: 1px solid ${theme.colors.grey};
        padding: 14px 20px;
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: 20px;
        }
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
      .ant-select-arrow .anticon:not(.ant-select-suffix) {
        pointer-events: none;
    }
      .ant-select-arrow {
        font-size: ${theme.font_size.size_l};
        margin-top: 0;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
      }
      &.ant-select-focused {
        .ant-select-selector {
          box-shadow: ${theme.shadows.input_focus};
          border-color: ${theme.colors.blue_primay};
        }
          .ant-select-arrow {
          color: ${theme.colors.black_secondary};
        }
      } 
    }
  }

  
  .form-select-dropdown {
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      .ant-select-item {
        &.ant-select-item-group {
          color: ${theme.colors.grey_tertiary};
          min-height: 16px;
          line-height: 16px;
          padding: 6px 12px;
          &:hover {
            background: none;
          }
        }
      }
      .ant-select-item-option-selected,
      .ant-select-item-option-active {
        &:not(.ant-select-item-option-disabled) {
          background: ${theme.colors.white};
          font-weight: normal;
        }
      }
      .ant-select-item {
        min-height: 40px;
        line-height: 24px;
        padding: 8px 12px;
        font-size: ${theme.font_size.size_xl};
        color: ${theme.colors.black_secondary};
        font-family: 'Inter';
        &:hover {
        background: ${theme.colors.light_grey};
        font-family: 'Inter';
      }
    }
  }
`;

const Wrapper = styled.div`
  padding-top: 32px;
`;

const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
  }
`;

const OverrideButtonAntPermissions = styled(ButtonAnt)`
  &&& {
    background: #e0b31f;
    color: black;
    font-weight:bold;
  }
`;



const CreateContactComponent = (props) => {
  const { form, onSubmit, formDisabled, setPermissionsModalVisible, isStatementRecipient, isConnectUsernameMandatory, selectedState, states, submitted, canSubmitEditedContact, setIsTouched, hasCheckVerifierPermission, checkVerifierCheckedPrimaryDisclaimer, checkVerifierCheckedSecondaryDisclaimer, checkVerifierUnCheckedDisclaimer } = props;

  // const contactId = useSelector((state) => state.userSlice.user.profile.sub);
  // const webUserKey = useSelector((state) => state.userSlice.user.profile.name);
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const clientDetails = useSelector((state) => state.programsClientsSlice.selectedClient);
  const programId = clientDetails.payload[0].programid;

  const handleBlur = (e) => {
    const value = e.target.value;
    const name = e.target.getAttribute('data-name');
    const trimmedValue = value.trim();
    form.setFieldsValue({ [name]: trimmedValue });
    if (name == 'mvarEmailAddress') {
      form.validateFields([name]);
    }
  }
  const RenderLabel = () => {
    return (
      <>
        {'Is this contact a Bank Trustee?'}
        <Tooltip
          placement="top"
          title="A Bank Trustee is a contact with a financial institution tasked with controlling the investment account. Most common for accounts related to bond proceeds or escrows."
          overlayClassName="tooltip"
        >
           <InfoCircleOutlined
            style={{ color: `${theme.colors.blue_primay}` }}
          />
        </Tooltip>
      </>
    );
  };
  const validatePhoneNumber = (_, value) => {
    if(value){
      var unmaskedValue = value.replace(/\D/g, '');
      if (unmaskedValue.length == 10) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Please enter a valid 10 digit number'));
    } else {
      return Promise.reject(new Error('Phone Number Required'));
    }
   
  }

  const validateMobileNumber = (_, value) => {
    if(value) {
      var unmaskedValue = value.replace(/\D/g, '');
      if (unmaskedValue.length == 0 || unmaskedValue.length == 10) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Please enter a valid 10 digit number'));
    } else {
      return Promise.resolve();
    }
    
  }

  return (
    <Wrapper>
      <OverrideStyle />
      <Container>
        <CardAnt
          cardContent={
            <Form
              form={form}
              onFieldsChange={() => {
                setIsTouched(true);
              }}
              name="basic"
              layout="vertical"
              onFinish={onSubmit}
              requiredMark={true}
              className="forms"
            >
              <Row gutter={40}>
                <Col span={12}>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item label="First Name" name="mvarFirstName"
                        rules={[
                          {
                            required: true,
                            message: 'First Name Required',
                          },
                        ]}
                      >
                        <InputComponent
                          type="default"
                          maxLength={15}
                          placeholder="Add First Name"
                          disabled={formDisabled}
                          data-name="mvarFirstName"
                          onBlur={handleBlur}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Last Name" name="mvarLastName" rules={[
                        {
                          required: true,
                          message: 'Last Name Required',
                        },
                      ]}>
                        <InputComponent
                          type="default"
                          maxLength={25}
                          placeholder="Add Last Name"
                          disabled={formDisabled}
                          data-name="mvarLastName"
                          onBlur={handleBlur}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Form.Item label="Title" name="mvarTitle" rules={[
                    {
                      required: true,
                      message: 'Title is Required',
                    },
                  ]}>
                    <InputComponent maxLength={25} type="default" placeholder="Add Title" disabled={formDisabled} data-name="mvarTitle" onBlur={handleBlur} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={40}>
                <Col span={12}>

                  <Form.Item
                    label="Email Address"
                    name="mvarEmailAddress"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: 'Email Address Required',
                      },
                    ]}
                  >
                    <InputComponent maxLength={80} type="default" placeholder="Add Email Address" disabled={formDisabled} data-name="mvarEmailAddress" onBlur={handleBlur} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Row gutter={24}>
                    <Col span={15}>
                      <Form.Item label="Phone Number" name="mvarPhoneNumber"
                        rules={[
                          {
                            required: true,
                            validator: validatePhoneNumber,
                          },
                        ]}
                      >
                        <MaskedInput
                          mask="(111) 111-1111"
                          placeholder="(000) 000-0000"
                          disabled={formDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item label="Ext" name="ext">
                        <InputComponent maxLength={25} type="default" placeholder="000" disabled={formDisabled} data-name="ext" onBlur={handleBlur} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row gutter={40}>
                <Col span={12}>
                  <Form.Item label="Mobile Number" name="mvarMobileNumber"
                    rules={[
                      {
                        validator: validateMobileNumber,
                      },
                    ]}
                  >
                    <MaskedInput mask="(111) 111-1111" placeholder="(000) 000-0000" disabled={formDisabled} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Connect Username"
                    name="mvarLoginName"
                    rules={[
                      {
                        required: isConnectUsernameMandatory,
                        message: 'Connect Username is Required',
                      },
                    ]}>
                    <InputComponent maxLength={25} type="default" disabled={formDisabled} data-name="mvarLoginName" onBlur={handleBlur} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={40}>
                <Col span={12}>
                  <RenderLabel/>
                  <Form.Item label="" name="mvarIsTrustee">
                    <SelectComponent
                      defaultValue="No"
                      disabled={formDisabled}
                      option={[
                        {
                          label: 'Yes',
                          value: 'Yes',
                        },
                        {
                          label: 'No',
                          value: 'No',
                        },
                      ]}
                      placeholder=""
                      suffixIcon={<CaretDownOutlined />}
                      dropdownClassName="form-select-dropdown"
                      bordered={true}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Box display="flex" justifycontent="flex-end">
                    <OverrideButtonAntPermissions label="Edit Permissions" disabled={formDisabled} type="Secondary" onClick={() => setPermissionsModalVisible(true)} />
                  </Box>
                </Col>
              </Row>

              <br></br>
              <>
                <span hidden={isStatementRecipient ? false : true}>Statement Recipient Mailing Address</span>

                <Row gutter={40}>
                  <Col span={12}>
                    <Form.Item label="Agency (If Applicable)" name="mvarAgency" hidden={isStatementRecipient ? false : true}>
                      <InputComponent maxLength={40} type="default" disabled={formDisabled} data-name="mvarAgency" onBlur={handleBlur} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Address One"
                      name="mvarAddressOne"
                      hidden={isStatementRecipient ? false : true}
                      rules={[
                        {
                          required: isStatementRecipient,
                          message: 'Address One is Required',
                        },
                      ]}>
                      <InputComponent maxLength={50} type="default" disabled={formDisabled} data-name="mvarAddressOne" onBlur={handleBlur} />
                    </Form.Item>
                  </Col>
                </Row>



                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item label="Address Two" name="mvarAddressTwo" hidden={isStatementRecipient ? false : true}>
                      <InputComponent maxLength={50} type="default" disabled={formDisabled} data-name="mvarAddressTwo" onBlur={handleBlur} />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label="City"
                      name="mvarCity"
                      hidden={isStatementRecipient ? false : true}
                      rules={[
                        {
                          required: isStatementRecipient,
                          message: 'City is Required',
                        },
                      ]}>
                      <InputComponent maxLength={25} type="default" disabled={formDisabled} data-name="mvarCity" onBlur={handleBlur} />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label="State"
                      name="mvarState"
                      hidden={isStatementRecipient ? false : true}
                      rules={[
                        {
                          required: isStatementRecipient,
                          message: 'State is Required',
                        },
                      ]}>
                      {/* <InputComponent type="default" /> */}
                      <SelectComponent
                        disabled={formDisabled}
                        option={states}
                        placeholder="Select State"
                        suffixIcon={<CaretDownOutlined />}
                        dropdownClassName="form-select-dropdown"
                        bordered={true}
                        value={selectedState}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label="Zip Code"
                      name="mvarZipCode"
                      hidden={isStatementRecipient ? false : true}
                      rules={[
                        {
                          required: isStatementRecipient,
                          message: 'Zip Code is Required',
                        },
                      ]}>
                      <InputComponent maxLength={12} type="default" disabled={formDisabled} data-name="mvarZipCode" onBlur={handleBlur} />
                    </Form.Item>
                  </Col>
                </Row>
              </>

              <br></br>
              <Text
                fontSize="16px"
                lineheight="18px"
                label={hasCheckVerifierPermission ? checkVerifierCheckedPrimaryDisclaimer : checkVerifierUnCheckedDisclaimer}
              />
              <br></br>
              {hasCheckVerifierPermission && (
                <Text
                  fontSize="16px"
                  lineheight="18px"
                  label={checkVerifierCheckedSecondaryDisclaimer}
                />
              )}

              <br></br>

              <Box display="flex" justifycontent="center">
                <>
                  <OverrideButtonAnt
                    label="Submit Contact"
                    type="primary"
                    htmlType="submit"
                    linkTextColor={primary_color}
                    disabled={canSubmitEditedContact == false ? true : formDisabled}
                    loading={submitted}
                  />

                </>
              </Box>

            </Form>


          }
          className="profile-card basic-card"
          title="Contact Information"
        />

      </Container>
    </Wrapper>
  );
};

export default CreateContactComponent;
