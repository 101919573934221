import React from 'react';
import styled from 'styled-components';
import { theme, Text } from '@pfmam/pfmam';
import { Box } from '../../styles/globalStyle';

const ListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.grey};
  transition: 0.3s;
  &:last-of-type {
    border-bottom: 0;
  }
  .strong {
    font-family: 'Inter Semibold';
  }
`;
const ListWrapperComponent = ({
  label,
  value,
  className,
  maxwidth = '215px',
  minwidth = '215px',
}) => {
  return (
    <ListWrapper>
      <Box
        minwidth={minwidth}
        maxwidth={maxwidth}
        textalign="left"
        className={className}
      >
        <Text
          label={label}
          padding="14px 0 10px 0"
          color={`${theme.colors.grey_tertiary}`}
          fontSize={`${theme.font_size.size_xl}`}
          lineheight="24px"
        />
      </Box>
      <Box
        flex="1"
        textalign="right"
        padding="0 0 0 16px"
        className={className}
      >
        <Text
          label={value}
          padding="14px 0 14px 0"
          color={`${theme.colors.black_secondary}`}
          fontSize={`${theme.font_size.size_xl}`}
          lineheight="20px"
        />
      </Box>
    </ListWrapper>
  );
};

export default ListWrapperComponent;
