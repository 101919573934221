/* eslint-disable array-callback-return */
import React from 'react';
import styled, { css } from 'styled-components';
import { theme, Text, ButtonAnt } from '@pfmam/pfmam';
import { Space } from 'antd';
import { Box } from '../../../styles/globalStyle';
import ListWrapperComponent from '../../../common/components/listWrapper';
import phoneFormatter from 'phone-formatter';

const ListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.grey};
  transition: 0.3s;
  &:last-of-type {
    border-bottom: 0;
  }
  .strong {
    font-family: 'Inter Semibold';
  }
`;
const ButtonWrapper = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
`;

const ReviewTeamMember = ({
  form,
  memberData,
  isTeamMemberCompleted,
  setIsTeamMemberCompleted,
  setIsReviewTeamMmber,
  handleBack,
  accDetails,
}) => {
  let accountsArray = {};

  const displayPermissions = () => {
    accDetails &&
      accDetails.map((acc) => {
        accountsArray[acc] = [];
      });

    accDetails &&
      accDetails.length &&
      accDetails.map((acc) => {
        memberData.permissions &&
          memberData.permissions.length &&
          memberData.permissions.map((permission) => {
            if (
              permission.accounts.length &&
              permission.accounts.includes(acc)
            ) {
              accountsArray[acc].push(permission.permission);
            }
          });
      });
    return (
      <Box>
        {Object.keys(accountsArray).length &&
          Object.keys(accountsArray).map((acc) => {
            if (accountsArray[acc].length > 0) {
              return (
                <ListWrapper>
                  <Box minwidth="215px" maxwidth="215px" textalign="left">
                    <Text
                      label={acc}
                      padding="14px 0 10px 0"
                      color={`${theme.colors.grey_tertiary}`}
                      fontSize={`${theme.font_size.size_xl}`}
                      lineheight="24px"
                    />
                  </Box>
                  <Box flex="1" textalign="right" padding="14px 0">
                    {accountsArray[acc].map((permission) => {
                      return (
                        <Text
                          label={permission}
                          color={`${theme.colors.black_secondary}`}
                          fontSize={`${theme.font_size.size_xl}`}
                          margin="0 0 4px 0"
                        />
                      );
                    })}
                  </Box>
                </ListWrapper>
              );
            }
          })}
      </Box>
    );
  };

  return (
    <>
      <Box>
        <ListWrapperComponent
          label="Salutation"
          value={memberData.salutation}
        />
        <ListWrapperComponent label="First Name" value={memberData.firstName} />
        <ListWrapperComponent label="Last Name" value={memberData.lastName} />
        <ListWrapperComponent label="Email Address" value={memberData.email} />
        <ListWrapperComponent
          label="Title"
          value={memberData.title ? memberData.title : '- - - -'}
        />
        <ListWrapperComponent
          label="Phone Number"
          value={
            memberData.phone
              ? phoneFormatter.format(memberData.phone, '(NNN) NNN-NNNN') +
                `${memberData.ext ? ' +' + memberData.ext : ''}`
              : '- - - -'
          }
        />
        <ListWrapperComponent
          label="Mobile Number"
          value={
            memberData.mobile
              ? phoneFormatter.format(memberData.mobile, '(NNN) NNN-NNNN')
              : '- - - -'
          }
        />
        <ListWrapperComponent
          label="Fax Number"
          value={
            memberData.fax
              ? phoneFormatter.format(memberData.fax, '(NNN) NNN-NNNN')
              : '- - - -'
          }
        />
      </Box>
      <Text
        label="Permissions"
        padding="48px 0 12px 0"
        color={`${theme.colors.clean_black}`}
        fontSize={`${theme.font_size.size_2xl}`}
        lineheight="28px"
      />
      {displayPermissions()}

      <ButtonWrapper size={12} spacing>
        <ButtonAnt
          label={
            isTeamMemberCompleted ? 'Back to Organizational Settings' : 'Back'
          }
          type="secondary"
          htmlType="button"
          onClick={() => {
            if (isTeamMemberCompleted) {
              form.resetFields();
              handleBack();
            } else {
              setIsReviewTeamMmber(false);
            }
          }}
        />
        {!isTeamMemberCompleted && (
          <ButtonAnt
            label={'Submit'}
            type="primary"
            htmlType="submit"
            onClick={() => setIsTeamMemberCompleted(true)}
          />
        )}
      </ButtonWrapper>
    </>
  );
};

export default ReviewTeamMember;
