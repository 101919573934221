/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  theme,
  ButtonAnt,
  Text,
  Modal,
} from '@pfmam/pfmam';
import { Space, Tree, Switch, Divider, Tooltip } from 'antd';
import { Box } from '../../styles/globalStyle';
import StopPaymentMultiSelect from './stopPayments/stopPaymentMultiSelect';
import ExpandInputComponent from '../../common/components/expandInput';
import CheckboxImg from '../../assets/images/checkbox-blue.svg';
import CheckboxCheckedImg from '../../assets/images/checkbox-blue-checked.svg';
import { DownloadOutlined } from '@ant-design/icons';
import accountData from '../../data/AccountDetails.json';
import DownloadModal from '../../common/components/downloadModal';
import { useDispatch } from 'react-redux';
import {
  storeInputSearch,
  storeFilteredAccounts,
} from '../../services/stopPaymentSlice';

const FilterCol = styled(Space)`
  flex: 1;
  white-space: nowrap;
  overflow: ${(props) => (props.overflow ? 'hidden' : '')};
`;

const ProductTreeFilter = styled(Tree)`
  .ant-tree-treenode {
    padding: 8px 0;
  }
  .ant-tree-switcher {
    height: 20px;
    width: 20px;
    line-height: 20px;
    margin-right: 3px;
    margin-left: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    .anticon {
      font-size: ${theme.font_size.size_l};
      color: ${theme.colors.grey_tertiary};
    }
  }
  .ant-tree-checkbox {
    margin-top: 2px;
    z-index: 2;
    &:hover {
    }
    &.ant-tree-checkbox-checked {
      &:after {
        border-color: ${(props) => props.checkboxCheckedColor};
      }
      .ant-tree-checkbox-inner {
        background-color: ${(props) => props.checkboxCheckedColor};
        border-color: ${(props) => props.checkboxCheckedColor};
        &::after {
          left: 20%;
          height: 10px;
          transform: rotate(40deg) scale(1) translate(-50%, -50%);
        }
      }
    }
  }
  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
    border-color: ${(props) => props.checkboxCheckedColor};
  }
  .ant-tree-checkbox-indeterminate {
    .ant-tree-checkbox-inner {
      &::after {
        background-color: ${(props) => props.checkboxCheckedColor};
      }
    }
  }
  .ant-tree-node-content-wrapper {
    color: ${theme.colors.black_secondary};
    line-height: 22px;
    min-height: 22px;
    padding: 0 2px;
    margin-top: -1px;
    z-index: 2;
    &:hover {
      background-color: ${theme.colors.transparent};
    }
    &.ant-tree-node-selected {
      background-color: ${theme.colors.transparent};
    }
  }
  .ant-tree-switcher-noop {
    & + {
      .ant-tree-checkbox {
        margin-left: 2px;
      }
    }
  }
`;
const SwitchWrapper = styled.li`
  cursor: default !important;
  border-bottom: 1px solid ${theme.colors.pearl_river};
  padding: 9px 12px;
  .ant-switch {
    background-color: ${theme.colors.grey_stone};
    &.ant-switch-checked {
      background-color: ${theme.colors.blue_primay};
      &:focus {
        box-shadow: 0 0 0 2px ${theme.rgba.gradient_blue};
      }
    }
    [ant-click-animating-without-extra-node='true']::after,
    .ant-click-animating-node {
      box-shadow: 0 0 0 2px ${theme.rgba.gradient_blue};
    }
  }
`;

const StopPaymentRenderFilters = ({
  isGlobalPage,
  unfilteredData,
  filteredData,
  showInactiveAcc,
  setShowInactiveAcc,
}) => {
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState(null);
  const [accountSelectedValues, setAccountSelectedValues] = useState([]);
  const [accountFilterClass, setAccountFilterClass] = useState('hide');
  const [accDetails, setAccDetails] = useState([]);
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [inputClassName, setInputClassName] = useState('hide');
  const [tooltip, setTooltip] = useState(false);

  const buildTreeData = (data) => {
    const list = [];
    data.map((element) => {
      const treeNode = {
        title: element.name,
        key: element.id,
        isParentNode: 'children' in element,
      };
      if ('children' in element) {
        treeNode.children = buildTreeData(element.children);
      }
      list.push(treeNode);
    });
    return list;
  };

  const handleAccountChange = (name) => {
    if (accountSelectedValues.includes(name)) {
      const values = accountSelectedValues.filter(
        (selectedValue) => selectedValue !== name
      );
      setAccountSelectedValues([...values]);
      dispatch(storeFilteredAccounts([...values]));
    } else {
      setAccountSelectedValues([...accountSelectedValues, name]);
      dispatch(storeFilteredAccounts([...accountSelectedValues, name]));
    }
  };

  const getAccountFilterOptions = () => {
    const filterOptions = [];
    const uniqueAccounts = [];
    filterOptions.push(
      <SwitchWrapper key={'switch'}>
        <Switch
          checked={showInactiveAcc}
          onChange={(checked) => setShowInactiveAcc(checked)}
        />
        <Text
          label="Include Inactive Accounts"
          fontSize={`${theme.font_size.size_l}`}
          color={`${theme.colors.black_secondary}`}
          lineheight="20px"
          margin="0 0 0 10px"
        />
      </SwitchWrapper>
    );
    unfilteredData.map((acc) => {
      if (uniqueAccounts.indexOf(acc.Account) === -1) {
        uniqueAccounts.push(acc.Account);
        filterOptions.push(
          <li
            key={acc.AccountUniqueID}
            onClick={() => handleAccountChange(acc.Account)}
          >
            <img
              src={
                accountSelectedValues.includes(acc.Account)
                  ? CheckboxCheckedImg
                  : CheckboxImg
              }
              alt="Checkbox"
            />{' '}
            {acc.Account}
          </li>
        );
      }
    });

    return filterOptions;
  };
  const onInputSubmit = (value) => {
    dispatch(storeInputSearch(value.trim()));
  };
  const showModal = () => {
    setIsDownloadModalVisible(true);
  };

  const handleCancel = () => {
    setIsDownloadModalVisible(false);
  };

  return (
    <>
      <Box
        margin="0 0 24px 0"
        display="flex"
        justifycontent="space-between"
        alignitems="center"
        minwidth="100%"
        width="100%"
        className={inputClassName === 'hide' ? '' : 'expanded'}
      >
        <FilterCol size={8} overflow={inputClassName === 'show'}>
          <ExpandInputComponent
            inputData={searchData}
            setInputData={setSearchData}
            onInputSubmit={onInputSubmit}
            inputClassName={inputClassName}
            setInputClassName={setInputClassName}
          />
          {isGlobalPage && (
            <StopPaymentMultiSelect
              storeArray={'clearAllAccounts'}
              filterClass={accountFilterClass}
              setFilterClass={setAccountFilterClass}
              selectedValues={accountSelectedValues}
              setSelectedValues={setAccountSelectedValues}
              options={getAccountFilterOptions()}
              buttonLabel={'Account'}
              height={'150px'}
              selectedBgColor={`${theme.colors.blue_primay}`}
              selectedColor={`${theme.colors.white}`}
            />
          )}
        </FilterCol>
        <Box display="flex" alignitems="center">
          <Divider type="vertical" className="filter-divider" />
          <Tooltip
            placement="top"
            title="Download"
            overlayClassName="tooltip"
            defaultVisible={inputClassName === 'hide' ? false : true}
            visible={tooltip}
          >
            <ButtonAnt
              icon={<DownloadOutlined />}
              label={inputClassName === 'hide' ? 'Download' : ''}
              type="secondary"
              onClick={showModal}
              onMouseEnter={() =>
                inputClassName === 'hide' ? setTooltip(false) : setTooltip(true)
              }
              onMouseLeave={() => setTooltip(false)}
            />
          </Tooltip>
        </Box>
      </Box>
      <Modal
        title="Download File Type"
        width={590}
        bodyStyle={{
          padding: '32px 32px 34px 32px',
        }}
        maskStyle={{
          backgroundColor: `${theme.rgba.modal}`,
          zIndex: '1051',
        }}
        content={
          <DownloadModal
            downloadData={filteredData}
            onCancel={handleCancel}
            type="StopPayment"
          />
        }
        visible={isDownloadModalVisible}
        onCancel={handleCancel}
        footer={null}
        centered={true}
      />
    </>
  );
};

export default StopPaymentRenderFilters;
