import React, { useEffect, useState } from 'react';
import { theme, Text, ButtonAnt } from '@pfmam/pfmam';
import { Box, Container } from '../../styles/globalStyle';
import { getContactLastPasswordChange } from '../../actions/actions';
import { useSelector } from 'react-redux';
import config from '../../config';

const PasswordComponent = () => {
  const chngepwdUrl = config.ChangePasswordRedirectUrl;
  const [days, setDays] = useState(
    'You haven’t updated your password in 0 days'
  );
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const _progID = clientDetails.payload[0].programid;
  useEffect(() => {
    getContactLastPasswordChange()
      .then((response) => {
        if (response.data != null) {
          setDays(
            'You haven’t updated your password in ' + response.data + ' days'
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const routeChange = () => {
    var query = new URLSearchParams();
    query.append('prgId', _progID);
    query.append('clientName', 'Connect');
    window.location.href = chngepwdUrl + query.toString();
  };
  //
  return (
    <Container>
      <Box padding="24px 0 0">
        <Box
          boxcolor={`${theme.colors.white}`}
          padding="24px 32px"
          display="flex"
          justifycontent="space-between"
          alignitems="center"
        >
          <Box>
            <Text
              label="Password"
              fontSize={`${theme.font_size.size_3xl}`}
              color={`${theme.colors.black_secondary}`}
              lineheight="32px"
            />
            <Text
              label={days}
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.grey_tertiary}`}
              lineheight="24px"
              margin="4px 0 0"
            />
          </Box>
          <ButtonAnt
            type="secondary"
            label="Update Password"
            onClick={routeChange}
            icon={false}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default PasswordComponent;
