/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import {
  theme,
  Text,
  Modal,
  ButtonAnt,
} from '@pfmam/pfmam';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Container, Box, Banner, Anchor } from '../styles/globalStyle';
import AppLayout from '../common/layouts/appLayout';
import StopPaymentsDetailsModal from './components/stopPayments/stopPaymentsDetailsModal';
import StopPaymentRenderFilters from './components/stopPaymentRenderFilter';
import RenderFilters from './components/renderFilters';
import StopPaymentsTable from './components/stopPayments/stopPaymentsTable';
import InitiateStopPaymentsComponent from './components/stopPayments/initiateStopPayments';
import { useLocation, useHistory } from 'react-router-dom';
import { GetStopPaymentDetails } from '../actions/actions';
import { useSelector, useDispatch } from 'react-redux';
import {
  storeFilteredProductTypes,
  storeInputSearch,
  storeFilteredAccounts,
} from '../services/stopPaymentSlice';
import config from '../config';
const { Content } = Layout;

const TableWrapper = styled.div`
  .ant-table {
    border-radius: 4px 4px 0 0;
    .ant-table-tbody {
      & > tr {
        &:hover {
          &.ant-table-row:not(.ant-table-expanded-row) {
            background: linear-gradient(
                0deg,
                rgba(23, 54, 93, 0.07),
                rgba(23, 54, 93, 0.07)
              ),
              ${theme.colors.white};
          }
        }
        cursor: pointer;
        & > td {
          vertical-align: top;
          padding: 20px 32px;
          &.ant-table-column-sort {
            background: ${theme.colors.transparent};
          }
        }
        &:last-child {
          td {
            border-bottom-color: ${theme.colors.transparent};
          }
        }
        &.inactive-accounts {
          & > td {
            background-color: ${theme.colors.grey_faint};
          }
        }
      }
    }
    .ant-table-thead {
      & > tr {
        & > th {
          background: ${theme.colors.light_grey};
          font-family: 'Inter';
          padding: 0 32px;
          font-weight: normal;
          line-height: 20px;
          &:not(:last-child) {
            &:not(.ant-table-selection-column) {
              &:not(.ant-table-row-expand-icon-cell) {
                &:not([colspan]) {
                  &::before {
                    background-color: ${theme.colors.transparent};
                  }
                }
              }
            }
          }
        }
      }
    }
    &.ant-table.ant-table-middle {
      .ant-table-thead {
        .ant-table-column-sorters {
          padding: 0;
          .ant-table-column-sorter-inner .anticon {
            color: ${theme.colors.grey_stone};
          }
          .ant-table-column-sorter-up.active,
          .ant-table-column-sorter-down.active {
            color: ${theme.colors.black_sorted};
          }
        }
        .ant-table-column-sorter-inner {
          margin-top: 0;
        }
      }
    }
    .sort-space-none {
      .ant-table-column-sorters {
        justify-content: flex-start;
      }
      .ant-table-column-title {
        flex: 0 0 auto;
      }
      &.flex-end {
        .ant-table-column-sorters {
          justify-content: flex-end;
        }
      }
    }
  }
  .ant-table-title {
    padding: 0 !important;
  }
`;

function modifyStopPaymentsModal(stopPayments) {
  var resultantStopPayments = [];
 
  stopPayments.map(function (item) {     
    resultantStopPayments.push({     
      StopPaymentID:'S' + item.StopPaymentID,
      CheckDate: item.CheckDate,
      CheckPayee: item.CheckPayee,
      CheckNumber: item.CheckNumber,
      CheckAmount: item.CheckAmount,
      Account: item.Account,
      InvestmentType: item.InvestmentType,
      Reason: item.Reason,
      RequestedDate: item.RequestedDate,
      ExpirationDate: item.ExpirationDate,
    });
  });
  return resultantStopPayments;
}

const StopPaymentsContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [initiateStopPayment, setInitiateStopPayment] = useState(false);
  const [accountDetails, setAccountDetails] = useState([]);
  const [stopPaymentDetails, setStopPaymentDetails] = useState([]);  
  const [filteredStopPaymentDetails, setFilteredStopPaymentDetails] = useState(
    []
  );
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const checkedInvestmentTypes = useSelector(
    (state) => state.stopPaymentSlice.filteredInvestmentTypes
  );

  const checkedAccounts = useSelector(
    (state) => state.stopPaymentSlice.filteredAccounts
  );

  const inputSearchTerm = useSelector(
    (state) => state.stopPaymentSlice.inputSearchText
  );

  const selectedAccount = useSelector(
    (state) => state.accountProfileSlice.selectedAccount
  );
  const accountProfile = useSelector(
    (state) => state.accountProfileSlice.accountProfile
  );
  const [selectedAccountData, setSelectedAccountData] = useState({
    AccName: '',
  });
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const ssnorTIN = clientDetails.payload[0].ssnortin;
  const location = useLocation();

  useEffect(() => {
    return function cleanup() {
      dispatch(storeInputSearch(''));
      dispatch(storeFilteredProductTypes([]));
      dispatch(storeFilteredAccounts([]));
    };
  }, []);

  const [showInactiveAcc, setShowInactiveAcc] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setAccountDetails({ ...props.accData });
  }, []);

  useEffect(() => {
    if (!props.isGlobalStopPayments && selectedAccount.AccountUniqueID !== '') {
      const data = accountProfile.AccountDetails.filter(
        (acc) =>
          acc.AccountUniqueID.trim() === selectedAccount.AccountUniqueID.trim()
      );
      setSelectedAccountData(data[0]);
    }
  }, [props.isGlobalStopPayments]);

  useEffect(() => {
    applyFiltersToData();
  }, [
    checkedInvestmentTypes,
    inputSearchTerm,
    checkedAccounts,
    stopPaymentDetails,
  ]);

  useEffect(() => {
    GetStopPaymentDetails(ssnorTIN)
      .then((response) => {
        if (response.data != null && response.data.length > 0) {
          if (props.isGlobalStopPayments) {
            if (showInactiveAcc) {
              setFilteredStopPaymentDetails(
                modifyStopPaymentsModal(response.data)
              );
              setStopPaymentDetails(modifyStopPaymentsModal(response.data));
            } else {
              const data = response.data.filter(
                (acc) => acc.Status !== 'InActive'
              );
              setFilteredStopPaymentDetails(modifyStopPaymentsModal(data));
              setStopPaymentDetails(modifyStopPaymentsModal(data));
            }
          } else {
            const data = response.data.filter(
              (acc) =>
                acc.AccountUniqueID.trim() ===
                  selectedAccount.AccountUniqueID.trim() &&
                acc.Status !== 'InActive'
            );
            setFilteredStopPaymentDetails(modifyStopPaymentsModal(data));
            setStopPaymentDetails(modifyStopPaymentsModal(data));
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.isGlobalStopPayments, showInactiveAcc]);

  const handleAction = (data) => {
    setIsDetailsModalVisible(!isDetailsModalVisible);
    //Dont want to display AccountUniqueID in Detail model. So removing it from object.
    delete data.AccountUniqueID;
    setSelectedRecord(data);
  };

  const handleModalCancel = () => {
    setIsDetailsModalVisible(!isDetailsModalVisible);
    setSelectedRecord(null);
  };

  const handleBackClick = () => {
    if (props.isGlobalStopPayments) {
      history.push('/activity');
    } else {
      history.push('/dashboard/details/1');
    }
  };

  function applyFiltersToData() {
    let FilteredStopPayments = [];
    if (checkedAccounts.length > 0 || inputSearchTerm) {
      FilteredStopPayments = stopPaymentDetails.filter(function (elem) {
        // the current value is an object, so you can check on its properties
        let Investtypefilter = true;
        let InputSearchTermFilter = true;
        let Accountfilter = true;

        if (checkedAccounts.length > 0 && props.isGlobalStopPayments) {
          Accountfilter = checkedAccounts.includes(elem.Account);
        }
        if (inputSearchTerm) {
          InputSearchTermFilter =
            elem.CheckNumber.toUpperCase().indexOf(
              inputSearchTerm.toUpperCase()
            ) > -1;
        }
        return Investtypefilter && InputSearchTermFilter && Accountfilter;
      });
    } else {
      FilteredStopPayments = stopPaymentDetails;
    }

    setFilteredStopPaymentDetails(FilteredStopPayments);
  }

  return (
    <AppLayout>
      {initiateStopPayment ? (
        <InitiateStopPaymentsComponent
          initiateStopPayment={initiateStopPayment}
          setInitiateStopPayment={setInitiateStopPayment}
          accData={accountDetails}
          isGlobalStopPaymentsPage={props.isGlobalStopPayments}
        />
      ) : (
        <Content>
          <Banner bgcolor={primary_color} height="152px">
            <Box
              display="flex"
              alignitems="center"
              height="100%"
              minwidth="100%"
              width="100%"
              zindex="3"
              position="relative"
              padding="40px 0 36px 0"
            >
              <Container>
                <Anchor
                  onClick={handleBackClick}
                  fontsize={`${theme.font_size.size_l}`}
                  lineheight="20px"
                  color={`${theme.colors.pearl_river}`}
                  className="opac-text"
                >
                  <ArrowLeftOutlined style={{ marginRight: '6px' }} />
                  Back to{' '}
                  {props.isGlobalStopPayments
                    ? 'Activity History'
                    : selectedAccountData.AccName}
                </Anchor>
                <Box
                  display="flex"
                  justifycontent="space-between"
                  alignitems="flex-start"
                  margin="12px 0 0"
                >
                  <Text
                    color={`${theme.colors.white}`}
                    fontSize="36px"
                    lineheight="44px"
                    label={`Stop Payments${
                      props.isGlobalStopPayments
                        ? ''
                        : `-${selectedAccountData.AccName}`
                    }`}
                  />
                  {config.EnableTransaction ? (
                    <ButtonAnt
                      icon={false}
                      label="Initiate Stop Payment"
                      type="button"
                      onClick={() => setInitiateStopPayment(true)}
                    />
                  ) : null}
                </Box>
              </Container>
            </Box>
          </Banner>
          <Box padding="40px 0 0">
            <Container>
              <StopPaymentRenderFilters
                isGlobalPage={props.isGlobalStopPayments}
                unfilteredData={stopPaymentDetails}
                filteredData={filteredStopPaymentDetails}
                showInactiveAcc={showInactiveAcc}
                setShowInactiveAcc={setShowInactiveAcc}
              />
              <TableWrapper>
                <StopPaymentsTable
                  data={filteredStopPaymentDetails}
                  handleAction={handleAction}
                />
              </TableWrapper>

              {isDetailsModalVisible && (
                <Modal
                  title="Stop Payment Details"
                  width={590}
                  bodyStyle={{
                    padding: '32px 32px 34px 32px',
                  }}
                  maskStyle={{
                    backgroundColor: `${theme.rgba.modal}`,
                    zIndex: '1051',
                  }}
                  content={
                    <StopPaymentsDetailsModal selectedRecord={selectedRecord} />
                  }
                  visible={isDetailsModalVisible}
                  onCancel={() => handleModalCancel('details')}
                  footer={null}
                  centered={true}
                />
              )}
            </Container>
          </Box>
        </Content>
      )}
    </AppLayout>
  );
};

export default StopPaymentsContainer;
