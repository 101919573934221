import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  theme,
  ButtonAnt,
  InputComponent,
  Text,
  Modal,
  Tabs,
} from '@pfmam/pfmam';
import { EditOutlined } from '@ant-design/icons';
import { Form, Space, Radio, DatePicker } from 'antd';
import { Anchor, Box } from '../../../styles/globalStyle';
import { InvestmentSetlectStyle } from '../../../activityHistory/components/stopPayments/initiateStopPayments';
import { OverrideStyle } from '../../../common/components/datePicker';
import MultiDate from '../../common/multiDate';
import SingleDate from '../../common/singleDate';
import MultiAccount from '../../common/multiAccount';
import moment from 'moment';
import { IconBg } from '../../moveFunds';
import SinglBankICon from '../../../assets/images/Transaction Icons/single-account.png';
import MultipleAccIcon from '../../../assets/images/Transaction Icons/multiple-accounts.png';
import AccInvestmentTypeInput from '../../common/accInvestmentTypeInput';
import InvestmentModalContent from '../../common/accInvestmentTypeModal';
import { Prompt } from 'react-router';
import {
  GetACHInstructionsFilteredData,
  IsDuplicateTransactionSingleAccount,
  GetTransactionDefaultDay,
  IsFundValidBusinessDay
} from '../../../actions/actions';
import DuplicateTransactionModalContent from '../../common/duplicateTransactionModalContent';
import { amountFormat } from '../../../utils/numberFormat';
import { useSelector } from 'react-redux';
import TransactionInstructionTable from '../../common/transactionInstructionsTable';
const Title = styled(Text)`
  font-size: 36px;
  margin-bottom: 32px;
  line-height: 44px;
`;
const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
  }
`;

export const SuffixButton = styled(ButtonAnt)`
  &.ant-btn {
    font-size: ${theme.font_size.size_xl};
    color: ${(props) => props.color};
    line-height: 24px;
    padding: 0;
    border: 0;
    height: 100%;
    ${(props) =>
    props.btnblock &&
    css`
        position: absolute;
        left: 0;
        width: 100%;
        text-align: left;
        padding: 0 20px;
      `}
    ${(props) =>
    props.btnedit &&
    css`
        position: absolute;
        right: 0;
        width: auto;
        padding: 0 16px;
      `}
  }
  left: auto;
  width: auto;
  text-align: left;
  padding: 0 20px;
  right: 0;
  text-align: right;
`;
const ButtonWrapper = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
`;
const TextSemibold = styled(Text)`
  font-family: 'Inter Semibold';
`;
const TabsWrapper = styled.div`
  margin: 0 -32px;
  .nav-pills {
    .ant-tabs-nav {
      margin-bottom: 32px;
    }
  }
  .ant-tabs-nav-list {
    padding-left: 32px;
  }
  .ant-input-number-input {
    padding: 0 20px;
  }
  .multiple-dates-tab-content {
    .ant-input-number-input {
      text-align: right;
      padding: 0 20px;
    }
  }
  .field-wrapper {
    align-items: flex-start;
  }
`;
const RegularText = styled.span`
  color: ${(props) => props.color};
  font-size: 16px;
  line-height: 24px;  
`;
const Wrapper = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;
const EditButton = styled(ButtonAnt)`
  &.ant-btn {
    font-size: ${theme.font_size.size_xl};
    color: ${theme.colors.grey_stone};
    line-height: 24px;
    padding: 0;
    border: 0;
    height: 20px;
    width: 20px;
  }
`;

const momentBusinessDays = require('moment-business-days');

const PurchaseForm = ({
  form,
  selected,
  setSelected,
  showInvestmentTypeModal,
  setShowInvestmentTypeModal,
  setFormType,
  setFormData,
  setAmount,
  setSelectedPage,
  selectedPage
}) => {
  const [showAccountsModal, setShowAccountsModal] = useState(false);
  const [vals, setVals] = useState({});
  const [formTotal, setFormTotal] = useState(0);
  const [bankAccounts, setBankAccounts] = useState([]);
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const [isDuplicateTransModalVisible, setIsDuplicateTransModalVisible] = useState(false);
  const [duplicateModalTransData, setDuplicateModalTransData] = useState([]);
  const [isInvalidDateModalVisible, setIsInvalidDateModalVisible] = useState(false);
  const [fundUniqueID, setFundUniqueID] = useState(0);
  const [defaultDate, setDefaultDate] = useState(moment());
  const webUserTypeID = useSelector(
    (state) => state.userSlice.webUserType
  );

  const duplicateTransColumns = [
    {
      title: 'Confirmation #',
      dataIndex: 'Confirm #',
      key: 'Confirm #',
      width: 190,
      align: 'left',
    },
    {
      title: 'Account Number',
      dataIndex: 'Account Number',
      key: 'Account Number',
      width: 210,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={text}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    },
    {
      title: 'Posting Date',
      dataIndex: 'Posting Date',
      key: 'Posting Date',
      width: 212,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={moment(text).format('MMM DD, YYYY')}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'Created Date',
      key: 'Created Date',
      width: 212,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={moment(text).format('MMM DD, YYYY h:mm A')}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
          <Text
            label={record['Created By']}
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_xl}`}
            margin="4px 0 0"
            lineheight="20px"
          />
        </>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      width: 148,
      align: 'right',
      render: (text) => <>{amountFormat(text)}</>,
    },
  ];

  useEffect(() => {
    let data = form.getFieldValue('accountinvestmentType');
    if (data) {
      GetACHInstructionsFilteredData(data.AccountUniqueID)
        .then((response) => {
          if (response.data !== null && response.data.length > 0) {
            const sortByKey = (key) => (a, b) => a[key] > b[key] ? 1 : -1;
            setBankAccounts(response.data.sort(sortByKey('DisplayName')));
          } else {
            setBankAccounts([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [form.getFieldValue('accountinvestmentType')]);


  const handleDuplicateModalSubmit = () => {
    setIsDuplicateTransModalVisible(false);
    setFormType('review');
  };

  const handleDuplicateModalCancel = () => {
    setIsDuplicateTransModalVisible(false);
  };

  const onFinish = (values) => {
    setFormData(values);
    if (values.hasOwnProperty('amount')) {
      setAmount(values['amount']);
    }
    else {
      setAmount(formTotal);
    }
    if (values.multiAccount) {
      setSelected('MultiPurchaseForm');
    }
    verifyTransactionDateIsValid(values);
  };

  function verifyTransactionDateIsValid(values) {
    if (values.date) {
      IsFundValidBusinessDay(values.date.format('YYYY-MM-DD'), values.accountinvestmentType.type.FundUniqueID)
        .then((response) => {
          if (!response.data) {
            setIsInvalidDateModalVisible(true);
          }
          else {
            validateDuplicateTransaction(values);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let entryLength = values.multiDate.length;
      let resultantLength = 0;
      let isInvalidDate = [];
      values.multiDate.map((obj) => {
        IsFundValidBusinessDay(obj.date.format('YYYY-MM-DD'), values.accountinvestmentType.type.FundUniqueID)
          .then((response) => {
            resultantLength = resultantLength + 1;
            if (!response.data) {
              isInvalidDate.push(true);
            }

            if (entryLength === resultantLength && isInvalidDate.length > 0) {
              setIsInvalidDateModalVisible(true);
            } else if (entryLength === resultantLength) {
              validateDuplicateTransaction(values);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  }

  function validateDuplicateTransaction(values) {
    //Check for possible duplicate transaction.
    //If single entry
    if (values.date && !values.multiAccount) {
      let resultantData = [];
      IsDuplicateTransactionSingleAccount(values.date.format('YYYY-MM-DD'), values.accountinvestmentType.AccountUniqueID, values.amount, 1)
        .then((response) => {
          if (response.data !== null && response.data.Table.length > 0) {
            resultantData = buildDuplicateModalData(response.data.Table, resultantData);
            let sortedData = resultantData.sort((a, b) => b["Confirm #"] - a["Confirm #"]);
            setDuplicateModalTransData(sortedData);
            setIsDuplicateTransModalVisible(true);
          }
          else {
            setFormType('review');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //If multi dates
    if (values.multiDate) {
      let entryLength = values.multiDate.length;
      let resultantLength = 0;
      let resultantData = [];
      values.multiDate.map((obj) => {
        IsDuplicateTransactionSingleAccount(obj.date.format('YYYY-MM-DD'), values.accountinvestmentType.AccountUniqueID, obj.amount, 1)
          .then((response) => {
            resultantLength = resultantLength + 1;
            if (response.data !== null && response.data.Table.length > 0) {
              resultantData = buildDuplicateModalData(response.data.Table, resultantData);
            }

            if (entryLength === resultantLength && resultantData.length > 0) {
              let sortedData = resultantData.sort((a, b) => b["Confirm #"] - a["Confirm #"]);
              setDuplicateModalTransData(sortedData);
              setIsDuplicateTransModalVisible(true);
            }
            else if (entryLength === resultantLength) {
              setFormType('review');
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

    //If multi accounts
    if (values.multiAccount) {
      //setSelected('MultiPurchaseForm');
      let entryLength = values.multiAccount.length;
      let resultantLength = 0;
      let resultantData = [];
      values.multiAccount.map((obj) => {
        IsDuplicateTransactionSingleAccount(values.date.format('YYYY-MM-DD'), values.accountinvestmentType.AccountUniqueID, obj.amount, 1)
          .then((response) => {
            resultantLength = resultantLength + 1;
            if (response.data !== null && response.data.Table.length > 0) {
              resultantData = buildDuplicateModalData(response.data.Table, resultantData);
            }

            if (entryLength === resultantLength && resultantData.length > 0) {
              let sortedData = resultantData.sort((a, b) => b["Confirm #"] - a["Confirm #"]);
              setDuplicateModalTransData(sortedData);
              setIsDuplicateTransModalVisible(true);
            }
            else if (entryLength === resultantLength) {
              setFormType('review');
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  }

  function buildDuplicateModalData(duplicateData, resultantData) {
    duplicateData.map(function (item) {
      if (!(resultantData.filter(e => e["Confirm #"] === item["Confirm #"]).length > 0)) {
        resultantData.push({
          "Confirm #": item["Confirm #"],
          "Account Number": item["Account Number"],
          "Posting Date": item["Posting Date"],
          "Created Date": item["Created Date"],
          "Created By": item["Created By"],
          "Amount": item["Amount"]
        });
      }
    });
    return resultantData;
  }

  const buttonHandler = (type) => {
    return setSelected(type);
  };

  const AccountsModal = () => {

    const achInstructionsColumns = [
      {
        title: 'Bank Name',
        dataIndex: 'DisplayName',
        key: 'DisplayName',
        width: 400,
        align: 'left',
        className: 'sort-space-none',
        sorter: (a, b) => a.DisplayName.localeCompare(b.DisplayName),
      },
      {
        title: 'Nickname',
        dataIndex: 'NickName',
        key: 'NickName',
        width: 300,
        align: 'right',
        className: 'sort-space-none',
        sorter: (a, b) => (a.NickName+a.AddendumPurchaseDescription).localeCompare(b.NickName+b.AddendumPurchaseDescription),
        render: (text, record) => (
          <>
            {text}{' '}
            <Text
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.grey_tertiary}`}
              label={record.AddendumPurchaseDescription}
              lineheight="24px"
            />
          </>
        ),
      },
    ];

    const onAccountsChange = (e) => {
      form.setFieldsValue({
        bankAccount: e
      });
      setShowAccountsModal(!showAccountsModal);
    };

    return (
      <>
        <TransactionInstructionTable
          columns={achInstructionsColumns}
          handleAction={onAccountsChange}
          data={bankAccounts}
          noRecordsText="No Banking instructions found"
        />
      </>
    );
  };

  const onInvestmentTypeChange = (e) => {
    form.resetFields();
    setVals(0);
    form.setFieldsValue({
      accountinvestmentType: { ...e.target.value },
    });
    const fields = form.getFieldsValue();

    if (fields.bankAccount) {
      form.setFieldsValue({
        bankAccount: null
      });
    }


    if (fields.multiAccount) {
      fields.multiAccount.map((account) => {
        account.multiBankAccount = null;
      });
      form.setFieldsValue(fields);
    }

    GetTransactionDefaultDay(e.target.value.type.FundUniqueID, 1).then((response) => {
      if (response.data !== null) {
        //var date1 = new Date(response.data);
        var defaultMoment = moment(response.data.toString());
        form.setFieldsValue({
          date: defaultMoment,
        });
        form.setFieldsValue({
          multiDate: [
            { date: defaultMoment, amount: '' },
            { date: defaultMoment, amount: '' },
          ]
        });

        setDefaultDate(defaultMoment);
      }
    })
      .catch((error) => {
        console.log(error);
      });

    setFundUniqueID(e.target.value.type.FundUniqueID);
    setShowInvestmentTypeModal(!showInvestmentTypeModal);
  };

  const handleTotal = (_, values) => {
    setVals(values);
  };

  function disabledDate(current) {
    var x = moment().endOf("day");
    // Can not select days before default date and weekends
    return (current.isoWeekday() === 7 || current.isoWeekday() === 6 || current < defaultDate.startOf('day'));
  }

  function onDuplicateModalCancel() {
    setIsDuplicateTransModalVisible(false);
    setDuplicateModalTransData([]);
  }

  return (
    <>
      <Prompt
        when={form.isFieldsTouched()}
        message={JSON.stringify({
          header: 'Discard Transaction?',
          content:
            'Leaving this page will discard this transaction. Are you sure you want to discard this transaction?',
        })}
      />
      <OverrideStyle />
      <InvestmentSetlectStyle />
      <Title label={'ACH Purchase'} color={`${theme.colors.black_secondary}`} />
      <Box
        border={`1px solid ${theme.colors.grey}`}
        borderwidth="0 0 1px 0"
        display="flex"
        padding="20px 32px 24px"
        boxcolor={theme.colors.white}
        radius="4px 4px 0 0"
      >
        <IconBg bgColor={`${primary_color}`}>
          <img
            src={
              selectedPage === 'PurchaseForm' ? SinglBankICon : MultipleAccIcon
            }
            alt="Single Bank Account"
          />
        </IconBg>
        <Box flex="1">
          <TextSemibold
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.black_secondary}`}
            label={
              selectedPage === 'PurchaseForm'
                ? 'Single Bank Account'
                : 'Multiple Bank Account'
            }
            lineheight="24px"
            margin="0 0 4px"
          />
          <Anchor
            fontsize={`${theme.font_size.size_l}`}
            lineheight="20px"
            color={`${theme.colors.blue_primay}`}
            display="inline-block"
            onClick={() => setSelectedPage('TransferTypeCard')}
          >
            Change
          </Anchor>
        </Box>
      </Box>
      <Box
        boxcolor={`${theme.colors.white}`}
        padding="32px 32px 48px"
        radius="0 0 4px 4px"
      >
        <Form
          form={form}
          name="dynamic_form_nest_item"
          layout="vertical"
          requiredMark={false}
          className="forms"
          initialValues={{
            multiDate: [
              { date: momentBusinessDays().businessAdd(1), amount: '' },
              { date: momentBusinessDays().businessAdd(1), amount: '' },
            ],
            multiAccount: [
              { multiBankAccount: '', amount: '' },
              { multiBankAccount: '', amount: '' },
            ],
            date: momentBusinessDays().businessAdd(1),
          }}
          onFinish={onFinish}
          onValuesChange={handleTotal}
        >
          <>
            <AccInvestmentTypeInput
              form={form}
              showInvestmentTypeModal={showInvestmentTypeModal}
              setShowInvestmentTypeModal={setShowInvestmentTypeModal}
              amount={null}
            />
            {selectedPage === 'PurchaseForm' ? (
              <Form.Item
                label="Bank Account"
                name="bankAccount"
                rules={[
                  {
                    required: true,
                    message: 'Enter Account Details',
                  },
                ]}
                className={
                  form.getFieldValue('accountinvestmentType')
                    ? 'read-only-input'
                    : ''
                }
              >
                {form.getFieldValue('bankAccount') ? (
                  <Box
                    display="flex"
                    alignitems="center"
                    justifycontent="space-between"
                    padding="18px 20px"
                    border={`1px solid ${theme.colors.grey}`}
                    radius="2px"
                  >
                    <Box flex="1" display="flex" alignitems="center">
                      <Box
                        minwidth={'65%'}
                        maxwidth={'65%'}
                        padding="0 20px 0 0"
                      >
                        <Text
                          label={form.getFieldValue('bankAccount').DisplayName}
                          fontSize={`${theme.font_size.size_xl}`}
                          color={`${theme.colors.black_secondary}`}
                          lineheight="24px"
                        />
                      </Box>
                    </Box>
                    <EditButton
                      label={false}
                      icon={<EditOutlined style={{ color: `${theme.colors.grey_stone}` }} />}
                      onClick={() => {
                        setShowAccountsModal(!showAccountsModal);
                      }}
                    />
                  </Box>
                ) : (
                    <InputComponent
                      type="default"
                      placeholder="None Selected"
                      readOnly={true}
                      disabled={
                        form.getFieldValue('accountinvestmentType')
                          ? false
                          : true
                      }
                      onClick={() => {
                        setShowAccountsModal(!showAccountsModal);
                      }}
                      suffix={
                        <SuffixButton
                          label={
                            form.getFieldValue('bankAccount')
                              ? null
                              : 'Select Account'
                          }
                          icon={
                            form.getFieldValue('bankAccount') ? (
                              <EditOutlined
                                style={{ color: `${theme.colors.grey_stone}` }}
                              />
                            ) : null
                          }
                          onClick={() => {
                            setShowAccountsModal(!showAccountsModal);
                          }}
                          type="link"
                          disabled={
                            form.getFieldValue('accountinvestmentType')
                              ? false
                              : true
                          }
                          color={
                            form.getFieldValue('accountinvestmentType')
                              ? theme.colors.black_secondary
                              : theme.colors.grey_stone
                          }
                        />
                      }
                    />
                  )

                }

              </Form.Item>
            ) : null}

            {selectedPage === 'PurchaseForm' ? (
              <>
                <TabsWrapper>
                  <Tabs
                    activeTabColor={primary_color}
                    defaultActiveKey={selected}
                    activeKey={selected}
                    onTabClick={(key) => buttonHandler(key)}
                    tabsOptions={[
                      {
                        content: (
                          <Box padding="0 32px">
                            {selected === 'multiple' ? null : (
                              <SingleDate
                                vals={vals}
                                setFormTotal={setFormTotal}
                                dateLabel="Transaction Date"
                                disabledDate={disabledDate}
                              />
                            )}
                          </Box>
                        ),
                        key: 'single',
                        label: 'Single Date',
                      },
                      {
                        content: (
                          <Box className="multiple-dates-tab-content">
                            {selected === 'single' ? null : (
                              <MultiDate
                                activeTabColor={primary_color}
                                vals={vals}
                                formTotal={formTotal}
                                setFormTotal={setFormTotal}
                                selectedPage={selectedPage}
                                form={form}
                                disabledDate={disabledDate}
                                defaultDate={defaultDate}
                              />
                            )}
                          </Box>
                        ),
                        key: 'multiple',
                        label: 'Multiple Dates',
                      },
                    ]}
                    type="nav-pills"
                  />
                </TabsWrapper>
              </>
            ) : (
                <>
                  <MultiAccount
                    vals={vals}
                    formTotal={formTotal}
                    setFormTotal={setFormTotal}
                    form={form}
                    bankAccounts={bankAccounts}
                    type={'ACHPurchase'}
                  />
                  <Form.Item
                    label="Transaction Date"
                    name="date"
                    rules={[
                      {
                        type: 'object',
                        required: true,
                        message: 'Transaction Date Required',
                      },
                    ]}
                  >
                    <DatePicker
                      format="MMM DD, YYYY"
                      allowClear={false}
                      className="suffix-left"
                      dropdownClassName="single-date-picker"
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                </>
              )}
          </>
          <ButtonWrapper size={12} spacing>
            <ButtonAnt
              label="Cancel"
              type="secondary"
              htmlType="button"
              onClick={() => setSelectedPage('TransferTypeCard')}
            />
            <OverrideButtonAnt linkTextColor={primary_color} label="Review" type="primary" htmlType="submit" />
          </ButtonWrapper>
        </Form>
      </Box>
      {showInvestmentTypeModal && (
        <Modal
          title="Account/Investment Type"
          width={590}
          bodyStyle={{
            padding: '0 0 64px 0',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          content={
            <InvestmentModalContent
              form={form}
              onInvestmentTypeChange={onInvestmentTypeChange}
              showAmount={false}
              pendingTransactionTypeClassID={1}
            />
          }
          visible={showInvestmentTypeModal}
          onCancel={() => {
            setShowInvestmentTypeModal(!showInvestmentTypeModal);
          }}
          footer={null}
          centered={true}
        />
      )}

      {showAccountsModal && (
        <Modal
          title="Bank Account"
          width={590}
          bodyStyle={{
            padding: '0 0 0 0',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          content={<AccountsModal />}
          visible={showAccountsModal}
          onCancel={() => setShowAccountsModal(!showAccountsModal)}
          footer={null}
          centered={true}
        />
      )}

      {isDuplicateTransModalVisible && (
        <Modal
          title="Possible Duplicate Transaction"
          width={750}
          bodyStyle={{
            padding: '32px 0 40px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          footer={null}
          visible={isDuplicateTransModalVisible}
          onCancel={onDuplicateModalCancel}
          content={
            <DuplicateTransactionModalContent
              columns={duplicateTransColumns}
              data={duplicateModalTransData}
              handleDuplicateModalCancel={handleDuplicateModalCancel}
              handleDuplicateModalSubmit={handleDuplicateModalSubmit}
            />
          }
          centered={true}
        />
      )}
      {isInvalidDateModalVisible && (
        <Modal
          title="Invalid Date"
          width={750}
          bodyStyle={{
            padding: '32px 0 40px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          footer={null}
          visible={isInvalidDateModalVisible}
          onCancel={() => setIsInvalidDateModalVisible(false)}
          content={
            <Wrapper>
            <RegularText>
              {"The Date you selected is Invalid or a Holiday for the Investment Type you selected. Please select other Date"}
            </RegularText>
          </Wrapper>
          }
          centered={true}
        />
      )}
    </>
  );
};

export default PurchaseForm;