import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { theme } from '@pfmam/pfmam';
import { Box } from '../../styles/globalStyle';
import OutsideAlerter from './outsideAlerter';
import { sizes } from '../../styles/mediaQuery';

const SearcInput = styled(Input)`
  height: 52px;
  width: calc(100% - 52px);
  padding: 14px 0;
  background: ${theme.colors.transparent};
  border: 1px solid ${theme.colors.transparent};
  border-right-color: ${theme.colors.transparent};
  color: ${theme.colors.black_secondary};
  font-size: ${theme.font_size.size_xl};
  &::placeholder {
    color: ${theme.colors.grey_tertiary};
  }
`;
const SearchButton = styled.button`
  width: 52px;
  height: 52px;
  line-height: 50px;
  border: 1px solid ${theme.colors.grey};
  background-color: ${theme.colors.white};
  color: ${theme.colors.black_secondary};
  font-size: ${theme.font_size.size_l};
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

const InpuWrapper = styled(Box)`
  width: 52px;
  transition: width 250ms ease-in-out;
  &.show {
    width: 332px;
    @media ${sizes.mediaQuery('xs', 'lg')} {
      width: 215px;
    }
    .search-input {
      background: ${theme.colors.white};
      border-color: ${theme.colors.grey};
      border-right-color: ${theme.colors.transparent};
      padding: 14px 12px;
      &:focus {
        box-shadow: ${theme.shadows.input_focus};
        border-color: ${theme.colors.blue_primay};
      }
    }
    .search-button {
      border-radius: 0 2px 2px 0;
    }
  }
`;

const ExpandInputComponent = ({
  onInputSubmit,
  inputClassName,
  setInputClassName,
}) => {
  const [inputData, setInputData] = useState('');
  const handleOutsideClick = () => {
    if (!inputData || inputData === '') {
      setInputClassName('hide');
    }
  };

  return (
    <OutsideAlerter handleOutsideClick={handleOutsideClick}>
      <InpuWrapper display="flex" className={`${inputClassName} `}>
        <SearcInput
          type="text"
          placeholder="Search"
          className="search-input"
          onChange={(e) => {
            if (e.target.value === '') {
              onInputSubmit('');
            }
            setInputData(e.target.value);
          }}
          onPressEnter={() => onInputSubmit(inputData)}
        />
        <SearchButton
          type="button"
          className="search-button"
          label=""
          onClick={() => {
            inputClassName === 'hide'
              ? setInputClassName('show')
              : onInputSubmit(inputData);
          }}
        >
          <SearchOutlined />
        </SearchButton>
      </InpuWrapper>
    </OutsideAlerter>
  );
};

export default ExpandInputComponent;
