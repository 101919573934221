import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { Table, Switch, Form } from 'antd';
import { Box } from '../../styles/globalStyle';
import {
  theme,
  Text,
  TableComponent,
  ButtonAnt,
} from '@pfmam/pfmam';
import { amountFormat } from '../../utils/numberFormat';
import { sortArrayOfObjectsInDesc } from '../../utils/common';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import NoDataFound from '../../common/components/noDataFound';
import { storeSelectedAccountNumber } from '../../services/accountProfileSlice';
import { useDispatch, useSelector } from 'react-redux';

const TableWrapper = styled.div`
  .ant-table-title {
    padding: 0 !important;
  }
  .table-primary .ant-table-thead > tr > th {
    font-family: 'Inter';
    padding: 8px 32px 8px 16px;
    font-weight: normal;
    line-height: 20px;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: ${theme.colors.transparent};
  }
  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td,
  .ant-table-wrapper.table-primary
    .ant-table-tbody
    .ant-table-expanded-row
    > td {
    background: ${theme.colors.grey_faint};
  }
  .ant-table.ant-table-middle
    .ant-table-tbody
    .ant-table-wrapper:only-child
    .ant-table {
    margin: -19px -16px;
  }
  .ant-table-expanded-row .ant-table-tbody > tr > td {
    background: ${theme.colors.grey_faint} !important;
    border-bottom-color: ${theme.colors.grey_fossil};
    padding: 16px 32px 16px 16px;
    line-height: 20px;
    &:first-child {
      padding-right: 16px;
    }
  }
  .table-primary .ant-table-tbody > tr:last-child > td {
    border-bottom-color: ${theme.colors.transparent};
  }
  td.ant-table-column-sort {
    background: ${theme.colors.white};
  }
  .ant-table-column-sorters {
    justify-content: flex-start;
    .ant-table-column-title {
      flex: 0 0 auto;
    }
    .ant-table-column-sorter-inner .anticon {
      color: ${theme.colors.grey_stone};
    }
    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
      color: ${theme.colors.black_sorted};
    }
  }
  .ant-table-tbody {
    & > tr {
      & > td {
        &.right-aligned-cell {
          padding-right: 32px;
        }
      }
      &:hover {
        &.ant-table-row:not(.ant-table-expanded-row) {
          background: linear-gradient(
              0deg,
              ${(props) => props.linkTextColor},
              ${(props) => props.linkTextColor}
            ),
            ${theme.colors.white};
        }
        .ant-table-row-expand-icon {
          background: linear-gradient(
              0deg,
              rgba(23, 54, 93, 0.07),
              rgba(23, 54, 93, 0.07)
            ),
            ${theme.colors.white};
          border-color: ${theme.colors.transparent};
          box-shadow: none;
          .ant-table-row-expand-icon-collapsed {
            margin: 15px;
          }
        }
      }
      .ant-table-row-expand-icon {
        line-height: 32px;
        &:focus,
        &:hover {
          color: ${theme.colors.blue_primay};
          border-color: ${theme.colors.blue_primay};
        }
      }
      cursor: pointer;
    }
    .ant-table-tbody,
    .ant-table-expanded-row {
      .ant-table-row {
        cursor: default;
      }
    }
  }
  .link {
    &:hover {
      opacity: 0.6;
    }
    &:focus,
    &:active {
      opacity: 1;
    }
  }
`;
const ExpandButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  min-width: 32px;
  cursor: pointer;
  transition: 0.3s;
  & > span {
    height: 19px;
    min-width: 19px;
    width: 19px;
    margin: 0 auto;
    border: 2px solid ${theme.colors.grey};
    border-radius: 2px;
    padding: 5px;
    position: relative;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: '';
      position: absolute;
      top: 3px;
      background: ${theme.colors.black_secondary};
      height: 10px;
      width: 2px;
      transition: transform 0.3s ease-out;
      transform: rotate(0deg);
    }
    &::after {
      content: '';
      position: absolute;
      top: 7px;
      right: 2.3px;
      background: ${theme.colors.black_secondary};
      height: 2px;
      width: 10px;
      transition: transform 0.3s ease-out;
      transform: rotate(-180deg);
    }
  }
  &:focus {
    & > span {
      box-shadow: ${theme.shadows.input_focus};
      border-color: ${theme.colors.blue_primay};
    }
  }
  &.expanded {
    & > span {
      &::after {
        transform: rotate(0deg);
      }
      &::before {
        transform: rotate(90deg);
      }
    }
  }
`;
const CustomSwitch = styled(Switch)`
  background-color: ${(props) => props.checked ? props.linkTextColor : 'lightgray'}
`;
const AccountsTable = ({ accData, linkTextColor }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [totalTableRows, setTotalTableRows] = useState([]);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [switchLabel, setSwitchLabel] = useState('Expand All');

  useEffect(() => {
    if (accData.length > 0) {
      setExpandedKeys([]);
      setSwitchChecked(false);
      setSwitchLabel('Expand All');
      var rowKeys = [];
      for (var i = 1; i <= accData.length; i++) {
        rowKeys.push(i);
      }
      setTotalTableRows(rowKeys);
    }
  }, [accData]);

  const handleExpandCollaspeAllRows = enable => {
    setSwitchChecked(enable);
    if (enable) {
      setSwitchLabel('Collapse All');
      setExpandedKeys(totalTableRows)
    } else {
      setSwitchLabel('Expand All');
      setExpandedKeys([]);
    }
  };

  const columns = [
    {
      title: 'Account',
      dataIndex: 'AccName',
      key: 'AccName',
      render: (text, row) => (
        <div>
          <Text label={text} />
          <label style={{ fontSize: '13px' }}>{row.AccNumber}</label>
        </div>
      ),
    },
    {
      title: 'Market Value',
      dataIndex: 'CurrentBalance',
      key: 'CurrentBalance',
      align: 'right',
      className: 'right-aligned-cell',
      render: (text) => <Text label={amountFormat(text)} />,
    },
  ];

  const childColumns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 80,
    },
    {
      title: '',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: '',
      dataIndex: 'MarketValue',
      key: 'MarketValue',
      align: 'right',
      render: (text) => <Text label={amountFormat(text)} />,
    },
  ];

  const expandable = {
    expandedRowRender: (record) => (
      <Table
        showHeader={false}
        columns={childColumns}
        dataSource={record.ProductHoldings}
        /*
        dataSource={sortArrayOfObjectsInDesc(
          record.productHoldings,
          'marketValue'
        )}
*/
        pagination={false}
      />
    ),
    expandIcon: ({ expanded, onExpand, record }) => (
      <ExpandButton
        className={expanded ? 'expanded' : ''}
        onClick={(e) => {
          e.stopPropagation();
          if (expandedKeys.includes(record.key)) {
            var exp = expandedKeys.filter((val) => val != record.key);
            if (exp.length == 0 || exp.length == totalTableRows.length - 1) {
              setSwitchLabel('Expand All');
              setSwitchChecked(false);
            }
            setExpandedKeys(exp);
          } else {
            var exp = expandedKeys;
            exp.push(record.key);
            if (exp.length == totalTableRows.length) {
              setSwitchLabel('Collapse All');
              setSwitchChecked(true);
            }
            setExpandedKeys(exp);
          }
          onExpand(record, e);
        }}
      >
        <span></span>
      </ExpandButton>
    ),
  };

  return (
    <TableWrapper linkTextColor={linkTextColor}>
      <TableComponent
        columns={columns}
        dataSource={accData}
        locale={{
          emptyText: (
            <Box padding="28px 0">
              <NoDataFound
                text="You have no open accounts. Visit Activity History or Statements & Documents to view information related to closed accounts. To open a new account, send us a message via Secure Contact."
                textalign="center"
                padding="0 0 24px"
              />
            </Box>
          ),
        }}
        expandable={expandable}
        expandedRowKeys={expandedKeys}
        className="table-primary"
        title={
          <Box
            display="flex"
            justifycontent="space-between"
            alignitems="center"
            padding="24px 16px 24px 32px"
            border={`1px solid ${theme.colors.grey_fossil}`}
            borderwidth="0 0 1px 0"
          >
            <Text
              label="Accounts"
              fontSize={`${theme.font_size.size_3xl}`}
              lineheight="32px"
              color={`${theme.colors.black_tertiary}`}
            />
            {accData.length > 0 && (
              <Form.Item label={switchLabel}>
                <CustomSwitch linkTextColor={primary_color} checked={switchChecked} onChange={handleExpandCollaspeAllRows} />
              </Form.Item>
            )}
            {/*
            <ButtonAnt
              type="link"
              label="Add Account"
              icon={<PlusCircleOutlined />}
              className="add-button"
            />
          */}
          </Box>
        }
        onRow={(record) => ({
          onClick: () => {
            dispatch(storeSelectedAccountNumber(record));
            history.push(`/dashboard/details/${record.key}`);
          },
        })}
      />
    </TableWrapper>
  );
};

export default AccountsTable;
