/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Layout, Space, Tooltip } from 'antd';
import {
  theme,
  Text,
  ButtonAnt,
  Tabs,
  Modal,
} from '@pfmam/pfmam';
import {
  storeFilteredProductTypes,
  storeFilteredTransactionTypes,
  storeInputSearch,
} from '../services/accountProfileSlice';
import { getactivityHistory } from '../services/ActivityHistorySlice';
import { Link } from 'react-router-dom';
import { Container, Box, Banner, Anchor } from '../styles/globalStyle';
import AppLayout from '../common/layouts/appLayout';
import SummaryContainer from './summaryContainer';
import { ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { amountFormat } from '../utils/numberFormat';
import accountDetails from '../data/AccountDetails.json';
import ActivityHistoryContainer from '../activityHistory/activityHistoryContainer';
import PendingActivityContainer from '../activityHistory/pendingActivityContainer';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  GetCurrentBalanceInfo,
  GetClientOrAccountLevelTransPermission,
} from '../actions/actions';
import HoldingsContainer from './holdingsContainer';
import StopPaymentsContainer from '../activityHistory/stopPaymentsContainer';
import MoveFundsModal from './components/moveFundsModal';
import config from '../config';
const { Content } = Layout;

const ContentWrapper = styled(Content)`
  .cards {
    &:first-child {
      margin-bottom: 24px;
    }
    .ant-card-head {
      padding: 0 32px;
      font-weight: normal;
      border-color: ${theme.colors.grey_fossil};
    }
    .ant-card-head-title {
      font-size: ${theme.font_size.size_3xl};
      color: ${theme.colors.black_tertiary};
      line-height: 32px;
      padding: 24px 0;
    }
    .ant-card-body {
      padding: 12px 32px 32px;
    }
    &.ant-card {
      border-radius: 4px;
      border: 0;
    }
  }

  .pending-activity-card {
    .ant-card-body {
      padding: 0;
    }
  }
`;
const BalanceText = styled.p`
  color: ${theme.colors.white};
  font-size: ${theme.font_size.size_l};
  line-height: 20px;
  margin: 0 0 13px 0;
  opacity: 0.7;
  .anticon {
    margin-left: 5px;
  }
`;

const AccName = styled.p`
  color: ${theme.colors.white};
  font-size: 36px;
  line-height: 44px;
  text-transform: capitalize;
`;

const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
  }
`;

const DashboardGeneralFunds = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [accId, setAccId] = useState(null);
  const [accData, setAccData] = useState(null);
  const [activeTab, setActiveTab] = useState('1');
  const [currentBalance, setCurrentBalance] = useState(0);
  const showMoveFunds = useSelector(
    (state) => state.siteInfo.allowOnlineTransact
  );
  const [showMoveFundsAccountLevel, setShowMoveFundsAccountLevel] =
    useState(false);
  const webuserAccessType = useSelector((state) => state.userSlice.webUserType);
  const [accountData, setAccountData] = useState();
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const selectedAccount = useSelector(
    (state) => state.accountProfileSlice.selectedAccount
  );
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const rgbaPrimary = useSelector((state) => state.siteInfo.rgbaPrimary);
  const secondary_color = useSelector((state) => state.siteInfo.secondaryColor);
  const accountProfile = useSelector(
    (state) => state.accountProfileSlice.accountProfile
  );

  const [isMoveFundsVisible, setIsMoveFundsVisible] = useState(false);

  const handleFundsModalCancel = () => {
    setIsMoveFundsVisible(!isMoveFundsVisible);
  };
  useEffect(() => {
    if (props.location.pathname.includes('/activity-history')) {
      setActiveTab('2');
    } else if (props.location.pathname.includes('/holdings')) {
      setActiveTab('3');
    } else {
      setActiveTab('1');
    }
    const id = Number(props.match.params.id);
    setAccId(id);
    if (accountDetails.length > 0) {
      const data = accountDetails[0].accountDetails.filter(
        (acc) => acc.key === id
      );
      if (data.length > 0) {
        setAccData(data[0]);
      }
    }
  }, []);

  useEffect(() => {
    // dispatch(
    //   getactivityHistory({
    //     SSNorTIN: clientDetails.payload[0].ssnortin,
    //     AccountUniqueID: selectedAccount.AccountUniqueID,
    //   })
    // );
    GetCurrentBalanceInfo(
      clientDetails.payload[0].programid,
      clientDetails.payload[0].ssnortin,
      selectedAccount.AccountUniqueID
    )
      .then((response) => {
        let val = response.data;
        if (response.data.length >= 1) {
          let sum = response.data.reduce(
            (sum, account) => sum + account.Balance,
            0
          );
          setCurrentBalance(sum);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (showMoveFunds) {
      if (webuserAccessType == 0) {
        GetClientOrAccountLevelTransPermission(
          clientDetails.payload[0].ssnortin,
          selectedAccount.AccountUniqueID
        )
          .then((response) => {
            if (response.data === true) {
              setShowMoveFundsAccountLevel(true);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setShowMoveFundsAccountLevel(true);
      }
    }
  }, []);
  useEffect(() => {
    if (selectedAccount.AccNumber != '') {
      const data = accountProfile.AccountDetails.filter(
        (acc) => acc.AccNumber === selectedAccount.AccNumber
      );
      setAccountData(data[0]);
    }
  }, []);

  function setActiveTabAndStore(key) {
    setActiveTab(key);
    dispatch(storeInputSearch(''));
    dispatch(storeFilteredProductTypes([]));
    dispatch(storeFilteredTransactionTypes([]));
  }

  function handleClick() {
    history.push('/dashboard');
  }

  if (props.location.pathname.includes('/stop-payments')) {
    return accData && <StopPaymentsContainer accId={accId} accData={accData} />;
  }

  return (
    <AppLayout>
      {accountData && (
        <ContentWrapper>
          <Banner bgcolor={primary_color} height="254px">
            <Box
              display="flex"
              alignitems="center"
              height="100%"
              minwidth="100%"
              width="100%"
              zindex="3"
              position="relative"
            >
              <Container>
                <Anchor
                  onClick={handleClick}
                  fontsize={`${theme.font_size.size_l}`}
                  lineheight="20px"
                  color={`${theme.colors.pearl_river}`}
                  className="opac-text"
                >
                  <ArrowLeftOutlined style={{ marginRight: '6px' }} />
                  Back to Accounts Dashboard
                </Anchor>
                <Box
                  display="flex"
                  justifycontent="space-between"
                  alignitems="flex-start"
                  margin="12px 0 0"
                >
                  <Box flex="1">
                    {/* <Text
                      color={`${theme.colors.white}`}
                      fontSize="36px"
                      lineheight="44px"
                      label={accountData.AccName}
                    /> */}
                    <AccName>{accountData.AccName}</AccName>
                    <Box margin="32px -20px 0" display="flex">
                      <Box padding="0 20px">
                        <BalanceText>
                          Balance Summary as of{' '}
                          {moment(accountProfile.Balance_Date).format(
                            'MMM DD, YYYY'
                          )}
                        </BalanceText>
                        <Text
                          label={amountFormat(accountData.CurrentBalance)}
                          color={`${theme.colors.white}`}
                          fontSize={`${theme.font_size.size_3xl}`}
                          lineheight="32px"
                        />
                      </Box>
                      {showMoveFunds ? (
                        <Box padding="0 20px">
                          <BalanceText>
                            Current Liquid Balance
                            <Tooltip
                              placement="top"
                              title="Represents your End of Day liquid balance, from the prior Business day, plus or minus any pending transactions scheduled to settle today"
                              overlayClassName="tooltip"
                            >
                              <InfoCircleOutlined
                                style={{ cursor: 'pointer' }}
                              />
                            </Tooltip>
                          </BalanceText>
                          <Text
                            label={amountFormat(currentBalance)}
                            color={`${theme.colors.white}`}
                            fontSize={`${theme.font_size.size_xl}`}
                            lineheight="24px"
                          />
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                  <Space size={12}>
                    {config.EnableTransaction &&
                    showMoveFunds &&
                    showMoveFundsAccountLevel ? (
                      <OverrideButtonAnt
                        linkTextColor={secondary_color}
                        icon={false}
                        label="Move Funds"
                        type="secondary"
                        onClick={() => {
                          setIsMoveFundsVisible(!isMoveFundsVisible);
                        }}
                      />
                    ) : null}
                    {showMoveFunds ? (
                      <Link to={`/dashboard/settings/${1}`}>
                        <ButtonAnt
                          icon={false}
                          label="Settings"
                          type="secondary"
                        />
                      </Link>
                    ) : null}
                  </Space>
                </Box>
              </Container>
            </Box>
          </Banner>
          <>
            <Tabs
              activeTabColor={primary_color}
              defaultActiveKey={activeTab}
              //linkTextColor={primary_color}
              activeKey={activeTab}
              onTabClick={(key) => setActiveTabAndStore(key)}
              tabsOptions={[
                {
                  content: (
                    <SummaryContainer
                      linkTextColor={primary_color}
                      rgbaPrimaryHover={rgbaPrimary}
                      accData={accountData}
                      setActiveTab={setActiveTab}
                    />
                  ),
                  key: '1',
                  label: 'Summary',
                },
                {
                  content: (
                    <ActivityHistoryContainer
                      //rgbaPrimaryHover={rgbaPrimary}
                      //linkTextColor={primary_color}
                      //IsAccountLevelHistory={true}
                      //accId={accId}
                      {...props}
                      // isGlobal={false}
                    />
                  ),
                  key: '2',
                  label: 'Activity History',
                },
                ...(showMoveFunds ? [
                  {
                    content: (
                      <PendingActivityContainer
                        linkTextColor={primary_color}
                        rgbaPrimaryHover={rgbaPrimary}
                        setActiveTab={setActiveTab}
                        isGlobal={false}
                      />
                    ),
                    key: '3',
                    label: 'Pending Activity',
                  },
                  {
                    content: <HoldingsContainer />,
                    key: '4',
                    label: 'Holdings',
                  },
                ]
                : [
                  {
                    content: <HoldingsContainer />,
                    key: '3',
                    label: 'Holdings',
                  },
                ]),
              ]}
              type="horizontal-tab"
            />
          </>
          {isMoveFundsVisible && (
            <Modal
              title="Move Funds"
              width={590}
              bodyStyle={{
                padding: '0 0 64px 0',
              }}
              maskStyle={{
                backgroundColor: `${theme.rgba.modal}`,
                zIndex: '1051',
              }}
              content={<MoveFundsModal />}
              visible={isMoveFundsVisible}
              onCancel={() => handleFundsModalCancel()}
              footer={null}
              centered={true}
            />
          )}
          {isMoveFundsVisible && (
            <Modal
              title="Move Funds"
              width={590}
              bodyStyle={{
                padding: '0 0 64px 0',
              }}
              maskStyle={{
                backgroundColor: `${theme.rgba.modal}`,
                zIndex: '1051',
              }}
              content={<MoveFundsModal />}
              visible={isMoveFundsVisible}
              onCancel={() => handleFundsModalCancel()}
              footer={null}
              centered={true}
            />
          )}
        </ContentWrapper>
      )}
    </AppLayout>
  );
};

export default DashboardGeneralFunds;
