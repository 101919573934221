import React, { useEffect, useState } from 'react';
import ListWrapperComponent from '../../common/components/listWrapper';
import { theme, Text } from '@pfmam/pfmam';
import {GetWireInstructions} from '../../actions/actions';

const SendWireModalContent = ({fundDetails}) => {

  const [wireInstructions,setWireInstructions] =useState({"mvarABANumber": "",
                                                          "mvarBankAccountNumber": "",
                                                          "mvarBankCity": "",
                                                          "mvarBankName": "",
                                                          "mvarBankState": "",
                                                          "mvarBeneAccountNumber": "",
                                                          "mvarBeneDetails": "",
                                                          "mvarBeneName": "",
                                                          });
  useEffect(() => {
    GetWireInstructions(fundDetails.FundUniqueID) 
        .then((response) => {
          setWireInstructions(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
  }, [fundDetails.FundUniqueID]);
  return (
    <>
      <Text
        label="Send funds using the following wire instructions." 
        color={`${theme.colors.grey_tertiary}`}
        fontSize={`${theme.font_size.size_xl}`}
        lineheight="24px"
        margin="0 0 32px"
      />
      <ListWrapperComponent label="Bank Name" value={wireInstructions.mvarBankName} />
      <ListWrapperComponent
        label="Bank City, State"
        value={wireInstructions.mvarBankCity +', '+ wireInstructions.mvarBankState}
      />
      <ListWrapperComponent label="ABA#" value={wireInstructions.mvarABANumber} />
      <ListWrapperComponent
        label="Credit"
        value={wireInstructions.mvarBeneName}
      />
      <ListWrapperComponent label="Account Number" value={wireInstructions.mvarBankAccountNumber} />
      <ListWrapperComponent
        label="Further Credit"
        value={wireInstructions.mvarBeneDetails +' '+ wireInstructions.mvarBeneAccountNumber }
     />    
    </>
  );
};

export default SendWireModalContent;
