/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';
import {
  theme,
  Text,
  TableComponent,
  ButtonAnt,
} from '@pfmam/pfmam';
import { Link } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Box, Container } from '../../../styles/globalStyle';
import NoDataFound from '../../../common/components/noDataFound';
import AccountActions from '../accountActions';
const Wrapper = styled.div`
  padding-top: 40px;
`;

const AchAccountsContainer = ({
  achAccounts,
  handleAction,
  handleMenuClick,
  isGlobalSettingsPage,
}) => {
  const getColumns = () => {
    const permissionColumn = {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
      sorter: false,
      className: 'sort-space-none',
      width: 400,
      render: (text) => (
        <>
          {text && text.length > 0 ? (
            text.map((permission) => {
              return (
                <Text lineheight="20px" margin="0 0 4px 0" label={permission} />
              );
            })
          ) : (
            <Text
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.grey_tertiary}`}
              lineheight="20px"
              margin="4px 0 0"
              label={'- - - -'}
            />
          )}
        </>
      ),
    };
    const columns = [
      {
        title: 'Bank / Nickname',
        dataIndex: 'bankName',
        key: 'bankName',
        align: 'left',
        sorter: (a, b) => a.bankName.localeCompare(b.bankName),
        className: 'sort-space-none',
        width: 400,
        render: (text, record) => (
          <>
            {text}{' '}
            <Text
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.grey_tertiary}`}
              lineheight="20px"
              margin="4px 0 0"
              label={record.nickName ? record.nickName : '- - - -'}
            />
          </>
        ),
      },
      {
        title: 'Name on Account',
        dataIndex: 'nameOnAccount',
        key: 'nameOnAccount',
        sorter: (a, b) => a.nameOnAccount.localeCompare(b.nameOnAccount),
        className: 'sort-space-none',
        width: 320,
      },
      {
        title: '',
        dataIndex: 'righticon',
        key: 'righticon',
        align: 'right',
        className: 'action-cell',
        width: 80,
        render: (_, record) => (
          <AccountActions
            type="ACH"
            record={record}
            handleMenuClick={handleMenuClick}
            isGlobalSettingsPage={isGlobalSettingsPage}
          />
        ),
      },
    ];
    if (isGlobalSettingsPage) {
      columns.splice(2, 0, permissionColumn);
    }
    return columns;
  };

  return (
    <Container>
      <Wrapper>
        <TableComponent
          className="table-primary"
          title={
            <Box
              display="flex"
              justifycontent="space-between"
              alignitems="center"
              padding="24px 48px 24px 32px"
              border={`1px solid ${theme.colors.grey_fossil}`}
              borderwidth="0 0 1px 0"
            >
              <Text
                label="Linked ACH Accounts"
                fontSize={`${theme.font_size.size_3xl}`}
                lineheight="32px"
                color={`${theme.colors.black_tertiary}`}
              />
              {/* {isGlobalSettingsPage ? (
                <ButtonAnt
                  type="link"
                  label="Add ACH Accounts"
                  icon={<PlusCircleOutlined />}
                  onClick={(e) => {
                    handleMenuClick(e, null, 'ACH');
                  }}
                />
              ) : (
                <Link
                  to={{
                    pathname: '/settings',
                    accType: 'ACH',
                  }}
                >
                  <ButtonAnt type="link" label="Manage ACH Accounts" />
                </Link>
              )} */}
            </Box>
          }
          columns={getColumns()}
          dataSource={achAccounts}
          locale={{
            emptyText: <NoDataFound text="No ACH accounts" />,
          }}
          showSorterTooltip={false}
          pagination={
            achAccounts.length > 10
              ? {
                  position: ['bottomRight'],
                  showQuickJumper: true,
                  size: 'default',
                }
              : false
          }
          onRow={(record) => ({
            onClick: () => {
              handleAction('ACH', 'details', record);
            },
          })}
        />
      </Wrapper>
    </Container>
  );
};

export default AchAccountsContainer;
