import {
  configureStore,
  getDefaultMiddleware,
  combineReducers,
} from '@reduxjs/toolkit';
import userSlice from './services/userSlice';
import programsClientsSlice from './services/programClientsSlice';
import siteConfigurationSlice from './services/siteConfigurationSlice';
import accountProfileSlice from './services/accountProfileSlice';
import activityHistorySlice from './services/ActivityHistorySlice';
import menuPermissionSlice from './services/menuPermissionSlice';
import stopPaymentSlice from './services/stopPaymentSlice';
import dailyConfirmsSlice from './services/dailyConfirmsSlice';
import monthlyStatementsConfirmsSlice from './services/monthlyStatementsSlice';
import { createStateSyncMiddleware } from 'redux-state-sync';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import { Provider } from 'react-redux';

const persistConfig = {
  key: 'root',
  storage,
};
var moment = require('moment-timezone');
  const zone ="America/New_York"
  moment.tz.setDefault(zone);

const reducer = combineReducers({
  userSlice: userSlice,
  siteInfo: siteConfigurationSlice,
  programsClientsSlice: programsClientsSlice,
  accountProfileSlice: accountProfileSlice,
  activityHistorySlice: activityHistorySlice,
  menuPermissions: menuPermissionSlice,
  stopPaymentSlice: stopPaymentSlice,
  dailyConfirmsSlice: dailyConfirmsSlice,
  monthlyStatementsConfirmsSlice: monthlyStatementsConfirmsSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "SIGNOUT_REQUEST") {
      // for all keys defined in your persistConfig(s)
      storage.removeItem('persist:root')
      // storage.removeItem('persist:otherKey')
      return reducer(undefined, action);
  }
  return reducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware(),
    createStateSyncMiddleware({
      blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
    }),
  ],
});

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App store={store} />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
