import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout } from 'antd';
import styled from 'styled-components';
import {
  theme,
  Text,
  ButtonAnt,
  Modal,
} from '@pfmam/pfmam';
import {
  storeFilteredProductTypes,
  storeFilteredTransactionTypes,
  storeInputSearch,
  storeFilteredAccounts,
} from '../services/accountProfileSlice';
import { Container, Box, Banner } from '../styles/globalStyle';
import ActivityHistoryTable from './components/activityHistoryTable';
import { DownloadOutlined } from '@ant-design/icons';
import DetailsModalContent from '../common/components/detailsModal';
import CancelModalContent from '../common/components/cancelModal';
import RenderFilters from './components/renderFilters';
import AppLayout from '../common/layouts/appLayout';
import {
  GetPendingActivity,
  GetPendingActivityAdditionalInfo,
  GetClientOrAccountLevelTransPermission,
} from '../actions/actions';
import { useSelector, useDispatch } from 'react-redux';
import { ModelDisplayCleanup } from '../utils/common';
import {
  GetPendingActivityDetailsData,
  GetEnvisionPendingActivityDetailsData,
} from '../utils/pendingActivityRenderFilter';
import { getactivityHistory } from '../services/ActivityHistorySlice';

import { Link } from 'react-router-dom';

const { Content } = Layout;

const TableWrapper = styled.div`
  .ant-table {
    border-radius: 4px 4px 0 0;
    .ant-table-tbody {
      & > tr {
        &:hover {
          &.ant-table-row:not(.ant-table-expanded-row) {
            background: linear-gradient(
                0deg,
                ${(props) => props.rgbaPrimaryHover},
                ${(props) => props.rgbaPrimaryHover}
              ),
              ${theme.colors.white};
          }
        }
        cursor: pointer;
        & > td {
          vertical-align: top;
          padding: 20px 32px;
          &.ant-table-column-sort {
            background: ${theme.colors.transparent};
          }
          &.ant-table-row-expand-icon-cell {
            padding: 0;
            & > span {
              display: none;
            }
          }
        }
        &:last-child {
          td {
            border-bottom-color: ${theme.colors.transparent};
          }
        }
        &.inactive-accounts {
          & > td {
            background-color: ${theme.colors.grey_faint};
          }
        }
      }
    }
    .ant-table-thead {
      & > tr {
        & > th {
          background: ${theme.colors.light_grey};
          font-family: 'Inter';
          padding: 0 32px;
          font-weight: normal;
          line-height: 20px;
          &:not(:last-child) {
            &:not(.ant-table-selection-column) {
              &:not(.ant-table-row-expand-icon-cell) {
                &:not([colspan]) {
                  &::before {
                    background-color: ${theme.colors.transparent};
                  }
                }
              }
            }
          }
        }
      }
    }
    &.ant-table.ant-table-middle {
      .ant-table-thead {
        .ant-table-column-sorters {
          padding: 0;
          .ant-table-column-sorter-inner .anticon {
            color: ${theme.colors.grey_stone};
          }
          .ant-table-column-sorter-up.active,
          .ant-table-column-sorter-down.active {
            color: ${theme.colors.black_sorted};
          }
        }
        .ant-table-column-sorter-inner {
          margin-top: 0;
        }
      }
    }
    .sort-space-none {
      .ant-table-column-sorters {
        justify-content: flex-start;
      }
      .ant-table-column-title {
        flex: 0 0 auto;
      }
      &.flex-end {
        .ant-table-column-sorters {
          justify-content: flex-end;
        }
      }
    }
  }
  .ant-table-title {
    padding: 0 !important;
  }
  .table-primary {
    &:first-child {
      margin-bottom: 24px;
    }
  }
  .ant-table {
    &.ant-table-middle {
      .ant-table-tbody {
        .ant-table-wrapper {
          &:only-child {
            .ant-table {
              margin: -19px -32px;
            }
          }
        }
        .ant-table-container {
          .ant-table-tbody {
            .ant-table-row {
              .ant-table-cell {
                padding: 14px 32px;
                background-color: ${theme.colors.grey_faint};
              }
              &:hover {
                .ant-table-cell {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
`;
const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
  }
`;

const ActivityHistoryContainer = (props) => {
  const params = new URLSearchParams(
    props.location.search ? props.location.search : null
  );
  let programType = params.get('programType');
  console.log('props', props);
  const dispatch = useDispatch();
  const history = useHistory();
  const [closeCancelWindow, setCloseCancelWindow] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isCancelTransaction, setIsCancelTransaction] = useState(false);
  const [isCancelSuccess, setIsCancelSuccess] = useState(false);
  const [pendingActivities, setPendingActivities] = useState([]);
  const [HistoryActivities, setHistoryActivities] = useState([]);
  const [isGlobalActivityHistoryPage, setIsGlobalActivityHistoryPage] =
    useState(false);
  const ProgramLevelTransPermission = useSelector(
    (state) => state.siteInfo.allowOnlineTransact
  );
  const accountProfile = useSelector(
    (state) => state.accountProfileSlice.accountProfile
  );
  const clientHasAccounts = accountProfile.AccountDetails.length > 0 ? true : false;
  const isZeroBalClient=useSelector((state)=>state.programsClientsSlice.selectedClient.payload[0].current_balance!=="( $0.00 )");
  const [showStopPayment, setShowStopPayment] = useState(false);
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const selectedAccount = useSelector(
    (state) => state.accountProfileSlice.selectedAccount
  );
  const activityHistory = useSelector(
    (state) => state.activityHistorySlice.ActivityHistory
  );
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const rgbaPrimary = useSelector((state) => state.siteInfo.rgbaPrimary);
  const fundgroup_Id = useSelector((state) => state.siteInfo.fundGroupID);

  const [accountActivityHistory, setaccountActivityHistory] = useState([]);

  const checkedInvestmentTypes = useSelector(
    (state) => state.accountProfileSlice.filteredInvestmentTypes
  );

  const checkedAccounts = useSelector(
    (state) => state.accountProfileSlice.filteredAccounts
  );

  const checkedTransactionTypes = useSelector(
    (state) => state.accountProfileSlice.filteredTransactionTypes
  );
  const webuserAccessType = useSelector((state) => state.userSlice.webUserType);
  const [inputSearchTerm, setInputSearchTerm] = useState('');
  const programId = clientDetails.payload[0].programid;
  const ssnorTIN = clientDetails.payload[0].ssnortin;
  const secondary_color = useSelector((state) => state.siteInfo.secondaryColor);
  const [showInactiveAcc, setShowInactiveAcc] = useState(false);
  const isStopPaymentEnabledForFund = useSelector((state) =>
    state.menuPermissions.menuPermissionOptions.includes(9)
  );

  const [isCheckRedemtionExpanded, setIsCheckRedemtionExpanded] =
    useState(null);
  useEffect(() => {
    return function cleanup() {
      // dispatch(storeInputSearch(''));
      dispatch(storeFilteredProductTypes([]));
      dispatch(storeFilteredTransactionTypes([]));
      dispatch(storeFilteredAccounts([]));
    };
  }, []);

  useEffect(() => {
    if (
      props.location.pathname.includes('/activity') &&
      Object.keys(props.match.params).length === 0 &&
      !isGlobalActivityHistoryPage
    ) {
      dispatch(
        getactivityHistory({ SSNorTIN: ssnorTIN, ProgramId: programId })
      );
      setIsGlobalActivityHistoryPage(true);
    }
  }, [isGlobalActivityHistoryPage]);

  function applyFiltersToData() {
    let Activities = [];
    let FilteredActivity = [];
    if (isGlobalActivityHistoryPage) {
      Activities = activityHistory;
    } else {
      Activities = activityHistory.filter(
        (activity) =>
          activity.AccountUniqueID === selectedAccount.AccountUniqueID
      );
    }

    if (
      checkedInvestmentTypes.length > 0 ||
      checkedTransactionTypes.length > 0 ||
      checkedAccounts.length > 0 ||
      inputSearchTerm
    ) {
      FilteredActivity = Activities.filter(function (elem) {
        // the current value is an object, so you can check on its properties
        let Investtypefilter = true;
        let InputSearchTermFilter = true;
        let TransactionTypefilter = true;
        let Accountfilter = true;

        if (checkedInvestmentTypes.length > 0) {
          Investtypefilter = checkedInvestmentTypes.includes(
            elem.InvestmentTypeClassDesc
          );
        }
        if (checkedTransactionTypes.length > 0) {
          TransactionTypefilter = checkedTransactionTypes.includes(
            elem.TransactionType
          );
        }
        if (checkedAccounts.length > 0 && isGlobalActivityHistoryPage) {
          Accountfilter = checkedAccounts.includes(elem.AccountUniqueID);
        }
        if (inputSearchTerm) {
          InputSearchTermFilter =
            elem.TransDesc.toUpperCase().indexOf(
              inputSearchTerm.toUpperCase()
            ) > -1 ||
            (elem.CheckDetails !== undefined
              ? elem.CheckDetails.some(
                  (IndividualCheck) =>
                    IndividualCheck.TransDesc.toUpperCase().indexOf(
                      inputSearchTerm.toUpperCase()
                    ) > -1
                )
              : false) ||
            (elem.CUSIP
              ? elem.CUSIP.toUpperCase().indexOf(
                  inputSearchTerm.toUpperCase()
                ) > -1
              : false);
        }
        return (
          Investtypefilter &&
          InputSearchTermFilter &&
          TransactionTypefilter &&
          Accountfilter
        );
      });
    } else {
      FilteredActivity = Activities;
    }

    setaccountActivityHistory(FilteredActivity);
    setHistoryActivities(FilteredActivity);
  }

  useEffect(() => {
    applyFiltersToData();
  }, [
    checkedInvestmentTypes,
    checkedTransactionTypes,
    inputSearchTerm,
    checkedAccounts,
    isGlobalActivityHistoryPage,
    activityHistory,
  ]);

  useEffect(() => {
    if (ProgramLevelTransPermission == true) {
      GetPendingActivity(programId, ssnorTIN, null)
        .then((response) => {
          if (response.data != null) {
            if (props.isGlobal) {
              setPendingActivities(response.data);
            } else {
              var filteredPendingActivities = response.data.filter(
                (x) =>
                  x.AccountUniqueID == selectedAccount.AccountUniqueID.trim()
              );
              setPendingActivities(filteredPendingActivities);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [ProgramLevelTransPermission, closeCancelWindow, props.isGlobal]);

  function checkedTransactionType(pendingTransactionTypeClassID) {
    return pendingTransactionTypeClassID == 9;
  }

  useEffect(() => {
    if (ProgramLevelTransPermission == true) {
      //Check if Fund Group allows stop payment
      if(isStopPaymentEnabledForFund){
        if (webuserAccessType == 0) {
          if (props.isGlobal) {
            GetClientOrAccountLevelTransPermission(ssnorTIN, null)
              .then((response) => {
                if (response.data === true) {
                  setShowStopPayment(true && (isZeroBalClient || clientHasAccounts));
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            GetClientOrAccountLevelTransPermission(
              ssnorTIN,
              selectedAccount.AccountUniqueID
            )
              .then((response) => {
                if (response.data === true) {
                  setShowStopPayment(true && (isZeroBalClient || clientHasAccounts));
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } else {
          setShowStopPayment(true && (isZeroBalClient || clientHasAccounts));
        }
      }
    }
  }, [ProgramLevelTransPermission, props.isGlobal,isStopPaymentEnabledForFund]);

  const handleSearch = (searchstring) => {
    setInputSearchTerm(searchstring);
  };

  const dateChangeAction = (startDate, endDate) => {
    //isGlobalActivityHistoryPage
    // ?
    dispatch(
      getactivityHistory({
        SSNorTIN: ssnorTIN,
        ProgramId: programId,
        EndSettlementDate: endDate.format('YYYY-MM-DD'),
        StartSettlementDate: startDate.format('YYYY-MM-DD'),
      })
    );
  };
  const handleAction = (type, data, rowClick = false) => {
    if (type === 'details') {
      setIsDetailsModalVisible(!isDetailsModalVisible);
      setSelectedRecord(data);
    }
  };

  const handleModalCancel = (type) => {
    if (type === 'cancel') {
      setIsCancelModalVisible(!isCancelModalVisible);
      setIsCancelTransaction(false);
      if (isCancelSuccess) {
        //Fetch the pending transaction once the transaction is successfully reversed. Else status wont be reflected in the UI.
        setCloseCancelWindow(!closeCancelWindow);
      }
      setIsCancelSuccess(false);
    }
    if (type === 'details') {
      setIsDetailsModalVisible(!isDetailsModalVisible);
    }
    setSelectedRecord(null);
  };

  return (
    <>
      {isGlobalActivityHistoryPage ? (
        <>
            <Content>
              <Container>
                <Box
                  padding={isGlobalActivityHistoryPage ? '32px 0 0 0' : null}
                >
                  <RenderFilters
                    data={
                      accountActivityHistory.length > 0
                        ? accountActivityHistory
                        : []
                    }
                    isGlobalActivityHistoryPage={isGlobalActivityHistoryPage}
                    handleSearch={handleSearch}
                    dateChangeAction={dateChangeAction}
                  />
                  <TableWrapper rgbaPrimaryHover={rgbaPrimary}>
                    <ActivityHistoryTable
                      isGlobalActivityHistoryPage={isGlobalActivityHistoryPage}
                      data={HistoryActivities.length ? HistoryActivities : []}
                      handleAction={handleAction}
                      isCheckRedemtionExpanded={isCheckRedemtionExpanded}
                      setIsCheckRedemtionExpanded={setIsCheckRedemtionExpanded}
                      type="completed"
                    />
                  </TableWrapper>
                </Box>
              </Container>
            </Content>
        </>
      ) : (
        <Container>
          {!isGlobalActivityHistoryPage && (
            <Box
              padding="36px 0 28px 0"
              display="flex"
              alignitems="center"
              justifycontent="space-between"
            >
              <Text
                label="Activity History"
                fontSize="36px"
                lineheight="44px"
                color={`${theme.colors.black_secondary}`}
              />
              {showStopPayment ? (
                <OverrideButtonAnt
                  icon={false}
                  label="Stop Payments"
                  linkTextColor={secondary_color}
                  type="secondary"
                  onClick={() =>
                    history.push(`/dashboard/details/1/stop-payments`)
                  }
                />
              ) : null}
            </Box>
          )}
          <Box padding={isGlobalActivityHistoryPage ? '32px 0 0 0' : null}>
            <RenderFilters
              data={
                accountActivityHistory.length > 0 ? accountActivityHistory : []
              }
              isGlobalActivityHistoryPage={isGlobalActivityHistoryPage}
              handleSearch={handleSearch}
              dateChangeAction={dateChangeAction}
            />
            <TableWrapper rgbaPrimaryHover={rgbaPrimary}>
              <ActivityHistoryTable
                isGlobalActivityHistoryPage={isGlobalActivityHistoryPage}
                data={HistoryActivities.length ? HistoryActivities : []}
                handleAction={handleAction}
                isCheckRedemtionExpanded={isCheckRedemtionExpanded}
                setIsCheckRedemtionExpanded={setIsCheckRedemtionExpanded}
                type="completed"
              />
            </TableWrapper>
          </Box>
        </Container>
      )}
      {isCancelModalVisible && (
        <Modal
          title={
            isCancelSuccess
              ? 'Transaction Cancelled'
              : isCancelTransaction && selectedRecord.Cancellable
              ? 'Cancel Transaction?'
              : 'Activity Details'
          }
          content={
            <CancelModalContent
              selectedRecord={selectedRecord}
              isCancelTransaction={isCancelTransaction}
              setIsCancelTransaction={setIsCancelTransaction}
              handleModalCancel={handleModalCancel}
              isCancelSuccess={isCancelSuccess}
              setIsCancelSuccess={setIsCancelSuccess}
            />
          }
          visible={isCancelModalVisible}
          onCancel={() => handleModalCancel('cancel')}
          footer={null}
          width={590}
          bodyStyle={{
            padding: '32px 32px 48px 32px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          centered={true}
        />
      )}
      {isDetailsModalVisible && (
        <Modal
          title="Activity Details"
          width={590}
          bodyStyle={{
            padding: '32px 32px 34px 32px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          content={<DetailsModalContent selectedRecord={selectedRecord} />}
          visible={isDetailsModalVisible}
          onCancel={() => handleModalCancel('details')}
          footer={null}
          centered={true}
        />
      )}
    </>
  );
};

export default ActivityHistoryContainer;
