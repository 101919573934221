import React from 'react';
import { useSelector } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import {
  ButtonAnt,
  theme,
  Text,
  TableComponent,
} from '@pfmam/pfmam';
import { Space } from 'antd';
import NoDataFound from '../../common/components/noDataFound';
import { Box } from '../../styles/globalStyle';
const ButtonWrapper = styled(Space)`
  width: 100%;
  justify-content: flex-end;
  padding: 40px 32px 0;
`;
const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
  }
`;
const OverrideTableStyle = createGlobalStyle`
  .ant-table-wrapper {
  &.table-primary {
      .ant-table-thead > tr > th {
        font-family: 'Inter';
        font-weight: normal;
        &:first-child,
        &:last-child {
          padding: 8px 32px;
        }
        
      }
      .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        content: none;
      }
      .ant-table-tbody > tr > td {
        padding: 20px 8px;
        vertical-align: top;
        font-size:${theme.font_size.size_xl};
        color:${theme.colors.black_tertiary};
        line-height:24px;
        border-bottom-color: ${theme.colors.grey_fossil};
        &:first-child{
          padding-left:32px;
        }
        &:last-child{
          padding-right:32px;
        }
      }
      .ant-table-title {
        display: none;
      }
    }
  }
`;
const DuplicateTransactionModalContent = ({
  columns,
  data,
  handleDuplicateModalCancel,
  handleDuplicateModalSubmit,
}) => {
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  return (
    <>
      <OverrideTableStyle />
      <Box width="550px" padding="0 0 24px 32px">
        <Text
          label="A possible duplicate transaction has been detected. Would you like to proceed?"
          fontSize={theme.font_size.size_xl}
          color={theme.colors.black_tertiary}
          lineheight="24px"
          padding=""
        />
      </Box>
      <TableComponent
        className="table-primary"
        showHeader={true}
        columns={columns}
        dataSource={data}
        locale={{
          emptyText: (
            <NoDataFound text="No duplicate transactions" padding="18px 16px" />
          ),
        }}
        pagination={
          data.length > 10
            ? {
                position: ['bottomRight'],
                showQuickJumper: true,
                size: 'default',
              }
            : false
        }
        title={false}
      />
      <ButtonWrapper size={12}>
        <ButtonAnt
          type="secondary"
          label="Discard Transaction"
          onClick={() => handleDuplicateModalCancel()}
        />
        <OverrideButtonAnt
          linkTextColor={primary_color}
          type="primary"
          label="Submit Transaction"
          htmlType="submit"
          onClick={() => {
            handleDuplicateModalSubmit();
          }}
        />
      </ButtonWrapper>
    </>
  );
};

export default DuplicateTransactionModalContent;
