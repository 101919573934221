import format from 'format-number';
export const amountFormat = (value, round = 2, truncate = 2) => {
  const number = Number(value);
  return format({
    prefix: '$',
    padRight: 2,
    round: round,
    truncate: truncate,
    negativeType: 'brackets',
  })(number);
};

export const CheckClientSSNIsNumeric=(ssnrotin)=>{
  return !isNaN(ssnrotin)
}
export const percentFormat = (value) => {
  const number = Number(value);
  return format({
    suffix: '%',
    round: 2,
    padRight: 2,
    negativeType: 'brackets',
  })(number);
};
const formatPercentage = (value, locale = 'en-GB') => {
  return Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};
export const percentFormatTerm = (value) => {
  const number = Number(value);
  return formatPercentage(number);
};

export const numberFormat = (value) => {
  const number = Number(value);
  return format({ padRight: 2, round: 2, negativeType: 'brackets' })(number);
};

export const dollarToFloat = (value) => {
  return parseFloat(
    value
      .replace(/(\$|,)/g, '')
      .replace('(', '-')
      .replace(')', '')
  );
};
