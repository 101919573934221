import { createSlice } from '@reduxjs/toolkit';
import { setAuthHeader } from '../utils/axiosHeaders';
export const initialState = {
  user: null,
  isLoadingUser: false,
  webUserType: -1,
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    storeUser: (state, action) => {
      if (action.payload != null) {
        setAuthHeader(action.payload.access_token);
      }

      return {
        ...state,
        isLoadingUser: false,
        user: action.payload,
      };
    },
    storeUserError: (state, action) => {
      return {
        ...state,
        isLoadingUser: true,
      };
    },
    setWebUserType: (state, { payload }) => {
      state.webUserType = payload;
    },
  },
});

export const { storeUser, storeUserError, setWebUserType } = userSlice.actions;
export default userSlice.reducer;
