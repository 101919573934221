import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import format from 'format-number';
import {
  theme,
  ButtonAnt,
  InputComponent,
  Text,
  InputNumberComponent,
  Modal,
} from '@pfmam/pfmam';
import { EditOutlined } from '@ant-design/icons';
import { Form, DatePicker, Row, Col, Space,message } from 'antd';
import moment from 'moment';
import { validateAmount } from '../../../utils/common';
import {
  IsValidStopPaymentRequest, 
  IsDuplicateTransactionStopPayments
} from '../../../actions/actions';
import DuplicateTransactionModalContent from '../../../transactionPages/common/duplicateTransactionModalContent';
import { amountFormat } from '../../../utils/numberFormat';
import AccInvestmentTypeInput from '../../../transactionPages/common/accInvestmentTypeInput';
const SuffixButton = styled(ButtonAnt)`
  &.ant-btn {
    font-size: ${theme.font_size.size_xl};
    color: ${theme.colors.black_secondary};
    line-height: 24px;
    padding: 0;
    border: 0;
    height: 100%;
  }
`;
const ButtonWrapper = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
`;

const InitiateStopPaymentForm = ({
  stopPaymentsData,
  setStopPaymentsData,
  setIsReviewStopPayment,
  setIsStopPaymentCompleted,
  showInvestmentTypeModal,
  setShowInvestmentTypeModal,
  setInitiateStopPayment,
  form,
  accountName,
  isGlobalStopPaymentsPage,
  fundUniqueID,
  accountUniqueID,
  webuserAccessType,
}) => {
  const [isDuplicateTrans, setIsDuplicateTrans]=useState();
  const [isDuplicateTransModalVisible, setIsDuplicateTransModalVisible] = useState(false);
  const [duplicateTransData, setDuplicateTransData] = useState([]);
  const [formData, setFormData] = useState([]);

  const duplicateTransColumns = [
    {
      title: 'Confirmation #',
      dataIndex: 'Confirm #',
      key: 'Confirm #',
      width: 175,
      align: 'left',
    },
    {
      title: 'Account Number',
      dataIndex: 'Account Number',
      key: 'Account Number',
      width: 120,
      align: 'left',
      render: (text, record) => (
        <>
        <Text
          label={text}
          color={`${theme.colors.black_secondary}`}
          fontSize={`${theme.font_size.size_xl}`}
          lineheight="24px"
        />
        </>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      width: 70,
      align: 'left',
      render: (text) => <>{amountFormat(text)}</>,
    },
    {
      title: 'Transaction Type',
      dataIndex: 'Transaction Type',
      key: 'Transaction Type',
      width: 130,
      align: 'left',  
    },
    {
      title: 'Check Date',
      dataIndex: 'Check Date',
      key: 'Check Date',
      width: 120,
      align: 'left',
      render: (text, record) => (
        <>
        <Text
          label={moment(text).format('MMM DD, YYYY')}
          color={`${theme.colors.black_secondary}`}
          fontSize={`${theme.font_size.size_xl}`}
          lineheight="24px"
        />        
        </>
      ),
    },  
    {
      title: 'Created By',
      dataIndex: 'Created By',
      key: 'Created By',
      width: 120,
      align: 'left',      
    },
    {
      title: 'Created',
      dataIndex: 'Created Date',
      key: 'Created Date',
      width: 148,
      align: 'left', 
      render: (text, record) => (
        <>
        <Text
          label={new Date(text).toLocaleString()}
          color={`${theme.colors.black_secondary}`}
          fontSize={`${theme.font_size.size_xl}`}
          lineheight="24px"
        />        
        </>
      ),     
    }, 
    {
      title: 'Created Source',
      dataIndex: 'Created Source',
      key: 'Created Source',
      width: 170,
      align: 'right',      
    }, 
  ];

  const handleDuplicateModalSubmit = () => {
    setIsDuplicateTransModalVisible(false);    
    setIsReviewStopPayment(true);
    setIsStopPaymentCompleted(false);

  };

  const handleDuplicateModalCancel = () => {
    setIsDuplicateTransModalVisible(false);     
  };

  const onFinish = (values) => {
    setFormData(values);
    IsValidStopPaymentRequest(accountUniqueID,fundUniqueID,values.date,values.checkNumber,values.payee,values.reasonForStop,webuserAccessType,values.amount)
    .then((response) => {
      if (response.data !== null && response.data.length > 0) {
        if(response.data =='Valid'){         

            //Checking for duplicate Transactions
            IsDuplicateTransactionStopPayments(new Date(values.date).toDateString(), accountUniqueID,accountUniqueID ,values.amount, 9)
            .then((response) => {
              if (response.data !== null && response.data.Table.length > 0) {
                setDuplicateTransData(response.data.Table);
                setIsDuplicateTransModalVisible(true);
                setIsDuplicateTrans(true);
                setStopPaymentsData(values);
              }
              else {
                setIsDuplicateTrans(false);                
                setStopPaymentsData(values);
                setIsReviewStopPayment(true);
                setIsStopPaymentCompleted(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });

        }
        else{
          message.error(response.data);
        }
      }
     
    })
    .catch((error) => {
      console.log(error);
    });  
   
  };
  const onDateChange = (date) => {
    form.setFieldsValue({ date: date });
  };

  const disabledDate = (current) => {
    return current > moment().endOf('day');
  };

  const numberFormat = (value) => {
    const number = Number(value);
    return format({ negativeType: 'brackets' })(number);
  };

  const validateCheckAmount = (value) => {
    const numStr = String(value);
    let length = 0;
    // String Contains Decimal
    if (numStr.includes('.')) {
      length = numStr.split('.')[1].length;
    }

    if(value >= 100000000){
      return Promise.reject(`Amount is greater than highest acceptable amount.`);
    }

    if (length > 2) {
      return Promise.reject('Check Amount cannot have more than 2 decimals');
    } else {
      return Promise.resolve();
    }
  };

  return (
    <>
      {!isGlobalStopPaymentsPage && (
        <>
          <Text
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.black_secondary}`}
            lineheight="24px"
            label="Account"
          />
          <Text
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.black_secondary}`}
            lineheight="24px"
            label={accountName}
            margin="8px 0 32px"
          />
        </>
      )}
      <Form
        form={form}
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
        className="forms"
      >
        <Row gutter={24}>
          <Col span={24}>
          <AccInvestmentTypeInput
          form={form}
          showInvestmentTypeModal={showInvestmentTypeModal}
          setShowInvestmentTypeModal={setShowInvestmentTypeModal}
          amount={null}
        />
          </Col>
          <Col span={24}>
            <Form.Item
              label="Check payee"
              name="payee"
              rules={[
                {
                  required: true,
                  message: 'Check payee Required',
                },
              ]}
            >
              <InputComponent type="default" placeholder="Enter check payee" MaxLength="255" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Check Number"
              name="checkNumber"
              rules={[
                {
                  required: true,
                  message: 'Check Number Required',
                },
              ]}
            >
              <InputComponent type="default" placeholder="Enter check Number" MaxLength="20"/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Check Amount"
              name="amount"
              rules={[
                {
                  validator: (_, value) =>
                    value && validateAmount(value)
                      ? validateCheckAmount(value)
                      : value
                      ? Promise.reject('Please enter a valid check amount')
                      : Promise.reject('Check Amount Required'),
                },
              ]}
            >
              <InputNumberComponent
                placeholder="$ Enter Amount"
                showHandleButton={false}
                defaultValue={null}
                formatter={(value, info) => {
                  if(!info.userTyping)
                  {
                    let val = value !== '' ? numberFormat(value) : null;
                    if(val != null){
                      if (String(value).includes('.')) {
                        let length = String(value).split('.')[1].length;
                        if(length > 2){
                          return "$" + val;
                        }
                      }
                      val = amountFormat(val.toString().replace(/,/g,''));
                    }
                    return val;
                  } else {
                    return value !== '' ? '$' + numberFormat(value) : null;
                  }
                }}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Check Date"
              name="date"
              rules={[
                {
                  type: 'object',
                  required: true,
                  message: 'Check Date Required',
                },
              ]}
            >
              <DatePicker
                onChange={onDateChange}
                disabledDate={disabledDate}
                format="MMM DD, YYYY"
                //allowClear={false}
                className="suffix-left"
                dropdownClassName="single-date-picker"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Reason for Stop"
              name="reasonForStop"
              rules={[
                {
                  validator: (_, value) =>
                  (value ="" || value.trim() === "")
                    ? Promise.reject('Reason for Stop Required')                 
                    : Promise.resolve()
                },
              ]}
            >
              <InputComponent type="textarea" placeholder="Enter Reason" MaxLength="255" />
            </Form.Item>
          </Col>
        </Row>
        <ButtonWrapper size={12}>
          <ButtonAnt
            label="Cancel"
            type="secondary"
            htmlType="button"
            onClick={() => {
              setInitiateStopPayment(false)
              window.location.reload();
            }}
          />
          <ButtonAnt label="Review" type="primary" htmlType="submit" />
        </ButtonWrapper>
      </Form>
      {isDuplicateTransModalVisible && (
      <Modal
        title="Possible Duplicate Transaction"
        width='77%'
        bodyStyle={{
          padding: '32px 0 40px',
        }}
        maskStyle={{
          backgroundColor: `${theme.rgba.modal}`,
          zIndex: '1051',
        }}
        footer={null}
        visible={isDuplicateTransModalVisible}
        onCancel={() => setIsDuplicateTransModalVisible(false)}
        content={
          <DuplicateTransactionModalContent
            columns={duplicateTransColumns}
            data={duplicateTransData}
            handleDuplicateModalCancel={handleDuplicateModalCancel}
            handleDuplicateModalSubmit={handleDuplicateModalSubmit}
          />
        }
        centered={true}
      />
    )}
    </>
  );
};

export default InitiateStopPaymentForm;
