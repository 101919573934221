import { UserManager, WebStorageStateStore } from 'oidc-client';
import { storeUser, storeUserError } from './userSlice';
import config from '../config';

const userStore = new WebStorageStateStore({ store: window.localStorage });

const authconfig = {
  authority: config.AUTH_URL,
  client_id: '1',
  redirect_uri: config.CONNECT_REDIRECT_URI,
  response_type: 'id_token token',
  scope: 'openid profile api1 email',
  post_logout_redirect_uri: config.CONNECT_POSTLOGOUT_REDIRECT_URI,
  userStore,
};

const userManager = new UserManager(authconfig);

export async function loadUserFromStorage(store) {
  try {
    // console.log('load from user storage');
    let user = await userManager.getUser();
    if (!user) {
      return store.dispatch(storeUserError());
    }
    store.dispatch(storeUser(JSON.parse(user.toStorageString())));
  } catch (e) {
    // console.error(`User not found: ${e}`);
    store.dispatch(storeUserError());
  }
}

export function signinRedirect() {
  window.localStorage.removeItem('CSG');
  return userManager.signinRedirect();
}

export function signinRedirectCallback() {
  window.localStorage.removeItem('CSG');
  return userManager.signinRedirectCallback();
}

export function signoutRedirect(user_logout, dispatch) {
  document.cookie = 'CSG12=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'AuthLog=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  if(config.CSI_DOMAIN == 'localhost'){
    document.cookie = 'CSI=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  } else {
    document.cookie = 'CSI=;domain=' + config.CSI_DOMAIN +';expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
  window.localStorage.clear();
  userManager.clearStaleState();
  userManager.removeUser();
  let returnVal = userManager.signoutRedirect({ id_token_hint: user_logout });
  //This clears the persist:root localstorage
  dispatch({ type: "SIGNOUT_REQUEST" });
  return returnVal;
}

export function multipleSessionSignoutRedirect(dispatch, user_logout) {
  document.cookie = 'CSG12=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'AuthLog=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  if(config.CSI_DOMAIN == 'localhost'){
    document.cookie = 'CSI=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  } else {
    document.cookie = 'CSI=;domain=' + config.CSI_DOMAIN +';expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
  window.localStorage.clear();
  userManager.clearStaleState();
  userManager.removeUser();

  let returnVal = userManager.signoutRedirect({
    id_token_hint: user_logout,
    extraQueryParams: {
      errorMessage:
        'You have started a new session. This session is now logged out.',
    },
  });
  dispatch({ type: "SIGNOUT_REQUEST" });
  return returnVal;
}

export function signoutRedirectCallback(dispatch) {
  dispatch({ type: "SIGNOUT_REQUEST" });
  document.cookie = 'CSG12=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'AuthLog=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  if(config.CSI_DOMAIN == 'localhost'){
    document.cookie = 'CSI=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  } else {
    document.cookie = 'CSI=;domain=' + config.CSI_DOMAIN +';expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
  window.localStorage.clear();
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirectCallback();
}

export default userManager;
