import React, { useEffect, useState } from 'react';
import {
  theme,
  Text,
  TableComponent
} from '@pfmam/pfmam';
import { Form, DatePicker, Table, Col } from 'antd';
import styled from 'styled-components';
import { amountFormat } from '../../../utils/numberFormat';
import { validateAmount } from '../../../utils/common';
import { Box } from '../../../styles/globalStyle';
import moment from 'moment';
import NoDataFound from '../../../common/components/noDataFound';
import LoadingData from '../../../common/components/LoadingSpinner/LoadingData';
const TableWrapper = styled.div`
  .ant-table {
    border-radius: 4px 4px 0 0;
    .ant-table-tbody {
      & > tr {  
          &.ant-table-row:not(.ant-table-expanded-row) {
            background: linear-gradient(
                0deg,
                ${(props) => props.rgbaPrimaryHover},
                ${(props) => props.rgbaPrimaryHover}
              ),
              ${theme.colors.white};
          }
        }
        &:last-child {
          td {
            border-bottom-color: ${theme.colors.transparent};
            padding-top: 2px;
            padding-bottom: 1px;
          }
        }
 
      }
    }
    .ant-table-thead {
      & > tr {
        & > th {
          background: ${theme.colors.light_grey};
          font-family: Inter !important;
          font-weight: bold;
          line-height: 20px;
          &:not(:last-child) {
            &:not(.ant-table-selection-column) {
              &:not(.ant-table-row-expand-icon-cell) {
                &:not([colspan]) {
                  &::before {
                    background-color: ${theme.colors.transparent};
                  }
                }
              }
            }
          }
        }
      }
    }
    .ant-table-thead {
      & > tr {
        & > th:first-child {
          .ant-table-column-title {
            flex: 0 0 auto;
          }
        }
      }
    }
    .ant-table-thead {
      & > tr {
        & > th:last-child {
          padding:20px;
        }
      }
    }
    &.ant-table.ant-table-middle {
      .ant-table-thead {
        .ant-table-column-sorters {
          padding: 0;
          .ant-table-column-sorter-inner .anticon {
            color: ${theme.colors.grey_stone};
          }
          .ant-table-column-sorter-up.active,
          .ant-table-column-sorter-down.active {
            color: ${theme.colors.black_sorted};
          }
        }
        .ant-table-column-sorter-inner {
          margin-top: 0;
        }
      }
    }
    .sort-space-none {
      .ant-table-column-sorters {
        justify-content: flex-start;
      }
     
    }
  }
  .ant-table-title {
    padding: 0 !important;
  }
  .table-primary {
    &:first-child {
      margin-bottom: 24px;
    }
  }
  .ant-empty-normal {
    margin: -37px 0;
    visibility: hidden;
}
  .ant-table {
    &.ant-table-middle {
      .ant-table-tbody {
        .ant-table-wrapper {
          &:only-child {
            .ant-table {
              margin: -19px -32px;
            }
          }
        }
        .ant-table-container {
          .ant-table-tbody {
            .ant-table-row {
              .ant-table-cell {
                background-color: ${theme.colors.grey_faint};
              }
              &:hover {
                .ant-table-cell {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
`;




const GreyBox = styled(Box)`
  &.multi-banks-acc-content {
    .ant-input-number-input {
      text-align: right;
      padding: 0 20px;
    }
  }
  .field-wrapper {
    align-items: flex-start;
  }
`;

const columns = [
  {
    title: 'Participant Instructions',
    dataIndex: 'participant',
    key: 'participant',
    align:'left',
    className: 'sort-space-none',
    sorter: (a, b) => {
      return (
        (a.entityName).localeCompare(b.entityName)
      )
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    align:'right',
    key: 'amount'
  }
];



const MultiTableParticipant = ({
  vals,
  formTotal,
  setFormTotal,
  form,
  availableBal,
  participantData,
  showTableData,
  defaultDate,
  loading
}) => {
  const disabledDate = (current) => {
    var x = moment().endOf("day");
    // Can not select days before default date and weekends
    return (current.isoWeekday() === 7 || current.isoWeekday() === 6 || current < defaultDate.startOf('day')); //&& current.isoWeekday() ===6;
  }
  useEffect(() => {
    let data = form.getFieldsValue();
    let sum = 0;
    let propertyValues = Object.values(data);
    const result = propertyValues.filter(obj => {
      return obj && obj.amount != '';
    });
    result.forEach((obj) => {
      let val = 0;
      if (obj && obj.amount && validateAmount(obj.amount)) {
        val = parseFloat(obj.amount);
      }
      sum = sum + val;
    });
    setFormTotal(sum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vals]);

  return (
    <>
      {loading === true ? <LoadingData /> :
        (
          <GreyBox
        boxcolor={`${theme.colors.grey_faint}`}
        padding={participantData.length>0?"0 15px 0 15px":null}
        margin="0 -32px 0 -32px"
      >
          <TableWrapper rgbaPrimaryHover={`${theme.colors.light_grey}`}>
            <TableComponent
              className="table-primary"
              rowKey={(r) => r.id}
              columns={columns}
              dataSource={participantData}
              locale={loading != null ? {
                emptyText: (
                    <NoDataFound
                        text="No Participant instructions found"
                        padding="12px 20px"
                    />
                ),
            } : null}
              showSorterTooltip={false}
              pagination={false}
            />
          </TableWrapper>
          </GreyBox>
        )}
      <GreyBox
        boxcolor={`${theme.colors.grey_faint}`}
      >
        <Box
          display="flex"
          alignitems="center"
          justifycontent="space-between"
          padding="10px 20px 10px 5px"
        >
          <Text
            label="Transaction Total"
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_tertiary}`}
            lineheight="50px"
          />
          <Box>
            <Text
              label={amountFormat(formTotal)}
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.black_secondary}`}
              lineheight="24px"
            />
          </Box>
        </Box>
      </GreyBox>

      <Box padding="32px 0px">
        <Form.Item
          label="Transaction Date"
          name="date"
          rules={[
            {
              type: 'object',
              required: true,
              message: 'Transaction Date Required',
            },
          ]}
        >
          <DatePicker
            format="MMM DD, YYYY"
            allowClear={false}
            className="suffix-left"
            dropdownClassName="single-date-picker"
            defaultValue={moment()}
            disabledDate={disabledDate}
          />
        </Form.Item>
      </Box>

    </>

  );
};


export default MultiTableParticipant;
