import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form, Space, DatePicker } from 'antd';
import { Box } from '../../styles/globalStyle';
import { PlusCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {
  InputNumberComponent,
  ButtonAnt,
  theme,
  Text,
} from '@pfmam/pfmam';
import moment from 'moment';
import { amountFormat } from '../../utils/numberFormat';
import { validateAmount } from '../../utils/common';
import {
  disabledDate,
  numberFormat,
  validateCheckAmount,
} from './commonFunctions';
import { Promise } from 'q';
const ActionButton = styled(ButtonAnt)`
  height: 50px;
  width: 32px;
  padding: 7px 9px;
  color: ${(props) => props.color};
  &.ant-btn-link[disabled],
  &.ant-btn-link[disabled]:hover,
  &.ant-btn-link[disabled]:focus,
  &.ant-btn-link[disabled]:active {
    opacity: 1;
    color: ${theme.colors.grey_stone};
  }
`;

const ActionWrapper = styled(Box)`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const MultiDate = ({
  vals,
  formTotal,
  setFormTotal,
  availableBal,
  isRedemption,
  disabledDate,
  form
  
}) => {
  const [fieldCount, setFieldCount] = useState(2);
  useEffect(() => {
    let data = form.getFieldsValue().multiDate;
    let sum = 0;
    if (data) {
      data.forEach((obj) => {
        let val = 0;
        if (obj && obj.amount) {
          val = obj.amount;
        }
        sum = sum + val;
      });
    }
    setFormTotal(sum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vals, fieldCount]);
  return (
    <Box
      boxcolor={`${theme.colors.grey_faint}`}
      padding="24px 32px 24px"
      margin="0 0 48px"
    >
      <Form.List name="multiDate">
        {(fields, { add, remove }) => (
          <>
            <Box
              display="flex"
              justifycontent="space-between"
              width={isRedemption ? 'calc(100% - 49%)' : 'calc(100% - 45%)'}
            >
              <Text
                label="Transaction Date"
                fontSize={`${theme.font_size.size_l}`}
                color={`${theme.colors.black_secondary}`}
                lineheight="20px"
                margin="0 0 8px"
              />
              <Text
                label="Amount"
                fontSize={`${theme.font_size.size_l}`}
                color={`${theme.colors.black_secondary}`}
                lineheight="20px"
                margin="0 0 8px"
              />
            </Box>
            {fields.map((field) => 
            ( 
              <Box display="flex" alignitems="flex-start">
                <Space key={field.key} size={12} className="field-wrapper">
                {/* //{JSON.stringify(field)} */}
                  <Form.Item
                    {...field}
                    label=""
                    name={[field.name, 'date']}
                    fieldKey={[field.fieldKey, 'date']}
                    rules={[
                      {
                        required: true,
                        message: 'Transaction Date Required',
                       },
                      ]}
                  >
                    <DatePicker
                      format="MMM DD, YYYY"
                      allowClear={false}
                      className="suffix-left"
                      dropdownClassName="single-date-picker"
                      //defaultValue={field.value}
                      disabledDate={disabledDate}
                      
                    />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label=""
                    name={[field.name, 'amount']}
                    fieldKey={[field.fieldKey, 'amount']}
                    rules={[
                      {
                        validator: (_, value) =>
                          value && validateAmount(value)
                            ? validateCheckAmount(
                                value,
                                formTotal,
                                availableBal
                              )
                            : value
                            ? Promise.reject('Please enter a valid amount')
                            : Promise.reject('Amount Required'),
                      },
                    ]}
                  >
                    <InputNumberComponent
                      placeholder="$ Enter Amount"
                      showHandleButton={false}
                      defaultValue={null}
                      formatter={(value, info) => {
                        if(!info.userTyping)
                        {
                          let val = value !== '' ? numberFormat(value) : null;
                          if(val != null){
                            if (String(value).includes('.')) {
                              let length = String(value).split('.')[1].length;
                              if(length > 2){
                                return "$" + val;
                              }
                            }
                            val = amountFormat(val.toString().replace(/,/g,''));
                          }
                          return val;
                        } else {
                          return value !== '' ? '$' + numberFormat(value) : null;
                        }
                      }}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                </Space>
                <ActionWrapper alingself="flex-start" flex="1" display="flex">
                  <Form.Item
                    // {...field}
                    label=""
                    name={[field.name, 'add']}
                    fieldKey={[field.fieldKey, 'add']}
                  >
                    <ActionButton
                      type="link"
                      label={false}
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        add();
                        setFieldCount(fieldCount + 1);
                      }}
                      color={`${theme.colors.blue_primay}`}
                    />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label=""
                    name={[field.name, 'remove']}
                    fieldKey={[field.fieldKey, 'remove']}
                  >
                    {fieldCount > 2 ? (
                      <ActionButton
                        type="link"
                        label={false}
                        icon={<CloseCircleOutlined />}
                        onClick={() => {
                          remove(field.name);
                          setFieldCount(fieldCount - 1);
                        }}
                        color={`${theme.colors.blue_primay}`}
                      />
                    ) : (
                      <ActionButton
                        type="link"
                        label={false}
                        icon={<CloseCircleOutlined />}
                        disabled={true}
                      />
                    )}
                  </Form.Item>
                </ActionWrapper>
              </Box>
            ))}
          </>
        )}
      </Form.List>
      <Box
        display="flex"
        alignitems="center"
        justifycontent="space-between"
        padding="0 85px 0 20px"
      >
        <Text
          label="Transaction Total"
          fontSize={`${theme.font_size.size_xl}`}
          color={`${theme.colors.grey_tertiary}`}
          lineheight="24px"
        />
        <Box>
          <Text
            label={amountFormat(formTotal)}
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.black_secondary}`}
            lineheight="24px"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MultiDate;
