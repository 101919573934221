import React from 'react';
import styled, { css } from 'styled-components';
import { theme, Text, TableComponent } from '@pfmam/pfmam';
import { Button } from 'antd';
import { Box } from '../../../styles/globalStyle';
import { amountFormat } from '../../../utils/numberFormat';
import moment from 'moment';
import NoDataFound from '../../../common/components/noDataFound';
const LinkButton = styled(Button)`
  padding: 0;
  display: inline-block;
  font-size: ${theme.font_size.size_xl};
  line-height: 24px;
  color: ${theme.colors.blue_primay};
  height: 24px;
  border: 0;
  background: none;
  box-shadow: none;
  :hover {
    opacity: 0.6;
    color: ${theme.colors.blue_primay};
  }
  :focus,
  :active {
    opacity: 1;
    color: ${theme.colors.blue_primay};
    background: none;
  }
  .anticon + span {
    margin-left: 5px;
  }
  ${(props) =>
    props.cancel &&
    css`
      color: ${theme.colors.danger_red};
      :hover {
        opacity: 0.6;
        color: ${theme.colors.danger_red};
      }
      :focus,
      :active {
        opacity: 1;
        color: ${theme.colors.danger_red};
        background: none;
      }
    `}
`;

const StopPaymentsTable = ({ data, handleAction }) => {
  const getRowClassName = (record) => {
    if (record.status === 'Inactive') {
      return 'inactive-accounts';
    }
    return '';
  };
  const columns = [
    {
      title: 'Check Date',
      dataIndex: 'CheckDate',
      key: 'CheckDate',
      align: 'left',
      sorter: (a, b) => moment(a.CheckDate).unix() - moment(b.CheckDate).unix(),
      className: 'sort-space-none',
      width: '180px',
      render: (text) => (
        <Text label={moment(text).format('MMM DD, YYYY')} lineheight="20px" />
      ),
    },
    {
      title: 'Check Payee/Check Number',
      dataIndex: 'CheckPayee',
      key: 'CheckPayee',
      align: 'left',
      sorter: (a, b) => a.CheckNumber - b.CheckNumber,
      className: 'sort-space-none',
      render: (text, record) => (
        <>
          {text}
          <Text
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_tertiary}`}
            lineheight="20px"
            label={record.CheckNumber}
          />
        </>
      ),
    },
    {
      title: 'Investment Type',
      dataIndex: 'InvestmentType',
      key: 'InvestmentType',
      align: 'left',
      sorter: (a, b) => a.InvestmentType.localeCompare(b.InvestmentType),
      className: 'sort-space-none',
      render: (text, record) => (
        <>
          {text} <Text>{record.comments}</Text>
        </>
      ),
    },
    {
      title: 'Check Amount',
      dataIndex: 'CheckAmount',
      key: 'CheckAmount',
      align: 'right',
      sorter: (a, b) => a.CheckAmount - b.CheckAmount,
      className: 'sort-space-none flex-end',
      render: (text) => <>{amountFormat(text)}</>,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <LinkButton
          type="link"
          onClick={() => {
            handleAction(record);
          }}
        >
          Details
        </LinkButton>
      ),
    },
  ];

  return (
    <>
      <TableComponent
        className="table-primary"
        title={
          <Box
            display="flex"
            justifycontent="space-between"
            alignitems="center"
            padding="24px 16px 24px 32px"
            border={`1px solid ${theme.colors.grey_fossil}`}
            borderwidth="0 0 1px 0"
          >
            <Text
              label="Stop Payments"
              fontSize={`${theme.font_size.size_3xl}`}
              lineheight="32px"
              color={`${theme.colors.black_tertiary}`}
            />
          </Box>
        }
        columns={columns}
        dataSource={data}
        locale={{
          emptyText: <NoDataFound text="No Stop Payments" padding="12px 0" />,
        }}
        showSorterTooltip={false}
        pagination={
          data.length > 10
            ? {
                position: ['bottomRight'],
                showQuickJumper: true,
                size: 'default',
              }
            : false
        }
        onRow={(record) => ({
          onClick: () => {
            handleAction(record);
          },
        })}
        rowClassName={(record) => getRowClassName(record)}
      />
    </>
  );
};

export default StopPaymentsTable;
