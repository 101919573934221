/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Row, Col, Upload, Tooltip, message } from 'antd';
import axios from 'axios';
import styled from 'styled-components';
import {
  theme,
  InputComponent,
  SelectComponent,
  Text,
  ButtonAnt,
} from '@pfmam/pfmam';
import { Box } from '../../styles/globalStyle';
import {
  CaretDownOutlined,
  InboxOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { MaskedInput } from 'antd-mask-input';
import {
  SecureContactUpload,
  SendUnSecureContactEmail,
  IsValidRecaptcha,
} from '../../actions/actions';
import { isNumber, validateEmail } from '../../utils/common';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../config';
import ReactGA from 'react-ga';

const { Dragger } = Upload;

const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    border-color: ${(props) => props.linkTextColor};
  }
`;
let uploadFileCount = 0;
const ContactUsForm = ({ isAuthenticatedUser, setFormSubmitted }) => {
  ReactGA.initialize(config.RecaptchaTag);
  ReactGA.pageview(window.location.pathname + window.location.search);
  const [form] = Form.useForm();
  const [subjectVal, setSubjectVal] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [uploadInfomessageVal, setuploadInfomessageVal] = useState(
    '0 file(s) attached (limit 10) | Total files size: 0 KB (Limit: 9MB)'
  );
  const recaptchaRef = React.useRef();
  useEffect(() => {
    form.setFieldsValue({
      attachments: [...attachments],
    });
  }, [attachments]);

  const dummyRequest = ({ file, onSuccess, onError }) => {
    setTimeout(() => {
      const fileData = [...attachments];
      fileData.push(file);
      setAttachments(fileData);
      onSuccess('ok');
    }, 1000);
  };
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const fundgroup_Id = useSelector((state) => state.siteInfo.fundGroupID);
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  let programId = null;
  let ssnorTIN = null;
  if (isAuthenticatedUser) {
    programId = clientDetails.payload[0].programid;
    ssnorTIN = clientDetails.payload[0].ssnortin;
  }

  const attachmentProps = {
    accept: '.doc, .docx, .pdf, .rtf, .tiff, .txt, .xlsx, .xls, .ppt, .pptx, .DOC, .DOCX, .PDF, .RTF, .TIFF, .TXT, .XLSX, .XLS, .PPT, .PPTX',
    name: 'file',
    multiple: true,    
    customRequest: dummyRequest,
    beforeUpload: (file) => {
      const filesAccepted = [
        'doc',
        'docx',
        'pdf',
        'rtf',
        'tiff',
        'txt',
        'xlsx',
        'ppt',
        'pptx',
        'xls',
        'DOC',
        'DOCX',
        'PDF',
        'RTF',
        'TIFF',
        'TXT',
        'XLSX',
        'PPT',
        'PPTX',
        'XLS',
      ];
      const extension = file.name.split('.').pop();

      if (!filesAccepted.includes(extension)) {
        message.error(`${file.name} is not supported`);
        return filesAccepted.includes(extension) ? true : Upload.LIST_IGNORE;
      } else if (file.size > 9437184) {
        message.error(
          `This requests exceeds the maximum total file size of 9 MB.`
        );
        return Upload.LIST_IGNORE;
      } else if (uploadFileCount === 10) {
        message.error(
          `Unable to upload ${file.name}, You may only include a maximum of 10 documents.`
        );
        return Upload.LIST_IGNORE;
      }
    },
    onChange(info) {
      const { status } = info.file;
      const list = [];
      let totalSizeFiles = 0;
      if (info.fileList) {
        for (let i = 0; i < info.fileList.length; i++) {
          totalSizeFiles += info.fileList[i].size;
          list.push(info.fileList[i].originFileObj);
        }
      }
      uploadFileCount = info.fileList.length;
      if (totalSizeFiles > 9437184 && info.fileList.length > 1) {
        message.error(
          `This requests exceeds the maximum total file size of 9 MB.`
        );
      } else if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
      if (info.fileList) {
        setuploadInfomessageVal(
          info.fileList.length +
            ' file(s) attached (limit 10) | Total files size: ' +
            Math.round(totalSizeFiles / 1024) +
            ' KB (Limit: 9MB)'
        );
      } else if (!info.fileList) {
        setuploadInfomessageVal(
          '0 file(s) attached (limit 10) | Total files size: 0 KB (Limit: 9MB)'
        );
      }
    },
    onRemove(file) {
      const filteredData = attachments.filter(
        (attachment) => attachment.name !== file.name
      );
      //console.log('filteredData ', filteredData);
      setAttachments(filteredData);
    },
    onDrop(e) {
      //console.log('Dropped files', e.dataTransfer.files);
    },
  };
  const RenderLabel = () => {
    return (
      <>
        {'Include Attachment(s):'}
        <Tooltip
          placement="top"
          title="Only .doc, .docx, .pdf, .rtf, .tiff, .txt, .xlsx, .xls, .ppt, .pptx, .DOC, .DOCX, .PDF, .RTF, .TIFF, .TXT, .XLSX, .XLS, .PPT, .PPTX file types are supported"
          overlayClassName="tooltip"
        >
          <InfoCircleOutlined
            style={{ color: `${theme.colors.blue_primay}` }}
          />
        </Tooltip>
      </>
    );
  };
  const onFinishwithAsync = async (values) => {
    if(values.firstName.trim() == ""){
      message.error(`Please enter first name.`);
    }
    else if(values.lastName.trim() == ""){
      message.error(`Please enter last name.`);
    }    
    else if(values.subject === 'other' && values.otherSubject.trim() == ""){
      message.error(`Please enter subject.`);
    } 
    else if(values.message.trim() == ""){
      message.error(`Please enter message.`);
    } 
    else{
      const token = await recaptchaRef.current.executeAsync();
      const isHuman = await IsValidRecaptcha(token);
      if (isHuman.data) {
        SendUnSecureContactEmail(
          values.firstName + ' ' + values.lastName,
          values.email,
          values.subject === 'other' ? values.otherSubject : values.subject,
          values.callbackNumber +
            ' ' +
            (values.ext != undefined ? values.ext : ''),
          values.message
        );
        setFormSubmitted(true);
      } else {
        message.error(`Please try again after sometime.`);
      }
    }     
    //console.log('values ==> ', values);
  };

  const onFinish = async (values) => {
    setDisableSubmit(true);
    if (isAuthenticatedUser) {        
      if(values.subject === 'other' && values.otherSubject.trim() == ""){
        message.error(`Please enter subject.`);
        setDisableSubmit(false);
      } 
      else if(values.message.trim() == ""){
        message.error(`Please enter message.`);
        setDisableSubmit(false);
      } 
      else{
        const list = [];
        let totalSizeFiles = 0;
        if (values.attachments.fileList) {
          for (let i = 0; i < values.attachments.fileList.length; i++) {
            totalSizeFiles += values.attachments.fileList[i].size;
            list.push(values.attachments.fileList[i].originFileObj);
          }
        }
        const frmData = new FormData();
        frmData.append('ClientSSNorTaxID', ssnorTIN);
        frmData.append('fundGroupID', fundgroup_Id);
        frmData.append(
          'subject',
          values.subject === 'other' ? values.otherSubject : values.subject
        );
        frmData.append(
          'callbackNumber',
          values.callbackNumber +' ' + (values.ext != undefined ? values.ext : '')
        );
        frmData.append('programId', programId);
        frmData.append('message', values.message);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        if (values.attachments.fileList) {
          for (let i = 0; i < values.attachments.fileList.length; i++) {
            let file = values.attachments.fileList[i].originFileObj;
            frmData.append('currentAttachments[' + i + ']', file);
          }
        }
        if (totalSizeFiles > 9437184) {
          setDisableSubmit(false);
          message.error(
            `This requests exceeds the maximum total file size of 9 MB.`
          );
        } else if (
          values.attachments.fileList &&
          values.attachments.fileList.length > 10
        ) {
          setDisableSubmit(false);
          message.error(`You may only include a maximum of 10 documents.`);
        } else {
          try {           
            const res = await SecureContactUpload(frmData, config);                        
            if(res.data =='File successfully uploaded'){
              setFormSubmitted(true);
              uploadFileCount = 0;
            }
            else{             
              message.error('Message sent failed. Please try again.');
              setDisableSubmit(false);
            }            
          } catch (err) {
            console.log('Error: ', err);
            setDisableSubmit(false);
          }
        }
        //console.log('values ==> ', values);
      }      
    }
  };

  return (
    <Form
      form={form}
      name="basic"
      layout="vertical"
      requiredMark={false}
      onFinish={isAuthenticatedUser ? onFinish : onFinishwithAsync}
      className="forms"
    >
      <Row gutter="24">
        {!isAuthenticatedUser && (
          <>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'First Name Required',
                  },
                ]}
              >
                <InputComponent
                  type="default"
                  placeholder="Enter First Name"
                  MaxLength="50"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Last Name Required',
                  },
                ]}
              >
                <InputComponent
                  type="default"
                  placeholder="Enter Last Name"
                  MaxLength="50"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    validator: (_, value) =>
                      value && validateEmail(value)
                        ? Promise.resolve()
                        : value
                        ? Promise.reject('Please enter a valid email address')
                        : Promise.reject('Email Address Required'),
                  },
                ]}
              >
                <InputComponent
                  type="default"
                  placeholder="Enter your email"
                  MaxLength="100"
                />
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={24}>
          <Form.Item
            label="Subject"
            name="subject"
            rules={[
              {
                required: true,
                message: 'Subject Required',
              },
            ]}
          >
            <SelectComponent
              option={[
                {
                  label: 'Add/Edit Connect User(s)',
                  value: 'Add/Edit Connect User(s)',
                },
                {
                  label: 'Authorized Person Update',
                  value: 'Authorized Person Update',
                },
                {
                  label: 'CD/Term Inquiry',
                  value: 'CD/Term Inquiry',
                },
                {
                  label: 'New Account Information',
                  value: 'New Account Information',
                },
                {
                  label: 'Statement Help',
                  value: 'Statement Help',
                },
                {
                  label: 'Transaction/ Reporting Help',
                  value: 'Transaction/ Reporting Help',
                },
                {
                  label: 'Wire/ACH Instruction(s) Update',
                  value: 'Wire/ACH Instruction(s) Update',
                },
                {
                  label: 'Other',
                  value: 'other',
                },
              ]}
              placeholder="Select Subject"
              suffixIcon={<CaretDownOutlined />}
              dropdownClassName="form-select-dropdown"
              bordered={true}
              onChange={(value) => setSubjectVal(value)}
            />
          </Form.Item>
        </Col>
        {subjectVal && subjectVal === 'other' ? (
          <Col span={24}>
            <Form.Item
              label=""
              name="otherSubject"
              rules={[
                {
                  required: true,
                  message: 'Subject Required',
                },
              ]}
            >
              <InputComponent
                type="default"
                placeholder="Enter Subject"
                MaxLength="30"
              />
            </Form.Item>
          </Col>
        ) : null}

        <Col span={15}>
          <Form.Item
                        label="Callback Number"
                        name="callbackNumber"
                        rules={[
                          {
                            validator: (rule, value) => {
                              if(value !="" && value != undefined){
                                let callBackNumber = value.replace(/\D/g,'');
                                if(callBackNumber && isNumber(callBackNumber))
                                {
                                  if (callBackNumber.length >= 7 && callBackNumber.length <= 10) {
                                      return Promise.resolve();   
                                  }
                                  else{     
                                    return Promise.reject('Please enter a min of 7 and max of 10 characters.');
                                  }
                                 }
                                  else{
                                    return Promise.reject('Callback Number Required');
                                  }
                              }
                              else{
                                return Promise.reject('Callback Number Required');
                              }
                            },
                          },
                        ]}
                      >
                         <MaskedInput
              mask="(111) 111-1111"
              placeholder="(000) 000-0000"
              // placeholderChar={' '}
            />
                      </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item
            label="Ext"
            name="ext"
            rules={[
              {
                validator: (_, value) =>
                  value == null || value == 0 || (value && isNumber(value))
                    ? Promise.resolve()
                    : Promise.reject('Please enter a valid Ext.'),
              },
            ]}
          >
            <InputComponent type="default" placeholder="000" MaxLength="25" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Add any message or handling instructions (max. 500 characters):"
            name="message"
            rules={[
              {
                required: true,
                message: 'Message Required',
              },
            ]}
          >
            <InputComponent
              type="textarea"
              placeholder="Why do you want to connect?"
              MaxLength="500"
            />
          </Form.Item>
        </Col>
        {isAuthenticatedUser && (
          <Col span={24}>
            <RenderLabel />
            <Form.Item label="" name="attachments">
              <Dragger {...attachmentProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <Text
                  label="Drop files here                  "
                  color={`${theme.colors.black_secondary}`}
                  fontSize={`${theme.font_size.size_xl}`}
                  lineheight="24px"
                />
                <Box margin="4px 0 0" padding="0 20px">
                  <Text
                    label="Please select file(s) to upload."
                    color={`${theme.colors.grey_tertiary}`}
                    fontSize={`${theme.font_size.size_l}`}
                    lineheight="20px"
                  />
                </Box>
                <Box margin="4px 0 0" padding="0 20px">
                  <Text
                    label={uploadInfomessageVal}
                    color={`${theme.colors.grey_tertiary}`}
                    fontSize={`${theme.font_size.size_l}`}
                    lineheight="20px"
                  />
                </Box>
              </Dragger>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Text
        label={
          isAuthenticatedUser
            ? 'Please note: only .doc, .docx, .pdf, .rtf, .tiff, .txt, .xls, .xlsx, .ppt, .pptx, .DOC, .DOCX, .PDF, .RTF, .TIFF, .TXT, .XLS, .XLSX, .PPT, .PPTX file types will be accepted.'
            : ''
        }
        fontSize={`${theme.font_size.size_l}`}
        lineheight="20px"
        color={`${theme.colors.grey_tertiary}`}
        margin="0 0 32px"
      />
      <Box display="flex" justifycontent="flex-end">
        <OverrideButtonAnt
          label="Send Message"
          type="primary"
          htmlType="submit"
          linkTextColor={isAuthenticatedUser ? primary_color : '#EAE8E8'}
          className={isAuthenticatedUser ? 'primary' : 'unauth-user-btn'}
          disabled={disableSubmit}
        />
      </Box>
      {!isAuthenticatedUser && (
        <>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={config.ReCaptchaSiteKey}
          />
        </>
      )}
    </Form>
  );
};

export default ContactUsForm;
