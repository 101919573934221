import React from 'react';
import { Result, Button } from 'antd';
const IATErrorContainer = () => {
  return (
    <Result
      status="warning"
      title="Your device's time-clock or time-zone is being set incorrectly. 
      In order to fix this issue, change the device's time-clock and time-zone so that they accurately reflect the 
      time and time-zone of your current location. Once you are done with this, please close the browser and try to access connect again."
    />
  );
}

export default IATErrorContainer;