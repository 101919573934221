import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import {
    theme,
    Text,
    ButtonAnt,
    Modal,
} from '@pfmam/pfmam';
import { Container, Box, Banner } from '../styles/globalStyle';
import PendingActivityTable from './components/pendingActivityTable';
import { DownloadOutlined } from '@ant-design/icons';
import CancelModalContent from '../common/components/cancelModal';
import RenderFiltersPending from './components/renderFiltersPending';
import AppLayout from '../common/layouts/appLayout';
import {
    GetPendingActivity,
    GetPendingActivityAdditionalInfo,
    GetClientOrAccountLevelTransPermission,
} from '../actions/actions';
import { useSelector, useDispatch } from 'react-redux';
import {
    GetPendingActivityDetailsData,
    GetEnvisionPendingActivityDetailsData,
} from '../utils/pendingActivityRenderFilter';


const { Content } = Layout;

const TableWrapper = styled.div`
  .ant-table {
    border-radius: 4px 4px 0 0;
    .ant-table-tbody {
      & > tr {
        &:hover {
          &.ant-table-row:not(.ant-table-expanded-row) {
            background: linear-gradient(
                0deg,
                ${(props) => props.rgbaPrimaryHover},
                ${(props) => props.rgbaPrimaryHover}
              ),
              ${theme.colors.white};
          }
        }
        cursor: pointer;
        & > td {
          vertical-align: top;
          padding: 20px 32px;
          &.ant-table-column-sort {
            background: ${theme.colors.transparent};
          }
          &.ant-table-row-expand-icon-cell {
            padding: 0;
            & > span {
              display: none;
            }
          }
        }
        &:last-child {
          td {
            border-bottom-color: ${theme.colors.transparent};
          }
        }
        &.inactive-accounts {
          & > td {
            background-color: ${theme.colors.grey_faint};
          }
        }
      }
    }
    .ant-table-thead {
      & > tr {
        & > th {
          background: ${theme.colors.light_grey};
          font-family: 'Inter';
          padding: 0 32px;
          font-weight: normal;
          line-height: 20px;
          &:not(:last-child) {
            &:not(.ant-table-selection-column) {
              &:not(.ant-table-row-expand-icon-cell) {
                &:not([colspan]) {
                  &::before {
                    background-color: ${theme.colors.transparent};
                  }
                }
              }
            }
          }
        }
      }
    }
    &.ant-table.ant-table-middle {
      .ant-table-thead {
        .ant-table-column-sorters {
          padding: 0;
          .ant-table-column-sorter-inner .anticon {
            color: ${theme.colors.grey_stone};
          }
          .ant-table-column-sorter-up.active,
          .ant-table-column-sorter-down.active {
            color: ${theme.colors.black_sorted};
          }
        }
        .ant-table-column-sorter-inner {
          margin-top: 0;
        }
      }
    }
    .sort-space-none {
      .ant-table-column-sorters {
        justify-content: flex-start;
      }
      .ant-table-column-title {
        flex: 0 0 auto;
      }
      &.flex-end {
        .ant-table-column-sorters {
          justify-content: flex-end;
        }
      }
    }
  }
  .ant-table-title {
    padding: 0 !important;
  }
  .table-primary {
    &:first-child {
      margin-bottom: 24px;
    }
  }
  .ant-table {
    &.ant-table-middle {
      .ant-table-tbody {
        .ant-table-wrapper {
          &:only-child {
            .ant-table {
              margin: -19px -32px;
            }
          }
        }
        .ant-table-container {
          .ant-table-tbody {
            .ant-table-row {
              .ant-table-cell {
                padding: 14px 32px;
                background-color: ${theme.colors.grey_faint};
              }
              &:hover {
                .ant-table-cell {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
`;
const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
  }
`;

const PendingActivityContainer = (props) => {
  
    const [closeCancelWindow, setCloseCancelWindow] = useState(false);
    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
    const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isCancelTransaction, setIsCancelTransaction] = useState(false);
    const [isCancelSuccess, setIsCancelSuccess] = useState(false);
    const [pendingActivities, setPendingActivities] = useState([]);
    const [filterPendingActivities, setFilterPendingActivities] = useState([]);
    //const [isGlobalActivityHistoryPage, setIsGlobalActivityHistoryPage] = useState(isGlobal);
    const [checkedTransactionTypes,setCheckedTransactionTypes]=useState([]);
    const [checkedAccounts,setCheckedAccounts]=useState([]);
    const ProgramLevelTransPermission = useSelector(
        (state) => state.siteInfo.allowOnlineTransact
    );

    const clientDetails = useSelector(
        (state) => state.programsClientsSlice.selectedClient
    );
    const selectedAccount = useSelector(
        (state) => state.accountProfileSlice.selectedAccount
    );

    const primary_color = useSelector((state) => state.siteInfo.primaryColor);
    const rgbaPrimary = useSelector((state) => state.siteInfo.rgbaPrimary);
    const programId = clientDetails.payload[0].programid;
    const ssnorTIN = clientDetails.payload[0].ssnortin;


    useEffect(() => {
        if (ProgramLevelTransPermission == true) {
            GetPendingActivity(programId, ssnorTIN, null)
                .then((response) => {
                    if (response.data != null) {
                        if (props.isGlobal) {
                            setPendingActivities(response.data);
                        } else {
                            var filteredPendingActivities = response.data.filter(
                                (x) =>
                                    x.AccountUniqueID == selectedAccount.AccountUniqueID.trim()
                            );
                            setPendingActivities(filteredPendingActivities);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [ProgramLevelTransPermission, closeCancelWindow]);

    useEffect(() => {
      applyFiltersToData();
  }, [pendingActivities,checkedAccounts,checkedTransactionTypes ]);


    const handleAction = (type, data, rowClick = false) => {
        if (type === 'cancel' && data.ReferenceID.startsWith('ENV')) {
            setSelectedRecord(GetEnvisionPendingActivityDetailsData(data));
            setIsCancelModalVisible(true);
            !rowClick && setIsCancelTransaction(true);
        } else if (type === 'cancel') {
            GetPendingActivityAdditionalInfo(
                data.ReferenceID,
                data.PendingTransactionTypeID
            )
                .then((response) => {
                    if (response.data != null) {
                        setSelectedRecord(
                            GetPendingActivityDetailsData(data, response.data)
                        );
                        setIsCancelModalVisible(true);
                        !rowClick && setIsCancelTransaction(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        if (type === 'details') {
            setIsDetailsModalVisible(!isDetailsModalVisible);
            setSelectedRecord(data);
        }
    };

    const handleModalCancel = (type) => {
        if (type === 'cancel') {
            setIsCancelModalVisible(!isCancelModalVisible);
            setIsCancelTransaction(false);
            if (isCancelSuccess) {
                //Fetch the pending transaction once the transaction is successfully reversed. Else status wont be reflected in the UI.
                setCloseCancelWindow(!closeCancelWindow);
            }
            setIsCancelSuccess(false);
        }
        if (type === 'details') {
            setIsDetailsModalVisible(!isDetailsModalVisible);
        }
        setSelectedRecord(null);
    };


    function applyFiltersToData() {
      let Activities = [...pendingActivities];
      let FilteredActivity = [];
      
      if (
        checkedTransactionTypes.length > 0 ||
        checkedAccounts.length > 0 
      ) {
        FilteredActivity = Activities.filter(function (elem) {
          // the current value is an object, so you can check on its properties
          let TransactionTypefilter = true;
          let Accountfilter = true;

          if (checkedTransactionTypes.length > 0) {
            TransactionTypefilter = checkedTransactionTypes.includes(
              elem.TransactionType
            );
          }
          if (checkedAccounts.length > 0 && props.isGlobal) {
            Accountfilter = checkedAccounts.includes(elem.AccountUniqueID);
          }
          return (
            TransactionTypefilter &&
            Accountfilter
          );
        });
      } else {
        FilteredActivity = Activities;
      }
  
     setFilterPendingActivities(FilteredActivity);
    }

    return (
        <>
        {<Container>
                <Box padding='32px 0 0 0'>
                {!props.isGlobal && (
                  <Text
                  label="Pending Activity"
                  fontSize="36px"
                  lineheight="44px"
                  color={`${theme.colors.black_secondary}`}
                />
                )}
                
                <RenderFiltersPending
                    data={pendingActivities}
                    isGlobalActivityHistoryPage={props.isGlobal}
                    handleTransactionTypeSelection={setCheckedTransactionTypes}
                    handleAccountSelection={setCheckedAccounts}
                   // dateChangeAction={dateChangeAction}
                  />
                    <TableWrapper rgbaPrimaryHover={rgbaPrimary}>
                            <PendingActivityTable
                                isGlobalActivityHistoryPage={props.isGlobal}
                                data={filterPendingActivities.length > 0 ? filterPendingActivities : []}
                                handleAction={handleAction}
                                type="pending"
                                linkTextColor={primary_color}
                            />
                    </TableWrapper>
                </Box>
            </Container>}
            

            {isCancelModalVisible && (
                <Modal
                    title={
                        isCancelSuccess
                            ? 'Transaction Cancelled'
                            : isCancelTransaction && selectedRecord.Cancellable
                                ? 'Cancel Transaction?'
                                : 'Activity Details'
                    }
                    content={
                        <CancelModalContent
                            selectedRecord={selectedRecord}
                            isCancelTransaction={isCancelTransaction}
                            setIsCancelTransaction={setIsCancelTransaction}
                            handleModalCancel={handleModalCancel}
                            isCancelSuccess={isCancelSuccess}
                            setIsCancelSuccess={setIsCancelSuccess}
                        />
                    }
                    visible={isCancelModalVisible}
                    onCancel={() => handleModalCancel('cancel')}
                    footer={null}
                    width={590}
                    bodyStyle={{
                        padding: '32px 32px 48px 32px',
                    }}
                    maskStyle={{
                        backgroundColor: `${theme.rgba.modal}`,
                        zIndex: '1051',
                    }}
                    centered={true}
                />
            )}

        </>
    );
};

export default PendingActivityContainer;
