import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { theme, Text, ButtonAnt } from '@pfmam/pfmam';
import { Container, Banner, Box } from '../../styles/globalStyle';
import PublicFooterComponent from '../../common/components/PublicFooter';
import { sizes } from '../../styles/mediaQuery';
import { useHistory } from 'react-router-dom';
import config from '../../config';

const LayoutWrapper = styled(Layout)`
  background-color: ${theme.colors.light_grey};
  min-height: 100vh;
  @media ${sizes.mediaQuery('xs', 'md')} {
    width: 1024px;
    overflow-x: auto;
  }
`;

const Wrapper = styled.div`
  padding-bottom: 64px;
  min-height: calc(100vh - 200px);
`;

const PublicFooterLayout = ({ children, title }) => {
  const history = useHistory();
  const routeChange = () => {
   // let path = '/login';
    window.location.href = config.CONNECT_POSTLOGOUT_REDIRECT_URI;
    //history.push(path);
  };
  return (
    <LayoutWrapper>
      <Banner bgcolor={`${theme.colors.black_primay}`} height={'124px'}>
        <Box
          display="flex"
          alignitems="center"
          height="100%"
          minwidth="100%"
          width="100%"
          zindex="3"
          position="relative"
          padding={'44px 0 36px 0'}
        >
          <Container>
            <Box
              display="flex"
              justifycontent="space-between"
              alignitems="flex-start"
              margin="12px 0 0"
            >
              <Text
                color={`${theme.colors.white}`}
                fontSize="36px"
                lineheight="44px"
                label={title}
              />
              <ButtonAnt
                icon={false}
                label="Sign In"
                type="secondary"
                onClick={routeChange}
              />
            </Box>
          </Container>
        </Box>
      </Banner>

      <Wrapper>{children}</Wrapper>

      <PublicFooterComponent />
    </LayoutWrapper>
  );
};

export default PublicFooterLayout;
