import { HeartFilled } from '@ant-design/icons';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../config';

const baseApiUrl = config.BASE_API_URL;

export const getProgramsClientsSummary = createAsyncThunk(
  'programClients/getProgramsClientsSummary',
  async () => {
    const req3 = await axios
      .get(baseApiUrl + 'WebUsers/Clients/DollarSummary')
      .then((res) => res.data);
    return req3;
  }
);

//test

const programsClientsSlice = createSlice({
  name: 'programClients',
  initialState: {
    programClients: [],
    programName: [],
    status: null,
    selectedClient: [],
  },
  reducers: {
    clientsFilteredSearch: (state, payload) => {
      state.selectedClient = payload;
    },
  },
  extraReducers: {
    [getProgramsClientsSummary.fulfilled]: (state, action) => {
      //binding raw data to returnArr and capitalizing client name
      var returnArr = [...action.payload.Table];
      returnArr.forEach((element, index) => {
        element.clientname = element.clientname.toUpperCase();
      });
      //sorting returnArr by client name
      const sortByKey = (key) => (a, b) => a[key] > b[key] ? 1 : -1;
      const sortedClients = returnArr.slice().sort(sortByKey('clientname'));
      let programNames = [];
      //looping through sorted[] array to create sortedUniquePrograms and formatting balances for clients
      //Although this loop contains two seperate responsibilities, looping once saves memory
      sortedClients.forEach(function (key) {
        //getting a list of unique program names from sortedClients
        if (!programNames.includes(key['programname'])) {
          programNames.push(key['programname']);
        }
        //Formatting current balance of each client
        if (key['current_balance'] != null) {
          if (key['current_balance'] > 0) {
            key['current_balance'] =
              '$' +
              key['current_balance']
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          } else {
            key['current_balance'] =
              '( $' +
              key['current_balance']
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            key['current_balance'] += ' )';
          }
        }
      });
      const sortedUniquePrograms = programNames.sort();
      const setFavoriteClients=sortedClients.filter((item) => {
        return item.IsFavorite;
      });
      state.favoriteClients=setFavoriteClients;
      state.programName = sortedUniquePrograms;
      state.programClients = sortedClients;
      state.status = 'fulfilled';
    },
    [getProgramsClientsSummary.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getProgramsClientsSummary.rejected]: (state, action) => {
      state.status = 'failed';
    },
  },
});

export const { clientsFilteredSearch } = programsClientsSlice.actions;
export default programsClientsSlice.reducer;
