import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  filteredAccounts: [],
  filteredRecipients: [],
};

const monthlyStatementsConfirmsSlice = createSlice({
  name: 'monthlystatements',
  initialState,
  reducers: {
    storeFilteredRecipients: (state, { payload }) => {
      state.filteredRecipients = payload;
    },
    storeFilteredAccounts: (state, { payload }) => {
      state.filteredAccounts = payload;
    },
  },
});

export const { storeFilteredRecipients, storeFilteredAccounts } =
  monthlyStatementsConfirmsSlice.actions;
export default monthlyStatementsConfirmsSlice.reducer;
