import React, { useState } from 'react';
import { Box, Anchor } from '../../styles/globalStyle';
import { theme, Text, Modal } from '@pfmam/pfmam';
import { ClickOn } from '../../styles/globalStyle';
import BetweenInvIcon from '../../assets/images/Transaction Icons/between-investments.png';
import BetweenAccICon from '../../assets/images/Transaction Icons/between-accounts.png';
import ProgramPartICon from '../../assets/images/Transaction Icons/program- participant.png';
import TransferTypeBox from '../common/transferTypeBox';
import MoveFundsModal from '../../dashboard/components/moveFundsModal';

const TransferTypeCard = ({ setSelectedPage, transferData, menuPermissions }) => {
  const [isMoveFundsVisible, setIsMoveFundsVisible] = useState(false);
  const handleFundsModalCancel = () => {
    setIsMoveFundsVisible(!isMoveFundsVisible);
  };
  return (
    <>
    <Text
      fontSize="36px"
      color={`${theme.colors.black_tertiary}`}
      label={'Transfer Funds'}
      lineheight="44px"
      margin="0 0 5px"
    />
    <Anchor
      fontsize={`${theme.font_size.size_l}`}
      lineheight="20px"
      color={`${theme.colors.blue_primay}`}
      display="inline-block"
      margin="0 0 12px"
      onClick={() => {
        setIsMoveFundsVisible(!isMoveFundsVisible);
      }}
    >
      Change
          </Anchor>
    <Box
      border={`1px solid ${theme.colors.grey_fossil}`}
      borderwidth="0 0 1px 0"
      display="flex"
      padding="24px 32px"
      minwidth="100%"
      maxwidth="100%"
      flexdirection="column"
      boxcolor={theme.colors.white}
    >
      <Box minwidth="100%" maxwidth="100%" textalign="left">
        <Text
          fontSize={`${theme.font_size.size_3xl}`}
          color={`${theme.colors.black_tertiary}`}
          label={'Select Transfer Type'}
          lineheight="32px"
        />
      </Box>
    </Box>
    {menuPermissions.includes(7) ? (
      <ClickOn
        type="button"
        onClick={() => {
          setSelectedPage('InvestmentTypeTransfer');
        }}
        disabled={transferData.permissions.includes('Investment')}
      >
        <TransferTypeBox
          icon={BetweenInvIcon}
          title="Transfer Between Investment Types"
          subTitle="Move funds to a different investment type within the same account"
          disable={transferData.permissions.includes('Investment')}
        />
      </ClickOn>


    ) : null}
    {menuPermissions.includes(5) ? (
      <ClickOn
        type="button"
        onClick={() => {
          setSelectedPage('AccountsTransfer');
        }}
        disabled={transferData.permissions.includes('Accounts')}
      >
        <TransferTypeBox
          icon={BetweenAccICon}
          title="Transfer Between Accounts"
          subTitle="Move funds to a different account within the same investment type"
          disable={transferData.permissions.includes('Accounts')}
        />
      </ClickOn>) : null}
    {menuPermissions.includes(25) ? (
      <ClickOn
        type="button"
        onClick={() => {
          setSelectedPage('ProgramTransfer');
        }}
        disabled={transferData.permissions.includes('Participants')}
        className="last-transfer-type"
      >
        <TransferTypeBox
          icon={ProgramPartICon}
          title="Transfer to Program Participant"
          subTitle="Move funds to one or more program participants"
          disable={transferData.permissions.includes('Participants')}
        />
      </ClickOn>
    ) : null}

    {isMoveFundsVisible && (
      <Modal
        title="Move Funds"
        width={590}
        bodyStyle={{
          padding: '0 0 64px 0',
        }}
        maskStyle={{
          backgroundColor: `${theme.rgba.modal}`,
          zIndex: '1051',
        }}
        content={<MoveFundsModal />}
        visible={isMoveFundsVisible}
        onCancel={() => handleFundsModalCancel()}
        footer={null}
        centered={true}
      />
    )}
    </>
  );
};

export default TransferTypeCard;
