import React, {useState}from 'react';
import { Box,Anchor } from '../../styles/globalStyle';
import { theme, Text,Modal } from '@pfmam/pfmam';
import { ClickOn } from '../../styles/globalStyle';
import BetweenAccICon from '../../assets/images/Transaction Icons/between-accounts.png';
import TransferTypeBox from '../common/transferTypeBox';


const AccountTransferTypeCard = ({ setSelectedPage }) => {
  return (
    <>
      <Text
        fontSize="36px"
        color={`${theme.colors.black_tertiary}`}
        label={'Transfer Between Accounts'}
        lineheight="44px"
        margin="0 0 5px"
      />
       <Anchor
      fontsize={`${theme.font_size.size_l}`}
      lineheight="20px"
      color={`${theme.colors.blue_primay}`}
      display="inline-block"
      margin="0 0 12px"
      onClick={() => setSelectedPage('TransferTypeCard')}
    >
      Change
          </Anchor>
      <Box
        border={`1px solid ${theme.colors.grey_fossil}`}
        borderwidth="0 0 1px 0"
        display="flex"
        padding="24px 32px"
        minwidth="100%"
        maxwidth="100%"
        flexdirection="column"
        boxcolor={theme.colors.white}
      >
        <Text
          fontSize={`${theme.font_size.size_3xl}`}
          color={`${theme.colors.black_tertiary}`}
          label={'Select Transfer Type'}
          lineheight="32px"
        />
      </Box>
      <ClickOn
        type="button"
        onClick={() => {
          setSelectedPage('SingleAccountTransferForm');
        }}
      >
        <TransferTypeBox
          icon={BetweenAccICon}
          title="Single Account Transfer"
          subTitle="Initiate single transfers between accounts"
        />
      </ClickOn>

      <ClickOn
        type="button"
        onClick={() => {
          setSelectedPage('MultiAccountTransferForm');
        }}
      >
        <TransferTypeBox
          icon={BetweenAccICon}
          title="Multiple Account Transfer"
          subTitle="Initiate multiple transfers between accounts"
        />
      </ClickOn>
    </>
  );
};

export default AccountTransferTypeCard;
