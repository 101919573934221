import React from 'react';
import styled, { css } from 'styled-components';
import { Form } from 'antd';
import {
  theme,
  ButtonAnt,
  InputComponent,
} from '@pfmam/pfmam';
import { getAccInvestmentTitle } from '../common/commonFunctions';
export const SuffixButton = styled(ButtonAnt)`
  &.ant-btn {
    font-size: ${theme.font_size.size_xl};
    color: ${(props) => props.color};
    line-height: 24px;
    padding: 0;
    border: 0;
    height: 100%;
    ${(props) =>
      props.btnblock &&
      css`
        position: absolute;
        left: 0;
        width: 100%;
        text-align: left;
        padding: 0 20px;
      `}
    ${(props) =>
      props.btnedit &&
      css`
        position: absolute;
        right: 0;
        width: auto;
        padding: 0 16px;
      `}
  }
  left: auto;
  width: auto;
  text-align: left;
  padding: 0 20px;
  right: 0;
  text-align: right;
`;
const AccInvestmentTypeInput = ({
  form,
  showInvestmentTypeModal,
  setShowInvestmentTypeModal,
  amount = null,
  label = 'Account/Investment Type',
}) => {
  return (
    <Form.Item
      label={label}
      name="accountinvestmentType"
      rules={[
        {
          required: true,
          message: 'Account/Investment Type required',
        },
      ]}
      className="investment-input gap-none"
    >
      {form.getFieldValue('accountinvestmentType') ? (
        getAccInvestmentTitle(
        //  form.getFieldValue('accountinvestmentType').FundShortName,
        //  form.getFieldValue('accountinvestmentType').FundType,
        form.getFieldValue('accountinvestmentType').AccName,
        form.getFieldValue('accountinvestmentType').type.FundShortName,
          showInvestmentTypeModal,
          setShowInvestmentTypeModal,
          amount
        )
      ) : (
        <InputComponent
          type="default"
          placeholder="None Selected"
          readOnly={true}
         // onChange={() => console.log('changed')}
          onClick={() => {
            setShowInvestmentTypeModal(!showInvestmentTypeModal);
          }}
          suffix={
            <SuffixButton
              label={'Select Account/Investment Type'}
              icon={null}
              type="link"
              color={theme.colors.black_secondary}
              onClick={() => {
                setShowInvestmentTypeModal(!showInvestmentTypeModal);
              }}
            />
          }
        />
      )}
    </Form.Item>
  );
};

export default AccInvestmentTypeInput;
