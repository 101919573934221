import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import {
  theme,
  ButtonAnt,
  InputComponent,
  Text,
  Modal,
} from '@pfmam/pfmam';
import { Form, Space } from 'antd';
import { Box } from '../../styles/globalStyle';
import { amountFormat } from '../../utils/numberFormat';
import { IsDuplicateTransactionSingleAccount, GetTransactionDefaultDay, IsFundValidBusinessDay } from '../../actions/actions';
import DuplicateTransactionModalContent from '../common/duplicateTransactionModalContent';
import { InvestmentSetlectStyle } from '../../activityHistory/components/stopPayments/initiateStopPayments';
import { OverrideStyle } from '../../common/components/datePicker';
import SingleDate from '../common/singleDate';
import moment from 'moment';
import InvestmentModalContent from '../common/accInvestmentTypeModal';
import AccInvestmentTypeInput from '../common/accInvestmentTypeInput';
import { Prompt } from 'react-router';
export const SuffixButton = styled(ButtonAnt) `
  &.ant-btn {
    font-size: ${theme.font_size.size_xl};
    color: ${(props) => props.color};
    line-height: 24px;
    padding: 0;
    border: 0;
    height: 100%;
  }
`;
const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
  }
`;
const ButtonWrapper = styled(Space) `
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  margin-top: 16px;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
`;
const RegularText = styled.span`
  color: ${(props) => props.color};
  font-size: 16px;
  line-height: 24px;  
`;
const Wrapper = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;

const WireForm = ({
  form,
  showInvestmentTypeModal,
  setShowInvestmentTypeModal,
  setFormType,
  formType,
  setFormData,
  setAmount,
  setFundDetails,
}) => {
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);

  const [vals, setVals] = useState({});
  const [formTotal, setFormTotal] = useState(0);
  const [isDuplicateTrans, setIsDuplicateTrans]=useState();
  const [isDateInValid, setIsDateInValid]=useState();
  const [isInvalidDateModalVisible, setIsInvalidDateModalVisible] = useState(false);
  const [isDuplicateTransModalVisible, setIsDuplicateTransModalVisible] = useState(false);
  const [duplicateTransData, setDuplicateTransData] = useState([]);
  const [fundUniqueID, setFundUniqueID] = useState(0);
  const [defaultDate, setDefaultDate] = useState(moment());
  //const [date, setDate] = useState(moment());

  useEffect(() => {
    if (fundUniqueID !== 0) {
      GetTransactionDefaultDay(fundUniqueID, 3).then((response) => {
        if (response.data !== null) {
          //var date1 = new Date(response.data);
          var defaultMoment = moment(response.data.toString());//.add(1,'days');//moment(year+'-'+month+'-'+day,'YYYY-MM-DD')
          form.setFieldsValue({
            date: defaultMoment,
          });
          setDefaultDate(defaultMoment);
        }
      })
        .catch((error) => {
          console.log(error);
        });
    }


  }, [fundUniqueID]);

  useEffect(() => {
    if (isDateInValid===false && isDuplicateTrans===false) {
      setFormType('review');
    }
  }, [isDateInValid,isDuplicateTrans]);


  const duplicateTransColumns = [
    {
      title: 'Confirmation #',
      dataIndex: 'Confirm #',
      key: 'Confirm #',
      width: 180,
      align: 'left',
    },
    {
      title: 'Account Number',
      dataIndex: 'Account Number',
      key: 'Account Number',
      width: 210,
      align: 'left',
      render: (text, record) => (
        <>
        <Text
          label={text}
          color={`${theme.colors.black_secondary}`}
          fontSize={`${theme.font_size.size_xl}`}
          lineheight="24px"
        />
        </>
      ),
    },
    {
      title: 'Posting Date',
      dataIndex: 'Posting Date',
      key: 'Posting Date',
      width: 212,
      align: 'left',
      render: (text, record) => (
        <>
        <Text
          label={moment(text).format('MMM DD, YYYY')}
          color={`${theme.colors.black_secondary}`}
          fontSize={`${theme.font_size.size_xl}`}
          lineheight="24px"
        />
         </>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'Created Date',
      key: 'Created Date',
      width: 212,
      align: 'left',
      render: (text, record) => (
        <>
        <Text
          label={moment(text).format('MMM DD, YYYY h:mm A')}
          color={`${theme.colors.black_secondary}`}
          fontSize={`${theme.font_size.size_xl}`}
          lineheight="24px"
        />
        <Text
          label={record['Created By']}
          color={`${theme.colors.grey_tertiary}`}
          fontSize={`${theme.font_size.size_xl}`}
          margin="4px 0 0"
          lineheight="20px"
        />
        </>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      width: 148,
      align: 'right',
      render: (text) => <>{amountFormat(text)}</>,
    },
  ];

  const handleDuplicateModalSubmit = () => {
    setIsDuplicateTransModalVisible(false);
    setFormType('review');

  };

  const handleDuplicateModalCancel = () => {
    setIsDuplicateTransModalVisible(false);
  };

  const onFinish = (values) => {
    setFormData(values);
    setAmount(values.amount);
    //Checking for duplicate Transactions
    IsDuplicateTransactionSingleAccount(values.date.format('YYYY-MM-DD'), values.accountinvestmentType.AccountUniqueID, values.amount, 3)
      .then((response) => {
        if (response.data !== null && response.data.Table.length > 0) {
          setDuplicateTransData(response.data.Table);
          setIsDuplicateTransModalVisible(true);
          setIsDuplicateTrans(true);
        }
        else {
          setIsDuplicateTrans(false);
          // setFormType('review');
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //Checking if the date is valid
    IsFundValidBusinessDay(values.date.format('YYYY-MM-DD'), values.accountinvestmentType.type.FundUniqueID)
      .then((response) => {
        if (!response.data) {
          setIsInvalidDateModalVisible(true);
          setIsDateInValid(true);
        }
        else {
          setIsDateInValid(false);
          //setFormType('review');
        }
      })
      .catch((error) => {
        console.log(error);
      });

  };

  const onInvestmentTypeChange = (e) => {
    form.resetFields();
    form.setFieldsValue({
      accountinvestmentType: { ...e.target.value },
    });
    setFundDetails({
      FundUniqueID: e.target.value.type.FundUniqueID,
      FundShortName: e.target.value.type.FundShortName
    });
    setFundUniqueID(e.target.value.type.FundUniqueID);
    setShowInvestmentTypeModal(!showInvestmentTypeModal);
  };
  const disabledDate = (current) => {
    var x = moment().endOf("day");
    // Can not select days before default date and weekends
    return (current.isoWeekday() === 7 || current.isoWeekday() === 6 || current < defaultDate.startOf('day')); //&& current.isoWeekday() ===6;
  }

  const handleTotal = (_, values) => {
    setVals(values);
  };

  const transactionTypeId = 3;

  return (
    <>
    <Prompt
      when={form.isFieldsTouched()}
      message={JSON.stringify({
        header: 'Discard Transaction?',
        content:
          'Leaving this page will discard this transaction. Are you sure you want to discard this transaction?',
      })}
    />
    <OverrideStyle />
    <InvestmentSetlectStyle />
    <Box
      boxcolor={`${theme.colors.white}`}
      padding="32px 32px 48px"
      radius="4px"
    >
      <Form
        form={form}
        name="basic"
        layout="vertical"
        requiredMark={false}
        className="forms"
        onFinish={onFinish}
        onValuesChange={handleTotal}
      initialValues={{
        date: moment(),
      }}
      >
        <AccInvestmentTypeInput
          form={form}
          showInvestmentTypeModal={showInvestmentTypeModal}
          setShowInvestmentTypeModal={setShowInvestmentTypeModal}
          amount={null}
        />
        <Form.Item
          label="Sending Bank"
          name="bankAccount"
          rules={[
            {
              required: true,
              message: 'Enter Bank Name',
            },
          ]}
        >
          <InputComponent
            type="default"
            placeholder="Enter Bank Name"
            MaxLength="50"
          />

        </Form.Item>
        <SingleDate
          vals={vals}
          setFormTotal={setFormTotal}
          amountLabel="Amount"
          dateLabel="Transaction Date"
          disabledDate={disabledDate}
        />
        <ButtonWrapper size={12}>
          <ButtonAnt
            label="Cancel"
            type="secondary"
            htmlType="button"
            onClick={() => {
              formType === 'wire'
                ? window.history.back()
                : setFormType('wire');
            }}
          />
          <OverrideButtonAnt linkTextColor={primary_color} label="Review" type="primary" htmlType="submit" />
        </ButtonWrapper>
      </Form>
    </Box>
    {showInvestmentTypeModal && (
      <Modal
        title="Investment Type"
        width={590}
        bodyStyle={{
          padding: '0 0 64px 0',
        }}
        maskStyle={{
          backgroundColor: `${theme.rgba.modal}`,
          zIndex: '1051',
        }}
        content={
          <InvestmentModalContent
            form={form}
            onInvestmentTypeChange={onInvestmentTypeChange}
            showAmount={false}
            pendingTransactionTypeClassID = {transactionTypeId}
           // disableZeroAccounts={false}
          />
        }
        visible={showInvestmentTypeModal}
        onCancel={() => {
          setShowInvestmentTypeModal(!showInvestmentTypeModal);
        }}
        footer={null}
        centered={true}
      />
    )}

    {isDuplicateTransModalVisible && (
      <Modal
        title="Possible Duplicate Transaction"
        width={750}
        bodyStyle={{
          padding: '32px 0 40px',
        }}
        maskStyle={{
          backgroundColor: `${theme.rgba.modal}`,
          zIndex: '1051',
        }}
        footer={null}
        visible={isDuplicateTransModalVisible}
        onCancel={() => setIsDuplicateTransModalVisible(false)}
        content={
          <DuplicateTransactionModalContent
            columns={duplicateTransColumns}
            data={duplicateTransData}
            handleDuplicateModalCancel={handleDuplicateModalCancel}
            handleDuplicateModalSubmit={handleDuplicateModalSubmit}
          />
        }
        centered={true}
      />
    )}

    {isInvalidDateModalVisible && (
      <Modal
        title="Invalid Date"
        width={750}
        bodyStyle={{
          padding: '32px 0 40px',
        }}
        maskStyle={{
          backgroundColor: `${theme.rgba.modal}`,
          zIndex: '1051',
        }}
        footer={null}
        visible={isInvalidDateModalVisible}
        onCancel={() => setIsInvalidDateModalVisible(false)}
        content={
          <Wrapper>
          <RegularText>
            {"The Date you selected is Invalid or a Holiday for the Investment Type you selected. Please select other Date"}
          </RegularText>
        </Wrapper> }
        centered={true}
      />
    )}
    </>
  );
};

export default WireForm;
