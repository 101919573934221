import React, { useState } from 'react';
import { Form } from 'antd';
import PurchaseForm from './forms/purchaseForm';
import ReviewForm from './forms/reviewForm';
const PurchaseFlow = ({ setSelectedPage, selectedPage }) => {
  const [selected, setSelected] = useState('single');
  const [showInvestmentTypeModal, setShowInvestmentTypeModal] = useState(false);
  const [form] = Form.useForm();
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [formType, setFormType] = useState('purchase');
  const [formData, setFormData] = useState({});
  const [amount, setAmount] = useState(0);
  const [showAccountsModal, setShowAccountsModal] = useState(false);

  return (
    <>
      {formType === 'review' ? (
        <ReviewForm
          form={form}
          formData={formData}
          selected={selected}
          setSelected={setSelected}
          amount={amount}
          setSelectedPage={setSelectedPage}
          selectedPage={selectedPage}
          setFormType={setFormType}
        />
      ) : (
        <PurchaseForm
          form={form}
          selected={selected}
          setSelected={setSelected}
          showInvestmentTypeModal={showInvestmentTypeModal}
          setShowInvestmentTypeModal={setShowInvestmentTypeModal}
          setShowReviewModal={setShowReviewModal}
          showReviewModal={showReviewModal}
          setFormType={setFormType}
          formData={formData}
          setFormData={setFormData}
          setAmount={setAmount}
          setSelectedPage={setSelectedPage}
          selectedPage={selectedPage}
          showAccountsModal={showAccountsModal}
          setShowAccountsModal={setShowAccountsModal}
        />
      )}
    </>
  );
};

export default PurchaseFlow;
