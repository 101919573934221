import React, { useState } from 'react';
import AppLayout from '../../common/layouts/appLayout';
import RedemptionTypeCard from './transferTypeCard';
import RedemptionFlow from './redemptionFlow';
import { Box } from '../../styles/globalStyle';
import { OverrideStyles } from '../purchaseFunds/purchaseContainer';
import { useSelector } from 'react-redux';
import NotFoundContainer from '../../notFound/notFoundContainer';

const Redemption = () => {
  const [selectedPage, setSelectedPage] = useState('TransferTypeCard');
  const showRedemption = useSelector((state) =>
    state.menuPermissions.menuPermissionOptions.includes(2) || state.menuPermissions.menuPermissionOptions.includes(4)
  );
  return (
    <>
    <OverrideStyles />
    {showRedemption ? (
      <AppLayout>
        <Box maxwidth="590px" width="100%" margin="0 auto" padding="80px 0 0">
          {selectedPage === 'TransferTypeCard' ? (
            <RedemptionTypeCard setSelectedPage={setSelectedPage} />
          ) : (
              <RedemptionFlow
                setSelectedPage={setSelectedPage}
                selectedPage={selectedPage}
              />
            )}
        </Box>
      </AppLayout>
    ) : (
        <NotFoundContainer />
      )
    }
    </>
  );
};

export default Redemption;
