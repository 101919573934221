import React from 'react';
import { theme, Text } from '@pfmam/pfmam';
// import moment from 'moment';
import { amountFormat } from '../../../utils/numberFormat';
import { Box } from '../../../styles/globalStyle';
// import ListWrapperComponent from '../../../common/components/listWrapper';
import DetailsModalContent from '../../../common/components/detailsModal';
const StopPaymentsDetailsModal = ({ selectedRecord }) => {
  return (
    <>
      <Text
        label="Check Amount"
        color={`${theme.colors.black_tertiary}`}
        fontSize={`${theme.font_size.size_xl}`}
        lineheight="24px"
        padding="0 0 8px 0"
      />
      <Box display="flex" alignitems="center" margin="0 0 32px 0">
        <Text
          label={amountFormat(selectedRecord.CheckAmount)}
          color={`${theme.colors.clean_black}`}
          fontSize="36px"
          lineheight="44px"
        />
      </Box>
      <DetailsModalContent selectedRecord={selectedRecord} />
      {/* <ListWrapperComponent
        label="Confirmation #"
        value={selectedRecord.confirmationNumber}
      />
      <ListWrapperComponent label="Check Payee" value={selectedRecord.payee} />
      <ListWrapperComponent
        label="Check Number"
        value={selectedRecord.checkNumber}
      />
      <ListWrapperComponent
        label="Investment Type"
        value={selectedRecord.investmentType}
      />
      <ListWrapperComponent
        label="Check Date"
        value={moment(selectedRecord.date).format('MMM DD, YYYY')}
      /> */}
    </>
  );
};

export default StopPaymentsDetailsModal;
