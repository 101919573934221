import React, { useState, useEffect } from 'react';
import {
  theme,
  Text,
  TableComponent,
  ButtonAnt,
} from '@pfmam/pfmam';
import styled from 'styled-components';
import { Box } from '../../../styles/globalStyle';
import { amountFormat } from '../../../utils/numberFormat';
import { useSelector } from 'react-redux';
import NoDataFound from '../../../common/components/noDataFound';

const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    color: ${(props) => props.linkTextColor};
    :hover {
      color: ${(props) => props.linkTextColor};
      opacity: 0.6;
    }
  }
`;

const ProductHoldingTable = ({ setActiveTab }) => {
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const [productHoldings, setProductHoldings] = useState();
  const selectedAccount = useSelector(
    (state) => state.accountProfileSlice.selectedAccount
  );
  const accountProfile = useSelector(
    (state) => state.accountProfileSlice.accountProfile
  );
  const showMoveFunds = useSelector(
    (state) => state.siteInfo.allowOnlineTransact
  );

  useEffect(() => {
    if (selectedAccount.AccNumber != '') {
      const data = accountProfile.AccountDetails.filter(
        (acc) => acc.AccNumber === selectedAccount.AccNumber
      );
      if (data.length > 0) {
        setProductHoldings(data[0].ProductHoldings);
      }
    }
  }, []);

  const productHoldingsColumns = [
    {
      title: 'Investment Type',
      dataIndex: 'Name',
      key: 'Name',
      width: '50%',
      align: 'left',
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      className: 'sort-space-none',
    },
    {
      title: 'Market Value',
      dataIndex: 'MarketValue',
      key: 'MarketValue',
      width: '50%',
      align: 'right',
      sorter: true,
      sorter: (a, b) => a.MarketValue - b.MarketValue,
      render: (text) => <Text label={amountFormat(text)} />,
    },
  ];
  return (
    <>
      <TableComponent
        className="table-primary"
        title={
          <Box
            display="flex"
            justifycontent="space-between"
            alignitems="center"
            padding="24px 32px 24px 32px"
            border={`1px solid ${theme.colors.grey_fossil}`}
            borderwidth="0 0 1px 0"
          >
            <Text
              label="Investment Holdings"
              fontSize={`${theme.font_size.size_3xl}`}
              lineheight="32px"
              color={`${theme.colors.black_tertiary}`}
            />
            <OverrideButtonAnt
              linkTextColor={primary_color}
              type="link"
              label="View Holding Details"
              onClick={() => {showMoveFunds ? setActiveTab('4') : setActiveTab('3')} }
            />
          </Box>
        }
        columns={productHoldingsColumns}
        dataSource={productHoldings}
        locale={{
          emptyText: (
            <NoDataFound text="No available holdings" padding="12px 0" />
          ),
        }}
        showSorterTooltip={false}
      />
    </>
  );
};

export default ProductHoldingTable;
