/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Layout, Dropdown } from 'antd';
import {
  theme,
  Text,
  Tabs,
  ButtonAnt,
  Menu,
} from '@pfmam/pfmam';
import { Container, Box, Banner } from '../styles/globalStyle';
import AppLayout from '../common/layouts/appLayout';
import StatementsComponent from './components/statementsComponent';
import DocumentsComponent from './components/documentsComponent';
import DailyConfirmsComponent from './components/dailyConfirmsComponent';
import { FilePdfOutlined, CaretDownOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
const { Content } = Layout;

const OverrideTabs = styled(Tabs)`
  .ant-tabs-ink-bar {
    background: ${(props) => props.linkTextColor};
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.linkTextColor};
  }
`;

export const OverrideStyles = createGlobalStyle`
  .helpful-dropdown-menu {
    .ant-dropdown-menu {
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px rgba(0, 0, 0, 0.08);
      border-radius: 2px;
      padding: 4px 0;
      .ant-dropdown-menu-item {
        font-size: ${theme.font_size.size_l};
        line-height: 20px;
        color: ${theme.colors.black_secondary};
        padding: 8px 12px;
        &:hover {
          background: ${theme.colors.light_grey};
        }
        .anticon {
          margin-right: 8px;
        }
      }
    }
  }
  .rc-virtual-list-scrollbar-thumb {
    background: rgb(0, 0, 0, 0.2) !important;
  }
`;

const HelpfulGuideButton = styled(ButtonAnt)`
  background-color: ${theme.colors.light_blue};
  border: 1px solid ${theme.colors.light_blue};
  padding: 14px 12px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  &.ant-btn > .anticon + span,
  &.ant-btn > span + .anticon {
    margin-left: 0;
  }
  &.ant-btn > .anticon {
    margin-left: 12px;
    color: ${theme.colors.grey_stone};
  }
`;

const ContentWrapper = styled(Content)`
  .ant-table {
    border-radius: 4px;
    .ant-table-tbody {
      & > tr {
        cursor: pointer;
        &:hover {
          &.ant-table-row:not(.ant-table-expanded-row) {
            background: linear-gradient(
                0deg,
                rgba(23, 54, 93, 0.07),
                rgba(23, 54, 93, 0.07)
              ),
              ${theme.colors.white};
          }
        }
        & > td {
          vertical-align: top;
          padding: 20px 32px;
          &.ant-table-column-sort {
            background: ${theme.colors.transparent};
          }
        }
        &:last-child {
          & > td {
            border-bottom-color: ${theme.colors.transparent};
          }
        }
      }
    }
    .ant-table-thead {
      & > tr {
        & > th {
          background: ${theme.colors.light_grey};
          font-family: 'Inter';
          padding: 0 32px;
          font-weight: normal;
          line-height: 20px;
          &:not(:last-child) {
            &:not(.ant-table-selection-column) {
              &:not(.ant-table-row-expand-icon-cell) {
                &:not([colspan]) {
                  &::before {
                    background-color: ${theme.colors.transparent};
                  }
                }
              }
            }
          }
        }
      }
    }
    &.ant-table.ant-table-middle {
      .ant-table-thead {
        .ant-table-column-sorters {
          padding: 0;
          .ant-table-column-sorter-inner .anticon {
            color: ${theme.colors.grey_stone};
          }
          .ant-table-column-sorter-up.active,
          .ant-table-column-sorter-down.active {
            color: ${theme.colors.black_sorted};
          }
        }
        .ant-table-column-sorter-inner {
          margin-top: 0;
        }
      }
    }
    .sort-space-none {
      .ant-table-column-sorters {
        justify-content: flex-start;
      }
      .ant-table-column-title {
        flex: 0 0 auto;
      }
      &.flex-end {
        .ant-table-column-sorters {
          justify-content: flex-end;
        }
      }
    }
    .ant-avatar {
      min-width: 40px;
    }
  }
  .ant-table-title {
    padding: 0 !important;
  }
  .ant-table {
    &.ant-table-middle {
      .ant-table-tbody {
        .ant-table-wrapper {
          &:only-child {
            .ant-table {
              margin: -19px -32px;
            }
          }
        }
        .ant-table-container {
          .ant-table-tbody {
            .ant-table-row {
              .ant-table-cell {
                padding: 20px 32px;
                background-color: ${theme.colors.grey_faint};
              }
              &:hover {
                .ant-table-cell {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const StatementsDocumentsContainer = () => {
  const [activeTab, setActiveTab] = useState('statements');
  const showMoveFunds = useSelector(
    (state) => state.siteInfo.allowOnlineTransact
  );
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  return (
    <AppLayout>
      <OverrideStyles />

      <ContentWrapper>
        <Banner bgcolor={primary_color} height="124px">
          <Box
            display="flex"
            alignitems="center"
            height="100%"
            minwidth="100%"
            width="100%"
            zindex="3"
            position="relative"
            padding="44px 0 36px 0"
          >
            <Container>
              <Box
                display="flex"
                justifycontent="space-between"
                alignitems="flex-start"
                margin="0"
              >
                <Box flex="1">
                  <Text
                    color={`${theme.colors.white}`}
                    fontSize="36px"
                    lineheight="44px"
                    label="Statements & Documents"
                  />
                </Box>
                {/* <Dropdown
                  overlay={
                    <Menu
                      type={'withmenu'}
                      multiple={false}
                      mode={'inline'}
                      items={[
                        {
                          title: 'Statement Guide',
                          disabled: false,
                          key: 'statement',
                          icon: <FilePdfOutlined />,
                        },
                        {
                          title: 'IP Statement Guide',
                          disabled: false,
                          key: 'ip_statement',
                          icon: <FilePdfOutlined />,
                        },
                        {
                          title: 'New Daily Confirm Format',
                          disabled: false,
                          key: 'daily_format',
                          icon: <FilePdfOutlined />,
                        },
                      ]}
                      color={`${theme.colors.black_secondary}`}
                      width={'230px'}
                    />
                  }
                  placement="bottomRight"
                  trigger={['click']}
                  overlayClassName="helpful-dropdown-menu"
                >
                  <HelpfulGuideButton
                    icon={<CaretDownOutlined />}
                    label="Helpful Guides"
                    type="secondary"
                  />
                </Dropdown> */}
              </Box>
            </Container>
          </Box>
        </Banner>
        <>
          <Tabs
            activeTabColor={primary_color}
            //linkTextColor={primary_color}
            defaultActiveKey={activeTab}
            activeKey={activeTab}
            onTabClick={(key) => setActiveTab(key)}
            tabsOptions={[
              {
                content: <StatementsComponent recipientType="multiple" />,
                key: 'statements',
                label: 'Statements',
              },
              ...(showMoveFunds
                ? [
                    {
                      content: (
                        <DailyConfirmsComponent recipientType="multiple" />
                      ),
                      key: 'dailyConfirms',
                      label: 'Daily Confirms',
                    },
                  ]
                : []),
              {
                content: <DocumentsComponent />,
                key: 'documents',
                label: 'Documents',
              },
            ]}
            type="horizontal-tab"
          />
        </>
      </ContentWrapper>
    </AppLayout>
  );
};

export default StatementsDocumentsContainer;
