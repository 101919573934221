import { createSlice } from '@reduxjs/toolkit';
import { setAuthHeader } from '../utils/axiosHeaders';
export const initialState = {
  accountProfile: {
    Balance: 0,
    AccountDetails: [],
    BalanceChangeValue: 0,
    BalanceChangePercent: 0,
    Balance_Date: '1900/01/01',
    ProductDetails: [],
  },
  selectedAccount: {
    AccNumber: '',
    AccountUniqueID: '',
    AccountType: '',
  },
  filteredInvestmentTypes: [],
  filteredTransactionTypes: [],
  inputSearchText: '',
  filteredAccounts: [],
};

const accountProfileSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    storeAccountProfile: (state, { payload }) => {
      state.accountProfile.Balance = payload.Balance;
      state.accountProfile.AccountDetails = payload.AccountDetails;
      state.accountProfile.BalanceChangeValue = payload.BalanceChangeValue;
      state.accountProfile.BalanceChangePercent = payload.BalanceChangePercent;
      state.accountProfile.Balance_Date = payload.Balance_Date;
      state.accountProfile.ProductDetails = payload.ProductDetails;
    },
    storeSelectedAccountNumber: (state, { payload }) => {
      state.selectedAccount.AccNumber = payload.AccNumber;
      state.selectedAccount.AccountUniqueID = payload.AccountUniqueID;
      state.selectedAccount.AccountType = payload.AccountType;
    },
    storeFilteredProductTypes: (state, { payload }) => {
      state.filteredInvestmentTypes = payload;
    },
    storeFilteredTransactionTypes: (state, { payload }) => {
      state.filteredTransactionTypes = payload;
    },
    storeInputSearch: (state, { payload }) => {
      state.inputSearchText = payload;
    },
    storeFilteredAccounts: (state, { payload }) => {
      state.filteredAccounts = payload;
    },
  },
});

export const {
  storeAccountProfile,
  storeSelectedAccountNumber,
  storeFilteredProductTypes,
  storeFilteredTransactionTypes,
  storeInputSearch,
  storeFilteredAccounts,
} = accountProfileSlice.actions;
export default accountProfileSlice.reducer;
