/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { CalendarOutlined } from '@ant-design/icons';
import { theme, ButtonAnt } from '@pfmam/pfmam';
import { DatePicker } from 'antd';
import moment from 'moment';
import OutsideAlerter from './outsideAlerter';
const { RangePicker } = DatePicker;

export const OverrideStyle = createGlobalStyle`
  .ant-picker-range-arrow {
    visibility: hidden;
  }
  .ant-picker-panel-container {
    background-color: ${theme.colors.white};
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    border: 0;
  }
  .ant-picker-content th, .ant-picker-content td {
    padding: 4px 0;
  }
  .ant-picker-header {
    & > {
      button {
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  .ant-picker-header-view {
    & > {
      button {
        &:not(:first-child) {
          margin-left:5px;
        }
      }
    }
  }
  .ant-picker-cell {
    color: ${theme.colors.black_secondary};
    &::before {
      height: 26px;
    }
  }
  .ant-picker-content th,
  .ant-picker-header > button,
  .ant-picker-header > button:hover,
  .ant-picker-header-view > button,
  .ant-picker-header-view > button:hover,
  .ant-picker-cell-in-view {
    color: ${theme.colors.black_secondary};
  }
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: ${theme.colors.grey_faint};
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: ${theme.colors.blue_primay};
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, 
  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, 
  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before, 
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, 
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, 
  .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, 
  .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after, 
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, 
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: ${theme.rgba.gradient_blue};
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, 
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, 
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, 
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, 
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, 
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    background: ${theme.rgba.gradient_blue};
    left: 0; 
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, 
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, 
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, 
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, 
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, 
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after,
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after, 
  .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after, 
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after, 
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    border-color: ${theme.colors.transparent} !important;
  }
  .ant-picker-cell-in-range.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: ${theme.colors.transparent};
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
    left: 0;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
    right: 0;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
    border-radius: 2px;
  }
  .ant-picker-footer {
    border-bottom: 0;
    .ant-picker-today-btn {
      color: ${theme.colors.blue_primay};
    }
  }
  .ant-picker-cell.ant-picker-cell-today.ant-picker-cell-selected::before {
    background: none;
    .ant-picker-footer {
      border-bottom: 0;
      .ant-picker-today-btn {
        color: ${theme.colors.blue_primay};
      }
    }
  }
  .indicator {
    position: relative;
    display: inline-block;
    height: 100%;
    width: 100%;
    z-index:2;
    &::after {
      content: '';
      position: absolute;
      height:4px;
      min-width:4px;
      width:4px;
      right: 8px;
      top: 0;
      background-color: ${theme.colors.copper_yellow};
      border-radius:50%;
    }
  }
  .ant-picker-cell-disabled::before,
  .ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner, 
  .ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner, 
  .ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner, 
  .ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
  .ant-picker-cell-disabled .ant-picker-cell-inner {
    background-color: ${theme.colors.transparent};
    color: ${theme.colors.grey_stone};
  }
  .ant-picker-cell-disabled {
    pointer-events: all;
    cursor: not-allowed;
  }
  .ant-picker-cell-disabled.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, 
  .ant-picker-cell-disabled.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, 
  .ant-picker-cell-disabled.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background-color: ${theme.colors.transparent};
  }
`;

const DatePickerButton = styled.button`
  position: relative;
  background: ${theme.colors.white};
  height: 52px;
  border: 1px solid ${theme.colors.grey};
  border-radius: 2px;
  font-size: ${theme.font_size.size_xl};
  color: ${theme.colors.black_secondary};
  padding: 14px 10px;
  line-height: 23px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  white-space: nowrap;
  & > {
    .anticon {
      font-size: ${theme.font_size.size_l};
      margin-right: 8px;
    }
  }
  &:focus {
    box-shadow: ${theme.shadows.input_focus};
    border-color: ${theme.colors.anchor_blue};
  }
  .ant-picker {
    position: absolute;
    left: 0px;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0px;
    background: ${theme.colors.transparent};
    padding: 0;
    .ant-picker-input,
    .ant-picker-range-separator,
    .ant-picker-separator {
      display: none;
    }
  }
`;

const DatePickerBody = styled.div`
  display: flex;
`;
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 0;
  width: 142px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 2px 0px 0px 2px;
  position: relative;
  z-index: 1;
`;

const ActionButton = styled(ButtonAnt)`
  height: 36px;
  font-size: ${theme.font_size.size_l};
  color: ${theme.colors.black_secondary};
  padding: 7px 12px;
  line-height: 20px;
  text-align: left;
  &.active {
    background: ${theme.colors.grey_faint};
    opacity: 1;
  }
`;

const MenuWrapper = styled.div`
  position: relative;
`;
const RenderDatePicker = ({
  dates,
  setDates,
  dateValues,
  setDateValues,
  dateOption,
  setDateOption,
  dateLabel,
  setDateLabel,
  dateOpen,
  setDateOpen,
  handleDateChange,
  picker = 'date',
  monthPickerPanel = false,
  customDateRenderer = null,
}) => {
  const [dateClicks, setDateClicks] = useState(0);
  useEffect(() => {
    if (dateOption) {
      switch (dateOption) {
        case 'currentMonth':
          setDateLabel('Current Month');
          setDateValues([moment().startOf('month'), moment()]);
          break;
        case 'previousMonth':
          setDateValues([
            moment().subtract(1, 'months').startOf('month'),
            moment().subtract(1, 'months').endOf('month'),
          ]);

          setDateLabel('Previous Month');
          break;
        case 'last60days':
          setDateLabel('Last 60 days');
          setDateValues([moment().subtract(60, 'd'), moment()]);
          break;
        case 'last3months':
          setDateLabel('Last 3 Months');
          setDateValues([
            moment().subtract(3, 'months'),
            moment().subtract(1, 'months'),
          ]);
          break;
        case 'yearToDate':
          setDateValues([
            moment().startOf('year'),
            moment().subtract(1, 'months'),
          ]);

          setDateLabel('Year To Date');
          break;
        default:
          setDateLabel('Custom');
      }
    }
  }, [dateOption]);

  const handleOutsideClick = () => {
    dateOpen && handleDateChange();
    setDateOpen(false);
    setDateClicks(0);
  };

  const FilterPanel = ({ panelNode }) => {
    return (
      <OutsideAlerter handleOutsideClick={handleOutsideClick}>
        <OverrideStyle />
        <DatePickerBody>
          <Actions>
            {monthPickerPanel ? (
              <>
                <ActionButton
                  className={dateOption === 'last3months' ? 'active' : null}
                  label="Last 3 Months"
                  type="link"
                  onClick={(e) => setDateOption('last3months')}
                />
                <ActionButton
                  className={dateOption === 'yearToDate' ? 'active' : null}
                  label="Year to Date"
                  type="link"
                  onClick={() => setDateOption('yearToDate')}
                />
              </>
            ) : (
              <>
                <ActionButton
                  className={dateOption === 'currentMonth' ? 'active' : null}
                  label="Current Month"
                  type="link"
                  onClick={(e) => setDateOption('currentMonth')}
                />
                <ActionButton
                  className={dateOption === 'previousMonth' ? 'active' : null}
                  label="Previous Month"
                  type="link"
                  onClick={() => setDateOption('previousMonth')}
                />
                <ActionButton
                  className={dateOption === 'last60days' ? 'active' : null}
                  label="Last 60 Days"
                  type="link"
                  onClick={() => setDateOption('last60days')}
                />
              </>
            )}
            <ActionButton
              className={dateOption === 'custom' ? 'active' : null}
              label="Custom"
              type="link"
              onClick={() => {
                setDateOption('custom');
                setDateLabel('Custom');
                setDateValues([moment(), moment()]);
              }}
            />
          </Actions>
          <MenuWrapper>{panelNode}</MenuWrapper>
        </DatePickerBody>
      </OutsideAlerter>
    );
  };

  const getTitle = () => {
    const format = monthPickerPanel ? 'MMM YYYY' : 'MMM DD, YYYY';
    if (dateOption === 'custom') {
      return (
        moment(dateValues[0]).format(format) +
        '-' +
        moment(dateValues[1]).format(format)
      );
    } else {
      return dateLabel;
    }
  };

  function disabledDate(current) {
    // Can not select days after today
    return current && current > moment().endOf('day');
  }

  return (
    <DatePickerButton
      type="button"
      onClick={(e) => {
        e.preventDefault();
        setDateOpen(true);
      }}
    >
      <CalendarOutlined />
      {getTitle()}

      <RangePicker
        value={dateValues}
        defaultValue={dateValues}
        panelRender={(panelNode) => <FilterPanel panelNode={panelNode} />}
        onCalendarChange={(val, _, info) => {         
          if (val && val.length > 0) {
            setDateOption('custom');
            dateClicks === 1 ? setDateClicks(0) : setDateClicks(1);
            if (dateClicks === 0) {
              if (info.range === 'start') {
                setDateValues([val[0], val[0]]);
              } else {
                setDateValues([val[1], val[1]]);
              }
            } else if (dateClicks === 1) {
              if (info.range === 'start') {
                if ((dateValues[0].format('YYYY-MM-DD')) > (val[0].format('YYYY-MM-DD'))){                 
                  setDateValues([val[0],dateValues[0]]);
                }
                else{
                  setDateValues([dateValues[0], val[0]]);
                }                
              } else {
                setDateValues([dateValues[0], val[1]]);
              }
            }
          } else {
            setDateClicks(0);
            monthPickerPanel
              ? setDateOption('last3months')
              : setDateOption('last60days');
          }
        }}
        suffixIcon={false}
        bordered={false}
        allowClear={false} //false}
        programColor={`${theme.colors.blue_primay}`}
        open={dateOpen}
        disabledDate={disabledDate}
        dateRender={(current) => {
          if (customDateRenderer && customDateRenderer.length > 0) {
            if (customDateRenderer.includes(current.format('MM/DD/YYYY'))) {
              return (
                <>
                  <span className="ant-picker-cell-inner indicator">
                    {current.date()}
                  </span>
                </>
              );
            } else {
              return (
                <div className="ant-picker-cell-inner">{current.date()}</div>
              );
            }
          } else {
            return (
              <div className="ant-picker-cell-inner">{current.date()}</div>
            );
          }
        }}
        format={monthPickerPanel ? 'YYYY-MM' : 'YYYY-MM-DD'}
        picker={picker}
      />
    </DatePickerButton>
  );
};

export default RenderDatePicker;
