import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Container, Anchor } from '../../../styles/globalStyle';
import {
  Text,
  theme,
  TableComponent,
  CardAnt,
} from '@pfmam/pfmam';
import { percentFormatTerm } from '../../../utils/numberFormat';
import { GetTermYields } from '../../../actions/actions';
import styled from 'styled-components';
import moment from 'moment';
import { Row, Col } from 'antd';

const Wrapper = styled.div`
  padding-top: 40px;
  .ant-card-head-title {
    font-size: 24px;
    height: 80px;
    display: flex;
    align-items: center;
  }
  .ant-card {
    border: none;
    .ant-card-bordered {
      border: none;
    }
    .product-card {
      border: none;
    }
  }
  .card-content {
    margin-bottom: 20px;
  }
`;

const ListWrapper = styled.ul`
  padding-left: 20px;
  list-style-type: disc;
  padding-bottom: 24px;
`;

const BulletPointText = styled.li`
  padding-top: 10px;
  color: ${theme.colors.grey_tertiary};
  font-size: 16px;
`;

const RegularText = styled.span`
  color: ${(props) => props.color};
  margin-left: 20px;
  font-size: 16px;
  line-height: 24px;
`;

const Number = styled.p`
  color: ${(props) => props.color};
  font-size: 16px;
  margin-left: 20px;
`;
function modifyTermRatesModal(termRates) {
  var resultantTermRates = [];
  termRates.map(function (item) {
    resultantTermRates.push({
      time: item.Days,
      date: item.MaturityDate,
      yield: item.NetYield,
    });
  });
  return resultantTermRates;
}
const Term = ({ phoneNumber }) => {
  const [termYieldData, SetTermYieldData] = useState([]);
  const programName = useSelector(
    (state) => state.programsClientsSlice.selectedClient.payload[0].programname
  );
  const [labelTermText, setLabelTermText] = useState(
    programName + ' Term Yields'
  );
  const fundgroup_Id = useSelector((state) => state.siteInfo.fundGroupID);

  useEffect(() => {
    GetTermYields(fundgroup_Id)
      .then((response) => {
        if (response.data) {
          SetTermYieldData(modifyTermRatesModal(response.data.Table1));
          if (response.data.Table[0].ReturnValue != null) {
            setLabelTermText(
              programName +
                ' Term Yields As of ' +
                moment(response.data.Table[0].ReturnValue).format('MMM DD,YYYY')
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const liquidColumn = [
    {
      title: 'Maturity Time',
      dataIndex: 'time',
      key: 'time',
      sorter: (a, b) => a.time - b.time,
      align: 'left',
      className: 'sort-space-none',
      width: '40%',
      render: (text) => (
        <Box display="flex">
          <Text label={`${text} Days`} />
        </Box>
      ),
    },
    {
      title: 'Maturity Date',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      align: 'left',
      className: 'sort-space-none',
      width: '40%',
      render: (text) => (
        <Box display="flex">
          <Text label={moment(text).format('MMM DD, YYYY')} />
        </Box>
      ),
    },
    {
      title: 'Yield',
      dataIndex: 'yield',
      key: 'yield',
      align: 'center',
      className: 'sort-space-none',
      width: '40%',
      render: (text) => (
        <>
          {text || text == 0 ? (
            <Box
              display="inline-block"
              boxcolor={`${theme.colors.light_blue}`}
              padding="2px 8px"
              radius="2px"
            >
              <Text
                label={text || text == 0 ? percentFormatTerm(text) : null}
                fontSize={`${theme.font_size.size_l}`}
                lineheight="20px"
              />
            </Box>
          ) : null}
        </>
      ),
    },
  ];
  return (
    <>
      <Container>
        <Wrapper>
          <Row gutter={20}>
            <Col md={16}>
              <TableComponent
                className="table-primary"
                title={
                  <Box
                    display="flex"
                    justifycontent="space-between"
                    alignitems="center"
                    padding="24px 32px 24px 32px"
                    border={`1px solid ${theme.colors.grey_fossil}`}
                    borderwidth="0 0 1px 0"
                  >
                    <Text
                      label={labelTermText}
                      fontSize={`${theme.font_size.size_3xl}`}
                      lineheight="32px"
                      color={`${theme.colors.black_tertiary}`}
                    />
                  </Box>
                }
                columns={liquidColumn}
                dataSource={termYieldData}
                showSorterTooltip={false}
              />
            </Col>
            <Col md={8}>
              <CardAnt
                title={'Term Description'}
                cardContent={
                  <Box>
                    <ListWrapper>
                      <BulletPointText>
                        Fixed-rate, fixed term investment
                      </BulletPointText>
                      <BulletPointText>
                        Investments can range from the Fund’s minimum up to any
                        desired amount
                      </BulletPointText>
                      <BulletPointText>
                        Principal & dividend paid at maturity
                      </BulletPointText>
                      <BulletPointText>
                        Customized maturity dates available
                      </BulletPointText>
                      <BulletPointText>
                        <Anchor
                          color={`${theme.colors.blue_primay}`}
                          display="inline-block"
                          href="/dashboard"
                        >
                          View additional details
                        </Anchor>{' '}
                        and information in Funds Information Statement
                      </BulletPointText>
                      <BulletPointText>
                      Maturity date shown is an estimated maturity date. Actual maturity date will be determined upon purchase of Term
                      </BulletPointText>
                    </ListWrapper>
                    <RegularText>To purchase, contact us at</RegularText>
                    <Number color={theme.colors.blue_primay}>
                      {phoneNumber}
                    </Number>
                  </Box>
                }
                className="basic-card"
              />
            </Col>
          </Row>
        </Wrapper>
      </Container>
    </>
  );
};

export default Term;
