/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { Row, Col, Form, Checkbox, Table, Input, Tooltip, Space } from 'antd';
import {
  theme,
  CardAnt,
  ButtonAnt,
  InputComponent,
  SelectComponent,
  TableComponent,
} from '@pfmam/pfmam';
import { Container, Box } from '../../styles/globalStyle';
import { CaretDownOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import NoDataFound from '../../common/components/noDataFound';
import phoneFormatter from 'phone-formatter';
import { MaskedInput } from 'antd-mask-input';
import { GetContactsAcctPortPermissions } from '../../actions/actions';
import { InfoCircleOutlined } from '@ant-design/icons';



const ButtonWrapper = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
`;
const OverrideStyle = createGlobalStyle`
  .ant-form-item-control-input-content {
    & > span {
      width: 100%;
    }
    .primary.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 52px;
        width: 100%;
        background: ${theme.colors.white};
        color: ${theme.colors.black_secondary};
        border: 1px solid ${theme.colors.grey};
        padding: 14px 20px;
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: 20px;
        }
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
      .ant-select-arrow {
        font-size: ${theme.font_size.size_l};
        margin-top: 0;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
      }
      &.ant-select-focused {
        .ant-select-selector {
          box-shadow: ${theme.shadows.input_focus};
          border-color: ${theme.colors.blue_primay};
        }
          .ant-select-arrow {
          color: ${theme.colors.black_secondary};
        }
      } 
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.linkTextColor};
    border-color: ${(props) => props.linkTextColor};
  }
  
  .form-select-dropdown {
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      .ant-select-item {
        &.ant-select-item-group {
          color: ${theme.colors.grey_tertiary};
          min-height: 16px;
          line-height: 16px;
          padding: 6px 12px;
          &:hover {
            background: none;
          }
        }
      }
      .ant-select-item-option-selected,
      .ant-select-item-option-active {
        &:not(.ant-select-item-option-disabled) {
          background: ${theme.colors.white};
          font-weight: normal;
        }
      }
      .ant-select-item {
        min-height: 40px;
        line-height: 24px;
        padding: 8px 12px;
        font-size: ${theme.font_size.size_xl};
        color: ${theme.colors.black_secondary};
        font-family: 'Inter';
        &:hover {
        background: ${theme.colors.light_grey};
        font-family: 'Inter';
      }
    }
  }
`;

const TableWrapper = styled.div`
  .ant-table-title {
    padding: 0 !important;
  }
  .table-primary .ant-table-thead > tr > th {
    font-family: 'Inter';
    font-weight: bold;
    text-align: center;
    border: 1px solid ${theme.colors.grey};
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: ${theme.colors.transparent};
  }
  .ant-table-tbody > tr > td:not(:first-child) {
    text-align: center;
  }

  .ant-table-tbody > tr > td {
        border: 1px solid ${theme.colors.grey};

  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
   /* border-color: #1890ff; */
   border-color: ${(props) => props.linkTextColor} !important;
  }

  .ant-table.ant-table-middle
    .ant-table-tbody
    .ant-table-wrapper:only-child
    .ant-table {
    margin: -19px -16px;
  }
 
  
  td.ant-table-column-sort {
    background: ${theme.colors.white};
  }
  .ant-table-column-sorters {
    justify-content: flex-start;
    .ant-table-column-title {
      flex: 0 0 auto;
    }
    .ant-table-column-sorter-inner .anticon {
      color: ${theme.colors.grey_stone};
    }
    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
      color: ${theme.colors.black_sorted};
    }
  }
  .ant-table-tbody {
    & > tr {
      & > td {
        &.right-aligned-cell {
          padding-right: 32px;
        }
      }
    
      .ant-table-row-expand-icon {
        line-height: 32px;
        &:focus,
        &:hover {
          color: ${theme.colors.blue_primay};
          border-color: ${theme.colors.blue_primay};
        }
      }
      cursor: pointer;
    }
    .ant-table-tbody,
    .ant-table-expanded-row {
      .ant-table-row {
        cursor: default;
      }
    }
  }
  .link {
    &:hover {
      opacity: 0.6;
    }
    &:focus,
    &:active {
      opacity: 1;
    }
  }
`;

const Wrapper = styled.div`
  
`;

const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
  }
`;

const OverrideRemoveAccessButtonAnt = styled(ButtonAnt)`
  &&& {
    background: red;
    color: white;
    font-size: 14px;
    height:42px;
    padding-top: 5px;
    padding-bottom : 5px;
  }
`;




const RenderCreateContactPermissionForm = (props) => {
  const { permissionsDataSource, setPermissionsDataSource, OnPermissionsOkClick, formDisabled, OnPermissionsCancelClick, canShowClientLevelPortfolio, clientLevelPortfolioPermission, setClientLevelPortfolioPermission, setIsTouched } = props;
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const programId = clientDetails.payload[0].programid;
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);

  const handleClientLevelPortfolioPermissionCheckboxChange = (e) => {
    setClientLevelPortfolioPermission(e.target.checked ? 1 : 0);
    AssignOrRemoveAllPortfolioPermission(e.target.checked);
  };

  const handleCheckboxChange = (e, row, type) => {
    setIsTouched(true);
    const newData = [...permissionsDataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    if (index > -1) {

      const item = newData[index];
      if (programId !== 999) {
        if (type === 'IsViewReports') {
          if (!e.target.checked) {
            newData[index] = { ...item, IsViewReports: e.target.checked ? true : false, IsEnterTransactions: false, IsCheckVerifier: false }
          } else
            newData[index] = { ...item, IsViewReports: e.target.checked ? true : false }
        } else if (type === 'IsEnterTransactions') {
          newData[index] = { ...item, IsEnterTransactions: e.target.checked ? true : false }
        } else if (type === 'IsModifyBankingInstructions') {
          newData[index] = { ...item, IsModifyBankingInstructions: e.target.checked ? true : false }
        } else if (type === 'IsCloseAccounts') {
          newData[index] = { ...item, IsCloseAccounts: e.target.checked ? true : false }
        } else if (type === 'IsManageUsers') {
          newData[index] = { ...item, IsManageUsers: e.target.checked ? true : false }
        } else if (type === 'IsStatementRecipient') {
          newData[index] = { ...item, IsStatementRecipient: e.target.checked ? 1 : 0 }
        } else if (type === 'IsCheckVerifier') {
          newData[index] = { ...item, IsCheckVerifier: e.target.checked ? true : false }
        } else if (type === 'IsCheckSigner') {
          newData[index] = { ...item, IsCheckSigner: e.target.checked ? true : false }
        }
      } else {
        if (type === 'IsViewReports') {
          newData[index] = { ...item, IsViewReports: e.target.checked ? 1 : 0 }
        } else if (type === 'IsStatementRecipient') {
          newData[index] = { ...item, IsStatementRecipient: e.target.checked ? 1 : 0 }
        }
      }


      setPermissionsDataSource(newData);
    }

  };

  const poolColumns = [
    {
      title: 'Account',
      dataIndex: 'DisplayName',
    },
    {
      title: <Tooltip
        placement="top"
        title="View account Information"
        overlayClassName="tooltip"
      >
        <span>View Reports</span>
        <InfoCircleOutlined
        style={{padding:'2px 2px 2px 2px',cursor: 'pointer'}}
      ></InfoCircleOutlined>
      </Tooltip>,
      dataIndex: 'IsViewReports',
      editable: true,
      render: (_, record) => (
        <Checkbox disabled={formDisabled} checked={record.IsViewReports} onChange={(e) => handleCheckboxChange(e, record, 'IsViewReports')} />
      ),
    },
    {
      title: <Tooltip
        placement="top"
        title="Initiate transactions to/from instructions on file"
        overlayClassName="tooltip"
      >
          <span>Enter Transactions</span> 
          <InfoCircleOutlined
        style={{padding:'2px 2px 2px 2px',cursor: 'pointer'}}
      ></InfoCircleOutlined>
      </Tooltip>,
      dataIndex: 'IsEnterTransactions',
      editable: true,
      render: (_, record) => (
        <Checkbox disabled={formDisabled ? true : record.IsViewReports ? false : true} checked={record.IsEnterTransactions} onChange={(e) => handleCheckboxChange(e, record, 'IsEnterTransactions')} />
      ),
    },
    {
      title: <Tooltip
        placement="top"
        title="Add/remove banking instructions"
        overlayClassName="tooltip"
      >
        <span>Modify Banking Instructions</span>
        <InfoCircleOutlined
        style={{padding:'2px 2px 2px 2px', cursor: 'pointer'}}
      ></InfoCircleOutlined>
      </Tooltip>,
      dataIndex: 'IsModifyBankingInstructions',
      editable: true,
      render: (_, record) => (
        <Checkbox disabled={formDisabled ? true : record.IsViewReports ? false : true} checked={record.IsModifyBankingInstructions} onChange={(e) => handleCheckboxChange(e, record, 'IsModifyBankingInstructions')} />
      ),
    },
    {
      title: <Tooltip
        placement="top"
        title="Open and close accounts"
        overlayClassName="tooltip"
      >
        <span>Close Accounts</span>
        <InfoCircleOutlined
        style={{padding:'2px 2px 2px 2px',cursor: 'pointer'}}
      ></InfoCircleOutlined>
      </Tooltip>,
      dataIndex: 'IsCloseAccounts',
      editable: true,
      render: (_, record) => (
        <Checkbox disabled={formDisabled ? true : record.IsViewReports ? false : true} checked={record.IsCloseAccounts} onChange={(e) => handleCheckboxChange(e, record, 'IsCloseAccounts')} />
      ),
    },
    {
      title: <Tooltip
        placement="top"
        title="Add/remove permissions to/from contacts"
        overlayClassName="tooltip"
      >
        <span>Manage Users</span>
        <InfoCircleOutlined
        style={{padding:'2px 2px 2px 2px',cursor: 'pointer'}}
      ></InfoCircleOutlined>
      </Tooltip>,
      dataIndex: 'IsManageUsers',
      editable: true,
      render: (_, record) => (
        <Checkbox disabled={formDisabled ? true : record.IsViewReports ? false : true} checked={record.IsManageUsers} onChange={(e) => handleCheckboxChange(e, record, 'IsManageUsers')} />
      ),
    },
    {
      title: <Tooltip
        placement="top"
        title="Create a unique statement with this contact's name and address, in addition to anyone else marked as a statement recipient"
        overlayClassName="tooltip"
      >
        <span>Statement Recipient</span>
        <InfoCircleOutlined
        style={{padding:'2px 2px 2px 2px',cursor: 'pointer'}}
      ></InfoCircleOutlined>
      </Tooltip>,
      dataIndex: 'IsStatementRecipient',
      editable: true,
      render: (_, record) => (
        <Checkbox disabled={formDisabled} checked={record.IsStatementRecipient} onChange={(e) => handleCheckboxChange(e, record, 'IsStatementRecipient')} />
      ),
    },

    ...(programId === 44 ?
      [
        {
          title: <Tooltip
            placement="top"
            title="Check verifiers will be responsible for reviewing check activity on the PLGIT account(s) each business day and contacting PLGIT in a timely manner if payment needs to be stopped"
            overlayClassName="tooltip"
          >
            <span>Check Verifier</span>
            <InfoCircleOutlined
        style={{padding:'2px 2px 2px 2px',cursor: 'pointer'}}
      ></InfoCircleOutlined>
          </Tooltip>,
          dataIndex: 'IsCheckVerifier',
          editable: true,
          render: (_, record) => (
            <Checkbox disabled={formDisabled ? true : record.IsViewReports ? false : true} checked={record.IsCheckVerifier} onChange={(e) => handleCheckboxChange(e, record, 'IsCheckVerifier')} />
          ),
        },
      ] : []),
  ];

  const smaColumns = [
    {
      title: 'Portfolio',
      dataIndex: 'Description',
    },
    {
      title: <Tooltip
        placement="top"
        title="View account Information"
        overlayClassName="tooltip"
      >
        <span style={{ cursor: 'pointer' }}>View Reports</span>
      </Tooltip>,
      dataIndex: 'IsViewReports',
      editable: true,
      render: (_, record) => (
        <Checkbox disabled={formDisabled} checked={record.IsViewReports} onChange={(e) => handleCheckboxChange(e, record, 'IsViewReports')} />
      ),
    },
    {
      title: <Tooltip
        placement="top"
        title="Create a unique statement with this contact's name and address, in addition to anyone else marked as a statement recipient"
        overlayClassName="tooltip"
      >
        <span style={{ cursor: 'pointer' }}>Statement Recipient</span>
      </Tooltip>,
      dataIndex: 'IsStatementRecipient',
      editable: true,
      render: (_, record) => (
        <Checkbox disabled={formDisabled} checked={record.IsStatementRecipient} onChange={(e) => handleCheckboxChange(e, record, 'IsStatementRecipient')} />
      ),
    },
  ];

  const RemoveAllAccess = () => {
    if (programId !== 999) {
      var data = permissionsDataSource.map(items => ({
        ...items,
        IsViewReports: false,
        IsEnterTransactions: false,
        IsModifyBankingInstructions: false,
        IsCloseAccounts: false,
        IsManageUsers: false,
        IsStatementRecipient: 0,
        IsCheckVerifier: false,
        IsCheckSigner: false
      }));
      setPermissionsDataSource(data);
    } else {

      var data = permissionsDataSource.map(items => ({
        ...items,
        IsViewReports: false,
        IsStatementRecipient: 0,
      }));
      setPermissionsDataSource(data);
      setClientLevelPortfolioPermission(0);
    }
  };


  const AssignOrRemoveAllPortfolioPermission = (val) => {
    if (val) {
      var data = permissionsDataSource.map(items => ({
        ...items,
        IsViewReports: true
      }));
      setPermissionsDataSource(data);
    } else {
      var data = permissionsDataSource.map(items => ({
        ...items,
        IsViewReports: false
      }));
      setPermissionsDataSource(data);
    }

  }

  return (
    <>
      <Box display="flex" justifycontent="flex-end">
        <OverrideRemoveAccessButtonAnt
          hidden={permissionsDataSource?.length > 0 ? false : true}
          type="secondary"
          label="Remove All Access"
          onClick={() => RemoveAllAccess()}
          disabled={formDisabled}
        />
      </Box>
      {programId == 44 && (<><span>Contact the Client Services Group to assign or modify Check Signer Permission.</span><br></br></>)}
      <br></br>
      {programId === 999 && canShowClientLevelPortfolio && (
        <>
          <Checkbox disabled={formDisabled}
            checked={clientLevelPortfolioPermission}
            onChange={(e) => handleClientLevelPortfolioPermissionCheckboxChange(e)}
          />
          <span>  I grant user View Reports permission to all current portfolios under investor TIN and all future portfolios</span>
          <br></br>
          <br></br>
        </>
      )}

      <TableWrapper linkTextColor={primary_color}>
        {/* <Box display="flex" alignitems="center"> */}
        <Table
          className="table-primary"
          dataSource={permissionsDataSource}
          columns={programId !== 999 ? poolColumns : smaColumns}
          pagination={false}
          locale={{
            emptyText: <NoDataFound text="No Accounts/Portfolios found" padding="12px 0" />,
          }}
        />
        {/* </Box> */}
      </TableWrapper>
      <br></br>

      {/* <Box display="flex" justifycontent="flex-end"> */}
      <ButtonWrapper size={12} spacing>
        <ButtonAnt
          label="Cancel"
          type="secondary"
          htmlType="button"
          onClick={() => OnPermissionsCancelClick()}
        />
        <OverrideButtonAnt
          type="secondary"
          label="Confirm"
          onClick={() => OnPermissionsOkClick()}
          linkTextColor={primary_color}
          hidden={permissionsDataSource?.length > 0 ? false : true}
        />
      </ButtonWrapper>

      {/* </Box> */}
    </>
  );
};
const CreateContactPermissionsComponent = (props) => {
  const { permissionsDataSource, setPermissionsDataSource, OnPermissionsOkClick, formDisabled, OnPermissionsCancelClick, canShowClientLevelPortfolio, clientLevelPortfolioPermission, setClientLevelPortfolioPermission, setIsTouched } = props;
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  return (
    <Wrapper>
      <OverrideStyle linkTextColor={primary_color} />
      <Container>
        <CardAnt
          cardContent={
            <RenderCreateContactPermissionForm
              permissionsDataSource={permissionsDataSource}
              setPermissionsDataSource={setPermissionsDataSource}
              OnPermissionsOkClick={OnPermissionsOkClick}
              formDisabled={formDisabled}
              OnPermissionsCancelClick={OnPermissionsCancelClick}
              canShowClientLevelPortfolio={canShowClientLevelPortfolio}
              clientLevelPortfolioPermission={clientLevelPortfolioPermission}
              setClientLevelPortfolioPermission={setClientLevelPortfolioPermission}
              setIsTouched={setIsTouched}
            />}
          className="profile-card basic-card"
          title="Permissions"
        />
      </Container>
    </Wrapper>
  );
};

export default CreateContactPermissionsComponent;
