import React from 'react';
import styled from 'styled-components';
import { ButtonAnt, theme, Text } from '@pfmam/pfmam';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import { sizes } from '../../../styles/mediaQuery';

const ButtonWrapper = styled(Space)`
  width: 100%;
  justify-content: flex-end;
  margin-top: 48px;
  @media ${sizes.mediaQuery('xs', 'sm')} {
    flex-direction: column;
    .ant-btn,
    .ant-space-item {
      width: 100%;
    }
  }
`;

const RemoveAccountModal = ({
  selectedRecord,
  accType,
  accData,
  isRemoveAccountSuccess,
  setIsRemoveAccountSuccess,
  handleModalCancel,
  isGlobalSettingsPage,
}) => {
  const getRemoveModalContent = () => {
    if (isRemoveAccountSuccess) {
      if (isGlobalSettingsPage) {
        return accType === 'Team Member'
          ? `The team member, ${
              selectedRecord.firstName + ' ' + selectedRecord.lastName
            } was deleted`
          : `The ${accType} account, ${selectedRecord.bankName} was deleted`;
      } else {
        return accType === 'Team Member'
          ? `The team member, ${
              selectedRecord.firstName + ' ' + selectedRecord.lastName
            } was removed from ${accData.accName}`
          : `The ${accType} account, ${selectedRecord.bankName} was removed from ${accData.accName}`;
      }
    } else {
      switch (accType) {
        case 'ACH':
          return `You will no longer be able to use this ${accType} account for Purchases and Redemptions ${
            isGlobalSettingsPage ? '' : `for ${accData.accName}`
          }`;
        case 'Participant':
          return `You will no longer be able to use this ${accType} Account for transfers ${
            isGlobalSettingsPage ? '' : `with ${accData.accName}`
          }`;
        case 'Wire':
          return `You will no longer be able to use this ${accType} account for Redemptions ${
            isGlobalSettingsPage ? '' : `for ${accData.accName}`
          }`;
        case 'Team Member':
          return `This team member will no longer have access to view or move funds ${
            isGlobalSettingsPage ? '' : `with this account`
          }`;
        default:
          return '';
      }
    }
  };
  return (
    <>
      <Text
        label={getRemoveModalContent()}
        fontSize={theme.font_size.size_xl}
        color={theme.colors.black_tertiary}
        lineheight="24px"
      />
      <ButtonWrapper size={12}>
        {isRemoveAccountSuccess &&
        isGlobalSettingsPage ? null : isRemoveAccountSuccess ? (
          <Link
            to={{
              pathname: '/settings',
              accType: accType,
            }}
          >
            <ButtonAnt
              type="secondary"
              label={`Manage ${accType} Accounts`}
              onClick={() => handleModalCancel('remove')}
            />
          </Link>
        ) : (
          <ButtonAnt
            type="secondary"
            label="Cancel"
            onClick={() => handleModalCancel('remove')}
          />
        )}
        <ButtonAnt
          type={isRemoveAccountSuccess ? 'primary' : 'danger'}
          label={
            isRemoveAccountSuccess
              ? 'Done'
              : accType === 'Team Member'
              ? `${isGlobalSettingsPage ? 'Delete' : 'Remove'} Team Member`
              : `${
                  isGlobalSettingsPage ? 'Delete' : 'Remove'
                } ${accType} Account`
          }
          htmlType="submit"
          onClick={() => {
            isRemoveAccountSuccess
              ? handleModalCancel('remove')
              : setIsRemoveAccountSuccess(true);
          }}
        />
      </ButtonWrapper>
    </>
  );
};

export default RemoveAccountModal;
