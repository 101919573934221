import React from 'react';
import styled from 'styled-components';
import { Space } from 'antd';
import { theme, Text } from '@pfmam/pfmam';
import { Banner, Box, Anchor } from '../styles/globalStyle';

const BannerComponent = styled(Banner)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FooterWrapper = styled(Space)`
  width: 100%;
  margin-top: 32px;
  justify-content: center;
`;
const LogoutContainer = () => {
  return (
    <>
      <>
        <BannerComponent
          bgcolor={`${theme.colors.blue_primay}`}
          height="100vh"
          stripeleft
        >
          <Box minwidth="460px" maxwidth="460px" position="relative" zindex="9">
            <Box
              width="100%"
              radius="4px 4px 0 0"
              boxcolor="#333333"
              height="73px"
            />
            <Box
              padding="32px 40px 64px "
              boxcolor={`${theme.colors.white}`}
              radius="0 0 4px 4px"
            >
              <Text
                label="You are now logged out"
                fontSize={`${theme.font_size.size_3xl}`}
                color={`${theme.colors.black_secondary}`}
                lineheight="32px"
                margin="0 0 8px 0"
              />
              <Text
                label="To log back in"
                fontSize={`${theme.font_size.size_xl}`}
                color={`${theme.colors.grey_tertiary}`}
                lineheight="24px"
                display="inline-block"
              />{' '}
              <Anchor
                href="#"
                fontsize={`${theme.font_size.size_xl}`}
                color={`${theme.colors.blue_primay}`}
              >
                here
              </Anchor>
            </Box>
            <FooterWrapper size={24}>
              <Anchor
                href="#"
                fontsize={`${theme.font_size.size_l}`}
                color={`${theme.colors.white}`}
                lineheight="20px"
              >
                Contact us
              </Anchor>
              <Anchor
                href="#"
                fontsize={`${theme.font_size.size_l}`}
                color={`${theme.colors.white}`}
                lineheight="20px"
              >
                Terms & Conditions
              </Anchor>
              <Anchor
                href="#"
                fontsize={`${theme.font_size.size_l}`}
                color={`${theme.colors.white}`}
                lineheight="20px"
              >
                Privacy Policy
              </Anchor>
            </FooterWrapper>
          </Box>
        </BannerComponent>
      </>
    </>
  );
};

export default LogoutContainer;
