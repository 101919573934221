import React, { useEffect, useState } from 'react';
import AppLayout from '../common/layouts/appLayout';
import { theme, Tabs, Text } from '@pfmam/pfmam';
import { Banner, Container, Anchor, Box } from '../styles/globalStyle';
import styled from 'styled-components';
import { Layout } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import CD from './components/currentYields/cd';
import Liquid from './components/currentYields/liquid';
import Term from './components/currentYields/term';
import yieldDetails from '../data/CurrentYields.json';
import { GetCurrentYields } from '../actions/actions';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
const { Content } = Layout;

const ContentWrapper = styled(Content)`
  .ant-table {
    border-radius: 4px;
    .ant-table-tbody {
      & > tr {
        &:hover {
          &.ant-table-row:not(.ant-table-expanded-row) {
            background: linear-gradient(
                0deg,
                ${(props) => props.rgbaPrimary},
                ${(props) => props.rgbaPrimary}
              ),
              ${theme.colors.white};
          }
        }
        & > td {
          vertical-align: top;
          padding: 20px 32px;
          &.ant-table-column-sort {
            background: ${theme.colors.transparent};
          }
        }
        &:last-child {
          & > td {
            border-bottom-color: ${theme.colors.transparent};
          }
        }
      }
    }
    .ant-table-thead {
      & > tr {
        & > th {
          background: ${theme.colors.light_grey};
          font-family: 'Inter';
          padding: 0 32px;
          font-weight: normal;
          line-height: 20px;
          &:not(:last-child) {
            &:not(.ant-table-selection-column) {
              &:not(.ant-table-row-expand-icon-cell) {
                &:not([colspan]) {
                  &::before {
                    background-color: ${theme.colors.transparent};
                  }
                }
              }
            }
          }
        }
      }
    }
    &.ant-table.ant-table-middle {
      .ant-table-thead {
        .ant-table-column-sorters {
          padding: 0;
          .ant-table-column-sorter-inner .anticon {
            color: ${theme.colors.grey_stone};
          }
          .ant-table-column-sorter-up.active,
          .ant-table-column-sorter-down.active {
            color: ${theme.colors.black_sorted};
          }
        }
        .ant-table-column-sorter-inner {
          margin-top: 0;
        }
      }
    }
    .sort-space-none {
      .ant-table-column-sorters {
        justify-content: flex-start;
      }
      .ant-table-column-title {
        flex: 0 0 auto;
      }
      &.flex-end {
        .ant-table-column-sorters {
          justify-content: flex-end;
        }
      }
    }
  }
  .ant-table-title {
    padding: 0 !important;
  }
`;
const CurrentYieldsContainer = (props) => {
  const history = useHistory();
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const rgbaPrimary = useSelector((state) => state.siteInfo.rgbaPrimary);
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const showTermLine = useSelector(
    (state) => state.siteInfo.showTermInfoOnline
  );
  const showCDLine = useSelector((state) => state.siteInfo.allowCDTransactions);
  const showMoveFunds = useSelector(
    (state) => state.siteInfo.allowOnlineTransact
  );
  const programContactNumber = useSelector(
    (state) => state.siteInfo.phoneNumber
  );
  const [liquidYieldData, SetLiquidYieldData] = useState([]);
  const [activeTab, setActiveTab] = useState('liquid');
  const programId = clientDetails.payload[0].programid;
  useEffect(() => {
    setActiveTab(props.location.productProp);
  }, [props]);

  useEffect(() => {
    if (programId == 67 || !showMoveFunds){
      window.location.href ="/404";
    }
    GetCurrentYields(programId)
      .then((response) => {
        if (response.data.length > 0) {
          SetLiquidYieldData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleBackClick = () => {
    history.push('/dashboard');
  };

  return (
    <AppLayout>
      <ContentWrapper>
        <Banner bgcolor={primary_color} height="152px">
          <Box
            display="flex"
            alignitems="center"
            height="100%"
            minwidth="100%"
            width="100%"
            zindex="3"
            position="relative"
            padding="40px 0 36px 0"
          >
            <Container>
              {/* <Anchor
                href={`/dashboard`}
                fontsize={`${theme.font_size.size_l}`}
                lineheight="20px"
                color=" #F0F0F0"
                className="opac-text"
              >
                <ArrowLeftOutlined style={{ marginRight: '6px' }} />
                Back to Accounts Dashboard
              </Anchor> */}
              <Anchor
                onClick={handleBackClick}
                fontsize={`${theme.font_size.size_l}`}
                lineheight="20px"
                color=" #F0F0F0"
                className="opac-text"
              >
                <ArrowLeftOutlined style={{ marginRight: '6px' }} />
                Back to Accounts Dashboard
              </Anchor>
              <Box
                display="flex"
                justifycontent="space-between"
                alignitems="flex-start"
                margin="12px 0 0"
              >
                <Box flex="1">
                  <Text
                    color={`${theme.colors.white}`}
                    fontSize="36px"
                    lineheight="44px"
                    label={'Current Yields'}
                  />
                </Box>
              </Box>
            </Container>
          </Box>
        </Banner>
        <>
          <Tabs
            activeTabColor={primary_color}
            //linkTextColor={primary_color}
            rgbaHover={rgbaPrimary}
            defaultActiveKey={activeTab}
            activeKey={activeTab}
            onTabClick={(key) => setActiveTab(key)}
            // activeTabColor={theme.colors.pie_red}
            // activeColor={theme.colors.pie_yellow}
            tabsOptions={[
              {
                content: (
                  <Liquid
                    data={liquidYieldData}
                    date={
                      liquidYieldData.length > 0
                        ? liquidYieldData[0].RateDate
                        : null
                    }
                  />
                ),
                key: 'liquid',
                label: 'Liquid',
              },
              ...(showTermLine
                ? [
                    {
                      content: <Term phoneNumber={programContactNumber} />,
                      key: 'term',
                      label: 'Term',
                    },
                  ]
                : []),
              ...(showCDLine
                ? [
                    {
                      content: <CD phoneNumber={programContactNumber} />,
                      key: 'cd',
                      label: 'CD',
                    },
                  ]
                : []),
            ]}
            type="horizontal-tab"
          />
        </>
      </ContentWrapper>
    </AppLayout>
  );
};

export default CurrentYieldsContainer;
