import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Layout, Drawer } from 'antd';
import {
  ShopOutlined,
  QuestionCircleOutlined,
  // CommentOutlined,
  DashboardOutlined,
  FileTextOutlined,
  MailOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import { theme, Menu } from '@pfmam/pfmam';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { sizes } from '../../styles/mediaQuery';
const { Sider } = Layout;

const OverrideStyle = createGlobalStyle`
  .ant-drawer {
    z-index: 1053;
    @media ${sizes.mediaQuery('xl')} {
      display: none;
    }
    @media ${sizes.mediaQuery('xs', 'xl')} {
      display: block;
    }
  }
  .ant-drawer-body {
    padding: 0;
  }
  .ant-layout-sider,
  .ant-drawer {
    .ant-menu {
      background: transparent;
      border-right: 0;
    }
    .ant-menu-item {
      height: 48px;
      line-height: 48px;
      padding: 0 !important;
      width: 100%;
      margin: 0 !important;
      &.ant-menu-item-selected {
        background: none;
        &::after {
          border-right: 3px solid ${theme.colors.dark_blue};
        }
      }
      &:after {
        border-right: 3px solid ${theme.colors.dark_blue};
      }
    }
    .ant-menu-item-group-title {
      font-size: ${theme.font_size.size_m};
      padding: 8px 16px;
    }
    .menu-item {
      font-size: ${theme.font_size.size_l};
      color: ${theme.colors.grey_tertiary};
      display: block;
      height: 48px;
      line-height: 48px;
      padding: 0 16px;
      border-right: 3px solid ${theme.colors.transparent};
        .anticon {
          margin-right: 11px;
        }
        &:hover {
          background-color: ${(props) => props.rgbaPrimary};
          color: ${theme.colors.grey_tertiary};
          opacity: 1;
        }
        &.active {
          background-color:  ${(props) => props.rgbaPrimary};
          color: ${(props) => props.linkTextColor};
          border-right-color: ${(props) => props.linkTextColor};
          .anticon {
            color: ${(props) => props.linkTextColor};
          }
        }
      }
  }
  
`;
const Sidebar = styled(Sider)`
  position: relative;
  background-color: ${theme.colors.white};
  min-height: 100%;
  position: fixed;
  left: 0;
  z-index: 4;
  &.ant-layout-sider {
    box-shadow: 1px 0px 0px ${theme.shadows.sidebar};
  }
  @media ${sizes.mediaQuery('xs', 'xl')} {
    display: none;
  }
`;
const Logo = styled.img`
  display: block;
  min-width: 100%;
  width: 100%;
`;

const SidebarComponent = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const [showImage, setImage] = useState('');
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const rgbaPrimary = useSelector((state) => state.siteInfo.rgbaPrimary);
  const currentLogo = useSelector((state) => state.siteInfo.webBannerImage);
  useEffect(() => {
    if (currentLogo != '') {
      try {
        const SidebarLogo = require.context('../../assets/pfm-images', true);
        const setLogo = SidebarLogo(`./${currentLogo}`);
        setImage(setLogo.default);
      } catch {
        setImage(null);
      }
    }
  }, [currentLogo]);

  const args = {
    items: [
      {
        title: (
          <NavLink to="/dashboard" className="menu-item">
            <DashboardOutlined />
            {' Accounts Dashboard'}
          </NavLink>
        ),
        disabled: false,
        key: '1',
      },
      {
        title: (
          <NavLink to="/activity" className="menu-item">
            <HistoryOutlined />
            {' Activity History'}
          </NavLink>
        ),
        disabled: false,
        key: '2',
      },
      {
        title: (
          <NavLink to="/statements-documents" className="menu-item">
            <FileTextOutlined />
            {' Statements & Documents'}
          </NavLink>
        ),
        disabled: false,
        key: '3',
      },
      {
        title: (
          <NavLink to="/settings" className="menu-item">
            <ShopOutlined />
            {' Organizational Settings'}
          </NavLink>
        ),
        disabled: false,
        key: '4',
      },
      {
        title: 'CONTACT US',
        disabled: false,
        icon: false,
        key: '5',
        items: [
          {
            title: (
              <NavLink to="/contact" className="menu-item">
                <MailOutlined />
                {' Secure Contact'}
              </NavLink>
            ),
            disabled: false,
            key: '6',
          },
          // {
          //   title: (
          //     <NavLink to="/chat" className="menu-item">
          //       <CommentOutlined />
          //       {' Chat'}
          //     </NavLink>
          //   ),
          //   disabled: false,
          //   key: '7',
          // },
          {
            title: (
              <NavLink to="/faq" className="menu-item">
                <QuestionCircleOutlined />
                {' FAQ'}
              </NavLink>
            ),
            disabled: false,
            key: '8',
          },
        ],
      },
    ],
    type: 'itemGroup',
    mode: 'inline',
    width: '100%',
  };
  const onClose = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <OverrideStyle linkTextColor={primary_color} rgbaPrimary={rgbaPrimary} />
      <Sidebar width="244px" isSidebarOpen={isSidebarOpen}>
        <Logo src={showImage} />
        <Menu {...args} />
      </Sidebar>
      <Drawer
        width="244px"
        visible={isSidebarOpen}
        placement="left"
        maskStyle={{ background: `${theme.rgba.modal}` }}
        closeIcon={false}
        onClose={onClose}
        destroyOnClose={true}
      >
        <Logo src={showImage} />
        <Menu {...args} />
      </Drawer>
    </>
  );
};

export default SidebarComponent;
