export const breakpoints = {
  xs: 0,
  sm: 767,
  md: 1024,
  lg: 1200,
  xl: 1280,
  xxl: 1500,
};

export const sizes = {
  mediaQuery: (lower, upper) => {
    let query = 'only screen';

    if (lower) {
      query += ' and (min-width: ' + breakpoints[lower] + 'px)';
    }

    if (upper) {
      query += ' and (max-width: ' + breakpoints[upper] + 'px)';
    }
    return query;
  },
};
