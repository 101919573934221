import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Table, Progress, message } from 'antd';
import { theme, Text, TableComponent } from '@pfmam/pfmam';
import { Box } from '../../../styles/globalStyle';
import NoDataFound from '../../../common/components/noDataFound';
import { DownloadOutlined } from '@ant-design/icons';
// import { DownloadConsolidatedStatement } from '../../../actions/actions';
import axios from 'axios';
import config from '../../../config';

import moment from 'moment';
const LinkButton = styled(Button)`
  padding: 0;
  display: inline-block;
  font-size: ${theme.font_size.size_lg};
  line-height: 20px;
  color: ${theme.colors.blue_primay};
  height: 20px;
  border: 0;
  background: none;
  box-shadow: none;
  :hover {
    opacity: 0.6;
    color: ${theme.colors.blue_primay};
  }
  :focus,
  :active {
    opacity: 1;
    color: ${theme.colors.blue_primay};
    background: none;
  }
  .anticon + span {
    margin-left: 8px;
  }
  ${(props) =>
    props.cancel &&
    css`
      color: ${theme.colors.danger_red};
      :hover {
        opacity: 0.6;
        color: ${theme.colors.danger_red};
      }
      :focus,
      :active {
        opacity: 1;
        color: ${theme.colors.danger_red};
        background: none;
      }
    `}
`;
const ExpandButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  min-width: 32px;
  cursor: pointer;
  transition: 0.3s;
  & > span {
    height: 19px;
    min-width: 19px;
    width: 19px;
    margin: 0 auto;
    border: 2px solid ${theme.colors.grey};
    border-radius: 2px;
    padding: 5px;
    position: relative;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: '';
      position: absolute;
      top: 3px;
      background: ${theme.colors.black_secondary};
      height: 10px;
      width: 2px;
      transition: transform 0.3s ease-out;
      transform: rotate(0deg);
    }
    &::after {
      content: '';
      position: absolute;
      top: 7px;
      right: 2.3px;
      background: ${theme.colors.black_secondary};
      height: 2px;
      width: 10px;
      transition: transform 0.3s ease-out;
      transform: rotate(-180deg);
    }
  }
  &:focus {
    & > span {
      box-shadow: ${theme.shadows.input_focus};
      border-color: ${theme.colors.blue_primay};
    }
  }
  &.expanded {
    & > span {
      &::after {
        transform: rotate(0deg);
      }
      &::before {
        transform: rotate(90deg);
      }
    }
  }
`;


function DownloadConsolidatedStatement(
  slipsheetid,
  date,
  setDownloadProgress
) {
  const baseApiUrl = config.BASE_API_URL;
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Statements/Consolidated/Download',
    data: {
      SlipsheetID: slipsheetid,
      Date: date,
    },
    onDownloadProgress: (progressEvent) => {
      let percentCompleted = parseInt(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      setDownloadProgress(percentCompleted);
    },
  });
}

const success = (percentCompleted) => {
  message.loading('Download in progress..').then(() => {
    if (percentCompleted == 100) {
      message.success('Download finished');
    }
  });
};

const ConsolidatedStatementsTable = ({ data, recipientType }) => {
  const [downloadProgress, setDownloadProgress] = useState(0);
  const handleAction = (type, data) => {
    success(downloadProgress);
    if (type === 'parent') {
      data.recipients.map(function (recipient) {
        DownloadConsolidatedStatement(
          recipient.slipsheetid,
          recipient.statementDate,
          setDownloadProgress
        )
          .then((response) => {
            if (response.data != null) {
              const arrayBuffer = base64ToArrayBuffer(response.data);
              createAndDownloadBlobFile(arrayBuffer, 'Statement');
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } else {
      DownloadConsolidatedStatement(data.slipsheetid, data.statementDate,setDownloadProgress)
        .then((response) => {
          if (response.data != null) {
            const arrayBuffer = base64ToArrayBuffer(response.data);
            createAndDownloadBlobFile(arrayBuffer, 'Statement');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  function createAndDownloadBlobFile(body, filename, extension = 'pdf') {
    const blob = new Blob([body]);
    const fileName = `${filename}.${extension}`;
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'left',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      className: 'sort-space-none',
      width: 180,
      render: (text) => (
        <Text
          label={moment(text).format('MMM DD, YYYY')}
          fontSize={`${theme.font_size.size_xl}`}
          lineheight="24px"
        />
      ),
    },
    {
      title: 'Statement',
      dataIndex: '',
      key: 'recipients',
      align: 'left',
      sorter: false,
      className: 'sort-space-none',
      width: 500,
      render: (_, record) => (
        <Box>
          <Text
            label="Consolidated Statement (s)"
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />

          <Text
            label={moment(record.date).format('MMM YYYY')}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
            color={`${theme.colors.grey_tertiary}`}
          />
        </Box>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <LinkButton
          type="link"
          onClick={() => {
            handleAction('parent', record);
          }}
        >
          <DownloadOutlined />
          {recipientType === 'single' ? 'Download' : 'Download All'}
        </LinkButton>
      ),
    },
  ];

  const childColumns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 80,
    },
    {
      title: '',
      dataIndex: '',
      key: 'date',
      width: 180,
    },
    {
      title: '',
      dataIndex: 'recipientName',
      key: 'recipientName',
      width: 500,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <LinkButton
          type="link"
          onClick={(e) => {
            e.stopPropagation();
            handleAction('child', record);
          }}
        >
          <DownloadOutlined />
          Download
        </LinkButton>
      ),
    },
  ];

  const expandable = {
    expandedRowRender: (record) => (
      <Table
        showHeader={false}
        columns={childColumns}
        dataSource={record.recipients}
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            handleAction('child', record);
          },
        })}
      />
    ),
    expandIcon: ({ expanded, onExpand, record }) => (
      <ExpandButton
        className={expanded ? 'expanded' : ''}
        onClick={(e) => {
          e.stopPropagation();
          onExpand(record, e);
        }}
      >
        <span></span>
      </ExpandButton>
    ),
  };

  return (
    <TableComponent
      className="table-primary"
      title={
        <Box
          display="flex"
          justifycontent="space-between"
          alignitems="center"
          padding="24px 48px 24px 32px"
          border={`1px solid ${theme.colors.grey_fossil}`}
          borderwidth="0 0 1px 0"
        >
          <Text
            label="Consolidated Statements"
            fontSize={`${theme.font_size.size_3xl}`}
            lineheight="32px"
            color={`${theme.colors.black_tertiary}`}
          />
        </Box>
      }
      expandable={recipientType === 'multiple' ? expandable : null}
      columns={columns}
      dataSource={data}
      locale={{
        emptyText: <NoDataFound text="No Consolidated Statements" />,
      }}
      showSorterTooltip={false}
      pagination={
        data.length > 10
          ? {
              position: ['bottomRight'],
              showQuickJumper: true,
              size: 'default',
            }
          : false
      }
    />
  );
};

export default ConsolidatedStatementsTable;
