import React, { useEffect, useState } from 'react';
import { Charts, Text, theme } from '@pfmam/pfmam';
import { thousandsSeperator } from '../../../utils/common';
import _ from 'lodash';
import styled from 'styled-components';
import { Box } from '../../../styles/globalStyle';
import { barDetailsFormatter } from '../../../utils/common';

const BarTitle = styled(Box)`
  display: flex;
  align-items: center;
  border: none;
  padding: 20px;
  padding-left: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const Wrapper = styled(Box)`
  border: none;
  border-top: 1px solid ${theme.colors.grey_fossil};
  padding-top: 30px;
`;

const TitleText = styled(Text)`
  padding-left: 32px;
`;

const BarCharts = ({ details, date, selected }) => {
  console.log(selected);

  let title = `Composition by ${selected} Type`;
  const [data, setData] = useState();

  useEffect(() => {
    setData(barDetailsFormatter(details).reverse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  const barOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
      margin: 20,
    },
    xAxis: {
      categories: date,
      style: {
        color: `${theme.colors.grey_tertiary}`,
        fontWeight: 400,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: 'bold',
        },
      },
    },
    tooltip: {
      width: '129px',
      height: '68px',
      shadow: false,
      borderWidth: 0,
      backgroundColor: 'rgba(255,255,255,0)',
      borderColor: null,
      useHTML: true,
      style: {
        color: `${theme.colors.black_secondary}`,
        fontSize: '12px',
      },
      headerFormat: '<b>{point.x}</b><br/>',
      formatter: function () {
        var point = this.point;
        var percent = this.point.percentage.toFixed(1);
        var text =
          '<div style = "background-color:white; border: 1px solid #DFDFDF; border-radius: 4px; box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08);">' +
          '<div style = "padding:8px">' +
          '<span style="color:#595959; margin-left:4px; font-size:12px; font-weight:400;line-height:2em;">' +
          this.series.name +
          ':' +
          '</span>' +
          '<span style="color:#595959; margin-left:4px; font-size:12px; font-weight:600;margin-right:8px">' +
          percent +
          ' %' +
          '</span>' +
          '<br>' +
          '<span style="color:black; margin-left:4px; font-size:14px; line-height:2em;font-weight:400;margin-right:8px; margin-top: 5px;">' +
          thousandsSeperator(point.y) +
          '</span>' +
          '</div>' +
          '</div>';
        return text;
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
        pointWidth: 34,
      },
      series: {
        point: {
          events: {
            mouseOver: function () {
              const point = this;

              point.series.points.forEach((p) => {
                if (p !== point) {
                  p.setState('inactive');
                }
              });
            },
            mouseOut: function () {
              const point = this;

              point.series.points.forEach((p) => {
                if (p !== point) {
                  p.setState('normal');
                }
              });
            },
          },
        },
      },
    },
    series: data,
  };
  return (
    <>
      <BarTitle boxcolor={theme.colors.white}>
        <TitleText label={title} fontSize="24px" lineheight="32px" />
      </BarTitle>
      <Wrapper boxcolor={theme.colors.white}>
        <Charts options={barOptions} />
      </Wrapper>
    </>
  );
};

export default BarCharts;
