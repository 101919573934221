/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Menu, theme } from '@pfmam/pfmam';
import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { useSelector } from 'react-redux';

const ActionButton = styled.button`
  height: 32px;
  width: 32px;
  background: ${theme.colors.transparent};
  border: 0;
  padding: 0;
  color: ${theme.colors.black_secondary};
  box-shadow: none;
  cursor: pointer;
  &:focus,
  &:hover {
    background: ${theme.colors.transparent};
    color: ${theme.colors.black_secondary};
  }
  &.ant-dropdown-open {
    background: ${theme.colors.light_grey};
  }
`;

const AccountActions = ({
  type,
  record,
  handleMenuClick,
  isGlobalSettingsPage,
  canEditContact = false
}) => {
  const webuserAccessType = useSelector((state) => state.userSlice.webUserType);
  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu
          type={'basic'}
          multiple={false}
          mode={'inline'}
          onClick={(e) => {
            handleMenuClick(e, record, type);
          }}
          items={[
            {
              title:
                type === 'Team Member'
                  ? 'View Team Member Details'
                  : 'View Account Details',
              disabled: false,
              key: 'details',
            },
            ...(webuserAccessType == 0 && canEditContact && type === 'Team Member'
              ? [
                {
                  title: 'Edit Contact',
                  disabled: false,
                  key: 'Edit',
                },
              ]
              : []),
            // {
            //   title:
            //     type === 'Team Member' ? 'Edit Team Member' : 'Edit Account',
            //   disabled: false,
            //   key: 'edit',
            // },
            // {
            //   title: isGlobalSettingsPage
            //     ? `Delete ${type === 'Team Member' ? 'Team Member' : 'Account'}`
            //     : `Remove ${
            //         type === 'Team Member' ? 'Team Member' : 'Account'
            //       }`,
            //   disabled: false,
            //   key: 'remove',
            //   className: 'text-danger',
            // },
          ]}
          width={'auto'}
        />
      }
      placement="bottomRight"
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ zIndex: 999 }}
    >
      <ActionButton onClick={(e) => e.stopPropagation()}>
        <EllipsisOutlined />
      </ActionButton>
    </Dropdown>
  );
};

export default AccountActions;
