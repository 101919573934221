/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { theme, Text, TableComponent } from '@pfmam/pfmam';
import { Button, Table } from 'antd';
import { Box } from '../../styles/globalStyle';
import { CloseCircleOutlined, DownOutlined } from '@ant-design/icons';
import { amountFormat, dollarToFloat } from '../../utils/numberFormat';
import moment from 'moment';
import NoDataFound from '../../common/components/noDataFound';
import { useSelector } from 'react-redux';

// const OverrideTableComponent = styled(TableComponent)`
//   .ant-pagination .ant-pagination-options-quick-jumper input:hover {
//     border-color: ${(props) => props.rgbaHover};
//   }
//   .ant-pagination .ant-pagination-options-quick-jumper input {
//     color: ${(props) => props.rgbaHover};
//   }
//   .ant-pagination .ant-pagination-item.ant-pagination-item-active a,
//   .ant-pagination .ant-pagination-item:hover a {
//     color: ${(props) => props.rgbaHover};
//   }
//   .ant-pagination .ant-pagination-item.ant-pagination-item-active,
//   .ant-pagination .ant-pagination-item:hover {
//     border-color: ${(props) => props.rgbaHover};
//   }
//   .ant-select-single.ant-select-open .ant-select-selection-item {
//     color: ${(props) => props.rgbaHover};
//   }
// `;

const LinkButton = styled(Button)`
  padding: 0;
  display: inline-block;
  font-size: ${theme.font_size.size_xl};
  line-height: 24px;
  color: ${(props) => props.linkTextColor};
  height: 24px;
  border: 0;
  background: none;
  box-shadow: none;
  :hover {
    opacity: 0.6;
    color: ${(props) => props.linkTextColor};
  }
  :focus,
  :active {
    opacity: 1;
    color: ${theme.colors.blue_primay};
    background: none;
  }
  .anticon + span {
    margin-left: 5px;
  }
  ${(props) =>
    props.cancel &&
    css`
      color: ${theme.colors.danger_red};
      :hover {
        opacity: 0.6;
        color: ${theme.colors.danger_red};
      }
      :focus,
      :active {
        opacity: 1;
        color: ${theme.colors.danger_red};
        background: none;
      }
    `}
  .anticon-down {
    transform: rotate(0deg);
    transition: transform 0.3s linear;
    font-size: ${theme.font_size.size_m};
    vertical-align: middle;
  }
  &.expanded {
    .anticon-down {
      transform: rotate(180deg);
      transition: transform 0.3s linear;
      margin-top: -5px;
    }
  }
`;

const CancelledText = () => {
  return (
    <Text
      label="Cancelled"
      color={`${theme.colors.grey_tertiary}`}
      fontSize={`${theme.font_size.size_xl}`}
      lineheight="20px"
    />
  );
};

const ActivityHistoryTable = ({
  isGlobalActivityHistoryPage,
  data,
  handleAction,
  showInactiveAcc,
  type,
  linkTextColor,
  isCheckRedemtionExpanded,
  setIsCheckRedemtionExpanded,
}) => {
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const getColumns = (columnType = 'normal') => {
    const accountColumn = {
      title: 'Account',
      dataIndex: columnType === 'normal' ? 'AccountName' : '',
      key: 'AccountName',
      align: 'left',
      width: '180px',
    };

    const columns = [
      {
        title: 'Settlement Date',
        dataIndex: 'SettlementDate',
        key: 'SettlementDate',
        defaultSortOrder: "descend",
        align: 'left',
        sorter: (a, b) =>
          moment(a.SettlementDate).unix() - moment(b.SettlementDate).unix(),
        className: 'sort-space-none',
        width: 208,
        render: (text) =>
          columnType === 'normal' ? (
            <Text
              label={moment(text).format('MMM DD, YYYY')}
              lineheight="20px"
            />
          ) : null,
      },
      {
        title: 'Description',
        dataIndex: 'TransDesc',
        key: 'TransDesc',
        align: 'left',
        sorter: false,
        className: 'sort-space-none',
        width: 400,
        render: (text, record) => (
          <>
            {text}{' '}
            {record.TransDesc === 'Check Redemption Summary' ? (
              <Text
                label={record.CheckCount}
                color={`${theme.colors.grey_tertiary}`}
                lineheight="20px"
              />
            ) : (
              <Text
                label={
                  record.CUSIP == null
                    ? record.InvestmentTypeDesc
                    : record.CUSIP
                }
                color={`${theme.colors.grey_tertiary}`}
                lineheight="20px"
              />
            )}
          </>
        ),
      },
      {
        title: 'Total Amount',
        dataIndex: 'TotalAmount',
        key: 'TotalAmount',
        align: 'right',
        sorter: (a, b) => a.TotalAmount - b.TotalAmount,
        className: 'sort-space-none flex-end',
        width: 160,
        render: (text) => <>{amountFormat(text)}</>,
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        width: 80,
        render: (_, record) => (
          <>
            {type === 'completed' &&
              (record.TransDesc === 'Check Redemption Summary' ? (
                <LinkButton
                  type="link"
                  icon={<DownOutlined />}
                  className={
                    isCheckRedemtionExpanded &&
                    isCheckRedemtionExpanded.includes(record.id)
                      ? 'expanded'
                      : ''
                  }
                >
                  {isCheckRedemtionExpanded &&
                  isCheckRedemtionExpanded.includes(record.id)
                    ? 'Less'
                    : 'More'}
                </LinkButton>
              ) : (
                <LinkButton
                  linkTextColor={primary_color}
                  type="link"
                  onClick={() => {
                    handleAction('details', record);
                  }}
                >
                  Details
                </LinkButton>
              ))}
          </>
        ),
      },
    ];
    if (isGlobalActivityHistoryPage) {
      columns.splice(2, 0, accountColumn);
    }

    return columns;
  };

  const getRowClassName = (record) => {
    if (type === 'completed' && record.status === 'Inactive') {
      return 'inactive-accounts';
    }
    return '';
  };

  const expandable = {
    expandRowByClick: true,
    expandIconColumnIndex: -1,
    expandIconAsCell: false,
    expandedRowRender: (record) => {
      return (
        <Table
          showHeader={false}
          columns={getColumns('expanded')}
          dataSource={record.CheckDetails}
          pagination={false}
          onRow={(record) => ({
            onClick: () => {
              handleAction('details', record);
            },
          })}
        />
      );
    },
    rowExpandable: (record) =>
      record.CheckDetails && record.CheckDetails.length,
    expandIcon: false,
    defaultExpandedRowKeys: isCheckRedemtionExpanded
      ? isCheckRedemtionExpanded
      : [],
    onExpand: (expanded, record) =>
      expanded
        ? setIsCheckRedemtionExpanded([record.id])
        : setIsCheckRedemtionExpanded(null),
  };

  const toggleCheckRedemptionExpanded = (record) => {
    if (isCheckRedemtionExpanded && isCheckRedemtionExpanded.length > 0) {
      if (isCheckRedemtionExpanded.includes(record.id)) {
        const filteredData = isCheckRedemtionExpanded.filter(
          (id) => record.id !== id
        );
        setIsCheckRedemtionExpanded(filteredData);
      } else {
        setIsCheckRedemtionExpanded([...isCheckRedemtionExpanded, record.id]);
      }
    } else {
      setIsCheckRedemtionExpanded([record.id]);
    }
  };

  return (
    <>
      <TableComponent
        rgbaHover={linkTextColor}
        className="table-primary"
        expandable={type === 'completed' ? expandable : null}
        title={
          <Box
            display="flex"
            justifycontent="space-between"
            alignitems="center"
            padding="24px 16px 24px 32px"
            border={`1px solid ${theme.colors.grey_fossil}`}
            borderwidth="0 0 1px 0"
          >
            <Text
              label={type === 'pending' ? 'Pending' : 'History'}
              fontSize={`${theme.font_size.size_3xl}`}
              lineheight="32px"
              color={`${theme.colors.black_tertiary}`}
            />
          </Box>
        }
        columns={getColumns()}
        dataSource={data}
        locale={{
          emptyText: (
            <NoDataFound
              text={
                type === 'pending'
                  ? 'No pending activity'
                  : 'No activity history'
              }
              padding="12px 0"
            />
          ),
        }}
        showSorterTooltip={false}
        pagination={
          data.length > 10
            ? {
                position: ['bottomRight'],
                showQuickJumper: true,
                size: 'default',
              }
            : false
        }
        onRow={(record) => ({
          onClick: () => {
            record.CheckDetails && record.CheckDetails.length
              ? toggleCheckRedemptionExpanded(record)
              : handleAction('details', record);
          },
        })}
        rowKey={(record) => record.id}
        rowClassName={(record) => getRowClassName(record)}
      />
    </>
  );
};

export default ActivityHistoryTable;
