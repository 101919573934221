/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Modal } from 'antd';
import { Row, Col, Form } from 'antd';
import {
  ButtonAnt,
  theme,Text,
  InputComponent,
  SelectComponent,
} from '@pfmam/pfmam';
import { signoutRedirect } from '../../../src/services/userService';
import {  CheckCircleOutlined } from '@ant-design/icons';
import { getContactProfile, updateProfileContact } from '../../actions/actions';
import { useSelector,useDispatch } from 'react-redux';
import phoneFormatter from 'phone-formatter';
import { MaskedInput } from 'antd-mask-input';
import { Box } from '../../styles/globalStyle';
import { validateEmail, isNumber } from '../../utils/common';


const OverrideStyle = createGlobalStyle`
  .form-select-dropdown {
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      .ant-select-item {
        &.ant-select-item-group {
          color: ${theme.colors.grey_tertiary};
          min-height: 16px;
          line-height: 16px;
          padding: 6px 12px;
          &:hover {
            background: none;
          }
        }
      }
      .ant-select-item-option-selected,
      .ant-select-item-option-active {
        &:not(.ant-select-item-option-disabled) {
          background: ${theme.colors.white};
          font-weight: normal;
        }
      }
      .ant-select-item {
        min-height: 40px;
        line-height: 24px;
        padding: 8px 12px;
        font-size: ${theme.font_size.size_xl};
        color: ${theme.colors.black_secondary};
        font-family: 'Inter';
        &:hover {
        background: ${theme.colors.light_grey};
        font-family: 'Inter';
      }
    }
  }
`;

const validateFaxNumber = (_, value) => {
  if(value) {
    var unmaskedValue = value.replace(/\D/g, '');
    if (unmaskedValue.length == 0 || unmaskedValue.length == 10) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid 10 digit number'));
  } else {
    return Promise.resolve();
  }
}


const validatePhoneNumber = (_, value) => {
  if(value) {
    var unmaskedValue = value.replace(/\D/g, '');
    if (unmaskedValue.length == 0 || unmaskedValue.length == 10) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid 10 digit number'));
  } else {
    return Promise.resolve();
  }
}

const validateMobileNumber = (_, value) => {
  if(value) {
    var unmaskedValue = value.replace(/\D/g, '');
    if (unmaskedValue.length == 0 || unmaskedValue.length == 10) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid 10 digit number'));
  } else {
    return Promise.resolve();
  }
}

const ButtonWrapper = styled.div`
  .anticon-check-circle {
    margin-left: 30px;
    margin-top: 2px;
  }
`;

const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
  }
`;

function modifyContactProfile(profile) {
  var contactProfile = [];
  contactProfile.push({
    ext: profile.phoneAddionalInfo,
    emailAddress: profile.emailAddress,
    faxNumber:
      profile.faxNumber != ''
        ? phoneFormatter.format(profile.faxNumber, '(NNN) NNN-NNNN')
        : null,
    phoneNumber:
      profile.phoneNumber != ''
        ? phoneFormatter.format(profile.phoneNumber, '(NNN) NNN-NNNN')
        : null,
    mobileNumber:
      profile.mobileNumber != ''
        ? phoneFormatter.format(profile.mobileNumber, '(NNN) NNN-NNNN')
        : null,
    salutation: profile.salutation,
    secretAnswer: profile.secretAnswer,
    secretQuestion: profile.secretQuestion,
    secretQuestionType:profile.secretQuestion==='What is the name of your first pet?'||
    profile.secretQuestion==='In what city was your mother born?'||
    profile.secretQuestion==='What is the middle name of your oldest child?'||
    profile.secretQuestion==='What is your mothers maiden name?'||
    profile.secretQuestion==='What is the name of the street you grew up on?'||
    profile.secretQuestion==="What was the color of your first car?"||
    profile.secretQuestion==='What was your childhood nick name?'?profile.secretQuestion:profile.secretQuestion===''||profile.secretQuestion===null?'':'Other',
    title: profile.title,
    firstName: profile.firstName,
    lastName: profile.lastName,
    loginName: profile.loginName,
    isPending:profile.isPending
  });
  return contactProfile;
}
const RenderProfileForm = (props) => {
  const [form] = Form.useForm();
  const [submitted, setSubmitted] = useState(false);
  const contactId = useSelector((state) => state.userSlice.user.profile.sub);
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPendingContact, setIsPendingContact] = useState(false);
  const user = useSelector((state) => state.userSlice.user);
  const dispatch = useDispatch();
  const [subjectVal, setSubjectVal] = useState(null);
 
  const handleBlur = (e) => {
    const value = e.target.value;
    const name = e.target.getAttribute('data-name');
    const trimmedValue = value.trim();
    form.setFieldsValue({ [name]: trimmedValue });
  }

  const validateEmailAddress = (_, value) => {
    if(isPendingContact){
        return Promise.resolve();
      }
    else if(!isPendingContact && value && validateEmail(value)) {
      return Promise.resolve();
    } 
    else if(!isPendingContact && !value){
      return Promise.reject(new Error('Email Address Required.'));
    }
    else {
      return Promise.reject(new Error('Please enter a valid email address'));
    }
  }

  useEffect(() => {
    getContactProfile()
    .then((response) => {
      if (response.data != null) {
        const profile = modifyContactProfile(response.data);
        form.setFieldsValue(profile[0]);
        if(profile[0].isPending=="true"){
          setIsPendingContact(true);
        }
        if(profile[0].secretQuestionType=='Other'){
          setSubjectVal('Other')
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);
  const onSubmit = (values) => {
    updateProfileContact(
      contactId,
      values.ext,
      values.emailAddress,
      values.faxNumber,
      values.mobileNumber,
      values.phoneNumber,
      values.salutation,
      values.secretAnswer,
      values.secretQuestion=(values.secretQuestionType=='Other')? values.secretQuestion:values.secretQuestionType,
      values.title,
      values.firstName,
      values.lastName,
      values.loginName,
    )
      .then((response) => {
        if (response.data != null) {
          setSubmitted(true);
          if(user.profile.contactInformationInComplete=="True"){
            setIsModalVisible(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
    <OverrideStyle/>
    <Form
      form={form}
      name="basic"
      layout="vertical"
      onFinish={onSubmit}
      requiredMark={false}
      className="forms"
      onFieldsChange={() => setSubmitted(false)}
    >
      <Row gutter={80}>
        <Col span={12}>
          <Form.Item label="Login ID" name="loginName">
            <InputComponent type="default" disabled={true} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Title" name="title">
          <InputComponent maxLength={25} disabled={isPendingContact}  type="default" placeholder="Add Title" data-name="title" onBlur={handleBlur} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={80}>
        <Col span={12}>
          <Form.Item label="Salutation" name="salutation">
            <SelectComponent
              option={[
                {
                  label: 'Dr.',
                  value: 'Dr',
                },
                {
                  label: 'Miss.',
                  value: 'Miss',
                },
                {
                  label: 'Mr.',
                  value: 'Mr',
                },
                {
                  label: 'Mrs.',
                  value: 'Mrs',
                },
                {
                  label: 'Ms.',
                  value: 'Ms',
                },
              ]}
              placeholder="Select Salutation"
              disabled={isPendingContact} 
              dropdownClassName="form-select-dropdown"
              bordered={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
                        label="Email Address"
                        name="emailAddress"
                        rules={[
                          {
                            validator:validateEmailAddress
                          }
                        ]}
                      >
                        <InputComponent
                          type="default"
                          placeholder="Add Email Address" 
                          disabled={isPendingContact} 
                          data-name="emailAddress"  onBlur={handleBlur}
                        />
                      </Form.Item>
        </Col>
      </Row>
      <Row gutter={80}>
        <Col span={12}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="First Name" name="firstName">
                <InputComponent
                  type="default"
                  placeholder="Add First Name"
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Last Name" name="lastName">
                <InputComponent
                  type="default"
                  placeholder="Add Last Name"
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={24}>
            <Col span={15}>
            <Form.Item label="Phone Number" name="phoneNumber"
                        rules={[
                          {
                            validator: validatePhoneNumber,
                          },
                        ]}
                        >
                        <MaskedInput
                         disabled={isPendingContact} 
                          mask="(111) 111-1111"
                          placeholder="(000) 000-0000"
                        />
                        </Form.Item>
            </Col>
            <Col span={9}>
            <Form.Item
                        label="Ext"
                        name="ext"
                      >
                        <InputComponent
                          type="default"
                          placeholder="Add Ext"
                          disabled={isPendingContact} 
                          maxLength="25"
                          data-name="ext" 
                          onBlur={handleBlur}
                        />
                      </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={80}>
      <Col span={12}>
          <Form.Item
            label="Secret Question Type"
            name="secretQuestionType"
            rules={[
              {
                required: true,
                message: 'Secret Question Type'
              },
            ]}
          >
            <SelectComponent
             option={[
              { 
                label: 'What is the name of your first pet?',
                value: 'What is the name of your first pet?'
              
              },
              { 
            
              label: 'In what city was your mother born?' ,
               value: 'In what city was your mother born?'
              },
              { 
                label: 'What is the middle name of your oldest child?',
                value: 'What is the middle name of your oldest child?'
                
              },
              { 
                label: 'What is your mothers maiden name?' ,
                value: 'What is your mothers maiden name?'
               
              },
              { 
                label: 'What is the name of the street you grew up on?',
                value: 'What is the name of the street you grew up on?'
                
              },
              { 
                label: 'What was your childhood nick name?' ,
                value: 'What was your childhood nick name?'
              },
              {
                label: 'What was the color of your first car?',
                value: 'What was the color of your first car?'
              },
              {
              label:'Other',
              value:'Other'
              }
            ]}
              dropdownClassName="form-select-dropdown"
              bordered={true}
              onChange={(value) => {
                setSubjectVal(value)
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item label="Mobile Number" name="mobileNumber" 
                  rules={[
                    {
                      validator: validateMobileNumber,
                    },
                  ]}
                  >
                    <MaskedInput disabled={isPendingContact}  mask="(111) 111-1111" placeholder="(000) 000-0000"  />
                  </Form.Item>
        </Col>
        {subjectVal && subjectVal === 'Other' ? (    <Col span={12}>
          <Form.Item
            label="Secret Question"
            name="secretQuestion"
            rules={[
              {
                required: true,
                message: 'Secret Question Required',
              },
            ]}
          >
            <InputComponent
              type="default"
              placeholder="Enter Secret Question"
            />
          </Form.Item>
        </Col>):null}
        <Col span={12}>
          <Form.Item
            label="Secret Answer"
            name="secretAnswer"
            rules={[
              {
                required: true,
                message: 'Secret Answer Required',
              },
            ]}
          >
            <InputComponent type="default" placeholder="Add Secret Answer" />
          </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
                        label="Fax Number"
                        name="faxNumber"
                        rules={[
                          {
                            validator: validateFaxNumber,
                          },
                        ]}
                      >
                        <InputComponent
                          type="default"
                          disabled={isPendingContact} 
                          placeholder="Add Fax Number"
                          maxLength="10"
                        />
                  </Form.Item>
        </Col>
      </Row>
      <Row>
      {isPendingContact ?<Text
                label={`Note:Your changes are in Pending State and waiting for Approval.Once the CSG Group approves the disabled fields will be enabled.`}
                fontSize={`${theme.font_size.size_2xl}`}
                lineheight="30px"
                color={`${theme.colors.danger_red}`}
            />:
        null
        }
      </Row>
      <Box display="flex" justifycontent="flex-end">
        {submitted === false ? (
          <OverrideButtonAnt
            label="Save Changes"
            type="primary"
            htmlType="submit"
            linkTextColor={primary_color}
          />
        ) : (
          <ButtonWrapper>
            <ButtonAnt label="" type="success" icon={<CheckCircleOutlined />} />
          </ButtonWrapper>
        )}
      </Box>
    </Form>
      <Modal title={<strong>{"Profile Information has been saved"}</strong>}
      visible={isModalVisible && user.profile.contactInformationInComplete} 
      onOk={() => { 
        setIsModalVisible(false); 
        signoutRedirect(user.id_token, dispatch);
      }} 
      width={500}
      bodyStyle={{
          padding: '32px 32px 48px 32px',
      }}
      maskStyle={{
          backgroundColor: `${theme.rgba.modal}`,
          zIndex: '1051',
      }}
      closable={false}
      cancelButtonProps={{ style: { display: 'none' } }}
      maskClosable={false}
      centered={true}> 
      <p>Click OK to log back in and view Account Dashboard. If this is your first time accessing Connect, please allow up to 15 minutes for system to update. </p> 

    </Modal>
    </>
 
  );
};

export default RenderProfileForm;
