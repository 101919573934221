import React, { useState,useEffect } from 'react';
import { Form } from 'antd';
import TransferForm from './forms/transferForm';
import ReviewForm from './forms/reviewForm';
import {GetTransactableAccounts} from '../../actions/actions';
import { useSelector } from 'react-redux';
import moment from 'moment';
const TransferFlow = ({ setSelectedPage, selectedPage, transferData }) => {
  const [selected, setSelected] = useState('single');
  const [accSelected, setAccSelected] = useState(true);
  const [showInvestmentTypeModal, setShowInvestmentTypeModal] = useState(false);
  const [form] = Form.useForm();
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [formType, setFormType] = useState('transfer');
  const [formData, setFormData] = useState({});
  const [accName, setAccName] = useState('');
  const [amount, setAmount] = useState(0);
  const [showAccountsModal, setShowAccountsModal] = useState(false);
  const [transferDatafromAPI, setTransferDatafromAPI] = useState([]);
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const [participantData, setParticipantData] = useState([]);
  const ProgramID = clientDetails.payload[0].programid;
  const SsnOrTin = clientDetails.payload[0].ssnortin;
  const [defaultDate, setDefaultDate] = useState(moment());
  const [loading, setLoading] = useState(null);
  const getTransactionPendingType = () => {
    //5-transfer
    //7-exchnage
    //25-program to program
    if (selectedPage === 'InvestmentTypeTransfer') {
      return 7;
    } else if (selectedPage === 'SingleAccountTransferForm'||selectedPage === 'MultiAccountTransferForm') {
      return 5;
    } else if (selectedPage === 'ProgramTransfer') {
      return 25;
    }
  };

  useEffect(() => {
    const pendingTransactiontypeID=getTransactionPendingType(selectedPage);
    GetTransactableAccounts(SsnOrTin, ProgramID, pendingTransactiontypeID) //PendingTransactionTypeID Indicates type of Transfer.
        .then((response) => {
          if (response.data !== null && response.data.length > 0) {
            setTransferDatafromAPI(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
  }, []);

  return (
    <>
      {formType === 'review' ? (
        <ReviewForm
          form={form}
          formData={formData}
          accName={accName}
          selected={selected}
          amount={amount}
          setSelectedPage={setSelectedPage}
          selectedPage={selectedPage}
          setFormType={setFormType}
          setDefaultDate={setDefaultDate}
          defaultDate={defaultDate}
          loading={loading}
          setLoading={setLoading}
          participantData={participantData}
          setParticipantData={setParticipantData}
        />
      ) : (
        <TransferForm
          form={form}
          accSelected={accSelected}
          setAccSelected={setAccSelected}
          selected={selected}
          setSelected={setSelected}
          showInvestmentTypeModal={showInvestmentTypeModal}
          setShowInvestmentTypeModal={setShowInvestmentTypeModal}
          setShowReviewModal={setShowReviewModal}
          showReviewModal={showReviewModal}
          setFormType={setFormType}
          formData={formData}
          setFormData={setFormData}
          setAccName={setAccName}
          accName={accName}
          setAmount={setAmount}
          setSelectedPage={setSelectedPage}
          selectedPage={selectedPage}
          showAccountsModal={showAccountsModal}
          setShowAccountsModal={setShowAccountsModal}
          transferData={transferDatafromAPI}
          setDefaultDate={setDefaultDate}
          defaultDate={defaultDate}
          loading={loading}
          setLoading={setLoading}
          participantData={participantData}
          setParticipantData={setParticipantData}
        />
      )}
    </>
  );
};

export default TransferFlow;
