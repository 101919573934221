import React, { useEffect, useState } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { theme } from '@pfmam/pfmam';
import { GlobalStyle } from './styles/globalStyle';
import AuthRoutes from './AuthRoutes';
import AuthProvider from './utils/authProvider';
import userManager from './services/userService';
import { AppOverrideStyle } from './styles/overrideStyle';
import { initMessageListener } from 'redux-state-sync';
import { useSelector } from 'react-redux';
import { setAuthHeader } from '../src/utils/axiosHeaders';
import LoadSpinner from './common/components/LoadingSpinner/LoadSpinner';


function App({ store }) {
  const [loading, setLoading] = useState(null);

useEffect(() => {
  const root = document.getElementById('root');
  if (root !== null || root !== '') {
    setLoading(false);
  } else {
    setLoading(true);
  }
 // setTimeout(() => setLoading(false), 6000)
}, [loading])

  initMessageListener(store);
  const user = useSelector((state) => state.userSlice.user);
  if (user != null) {
    setAuthHeader(user.access_token);
  }


  return ( 
  <>
    {loading === false ? (
    <AuthProvider userManager={userManager} store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <AppOverrideStyle />
        <BrowserRouter>
          <Switch>
            <AuthRoutes />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>

    ) : (
      <LoadSpinner></LoadSpinner>
    )}
    </>
  );
};

export default App;
