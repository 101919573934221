/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { Form, Space, Checkbox } from 'antd';
import {
  theme,
  ButtonAnt,
  TableComponent,
} from '@pfmam/pfmam';
import { Box } from '../../../styles/globalStyle';

const TableOverrideStyle = createGlobalStyle`
  .ant-table.ant-table-middle .ant-table-title {
    padding: 0;
  }
  .forms {
    .table-primary {
      .ant-table-tbody {
        & > {
          & tr {
            & > {
              td {
                &.ant-table-row-expand-icon-cell {
                  padding: 19px 6px 19px 32px;
                  width: 80px;
                }
              }
            }
          } 
        }
        & > tr {
         & > td {
           padding: 18px 16px 18px 0px;
          }
        } 
        .ant-table-expanded-row {
          .ant-table-cell {
             padding: 0;
            .checkbox-group-wrapper {
              &:last-child {
                border-bottom:0;
              }
            }
          }
        }
        tr.ant-table-expanded-row > td, 
        tr.ant-table-expanded-row:hover > td {
          background: ${theme.colors.grey_faint};
        }
      } 
      .ant-checkbox-wrapper {
        .ant-checkbox {
          top: 4px;
        }
        margin-top: 2px;
      }
    } 
  }
`;

const ButtonWrapper = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      padding: 48px 32px 0 0;
    `}
`;
const ExpandButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  min-width: 32px;
  cursor: pointer;
  transition: 0.3s;
  & > span {
    height: 19px;
    min-width: 19px;
    width: 19px;
    margin: 0 auto;
    border: 2px solid ${theme.colors.grey};
    border-radius: 2px;
    padding: 5px;
    position: relative;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: '';
      position: absolute;
      top: 3px;
      background: ${theme.colors.black_secondary};
      height: 10px;
      width: 2px;
      transition: transform 0.3s ease-out;
      transform: rotate(0deg);
    }
    &::after {
      content: '';
      position: absolute;
      top: 7px;
      right: 2.3px;
      background: ${theme.colors.black_secondary};
      height: 2px;
      width: 10px;
      transition: transform 0.3s ease-out;
      transform: rotate(-180deg);
    }
  }
  &:focus {
    & > span {
      box-shadow: ${theme.shadows.input_focus};
      border-color: ${theme.colors.blue_primay};
    }
  }
  &.expanded {
    & > span {
      &::after {
        transform: rotate(0deg);
      }
      &::before {
        transform: rotate(90deg);
      }
    }
  }
`;

const RenderSelectPermissionsComponent = ({
  setSelectAccounts,
  setIsReviewTeamMmber,
  form,
  onFinish,
  permissions,
  setPermissions,
  accDetails,
}) => {
  const [accountsArray, setAccountsArray] = useState({});
  useEffect(() => {
    accDetails &&
      accDetails.length &&
      accDetails.map((acc) => {
        permissions.map((permission) => {
          if (permission.accounts.length && permission.accounts.includes(acc)) {
            if (Object.keys(accountsArray).includes(acc)) {
              accountsArray[acc].push(permission.permission);
            } else {
              accountsArray[acc] = [];
              accountsArray[acc].push(permission.permission);
            }
          }
        });
      });
    setAccountsArray(accountsArray);
  }, [permissions, accDetails, accountsArray]);
  const onSubmit = (values) => {
    onFinish(values);
    setIsReviewTeamMmber(true);
  };

  const onCheckAllChange = (e, record) => {
    let index =
      permissions &&
      permissions.findIndex(
        (permission) => permission.permission === record.permission
      );
    if (e.target.checked) {
      permissions[index].accounts = [...accDetails];
      setPermissions([...permissions]);
    } else {
      permissions[index].accounts = [];
      setPermissions([...permissions]);
    }
  };

  const onChange = (list, record) => {
    let index =
      permissions &&
      permissions.findIndex(
        (permission) => permission.permission === record.permission
      );
    permissions[index].accounts = [...list];
    setPermissions([...permissions]);
  };

  const columns = [
    {
      title: '',
      dataIndex: 'permission',
      key: 'permission',
      render: (text, record) => {
        let index =
          permissions &&
          permissions.findIndex(
            (permission) => permission.permission === record.permission
          );
        let indeterminate =
          permissions[index].accounts.length === 0 ||
          permissions[index].accounts.length >= accDetails.length
            ? false
            : true;
        let checkAll = permissions[index].accounts.length === accDetails.length;
        return (
          <>
            <Checkbox
              indeterminate={indeterminate}
              onChange={(e) => onCheckAllChange(e, record)}
              checked={checkAll}
            >
              {text}
            </Checkbox>
          </>
        );
      },
    },
  ];

  const expandable = {
    expandedRowRender: (record) => (
      <Checkbox.Group
        value={record.accounts}
        onChange={(list) => onChange(list, record)}
      >
        {accDetails.length > 0 &&
          accDetails.map((account) => {
            return (
              <Box
                padding="14px 14px 14px 80px"
                border={`1px solid ${theme.colors.grey}`}
                borderwidth="0 0 1px 0"
                className="checkbox-group-wrapper"
              >
                <Checkbox value={account}>{account}</Checkbox>
              </Box>
            );
          })}
      </Checkbox.Group>
    ),
    expandIcon: ({ expanded, onExpand, record }) => (
      <ExpandButton
        className={expanded ? 'expanded' : ''}
        onClick={(e) => {
          e.preventDefault();
          onExpand(record, e);
        }}
      >
        <span></span>
      </ExpandButton>
    ),
  };

  return (
    <>
      <TableOverrideStyle />
      <Form
        form={form}
        name="basic"
        layout="vertical"
        onFinish={onSubmit}
        requiredMark={false}
        className="forms block-level-checkbox"
      >
        <Form.Item
          label={null}
          name="permissions"
          rules={[
            {
              validator: () => {
                if (Object.keys(accountsArray).length > 0) {
                  return Promise.resolve();
                }
                return Promise.reject('Permissions Required');
              },
            },
          ]}
        >
          <TableComponent
            columns={columns}
            expandable={expandable}
            dataSource={permissions}
            showHeader={false}
            className="table-primary"
            title={null}
          />
        </Form.Item>

        <ButtonWrapper size={12} spacing>
          <ButtonAnt
            label="Back"
            type="secondary"
            htmlType="button"
            onClick={() => setSelectAccounts(false)}
          />
          <ButtonAnt label="Review" type="primary" htmlType="submit" />
        </ButtonWrapper>
      </Form>
    </>
  );
};

export default RenderSelectPermissionsComponent;
