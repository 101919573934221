/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Button, Space } from 'antd';
import { theme, Text, TableComponent } from '@pfmam/pfmam';
import { Box, Container } from '../../styles/globalStyle';
import NoDataFound from '../../common/components/noDataFound';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import ExpandInputComponent from '../../common/components/expandInput';
import MultiSelect from '../../common/components/multiSelect';
import RenderDatePicker from '../../common/components/datePicker';
import CheckboxImg from '../../assets/images/checkbox-blue.svg';
import CheckboxCheckedImg from '../../assets/images/checkbox-blue-checked.svg';
import { useSelector } from 'react-redux';
import { DownloadDocument, GetDocuments } from '../../actions/actions';
import {
  base64ToArrayBuffer,
  createAndDownloadBlobFile,
} from '../../utils/download';
const Wrapper = styled.div`
  padding-top: 22px;
`;
const LinkButton = styled(Button)`
  padding: 0;
  display: inline-block;
  font-size: ${theme.font_size.size_lg};
  line-height: 20px;
  color: ${theme.colors.blue_primay};
  height: 20px;
  border: 0;
  background: none;
  box-shadow: none;
  :hover {
    opacity: 0.6;
    color: ${theme.colors.blue_primay};
  }
  :focus,
  :active {
    opacity: 1;
    color: ${theme.colors.blue_primay};
    background: none;
  }
  .anticon + span {
    margin-left: 8px;
  }
  ${(props) =>
    props.cancel &&
    css`
      color: ${theme.colors.danger_red};
      :hover {
        opacity: 0.6;
        color: ${theme.colors.danger_red};
      }
      :focus,
      :active {
        opacity: 1;
        color: ${theme.colors.danger_red};
        background: none;
      }
    `}
`;

const DocumentsComponent = () => {
  const [searchData, setSearchData] = useState(null);
  const [inputClassName, setInputClassName] = useState('hide');

  const [documentTypeSelectedValues, setDocumentTypeSelectedValues] = useState(
    []
  );
  const [documentTypeFilterClass, setDocumentTypeFilterClass] =
    useState('hide');
  //DATE PICKER
  const [dates, setDates] = useState([moment().subtract(120, 'days'), moment()]);
  const [dateValues, setDateValues] = useState([
    moment().subtract(120, 'days'),
    moment(),
  ]);
  const [dateOption, setDateOption] = useState();
  const [dateLabel, setDateLabel] = useState( moment(dateValues[0]).format('MMM DD, YYYY') +
  '-' +
  moment(dateValues[1]).format('MMM DD, YYYY'));
  const [dateOpen, setDateOpen] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [finalDocumentData, setFinalDocumentData] = useState([]);
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const programId = clientDetails.payload[0].programid;
  const ssnorTIN = clientDetails.payload[0].ssnortin;

  const columns = [
    {
      title: 'Date',
      dataIndex: 'CreatedDate',
      key: 'CreatedDate',
      align: 'left',
      sorter: (a, b) =>
        moment(a.CreatedDate).unix() - moment(b.CreatedDate).unix(),
      className: 'sort-space-none',
      width: '180px',
      render: (text) => (
        <Text label={moment(text).format('MMM DD, YYYY')} lineheight="20px" />
      ),
    },
    {
      title: 'Document',
      dataIndex: 'Document',
      key: 'Document',
      align: 'left',
      sorter: (a, b) => a.Document.localeCompare(b.Document),
      className: 'sort-space-none',
      width: 500,
    },
    {
      title: 'Document Category',
      dataIndex: 'DocumentCategory',
      key: 'DocumentCategory',
      align: 'left',
      sorter: (a, b) => a.DocumentCategory.localeCompare(b.DocumentCategory),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <LinkButton
          type="link"
          onClick={(e) => {
            e.stopPropagation();
            handleAction(record);
          }}
        >
          <DownloadOutlined />
          Download
        </LinkButton>
      ),
    },
  ];

  const handleDocTypeChange = (name) => {
    if (documentTypeSelectedValues.includes(name)) {
      const values = documentTypeSelectedValues.filter(
        (selectedValue) => selectedValue !== name
      );
      setDocumentTypeSelectedValues([...values]);
    } else {
      setDocumentTypeSelectedValues([...documentTypeSelectedValues, name]);
    }
  };

  const getDocTypeFilterOptions = () => {
    const filterOptions = [];
    const DistinctDocType = [
      ...new Set(documentData.map((s) => s.DocumentCategory)),
    ].sort();

    DistinctDocType.map((option, index) => {
      filterOptions.push(
        <li key={index + 1} onClick={() => handleDocTypeChange(option)}>
          <img
            src={
              documentTypeSelectedValues.includes(option)
                ? CheckboxCheckedImg
                : CheckboxImg
            }
            alt="Checkbox"
          />{' '}
          {option}
        </li>
      );
    });
    return filterOptions;
  };

  const onInputSubmit = (searchstring) => {
    setSearchData(searchstring);
  };

  const handleDateChange = () => {
    setDates(dateValues);
  };

  useEffect(() => {
    GetDocuments(
      programId,
      ssnorTIN,
      dates[0].format('YYYY-MM-DD'),
      dates[1].format('YYYY-MM-DD')
    )
      .then((response) => {
        if (response.data != null) {
          setDocumentData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dates]);

  const Applyfilters = () => {
    let FilteredDoc = [];
    if (
      documentTypeSelectedValues.length > 0 ||
      searchData !== null ||
      dates !== null
    ) {
      FilteredDoc = documentData.filter(function (elem) {
        let Doctypefilter = true;
        let searchDataFilter = true;
        let dateFilter = true;

        if (documentTypeSelectedValues.length > 0) {
          Doctypefilter = documentTypeSelectedValues.includes(
            elem.DocumentCategory
          );
        }

        if (searchData !== null) {
          searchDataFilter =
            elem.Document.toUpperCase().indexOf(searchData.toUpperCase()) > -1;
        }
        if (dates !== null) {
          dateFilter =
            moment(elem.CreatedDate).format('YYYY-MM-DD') >=
              dates[0].format('YYYY-MM-DD') &&
            moment(elem.CreatedDate).format('YYYY-MM-DD') <=
              dates[1].format('YYYY-MM-DD');
        }
        return Doctypefilter && searchDataFilter && dateFilter;
      });
    } else {
      FilteredDoc = documentData;
    }

    setFinalDocumentData(FilteredDoc);
  };

  useEffect(() => {
    Applyfilters();
  }, [documentTypeSelectedValues, documentData, searchData, dates]);

  const handleAction = (data) => {
    DownloadDocument(data.Type, data.ID)
      .then((response) => {
        if (response.data != null) {
          const arrayBuffer = base64ToArrayBuffer(response.data.Buffer);
          let FileName = response.data.FileName.split('.');
          createAndDownloadBlobFile(
            arrayBuffer,
            FileName[0],
            response.data.ContentType
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  return (
    <Container>
      <Wrapper>
        <Box margin="0 0 24px">
          <Space size={8}>
            <ExpandInputComponent
              //inputData={searchData}
              //setInputData={setSearchData}
              onInputSubmit={onInputSubmit}
              inputClassName={inputClassName}
              setInputClassName={setInputClassName}
            />
            <RenderDatePicker
              // dates={dates}
              // setDates={setDates}
              dateValues={dateValues}
              setDateValues={setDateValues}
              dateOption={dateOption}
              setDateOption={setDateOption}
              dateLabel={dateLabel}
              setDateLabel={setDateLabel}
              dateOpen={dateOpen}
              setDateOpen={setDateOpen}
              handleDateChange={handleDateChange}
            />
            <MultiSelect
              filterClass={documentTypeFilterClass}
              setFilterClass={setDocumentTypeFilterClass}
              selectedValues={documentTypeSelectedValues}
              setSelectedValues={setDocumentTypeSelectedValues}
              options={getDocTypeFilterOptions()}
              buttonLabel={'Document Category'}
              height={'auto'}
              selectedBgColor={primary_color}
              selectedColor={`${theme.colors.white}`}
            />
          </Space>
        </Box>
        <TableComponent
          className="table-primary"
          title={
            <Box
              display="flex"
              justifycontent="space-between"
              alignitems="center"
              padding="24px 48px 24px 32px"
              border={`1px solid ${theme.colors.grey_fossil}`}
              borderwidth="0 0 1px 0"
            >
              <Text
                label="Documents"
                fontSize={`${theme.font_size.size_3xl}`}
                lineheight="32px"
                color={`${theme.colors.black_tertiary}`}
              />
            </Box>
          }
          columns={columns}
          dataSource={finalDocumentData}
          locale={{
            emptyText: <NoDataFound text="No Documents" />,
          }}
          showSorterTooltip={false}
          pagination={
            finalDocumentData.length > 10
              ? {
                  position: ['bottomRight'],
                  showQuickJumper: true,
                  size: 'default',
                }
              : false
          }
          onRow={(record) => ({
            onClick: () => {
              handleAction(record);
            },
          })}
        />
      </Wrapper>
    </Container>
  );
};

export default DocumentsComponent;
