import React from 'react';
import styled from 'styled-components';
import {
  theme,
  ButtonAnt,
  // Text,
  // Indicator,
} from '@pfmam/pfmam';
// import { Box } from '../../styles/globalStyle';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';

const EditBtn = styled(ButtonAnt)`
  border-radius: 2px;
  height: 52px;
  padding: 12px 24px;
  font-size: ${theme.font_size.size_xl};
  display: block;
  width: 100%;
  margin-top: 48px;
  &&& {
    background: ${(props) => props.linkTextColor};
    border-color: ${(props) => props.linkTextColor};
  }
`;

const AlertDetailsModalContent = ({ handleAlertModalCancel, content }) => {
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  return (
    <>
      {ReactHtmlParser(content.html)}
      {content.dismissable && (
        <EditBtn
          label="Dismiss"
          type="secondary"
          className="primary"
          linkTextColor={primary_color}
          onClick={() => handleAlertModalCancel(content.id)}
        />
      )}
    </>
  );
};

export default AlertDetailsModalContent;
