import { createGlobalStyle } from 'styled-components';
import { theme } from '@pfmam/pfmam';
import { sizes } from './mediaQuery';
import CaretImg from '../assets/images/vector.svg';
export const AppOverrideStyle = createGlobalStyle`
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .highcharts-credits {
    display: none;
  }
  .tooltip {
    position: relative;
    padding: 0;
    &.ant-tooltip { 
      background-color: ${theme.colors.black_secondary};
      border-radius: 2px;
      font-size: ${theme.font_size.size_l};
      color: ${theme.colors.white};
      box-shadow: ${theme.shadows.tooltip};
      filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08));
    }
    .ant-tooltip-inner {
      background: none;
      padding: 6px 8px;
    }
    &.ant-tooltip-placement-top {
      .ant-tooltip-arrow {
        bottom: -12px;
        .ant-tooltip-arrow-content {
          width: 10px;
          height: 10px;
          background-color: ${theme.colors.black_secondary};
          box-shadow: none;
        }
      }
    }
  }
  .primary-select {
    box-shadow: ${theme.shadows.dropdown};
    .ant-select-item {
      font-weight: normal;
      font-size: ${theme.font_size.size_l};
      color: ${theme.colors.black_secondary};
      line-height: 20px;
      padding: 8px 12px;
      &:hover {
        background: ${theme.colors.light_grey} !important;
      }
      &.ant-select-item-option-active {
        :not(.ant-select-item-option-disabled) {
          background: ${theme.colors.transparent};
        }
      }
    }
    .ant-select-item-option-selected {
      &:not(.ant-select-item-option-disabled) {
        background: ${theme.colors.light_blue} !important;
      }
    }
  }
  .ant-pagination {
    &.ant-table-pagination {
      margin: 0;
      background: ${theme.colors.white};
      padding: 16px 30px;
      border-radius: 0 0 4px 4px;
      border-top: 1px solid ${theme.colors.grey};
    }
    .ant-pagination-item {
      font-family: 'Inter';
      a {
        color: ${theme.colors.black_secondary};
      }
      &.ant-pagination-item-active,
      &:hover {
        border-color: ${theme.colors.blue_secondary};
        a {
          color: ${theme.colors.blue_secondary};
          font-family: 'Inter Semibold';
        }
      }
    }
    .ant-pagination-disabled .ant-pagination-item-link, 
    .ant-pagination-disabled:hover .ant-pagination-item-link {
      background: ${theme.colors.light_grey};
      border-color: ${theme.colors.grey};
      color: ${theme.colors.grey_stone};
    }
    .ant-pagination-item-link {
      &:hover {
        &:not([disabled]) {
          border-color: ${theme.colors.blue_secondary};
          color: ${theme.colors.blue_secondary};
          background: ${theme.colors.white};
        }
      }
      .anticon {
        line-height: 32px;
        vertical-align: middle;
      }
    }
    .ant-pagination-prev, 
    .ant-pagination-next, 
    .ant-pagination-jump-prev, 
    .ant-pagination-jump-next {
      font-family: 'Inter';
      color: ${theme.colors.black_secondary};
      .ant-pagination-item-link-icon {
        color: ${theme.colors.blue_secondary};
      }
    }
    .ant-select {
      min-width: 112px;
      .anticon {
        display: none;
      }
      &:not(.ant-select-disabled) {
        &:hover {
          .ant-select-selector {
            border-color: ${theme.colors.blue_secondary};
          }
        }
        .ant-form-item-control-input-content .primary.ant-select-single:not(.ant-select-customize-input).ant-select-focused .ant-select-selector
        &.ant-select-focused {
          &:not(.ant-select-disabled) {
            &.ant-select:not(.ant-select-customize-input) {
              .ant-select-selector {
                border-color: ${theme.colors.blue_secondary};
                box-shadow: none;
              }
            }
          }
        }
      }
      .ant-select-selector {
          &:after {
          content: "";
          display: block;
          height: 12px;
          width: 12px;
          background: url(${CaretImg}) no-repeat center;
          background-size: 12px;
          position: absolute;
          right: 12px;
          visibility: visible;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .ant-pagination-options-quick-jumper {
      input {
        color: ${theme.colors.black_secondary};
        margin-right: 0;
        &:hover {
          border-color: ${theme.colors.grey};
        }
        &:focus {
          box-shadow: ${theme.colors.input_focus};
          border-color: ${theme.colors.blue_secondary};
        }
      }
    } 
    .ant-select-item {
      font-weight: normal;
      font-size: ${theme.font_size.size_l};
      color: ${theme.colors.black_secondary};
      line-height: 20px;
      padding: 8px 12px;
      &:hover {
        background: ${theme.rgba.hover} !important;
      }
      &.ant-select-item-option-active {
        &:not(.ant-select-item-option-disabled) {
          background: none;
        }
      }
    }
    .ant-select-item-option-selected {
      &:not(.ant-select-item-option-disabled) {
        background: ${theme.colors.light_blue} !important;
      }
    }
  }
  /* this will remove once storybook update*/
  .password {
    &.ant-input-affix-wrapper-focused {
        box-shadow: 0px 0px 0px 2px rgba(23, 54, 93, 0.2);
        border-color: ${theme.colors.blue_primay} !important;
      }
  }
  .ant-modal-wrap {
    z-index: 1052;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-modal-title {
    font-family: 'Inter';
    font-weight: normal;
  }
  .ant-btn-primary {
    &.primary {
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .ant-table-empty {
    .ant-table-thead {
      display: none;
    }
    &.ant-table {
      .ant-table-tbody {
        & > tr {
          & > td:not(.ant-table-expanded-row .ant-table-cell) {
            border-color: ${theme.colors.transparent};
          }
        }
      }
    }  
  } 
  .btn-for-no-data {
    &.ant-btn {
      height: 48px;
      width: 240px;
      padding: 10px 24px;
    }
  }
  .add-button {
    &.ant-btn {
      height: 30px;
      padding: 2px 16px;
    }
  }
  .horizontal-tab {
    & > {
      .ant-tabs-nav {
      background: ${theme.colors.white};
      margin-bottom: 0;
        &:before {
          border-bottom: 0;
        }
        .ant-tabs-tab {
          font-size: ${theme.font_size.size_xl};
        }
        & > {
          .ant-tabs-nav-wrap {
            @media ${sizes.mediaQuery('xs', 'xxl')} {
              width: 100%;
              padding-left: 32px;
              padding-right: 32px;
            }
            @media ${sizes.mediaQuery('xxl')} {
              max-width: 1200px;
              width: 100%;
              margin-left: auto;
              margin-right: auto;
              padding-left: 0;
              padding-right: 0;
            }
            @media ${sizes.mediaQuery('xs', 'xl')} {
              padding-left: 40px;
              padding-right: 40px;
            }
          }
        }
      }
    }
  }
  .opac-text {
    opacity: 0.7;
    :hover {
      opacity: 0.5;
    }
  }
  .resizable-text {
    @media ${sizes.mediaQuery('xs', 'xxl')} {
      min-width: 100%;
      width: 100%;
    }
  }
  .auto-width-text {
    @media ${sizes.mediaQuery('xs', 'sm')} {
      width: auto;
      min-width: auto;
    }
  }
  .overflow-x-sm {
    @media ${sizes.mediaQuery('xs', 'sm')} {
      overflow-x: auto;
    }
  }
  .suffix-left {
    .ant-picker-input {
      flex-direction: row-reverse;
    }
    .ant-picker-suffix {
      margin: 0 10px 0 0;
      color: ${theme.colors.black_secondary};
    }
  }
  .forms {
    .ant-form-item {
      margin-bottom: 32px;
    }
    .ant-form-item-label {
      padding-bottom: 8px;
      line-height: 20px;
      min-height: 20px;
      & > label {
        font-size: ${theme.font_size.size_l};
        color: ${theme.colors.black_secondary};
        line-height: 20px;
      }
    }
    .ant-input,
    .ant-picker {
      height: 52px;
      width: 100%;
      padding: 14px 20px;
      border: 1px solid ${theme.colors.grey};
      color: ${theme.colors.black_secondary};
      font-size: ${theme.font_size.size_xl};
      &::placeholder,
      .ant-picker-input > input::placeholder  {
        color: ${theme.colors.grey_tertiary};
      }
      &:focus {
        box-shadow: ${theme.shadows.input_focus};
        border-color: ${theme.colors.blue_primay};
      }
    }
    .ant-form-item-has-error .ant-input-affix-wrapper,
    .ant-form-item-has-error .ant-input,
    .ant-form-item-has-error .ant-picker,
    .ant-form-item-has-error .ant-input-number, 
    .ant-form-item-has-error .ant-picker {
      border-color: ${theme.colors.pie_red};
    }
    .ant-form-item-has-error .ant-input:not(.ant-input-disabled), 
    .ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled), 
    .ant-form-item-has-error .ant-input:not(.ant-input-disabled):hover, 
    .ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
    .ant-form-item-has-error .ant-input-number {
      &.ant-input-affix-wrapper-focused,
      &.ant-input-number-focused {
        box-shadow: 0px 0px 0px 2px rgba(186, 90, 78, 0.2);
        border-color: ${theme.colors.pie_red};
      }
    }
    .ant-form-item-has-error .ant-input,
    .ant-form-item-has-error .ant-picker,
    .ant-form-item-has-error .ant-input-affix-wrapper,
    .ant-form-item-has-error .ant-input-number, 
    .ant-form-item-has-error .ant-picker {
      &:hover {
        border-color: ${theme.colors.pie_red};
      }
      &:focus {
        box-shadow: 0px 0px 0px 2px rgba(186, 90, 78, 0.2);
        border-color: ${theme.colors.pie_red};
      }
    }
    .ant-picker-focused,
    &:focus {
      box-shadow: ${theme.shadows.input_focus};
      border-color: ${theme.colors.blue_primay};
    }
    .ant-input-affix-wrapper {
      height: 52px;
      padding: 0 20px;
      &:hover {
        border-color: ${theme.colors.grey};
      }
      &:focus,
      &.ant-input-affix-wrapper-focused {
        box-shadow: ${theme.shadows.input_focus};
        border-color: ${theme.colors.blue_primay};
      }
      & > .ant-input {
        height: 100%;
        padding-right: 10px;
        box-shadow: none !important;
      }
    }
    .ant-form-item-has-error {
      .ant-picker-focused {
        box-shadow: 0px 0px 0px 2px rgba(186, 90, 78, 0.2);
        border-color: ${theme.colors.pie_red};
      }
    } 
    .ant-form-item-explain-error {
      color:${theme.colors.pie_red};
    }
    .ant-checkbox-wrapper {
      font-size: ${theme.font_size.size_xl};
      color: ${theme.colors.black_secondary};
      line-height: 24px;
      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: ${theme.colors.blue_primay};
          border-color: ${theme.colors.blue_primay};
        }
      }
      .ant-checkbox-indeterminate {
        .ant-checkbox-inner {
          &::after {
            background-color: ${theme.colors.blue_primay};
          }
        }
      } 
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
    .ant-checkbox:hover .ant-checkbox-inner, 
    .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-checked::after,
    .ant-radio-checked .ant-radio-inner,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-checked::after,
    .ant-radio-wrapper:hover .ant-radio, 
    .ant-radio:hover .ant-radio-inner, 
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: ${theme.colors.blue_primay};
    }
    .ant-radio-wrapper {
      font-size: ${theme.font_size.size_xl};
      color: ${theme.colors.black_secondary};
    }
    .ant-radio {
      top: 2px;
    }
    .ant-radio-inner::after {
      background-color: ${theme.colors.blue_primay};
    }
    .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: none;
    }
    .ant-input[disabled] {
      color: ${theme.colors.grey_tertiary};
    }
  }  

  .text-danger {
    color: ${theme.colors.pie_red} !important;
  }
  .block-level-checkbox {
    .ant-checkbox-group {
      min-width: 100%;
      max-width: 100%;
    }
    .ant-checkbox-wrapper {
      display: flex;
      align-items: flex-start;
       & + {
        .ant-checkbox-wrapper {
          margin-left: 0;
        }
      }
    }
    &.marginable-checkbox {
      .ant-checkbox-wrapper {
        padding: 10px 20px;
      }
    }
  }
  .checkbox-boss {
    background-color: ${theme.colors.grey_faint};
    border-radius: 4px;
    padding: 14px 20px;
  }
  .error-msg-as-left {
    .ant-form-item-has-error {
      .ant-form-item-explain-error {
        padding-left: 20px;
      }
    }
  }
  .filter-divider {
    top: 0;
    height: 62px;
    border-color: ${theme.colors.grey};
    margin: 0 12px 0 42px;
  }
  .ant-btn {
    &.ant-btn-secondary {
        &:hover,
        &:focus {
        border-color: ${theme.colors.grey};
      }
    }
  }
  .ant-modal {
    margin-top: 48px;
    margin-bottom: 28px;
  }
  .expanded {
    .ant-btn-secondary {
      width: 52px;
      padding: 14px 8px;
    }
  }
  .primary.ant-btn-primary[disabled], 
  .primary.ant-btn-primary[disabled]:hover, 
  .primary.ant-btn-primary[disabled]:focus, 
  .primary.ant-btn-primary[disabled]:active {
    background-color: ${theme.colors.grey_faint};
    border-color: ${theme.colors.grey};
    color: ${theme.colors.grey_stone};
    opacity: 1;
  }
  .ant-message {
    z-index: 1052;
  }
  .primary-modal {
    &.ant-modal {
    .ant-modal-content {
    .ant-modal-header {
    padding:24px 76px 24px 32px
    }
    }
    }
    }
`;
