/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Layout, Row, Col } from 'antd';
import {
  theme,
  Text,
  CardAnt,
  ButtonAnt,
  TableComponent,
} from '@pfmam/pfmam';
import { Container, Box, Banner, Anchor } from '../../../styles/globalStyle';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import phoneFormatter from 'phone-formatter';
import NoDataFound from '../../../common/components/noDataFound';
import { GetTeamMemberAccountDetails } from '../../../actions/actions';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const { Content } = Layout;

const OverrideStyle = createGlobalStyle`
  .ant-card {
    margin-bottom: 24px;
    &.basic-card {
      border: 0;
      border-radius: 4px;
    }
    .ant-card-head {
      border-color: ${theme.colors.grey_fossil};
      padding: 0 32px;
      font-family: 'Inter';
      font-weight: normal; 
      .ant-card-head-title {
        font-size: ${theme.font_size.size_3xl};
        color: ${theme.colors.black_tertiary};
        line-height: 32px;
        padding: 24px 0;
      }
    }
    .ant-card-body {
      padding: 40px 32px;
    }
  }
  .table-primary {
    .ant-table {
      .ant-table-thead {
        & > tr {
            & > th {
            padding: 8px 32px;
            background: ${theme.colors.grey_faint};
          }
        } 
      }
    }
  }
`;

const ContentWrapper = styled(Content)`
  .ant-table {
    border-radius: 4px;
    .ant-table-tbody {
      & > tr {
        &:hover {
          &.ant-table-row:not(.ant-table-expanded-row) {
            background: linear-gradient(
                0deg,
                ${(props) => props.rgbaHover},
                ${(props) => props.rgbaHover}
              ),
              ${theme.colors.white};
          }
        }
        & > td {
          vertical-align: top;
          padding: 20px 32px;
          &.ant-table-column-sort {
            background: ${theme.colors.transparent};
          }
        }
        &:last-child {
          & > td {
            border-bottom-color: ${theme.colors.transparent};
          }
        }
      }
    }
    .ant-table-thead {
      & > tr {
        & > th {
          background: ${theme.colors.light_grey};
          font-family: 'Inter';
          padding: 8px 32px;
          font-weight: normal;
          line-height: 20px;
          &:not(:last-child) {
            &:not(.ant-table-selection-column) {
              &:not(.ant-table-row-expand-icon-cell) {
                &:not([colspan]) {
                  &::before {
                    background-color: ${theme.colors.transparent};
                  }
                }
              }
            }
          }
        }
      }
    }
    &.ant-table.ant-table-middle {
      .ant-table-thead {
        .ant-table-column-sorters {
          padding: 0;
          .ant-table-column-sorter-inner .anticon {
            color: ${theme.colors.grey_stone};
          }
          .ant-table-column-sorter-up.active,
          .ant-table-column-sorter-down.active {
            color: ${theme.colors.black_sorted};
          }
        }
        .ant-table-column-sorter-inner {
          margin-top: 0;
        }
      }
    }
    .sort-space-none {
      .ant-table-column-sorters {
        justify-content: flex-start;
      }
      .ant-table-column-title {
        flex: 0 0 auto;
      }
      &.flex-end {
        .ant-table-column-sorters {
          justify-content: flex-end;
        }
      }
    }
    .ant-avatar {
      min-width: 40px;
    }
  }
  .ant-table-title {
    padding: 0 !important;
  }
`;

const Wrapper = styled.div`
  padding-top: 32px;
`;

const RenderContactInfo = ({ selectedRecord }) => {
  return (
    <Row gutter={42}>
      <Col md={12}>
        <Box margin="0 0 24px 0">
          <Text
            label="Login ID"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={selectedRecord.loginid}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
        <Box margin="0 0 24px 0">
          <Text
            label="Name"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={`${selectedRecord.firstName + ' ' + selectedRecord.lastName
              }`}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
        <Box margin="0 0 24px 0">
          <Text
            label="Email"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={selectedRecord.email}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
        <Box margin="0 0 24px 0">
          <Text
            label="Title"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={selectedRecord.title}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
      </Col>
      <Col md={12}>
        <Box margin="0 0 24px 0">
          <Text
            label="Phone Number"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={
              selectedRecord.phone === null || selectedRecord.phone === ""
                ? ""
                : phoneFormatter.format(
                  selectedRecord.phone,
                  '(NNN) NNN-NNNN +000'
                )
            }
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
        <Box margin="0 0 24px 0">
          <Text
            label="Mobile Number"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={
              selectedRecord.mobile === null || selectedRecord.mobile === ""
                ? ""
                : phoneFormatter.format(selectedRecord.mobile, '(NNN) NNN-NNNN')
            }
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
        <Box margin="0 0 24px 0">
          <Text
            label="Fax Number"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={
              selectedRecord.fax === null || selectedRecord.fax === ""
                ? ""
                : phoneFormatter.format(selectedRecord.fax, '(NNN) NNN-NNNN')
            }
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
        <Box margin="0 0 24px 0">
          <Text
            label="Last Login"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={
              selectedRecord.lastLoginDate != null
                ? moment(selectedRecord.lastLoginDate).format(
                  'MMM DD, YYYY [at] HH:mm'
                )
                : null
            }
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
      </Col>
    </Row>
  );
};

function modifyTeamMemberPfmManagedAccountDetailtModal(teamMemberInfo) {
  var resultantTeamMemberInfo = [];
  teamMemberInfo.map(function (item) {
    if (item.StatementRecipient == 'true' && item.AccessType == 'No Access') {
      resultantTeamMemberInfo.push({
        account: 'All',
        permissions: 'Statement Recipient'
      });
    } else if (item.StatementRecipient == 'true') {
      resultantTeamMemberInfo.push({
        account: 'All',
        permissions: item.AccessType + ', ' + 'Statement Recipient'
      });
    } else {
      resultantTeamMemberInfo.push({
        account: 'All',
        permissions: item.AccessType
      });
    }
  });
  return resultantTeamMemberInfo;
}
function modifyTeamMemberAccountDetailtModal(teamMemberInfo) {
  var resultantTeamMemberInfo = [];
  teamMemberInfo.map(function (item) {
    if (item.StatementRecipient == 'true') {
      resultantTeamMemberInfo.push({
        account: item.DisplayName,
        permissions: item.AccessType + ', ' + 'Statement Recipient'
      });
    } else {
      resultantTeamMemberInfo.push({
        account: item.DisplayName,
        permissions: item.AccessType
      });
    }
  });
  return resultantTeamMemberInfo;
}

const MemberDetails = ({
  selectedRecord,
  setViewMemberDetails,
  primaryColor,
  rgbaHover,
  canEditContact = false
}) => {
  const [accountPermissions, setAccountPermissions] = useState(null);
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const ssnorTIN = clientDetails.payload[0].ssnortin;
  const programId = clientDetails.payload[0].programid;
  const webuserAccessType = useSelector((state) => state.userSlice.webUserType);
  useEffect(() => {
    debugger;
    GetTeamMemberAccountDetails(selectedRecord.contactId, selectedRecord.contactPendingId, ssnorTIN)
      .then((response) => {
        if (response.data) {
          if (programId == '999') {
            setAccountPermissions(
              modifyTeamMemberPfmManagedAccountDetailtModal(response.data)
            );
          } else {
            setAccountPermissions(
              modifyTeamMemberAccountDetailtModal(response.data)
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const columns = [
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      align: 'left',
      sorter: false,
      width: 400,
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
      align: 'left',
      sorter: false,
    }
  ];

  const data = [
    {
      key: '1',
      account: 'General Fund *4998',
    },
    {
      key: '2',
      account: 'Capital Reserve *4998',
    },
    {
      key: '3',
      account: 'Payroll *4998',
    },
    {
      key: '4',
      account: 'Health Care Trust Account *4998',
    },
  ];
  return (
    <>
      <OverrideStyle />
      <ContentWrapper rgbaHover={rgbaHover}>
        <Banner bgcolor={primaryColor} height="152px">
          <Box
            display="flex"
            alignitems="center"
            height="100%"
            minwidth="100%"
            width="100%"
            zindex="3"
            position="relative"
            padding="40px 0 36px 0"
          >
            <Container>
              <Anchor
                // href={`/dashboard/details/${props.match.params.id}`}
                fontsize={`${theme.font_size.size_l}`}
                lineheight="20px"
                color={`${theme.colors.pearl_river}`}
                className="opac-text"
                onClick={() => setViewMemberDetails(false)}
              >
                <ArrowLeftOutlined style={{ marginRight: '6px' }} />
                Back to Settings
              </Anchor>
              <Box
                display="flex"
                justifycontent="space-between"
                alignitems="center"
                margin="8px 0 0"
              >
                <Text
                  color={`${theme.colors.white}`}
                  fontSize="36px"
                  lineheight="44px"
                  label={`${selectedRecord.firstName + ' ' + selectedRecord.lastName}`}
                />
                {/* <ButtonAnt label="Edit User" type="secondary" /> */}
                {selectedRecord.contactId > 1 && selectedRecord.contactPendingId > 1 || selectedRecord.contactId == 0 && selectedRecord.contactPendingId > 1 ? 
                <Box>
                <Text
                  color={`${theme.colors.pie_yellow}`}
                  fontSize="40px"
                  lineheight="44px"
                  label={'Pending'}
                />
              <Text
                label={'Changes not finalized until PFM Asset Management approval'}
                color={`${theme.colors.pie_red}`}
                fontSize={`${theme.font_size.size_xl}`}
                margin="10px 0 8px 0"
              /></Box> : null}</Box>
            </Container>
          </Box>
        </Banner>
        <Wrapper>
          <Container>
            <CardAnt
              cardContent={
                <RenderContactInfo selectedRecord={selectedRecord} />
              }
              className="basic-card"
              title={
                <Box display="flex"
                  justifycontent="space-between"
                  alignitems="right">
                  <Text label="Contact Information" />

                  {webuserAccessType == 0 && canEditContact ? (
                    <Link
                      to={{
                        pathname: '/edit-contact',
                        state: {
                          contactId: selectedRecord.contactId == 0 && selectedRecord.contactPendingId != 0 ? selectedRecord.contactPendingId : selectedRecord.contactId,
                          loginid: selectedRecord.loginid,
                          isNewContact: selectedRecord.contactId == 0 && selectedRecord.contactPendingId != 0 ? 1 : 0,
                          fromTeamMembers: 1
                        }
                      }}
                    >
                      <ButtonAnt
                        type="link"
                        label="Edit Contact"
                        icon={<EditOutlined />}
                      />
                    </Link>
                  ) : null}
                </Box>
              }
            />
            <TableComponent
              className="table-primary"
              title={
                <Box
                  display="flex"
                  justifycontent="space-between"
                  alignitems="center"
                  padding="24px 48px 24px 32px"
                  border={`1px solid ${theme.colors.grey_fossil}`}
                  borderwidth="0 0 1px 0"
                >
                  <Text
                    label="Permissions"
                    fontSize={`${theme.font_size.size_3xl}`}
                    lineheight="32px"
                    color={`${theme.colors.black_tertiary}`}
                  />
                </Box>
              }
              columns={columns}
              dataSource={accountPermissions}
              locale={{
                emptyText: <NoDataFound text="No permissions found" />,
              }}
              showSorterTooltip={false}
            />
          </Container>
        </Wrapper>
      </ContentWrapper>
    </>
  );
};

export default MemberDetails;
