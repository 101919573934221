import React,{useState} from 'react';
import { Box, Anchor } from '../../styles/globalStyle';
import { theme, Text, Modal } from '@pfmam/pfmam';
import { ClickOn } from '../../styles/globalStyle';
import SinglBankICon from '../../assets/images/Transaction Icons/single-account.png';
import MultipleAccIcon from '../../assets/images/Transaction Icons/multiple-accounts.png';
import TransferTypeBox from '../common/transferTypeBox';
import { useSelector } from 'react-redux';
import MoveFundsModal from '../../dashboard/components/moveFundsModal';

const RedemptionTypeCard = ({ setSelectedPage }) => {

  const showACHRedemption = useSelector((state) =>
    state.menuPermissions.menuPermissionOptions.includes(2)
  );
  const showWireRedemption = useSelector((state) =>
    state.menuPermissions.menuPermissionOptions.includes(4)
  );
  const [isMoveFundsVisible, setIsMoveFundsVisible] = useState(false);
  const handleFundsModalCancel = () => {
    setIsMoveFundsVisible(!isMoveFundsVisible);
  };
  return (
    <>
    <Text
      fontSize="36px"
      color={`${theme.colors.black_tertiary}`}
      label={'Redemption'}
      lineheight="44px"
      margin="0 0 5px"
    />
    <Anchor
      fontsize={`${theme.font_size.size_l}`}
      lineheight="20px"
      color={`${theme.colors.blue_primay}`}
      display="inline-block"
      margin="0 0 12px"
      onClick={() => {
        setIsMoveFundsVisible(!isMoveFundsVisible);
      }}
    >
      Change
          </Anchor>
    <Box
      border={`1px solid ${theme.colors.grey_fossil}`}
      borderwidth="0 0 1px 0"
      display="flex"
      padding="24px 32px"
      minwidth="100%"
      maxwidth="100%"
      flexdirection="column"
      boxcolor={theme.colors.white}
    >
      <Box minwidth="100%" maxwidth="100%" textalign="left">
        <Text
          fontSize={`${theme.font_size.size_3xl}`}
          color={`${theme.colors.black_tertiary}`}
          label={'Select Transfer Type'}
          lineheight="32px"
        />
      </Box>
    </Box>
    {showACHRedemption && (
      <ClickOn
        type="button"
        onClick={() => {
          setSelectedPage('RedemptionForm');
        }}
      >
        <TransferTypeBox
          icon={SinglBankICon}
          title="Single Bank Account"
          subTitle="Initiate a single ACH Redemption transaction"
        />
      </ClickOn>
    )}
    {showACHRedemption && (
      <ClickOn
        type="button"
        onClick={() => {
          setSelectedPage('MultiRedemptionForm');
        }}
      >
        <TransferTypeBox
          icon={MultipleAccIcon}
          title="Multiple Bank Account"
          subTitle="Initiate multiple ACH Redemption transactions"
        />
      </ClickOn>
    )}
    {showWireRedemption && (
      <ClickOn
        type="button"
        onClick={() => {
          setSelectedPage('WireRedemptionForm');
        }}
        className="last-transfer-type"
      >
        <TransferTypeBox
          icon={SinglBankICon}
          title="Wire Redemption"
          subTitle="Sell Fund shares and receive proceeds via a wire account"
        />
      </ClickOn>
    )}

    {isMoveFundsVisible && (
      <Modal
        title="Move Funds"
        width={590}
        bodyStyle={{
          padding: '0 0 64px 0',
        }}
        maskStyle={{
          backgroundColor: `${theme.rgba.modal}`,
          zIndex: '1051',
        }}
        content={<MoveFundsModal />}
        visible={isMoveFundsVisible}
        onCancel={() => handleFundsModalCancel()}
        footer={null}
        centered={true}
      />
    )}
    </>
  );
};

export default RedemptionTypeCard;
