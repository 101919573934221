import React from 'react';
import styled from 'styled-components';
import { theme, Text } from '@pfmam/pfmam';
import { Box } from '../../styles/globalStyle';

const Ul = styled.ul`
  list-style-type: disc;
  padding-left: 28px;
`;

const Li = styled.li`
  font-size: ${theme.font_size.size_xl};
  color: ${theme.colors.grey_secondary};
  line-height: 24px;
  margin-bottom: 10px;
`;

const BorderedText = styled.p`
  font-size: ${theme.font_size.size_xl};
  color: ${theme.colors.grey_secondary};
  line-height: 24px;
  margin-bottom: 10px;
`;

const PrivacyPolicyContent = ({ isAuthenticatedUser }) => {
  return (
    <Box padding="40px 0 0" width="100%" maxwidth="800px" margin="0 auto">
      <Box boxcolor={`${theme.colors.white}`} radius="2px">
        {isAuthenticatedUser && (
          <Box
            border={`1px solid ${theme.colors.grey}`}
            borderwidth="0 0 1px 0"
            padding="24px 32px"
          >
            <Text
              label="Privacy Policy"
              fontSize={`${theme.font_size.size_3xl}`}
              color={`${theme.colors.black_secondary}`}
              lineheight="32px"
            />
          </Box>
        )}
        <Box padding="43px 32px 48px">
          <Text
            label="This Website provides advisory clients and participants in investment funds administered by us with access to view account and program information and to complete transactions with regard to accounts."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="Providing a password-protected online environment for users of this Website is a high priority of ours. Customers have the right to expect privacy and security relating to information collected by financial institutions and we have devoted significant resources to designing systems and procedures to safeguard personal information."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="Background"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="The purpose of privacy policies and procedures is to provide administrative, technical and physical safeguards which assist employees in maintaining the confidentiality of non-public personal information collected from our clients. All non-public information, whether relating to current or former clients, is subject to these privacy policies and procedures. Any doubts about the confidentiality of client information must be resolved in favor of confidentiality. While our clients are currently only institutional and thus exempt from the provisions of SEC Regulation S-P, we have adopted the following privacy policies and procedures designed to protect any non-public personal information of clients."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="Policy"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="We have adopted policies and procedures designed to protect the “non-public personal information” of clients and to disclose to such clients policies and procedures for protecting that information. Non-public personal information includes non-public “personally identifiable financial information” plus any list, description or grouping of clients that is derived from non-public personally identifiable financial information. Such information may include personal financial and account information, information relating to services performed for or transactions entered into on behalf of clients, and data or analyses derived from such non-public personal information."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="Procedures"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="We have adopted various procedures to implement the privacy policy and we review operating procedures to monitor and ensure the policy is observed."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="The procedures are summarized as follows:"
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <BorderedText>
          <u>Non-Disclosure of Client Information</u>. We maintain safeguards to comply with applicable federal and state standards to guard each client’s non-public personal information. We do not share any non-public personal information with any nonaffiliated third parties, except in the following circumstances:
          </BorderedText>

          <Ul>
            <Li>
            	As necessary to provide the service that the client has requested or authorized, or to maintain and service the client’s account;
            </Li>
            <Li>
            	As required by regulatory authorities or law enforcement officials who have jurisdiction, or as otherwise required by any applicable law; and
            </Li>
            <Li>
            	To the extent reasonably necessary to prevent fraud and unauthorized transactions.
            </Li>
          </Ul>
          <BorderedText>
          <u>Security of Client Information</u>. We restrict access to non-public personal information to those employees who need to know such information to provide services to our clients. Any employee who is authorized to have access to non-public personal information is required to keep such information in a secure compartment or receptacle on a daily basis as of the close of business each day. All electronic or computer files containing such information shall be password secured and firewall protected from access by unauthorized persons.
          </BorderedText>
          <Text
            label="What types of information does this website collect and how is it used?"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="22px 0 10px"
          />
          <Text
            label="This Website collects IP addresses or domain names anytime a web page is requested from the Website server. This information is used in the aggregate to analyze site traffic. The URL of a referring web page also may be logged for the same purpose."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Information provided by site visitors is collected to assist in our delivery of services. If you send an email to us, your email address will be displayed in the message that we receive. We use this information to reply to your inquiry and we may keep your email message for our records, in part to fulfill regulatory requirements, and future reference. It is our privacy policy not to share that information with other companies or organizations, unless we are required to do so by law, court order or similar other judicial process, or regulatory requirements."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="The information that we collect from Website traffic is used to improve the content of our web pages and to notify Internet users of items of interest. Collected information is not shared with other organizations for commercial purposes."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Frequently, users may voluntarily provide non-public personal information. If you give your mailing address, then you may receive periodic mailings from us with information relating to the business and services provided. Likewise, if you give your telephone number, then you may receive telephone contact from us with information relating to its business and services provided."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="This Website is maintained by U.S. Bancorp Asset Management, Inc. (&quot;USBAM&quot;), through its division PFM Asset Management.  USBAM is an investment adviser registered with the U.S. Securities and Exchange Commission and a subsidiary of U.S. Bank National Association (&quot;U.S. Bank&quot;).  U.S. Bank is a separate entity and subsidiary of U.S. Bancorp.  U.S. Bank is not responsible for and does not guarantee the products, services or performance of USBAM or PFMAM."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            
          />
          <Text
            label="October 1, 2024"
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="50px"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PrivacyPolicyContent;
