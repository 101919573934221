/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import {
  storeFilteredProductTypes,
  storeFilteredTransactionTypes,
  storeInputSearch,
  storeFilteredAccounts,
} from '../../services/accountProfileSlice';
import {
  theme,
  ButtonAnt,
  Text,
  Modal,
} from '@pfmam/pfmam';
// import { Space, Tree, Switch } from 'antd';
import { Space, Switch, Tree, Divider, Tooltip } from 'antd';
import { Box, Container } from '../../styles/globalStyle';
import MultiSelect from '../../common/components/multiSelect';
import ExpandInputComponent from '../../common/components/expandInput';
import CheckboxImg from '../../assets/images/checkbox-blue.svg';
import CheckboxCheckedImg from '../../assets/images/checkbox-blue-checked.svg';
import RenderDatePicker from '../../common/components/datePicker';
import { DownloadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import accountData from '../../data/AccountDetails.json';
import DownloadModal from '../../common/components/downloadModal';

const FilterCol = styled(Space)`
  flex: 1;
  white-space: nowrap;
  overflow: ${(props) => (props.overflow ? 'hidden' : '')};
`;
const Wrapper = styled.div`
  padding-top: 22px;
`;

const ProductTreeFilter = styled(Tree)`
  .ant-tree-treenode {
    padding: 8px 0;
  }
  .ant-tree-switcher {
    height: 20px;
    width: 20px;
    line-height: 20px;
    margin-right: 3px;
    margin-left: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    .anticon {
      font-size: ${theme.font_size.size_l};
      color: ${theme.colors.grey_tertiary};
    }
  }
  .ant-tree-checkbox {
    margin-top: 2px;
    z-index: 2;
    &:hover {
    }
    &.ant-tree-checkbox-checked {
      &:after {
        border-color: ${(props) => props.checkboxCheckedColor};
      }
      .ant-tree-checkbox-inner {
        background-color: ${(props) => props.checkboxCheckedColor};
        border-color: ${(props) => props.checkboxCheckedColor};
        &::after {
          left: 20%;
          height: 10px;
          transform: rotate(40deg) scale(1) translate(-50%, -50%);
        }
      }
    }
  }
  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
    border-color: ${(props) => props.checkboxCheckedColor};
  }
  .ant-tree-checkbox-indeterminate {
    .ant-tree-checkbox-inner {
      &::after {
        background-color: ${(props) => props.checkboxCheckedColor};
      }
    }
  }
  .ant-tree-node-content-wrapper {
    color: ${theme.colors.black_secondary};
    line-height: 22px;
    min-height: 22px;
    padding: 0 2px;
    margin-top: -1px;
    z-index: 2;
    &:hover {
      background-color: ${theme.colors.transparent};
    }
    &.ant-tree-node-selected {
      background-color: ${theme.colors.transparent};
    }
  }
  .ant-tree-switcher-noop {
    & + {
      .ant-tree-checkbox {
        margin-left: 2px;
      }
    }
  }
`;
const SwitchWrapper = styled.li`
  cursor: default !important;
  border-bottom: 1px solid ${theme.colors.pearl_river};
  padding: 9px 12px;
  .ant-switch {
    background-color: ${theme.colors.grey_stone};
    &.ant-switch-checked {
      background-color: ${theme.colors.blue_primay};
      &:focus {
        box-shadow: 0 0 0 2px ${theme.rgba.gradient_blue};
      }
    }
    [ant-click-animating-without-extra-node='true']::after,
    .ant-click-animating-node {
      box-shadow: 0 0 0 2px ${theme.rgba.gradient_blue};
    }
  }
`;

const WrapperLi = styled.li`
  max-width: 100%;
  overflow: hidden;
  word-wrap: normal !important;
  white-space: normal;
`;

const RenderFilters = ({
  data,
  isGlobalActivityHistoryPage,
  handleSearch,
  dateChangeAction,
}) => {
  const dispatch = useDispatch();
  const [accountSelectedValues, setAccountSelectedValues] = useState([]);
  const [accountFilterClass, setAccountFilterClass] = useState('hide');
  const [accountSwitchChecked, setAccountSwitchChecked] = useState(false);
  const [accDetails, setAccDetails] = useState([]);
  const [productTypeSelectedValues, setProductTypeSelectedValues] = useState(
    []
  );
  const [productTypeFilterClass, setProductTypeFilterClass] = useState('hide');

  const [transactionTypeSelectedValues, setTransactionTypeSelectedValues] =
    useState([]);

  const [transactionTypeFilterClass, setTransactionTypeFilterClass] =
    useState('hide');

  const selectedAccount = useSelector(
    (state) => state.accountProfileSlice.selectedAccount
  );

  const activityHistory = useSelector(
    (state) => state.activityHistorySlice.ActivityHistory
  );

  const primary_color = useSelector((state) => state.siteInfo.primaryColor);

  const [dateValues, setDateValues] = useState([
    moment().subtract(60, 'days'),
    moment(),
  ]);
  const [dateOption, setDateOption] = useState('last60days');
  const [dateLabel, setDateLabel] = useState('Last 60 days');
  const [dateOpen, setDateOpen] = useState(false);
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [inputClassName, setInputClassName] = useState('hide');
  const [tooltip, setTooltip] = useState(false);

  const handleProductTypeChange = (checkedNodes) => {
    getCheckedValues(
      checkedNodes,
      setProductTypeSelectedValues,
      storeFilteredProductTypes
    );
  };
  const handleAccountChange = (AccountUniqueID) => {
    if (accountSelectedValues.includes(AccountUniqueID)) {
      const values = accountSelectedValues.filter(
        (selectedValue) => selectedValue !== AccountUniqueID
      );
      setAccountSelectedValues([...values]);
      dispatch(storeFilteredAccounts([...values]));
    } else {
      setAccountSelectedValues([...accountSelectedValues, AccountUniqueID]);
      dispatch(
        storeFilteredAccounts([...accountSelectedValues, AccountUniqueID])
      );
    }
  };

  function getCheckedValues(checkedNodes, type, dispatchAction) {
    const checkedValues = [];
    const checkedValuesKey = [];
    checkedNodes.map((node) => {
      if (!node.isParentNode) {
        checkedValues.push(node.title);
        checkedValuesKey.push(node.key);
      }
    });
    type([...checkedValuesKey]);
    dispatch(dispatchAction(checkedValues));
  }

  const handleTransactionTypeChange = (checkedNodes) => {
    getCheckedValues(
      checkedNodes,
      setTransactionTypeSelectedValues,
      storeFilteredTransactionTypes
    );
  };

  const buildTreeData = (data) => {
    const list = [];
    data.map((element) => {
      const treeNode = {
        title: element.toString(),
        key: data.indexOf(element),
      };
      list.push(treeNode);
    });
    return list;
  };

  function filterActivityHistory() {
    return activityHistory;
  }
  function unique(arr, keyProps) {
    const kvArray = arr.map((entry) => {
      const key = keyProps.map((k) => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }
  const getAccountFilterOptions = () => {
    const filterOptions = [];
    let uniqueAccounts = [];
    let accountHistory = filterActivityHistory();
    uniqueAccounts = unique(accountHistory, ['AccountName', 'AccountUniqueID']);
    uniqueAccounts.sort((a, b) =>
      a.AccountName > b.AccountName ? 1 : b.AccountName > a.AccountName ? -1 : 0
    );
    uniqueAccounts.map((account) => {
      filterOptions.push(
        <WrapperLi>
          <li
            key={account.AccountUniqueID}
            onClick={() => handleAccountChange(account.AccountUniqueID)}
          >
            <img
              src={
                accountSelectedValues.includes(account.AccountUniqueID)
                  ? CheckboxCheckedImg
                  : CheckboxImg
              }
              alt="Checkbox"
            />{' '}
            {account.AccountName.toString() +
              '**' +
              account.AccountUniqueID.toString().slice(-4)}
          </li>
        </WrapperLi>
      );
    });
    return filterOptions;
  };
  const getProductTypeFilterOptions = () => {
    let uniqueProductTypes = [];
    let accountHistory = filterActivityHistory();
    accountHistory.map((x) => {
      if (uniqueProductTypes.indexOf(x.InvestmentTypeClassDesc) === -1) {
        uniqueProductTypes.push(x.InvestmentTypeClassDesc);
      }
    });
    uniqueProductTypes.sort();
    return (
      <ProductTreeFilter
        checkable
        selectedKeys={productTypeSelectedValues}
        checkedKeys={productTypeSelectedValues}
        onCheck={(_, info) => handleProductTypeChange(info.checkedNodes)}
        treeData={buildTreeData(uniqueProductTypes)}
        checkboxCheckedColor={`${theme.colors.blue_primay}`}
      />
    );
  };

  const getTransFilterOptions = () => {
    let uniqueTransactionTypes = [];
    let accountHistory = filterActivityHistory();
    accountHistory.map((x) => {
      if (uniqueTransactionTypes.indexOf(x.TransactionType) === -1) {
        uniqueTransactionTypes.push(x.TransactionType);
      }
    });
    uniqueTransactionTypes.sort();
    return (
      <ProductTreeFilter
        checkable
        selectedKeys={transactionTypeSelectedValues}
        checkedKeys={transactionTypeSelectedValues}
        onCheck={(_, info) => handleTransactionTypeChange(info.checkedNodes)}
        treeData={buildTreeData(uniqueTransactionTypes)}
        checkboxCheckedColor={`${theme.colors.blue_primay}`}
      />
    );
  };
  const updateModalStatus = () => {
    setIsDownloadModalVisible(false);
  };
  const onInputSubmit = (searchterm) => {
    handleSearch(searchterm);
  };
  const handleDateChange = () => {
    dateChangeAction(dateValues[0], dateValues[1]);
  };
  const showModal = () => {
    setIsDownloadModalVisible(true);
  };

  const handleCancel = () => {
    setIsDownloadModalVisible(false);
  };
  return (
    <Container>
      <Wrapper>
        <Box
          margin="0 0 24px 0"
          display="flex"
          justifycontent="space-between"
          alignitems="center"
          minwidth="100%"
          width="100%"
          className={inputClassName === 'hide' ? '' : 'expanded'}
        >
          <FilterCol size={8} overflow={inputClassName === 'show'}>
            <ExpandInputComponent
              onInputSubmit={onInputSubmit}
              inputClassName={inputClassName}
              setInputClassName={setInputClassName}
            />
            <RenderDatePicker
              dateValues={dateValues}
              setDateValues={setDateValues}
              dateOption={dateOption}
              setDateOption={setDateOption}
              dateLabel={dateLabel}
              setDateLabel={setDateLabel}
              dateOpen={dateOpen}
              setDateOpen={setDateOpen}
              handleDateChange={handleDateChange}
            />
            {isGlobalActivityHistoryPage && (
              <MultiSelect
                storeArray={'clearAllAccounts'}
                filterClass={accountFilterClass}
                setFilterClass={setAccountFilterClass}
                selectedValues={accountSelectedValues}
                setSelectedValues={setAccountSelectedValues}
                options={getAccountFilterOptions()}
                buttonLabel={'Account'}
                height={'180px'}
                selectedBgColor={`${primary_color}`}
                selectedColor={`${theme.colors.white}`}
              />
            )}
            <MultiSelect
              storeArray={'clearAllProductTypes'}
              filterClass={productTypeFilterClass}
              setFilterClass={setProductTypeFilterClass}
              selectedValues={productTypeSelectedValues}
              setSelectedValues={setProductTypeSelectedValues}
              options={getProductTypeFilterOptions()}
              buttonLabel={'Investment Type'}
              minHeight={'150px'}
              height={'auto'}
              borderRadius={'2px'}
              dropdownPadding={'4px 0 4px 0'}
              selectedBgColor={primary_color}
              selectedColor={`${theme.colors.white}`}
              scrollable={true}
            />
            <MultiSelect
              storeArray={'clearAllInvestmentTypes'}
              filterClass={transactionTypeFilterClass}
              setFilterClass={setTransactionTypeFilterClass}
              selectedValues={transactionTypeSelectedValues}
              setSelectedValues={setTransactionTypeSelectedValues}
              options={getTransFilterOptions()}
              buttonLabel={'Transaction Type'}
              minHeight={'150px'}
              height={'auto'}
              borderRadius={'2px'}
              dropdownPadding={'4px 0 4px 0'}
              selectedBgColor={primary_color}
              selectedColor={`${theme.colors.white}`}
            />
          </FilterCol>
          <Box display="flex" alignitems="center">
            <Divider type="vertical" className="filter-divider" />
            <Tooltip
              placement="top"
              title="Download"
              overlayClassName="tooltip"
              defaultVisible={inputClassName === 'hide' ? false : true}
              visible={tooltip}
            >
              <ButtonAnt
                icon={<DownloadOutlined />}
                label={inputClassName === 'hide' ? 'Download' : ''}
                type="secondary"
                onClick={showModal}
                onMouseEnter={() =>
                  inputClassName === 'hide'
                    ? setTooltip(false)
                    : setTooltip(true)
                }
                onMouseLeave={() => setTooltip(false)}
              />
            </Tooltip>
          </Box>
        </Box>

        <Modal
          title="Download File Type"
          width={590}
          bodyStyle={{
            padding: '32px 32px 34px 32px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          content={
            <DownloadModal downloadData={data} type="ActivityHistory"  onCancel={updateModalStatus} />
          }
          visible={isDownloadModalVisible}
          onCancel={handleCancel}
          footer={null}
          centered={true}
        />
      </Wrapper>
    </Container>
  );
};

export default RenderFilters;
