import React, { useState } from 'react';
import styled from 'styled-components';
import { theme, ButtonAnt, Text } from '@pfmam/pfmam';
import { Space, Radio, message } from 'antd';
import { sizes } from '../../styles/mediaQuery';
import { Box } from '../../styles/globalStyle';
import { useSelector } from 'react-redux';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { ObjectToCsv, ObjectToXLS } from '../../utils/common';
import axios from 'axios';
import config from '../../config';

const ButtonWrapper = styled(Space)`
  width: 100%;
  justify-content: flex-end;
  margin-top: 48px;
  @media ${sizes.mediaQuery('xs', 'sm')} {
    flex-direction: column;
    .ant-btn,
    .ant-space-item {
      width: 100%;
    }
  }
`;
const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
  }
`;
function getHoldingData(data) {
  var HoldingsDownload = [];
  data.forEach((val) => {
    HoldingsDownload.push(val.Holdings);
  });
  var result = [].concat.apply([], HoldingsDownload);
  return result;
}

function DownloadHoldingsPDF(
  accountUniqueID,
  setDownloadProgress
) {
  const baseApiUrl = config.BASE_API_URL;
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'ReportGenerator/DailyHoldings/' + accountUniqueID,
    onDownloadProgress: (progressEvent) => {
      let percentCompleted = parseInt(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      setDownloadProgress(percentCompleted);
    },
  });
}

const success = (percentCompleted) => {
  message.loading('Download in progress..').then(() => {
    if (percentCompleted == 100) {
      message.success('Download finished');
    }
  });
};

const DownloadModal = ({ downloadData, onCancel, type = null }) => {
  const [downloadProgress, setDownloadProgress] = useState(0);
  const selectedAccount = useSelector(
    (state) => state.accountProfileSlice.selectedAccount
  );
  const programType = selectedAccount.AccountType;
  const fundgroup_Id = useSelector((state) => state.siteInfo.fundGroupID);

  const [downloadOption, setDownloadOption] = useState('');
  const primaryColor = useSelector((state) => state.siteInfo.primaryColor);

  const exportHoldingsToPDF = () => {
    var date = new Date();
    success(downloadProgress);

    DownloadHoldingsPDF(selectedAccount.AccountUniqueID,setDownloadProgress)
        .then((response) => {
          if (response.data != null) {
            const arrayBuffer = base64ToArrayBuffer(response.data);
            createAndDownloadBlobFile(arrayBuffer, 'Holdings_' +  moment(date).format('YYYYMMDD'));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    CancelOnClick();
  }

  function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  function createAndDownloadBlobFile(body, filename, extension = 'pdf') {
    const blob = new Blob([body]);
    const fileName = `${filename}.${extension}`;
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  const exportToCSV = (data) => {
    if(type == "ActivityHistory"){
      data = data.slice().sort((a, b) => b.SettlementDate - a.SettlementDate)
  }
    if (type !== null && (type === 'Holdings' || type === 'HistoricalHoldings')) {
      data = getHoldingData(data);
    }
    const csvdata = ObjectToCsv(data, fundgroup_Id);
    const csvData = new Blob([csvdata], { type: 'text/csv;charset=utf-8;' });
    var date = new Date();
    if (type !== null && type === 'StopPayment') {
      FileSaver.saveAs(
        csvData,
        'StopPayment_' + moment(date).format('YYYYMMDD') + '' + '.csv'
      );
    } else if (type !== null && (type === 'Holdings' || type === 'HistoricalHoldings')) {
      FileSaver.saveAs(
        csvData,
        'Holdings_' + moment(date).format('YYYYMMDD') + '' + '.csv'
      );
    } else {
      FileSaver.saveAs(
        csvData,
        'Activity_' + moment(date).format('YYYYMMDD') + '' + '.csv'
      );
    }
    CancelOnClick();
  };

  const exportToXLSX = (data) => {
    if (type !== null && (type === 'Holdings' || type === 'HistoricalHoldings')) {
      data = getHoldingData(data);
    }
    if(type == "ActivityHistory"){
      data = data.slice().sort((a, b) => b.SettlementDate - a.SettlementDate)
  }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const excelBuffer = ObjectToXLS(data, fundgroup_Id);
    const xlsxData = new Blob([excelBuffer], { type: fileType });
    var date = new Date();
    if (type !== null && type === 'StopPayment') {
      FileSaver.saveAs(
        xlsxData,
        'StopPayment_' + moment(date).format('YYYYMMDD') + '' + fileExtension
      );
    } else if (type !== null && (type === 'Holdings' || type === 'HistoricalHoldings')) {
      FileSaver.saveAs(
        xlsxData,
        'Holdings_' + moment(date).format('YYYYMMDD') + '' + fileExtension
      );
    } else {
      FileSaver.saveAs(
        xlsxData,
        'Activity_' + moment(date).format('YYYYMMDD') + '' + fileExtension
      );
    }
    CancelOnClick();
  };
  const CancelOnClick = () => {
    onCancel();
  };
  function onChangeValue(current) {
    setDownloadOption(current.target.value);
  }
  return (
    <>
      <Text
        label="Choose a file type for your download."
        fontSize={`${theme.font_size.size_xl}`}
        color={`${theme.colors.grey_tertiary}`}
        lineheight="24px"
      />
      <Box margin="16px 0 32px" className="forms">
        <Radio.Group onChange={(e) => onChangeValue(e)}>
          <Radio value={1}>CSV</Radio>
          <Radio value={2}>XLSX (Excel)</Radio>
          {fundgroup_Id == '999' && programType != 'POOL' && type === 'Holdings' && (<Radio value={3}>PDF</Radio> ) }
        </Radio.Group>
      </Box>
      <Text
        label="Prior to downloading any information from this website, please read the important Terms and Conditions."
        fontSize={`${theme.font_size.size_xl}`}
        color={`${theme.colors.grey_tertiary}`}
        lineheight="24px"
      />
      <ButtonWrapper size={12}>
        <ButtonAnt
          type="secondary"
          label="Cancel"
          onClick={() => CancelOnClick()}
        />
        <OverrideButtonAnt
          type="primary"
          label="Download"
          htmlType="submit"
          linkTextColor={primaryColor}
          onClick={(e) => {
            if (downloadOption == 1) {
              exportToCSV(downloadData);
            } else if (downloadOption == 2) {
              exportToXLSX(downloadData);
            } else if (downloadOption == 3){
              exportHoldingsToPDF();
            } else {
              CancelOnClick();
            }
          }}
        />
      </ButtonWrapper>
    </>
  );
};

export default DownloadModal;
