import React, { useEffect, useState } from 'react';
import { theme, Text } from '@pfmam/pfmam';
import { Radio } from 'antd';
import { Box } from '../../styles/globalStyle';
import styled from 'styled-components';
import { amountFormat } from '../../utils/numberFormat';
import { useSelector } from 'react-redux';
import {
  GetTransactableAccounts
} from '../../actions/actions';
const RadioItemsWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 15px;
`;
const Title = styled(Text)`
  font-weight: bold;
`;
const InvestmentModalContent = ({
  form,
  onInvestmentTypeChange,
  showAmount = true,
  pendingTransactionTypeClassID,
  //disableZeroAccounts=false,
}) => {
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const ProgramID = clientDetails.payload[0].programid;
  const SsnOrTin = clientDetails.payload[0].ssnortin;
  const [transactableAccounts, setTransactableAccounts] = useState([]);

  useEffect(() => {
    GetTransactableAccounts(SsnOrTin, ProgramID, pendingTransactionTypeClassID) // 3 Indicates Wire notification
        .then((response) => {
          if (response.data !== null && response.data.length > 0) {
            setTransactableAccounts(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
  }, [SsnOrTin, ProgramID, pendingTransactionTypeClassID]);
  return (
    <>
      <Radio.Group
        defaultValue="pligit_prime"
        size="large"
        onChange={(e) => onInvestmentTypeChange(e)}
        value={form.getFieldValue('accountinvestmentType')}
      >
        {transactableAccounts.map((account) => {
          return (
            <>
              <Box
                boxcolor={`${theme.colors.light_grey}`}
                padding="8px 32px"
                display="flex"
                justifycontent="space-between"
              >
                <Title
                  label={account.DisplayName}
                  fontSize={`${theme.font_size.size_xl}`}
                  lineheight="20px"
                  color={`${theme.colors.clean_black}`}
                />
                {showAmount && (
                  <Text
                    label="Available Balance"
                    fontSize={`${theme.font_size.size_l}`}
                    lineheight="20px"
                    color={`${theme.colors.clean_black}`}
                  />
                )}
              </Box>
              {account.FundDetails.map((type) => {
                return (
                  <Radio.Button value={{AccName: account.DisplayName,AccountUniqueID:account.AccountUniqueID, type:type }}
                  //disabled={disableZeroAccounts && type.AvailBalance === 0 ? true:false}
                  >
                    <RadioItemsWrapper>
                      <span>{type.FundShortName}</span>
                      {showAmount && (
                        <span>{amountFormat(type.AvailBalance)}</span>
                      )}
                    </RadioItemsWrapper>
                  </Radio.Button>
                );
              })}
            </>
          );
        })}
      </Radio.Group>
    </>
  );
};

export default InvestmentModalContent;
