import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signinRedirect, signoutRedirect } from './services/userService';
import { getCookie } from './utils/common';

function Login() {
  const bcrypt = require('bcryptjs');
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSlice.user);
let isLoginCookiePresent = bcrypt.compareSync("true",getCookie('AuthLog'));
  if (user == null) {
    signinRedirect();
  }
  if(user != null && !isLoginCookiePresent){
    signoutRedirect(user.id_token, dispatch);
  }
  if(user != null && isLoginCookiePresent){
    return <Redirect to="/" />;
  }
}
export default Login;
