import React, { useState } from 'react';
import AppLayout from '../common/layouts/appLayout';
import { Box } from '../styles/globalStyle';
import { OverrideStyles } from '../transactionPages/purchaseFunds/purchaseContainer';
import { theme} from '@pfmam/pfmam';
import { ExistingUserSearch } from './components/ExistingUserSearch';
import { UserSearch } from './components/UserSearch';
import { EmailConfirmation } from './components/EmailConfirmation';
import { NotAMatch } from './components/NotAMatch';
import { useSelector } from 'react-redux';
const ContactManagement = () => {
  const [step, setStep] = useState(1);
  const [errorCount, setErrorCount] = useState(0);
  const [users, setUsers] = useState([]);
  const clientDetails = useSelector((state) => state.programsClientsSlice.selectedClient);
  return (
    <>
    <OverrideStyles />
    <AppLayout>
      <Box maxwidth={step==1 ? "590px": step==2 ?"500px":"690px"} width="100%" margin="0 auto" padding="30px 0 0">
      <Box
        border={`1px solid ${theme.colors.grey_fossil}`}
        display="flex"
        padding="25px 32px"
        minwidth="100%"
        maxwidth="100%"
        flexdirection="column"
        boxcolor={theme.colors.white}
      >
      {step === 1 && <ExistingUserSearch fundname={clientDetails.payload[0].programname}  setStep={setStep} />}
      {step === 2 && (
        <UserSearch setUsers={setUsers} errorCount={errorCount} setErrorCount={setErrorCount}  setStep={setStep} 
        />
      )}
     {step === 3 && (
        <EmailConfirmation users={users} errorCount={errorCount} setErrorCount={setErrorCount} setStep={setStep}/>
      )}
     {step === 4 && (
        <NotAMatch setStep={setStep} errorCount={errorCount} setErrorCount={setErrorCount} />
      )}
      </Box>
      </Box>
    </AppLayout>
    </>
  );
};

export default ContactManagement;
