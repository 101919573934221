/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Row, Col } from 'antd';
import { theme, Text, CardAnt } from '@pfmam/pfmam';
import { Box, Container } from '../../styles/globalStyle';
import ClientManagementContainer from '../components/teamMembers/clientManagementContainer';

const OverrideStyle = createGlobalStyle`
  .ant-card {
    margin-bottom: 24px;
    &.basic-card {
      border: 0;
      border-radius: 4px;
    }
    .ant-card-head {
      border-color: ${theme.colors.grey_fossil};
      padding: 0 32px;
      font-family: 'Inter';
      font-weight: normal; 
      .ant-card-head-title {
        font-size: ${theme.font_size.size_3xl};
        color: ${theme.colors.black_tertiary};
        line-height: 32px;
        padding: 24px 0;
      }
    }
    .ant-card-body {
      padding: 40px 32px 48px;
    }
  }
  .table-primary {
    .ant-table {
      .ant-table-thead {
        & > tr {
            & > th {
            padding: 8px 32px;
            background: ${theme.colors.grey_faint};
          }
        } 
      }
    }
  }
`;

const Wrapper = styled.div`
  padding-top: 40px;
`;

const RenderOrgDetails = ({ orgDetails }) => {
  if (orgDetails.length > 0) {
    orgDetails = orgDetails[0];
  }
  return (
    <Row gutter={42}>
      <Col md={12}>
        <Box margin="0 0 24px 0">
          <Text
            label="Entity Name"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={orgDetails.entityName}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
        <Box margin="0 0 24px 0">
          <Text
            label="Legal Name"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={orgDetails.legalName}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
        <Box margin="0 0 24px 0">
          <Text
            label="TAX ID"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={orgDetails.taxId}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
        <Box margin="0 0 24px 0">
          <Text
            label="Address"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={orgDetails.addressLine1}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
          <Text
            label={orgDetails.addressLine2}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
        <Box margin="0 0 24px 0">
          <Text
            label="City"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={orgDetails.city}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
        <Box margin="0 0 24px 0">
          <Text
            label="Zip Code"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={orgDetails.zipcode}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
      </Col>
      <Col md={12}>
        <Box margin="0 0 24px 0">
          <Text
            label="Fiscal Year End"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={orgDetails.fiscalYearEnd}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
        <Box margin="0 0 24px 0">
          <Text
            label="County"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={orgDetails.county}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
        <Box margin="0 0 24px 0">
          <Text
            label="Voice Phone"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={orgDetails.mobile}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
        <Box margin="0 0 24px 0">
          <Text
            label="Fax Number"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={orgDetails.fax}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
        <Box margin="0 0 24px 0">
          <Text
            label="State"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={orgDetails.state}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
      </Col>
      <Col md={12}>
      <Box margin="0 0 24px 0">
          <Text
            label="Voice Phone"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            margin="0 0 8px 0"
            lineheight="20px"
          />
          <Text
            label={orgDetails.mobile}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
      </Col>
    </Row>
  );
};


const OrganisationalDetails = ({ data,data2 }) => {
  return (
    <Container>
      <OverrideStyle />
      <Wrapper>
        <CardAnt
          cardContent={<RenderOrgDetails orgDetails={data} />}
          className="basic-card"
          title="Organization Details"
        />
      </Wrapper>
      <ClientManagementContainer clientManagementInfo={data2} />
    </Container>
  );
};

export default OrganisationalDetails;