import React, { useState } from 'react';
import { Form } from 'antd';
import RedemptionForm from './forms/redemptionForm';
import ReviewForm from './forms/reviewForm';
import moment from 'moment';
const RedemptionFlow = ({ setSelectedPage, selectedPage }) => {
  const [selected, setSelected] = useState('single');
  const [accSelected, setAccSelected] = useState(true);
  const [showInvestmentTypeModal, setShowInvestmentTypeModal] = useState(false);
  const [form] = Form.useForm();
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [formType, setFormType] = useState('redeem');
  const [formData, setFormData] = useState({});
  const [accName, setAccName] = useState('');
  const [amount, setAmount] = useState(0);
  const [showAccountsModal, setShowAccountsModal] = useState(false);
  const [defaultDate, setDefaultDate] = useState(moment());
  return (
    <>
      {formType === 'review' ? (
        <ReviewForm
          form={form}
          formData={formData}
          accName={accName}
          selected={selected}
          amount={amount}
          setSelectedPage={setSelectedPage}
          setSelected={setSelected}
          setDefaultDate={setDefaultDate}
          defaultDate={defaultDate}
          selectedPage={selectedPage}
          setFormType={setFormType}
        />
      ) : (
        <RedemptionForm
          form={form}
          accSelected={accSelected}
          setAccSelected={setAccSelected}
          selected={selected}
          setSelected={setSelected}
          showInvestmentTypeModal={showInvestmentTypeModal}
          setShowInvestmentTypeModal={setShowInvestmentTypeModal}
          setShowReviewModal={setShowReviewModal}
          showReviewModal={showReviewModal}
          setFormType={setFormType}
          formData={formData}
          setFormData={setFormData}
          setAccName={setAccName}
          accName={accName}
          setAmount={setAmount}
          setDefaultDate={setDefaultDate}
          defaultDate={defaultDate}
          setSelectedPage={setSelectedPage}
          selectedPage={selectedPage}
          showAccountsModal={showAccountsModal}
          setShowAccountsModal={setShowAccountsModal}
        />
      )}
    </>
  );
};

export default RedemptionFlow;