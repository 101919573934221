import React, { useState } from 'react';
import { Box,Anchor } from '../../styles/globalStyle';
import { Space, Radio } from 'antd';
import { theme, Text, ButtonAnt } from '@pfmam/pfmam';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UpdateConnectFailedLoginAttempts } from '../../actions/actions';
import {  useDispatch } from 'react-redux';
import { signoutRedirect } from '../../services/userService';
const GreyBox = styled(Box)`
  &.multi-cntct-mail-content {
    .ant-input-number-input {
      text-align: right;
      padding: 0 2520px;
    }
  }
  .field-wrapper {
    align-items: flex-start;
  }
`;
const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
  }
`;
const ButtonWrapper = styled(Space)`
  justify-content: space-between;
  width: 100%;
  min-width: 100%;
  margin-top: 16px;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
`;

const ButtonWrapperNoMatch = styled(Space)`
  justify-content: center;
  width: 100%;
  min-width: 100%;
  margin-top: 16px;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
`;

export const EmailConfirmation = ({ users,errorCount,setErrorCount, setStep}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSlice.user);
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const [contactId, setContactId] = useState();
  const [value, setValue] = useState(null);
  const [loginid, setLoginid] = useState();
  const [isNewContact, setIsNewContact] = useState();
  const [disableSubmit, setdisableSubmit] = useState(true);
  const history = useHistory();
  const onChange = (e) => {
    if(e.target.checked){
      setValue(e.target.value);
      setLoginid(e.target.value.WebUserKey);
      setContactId(e.target.value.ContactID);
      setIsNewContact(e.target.value.IsNewContact);
      setdisableSubmit(false);
    }
    else{
      setdisableSubmit(true);
    }
  };
  const deselect=(e)=>{
    setValue(null);
    setdisableSubmit(true);
  }
  return (
    <>
      <GreyBox
        boxcolor={primary_color}
        className="multi-cntct-mail-content">
        <Box display="flex"
          alignitems="center"
          padding={users.length > 1 ? "0 0 0 220px" : "0 0 0 245px"}>
          <Text
            label={users.length > 1 ? "Multiple Match" : "Single Match"}
            fontSize={`${theme.font_size.size_3xl}`}
            lineheight="42px"
            color={`${theme.colors.white}`}
          />
        </Box>
      </GreyBox>
      <Box className="forms">
        <Text
          label={
            users.length > 1 ? "Connect has found multiple matches based on the entered field." :
              "Connect has found a match based on the entered field."

          }
          fontSize={`${theme.font_size.size_2xl}`}
          lineheight="42px"
          color={`${theme.colors.black_secondary}`}
        />
        <Text
          label={
            users.length > 1 ? "Confirm email on the file to be:"
              : "Select email that would be on file for this contact:"}
          fontSize={`${theme.font_size.size_2xl}`}
          lineheight="42px"
          color={`${theme.colors.black_secondary}`}
        />
        <Box padding="20px">
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              {users.map((user) => (
                <Radio onClick={deselect} key={user.ContactID} value={user}>{user.EmailAddress}</Radio>
              ))}
            </Space>
          </Radio.Group>
          <ButtonWrapper size={12}>
            <ButtonAnt
              label="Not A Match"
              type="secondary"
              htmlType="button"
              onClick={() => {
                if(errorCount < 5){
                  setStep(4);
                  setErrorCount(errorCount+1);
                }
                else{
                  UpdateConnectFailedLoginAttempts(true).then((response) => {
                    if(response.data){
                      signoutRedirect(user.id_token, dispatch);
                    }
                  }).catch((err) => {
                    console.log(err);
                  });
                }

              }}
            />  
            <OverrideButtonAnt
              disabled={disableSubmit}
              linkTextColor={primary_color} label="Confirm Match" type="primary"
              onClick={() => {
                history.push("/edit-contact", { contactId: contactId, loginid: loginid, isNewContact: isNewContact, fromTeamMembers: 0 });
              }}
            />
          </ButtonWrapper>
        </Box>
      </Box>
    </>
  )
}