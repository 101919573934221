import React, { useEffect } from 'react';
import { signoutRedirect } from './services/userService';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

function SignoutOidc() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user_logout = useSelector((state) => state.userSlice.user.id_token);
  useEffect(() => {
    async function signoutAsync() {
      await signoutRedirect(user_logout,dispatch);
      history.push('/');
    }
    
    signoutAsync();
  }, [history]);

  return <div></div>;
}

export default SignoutOidc;
