/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { Box } from '../../../styles/globalStyle';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Form, Row, Col, Space } from 'antd';
import {
  theme,
  ButtonAnt,
  InputComponent,
  Text,
  SelectComponent,
} from '@pfmam/pfmam';
import ReviewTeamMember from './reviewTeamMember';
import RenderSelectPermissionsComponent from './selectPermissions';
import { CaretDownOutlined } from '@ant-design/icons';
import accountData from '../../../data/AccountDetails.json';
import { validateEmail, isNumber } from '../../../utils/common';

const OverrideStyle = createGlobalStyle`
  .ant-form-item-control-input-content {
    & > span {
      width: 100%;
    }
    .primary.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 52px;
        width: 100%;
        background: ${theme.colors.white};
        color: ${theme.colors.black_secondary};
        border: 1px solid ${theme.colors.grey};
        padding: 14px 20px;
        .ant-select-selection-item {
          line-height: 20px;
        }
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
      .ant-select-arrow {
        color: ${theme.colors.grey_stone};
        font-size: ${theme.font_size.size_l};
        margin-top: 0;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
      }
      &.ant-select-focused {
        .ant-select-selector {
          box-shadow: ${theme.shadows.input_focus};
          border-color: ${theme.colors.blue_primay};
        }
          .ant-select-arrow {
          color: ${theme.colors.black_secondary};
        }
      } 
    }
  }
  
  .form-select-dropdown {
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      .ant-select-item {
        &.ant-select-item-group {
          color: ${theme.colors.grey_tertiary};
          min-height: 16px;
          line-height: 16px;
          padding: 6px 12px;
          &:hover {
            background: none;
          }
        }
      }
      .ant-select-item-option-selected,
      .ant-select-item-option-active {
        &:not(.ant-select-item-option-disabled) {
          background: ${theme.colors.white};
          font-weight: normal;
        }
      }
      .ant-select-item {
        min-height: 40px;
        line-height: 24px;
        padding: 8px 12px;
        font-size: ${theme.font_size.size_xl};
        color: ${theme.colors.black_secondary};
        font-family: 'Inter';
        &:hover {
        background: ${theme.colors.light_grey};
        font-family: 'Inter';
      }
    }
  }
`;

const ButtonWrapper = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 16px;
    `}
`;

const AddEditTeamMembers = ({
  defaultAddEditAccObj,
  addEditAccountObject,
  setAddEditAccountObject,
}) => {
  const [isAddTeamMember, setIsAddTeamMember] = useState(true);
  const [memberData, setMemberData] = useState(null);
  const [selectAccounts, setSelectAccounts] = useState(false);
  const [isReviewTeamMmber, setIsReviewTeamMmber] = useState(false);
  const [isTeamMemberCompleted, setIsTeamMemberCompleted] = useState(false);
  const defaultPermissionsObject = [
    {
      key: 1,
      permission: 'View Info',
      accounts: [],
    },
    {
      key: 2,
      permission: 'Transact',
      accounts: [],
    },
    {
      key: 3,
      permission: 'Open/Close',
      accounts: [],
    },
    {
      key: 4,
      permission: 'Sign Checks',
      accounts: [],
    },
    {
      key: 5,
      permission: 'Verify Checks',
      accounts: [],
    },
    {
      key: 6,
      permission: 'Manage Linked ACH/Wire/Participant Accounts',
      accounts: [],
    },
    {
      key: 7,
      permission: 'Manage Team Member Permissions',
      accounts: [],
    },
    {
      key: 8,
      permission: 'Receive Statements',
      accounts: [],
    },
  ];
  const [permissions, setPermissions] = useState(defaultPermissionsObject);
  const [accDetails, setAccDetails] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    const filteredData = [];
    accountData[0].accountDetails.length > 0 &&
      accountData[0].accountDetails.map((data) => {
        if (data.status === 'active') {
          filteredData.push(data.accName);
        }
      });
    setAccDetails([...filteredData]);
  }, []);
  useEffect(() => {
    if (addEditAccountObject.selectedRecord) {
      const selectedRecord = addEditAccountObject.selectedRecord;
      setIsAddTeamMember(false);
      form.setFieldsValue({ ...selectedRecord });
      setPermissions(selectedRecord.permissions);
    } else {
      form.setFieldsValue({ permissions: permissions });
    }
  }, [addEditAccountObject, form, permissions]);

  const onFinish = (values) => {
    setMemberData({ ...memberData, ...values });
  };

  const onSubmit = (values) => {
    setSelectAccounts(true);
    onFinish(values);
  };
  const handleBack = () => {
    setAddEditAccountObject({ ...defaultAddEditAccObj });
  };

  const getTitle = () => {
    let label = '';
    if (isTeamMemberCompleted) {
      label = 'Team Member Submitted';
    } else if (isReviewTeamMmber) {
      label = 'Review Team Member';
    } else {
      label = `${isAddTeamMember ? 'Add' : 'Edit'} Team Member`;
    }
    return (
      <>
        <Text
          fontSize="36px"
          color={`${theme.colors.black_secondary}`}
          lineheight="44px"
          label={label}
          icon={
            isTeamMemberCompleted ? (
              <CheckCircleOutlined
                style={{ color: `${theme.colors.pie_green}`, marginRight: '0' }}
              />
            ) : null
          }
          margin={
            isReviewTeamMmber && !isTeamMemberCompleted ? '0 0 32px' : '0 0 8px'
          }
        />
        {isTeamMemberCompleted && (
          <Text
            label="You will be notified when this team member is approved"
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_tertiary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
        )}
        {selectAccounts && !isReviewTeamMmber && (
          <Text
            label="Set user permissions across accounts"
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_tertiary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
        )}
        {isAddTeamMember && !selectAccounts && !isReviewTeamMmber && (
          <Text
            label="Add basic information for team member"
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_tertiary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
        )}
      </>
    );
  };
  return (
    <>
      <OverrideStyle />
      <Box maxwidth="590px" width="100%" margin="0 auto" padding="77px 0 0">
        {getTitle()}
        <Box
          boxcolor={`${theme.colors.white}`}
          padding={
            selectAccounts && !isReviewTeamMmber ? '0 0 48px' : '32px 32px 48px'
          }
          radius="4px"
        >
          {isReviewTeamMmber ? (
            <ReviewTeamMember
              form={form}
              memberData={memberData}
              isTeamMemberCompleted={isTeamMemberCompleted}
              setIsTeamMemberCompleted={setIsTeamMemberCompleted}
              setIsReviewTeamMmber={setIsReviewTeamMmber}
              handleBack={handleBack}
              accDetails={accDetails}
            />
          ) : (
            <>
              {selectAccounts ? (
                <RenderSelectPermissionsComponent
                  setSelectAccounts={setSelectAccounts}
                  setIsReviewTeamMmber={setIsReviewTeamMmber}
                  form={form}
                  onFinish={onFinish}
                  permissions={permissions}
                  setPermissions={setPermissions}
                  accDetails={accDetails}
                />
              ) : (
                <Form
                  form={form}
                  name="basic"
                  layout="vertical"
                  onFinish={onSubmit}
                  requiredMark={false}
                  className="forms"
                >
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item
                        label="Salutation"
                        name="salutation"
                        rules={[
                          {
                            required: true,
                            message: 'Salutation Required',
                          },
                        ]}
                      >
                        <SelectComponent
                          option={[
                            {
                              label: 'Dr.',
                              value: 'Dr',
                            },
                            {
                              label: 'Miss.',
                              value: 'Miss',
                            },
                            {
                              label: 'Mr.',
                              value: 'Mr',
                            },
                            {
                              label: 'Mrs.',
                              value: 'Mrs',
                            },
                            {
                              label: 'Ms.',
                              value: 'Ms',
                            },
                          ]}
                          placeholder="Select Salutation"
                          suffixIcon={<CaretDownOutlined />}
                          dropdownClassName="form-select-dropdown"
                          bordered={true}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: 'First Name Required',
                          },
                        ]}
                      >
                        <InputComponent
                          type="default"
                          placeholder="Add First Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: 'Last Name Required',
                          },
                        ]}
                      >
                        <InputComponent
                          type="default"
                          placeholder="Add Last Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[
                          {
                            validator: (_, value) =>
                              value && validateEmail(value)
                                ? Promise.resolve()
                                : value
                                ? Promise.reject(
                                    'Please enter a valid email address'
                                  )
                                : Promise.reject('Email Address Required'),
                          },
                        ]}
                      >
                        <InputComponent
                          type="default"
                          placeholder="Add Email Address"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Title (Optional)" name="title">
                        <InputComponent
                          type="default"
                          placeholder="Add a Title"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={15}>
                      <Form.Item
                        label="Phone Number (Optional)"
                        name="phone"
                        rules={[
                          {
                            validator: (_, value) =>
                              value && isNumber(value) && value.length === 10
                                ? Promise.resolve()
                                : Promise.reject(
                                    'Please enter a valid phone number'
                                  ),
                          },
                        ]}
                      >
                        <InputComponent
                          type="default"
                          placeholder="Add Phone Number"
                          pattern="[0-9]*"
                          maxLength="10"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item
                        label="Ext (Optional)"
                        name="ext"
                        rules={[
                          {
                            validator: (_, value) =>
                              value && isNumber(value)
                                ? Promise.resolve()
                                : Promise.reject('Please enter a valid ext'),
                          },
                        ]}
                      >
                        <InputComponent
                          type="default"
                          placeholder="Add Ext"
                          maxLength="3"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Mobile Number (Optional)"
                        name="mobile"
                        rules={[
                          {
                            validator: (_, value) =>
                              value && isNumber(value) && value.length === 10
                                ? Promise.resolve()
                                : Promise.reject(
                                    'Please enter a valid mobile number'
                                  ),
                          },
                        ]}
                      >
                        <InputComponent
                          type="default"
                          placeholder="Add Mobile Number"
                          maxLength="10"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Fax Number (Optional)"
                        name="fax"
                        rules={[
                          {
                            validator: (_, value) =>
                              value && isNumber(value) && value.length === 10
                                ? Promise.resolve()
                                : Promise.reject(
                                    'Please enter a valid fax number'
                                  ),
                          },
                        ]}
                      >
                        <InputComponent
                          type="default"
                          placeholder="Add Fax Number"
                          maxLength="10"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <ButtonWrapper size={12} spacing>
                    <ButtonAnt
                      label="Cancel"
                      type="secondary"
                      htmlType="button"
                      onClick={() => handleBack()}
                    />
                    <ButtonAnt label="Next" type="primary" htmlType="submit" />
                  </ButtonWrapper>
                </Form>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AddEditTeamMembers;
