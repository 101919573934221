import React from 'react';
import styled, { css } from 'styled-components';
import { Box, ClearFix } from '../../../styles/globalStyle';
import { theme, Text } from '@pfmam/pfmam';
import moment from 'moment';
import { amountFormat } from '../../../utils/numberFormat';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import NoDataFound from '../../../common/components/noDataFound';
import {
  sortArrayOfObjectsInDesc,
  ModelDisplayCleanup,
} from '../../../utils/common';
const LinkButton = styled(Button)`
  padding: 0;
  display: inline-block;
  font-size: ${theme.font_size.size_xl};
  line-height: 24px;
  color: ${(props) => props.linkTextColor};
  height: 24px;
  border: 0;
  background: none;
  box-shadow: none;
  :hover {
    opacity: 0.6;
    color: ${(props) => props.linkTextColor};
  }
  :focus,
  :active {
    opacity: 1;
    color: ${theme.colors.blue_primay};
    background: none;
  }
  .anticon + span {
    margin-left: 5px;
  }
  ${(props) =>
    props.cancel &&
    css`
      color: ${theme.colors.danger_red};
      :hover {
        opacity: 0.6;
        color: ${theme.colors.danger_red};
      }
      :focus,
      :active {
        opacity: 1;
        color: ${theme.colors.danger_red};
        background: none;
      }
    `}
`;
const ClickOn = styled.button`
  padding: 0;
  border: 0;
  background: none;
  display: block;
  cursor: pointer;
  text-align: unset;
  min-width: 100%;
  max-width: 100%;
  :hover {
    background: linear-gradient(
        0deg,
        ${(props) => props.rgbaPrimaryHover},
        ${(props) => props.rgbaPrimaryHover}
      ),
      ${theme.colors.white};
  }
  &:last-of-type {
    .activity-row {
      border-bottom-color: ${theme.colors.transparent};
    }
  }
`;
const RecentActivity = ({ handleAction, rgbaPrimaryHover }) => {
  const selectedAccount = useSelector(
    (state) => state.accountProfileSlice.selectedAccount
  );
  const activityHistory = useSelector(
    (state) => state.activityHistorySlice.ActivityHistory
  );
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  var Activity = activityHistory.filter(
    (activity) => activity.AccountUniqueID === selectedAccount.AccountUniqueID
  );
  Activity = sortArrayOfObjectsInDesc(Activity, 'SettlementDate').slice(0, 3);
  let value = 0;
  return (
    <>
      {Activity.length > 0 ? (
        Activity.map((activity) => {
          value = amountFormat(activity.TotalAmount);
          return (
            <ClickOn
              rgbaPrimaryHover={rgbaPrimaryHover}
              type="button"
              key={activity.id}
              onClick={() => {
                handleAction('details', activity);
              }}
            >
              <Box
                border={`1px solid ${theme.colors.grey}`}
                borderwidth="0 0 1px 0"
                display="flex"
                padding="20px 0 20px 0 "
                key={activity.id}
                minwidth="100%"
                maxwidth="100%"
                className="activity-row"
              >
                <Box
                  padding="0 13.5px 0 32px"
                  minwidth="75%"
                  maxwidth="75%"
                  textalign="left"
                >
                  <Text
                    fontSize={`${theme.font_size.size_xl}`}
                    color={`${theme.colors.grey_tertiary}`}
                    label={moment(activity.SettlementDate).format(
                      'MMM DD, YYYY'
                    )}
                    margin="0 0 10px 0"
                    lineheight="24px"
                  />
                  <Text
                    fontSize={`${theme.font_size.size_xl}`}
                    color={`${theme.colors.black_secondary}`}
                    label={activity.TransDesc}
                    lineheight="24px"
                  />
                </Box>
                <Box
                  padding="0 32px 0 13.5px"
                  minwidth="25%"
                  maxwidth="25%"
                  textalign="right"
                >
                  {
                    <LinkButton
                      linkTextColor={primary_color}
                      type="link"
                      onClick={() => {
                        handleAction('details', activity);
                      }}
                    >
                      Details
                    </LinkButton>
                  }
                  <ClearFix />
                  <Text
                    fontSize={`${theme.font_size.size_xl}`}
                    color={`${theme.colors.black_secondary}`}
                    label={value}
                    lineheight="24px"
                  />
                </Box>
              </Box>
            </ClickOn>
          );
        })
      ) : (
        <NoDataFound text="No recent activities" padding="32px" />
      )}
    </>
  );
};

export default RecentActivity;
