import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Box } from '../../../styles/globalStyle';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Form, Row, Col, Space } from 'antd';
import {
  theme,
  ButtonAnt,
  InputComponent,
  Text,
  SelectComponent,
} from '@pfmam/pfmam';
import ReviewACHAccounts from './reviewACHAccounts';
import RenderSelectAccountsComponent from '../selectAccounts';
import { CaretDownOutlined } from '@ant-design/icons';

const ButtonWrapper = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 16px;
    `}
`;

const AddEditAccounts = ({
  defaultAddEditAccObj,
  addEditAccountObject,
  setAddEditAccountObject,
}) => {
  const [isAddAccount, setIsAddAccount] = useState(true);
  const [achAccountData, setAchAccountData] = useState(null);
  const [selectAccounts, setSelectAccounts] = useState(false);
  const [isReviewACHAccount, setIsReviewACHAccount] = useState(false);
  const [isACHAccountCompleted, setIsACHAccountCompleted] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (addEditAccountObject.selectedRecord) {
      const selectedRecord = addEditAccountObject.selectedRecord;
      setIsAddAccount(false);
      form.setFieldsValue({ ...selectedRecord });
    }
  }, [addEditAccountObject, form]);

  const onFinish = (values) => {
    setSelectAccounts(true);
    setAchAccountData({ ...achAccountData, ...values });
  };
  const handleBack = () => {
    setAddEditAccountObject({ ...defaultAddEditAccObj });
  };

  const getTitle = () => {
    let label = '';
    if (isACHAccountCompleted) {
      label = 'ACH Account Submitted';
    } else if (isReviewACHAccount) {
      label = 'Review ACH Account ';
    } else {
      label = `${isAddAccount ? 'Add' : 'Edit'} ACH Account`;
    }
    return (
      <>
        <Text
          fontSize="36px"
          color={`${theme.colors.black_secondary}`}
          lineheight="44px"
          label={label}
          icon={
            isACHAccountCompleted ? (
              <CheckCircleOutlined
                style={{ color: `${theme.colors.pie_green}`, marginRight: '0' }}
              />
            ) : null
          }
          margin={isACHAccountCompleted ? '0 0 16px' : '0 0 32px'}
        />
        {isACHAccountCompleted && (
          <Text
            label="You will be notified when this linked account is approved for transactions"
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_tertiary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
        )}
      </>
    );
  };
  return (
    <>
      <Box maxwidth="590px" width="100%" margin="0 auto" padding="77px 0 0">
        {getTitle()}
        <Box
          boxcolor={`${theme.colors.white}`}
          padding="32px 32px 48px"
          radius="4px"
        >
          {isReviewACHAccount ? (
            <ReviewACHAccounts
              form={form}
              achAccountData={achAccountData}
              isACHAccountCompleted={isACHAccountCompleted}
              setIsACHAccountCompleted={setIsACHAccountCompleted}
              setIsReviewACHAccount={setIsReviewACHAccount}
              handleBack={handleBack}
            />
          ) : (
            <>
              {selectAccounts ? (
                <RenderSelectAccountsComponent
                  setSelectAccounts={setSelectAccounts}
                  setIsReviewAccount={setIsReviewACHAccount}
                  form={form}
                  onFinish={onFinish}
                />
              ) : (
                <Form
                  form={form}
                  name="basic"
                  layout="vertical"
                  onFinish={onFinish}
                  requiredMark={false}
                  className="forms"
                >
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item
                        label="Bank Name"
                        name="bankName"
                        rules={[
                          {
                            required: true,
                            message: 'Bank Name Required',
                          },
                        ]}
                      >
                        <InputComponent
                          type="default"
                          placeholder="Add Bank Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Nickname"
                        name="nickName"
                        rules={[
                          {
                            required: true,
                            message: 'Nickname Required',
                          },
                        ]}
                      >
                        <InputComponent
                          type="default"
                          placeholder="Add a Nickname"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Account Number"
                        name="accnumber"
                        rules={[
                          {
                            required: true,
                            message: 'Account Number Required',
                          },
                        ]}
                      >
                        <InputComponent
                          type="default"
                          placeholder="Add Account Number"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="ACH, ABA, or Routing #"
                        name="routing"
                        rules={[
                          {
                            required: true,
                            message: 'Routing Number Required',
                          },
                        ]}
                      >
                        <InputComponent
                          type="default"
                          placeholder="Add Routing Number"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Name on Account"
                        name="nameOnAccount"
                        rules={[
                          {
                            required: true,
                            message: 'Name on Account Required',
                          },
                        ]}
                      >
                        <InputComponent
                          type="default"
                          placeholder="Add a Name on Account"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Account Type"
                        name="acctype"
                        rules={[
                          {
                            required: true,
                            message: 'Account Type Required',
                          },
                        ]}
                      >
                        <SelectComponent
                          option={[
                            {
                              label: 'Checking',
                              value: 'Checking',
                            },
                            {
                              label: 'Savings',
                              value: 'Savings',
                            },
                          ]}
                          placeholder="Select Account Type"
                          suffixIcon={<CaretDownOutlined />}
                          dropdownClassName="form-select-dropdown"
                          bordered={true}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Addendum (Optional)" name="addendum">
                        <InputComponent
                          type="default"
                          placeholder="Add Addendum"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <ButtonWrapper size={12} spacing>
                    <ButtonAnt
                      label="Cancel"
                      type="secondary"
                      htmlType="button"
                      onClick={() => handleBack()}
                    />
                    <ButtonAnt label="Next" type="primary" htmlType="submit" />
                  </ButtonWrapper>
                </Form>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AddEditAccounts;
