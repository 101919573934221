import styled, { css, createGlobalStyle } from 'styled-components';
import { theme } from '@pfmam/pfmam';
import FontRegular from '../assets/fonts/Inter-Regular.ttf';
import FontMedium from '../assets/fonts/Inter-Medium.ttf';
import FontSemiBold from '../assets/fonts/Inter-SemiBold.ttf';
import FontBold from '../assets/fonts/Inter-Bold.ttf';
import BannerGradientImg from '../assets/images/PFM_LinearGraphic.png';
import BannerGradientLargeImg from '../assets/images/Large_Gradient_Background.png';
import { sizes } from './mediaQuery';

export const GlobalStyle = createGlobalStyle`
 
  @font-face {
    font-family: 'Inter';
    src: url(${FontRegular}) format('truetype');
  }
  @font-face {
    font-family: 'Inter Medium';
    src: url(${FontMedium}) format('truetype');
  }
  @font-face {
    font-family: 'Inter Semibold';
    src: url(${FontSemiBold}) format('truetype');
  }
  @font-face {
    font-family: 'Inter Bold';
    src: url(${FontBold}) format('truetype');
  }
   
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html,
  body,
  #root {
    height: 100%;
    width: 100%;
  }
  body {
    font-family: 'Inter';
    font-size: 14px;
    line-height: 20px;
  }

  h1, 
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  ul {
      padding-left: 0;
      list-style-type: none;
  }

  a,
  button {
    outline: none;
    background: none;
    border: 0;
    padding: 0;
    display: inline-block;
  }
  a,
  Link {
    color: ${theme.colors.blue_secondary};
    &:hover {
      opacity: 0.6;
    }
    &:focus,
    &:active {
      opacity: 1;
    }
  }
`;

export const Elevation = styled.div`
  margin-top: ${(props) => props.top};
  position: relative;
  z-index: ${(props) => props.index};
`;
export const Box = styled.div`
  min-width: ${(props) => props.minwidth};
  max-width: ${(props) => props.maxwidth};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => props.boxcolor};
  border-radius: ${(props) => props.radius};
  border: ${(props) => props.border};
  border-width: ${(props) => props.borderwidth};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justifycontent};
  align-items: ${(props) => props.alignitems};
  flex: ${(props) => props.flex};
  text-align: ${(props) => props.textalign};
  z-index: ${(props) => props.zindex};
  position: ${(props) => props.position};
  flex-direction: ${(props) => props.flexdirection};
  flex-wrap: ${(props) => props.flexwrap};
  align-self: ${(props) => props.alingself};
`;

export const ClearFix = styled.div`
  clear: both;
`;
export const Anchor = styled.a`
  font-size: ${(props) => props.fontsize};
  color: ${(props) => props.color};
  line-height: ${(props) => props.lineheight};
  min-width: ${(props) => props.minwidth};
  max-width: ${(props) => props.maxwidth};
  width: ${(props) => props.width};
  background-color: ${(props) => props.boxcolor};
  border-radius: ${(props) => props.radius};
  border: ${(props) => props.border};
  border-width: ${(props) => props.borderwidth};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justifycontent};
  align-items: ${(props) => props.alignitems};
  flex: ${(props) => props.flex};
  text-align: ${(props) => props.textalign};
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: ${(props) => props.color};
  }
`;
export const Banner = styled.div`
  background-color: ${(props) => props.bgcolor};
  height: ${(props) => props.height};
  width: 100%;
  position: relative;
  z-index: 1;
  margin: ${(props) => props.margin};
  &::after {
    content: '';
    background-image: url(${BannerGradientImg});
    background-size: cover;
    background-position: center;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    ${(props) =>
      props.positionrevert &&
      css`
        background-position: revert;
      `}
  }
  ${(props) =>
    props.stripeleft &&
    css`
      &::after {
        background-image: url(${BannerGradientLargeImg});
      }
    `}
`;

export const ClickOn = styled.button`
  padding: 0;
  border: 0;
  background: none;
  display: block;
  cursor: pointer;
  text-align: unset;
  min-width: 100%;
  max-width: 100%;
  :hover {
    background: linear-gradient(
        0deg,
        rgba(23, 54, 93, 0.07),
        rgba(23, 54, 93, 0.07)
      ),
      ${theme.colors.white};
  }
`;
export const Container = styled.div`
  @media ${sizes.mediaQuery('xs', 'xxl')} {
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
  }
  @media ${sizes.mediaQuery('xxl')} {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
  @media ${sizes.mediaQuery('xs', 'xl')} {
    padding-left: 40px;
    padding-right: 40px;
  }
`;
