import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  theme,
  Text,
  TableComponent,
  Indicator,
} from '@pfmam/pfmam';
import { Button, Table } from 'antd';
import { Box } from '../../../styles/globalStyle';
import {
  amountFormat,
  percentFormat,
  numberFormat,
} from '../../../utils/numberFormat';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { colorAllocator } from '../../../utils/common';
import NoDataFound from '../../../common/components/noDataFound';

const LinkButton = styled(Button)`
  padding: 0;
  display: inline-block;
  font-size: ${theme.font_size.size_xl};
  line-height: 24px;
  color: ${theme.colors.blue_primay};
  height: 24px;
  border: 0;
  background: none;
  box-shadow: none;
  padding-right: 16px;
  width: 70px;
  :hover {
    opacity: 0.6;
    color: ${theme.colors.blue_primay};
  }
  :focus,
  :active {
    opacity: 1;
    color: ${theme.colors.blue_primay};
    background: none;
  }
  .anticon + span {
    margin-left: 5px;
  }
  ${(props) =>
    props.cancel &&
    css`
      color: ${theme.colors.danger_red};
      :hover {
        opacity: 0.6;
        color: ${theme.colors.danger_red};
      }
      :focus,
      :active {
        opacity: 1;
        color: ${theme.colors.danger_red};
        background: none;
      }
    `}
`;

const ExpandButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  min-width: 32px;
  cursor: pointer;
  transition: 0.3s;
  & > span {
    height: 19px;
    min-width: 19px;
    width: 19px;
    margin: 0 auto;
    border: 2px solid ${theme.colors.grey};
    border-radius: 2px;
    padding: 5px;
    position: relative;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: '';
      position: absolute;
      top: 3px;
      background: ${theme.colors.black_secondary};
      height: 10px;
      width: 2px;
      transition: transform 0.3s ease-out;
      transform: rotate(0deg);
    }
    &::after {
      content: '';
      position: absolute;
      top: 7px;
      right: 2.3px;
      background: ${theme.colors.black_secondary};
      height: 2px;
      width: 10px;
      transition: transform 0.3s ease-out;
      transform: rotate(-180deg);
    }
  }
  &:focus {
    & > span {
      box-shadow: ${theme.shadows.input_focus};
      border-color: ${theme.colors.blue_primay};
    }
  }
  &.expanded {
    & > span {
      &::after {
        transform: rotate(0deg);
      }
      &::before {
        transform: rotate(90deg);
      }
    }
  }
`;

const SecuritiesTable = ({ data, handleAction }) => {
  console.log(data);
  const [productsData, setProductsData] = useState([...data]);
  const selectedAccount = useSelector(
    (state) => state.accountProfileSlice.selectedAccount
  );
  const programType = selectedAccount.AccountType;

  let columns = [
    {
      title: 'Description/CUSIP',
      dataIndex: 'SectorType',
      key: 'SectorType',
      align: 'left',
      sorter: true,
      className: 'sort-space-none',
      width: 360,
      render: (text, record) => (
        <Box display="flex">
          <Indicator
            bgColor={record.Color}
            height="12px"
            width="12px"
            rounded="20px"
            margin="6px 10px 0 0"
          />
          <Text label={text} />
        </Box>
      ),
    },
    {
      title: 'YTM at Cost',
      dataIndex: '',
      key: 'YtmAtCost',
      align: 'right',
      sorter: true,
      className: 'sort-space-none',
      width: 136,
    },
    {
      title: 'S&P Rating',
      dataIndex: '',
      key: 'S&PRating',
      align: 'right',
      sorter: true,
      className: 'sort-space-none',
      width: 120,
    },
    {
      title: 'Maturity Date',
      dataIndex: '',
      key: 'MaturityDate',
      align: 'right',
      sorter: true,
      className: 'sort-space-none flex-end',
      width: 160,
    },
    {
      title: 'Market Value',
      dataIndex: 'MarketValue',
      key: 'MarketValue',
      align: 'right',
      sorter: true,
      className: 'sort-space-none flex-end',
      width: 170,
      render: (text) => <>{amountFormat(text)}</>,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      width: 110,
    },
  ];

  const getColumns = () => {
    let sharesColumn = {
      title: 'Shares',
      dataIndex: '',
      key: 'Shares',
      align: 'right',
      sorter: true,
      className: 'sort-space-none',
      width: 120,
    };

    let MarketPricePerShareColumn = {
      title: 'Market Price',
      dataIndex: 'MarketPricePerShare',
      key: 'MarketPricePerShare',
      align: 'right',
      sorter: true,
      className: 'sort-space-none',
      width: 120,
      // render: (text) => (text ? <Text label={numberFormat(text)} /> : null),
    };

    if (programType === 'SMA-MACM') {
      columns.splice(1, 3, sharesColumn, MarketPricePerShareColumn);
      return columns;
    } else {
      return columns;
    }
  };
  const getChildColumns = (SectorType) => {
    let sharesColumn = {
      title: 'Shares',
      dataIndex: 'Shares',
      key: 'Shares',
      align: 'right',
      sorter: true,
      className: 'sort-space-none',
      width: 120,
      render: (text) => (text ? <Text label={numberFormat(text)} /> : null),
    };
    let MarketPricePerShareColumn = {
      title: 'Market Price',
      dataIndex: 'MarketPricePerShare',
      key: 'MarketPricePerShare',
      align: 'right',
      sorter: true,
      className: 'sort-space-none',
      width: 120,
      render: (text) => (text ? <Text label={amountFormat(text)} /> : null),
    };
    let childColumns = [
      {
        title: '',
        dataIndex: 'blank_cell',
        key: 'blank_cell',
        align: 'left',
        sorter: true,
        width: 70,
      },
      {
        title: '',
        dataIndex: 'Description',
        key: 'Description',
        align: 'left',
        sorter: true,
        className: 'sort-space-none',
        render: (text, record) => (
          <Box display="flex">
            <Text label={text} />
            <Text
              label={record.CUSIP}
              color={`${theme.colors.grey_tertiary}`}
              margin="0 0 0 10px"
            />
          </Box>
        ),
        width: 330,
      },
      {
        title: '',
        dataIndex: 'YtmAtCost',
        key: 'YtmAtCost',
        align: 'right',
        sorter: true,
        className: 'sort-space-none',
        render: (text) => (
          <>
            {text ? (
              <Box
                display="inline-block"
                boxcolor={`${theme.colors.green_light}`}
                padding="2px 8px"
                radius="2px"
              >
                <Text
                  label={text ? percentFormat(text) : null}
                  fontSize={`${theme.font_size.size_l}`}
                  lineheight="20px"
                />
              </Box>
            ) : null}
          </>
        ),
        width: 136,
      },
      {
        title: '',
        dataIndex: 'S&PRating',
        key: 'S&PRating',
        align: 'right',
        sorter: true,
        className: 'sort-space-none',
        width: 120,
        render: (text) => (text ? <Text label={text} /> : null),
      },
      {
        title: '',
        dataIndex: 'MaturityDate',
        key: 'MaturityDate',
        align: 'right',
        sorter: true,
        className: 'sort-space-none',
        width: 160,
        render: (text) =>
          text ? <Text label={moment(text).format('MMM DD, YYYY')} /> : null,
      },
      {
        title: '',
        dataIndex: 'MarketValue',
        key: 'MarketValue',
        align: 'right',
        sorter: true,
        className: 'sort-space-none flex-end',
        width: 170,
        render: (text) => <>{amountFormat(text)}</>,
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        width: 110,
        render: (_, record) => (
          <>
            {SectorType !== 'Liquid' && (
              <LinkButton
                type="link"
                onClick={() => {
                  SectorType !== 'Liquid' && handleAction(record, 'securities');
                }}
              >
                <>{SectorType !== 'Liquid' ? 'Details' : ''} </>
              </LinkButton>
            )}
          </>
        ),
      },
    ];

    if (programType === 'SMA-MACM') {
      childColumns.splice(2, 3, sharesColumn, MarketPricePerShareColumn);
      return childColumns;
    } else {
      return childColumns;
    }
  };

  const expandable = {
    defaultExpandAllRows: false,
    expandedRowRender: (childRecord) => (
      <Table
        showHeader={false}
        columns={getChildColumns(childRecord.SectorType)}
        dataSource={childRecord.Holdings}
        pagination={false}
        onRow={(record) =>
          childRecord.SectorType !== 'Liquid' && {
            onClick: () => {
              handleAction(record, 'securities');
            },
          }
        }
      />
    ),
    expandIcon: ({ expanded, onExpand, record }) => (
      <ExpandButton
        className={expanded ? 'expanded' : ''}
        onClick={(e) => {
          e.stopPropagation();
          onExpand(record, e);
        }}
      >
        <span></span>
      </ExpandButton>
    ),
  };

  function onChange(pagination, filters, sorter) {
    if (sorter && data) {
      if (sorter.order) {
        const order = sorter.order === 'ascend' ? 'asc' : 'desc';
        const productTypes = [...data];
        productTypes.map((productType, index) => {
          let sortedData;
          if (sorter.columnKey === 'MaturityDate') {
            sortedData = _.orderBy(
              productType.Holdings,
              function (o) {
                return new moment(o.MaturityDate);
              },
              [order]
            );
          } else {
            sortedData = _.orderBy(
              productType.Holdings,
              [sorter.columnKey],
              [order]
            );
          }

          productTypes[index].Holdings = [...sortedData];
        });
        setProductsData([...productTypes]);
      } else {
        setProductsData([...data]);
      }
    }
  }

  return (
    <TableComponent
      className="table-primary"
      title={
        <Box
          display="flex"
          justifycontent="space-between"
          alignitems="center"
          padding="24px 16px 24px 32px"
          border={`1px solid ${theme.colors.grey_fossil}`}
          borderwidth="0 0 1px 0"
        >
          <Text
            label="Securities"
            fontSize={`${theme.font_size.size_3xl}`}
            lineheight="32px"
            color={`${theme.colors.black_tertiary}`}
          />
        </Box>
      }
      columns={getColumns()}
      dataSource={data}
      locale={{
        emptyText: (
          <NoDataFound text="No available securities" padding="18px 16px" />
        ),
      }}
      showSorterTooltip={false}
      pagination={
        data.length > 10
          ? {
              position: ['bottomRight'],
              showQuickJumper: true,
              size: 'default',
            }
          : false
      }
      expandable={expandable}
      onChange={onChange}
    />
  );
};

export default SecuritiesTable;
