import React, { useState, useEffect } from 'react';
import { Layout, Space, Form, Switch } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import {
  theme,
  ButtonAnt,
  SelectComponent,
  TableComponent
} from '@pfmam/pfmam';
import { Container, Box, Banner } from '../styles/globalStyle';
import { CaretDownOutlined } from '@ant-design/icons';
import DividendHistoryTable from './components/dividendHistoryTable';
import LoadingData from '../common/components/LoadingSpinner/LoadingData';
import NoDataFound from '../common/components/noDataFound';
import {
  GetDividendsHistory,
  GetDividendsHistoryMonthYear,
} from '../actions/actions';
import { useSelector, useDispatch } from 'react-redux';


const { Content } = Layout;

const FilterCol = styled(Space)`
  flex: 1;
  white-space: nowrap;
`;

const TableWrapper = styled.div`
  .ant-table {
    border-radius: 4px 4px 0 0;
    .ant-table-tbody {
      & > tr {
        & > td {
          vertical-align: top;
          padding: 10px 22px;
          font-family: 'inter';
          &.ant-table-column-sort {
            background: ${theme.colors.transparent};
          }
        }
       
      }
    }
    .ant-table-thead {
      & > tr {
        & > th {
          background: #eaeaeac7;
          font-family: 'inter';
          padding: 0 22px;
          
          line-height: 40px;
        }
      }
    }
    .ant-table-summary {
      & > tr {
        & > td {
          background: #eaeaeac7;
          font-family: 'inter';
          padding: 0 22px;
          font-weight: bold;
          line-height: 40px;
          font-size:16px;
        }
      }
    }
    
   
  }
  .ant-table-title {
    padding: 0 !important;
    font-family: 'inter';
  }
  .table-primary {
    &:first-child {
      margin-bottom: 24px;
    }
  }
  .ant-table {
    &.ant-table-middle {
      .ant-table-title {
        background-color: ${theme.colors.transparent};
        
      }
      .ant-table-tbody {
        .ant-table-wrapper {
          &:only-child {
            .ant-table {
              margin: -19px -32px;
            }
          }
        }
        .ant-table-container {
          .ant-table-tbody {
            .ant-table-row {
              .ant-table-cell {
                padding: 14px 32px;
                background-color: ${theme.colors.grey};
              }
              &:hover {
                .ant-table-cell {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
`;
const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
  }
`;
const OverrideStyle = createGlobalStyle`
  .ant-form-item-control-input-content {
    & > span {
      width: 180px;
    }
    .primary.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 52px;
        width: 180px;
        background: ${theme.colors.white};
        color: ${theme.colors.black_secondary};
        border: 1px solid ${theme.colors.grey};
        padding: 14px 20px;
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: 20px;
        }
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
      .ant-select-arrow {
        color: ${theme.colors.grey_stone};
        font-size: 17px;
        margin-top: 0;
        top: 45%;
        transform: translateY(-50%);
        right: 20px;
      }
      &.ant-select-focused {
        .ant-select-selector {
          box-shadow: ${theme.shadows.input_focus};
          border-color: ${theme.colors.blue_primay};
        }
          .ant-select-arrow {
          color: ${theme.colors.black_secondary};
        }
      } 
    }
  }
  
  .form-select-dropdown {
    box-shadow:  0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      .ant-select-item {
        &.ant-select-item-group {
          color: ${theme.colors.grey_tertiary};
          min-height: 22px;
          line-height: 22px;
          padding: 6px 12px;
          &:hover {
            background: none;
          }
        }
      }
      .ant-select-item-option-selected,
      .ant-select-item-option-active {
        &:not(.ant-select-item-option-disabled) {
          background: ${theme.colors.white};
          font-weight: normal;
        }
      }
      .ant-select-item {
        min-height: 36px;
        line-height: 20px;
        padding: 8px 12px;
        font-size: ${theme.font_size.size_l};
        color: ${theme.colors.black_secondary};
        font-family: 'Inter';
        &:hover {
        background: ${theme.colors.light_grey};
        font-family: 'Inter';
      }
    }
  }
  .forms {
    .ant-input {
      &.textarea {
        min-height: 108px;
      }
    }
  }
  }
`;
const Wrapper = styled.div`
  padding-top: 22px;
`;

const CustomSwitch = styled(Switch)`
  background-color: ${(props) => props.checked ? props.linkTextColor : 'lightgray'}
`;

const DividendHistoryContainer = (props) => {
  const [dividenData, setDividendData] = useState([]);
  const [filteredDividendData, setFilteredDividendData] = useState([]);
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );

  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const rgbaPrimary = useSelector((state) => state.siteInfo.rgbaPrimary);
  const programId = clientDetails.payload[0].programid;
  const ssnorTIN = clientDetails.payload[0].ssnortin;
  const [yearMonthDropDownKeyValue, setYearMonthDropDownKeyValue] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [selectedYearMonth, setSelectedYearMonth] = useState(null);

  useEffect(() => {
    setInitialValues();
  }, [ssnorTIN]);

  const handleSwitchChange = enable => {
    setSwitchChecked(enable);
    setLoadingData(true);
    GetDividendsHistory(programId, ssnorTIN, selectedYearMonth, enable)
      .then((response) => {
        if (response.data != null) {
          setDividendData(response.data);
          setLoadingData(false);
        } else {
          setLoadingData(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingData(false);
      });
  };

  function dropDownOnChange(value) {
    setLoadingData(true);
    setSelectedYearMonth(value);
    GetDividendsHistory(programId, ssnorTIN, value, switchChecked)
      .then((response) => {
        if (response.data != null) {
          setDividendData(response.data);
          setLoadingData(false);
        } else {
          setLoadingData(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingData(false);
      });
  }

  function setInitialValues() {
    setLoadingData(true);
    var result = [];

    GetDividendsHistoryMonthYear()
      .then((response) => {
        if (response.data != null) {
          response.data.map((data) => {
            result.push({
              label: data.Label,
              value: data.Value
            });
          });
          setYearMonthDropDownKeyValue(result);
          setSelectedYearMonth(result[0].value);

          GetDividendsHistory(programId, ssnorTIN, result[0].value, switchChecked)
            .then((response) => {
              if (response.data != null) {
                setDividendData(response.data);
                setLoadingData(false);
              } else {
                setLoadingData(false);
              }
            })
            .catch((error) => {
              console.log(error);
              setLoadingData(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingData(false);
      });



  }

  return (
    <>
      <OverrideStyle />
      {loadingData === true ? <Container><LoadingData /> </Container> : null}
      {
        dividenData.length > 0 ? (
          <Container>
            <Wrapper>
              <Box margin="0 0 24px"
                display="flex"
                justifycontent="space-between"
                alignitems="center"
                minwidth="100%"
                width="100%">
                <FilterCol size={8} overflow={true}>
                  <Form.Item>
                    <SelectComponent
                      defaultValue={yearMonthDropDownKeyValue.length > 0 ? yearMonthDropDownKeyValue[0].value : ''}
                      option={yearMonthDropDownKeyValue}
                      suffixIcon={
                        <CaretDownOutlined className="ant-select-suffix" />
                      }
                      dropdownClassName="form-select-dropdown"
                      bordered={true}
                      onChange={(value) => dropDownOnChange(value)}
                    />
                  </Form.Item>
                </FilterCol>
                <Box display="flex" alignitems="right">
                  <Form.Item label='Show Inactive Accounts'>
                    <CustomSwitch disabled={loadingData} linkTextColor={primary_color} checked={switchChecked} onChange={handleSwitchChange} />
                  </Form.Item>
                </Box>
              </Box>

            </Wrapper>
            {loadingData === true ? <Container><LoadingData /></Container> : (
              <Box padding='0 0 0 0'>
                {dividenData.map((data, index) => {
                  return (
                    <TableWrapper rgbaPrimaryHover={rgbaPrimary}>
                      <DividendHistoryTable
                        data={data.AdditionalInfo}
                        headerText={data.DisplayName}
                        dividendsYTDHeaderText={data.DividendsYTDHeader}
                        isLastRecord={index + 1 == dividenData.length ? true : false}
                        fullDividendData={dividenData}
                      />
                    </TableWrapper>
                  )
                })}
              </Box>
            )}
          </Container>
        ) : (
            loadingData === false && dividenData.length == 0 ?
              (
                <Container>
                  <Wrapper>
                    <TableComponent
                      className="table-primary"
                      dataSource={[]}
                      locale={{
                        emptyText: <NoDataFound text="No Dividends" padding="12px 0" />,
                      }}
                      showSorterTooltip={false}
                    />
                  </Wrapper>
                </Container>
              ) : null
          )
      }

    </>
  );
};

export default DividendHistoryContainer;
