import React, { useState } from 'react';
import styled from 'styled-components';
import {
  theme,
  Text,
  ButtonAnt,
  InputComponent,
} from '@pfmam/pfmam';
import { Space, Form } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { amountFormat } from '../../utils/numberFormat';
import { Box } from '../../styles/globalStyle';
import ListWrapperComponent from '../../common/components/listWrapper';
import { sizes } from '../../styles/mediaQuery';
import {
  GetPendingTransactionStatus,
  VoidTransaction,
  GetWebUserPassword,
  UpdateAuthenticationAttempt,
} from '../../actions/actions';
import _ from 'lodash';
import { useSelector } from 'react-redux';

const CancelBtn = styled(ButtonAnt)`
  border: 1px solid ${theme.colors.grey};
  border-radius: 2px;
  height: 52px;
  padding: 12px 24px;
  color: ${theme.colors.danger_red};
  font-size: ${theme.font_size.size_xl};
  display: block;
  width: 100%;
  margin-top: 48px;
  &:hover {
    color: ${theme.colors.danger_red};
    border-color: ${theme.colors.grey};
  }
`;
const ButtonWrapper = styled(Space)`
  width: 100%;
  justify-content: flex-end;
  margin-top: 48px;
  @media ${sizes.mediaQuery('xs', 'sm')} {
    flex-direction: column;
    .ant-btn,
    .ant-space-item {
      width: 100%;
    }
  }
`;

const bcrypt = require('bcryptjs');

const CancelModalContent = ({
  selectedRecord,
  isCancelTransaction,
  setIsCancelTransaction,
  handleModalCancel,
  isCancelSuccess,
  setIsCancelSuccess,
}) => {
  const ignoreColumns = [
    'LINK ID',
    'PENDING TRANSACTION TYPE ID',
    'AMOUNT',
    'CANCELLABLE',
    'APPROVED',
    'IS INITIATOR',
  ];
  const programPhoneNumber = useSelector((state) => state.siteInfo.phoneNumber);
  const onFinish = () => {
    setIsCancelSuccess(true);
  };
  return (
    <>
      <Text
        label="Transaction Amount"
        color={`${theme.colors.black_tertiary}`}
        fontSize={`${theme.font_size.size_xl}`}
        lineheight="24px"
        padding="0 0 8px 0"
      />
      <Box
        display="flex"
        alignitems="center"
        margin="0 0 32px 0"
        className="overflow-x-sm"
      >
        <Text
          label={amountFormat(selectedRecord.Amount)}
          color={`${theme.colors.clean_black}`}
          fontSize="36px"
          lineheight="44px"
        />
        <Box
          boxcolor={
            selectedRecord.Status === 'Reversed' || isCancelSuccess
              ? `${theme.colors.pearl_river}`
              : '#FFA940'
          }
          radius="2px"
          padding="2px 8px"
          textalign="center"
          margin="0 0 0 24px"
        >
          <Text
            label={
              selectedRecord.Status === 'Reversed' || isCancelSuccess
                ? 'Cancelled'
                : 'Pending'
            }
            color={`${theme.colors.clean_black}`}
            fontSize={`${theme.font_size.size_l}`}
            lineheight="20px"
          />
        </Box>
      </Box>

      {Object.entries(selectedRecord).map(([key, val]) => {
        key = _.upperCase(key);
        if (key === 'AMOUNT') {
          val = amountFormat(val);
        }
        if (ignoreColumns.includes(key)) {
          return null;
        }
        if (isCancelSuccess && key === 'STATUS') {
          val = 'Reversed';
        }

        return (
          <ListWrapperComponent
            label={key}
            value={val}
            key={key}
            className="auto-width-text"
          />
        );
      })}
      {isCancelSuccess && (
        <ListWrapperComponent
          label="Cancel Date"
          value={moment(selectedRecord.date).format('MMM DD, YYYY')}
          className="auto-width-text"
        />
      )}
      {selectedRecord.Status === 'Reversed' ||
      !selectedRecord.Cancellable ||
      isCancelSuccess ? null : isCancelTransaction ? (
        <Form onFinish={onFinish} validateTrigger="onFinish">
          <Text
            label="Password"
            padding="32px 0 8px 0"
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_l}`}
            lineheight="20px"
          />
          <Form.Item
            name="password"
            rules={[
              //{ required: true, message: 'Please input your password!' },
              {
                validator: (rule, value, callback) => {
                  //Get the users hashed password from DB to compare with the input password.
                  GetWebUserPassword()
                    .then((response) => {
                      bcrypt.compare(value, response.data, (err, res) => {
                        if (!res) {
                          //Update the SerialFailedAuthenticationAttemptCount
                          UpdateAuthenticationAttempt(true);
                          callback('Please enter correct Password.');
                          return;
                        } else {
                          //Reset the SerialFailedAuthenticationAttemptCount
                          UpdateAuthenticationAttempt(false);
                          //Check if the transaction is Approved by any other person. Coz Approved transaction Cannot be reversed.
                          GetPendingTransactionStatus(selectedRecord.LinkID)
                            .then((response) => {
                              if (response.data != null) {
                                var status = response.data.toLowerCase();
                                if (
                                  status === 'approved' ||
                                  status === 'sent to envision'
                                ) {
                                  callback(
                                    'Transaction has already been approved and cannot be Reversed'
                                  );
                                  return;
                                }
                                if (status === 'reversed') {
                                  callback(
                                    'Transaction has already been cancelled'
                                  );
                                  return;
                                }

                                //Void the transaction
                                VoidTransaction(
                                  selectedRecord.PendingTransactionTypeID,
                                  selectedRecord.LinkID
                                )
                                  .then((response) => {
                                    if (response.data === true) {
                                      callback();
                                      return;
                                    }

                                    callback(
                                      'Failed to cancel the transaction. Please try after sometime'
                                    );
                                    return;
                                  })
                                  .catch((error) => {
                                    callback(
                                      'Server Error. Please try after sometime'
                                    );
                                    return;
                                  });
                              }
                            })
                            .catch((error) => {
                              callback(
                                'Server Error. Please try after sometime'
                              );
                              return;
                            });
                        }
                      });
                    })
                    .catch((error) => {
                      callback('Server Error. Please try after sometime');
                      return;
                    });
                },
              },
            ]}
          >
            <InputComponent
              maxLength="25"
              allowClear
              onChange={() => {}}
              placeholder="Enter Password"
              type="password"
              autoFocus
            />
          </Form.Item>

          <Form.Item>
            <ButtonWrapper size={8}>
              <ButtonAnt
                type="secondary"
                label="Don't Cancel"
                onClick={() => handleModalCancel('cancel')}
              />
              <ButtonAnt
                type="danger"
                label="Cancel Transaction"
                htmlType="submit"
              />
            </ButtonWrapper>
          </Form.Item>
        </Form>
      ) : (
        <CancelBtn
          icon={<CloseCircleOutlined />}
          label="Cancel Transaction"
          type="secondary"
          onClick={() => setIsCancelTransaction(true)}
        />
      )}

      {selectedRecord.Approved && selectedRecord.IsInitiator && (
        <>
          <br></br>
          <Text
            label={
              'This transaction can no longer be cancelled via the website. Please contact Client Services at ' +
              programPhoneNumber +
              ' to review cancellation options.'
            }
            color={`${theme.colors.black_tertiary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
            padding="0 0 8px 0"
          />
        </>
      )}
    </>
  );
};

export default CancelModalContent;
