import React from 'react';
import styled from 'styled-components';
import { theme, Text, ButtonAnt } from '@pfmam/pfmam';
import { Box } from '../../styles/globalStyle';
import moment from 'moment';
import format from 'format-number';
import { amountFormat } from '../../utils/numberFormat';
import { EditOutlined } from '@ant-design/icons';

export const EditButton = styled(ButtonAnt)`
  &.ant-btn {
    font-size: ${theme.font_size.size_xl};
    color: ${theme.colors.grey_stone};
    line-height: 24px;
    padding: 0;
    border: 0;
    height: 20px;
    width: 20px;
  }
`;
const OverflowText = styled(Text)`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const getAccInvestmentTitle = (
  accName,
  investmentType,
  showInvestmentTypeModal,
  setShowInvestmentTypeModal,
  amount = null,
  nickName = null
) => {
  return (
    <Box
      display="flex"
      alignitems="center"
      justifycontent="space-between"
      padding="18px 20px"
      border={`1px solid ${theme.colors.grey}`}
      radius="2px"
    >
      <Box flex="1" display="flex" alignitems="center">
        <Box
          minwidth={nickName ? '70%' : '65%'}
          maxwidth={nickName ? '70%' : '65%'}
          padding="0 20px 0 0"
        >
          <Text
            label={accName}
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_tertiary}`}
            lineheight="24px"
          />
          <Text
            label={investmentType}
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.black_secondary}`}
            lineheight="24px"
          />
        </Box>
        {amount!==null && (
          <Box minwidth="35%" maxwidth="35%" padding="0 20px 0 0">
            <Text
              label="Available Balance"
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.grey_tertiary}`}
              lineheight="24px"
            />
            <OverflowText
              label={amountFormat(amount)}
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.black_secondary}`}
              lineheight="24px"
            />
          </Box>
        )}
        {nickName && (
          <Box
            minwidth="30%"
            maxwidth="30%"
            textalign="right"
            padding="0 20px 0 0"
          >
            <Text
              label={nickName}
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.grey_tertiary}`}
              lineheight="24px"
            />
          </Box>
        )}
      </Box>
      <EditButton
        label={false}
        icon={<EditOutlined style={{ color: `${theme.colors.grey_stone}` }} />}
        onClick={() => {
          setShowInvestmentTypeModal(!showInvestmentTypeModal);
        }}
      />
      {/* ADD EDIT ICON  */}
    </Box>
  );
};

export const getParticipantTitle = (
  entityName,
  accNumber,
) => {
  return (
    <Box
      display="flex"
      alignitems="center"
      justifycontent="space-between"
      padding="1px 2px"
      border={`1px solid ${theme.colors.grey}`}
      radius="2px"
      minwidth="30%"
      maxwidth="90%"
    >
        <Box
        >
          <Text
            label={entityName}
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_tertiary}`}           
          />
          <Text
            label={accNumber}
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.black_secondary}`}           
          />
      </Box>
    </Box>
  );
};

export const disabledDate = (current) => {
  // Can not select days after today
  return current < moment().subtract(1, 'd');
};

export const numberFormat = (value) => {
  const number = Number(value);
  return format({ negativeType: 'brackets' })(number);
};

export const validateCheckAmount = (
  value,
  formTotal,
  availableBal,
  field = 'Amount'
) => {
  console.log('abal in func', availableBal);
  const numStr = String(value);
  let length = 0;
  // String Contains Decimal
  if (numStr.includes('.')) {
    length = numStr.split('.')[1].length;
  }

  if(value >= 100000000){
    return Promise.reject(`Amount is greater than highest acceptable amount.`);
  }

  if (length > 2) {
    return Promise.reject(`${field} cannot have more than 2 decimals`);
  } 
  // else if (availableBal && formTotal > availableBal) {
  //   return Promise.reject(`Insufficient Balance`);
  // } 
  else {
    return Promise.resolve();
  }
};
