import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { theme } from '@pfmam/pfmam';
import { CaretDownOutlined, CloseOutlined } from '@ant-design/icons';
import OutsideAlerter from '../../../common/components/outsideAlerter';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector, useDispatch } from 'react-redux';
import {
  storeFilteredProductTypes,
  storeFilteredAccounts,
} from '../../../services/stopPaymentSlice';

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
const DropdownButton = styled.button`
  height: 52px;
  line-height: 50px;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.grey};
  border-radius: 2px;
  cursor: pointer;
  outline: none;
  position: relative;
  z-index: 1;
  padding: 0 12px;
  font-size: ${theme.font_size.size_xl};
  color: ${theme.colors.black_secondary};
  white-space: nowrap;
  .anticon-caret-down {
    color: ${theme.colors.grey_stone};
    margin-left: 14px;
  }
  &.active {
    background: ${(props) => props.selectedBgColor};
    border-color: ${(props) => props.selectedBgColor};
    color: ${(props) => props.selectedColor};
  }
  &:focus {
    box-shadow: ${theme.shadows.input_focus};
    border-color: ${theme.colors.anchor_blue};
    .anticon-caret-down {
      color: ${theme.colors.black_secondary};
    }
  }
`;
const ListWrapper = styled.div`
  position: absolute;
  top: 60px;
  z-index: 1000;
`;
const Lists = styled.ul`
  background: ${theme.colors.white};
  border-radius: ${(props) => props.borderRadius};
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  padding: ${(props) => props.dropdownPadding};
  transition: all 0.25s ease;
  min-width: 320px;
  li {
    padding: 7px 12px;
    cursor: pointer;
    font-size: ${theme.font_size.black_secondary};
    line-height: 22px;
    color: ${theme.colors.black_secondary};
    display: flex;
    align-items: center;
    &:hover {
      background-color: ${theme.colors.grey_faint};
    }
    .ant-checkbox-wrapper {
      display: flex;
      min-width: 100%;
      max-width: 100%;
      color: ${theme.colors.black_secondary};
      .ant-checkbox {
        & + {
          span {
            padding: 0 10px;
          }
        }
      }
    }
    img {
      margin-right: 10px;
    }
  }
`;
const SelectedProgram = styled.span`
  display: inline-block;
  background: ${theme.colors.white};
  font-size: ${theme.font_size.size_l};
  line-height: 20px;
  color: ${theme.colors.black_secondary};
  border-radius: 16px;
  padding: 0 8px;
  margin: 0 12px;
`;
const CloseButton = styled.button`
  padding: 0;
  border: 0;
  background: none;
  font-size: ${theme.font_size.size_m};
  color: ${theme.colors.white};
  cursor: pointer;
  outline: none;
  vertical-align: top;
  width: 16px;
  height: 100%;
  position: relative;
  z-index: 3;
`;

const StopPaymentMultiSelect = ({
  storeArray = '',
  filterClass,
  setFilterClass,
  selectedValues,
  setSelectedValues,
  options,
  buttonLabel,
  height = '300px',
  borderRadius = '4px',
  minHeight = '100px',
  dropdownPadding = '4px 0',
  selectedBgColor = `${theme.colors.anchor_blue}`,
  selectedColor = `${theme.colors.white}`,
  scrollable = true,
}) => {
  const dispatch = useDispatch();

  function setStoreValue() {
    if (storeArray == 'clearAllAccounts') {
      dispatch(storeFilteredAccounts([]));
    }
  }
  const getFilterOptions = () => {
    return (
      <>
        {scrollable ? (
          <Scrollbars
            autoHide={true}
            style={{ height: height, minHeight: minHeight }}
          >
            {options}
          </Scrollbars>
        ) : (
          options
        )}
      </>
    );
  };

  const toggleClass = () => {
    if (filterClass === 'hide') {
      setFilterClass('show');
    } else {
      setFilterClass('hide');
    }
  };

  const handleOutsideClick = () => {
    setFilterClass('hide');
  };
  return (
    <>
      <OutsideAlerter handleOutsideClick={handleOutsideClick}>
        <DropdownWrapper>
          <DropdownButton
            onClick={() => toggleClass()}
            className={selectedValues.length ? 'active' : ''}
            selectedBgColor={selectedBgColor}
            selectedColor={selectedColor}
          >
            {selectedValues.length > 0 ? (
              <>
                {buttonLabel}
                <SelectedProgram>
                  {' '}
                  {selectedValues.length ? selectedValues.length : null}
                </SelectedProgram>
                <CloseButton
                  onClick={() => {
                    setStoreValue();
                    setSelectedValues([]);
                  }}
                >
                  <CloseOutlined />
                </CloseButton>
              </>
            ) : (
              <>
                {buttonLabel}
                <CaretDownOutlined />
              </>
            )}
          </DropdownButton>
          <ListWrapper
            style={
              filterClass === 'hide'
                ? { display: 'none' }
                : { display: 'block' }
            }
          >
            <Lists
              borderRadius={borderRadius}
              dropdownPadding={dropdownPadding}
            >
              {getFilterOptions()}
            </Lists>
          </ListWrapper>
        </DropdownWrapper>
      </OutsideAlerter>
    </>
  );
};

export default StopPaymentMultiSelect;
