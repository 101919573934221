import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { theme, Text } from '@pfmam/pfmam';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
const RightIconButton = styled(Button)`
  height: 40px;
  width: 40px;
  font-size: ${theme.font_size.size_m};
  color: ${theme.colors.black_secondary};
  &:hover,
  &:focus {
    background: ${theme.colors.transparent};
  }
`;
const ProductLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.grey};
  transition: 0.3s;
  &:last-of-type {
    border-bottom: 0;
  }
  &:hover {
    background: linear-gradient(
        0deg,
        ${(props) => props.linkTextColor},
        ${(props) => props.linkTextColor}
      ),
      ${theme.colors.white};
  }
`;

const ProductList = (linkTextColor) => {
  const showTermLine = useSelector(
    (state) => state.siteInfo.showTermInfoOnline
  );
  const showCDLine = useSelector((state) => state.siteInfo.allowCDTransactions);
  return (
    <>
      <ProductLink
        linkTextColor={linkTextColor.linkTextColor}
        to={{ pathname: '/dashboard/current-yields/', productProp: 'liquid' }}
      >
        <Text
          label="Liquid"
          padding="10px 0"
          color={`${theme.colors.grey_tertiary}`}
          fontSize={`${theme.font_size.size_l}`}
        />
        <RightIconButton type="text">
          <RightOutlined />
        </RightIconButton>
      </ProductLink>
      {showTermLine ? (
        <ProductLink
          linkTextColor={linkTextColor.linkTextColor}
          to={{ pathname: '/dashboard/current-yields/', productProp: 'term' }}
        >
          <Text
            label="TERM"
            padding="10px 0"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
          />
          <RightIconButton type="text">
            <RightOutlined />
          </RightIconButton>
        </ProductLink>
      ) : null}
      {showCDLine ? (
        <ProductLink
          linkTextColor={linkTextColor.linkTextColor}
          to={{ pathname: '/dashboard/current-yields/', productProp: 'cd' }}
        >
          <p
            label="CD"
            padding="10px 0"
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_l}`}
            className="cd-label"
          >
            CD
          </p>
          <RightIconButton type="text">
            <RightOutlined />
          </RightIconButton>
        </ProductLink>
      ) : null}
    </>
  );
};

export default ProductList;
