import React, { useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { theme, Text } from '@pfmam/pfmam';
import { Box } from '../styles/globalStyle';
import AppLayout from '../common/layouts/appLayout';
import ContactUsForm from './components/contactUsForm';
import { CheckCircleOutlined } from '@ant-design/icons';
import UnSecureContactContainer from './components/unSecureContactContainer';
import FormSubmittedComponent from './components/formSubmittedComponent';

const OverrideStyle = createGlobalStyle`
  .ant-form-item-control-input-content {
    & > span {
      width: 100%;
    }
    .primary.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 52px;
        width: 100%;
        background: ${theme.colors.white};
        color: ${theme.colors.black_secondary};
        border: 1px solid ${theme.colors.grey};
        padding: 14px 20px;
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: 20px;
        }
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
      .ant-select-arrow {
        color: ${theme.colors.grey_stone};
        font-size: ${theme.font_size.size_l};
        margin-top: 0;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
      }
      &.ant-select-focused {
        .ant-select-selector {
          box-shadow: ${theme.shadows.input_focus};
          border-color: ${theme.colors.blue_primay};
        }
          .ant-select-arrow {
          color: ${theme.colors.black_secondary};
        }
      } 
    }
  }
  
  .form-select-dropdown {
    box-shadow:  0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      .ant-select-item {
        &.ant-select-item-group {
          color: ${theme.colors.grey_tertiary};
          min-height: 16px;
          line-height: 16px;
          padding: 6px 12px;
          &:hover {
            background: none;
          }
        }
      }
      .ant-select-item-option-selected,
      .ant-select-item-option-active {
        &:not(.ant-select-item-option-disabled) {
          background: ${theme.colors.white};
          font-weight: normal;
        }
      }
      .ant-select-item {
        min-height: 36px;
        line-height: 20px;
        padding: 8px 12px;
        font-size: ${theme.font_size.size_l};
        color: ${theme.colors.black_secondary};
        font-family: 'Inter';
        &:hover {
        background: ${theme.colors.light_grey};
        font-family: 'Inter';
      }
    }
  }
  .forms {
    .ant-input {
      &.textarea {
        min-height: 108px;
      }
    }
  }
  .ant-form-item-label {
    & > label {
      .attachment-info {
        font-size: 18px;
        margin-left: 10px;
        color: ${theme.colors.blue_primay};
      }
    }
  } 
  .ant-upload {
    &.ant-upload-drag {
      border-style: solid;
      border-color: ${theme.colors.grey};
      p.ant-upload-drag-icon .anticon {
        color: ${theme.colors.blue_primay};
      }
    }
    &.ant-upload-drag:not(.ant-upload-disabled):hover {
      border-color: ${theme.colors.grey};
    }
    
  }
`;

const SecureContactContainer = ({ isAuthenticatedUser }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  return (
    <>
      <OverrideStyle />
      {isAuthenticatedUser ? (
        <AppLayout>
          <Box padding="80px 0 0" margin="0 auto" maxwidth="590px" width="100%">
            <Text
              label={formSubmitted ? 'Form Submitted' : 'Secure Contact'}
              fontSize="36px"
              lineheight="42px"
              color={`${theme.colors.black_secondary}`}
              margin="0 0 32px"
              icon={
                formSubmitted ? (
                  <CheckCircleOutlined
                    style={{
                      color: `${theme.colors.pie_green}`,
                      marginRight: '0',
                    }}
                  />
                ) : null
              }
            />

            <Box boxcolor={`${theme.colors.white}`} padding="32px" radius="4px">
              {formSubmitted ? (
                <>
                  <FormSubmittedComponent
                    label="Go to Dashboard"
                    link="/dashboard"
                    isAuthenticatedUser={isAuthenticatedUser}
                  />
                </>
              ) : (
                <ContactUsForm
                  isAuthenticatedUser={isAuthenticatedUser}
                  setFormSubmitted={setFormSubmitted}
                />
              )}
            </Box>
          </Box>
        </AppLayout>
      ) : (
        <UnSecureContactContainer
          isAuthenticatedUser={isAuthenticatedUser}
          formSubmitted={formSubmitted}
          setFormSubmitted={setFormSubmitted}
        />
      )}
    </>
  );
};

export default SecureContactContainer;
