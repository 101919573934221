import React from 'react';
import styled from 'styled-components';
import { theme, Text, TableComponent } from '@pfmam/pfmam';
import { Table } from 'antd';
import { Box } from '../../styles/globalStyle';
import { amountFormat } from '../../utils/numberFormat';


const OverrideTableComponent = styled(TableComponent)`
`;



const DividendHistoryTable = ({
  headerText,
  data,
  dividendsYTDHeaderText,
  isLastRecord,
  fullDividendData
}) => {

  const columns = [
    {
      title: 'Investment Type',
      dataIndex: 'FundShortName',
      key: 'FundShortName',
      align: 'left',
      className: 'sort-space-none',
      width: '280px',
    },
    {
      title: 'Closing Value',
      dataIndex: 'ReturnBalance',
      key: 'ReturnBalance',
      align: 'left',
      sorter: false,
      className: 'sort-space-none',
      render: (text) => (
        <>
          {amountFormat(text)}
        </>
      ),
    },
    {
      title: 'Dividends This Month',
      dataIndex: 'MonthlyDividend',
      key: 'MonthlyDividend',
      align: 'left',
      sorter: false,
      className: 'sort-space-none',
      render: (text) => (
        <>
          {amountFormat(text)}
        </>
      ),
    },
    {
      title: dividendsYTDHeaderText,
      dataIndex: 'YTDDividend',
      key: 'YTDDividend',
      align: 'left',
      sorter: false,
      className: 'sort-space-none',
      render: (text) => (
        <>
          {amountFormat(text)}
        </>
      ),
    },
  ];

  return (
    <>
      <OverrideTableComponent
        className="table-primary"
        title={
          <Box
            display="flex"
            justifycontent="space-between"
            alignitems="center"
            padding="24px 16px 24px 32px"
            border={`1px solid ${theme.colors.grey}`}
            borderwidth="1px 1px 1px 1px"
          >
            <Text
              label={headerText}
              fontSize={`${theme.font_size.size_3xl}`}
              lineheight="12px"
              color={`${theme.colors.black_primay}`}
            />
          </Box>
        }
        columns={columns}
        dataSource={data}
        summary={data => {
          let subtotalClosingValue = 0;
          let subtotalDividends = 0;
          let subtotalDividendsYTD = 0;

          let totalClosingValue = 0;
          let totalDividends = 0;
          let totalDividendsYTD = 0;

          data.forEach(({ ReturnBalance, MonthlyDividend, YTDDividend }) => {
            subtotalClosingValue += parseFloat(ReturnBalance);
            subtotalDividends += parseFloat(MonthlyDividend);
            subtotalDividendsYTD += parseFloat(YTDDividend);
          });

          if(isLastRecord){
            fullDividendData.map((data) => {
              data.AdditionalInfo.forEach(({ ReturnBalance, MonthlyDividend, YTDDividend }) => {
                totalClosingValue += parseFloat(ReturnBalance);
                totalDividends += parseFloat(MonthlyDividend);
                totalDividendsYTD += parseFloat(YTDDividend);
              });
            });
          }

          if (!isLastRecord) {
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell>SubTotals</Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {amountFormat(subtotalClosingValue)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {amountFormat(subtotalDividends)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {amountFormat(subtotalDividendsYTD)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          } else {
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell>SubTotals</Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {amountFormat(subtotalClosingValue)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {amountFormat(subtotalDividends)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {amountFormat(subtotalDividendsYTD)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <br/>
                <Table.Summary.Row>
                  <Table.Summary.Cell>Grand Total</Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {amountFormat(totalClosingValue)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {amountFormat(totalDividends)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {amountFormat(totalDividendsYTD)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }

        }}
      />
    </>
  );
};

export default DividendHistoryTable;
