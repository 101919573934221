import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import config from '../config';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import {
    theme,
    Text,
    ButtonAnt,
    Modal,
    Tabs,
    SelectComponent,
} from '@pfmam/pfmam';
import { Container, Box } from '../styles/globalStyle';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { removeNull } from '../utils/common';
import DetailsModalContent from '../common/components/detailsModal';
import DownloadModal from '../common/components/downloadModal';
import InvestmentTypeComponent from '../dashboard/components/holdings/investmentTypeComponent';
import HistoricHoldingSecurityTypeComponent from '../dashboard/components/holdings/historicHoldingSecurityTypeComponent';
import MultiSelect from '../common/components/multiSelect';
import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Space, Table, Form, Switch, Modal as antmodal } from 'antd';
import CheckboxImg from '../../src/assets/images/checkbox-blue.svg';
import CheckboxCheckedImg from '../../src/assets/images/checkbox-blue-checked.svg';
import { GetHistoryHoldings, GetAccountsForHistoryHoldings } from '../actions/actions';

const OverrideStyle = createGlobalStyle`
  .ant-form-item-control-input-content {
    & > span {
      width: 100%;
    }
    .primary.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        min-width: 200px;
        height: 52px;
        width: 100%;
        background: ${theme.colors.white};
        color: ${theme.colors.black_secondary};
        border: 1px solid ${theme.colors.grey};
        padding: 14px 20px;
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: 20px;
        }
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
      .ant-select-arrow {
        font-size: ${theme.font_size.size_l};
        margin-top: 0;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
      }
      &.ant-select-focused {
        .ant-select-selector {
          box-shadow: ${theme.shadows.input_focus};
          border-color: ${theme.colors.blue_primay};
        }
          .ant-select-arrow {
          color: ${theme.colors.black_secondary};
        }
      } 
    }
  }
  
  .form-select-dropdown {
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      .ant-select-item {
        &.ant-select-item-group {
          color: ${theme.colors.grey_tertiary};
          min-height: 16px;
          line-height: 16px;
          padding: 6px 12px;
          &:hover {
            background: none;
          }
        }
      }
      .ant-select-item-option-selected,
      .ant-select-item-option-active {
        &:not(.ant-select-item-option-disabled) {
          background: ${theme.colors.white};
          font-weight: normal;
        }
      }
      .ant-select-item {
        min-height: 40px;
        line-height: 24px;
        padding: 8px 12px;
        font-size: ${theme.font_size.size_xl};
        color: ${theme.colors.black_secondary};
        font-family: 'Inter';
        &:hover {
        background: ${theme.colors.light_grey};
        font-family: 'Inter';
      }
    }
  }
`;

const CustomSwitch = styled(Switch)`
  background-color: ${(props) => props.checked ? props.linkTextColor : 'lightgray'}
`;

const FilterCol = styled(Space)`
  flex: 1;
  white-space: nowrap;
  overflow: ${(props) => (props.overflow ? 'hidden' : '')};
`;

const HistoricalHoldingsContainer = () => {
    const [form] = Form.useForm();
    const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [investTypeHoldings, setInvestTypeHoldings] = useState([]);
    const [investTypeBarDetails, setInvestTypeBarDetails] = useState(null);
    const [investTypeBarDates, setInvestTypeBarDates] = useState(null);
    const [sectorTypeHoldings, setSectorTypeHoldings] = useState([]);
    const [sectorTypeBarDetails, setSectorTypeBarDetails] = useState(null);
    const [sectorTypeBarDates, setSectorTypeBarDates] = useState(null);
    const [inputClassName, setInputClassName] = useState('hide');
    const [accountFilterClass, setAccountFilterClass] = useState('hide');
    const [accountSelectedValues, setAccountSelectedValues] = useState([]);
    const [yearMonthDropdown, setYearMonthDropdown] = useState([]);
    const [selectedYearMonth, setSelectedYearMonth] = useState(null);
    
    const [switchChecked, setSwitchChecked] = useState(false);
    const clientDetails = useSelector(
        (state) => state.programsClientsSlice.selectedClient
    );
    const programId = clientDetails.payload[0].programid;
    const ssnorTIN = clientDetails.payload[0].ssnortin;
    const [programType, setProgramType] = useState(programId == '999' ? 'SMA': "");
    const primary_color = useSelector((state) => state.siteInfo.primaryColor);
    const [displayType, setDisplayType] = useState(null);

    const associatedAccounts = useSelector(
        (state) => state.accountProfileSlice.accountProfile.AccountDetails
    );

       const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('investment_type');
    const [type, setType] = useState(null);
    const [accountDropdownValues, setAccountDropdownValues] = useState([]);

    const handleAction = (data, type) => {
        setType(type);
        setIsDetailsModalVisible(!isDetailsModalVisible);
        setSelectedRecord(data);
    };

    const handleModalCancel = () => {
        setIsDetailsModalVisible(!isDetailsModalVisible);
        setSelectedRecord(null);
    };

    const handleAccountChange = (value) => {
        if (accountSelectedValues.includes(value)) {
            const values = accountSelectedValues.filter(
                (selectedValue) => selectedValue !== value
            );
            setAccountSelectedValues([...values]);
        } else {
            setAccountSelectedValues([...accountSelectedValues, value]);
        }
    };


    const getAccountFilterOptions = () => {
        const filterOptions = [];
        accountDropdownValues.map((account) => {
            filterOptions.push(
                <li
                    key={account.AccountUniqueID}
                    onClick={() => handleAccountChange(account.AccountUniqueID)}
                >
                    <img
                        src={
                            accountSelectedValues.includes(account.AccountUniqueID)
                                ? CheckboxCheckedImg
                                : CheckboxImg
                        }
                        alt="Checkbox"
                    />{' '}
                    {account.DisplayName}
                </li>
            );
        });
        return filterOptions;
    };


    useEffect(() => {
        axios
            .request({
                method: 'GET',
                url:
                    config.BASE_API_URL + 'Holdings/YearMonth'
            })
            .then((response) => {
                setYearMonthDropdown(response.data);
            });
    }, []);

    const showModal = () => {
        setIsDownloadModalVisible(true);
    };

    const handleCancel = () => {
        setIsDownloadModalVisible(false);
    };

    const yearMonthDropdownChange = (value) => {
        setAccountSelectedValues([]);
        setSwitchChecked(false);
        setSelectedYearMonth(value);
        //Get The ShowInactiveAccounts checkbox value

        //LoadAccounts dropdown
        GetAccountsForHistoryHoldings(programId, ssnorTIN, value, false).then((response) => {
            if (response.data.length > 0) {
                setAccountDropdownValues(response.data)
            } else {
                setAccountDropdownValues([]);
            }
        });
    };

    const handleSwitchChange = enable => {
        setAccountSelectedValues([]);
        setSwitchChecked(enable);
        //LoadAccounts dropdown
        GetAccountsForHistoryHoldings(programId, ssnorTIN, selectedYearMonth, enable).then((response) => {
            if (response.data.length > 0) {
                setAccountDropdownValues(response.data)
            } else {
                setAccountDropdownValues([]);
            }
        });
    };

    const searchholdings = () => {
        //Assign the programtype
        var accountDropDownAccounts = accountDropdownValues.filter(item => accountSelectedValues.includes(item.AccountUniqueID));
        var pType = "";
        var displayType = "";
        for (let i = 0; i < accountDropDownAccounts.length; i++) {
            if (accountDropDownAccounts[i].AccountType == "HYBRID") {
                pType = "HYBRID";
                break;
            }
            else if (accountDropDownAccounts[i].AccountType == "SMA-FI" || accountDropDownAccounts[i].AccountType == "SMA-MACM") {
                pType = "SMA";
            }
        }
        setProgramType(pType);

        for (let i = 0; i < accountDropDownAccounts.length; i++) {
           if (accountDropDownAccounts[i].AccountType == "SMA-MACM") {
                displayType = "SMA-MACM";
                break;
            }
        }
        setDisplayType(displayType);
        //Do database call to pull the records.
        GetHistoryHoldings(selectedYearMonth, accountSelectedValues).then((response) => {
            setInvestTypeHoldings(removeNull(response.data.InvTypeClassHold));
            setSectorTypeHoldings(removeNull(response.data.SectorTypeHold));
        });
    }

    const validateClick = () => {
        if (selectedYearMonth == null || accountSelectedValues.length <= 0) {
            //First check if month and accounts are selected
            antmodal.warning({
                title: 'Account and Month dropdowns cannot be empty.',
                content: 'Please select an account and a month from the dropdowns to view the holdings.',
                okText: 'Ok',
            });
        } else {
            searchholdings();
        }
    }

    return (
        <Container>
            <OverrideStyle />
            <Box
                padding="36px 0 28px 0"
                display="flex"
                alignitems="center"
                justifycontent="space-between"
                minwidth="100%"
                width="100%"
            >
                <FilterCol size={8} overflow={inputClassName === 'show'}>

                    <Text
                        label="View Holdings as of"
                        fontSize="26px"
                        lineheight="44px"
                        color={`${theme.colors.black_secondary}`}
                    />
                    <Form.Item>
                        <SelectComponent
                            placeholder="Select Month"
                            option={yearMonthDropdown}
                            suffixIcon={
                                <CaretDownOutlined className="ant-select-suffix" />
                            }
                            dropdownClassName="form-select-dropdown"
                            bordered={true}
                            onChange={(value) => yearMonthDropdownChange(value)}
                        />
                    </Form.Item>
                    <MultiSelect
                        storeArray={'clearAllAccounts'}
                        filterClass={accountFilterClass}
                        setFilterClass={setAccountFilterClass}
                        selectedValues={accountSelectedValues}
                        setSelectedValues={setAccountSelectedValues}
                        options={getAccountFilterOptions()}
                        buttonLabel={'Account'}
                        height={'180px'}
                        selectedBgColor={`${primary_color}`}
                        selectedColor={`${theme.colors.white}`}
                    />
                    <ButtonAnt
                        //icon={<SearchOutlined />}
                        label="View Report"
                        type="secondary"
                        onClick={validateClick}
                    //onClick={searchHoldings()}
                    />
                    <Box display="flex" alignitems="right">
                        <Form.Item label='Include Inactive Accounts'>
                            <CustomSwitch linkTextColor={primary_color} checked={switchChecked} onChange={handleSwitchChange} />
                        </Form.Item>
                    </Box>
                </FilterCol>

                <Box display="flex" alignitems="center">
                    <ButtonAnt
                        icon={<DownloadOutlined />}
                        label="Download"
                        type="secondary"
                        onClick={showModal}
                    />
                </Box>

            </Box>
            <Text 
                        label="The Historical Holdings data is as of close of business of the selected month’s last business day. Only currently open accounts are shown in the Account drop down box, and thus the Historical Holdings report. Holdings data for this report dates back to five years from current Month & Year. For older data, or to view historical holdings for a closed account, see the monthly statement."
                        fontSize="14px"
                        color={`${theme.colors.black_secondary}`}
                    />
            {/* //<Box padding="20px 0px">
      //  <BarCharts details={barDetailsFormatter(barDetails)} date={barDates}  />
      //</Box> */}
            {programType === 'HYBRID' ? (
                <Tabs
                    defaultActiveKey={activeTab}
                    activeKey={activeTab}
                    activeTabColor={primary_color}
                    onTabClick={(key) => setActiveTab(key)}
                    tabsOptions={[
                        {
                            content: (
                                <InvestmentTypeComponent
                                    InvTypeBarDetails={investTypeBarDetails}
                                    InvTypeBarDates={investTypeBarDates}
                                    InvTypeHoldings={investTypeHoldings}
                                    handleAction={handleAction}
                                    isHistoricalHoldings={true}
                                />
                            ),
                            key: 'investment_type',
                            label: 'Investment Type',
                        },
                        {
                            content: (
                                <HistoricHoldingSecurityTypeComponent
                                    SecTypeBarDates={sectorTypeBarDates}
                                    SecTypeBarDetails={sectorTypeBarDetails}
                                    SecTypeHoldings={sectorTypeHoldings}
                                    handleAction={handleAction}
                                    isHistoricalHoldings={true}
                                    programType={displayType}
                                />
                            ),
                            key: 'sector_type',
                            label: 'Sector Type',
                        },
                    ]}
                    type="nav-pills"
                />
            ) : (
                <>
                    {programType === 'SMA' ? (
                        <HistoricHoldingSecurityTypeComponent
                            SecTypeBarDates={sectorTypeBarDates}
                            SecTypeBarDetails={sectorTypeBarDetails}
                            SecTypeHoldings={sectorTypeHoldings}
                            handleAction={handleAction}
                            isHistoricalHoldings={true}
                            programType={displayType}
                        />
                    ) : (
                        <InvestmentTypeComponent
                            InvTypeBarDetails={investTypeBarDetails}
                            InvTypeBarDates={investTypeBarDates}
                            InvTypeHoldings={investTypeHoldings}
                            handleAction={handleAction}
                            isHistoricalHoldings={true}
                        />
                    )}
                </>
            )}

            {isDetailsModalVisible && (
                <Modal
                    title="Holdings Details"
                    width={590}
                    bodyStyle={{
                        padding: '32px 32px 48px 32px',
                    }}
                    maskStyle={{
                        backgroundColor: `${theme.rgba.modal}`,
                        zIndex: '1051',
                    }}
                    content={
                        <DetailsModalContent
                            title={selectedRecord.Description}
                            selectedRecord={selectedRecord}
                        />
                    }
                    visible={isDetailsModalVisible}
                    onCancel={() => handleModalCancel()}
                    footer={null}
                    centered={true}
                />
            )}
            <Modal
                title="Download File Type"
                width={590}
                bodyStyle={{
                    padding: '32px 32px 34px 32px',
                }}
                maskStyle={{
                    backgroundColor: `${theme.rgba.modal}`,
                    zIndex: '1051',
                }}
                content={
                    <DownloadModal
                        downloadData={investTypeHoldings}
                        onCancel={handleCancel}
                        type="HistoricalHoldings"
                    />
                }
                visible={isDownloadModalVisible}
                onCancel={handleCancel}
                footer={null}
                centered={true}
            />
        </Container>
    );
};

export default HistoricalHoldingsContainer;
