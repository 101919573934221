import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../config';

const baseApiUrl = config.BASE_API_URL;

export const initialState = {
  menuPermissionOptions: [],
};

export const getMenuPermissions = createAsyncThunk(
  'menuOptions/getgetMenuPermissions',
  async ({ fundgroup_Id }, { dispatch, getstate }) => {
    const req1 = await axios
      .get(baseApiUrl + 'FundGroups/' + fundgroup_Id + '/TransactionTypeIDs')
      .then((res) => res.data);
    return req1;
  }
);

const menuPermissionSlice = createSlice({
  name: 'menuPermissionInfo',
  initialState,
  reducers: {
    menuPermissions: (state, { payload }) => {
      state.menuPermissionOptions = payload;
    },
  },
  extraReducers: {
    [getMenuPermissions.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getMenuPermissions.fulfilled]: (state, { payload }) => {
      state.menuPermissionOptions = payload;
      state.status = 'success';
    },
    [getMenuPermissions.rejected]: (state, action) => {
      state.status = 'failed';
    },
  },
});

export const { menuPermissions } = menuPermissionSlice.actions;
export default menuPermissionSlice.reducer;
