import React, { useState } from 'react';
import { Box, Container, ClickOn } from '../../../styles/globalStyle';
import {
  Text,
  theme,
  TableComponent,
  ButtonAnt,
  Modal,
} from '@pfmam/pfmam';
import { percentFormat } from '../../../utils/numberFormat';
import moment from 'moment';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MoveFundsModal from '../moveFundsModal';
import config from '../../../config';
const Title = styled(Text)`
  font-weight: bold;
`;

const Wrapper = styled.div`
  padding-top: 40px;
  .ant-btn {
    background-color: ${theme.colors.blue_primay};
    color: ${theme.colors.white};
    height: 34px;
    width: 103px;
    padding: 0px;
    margin-top: 0px;
    border-radius: 2px;
  }
`;

const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
  }
`;

const Liquid = ({ data, date }) => {
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const labelText = date
    ? 'Liquid Seven Day Yields As of ' + moment(date).format('MMM DD,YYYY')
    : 'Liquid Seven Day Yields';

  const [isMoveFundsVisible, setIsMoveFundsVisible] = useState(false);
  const handleModalCancel = () => {
    setIsMoveFundsVisible(!isMoveFundsVisible);
  };

  const liquidColumn = [
    {
      title: 'Name',
      dataIndex: 'FundName',
      key: 'FundName',
      sorter: (a, b) => a.FundName.localeCompare(b.FundName),
      align: 'left',
      className: 'sort-space-none',
      width: '40%',
      render: (text) => (
        <Box display="flex" padding="5px 0px">
          <Text label={text} />
        </Box>
      ),
    },
    {
      title: 'Current Seven-Day Yield',
      dataIndex: 'Yield7BW',
      key: 'Yield7BW',
      align: 'right',
      className: 'sort-space-none',
      width: '40%',
      render: (text) => (
        <>
          {text ? (
            <Box padding="5px 0px">
              <Box
                display="inline-block"
                boxcolor={`${theme.colors.light_blue}`}
                padding="2px 8px"
                radius="2px"
              >
                <Text
                  label={text ? text : null}
                  fontSize={`${theme.font_size.size_l}`}
                  lineheight="20px"
                />
              </Box>
            </Box>
          ) : null}
        </>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      align: 'right',
      className: 'sort-space-none',
      width: '20%',
      render: () => {
        if (config.EnableTransaction) {
          return (
            <OverrideButtonAnt
              icon={false}
              type="button"
              label={'Purchase'}
              linkTextColor={primary_color}
              onClick={() => {
                setIsMoveFundsVisible(true);
              }}
            />
          );
        }
      },
    },
  ];
  return (
    <>
      <Container>
        <Wrapper>
          <TableComponent
            className="table-primary"
            title={
              <Box
                display="flex"
                justifycontent="space-between"
                alignitems="center"
                padding="24px 32px 24px 32px"
                border={`1px solid ${theme.colors.grey_fossil}`}
                borderwidth="0 0 1px 0"
              >
                <Text
                  // label="Liquid Seven-Day Yields"
                  label={labelText}
                  fontSize={`${theme.font_size.size_3xl}`}
                  lineheight="32px"
                  color={`${theme.colors.black_tertiary}`}
                />
              </Box>
            }
            columns={liquidColumn}
            dataSource={data}
            showSorterTooltip={false}
          />
          <Wrapper>
            <Text
              color={`${theme.colors.grey_tertiary}`}
              label={
                'The current seven-day yield, also referred to as the current annualized yield, represents the net change, exclusive of capital change and income other than investment income, in the value of a hypothetical account with a balance of one share (normally $1.00 per share) over a seven-day base period expressed as a percentage of the value of one share at the beginning of the seven-day period. The resulting net change in account value is then annualized by multiplying it by 365 and dividing the result by 7. Past performance is not indicative of future results and yields may vary. The yields shown above may reflect fee waivers by Fund’s current or prior service providers. When such waivers occur, they reduce the total operating expenses of the Trust, and the Trust’s yields would have been lower if there were no such waivers. Refer to Fund’s Information Statement for further information on the expenses of the Trust and fees of its Service Providers.'
              }
            />
          </Wrapper>
          {isMoveFundsVisible && (
            <Modal
              title="Move Funds"
              width={590}
              bodyStyle={{
                padding: '0px 0px 64px 0px',
              }}
              maskStyle={{
                backgroundColor: `${theme.rgba.modal}`,
                zIndex: '1051',
              }}
              content={<MoveFundsModal />}
              visible={isMoveFundsVisible}
              onCancel={() => handleModalCancel()}
              footer={null}
              centered={true}
            />
          )}
        </Wrapper>
      </Container>
    </>
  );
};

export default Liquid;
