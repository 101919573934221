import React from 'react';
import styled from 'styled-components';
import { Alert, Button } from 'antd';
import { theme } from '@pfmam/pfmam';
import ReactHtmlParser from 'react-html-parser';

const LinkButton = styled(Button)`
  padding: 0;
  display: inline-block;
  font-size: ${theme.font_size.size_l};
  line-height: ${(props) => (props.description ? '24px' : '20px')};
  color: #254072;
  height: ${(props) => (props.description ? '24px' : '20px')};
  border: 0;
  background: none;
  box-shadow: none;
  :hover {
    opacity: 0.6;
    color: #254072;
  }
  :focus,
  :active {
    opacity: 1;
    color: #254072;
    background: none;
  }
  .anticon + span {
    margin-left: 5px;
  }
`;
const AlertWrapper = styled(Alert)`
  padding: ${(props) => (props.description ? '16px 32px' : '14px 32px')};
  &.ant-alert-warning {
    background-color: ${(props) => props.bgColor};
    border: ${theme.colors.transparent};
  }
  .ant-alert-message {
    font-size: ${(props) =>
      props.description
        ? `${theme.font_size.size_xl}`
        : `${theme.font_size.size_l}`};
    line-height: ${(props) => (props.description ? '24px' : '20px')};
    color: ${theme.colors.black_secondary};
    margin-bottom: ${(props) => (props.description ? '4px' : '0')};
  }
  .ant-alert-description {
    font-size: ${theme.font_size.size_l};
    line-height: 20px;
    color: ${theme.colors.grey_secondary};
  }
  .ant-alert-icon {
    margin-right: ${(props) => (props.description ? '18px' : '11px')};
    font-size: ${(props) => (props.description ? '21px' : '14px')};
    color: #997d31;
    align-self: center;
  }
  .ant-alert-close-icon {
    margin-left: 24px;
  }
  .ant-alert-action {
    height: ${(props) => (props.description ? 'auto' : '20px')};
  }
`;
const AlertComponent = ({ alertObjects = null }) => {
  return (
    <>
      {alertObjects
        ? alertObjects.map((alertObject) => {
            return (
              <>
                <AlertWrapper
                  className={alertObject.className}
                  bgColor={alertObject.bgColor}
                  message={
                    alertObject.message
                      ? ReactHtmlParser(alertObject.message)
                      : null
                  }
                  description={
                    alertObject.description ? alertObject.description : null
                  }
                  type="warning"
                  banner
                  showIcon
                  icon={alertObject.icon ? alertObject.icon : null}
                  action={
                    <LinkButton
                      type="link"
                      description={
                        alertObject.description ? alertObject.description : null
                      }
                      onClick={() => {
                        alertObject.onViewDetailsClick(alertObject);
                      }}
                    >
                      View Details
                    </LinkButton>
                  }
                />
              </>
            );
          })
        : null}
    </>
  );
};

export default AlertComponent;
