import React, { useEffect } from 'react';
import { signinRedirectCallback } from './services/userService';
import { useHistory } from 'react-router-dom';

function SigninOidc() {
  const history = useHistory();
  useEffect(() => {
    async function signinAsync() {
      await signinRedirectCallback()
      .then((response) => {
        history.push('/');
      })
      .catch((err) => {
        if(err.toString().includes('iat is in the future') || err.toString().includes('exp is in the past')) {
          window.location.href ="/IATError";
        } else {
          history.push('/');
        }
      });
    }
    signinAsync();
  }, [history]);

  return <div></div>;
}

export default SigninOidc;
