import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { theme, Text } from '@pfmam/pfmam';
import { Container, Banner, Box } from '../../styles/globalStyle';
import ContactUsForm from './contactUsForm';
import PublicFooterComponent from '../../common/components/PublicFooter';
import { sizes } from '../../styles/mediaQuery';
import { CheckCircleOutlined } from '@ant-design/icons';
import FormSubmittedComponent from './formSubmittedComponent';
const LayoutWrapper = styled(Layout)`
  background-color: ${theme.colors.light_grey};
  min-height: 100vh;
  @media ${sizes.mediaQuery('xs', 'md')} {
    width: 1024px;
    overflow-x: auto;
  }
`;

const UnSecureContactContainer = ({
  isAuthenticatedUser,
  formSubmitted,
  setFormSubmitted,
}) => {
  return (
    <LayoutWrapper>
      <Banner bgcolor={'#292929'} height={'124px'}>
        <Box
          display="flex"
          alignitems="center"
          height="100%"
          minwidth="100%"
          width="100%"
          zindex="3"
          position="relative"
          padding={'44px 0 36px 0'}
        >
          <Container>
            <Text
              color={`${theme.colors.white}`}
              fontSize="36px"
              lineheight="44px"
              label="Contact Us"
            />
          </Container>
        </Box>
      </Banner>
      <Box
        padding="80px 0 64px 0"
        margin="0 auto"
        maxwidth="590px"
        width="100%"
      >
        {formSubmitted && (
          <Text
            label={'Form Submitted'}
            fontSize="36px"
            lineheight="42px"
            color={`${theme.colors.black_secondary}`}
            margin="0 0 32px"
            icon={
              <CheckCircleOutlined
                style={{
                  color: `${theme.colors.pie_green}`,
                  marginRight: '0',
                }}
              />
            }
          />
        )}
        <Box boxcolor={`${theme.colors.white}`} padding="32px">
          {formSubmitted ? (
            <FormSubmittedComponent
              label="Go to Home"
              link="/"
              isAuthenticatedUser={isAuthenticatedUser}
            />
          ) : (
            <ContactUsForm
              isAuthenticatedUser={isAuthenticatedUser}
              setFormSubmitted={setFormSubmitted}
            />
          )}
        </Box>
      </Box>
      <PublicFooterComponent />
    </LayoutWrapper>
  );
};

export default UnSecureContactContainer;
