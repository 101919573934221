import React, { useState } from 'react';
import { Box } from '../../styles/globalStyle';
import { Space, Radio } from 'antd';
import { theme, Text, ButtonAnt } from '@pfmam/pfmam';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
const GreyBox = styled(Box)`
  &.multi-cntct-mail-content {
    .ant-input-number-input {
      text-align: right;
      padding: 0 2520px;
    }
  }
  .field-wrapper {
    align-items: flex-start;
  }
`;
const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
    margin-top: 15px;
    margin-left: 220px;
  }
`;
const ButtonWrapper = styled(Space)`
  justify-content: space-between;
  width: 100%;
  min-width: 100%;
  margin-top: 16px;
  .primary {
    width: 160px;
  }
  ${(props) =>
        props.spacing &&
        css`
      margin-top: 48px;
    `}
`;

const ButtonWrapperNoMatch = styled(Space)`
  justify-content: center;
  width: 100%;
  min-width: 100%;
  margin-top: 16px;
  .primary {
    width: 160px;
  }
  ${(props) =>
        props.spacing &&
        css`
      margin-top: 48px;
    `}
`;

export const NotAMatch = ({setStep,errorCount }) => {
    const primary_color = useSelector((state) => state.siteInfo.primaryColor);
    const totalCount=6;
    return (
        <>
            <GreyBox
                boxcolor={primary_color}
                className="multi-cntct-mail-content">
                <Box
                    display="flex"
                    alignitems="center"
                    padding="0 0 0 245px"
                >
                    <Text
                        label={"No Match"}
                        fontSize={`${theme.font_size.size_3xl}`}
                        lineheight="42px"
                        color={`${theme.colors.white}`}
                    />
                </Box>
            </GreyBox>
            <Box margin="10px 0px 5px" className="forms">
                <Text
                    label={"Connect did not find a match based on content criteria."}
                    fontSize={`${theme.font_size.size_2xl}`}
                    lineheight="42px"
                    color={`${theme.colors.black_secondary}`}
                />
                 <Text
                    label={"Try to search again or reach out to the Client Services Group for assistance."}
                    fontSize={`${theme.font_size.size_2xl}`}
                    lineheight="32px"
                    color={`${theme.colors.black_secondary}`}
                />
                {errorCount<totalCount && errorCount != 5? <Text
                label={`Note: You have ${totalCount-errorCount} more attempts.After ${totalCount-errorCount} more attempts, 
                              you will be locked and signed out of Connect.`}
                fontSize={`${theme.font_size.size_2xl}`}
                lineheight="30px"
                color={`${theme.colors.danger_red}`}
            />:
            <>
                <Text
                    label={"This is your last attempt and you will be locked and signed out of Connect portal. "}
                    fontSize={`${theme.font_size.size_2xl}`}
                    lineheight="30px"
                    color={`${theme.colors.danger_red}`}
                />
            </>
        }
          <Space size={22}>
            <OverrideButtonAnt
                    linkTextColor={primary_color}  label="Return to Search" type="primary"
                    onClick={() => {
                        setStep(2);
                    }}
                />
            </Space>
          </Box>
        </>
    )
}