import React from 'react';
import styled, { css } from 'styled-components';
import { theme, Text, ButtonAnt } from '@pfmam/pfmam';
import { Space } from 'antd';
import { Box } from '../../../styles/globalStyle';
import ListWrapperComponent from '../../../common/components/listWrapper';

const ListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.grey};
  transition: 0.3s;
  &:last-of-type {
    border-bottom: 0;
  }
  .strong {
    font-family: 'Inter Semibold';
  }
`;
const ButtonWrapper = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
`;

const ReviewWireAccounts = ({
  form,
  wireAccountData,
  isWireAccountCompleted,
  setIsWireAccountCompleted,
  setIsReviewWireAccount,
  handleBack,
}) => {
  return (
    <>
      <Box>
        <ListWrapperComponent
          label="Bank Name"
          value={wireAccountData.bankName}
        />
        <ListWrapperComponent
          label="Bank City"
          value={wireAccountData.bankCity}
        />
        <ListWrapperComponent
          label="Bank State"
          value={wireAccountData.bankState}
        />
        <ListWrapperComponent
          label="Nickname"
          value={wireAccountData.nickName}
        />
        <ListWrapperComponent
          label="Bank Account Number"
          value={wireAccountData.accnumber}
        />
        <ListWrapperComponent
          label="ABA or Routing Number"
          value={wireAccountData.routing}
        />
        <ListWrapperComponent
          label="Beneficiary Name"
          value={wireAccountData.beneficiaryName}
        />
        <ListWrapperComponent
          label="Beneficiary Account Number"
          value={
            wireAccountData.beneficiaryAccNumber
              ? wireAccountData.beneficiaryAccNumber
              : '- - - -'
          }
          minwidth="239px"
          maxwidth="239px"
        />
        <ListWrapperComponent
          label="Beneficiary Details"
          value={
            wireAccountData.beneficiaryDetails
              ? wireAccountData.beneficiaryDetails
              : '- - - -'
          }
        />
        <ListWrapper>
          <Box minwidth="215px" maxwidth="215px" textalign="left">
            <Text
              label="Permissions"
              padding="14px 0 10px 0"
              color={`${theme.colors.grey_tertiary}`}
              fontSize={`${theme.font_size.size_xl}`}
              lineheight="24px"
            />
          </Box>
          <Box flex="1" textalign="right" padding="14px 0 0 16px">
            {wireAccountData.permissions &&
            wireAccountData.permissions.length ? (
              wireAccountData.permissions.map((permission) => {
                return (
                  <Text
                    label={permission}
                    color={`${theme.colors.black_secondary}`}
                    fontSize={`${theme.font_size.size_xl}`}
                    margin="0 0 4px 0"
                  />
                );
              })
            ) : (
              <Text
                label="- - - -"
                padding="14px 0 14px 0"
                color={`${theme.colors.black_secondary}`}
                fontSize={`${theme.font_size.size_xl}`}
              />
            )}
          </Box>
        </ListWrapper>
      </Box>
      <ButtonWrapper size={12} spacing>
        <ButtonAnt
          label={
            isWireAccountCompleted ? 'Back to Organizational Settings' : 'Back'
          }
          type="secondary"
          htmlType="button"
          onClick={() => {
            if (isWireAccountCompleted) {
              form.resetFields();
              handleBack();
            } else {
              setIsReviewWireAccount(false);
            }
          }}
        />
        {!isWireAccountCompleted && (
          <ButtonAnt
            label={'Submit'}
            type="primary"
            htmlType="submit"
            onClick={() => setIsWireAccountCompleted(true)}
          />
        )}
      </ButtonWrapper>
    </>
  );
};

export default ReviewWireAccounts;
