import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme, Text, Modal } from '@pfmam/pfmam';
import { Row, Col, Layout } from 'antd';
import moment from 'moment';
import { CardAnt, ButtonAnt } from '@pfmam/pfmam';
import { Container } from '../styles/globalStyle';
import AccountInformationCard from './components/summary/accountInformationCard';
import DividendsCard from './components/summary/dividendCard';
import ProductHoldingTable from './components/summary/productHoldings';
import RecentActivity from './components/summary/recentActivity';
import { Box } from '../styles/globalStyle';
import CancelModalContent from '../common/components/cancelModal';
import activityHistory from '../data/ActivityHistory.json';
import DetailsModalContent from '../common/components/detailsModal';
import { GetAccountMonthlySummary, GetFiscalYear } from '../actions/actions';
import { useSelector } from 'react-redux';
import { sizes } from '../styles/mediaQuery';
const { Content } = Layout;
const ContentWrapper = styled(Content)`
  .product-card {
    &:first-child {
      margin-bottom: 24px;
    }
    .ant-card-head {
      padding: 0 32px;
      font-weight: normal;
    }
    .ant-card-head-title {
      font-size: ${theme.font_size.size_3xl};
      color: ${theme.colors.black_tertiary};
      line-height: 32px;
      padding: 24px 0;
    }
    .ant-card-body {
      padding: 24px 32px 40px;
    }
    &.ant-card {
      border-radius: 4px;
      border: 0;
    }
  }

  .pending-activity-card {
    .ant-card-body {
      padding: 0;
    }
  }
  .divider-horizontal {
    border-left: 1px solid ${theme.colors.grey_fossil};
  }
  .cd-label {
    color: ${theme.colors.grey_tertiary};
    font-size: ${theme.font_size.size_l};
    line-height: 24px;
    padding: 10px 0;
    .anticon {
      margin-left: 8px;
    }
  }
  .balance-summary-text {
    color: ${theme.colors.black_tertiary};
    font-size: ${theme.font_size.size_xl};
    line-height: 24px;
    margin: 0 0 9px 0;
    .anticon {
      color: ${theme.colors.grey_tertiary};
      margin-left: 5px;
    }
    @media ${sizes.mediaQuery('xs', 'xxl')} {
      font-size: 13px;
    }
  }
  .actual-balance {
    @media ${sizes.mediaQuery('xs', 'xxl')} {
      font-size: 30px;
    }
  }
  .highcharts-title {
    font-size: 30px !important;
    line-height: 40px;
  }
`;

const CardStyled = styled(CardAnt)`
  .ant-card {
    border: none;
    .ant-card-bordered {
      border: none;
    }
    .product-card {
      border: none;
    }
  }
`;

const Title = styled.span`
  lineheight: 32px;
  color: ${theme.colors.black_tertiary};
  font-size: 24px;
`;

const TitleWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-width: 0 0 1px 0;
`;

const TableWrapper = styled.div`
  .ant-table {
    border-radius: 4px 4px 0 0;
    .ant-table-tbody {
      & > tr {
        &:hover {
          &.ant-table-row:not(.ant-table-expanded-row) {
            background: linear-gradient(
                0deg,
                ${(props) => props.rgbaPrimaryHover},
                ${(props) => props.rgbaPrimaryHover}
              ),
              ${theme.colors.white};
          }
        }
        cursor: pointer;
        & > td {
          vertical-align: top;
          padding: 20px 32px;
          &.ant-table-column-sort {
            background: ${theme.colors.transparent};
          }
        }
        &:last-child {
          td {
            border-bottom-color: ${theme.colors.transparent};
          }
        }
      }
    }
    .ant-table-thead {
      & > tr {
        & > th {
          background: ${theme.colors.light_grey};
          font-family: 'Inter';
          padding: 0 32px;
          font-weight: normal;
          line-height: 20px;
          &:not(:last-child) {
            &:not(.ant-table-selection-column) {
              &:not(.ant-table-row-expand-icon-cell) {
                &:not([colspan]) {
                  &::before {
                    background-color: ${theme.colors.transparent};
                  }
                }
              }
            }
          }
        }
      }
    }
    &.ant-table.ant-table-middle {
      .ant-table-thead {
        .ant-table-column-sorters {
          padding: 0;
          .ant-table-column-sorter-inner .anticon {
            color: ${theme.colors.grey_stone};
          }
          .ant-table-column-sorter-up.active,
          .ant-table-column-sorter-down.active {
            color: ${theme.colors.black_sorted};
          }
        }
        .ant-table-column-sorter-inner {
          margin-top: 0;
        }
      }
    }
    .sort-space-none {
      .ant-table-column-sorters {
        justify-content: flex-start;
      }
      .ant-table-column-title {
        flex: 0 0 auto;
      }
      &.flex-end {
        .ant-table-column-sorters {
          justify-content: flex-end;
        }
      }
    }
  }
  .ant-table-title {
    padding: 0 !important;
  }
  .table-primary {
    &:first-child {
      margin-bottom: 24px;
    }
  }
`;

const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    color: ${(props) => props.linkTextColor};
    :hover {
      color: ${(props) => props.linkTextColor};
      opacity: 0.6;
    }
  }
`;
function getCurrentFiscalYear(endOfMonth) {
  //get current date
  var today = new Date();
  var fiscalYr = '';
  //get current month
  var curMonth = today.getMonth() + 1;
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  if (endOfMonth != 12 && curMonth > endOfMonth) {
    var nextYr = today.getFullYear().toString();
    fiscalYr = 'Since ' + monthNames[endOfMonth] + ' 1, ' + nextYr;
  } else if (endOfMonth == 12) {
    var nextYr = today.getFullYear().toString();
    fiscalYr = 'Since ' + 'Jan 1, ' + nextYr;
  } else {
    var nextYr = (today.getFullYear() - 1).toString();
    fiscalYr = 'Since ' + monthNames[endOfMonth] + ' 1, ' + nextYr;
  }
  return fiscalYr;
}
const SummaryContainer = ({
  accData,
  setActiveTab,
  linkTextColor,
  rgbaPrimaryHover,
}) => {
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isCancelTransaction, setIsCancelTransaction] = useState(false);
  const [isCancelSuccess, setIsCancelSuccess] = useState(false);
  const [monthlyAccountInfo, setmonthlyAccountInfo] = useState([]);
  const [fiscalyear, setFiscalYear] = useState(null);
  const selectedClients = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const showMoveFunds = useSelector(
    (state) => state.siteInfo.allowOnlineTransact
  );
  const selectedAccount = useSelector(
    (state) => state.accountProfileSlice.selectedAccount
  );

  const handleAction = (type, data, rowClick = false) => {
    if (type === 'cancel') {
      setIsCancelModalVisible(!isCancelModalVisible);
      !rowClick && setIsCancelTransaction(true);
    }
    if (type === 'details') {
      setIsDetailsModalVisible(!isDetailsModalVisible);
    }
    setSelectedRecord(data);
  };

  useEffect(() => {
    const ssnorTin = selectedClients.payload[0].ssnortin;
    GetFiscalYear(ssnorTin)
      .then((response) => {
        if (response.data != null) {
          setFiscalYear(getCurrentFiscalYear(response.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const programId = selectedClients.payload[0].programid;
    const ssnorTin = selectedClients.payload[0].ssnortin;
    var month = new Date().getMonth() + 1;
    month = month < 10 ? '0' + month : month;
    var year = new Date().getFullYear();
    const convertDate = year + '' + month;
    GetAccountMonthlySummary(programId, ssnorTin, convertDate)
      .then((response) => {
        if (response.data != null) {
          setmonthlyAccountInfo(
            response.data.filter(
              (acc) => acc.AccountNumber === selectedAccount.AccNumber
            )
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleModalCancel = (type) => {
    if (type === 'cancel') {
      setIsCancelModalVisible(!isCancelModalVisible);
      setIsCancelTransaction(false);
      setIsCancelSuccess(false);
    }
    if (type === 'details') {
      setIsDetailsModalVisible(!isDetailsModalVisible);
    }
    setSelectedRecord(null);
  };
  return (
    <Container>
      <Text
        label="Summary"
        padding="40px 0 32px 0"
        fontSize="36px"
        lineheight="44px"
        color={`${theme.colors.black_secondary}`}
      />
      <Row gutter={20}>
        <Col span={16}>
          <TableWrapper rgbaPrimaryHover={rgbaPrimaryHover}>
            <ProductHoldingTable setActiveTab={setActiveTab} />

            <ContentWrapper>
              <CardStyled
                cardContent={
                  <RecentActivity
                    rgbaPrimaryHover={rgbaPrimaryHover}
                    handleAction={handleAction}
                    //Activity={accData.activityHistory}
                  />
                }
                className="ant-card basic-card product-card pending-activity-card"
                title={
                  <>
                    <TitleWrapper>
                      <Title>Recent Activity</Title>
                      <OverrideButtonAnt
                        linkTextColor={linkTextColor}
                        type="link"
                        label="View All Activity"
                        onClick={() => setActiveTab('2')}
                      />
                    </TitleWrapper>
                  </>
                }
              />
            </ContentWrapper>
          </TableWrapper>
        </Col>
        <Col span={8}>
          {showMoveFunds ? (
            <CardAnt
              cardContent={
                <DividendsCard monthlyAccountInfo={monthlyAccountInfo} />
              }
              className="basic-card cards"
              title={
                <Box>
                  <Text label="Fiscal YTD Dividends" />

                  <Text
                    label={fiscalyear}
                    fontSize={`${theme.font_size.size_xl}`}
                    color={`${theme.colors.grey_tertiary}`}
                    lineheight="24px"
                    margin="3px 0 0"
                  />
                </Box>
              }
            />
          ) : null}

          <CardAnt
            cardContent={<AccountInformationCard />}
            className="basic-card cards"
            title="Account Information"
          />
        </Col>
      </Row>
      {isCancelModalVisible && (
        <Modal
          title={
            isCancelSuccess
              ? 'Transaction Cancelled'
              : isCancelTransaction
              ? 'Cancel Transaction?'
              : 'Activity Details'
          }
          content={
            <CancelModalContent
              selectedRecord={selectedRecord}
              isCancelTransaction={isCancelTransaction}
              setIsCancelTransaction={setIsCancelTransaction}
              handleModalCancel={handleModalCancel}
              isCancelSuccess={isCancelSuccess}
              setIsCancelSuccess={setIsCancelSuccess}
            />
          }
          visible={isCancelModalVisible}
          onCancel={() => handleModalCancel('cancel')}
          footer={null}
          width={590}
          bodyStyle={{
            padding: '32px 32px 48px 32px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          centered={true}
        />
      )}
      {isDetailsModalVisible && (
        <Modal
          title="Activity Details"
          width={590}
          bodyStyle={{
            padding: '32px 32px 48px 32px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          content={<DetailsModalContent selectedRecord={selectedRecord} />}
          visible={isDetailsModalVisible}
          onCancel={() => handleModalCancel('details')}
          footer={null}
          centered={true}
        />
      )}
    </Container>
  );
};

export default SummaryContainer;
