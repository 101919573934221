import React, { useState } from 'react';
import styled from 'styled-components';
import { theme, ButtonAnt, Text } from '@pfmam/pfmam';
import { Space, Radio,Row } from 'antd';
import { sizes } from '../../styles/mediaQuery';
import { Box } from '../../styles/globalStyle';
import { useSelector } from 'react-redux';
import axios from 'axios';


const ButtonWrapper = styled(Space)`
  width: 100%;
  justify-content: flex-end;
  margin-top: 48px;
  @media ${sizes.mediaQuery('xs', 'sm')} {
    flex-direction: column;
    .ant-btn,
    .ant-space-item {
      width: 100%;
    }
  }
`;
const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
  }
`;

const ThirdPartyCheckComponentModal = ({thirdpartyOptionsData,selectedThirdPartyRecordOption,setThirdPartySelectedRecordOption,handleThirdPartySubmit,handleModalCancel}) => {
  const primaryColor = useSelector((state) => state.siteInfo.primaryColor);
  const CancelOnClick = () => {
    handleModalCancel();
  };
  function onChangeValue(current) {
    setThirdPartySelectedRecordOption(current.target.value);
  }
  return (
    <>
      <Text
        label="Email Domain entered does not match any domains on file. Certify one of the below."
        fontSize={`${theme.font_size.size_xl}`}
        color={`${theme.colors.grey_tertiary}`}
        lineheight="24px"
      />
    <Box margin="16px 0 32px" className="forms">
      <Radio.Group onChange={(e) => onChangeValue(e)}>
            <Space direction="vertical">
              {thirdpartyOptionsData.map((user) => 
              (
                <Row>
                  <Radio key={user.ThirdPartyCheckOptionsID} value={user.ThirdPartyCheckOptionsID}>{user.ThirdPartySubject}</Radio>
                </Row>
              ))}
            </Space>
          </Radio.Group>
          <ButtonWrapper size={12}>
        <ButtonAnt
          type="secondary"
          label="Cancel"
          onClick={() => CancelOnClick()}
        />
        <OverrideButtonAnt
          type="primary"
          label="Confirm"
          htmlType="submit"
          linkTextColor={primaryColor}
          onClick={(e) => {
            handleThirdPartySubmit(selectedThirdPartyRecordOption)
          }}
        />
      </ButtonWrapper>
      </Box>
    </>
  );
};

export default ThirdPartyCheckComponentModal;
