import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from '@pfmam/pfmam';
import { Box } from '../../../styles/globalStyle';
import HistoricHoldingSecuritiesTable from './historicHoldingSecuritiesTable';
import securityTypes from '../../../data/securityTypes.json';
import BarCharts from './barChart';
import holdingsHistory from '../../../data/HoldingsHistory.json';
// import { barDetailsFormatter } from '../../../utils/common';
import moment from 'moment';
import _ from 'lodash';

const TableWrapper = styled.div`
  .ant-table {
    &.ant-table-middle {
      border-radius: 4px;
      background: ${theme.colors.white};
    }
    .ant-table-tbody {
      & > tr {
        &:hover {
          &.ant-table-row {
            background: linear-gradient(
                0deg,
                rgba(23, 54, 93, 0.07),
                rgba(23, 54, 93, 0.07)
              ),
              ${theme.colors.white};
          }
        }
        cursor: pointer;
        & > td:not(.ant-table-expanded-row .ant-table-cell) {
          vertical-align: middle;
          padding: 16px;
          border-bottom-color: ${theme.colors.grey_fossil};
          &.ant-table-column-sort {
            background: ${theme.colors.transparent};
          }
        }
      }
    }
    .ant-table-thead {
      & > tr {
        & > th {
          background: ${theme.colors.light_grey};
          font-family: 'Inter';
          padding: 0 16px;
          font-weight: normal;
          line-height: 20px;
          &:not(:last-child) {
            &:not(.ant-table-selection-column) {
              &:not(.ant-table-row-expand-icon-cell) {
                &:not([colspan]) {
                  &::before {
                    background-color: ${theme.colors.transparent};
                  }
                }
              }
            }
          }
        }
      }
    }
    &.ant-table.ant-table-middle {
      .ant-table-thead {
        .ant-table-column-sorters {
          padding: 0;
          .ant-table-column-sorter-inner .anticon {
            color: ${theme.colors.grey_stone};
          }
          .ant-table-column-sorter-up.active,
          .ant-table-column-sorter-down.active {
            color: ${theme.colors.black_sorted};
          }
        }
        .ant-table-column-sorter-inner {
          margin-top: 0;
        }
      }
      .ant-table-row-expand-icon-cell {
        vertical-align: middle;
      }
    }
    .sort-space-none {
      .ant-table-column-sorters {
        justify-content: flex-start;
      }
      .ant-table-column-title {
        flex: 0 0 auto;
      }
      &.flex-end {
        .ant-table-column-sorters {
          justify-content: flex-end;
        }
      }
    }
  }
  .ant-table-title {
    padding: 0 !important;
  }
  .table-primary {
    &:first-child {
      margin-bottom: 24px;
    }
  }
  .ant-table {
    &.ant-table-middle {
      .ant-table-tbody {
        .ant-table-wrapper {
          &:only-child {
            .ant-table {
              margin: -19px -16px;
            }
          }
        }
        .ant-table-container {
          .ant-table-tbody {
            .ant-table-row {
              .ant-table-cell {
                padding: 14px 16px;
                background-color: ${theme.colors.grey_faint};
                vertical-align: top;
              }
              &:hover {
                .ant-table-cell {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const HistoricHoldingSecurityTypeComponent = ({
  SecTypeBarDetails,
  SecTypeBarDates,
  SecTypeHoldings,
  handleAction,
  isHistoricalHoldings = false,
  programType
}) => {
  // let barDates = [];
  // holdingsHistory.dates.forEach((val) => {
  //   barDates.push(moment(val).format('MMM YYYY'));
  // });
  // const security = _.cloneDeep(holdingsHistory.security);
  const [barDetails, setbarDetails] = useState(null);
  const [SectorTypeHoldings, setSectorTypeHoldings] = useState(null);
  const [barDates, setbarDates] = useState(null);

  useEffect(() => {
    setbarDetails(SecTypeBarDetails);
    setbarDates(SecTypeBarDates);
    setSectorTypeHoldings(SecTypeHoldings);
  }, [SecTypeBarDetails, SecTypeBarDates, SecTypeHoldings]);

  return (
    <>
      {!isHistoricalHoldings && barDetails !== null && barDates !== null && (
        <BarCharts details={barDetails} date={barDates} selected="Sector" />
      )}
      {SectorTypeHoldings !== null && (
        <Box margin="32px 0 0 0">
          <TableWrapper>
            <HistoricHoldingSecuritiesTable
              data={SectorTypeHoldings}
              handleAction={handleAction}
              programType= {programType}
            />
          </TableWrapper>
        </Box>
      )}
    </>
  );
};

export default HistoricHoldingSecurityTypeComponent;
