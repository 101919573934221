import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signinRedirect, signoutRedirect } from '../services/userService';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import {GetContactProfileCheck} from '../actions/actions'
import { getCookie } from '../utils/common';
import { MFARoute } from './mfaAuthentication';
import RestrictToProfileScreen from '../profile/restrictToProfileScreen';
import ReactGA from 'react-ga';
import config from '../config';

const userStore = new WebStorageStateStore({ store: window.localStorage });

const authconfig = {
  authority: config.AUTH_URL,
  client_id: '1',
  redirect_uri: config.CONNECT_REDIRECT_URI,
  response_type: 'id_token token',
  scope: 'openid profile api1 email',
  post_logout_redirect_uri: config.CONNECT_POSTLOGOUT_REDIRECT_URI,
  userStore,
};

const userManager = new UserManager(authconfig);

function ProtectedRoute({ children, component: Component, ...rest }) {
  ReactGA.initialize(config.RecaptchaTag);
  ReactGA.pageview(window.location.pathname + window.location.search);
  const bcrypt = require('bcryptjs');
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSlice.user);
  const { REACT_APP_SecureAuthCookie } = process.env;
  let isLoginCookiePresent = bcrypt.compareSync('true', getCookie('AuthLog'));
  let clientSignIn = getCookie('CSI');
  // let mfaPassed = true;
  let mfaPassed = bcrypt.compareSync(
    REACT_APP_SecureAuthCookie,
    getCookie('CSG12')
  );
  
  
  if (user != null && isLoginCookiePresent && user.profile.NewExperience==="False") {
    window.localStorage.clear();
    window.location.replace(config.EON_URL);
    return null;
  }

  if (clientSignIn == "" && isLoginCookiePresent) {
    userManager.clearStaleState();
    userManager.removeUser();
    dispatch({ type: "SIGNOUT_REQUEST" });
    signinRedirect();
  }

  if (user != null && !isLoginCookiePresent) {
    signoutRedirect(user.id_token, dispatch);
  }
  if (user != null && isLoginCookiePresent && mfaPassed && user.profile.contactInformationInComplete==="False" ) {
    return <Route {...rest} component={Component} />;
  } 
  else if (user != null && isLoginCookiePresent && mfaPassed && user.profile.contactInformationInComplete==="True") {
    return  <Route {...rest}  render={(props) => <RestrictToProfileScreen  {...props} /> } />
  }
  else if (user != null && isLoginCookiePresent && !mfaPassed) {
    return <MFARoute user={user} />;
  } else {
    return <Redirect to={'/login'} />;
  }
}

export default ProtectedRoute;
