import React from 'react';
import styled from 'styled-components';
import { theme, Text } from '@pfmam/pfmam';

const ListWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.grey};
  transition: 0.3s;
  padding: 8px 0;
  &:last-of-type {
    border-bottom: 0;
  }
  .strong {
    font-family: 'Inter Semibold';
  }
`;
const ListWrapperComponent = ({ label, value, className = null }) => {
  return (
    <ListWrapper>
      <Text
        label={label}
        color={`${theme.colors.grey_tertiary}`}
        fontSize={`${theme.font_size.size_l}`}
        lineheight="24px"
        className={className}
      />
      <Text
        label={value}
        color={`${theme.colors.black_secondary}`}
        fontSize={`${theme.font_size.size_l}`}
        lineheight="24px"
        className={className}
      />
    </ListWrapper>
  );
};

export default ListWrapperComponent;
