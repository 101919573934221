import React from 'react';
import styled from 'styled-components';
import { Box } from '../../styles/globalStyle';
import { theme, Text } from '@pfmam/pfmam';
import { Link } from 'react-router-dom';
import PurchaseICon from '../../assets/images/Transaction Icons/Purchase.png';
import WirePurchaseICon from '../../assets/images/Transaction Icons/transfer-icon.png';
import RedeemICon from '../../assets/images/Transaction Icons/Redeem.png';
import TransferICon from '../../assets/images/Transaction Icons/Transfer.png';
import { IconBg } from '../../transactionPages/moveFunds';
import { useSelector } from 'react-redux';
const Title = styled(Text) `
  font-family: 'Inter Semibold';
`;
const MoveFundsModal = () => {

  const showACHPurchase = useSelector((state) =>
    state.menuPermissions.menuPermissionOptions.includes(1) && window.location.pathname != '/purchase'
  );
  const showWireNotification = useSelector((state) =>
    state.menuPermissions.menuPermissionOptions.includes(3) && window.location.pathname != '/wire-notification'
  );
  const showRedemption = useSelector((state) =>
    (state.menuPermissions.menuPermissionOptions.includes(2) || state.menuPermissions.menuPermissionOptions.includes(4)) && window.location.pathname != '/redemption'
  );

  const showTransfer = useSelector((state) =>
    (state.menuPermissions.menuPermissionOptions.includes(5) || state.menuPermissions.menuPermissionOptions.includes(7) || state.menuPermissions.menuPermissionOptions.includes(25)) && 
    window.location.pathname != '/transfer'
  );

  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  return (
    <>
    {showACHPurchase && (
      <Link
        to={{
          pathname: '/purchase',
        }}
        style={{ display: 'block', width: '100%', opacity: '1' }}
      >
        <Box
          border={`1px solid ${theme.colors.grey}`}
          borderwidth="0 0 1px 0"
          display="flex"
          padding="20px 32px 24px"
          minwidth="100%"
          maxwidth="100%"
        >
          <IconBg bgColor={`${primary_color}`}>
            <img src={PurchaseICon} alt="Purchase" />
          </IconBg>
          <Box flex="1">
            <Title
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.black_tertiary}`}
              label={'ACH Purchase'}
              lineheight="24px"
              margin="0 0 4px"
            />
            <Text
              fontSize={`${theme.font_size.size_l}`}
              color={`${theme.colors.grey_tertiary}`}
              label={
                'Invest money into the Fund via ACH from another institution'
              }
              lineheight="20px"
            />
          </Box>
        </Box>
      </Link>
    )}

    {showWireNotification && (
      <Link
        to={{
          pathname: '/wire-notification',
        }}
        style={{ display: 'block', width: '100%', opacity: '1' }}
      >
        <Box
          border={`1px solid ${theme.colors.grey}`}
          borderwidth="0 0 1px 0"
          display="flex"
          padding="20px 32px 24px"
          minwidth="100%"
          maxwidth="100%"
        >
          <IconBg bgColor={`${primary_color}`}>
            <img src={WirePurchaseICon} alt="Wire Purchase" />
          </IconBg>
          <Box flex="1">
            <Title
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.black_tertiary}`}
              label={'Wire Purchase Notification'}
              lineheight="24px"
              margin="0 0 4px"
            />
            <Text
              fontSize={`${theme.font_size.size_l}`}
              color={`${theme.colors.grey_tertiary}`}
              label={
                'Notify the Fund of an incoming Wire sent from another institution'
              }
              lineheight="20px"
            />
          </Box>
        </Box>
      </Link>
    )}

    {showRedemption && (

      <Link
        to={{
          pathname: '/redemption',
        }}
        style={{ display: 'block', width: '100%', opacity: '1' }}
      >
        <Box
          border={`1px solid ${theme.colors.grey}`}
          borderwidth="0 0 1px 0"
          display="flex"
          padding="20px 32px 24px"
          minwidth="100%"
          maxwidth="100%"
        >
          <IconBg bgColor={`${primary_color}`}>
            <img src={RedeemICon} alt="Redeem" />
          </IconBg>
          <Box flex="1">
            <Title
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.black_tertiary}`}
              label={'Redeem'}
              lineheight="24px"
              margin="0 0 4px"
            />
            <Text
              fontSize={`${theme.font_size.size_l}`}
              color={`${theme.colors.grey_tertiary}`}
              label={
                'Send money out of the Fund by ACH or Wire to another institution'
              }
              lineheight="20px"
            />
          </Box>
        </Box>
      </Link>
    )}
    {showTransfer && (
      <Link
        to={{
          pathname: '/transfer',
        }}
        style={{ display: 'block', width: '100%', opacity: '1' }}
      >
        <Box
          border={`1px solid ${theme.colors.grey}`}
          borderwidth="0 0 1px 0"
          display="flex"
          padding="20px 32px 24px"
          minwidth="100%"
          maxwidth="100%"
        >
          <IconBg bgColor={`${primary_color}`}>
            <img src={TransferICon} alt="Transfer" />
          </IconBg>
          <Box flex="1">
            <Title
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.black_tertiary}`}
              label={'Transfer'}
              lineheight="24px"
              margin="0 0 4px"
            />
            <Text
              fontSize={`${theme.font_size.size_l}`}
              color={`${theme.colors.grey_tertiary}`}
              label={'Move money to different accounts within the fund'}
              lineheight="20px"
            />
          </Box>
        </Box>
      </Link>
    )}
    </>
  );
};

export default MoveFundsModal;
