/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  theme,
  Charts,
  SelectComponent,
} from '@pfmam/pfmam';
import {
  colorAllocator,
  sortArrayOfObjectsInDesc,
  objCleaner,
  aggregator,
} from '../../utils/common';
import { CaretDownOutlined } from '@ant-design/icons';
import { Box } from '../../styles/globalStyle';
import _ from 'lodash';
import { thousandsSeperator } from '../../utils/common';

const DonutChartComponent = ({ accountDetails, programType }) => {
  const [breakdownType, setBreakdownType] = useState();
  const [donutData, setDonutData] = useState([]);
  const [donutLen, setDonutLen] = useState(0);

  const [accData, setAccData] = useState([]);
  const [accLen, setAccLen] = useState(0);
  const [prodData, setprodData] = useState([]);
  const [prodLen, setProdLen] = useState(0);

  let noDataFound = [
    {
      accName: 'NA',
      y: 0.00001,
      color: `${theme.colors.grey_stone}`,
    },
  ];

  let dropdownLabel1 =
    programType === 'fixed-sma' || programType === 'macm-sma'
      ? 'Investment Types'
      : 'Account Breakdown';
  let dropdownLabel2 =
    programType === 'fixed-sma' || programType === 'macm-sma'
      ? 'Security Type'
      : 'Investment Breakdown';

  const options = [
    { label: dropdownLabel1, value: 'account_breakdown' },
    { label: dropdownLabel2, value: 'investment_breakdown' },
  ];

  useEffect(() => {
      let keep = ['AccName', 'CurrentBalance', 'y'];

      setAccLen(accountDetails.AccountDetails.length);
      let accDetails = _.cloneDeep(accountDetails.AccountDetails);

      accDetails = sortArrayOfObjectsInDesc(accDetails, 'CurrentBalance');
      accDetails = objCleaner(accDetails, keep);
      accDetails =
        accDetails.length > 6
          ? aggregator(accDetails)
          : colorAllocator(accDetails);

      // accDetails = colorAllocator(accDetails);
      setAccData([...accDetails]);

      setProdLen(accountDetails.ProductDetails.length);
      let prodDetails = _.cloneDeep(accountDetails.ProductDetails);

      prodDetails = sortArrayOfObjectsInDesc(prodDetails, 'CurrentBalance');
      prodDetails = objCleaner(prodDetails, keep);
      prodDetails =
        prodDetails.length > 6
          ? aggregator(prodDetails)
          : colorAllocator(prodDetails);
      // prodDetails = colorAllocator(prodDetails);
      setprodData([...prodDetails]);

      setBreakdownType('account_breakdown');
  }, [accLen, prodLen, accountDetails]);

  useEffect(() => {
    if (breakdownType === 'account_breakdown') {
      setDonutData([...accData]);
      setDonutLen(accLen);
    } else if (breakdownType === 'investment_breakdown') {
      setDonutData([...prodData]);
      setDonutLen(prodLen);
    }
  }, [breakdownType, accLen, prodLen, accountDetails]);

  const getLabelText = () => {
    if (
      breakdownType === 'account_breakdown' &&
      dropdownLabel1 === 'Account Breakdown'
    ) {
      return donutData.length > 1 ? 'Accounts' : 'Account';
    } else if (
      breakdownType === 'account_breakdown' &&
      dropdownLabel1 === 'Investment Types'
    ) {
      return donutData.length > 1 ? 'Investment Types' : 'Investment Type';
    } else if (
      breakdownType === 'investment_breakdown' &&
      dropdownLabel1 === 'Investment Breakdown'
    ) {
      return donutData.length > 1 ? 'Products' : 'Product';
    } else if (
      breakdownType === 'investment_breakdown' &&
      dropdownLabel1 === 'Security Type'
    ) {
      return donutData.length > 1 ? 'Accounts' : 'Account';
    } else {
      return donutData.length > 1 ? 'Accounts' : 'Account';
    }
  };

  const donutChartOptions = {
    chart: {
      height: '240px',
      type: 'pie',
      style: {
        fontFamily: 'Inter',
      },
    },
    title: {
      text:
        `<div style="margin-top: 40px;">` +
        `<span style="font-size:48px; font-weight:400; margin-bottom:-70px;">` +
        `${donutLen}` +
        `</span>` +
        `<br />` +
        `<span style="font-size:12px; font-weight:400;">` +
        `${getLabelText()}` +
        `</span>` +
        `</div>`,
      align: 'center',
      verticalAlign: 'middle',
      y: 40,
    },
    plotOptions: {
      pie: {
        innerSize: '80%',
        size: '80%',
      },
      series: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      donutData.length > 0 ? { data: donutData } : { data: noDataFound },
    ],
    tooltip: {
      width: '129px',
      height: '68px',
      shadow: false,
      borderWidth: 0,
      backgroundColor: 'rgba(255,255,255,0)',
      borderColor: null,
      useHTML: true,
      style: {
        color: `${theme.colors.black_secondary}`,
        fontSize: '12px',
      },
      formatter: function () {
        var point = this.point;
        var percent = point.percentage.toFixed(1);
        var text =
          '<div style = "background-color:white; border: 1px solid #DFDFDF; border-radius: 4px; box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08);">' +
          '<div style = "padding:8px">' +
          '<span style="color:#595959; margin-left:4px; font-size:12px; font-weight:400;line-height:2em;">' +
          (accountDetails.AccountDetails.length>0 ? point.AccName: 'N/A') +
          ':' +
          '</span>' +
          '<span style="color:#595959; margin-left:4px; font-size:12px; font-weight:600;margin-right:8px">' +
          (accountDetails.AccountDetails.length>0? percent: "0.00") +
          ' %' +
          '</span>' +
          '<br>' +
          '<span style="color:black; margin-left:4px; font-size:14px; line-height:2em;font-weight:400;margin-right:8px; margin-top: 5px;">' +
          (accountDetails.AccountDetails.length>0? thousandsSeperator(point.CurrentBalance): "0.00") +
          '</span>' +
          '</div>' +
          '</div>';
        return text;
      },
    },
  };

  return (
    <>
      <Box display="flex" justifycontent="center" padding="30px 0 0 0">
        <SelectComponent
          value={breakdownType}
          option={options}
          onChange={(value) => setBreakdownType(value)}
          suffixIcon={<CaretDownOutlined className="ant-select-suffix" />}
          dropdownClassName="primary-select"
        />
      </Box>
      <Charts options={donutChartOptions} />
    </>
  );
};

export default DonutChartComponent;
