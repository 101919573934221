import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { theme, Text, TableComponent } from '@pfmam/pfmam';
import { Button } from 'antd';
import { Box } from '../../styles/globalStyle';
import { CloseCircleOutlined } from '@ant-design/icons';
import { amountFormat } from '../../utils/numberFormat';
import moment from 'moment';
import NoDataFound from '../../common/components/noDataFound';
import { useSelector } from 'react-redux';

const OverrideTableComponent = styled(TableComponent)`
  .ant-pagination .ant-pagination-options-quick-jumper input:hover {
    border-color: ${(props) => props.rgbaHover};
  }
  .ant-pagination .ant-pagination-options-quick-jumper input {
    color: ${(props) => props.rgbaHover};
  }
  .ant-pagination .ant-pagination-item.ant-pagination-item-active a,
  .ant-pagination .ant-pagination-item:hover a {
    color: ${(props) => props.rgbaHover};
  }
  .ant-pagination .ant-pagination-item.ant-pagination-item-active,
  .ant-pagination .ant-pagination-item:hover {
    border-color: ${(props) => props.rgbaHover};
  }
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: ${(props) => props.rgbaHover};
  }
`;

const LinkButton = styled(Button)`
  padding: 0;
  display: inline-block;
  font-size: ${theme.font_size.size_xl};
  line-height: 24px;
  color: ${(props) => props.linkTextColor};
  height: 24px;
  border: 0;
  background: none;
  box-shadow: none;
  :hover {
    opacity: 0.6;
    color: ${(props) => props.linkTextColor};
  }
  :focus,
  :active {
    opacity: 1;
    color: ${theme.colors.blue_primay};
    background: none;
  }
  .anticon + span {
    margin-left: 5px;
  }
  ${(props) =>
    props.cancel &&
    css`
      color: ${theme.colors.danger_red};
      :hover {
        opacity: 0.6;
        color: ${theme.colors.danger_red};
      }
      :focus,
      :active {
        opacity: 1;
        color: ${theme.colors.danger_red};
        background: none;
      }
    `}
`;

const Status = styled.p`
  color: ${theme.colors.grey_tertiary};
`;

const CancelledText = () => {
  return (
    <Text
      label="Cancelled"
      color={`${theme.colors.grey_tertiary}`}
      fontSize={`${theme.font_size.size_xl}`}
      lineheight="24px"
    />
  );
};

const PendingActivityTable = ({
  isGlobalActivityHistoryPage,
  data,
  handleAction,
  type,
  linkTextColor,
}) => {
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);

  const columns = [
    {
      title: 'Settlement Date',
      dataIndex: 'Date',
      key: 'Date',
      align: 'left',
      sorter: (a, b) => moment(a.Date).unix() - moment(b.Date).unix(),
      className: 'sort-space-none',
      width: '180px',
      render: (text) => <Text label={moment(text).format('MMM DD, YYYY')} />,
    },
    {
      title: 'Description',
      dataIndex: 'Details',
      key: 'Details',
      align: 'left',
      sorter: false,
      className: 'sort-space-none',
      render: (text, record) => (
        <>
          {text} <Status>{record.Comments}</Status>
        </>
      ),
    },
    {
      title: 'Total Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      align: 'right',
      sorter: (a, b) => a.Amount - b.Amount,
      className: 'sort-space-none flex-end',
      render: (text) => <>{amountFormat(text)}</>,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <>
          {type === 'pending' &&
            (record.Cancelled && record.IsInitiator ? (
              <CancelledText />
            ) : (
              [
                record.Cancellable ? (
                  <LinkButton
                    type="link"
                    cancel
                    onClick={() => {
                      handleAction('cancel', record);
                    }}
                  >
                    <CloseCircleOutlined />
                    Cancel
                  </LinkButton>
                ) : (
                  <LinkButton
                    linkTextColor={primary_color}
                    type="link"
                    onClick={() => {
                      handleAction('cancel', record);
                    }}
                  >
                    Details
                  </LinkButton>
                ),
              ]
            ))}
        </>
      ),
    },
  ];

  return (
    <>
      <OverrideTableComponent
        rgbaHover={linkTextColor}
        className="table-primary"
        title={
          <Box
            display="flex"
            justifycontent="space-between"
            alignitems="center"
            padding="24px 16px 24px 32px"
            border={`1px solid ${theme.colors.grey_fossil}`}
            borderwidth="0 0 1px 0"
          >
            <Text
              label={type === 'pending' ? 'Pending' : 'History'}
              fontSize={`${theme.font_size.size_3xl}`}
              lineheight="32px"
              color={`${theme.colors.black_tertiary}`}
            />
          </Box>
        }
        columns={columns}
        dataSource={data}
        locale={{
          emptyText: (
            <NoDataFound
              text={
                type === 'pending'
                  ? 'No pending activity'
                  : 'No activity history'
              }
              padding="12px 0"
            />
          ),
        }}
        showSorterTooltip={false}
        pagination={
          data.length > 10
            ? {
                position: ['bottomRight'],
                showQuickJumper: true,
                size: 'default',
              }
            : false
        }
        onRow={(record) => ({
          onClick: () => {
            type === 'completed'
              ? handleAction('details', record)
              : handleAction('cancel', record, true);
          },
        })}
      />
    </>
  );
};

export default PendingActivityTable;
