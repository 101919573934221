import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { theme, Text, ButtonAnt, Modal } from '@pfmam/pfmam';
import { Space } from 'antd';
import { Box } from '../../styles/globalStyle';
import ListWrapperComponent from '../../common/components/listWrapper';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { amountFormat } from '../../utils/numberFormat';
import { CheckCircleOutlined, PrinterOutlined } from '@ant-design/icons';
import { Prompt } from 'react-router';
import { SubmitWireTransaction, GetTransactionDefaultDay, IsDuplicateTransactionSingleAccount } from '../../actions/actions';
import DuplicateTransactionModalContent from '../common/duplicateTransactionModalContent';
import { useHistory } from 'react-router-dom';
const ButtonWrapper = styled(Space) `
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
  &.done-button-wrapper {
    .primary {
      width: 120px;
    }
  }
`;
const RegularText = styled.span`
  color: ${(props) => props.color};
  font-size: 16px;
  line-height: 24px;  
`;
const Wrapper = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;
const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
  }
`;
const SendWireButton = styled(ButtonAnt) `
  font-size: ${theme.font_size.size_xl};
  margin: 0 0 32px;
  color: ${(props) => props.color};
  padding: 0;
  height: auto;
  line-height: 24px;
  border: 0;
  &:hover {
    color: ${(props) => props.color};
  }
  ${(props) =>
    props.spacing &&
    css`
      padding: 0 0 0 54px;
    `}
  ${(props) =>
    props.spacingnone &&
    css`
      margin: 0;
    `}
`;

const ReviewForm = ({
  form,
  formData,
  amount,
  selected,
  setSelected,
  selectedPage,
  setFormType,
  showSendWireModal,
  setShowSendWireModal,
}) => {
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);
  const [errorsubmitted, setErrorsubmitted] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [cutoffTimeDetails, setCutOffTimeDetails] = useState({
    isCutOffTimePassed: false,
    curOffTime: ''
  });
  const [isDuplicateTransModalVisible, setIsDuplicateTransModalVisible] = useState(false);
  const [duplicateTransData, setDuplicateTransData] = useState([]);

  const duplicateTransColumns = [
    {
      title: 'Confirmation #',
      dataIndex: 'Confirm #',
      key: 'Confirm #',
      width: 180,
      align: 'left',
    },
    {
      title: 'Account Number',
      dataIndex: 'Account Number',
      key: 'Account Number',
      width: 210,
      align: 'left',
      render: (text, record) => (
        <>
        <Text
          label={text}
          color={`${theme.colors.black_secondary}`}
          fontSize={`${theme.font_size.size_xl}`}
          lineheight="24px"
        />
        </>
      ),
    },
    {
      title: 'Posting Date',
      dataIndex: 'Posting Date',
      key: 'Posting Date',
      width: 212,
      align: 'left',
      render: (text, record) => (
        <>
        <Text
          label={moment(text).format('MMM DD, YYYY')}
          color={`${theme.colors.black_secondary}`}
          fontSize={`${theme.font_size.size_xl}`}
          lineheight="24px"
        />
         </>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'Created Date',
      key: 'Created Date',
      width: 212,
      align: 'left',
      render: (text, record) => (
        <>
        <Text
          label={moment(text).format('MMM DD, YYYY h:mm A')}
          color={`${theme.colors.black_secondary}`}
          fontSize={`${theme.font_size.size_xl}`}
          lineheight="24px"
        />
        <Text
          label={record['Created By']}
          color={`${theme.colors.grey_tertiary}`}
          fontSize={`${theme.font_size.size_xl}`}
          margin="4px 0 0"
          lineheight="20px"
        />
        </>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      width: 148,
      align: 'right',
      render: (text) => <>{amountFormat(text)}</>,
    },
  ];

  const ValidateDuplicateTransaction = () => {
    if(!submitted){
    setDisableSubmit(true);
    IsDuplicateTransactionSingleAccount(formData.date.format('YYYY-MM-DD'), formData.accountinvestmentType.AccountUniqueID, amount, 3)
      .then((response) => {
        if (response.data !== null && response.data.Table.length > 0) {
          setDuplicateTransData(response.data.Table);
          setIsDuplicateTransModalVisible(true);
        }
        else {
          VerifyCutOffTime();
        }
      })
      .catch((error) => {
        setDisableSubmit(false);
        console.log(error);
      });
    } else {
      history.push('/dashboard');
    }
  }

  const handleDuplicateModalSubmit = () => {
    setIsDuplicateTransModalVisible(false);
    VerifyCutOffTime();
  };

  const handleDuplicateModalCancel = () => {
    setIsDuplicateTransModalVisible(false);
    setDisableSubmit(false);
  };

  function onDuplicateModalCancel() {
    setIsDuplicateTransModalVisible(false);
    setDisableSubmit(false);
    setDuplicateTransData([]);
  }

  function onErrorModalCancel() {
    setErrorsubmitted(false);
    setDisableSubmit(false);
  }
  const VerifyCutOffTime = () => {
    setDisableSubmit(true);
    setCutOffTimeDetails({ isCutOffTimePassed: false, curOffTime: '' });
    var PendingTransactionTypeClassID = 3  // 3 Indicates Wire notification
    GetTransactionDefaultDay(formData.accountinvestmentType.type.FundUniqueID, PendingTransactionTypeClassID)
      .then((response) => {
        if (response.data !== null) {
          let defaultDay = new Date(response.data);
          defaultDay.setHours(0, 0, 0, 0);
          let transEntryDateString = formData.date.format('MMM DD, YYYY');
          let transEntryDate = new Date(transEntryDateString);
          transEntryDate.setHours(0, 0, 0, 0);
          if (transEntryDate < defaultDay) {
            setCutOffTimeDetails({ isCutOffTimePassed: true, curOffTime: response.data });
            setDisableSubmit(false);
          } else {
            submitWireNotification();
          }
        }
      }
      ).catch((error) => {
        setDisableSubmit(false);
        console.log(error);
      });
  }


  const submitWireNotification = () => {
    if (!submitted) {
      SubmitWireTransaction(formData.date.format('YYYY-MM-DD'), amount, formData.bankAccount, formData.accountinvestmentType.type.FundUniqueID, formData.accountinvestmentType.AccountUniqueID) // 3 Indicates Wire notification
        .then((response) => {
          if (response.data !== null && response.data.length > 0 && response.data !== "0") {
            setConfirmation(response.data);
            setDisableSubmit(false);
            setSubmitted(true);
          }
          else{
            setErrorsubmitted(true);
          }
        })
        .catch((error) => {
          setDisableSubmit(false);
          setErrorsubmitted(true);
          console.log(error);
        });
    }
    //submitted ? window.history.back() : setSubmitted(true);
   // submitted ? history.push('/dashboard') : setSubmitted(true);
  }

  return (
    <>
    <Prompt
      when={submitted === false}
      message={JSON.stringify({
        header: 'Discard Transaction?',
        content:
          'Leaving this page will discard this transaction. Are you sure you want to discard this transaction?',
      })}
    />
    <Box display="flex" alignitems="flex-start">
      {submitted && (
        <CheckCircleOutlined
          style={{
            color: `${theme.colors.pie_green}`,
            fontSize: '38px',
            marginTop: '6px',
          }}
        />
      )}
      <Text
        fontSize="36px"
        color={`${theme.colors.black_secondary}`}
        lineheight="44px"
        label={
          submitted
            ? 'Wire Purchase Notification Submitted'
            : 'Review Wire Purchase Notification'
        }
        icon={false}
        margin={submitted ? '0 0 8px 15px' : '0 0 15px'}
      />
    </Box>
    {submitted ? (
      <SendWireButton
        type="link"
        label="Where should my bank send the wire?"
        icon={false}
        color={`${theme.colors.blue_primay}`}
        spacing
        onClick={() => {
          setShowSendWireModal(!showSendWireModal);
        }}
      />
    ) : (
        <SendWireButton
          type="link"
          label="Where should my bank send the wire?"
          icon={false}
          color={`${theme.colors.blue_primay}`}
          onClick={() => {
            setShowSendWireModal(!showSendWireModal);
          }}
        />
      )}

    <Box
      boxcolor={`${theme.colors.white}`}
      padding="32px 32px 48px"
      radius="4px"
    >
      <Box
        display="flex"
        justifycontent="space-between"
        alignitems="flex-start"
        width="100%"
      >
        <Box flex="1" margin="0 0 8px">
          <Text
            label="Wire Purchase Amount"
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </Box>
        {submitted && (
          <SendWireButton
            type="link"
            label="Print Confirmation"
            icon={<PrinterOutlined />}
            color={`${theme.colors.blue_primay}`}
            onClick={() => window.print()}
            spacingnone
          />
        )}
      </Box>
      <Text
        label={amountFormat(amount, null)}
        color={`${theme.colors.black_secondary}`}
        fontSize="36px"
        lineheight="44px"
        margin="0 0 32px"
      />
      <Box>
        {submitted && (
          <ListWrapperComponent label="Confirmation #" value={confirmation} />
        )}
        <ListWrapperComponent
          label="Transaction Type"
          value="Wire Purchase Notification"
        />
        <ListWrapperComponent
          label="Account/Investment"
          value={
            <>
            <Text
              label={formData.accountinvestmentType.AccName}
              color={`${theme.colors.black_secondary}`}
              fontSize={`${theme.font_size.size_xl}`}
              lineheight="24px"
            />
            <Text
              label={formData.accountinvestmentType.type.FundShortName}
              color={`${theme.colors.grey_tertiary}`}
              fontSize={`${theme.font_size.size_xl}`}
              margin="4px 0 0"
              lineheight="20px"
            />
            </>
          }
        />
        <ListWrapperComponent
          label="Bank Account"
          value={formData.bankAccount}
        />
        <>
        <ListWrapperComponent
          label="Date Scheduled"
          value={moment(formData.date).format('MMM DD, YYYY')}
        />
        </>
      </Box>
      <ButtonWrapper
        size={12}
        spacing
        className={submitted ? 'done-button-wrapper' : ''}
      >
        <ButtonAnt
          label={submitted ? 'Make Another Transaction' : 'Back'}
          type="secondary"
          htmlType="button"
          onClick={() => {
            if (submitted) {
              form.resetFields();
              sessionStorage.setItem('showMoveFundsModal', true);
              window.location.reload();
              // window.history.back();
            } else {
              setFormType('wire');
            }
          }}
        />
        <OverrideButtonAnt 
        linkTextColor={primary_color} 
          label={submitted ? 'Done' : 'Submit'}
          type="primary"
          htmlType="submit"
          onClick={ValidateDuplicateTransaction}
          disabled={disableSubmit}
        />
      </ButtonWrapper>
    </Box>

    {cutoffTimeDetails.isCutOffTimePassed && (
          <Box display="flex" alignitems="flex-start">
            <Text
              fontSize="16px"
              color={`${theme.colors.danger_red}`}
              lineheight="18px"
              label={'The cutoff time for the date of this transaction has passed. Please enter a date on or after ' + moment(cutoffTimeDetails.curOffTime).format('MMM DD, YYYY')}
              icon={false}
              margin={'0 0 0 0'}
            />
          </Box>
        )

        }

{isDuplicateTransModalVisible && (
      <Modal
        title="Possible Duplicate Transaction"
        width={750}
        bodyStyle={{
          padding: '32px 0 40px',
        }}
        maskStyle={{
          backgroundColor: `${theme.rgba.modal}`,
          zIndex: '1051',
        }}
        footer={null}
        visible={isDuplicateTransModalVisible}
        onCancel={onDuplicateModalCancel}
        content={
          <DuplicateTransactionModalContent
            columns={duplicateTransColumns}
            data={duplicateTransData}
            handleDuplicateModalCancel={handleDuplicateModalCancel}
            handleDuplicateModalSubmit={handleDuplicateModalSubmit}
          />
        }
        centered={true}
      />
    )}
{errorsubmitted && (
      <Modal
        title="Service Unavailable"
        width={750}
        bodyStyle={{
          padding: '32px 0 40px',
        }}
        maskStyle={{
          backgroundColor: `${theme.rgba.modal}`,
          zIndex: '1051',
        }}
        footer={null}
        visible={errorsubmitted}
        onCancel={onErrorModalCancel}
        content={
          <Wrapper>
          <RegularText>
            {"We apologize for your inconvenience. Please try again later."}
          </RegularText>
        </Wrapper> }
        centered={true}
      />
    )}
    </>
  );
};

export default ReviewForm;
