import React from 'react';
import styled, { css } from 'styled-components';
import { Box, ClearFix } from '../../styles/globalStyle';
import { theme, Text } from '@pfmam/pfmam';
import moment from 'moment';
import { amountFormat } from '../../utils/numberFormat';
import { Link } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import NoDataFound from '../../common/components/noDataFound';
import { useSelector } from 'react-redux';
import { sizes } from '../../styles/mediaQuery';
const ViewLink = styled(Box)`
  font-size: ${theme.font_size.size_xl};
  line-height: 24px;
  a {
    color: ${(props) => props.linkTextColor};
    :hover {
      color: ${(props) => props.linkTextColor};
      opacity: 0.6;
    }
    &:active,
    &:focus {
      opacity: 1;
    }
  }
`;
const LinkButton = styled(Button)`
  padding: 0;
  display: inline-block;
  margin-left: auto;
  font-size: ${theme.font_size.size_xl};
  line-height: 24px;
  color: ${(props) => props.linkTextColor};
  height: 24px;
  :hover {
    color: ${(props) => props.linkTextColor};
    opacity: 0.6;
  }

  &:active,
  &:focus {
    opacity: 1;
    color: ${(props) => props.linkTextColor};
  }
  & > .anticon + span {
    margin-left: 5px;
  }
  }
`;
const CancelButton = styled(Button)`
  padding: 0;
  display: inline-block;
  margin-left: auto;
  font-size: ${theme.font_size.size_xl};
  line-height: 24px;
  color: ${theme.colors.danger_red};
  height: 24px;
  :hover {
    color: ${theme.colors.danger_red};
    opacity: 0.6;
  }

  &:active,
  &:focus {
    opacity: 1;
    color: ${theme.colors.danger_red};
  }
  & > .anticon + span {
    margin-left: 5px;
  }
`;
const ClickOn = styled.button`
  padding: 0;
  border: 0;
  background: none;
  display: block;
  cursor: pointer;
  text-align: unset;
  min-width: 100%;
  max-width: 100%;
  :hover {
    background: linear-gradient(
        0deg,
        rgba(23, 54, 93, 0.07),
        rgba(23, 54, 93, 0.07)
      ),
      ${theme.colors.white};
  }
`;
const ActivityText = styled(Text)`
  @media ${sizes.mediaQuery('xs', 'xxl')} {
    margin-top: 4px;
  }
`;
const ActivityList = ({ pendingActivity, handleAction, type }) => {
  const CancelledText = () => {
    return (
      <Text
        label="Cancelled"
        color={`${theme.colors.grey_tertiary}`}
        fontSize={`${theme.font_size.size_xl}`}
        lineheight="24px"
        display="inline-block"
        margin="0 0 0 auto"
      />
    );
  };
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  return (
    <>
      {pendingActivity.length > 0 ? (
        pendingActivity.map((activity) => {
          return (
            <ClickOn
              type="button"
              key={activity.ReferenceID}
              onClick={() => {
                type === 'pending'
                  ? handleAction('cancel', activity, true)
                  : handleAction('details', activity, true);
              }}
            >
              <Box
                border={`1px solid ${theme.colors.grey}`}
                borderwidth="0 0 1px 0"
                display="flex"
                padding="20px 32px"
                key={activity.ReferenceID}
                minwidth="100%"
                maxwidth="100%"
                flexdirection="column"
              >
                <Box
                  minwidth="100%"
                  maxwidth="100%"
                  textalign="left"
                  display="flex"
                  justifycontent="space-between"
                >
                  <Text
                    fontSize={`${theme.font_size.size_xl}`}
                    color={`${theme.colors.grey_tertiary}`}
                    label={
                      type === 'pending'
                        ? moment(activity.Date).format('MMM DD, YYYY')
                        : moment(activity.SettlementDate).format('MMM DD, YYYY')
                    }
                    lineheight="24px"
                  />

                  {type === 'pending' ? (
                    activity.Cancelled && activity.IsInitiator ? (
                      <CancelledText />
                    ) : activity.Cancellable ? (
                      <CancelButton
                        type="link"
                        onClick={() => handleAction('cancel', activity)}
                      >
                        <CloseCircleOutlined />
                        Cancel
                      </CancelButton>
                    ) : (
                      <LinkButton
                        linkTextColor={primary_color}
                        type="link"
                        onClick={() => {
                          handleAction('cancel', activity);
                        }}
                      >
                        Details
                      </LinkButton>
                    )
                  ) : (
                    <LinkButton
                      type="link"
                      onClick={() => handleAction('details', activity)}
                      details
                    >
                      Details
                    </LinkButton>
                  )}

                  <ClearFix />
                </Box>
                <Box
                  minwidth="100%"
                  maxwidth="100%"
                  display="flex"
                  flexdirection="column"
                >
                  <ActivityText
                    fontSize={`${theme.font_size.size_xl}`}
                    color={`${theme.colors.black_secondary}`}
                    label={
                      type === 'pending' ? activity.Details : activity.TransDesc
                    }
                    lineheight="24px"
                    textalign="left"
                  />
                </Box>
                <Box
                  minwidth="100%"
                  maxwidth="100%"
                  display="flex"
                  flexwrap="wrap"
                  justifycontent="space-between"
                >
                  {type === 'pending' ? (
                    <Text
                      fontSize={`${theme.font_size.size_xl}`}
                      color={`${theme.colors.black_secondary}`}
                      label={amountFormat(activity.Amount)}
                      lineheight="24px"
                      textalign="left"
                    />
                  ) : (
                    <Text
                      fontSize={`${theme.font_size.size_xl}`}
                      color={`${theme.colors.black_secondary}`}
                      label={amountFormat(activity.TotalAmount)}
                      lineheight="24px"
                      textalign="left"
                    />
                  )}
                </Box>
              </Box>
            </ClickOn>
          );
        })
      ) : (
        <NoDataFound text= {type === 'pending'?"No pending activities":"No recent activities" } padding="24px 32px 32px" />
      )}
      {pendingActivity.length > 0 && (
        <ViewLink
          linkTextColor={primary_color}
          display="flex"
          padding="20px 32px"
        >
          <Link to="/activity">View All Activity</Link>
        </ViewLink>
      )}
    </>
  );
};

export default ActivityList;
