import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Layout,Button, Space,Modal } from 'antd';
import {
  theme,
  Text,
  InputComponent,
  TableComponent
} from '@pfmam/pfmam';
import CheckboxImg from '../assets/images/checkbox-blue.svg';
import CheckboxCheckedImg from '../assets/images/checkbox-black-checked.svg';
import { RightOutlined } from '@ant-design/icons';
import { Container, Banner } from '../styles/globalStyle';
import HeaderComponent from '../common/components/Header';
import FooterComponent from '../common/components/footer';
import { Box } from '../styles/globalStyle';
import user from '../data/UserDetails.json';
import programDetails from '../data/ProgramDetails.json';
import { amountFormat } from '../utils/numberFormat';
import { useHistory } from 'react-router-dom';
import MultiSelect from '../common/components/multiSelect';
import { updateSiteinfo } from '../services/siteConfigurationSlice';
import {
  getProgramsClientsSummary,
  clientsFilteredSearch
} from '../services/programClientsSlice';
import {
  CreateOrUpdateClientFavorite
} from '../actions/actions';
import { useSelector, useDispatch } from 'react-redux';
import { sizes } from '../styles/mediaQuery';
import Ubookmark from '../assets/images/Ubookmark.png';
import FBookmark from '../assets/images/FBookmark.png';
const { Content } = Layout;

const OverrideStyle = createGlobalStyle`
  body {
    .ant-layout-header {
      width: 100%;
    }
  }
  .input-wrapper {
    @media ${sizes.mediaQuery('xs', 'md')} {
      min-width: auto;
      width: auto;
    }
  }
`;
const LayoutWrapper = styled(Layout)`
  background-color: ${theme.colors.light_grey};
  min-height: 100vh;
`;
const Wrpaper = styled(Content)`
  min-height: calc(100vh - 200px);
  padding-bottom: 44px;
  .search {
    .ant-input {
      height: 100%;
      &:hover {
        border-color: ${theme.colors.grey};
      }
      &:focus {
        box-shadow: ${theme.shadows.input_focus};
        border-color: ${theme.colors.blue_primay};
      }
    }
  }
`;
const TableWrapper = styled.div`
  margin-top: 24px;
  .ant-table {
    border-radius: 4px 4px 0 0;
    .ant-table-tbody {
      & > tr {
        &:hover {
          &.ant-table-row:not(.ant-table-expanded-row) {
            background: ${theme.rgba.row_hover};
          }
        }
        cursor: pointer;
        & > td {
          padding: 20px 32px;
        }
        &:last-child {
          td {
            border-bottom-color: ${theme.colors.transparent};
          }
        }
      }
    }
    .ant-table-thead {
      & > tr {
        & > th {
          background: ${theme.colors.light_grey};
          font-family: 'Inter';
          padding: 8px 32px;
          font-weight: normal;
          line-height: 20px;
          &:not(:last-child) {
            &:not(.ant-table-selection-column) {
              &:not(.ant-table-row-expand-icon-cell) {
                &:not([colspan]) {
                  &::before {
                    background-color: ${theme.colors.transparent};
                  }
                }
              }
            }
          }
        }
      }
    }
    .ant-table-content {
      @media ${sizes.mediaQuery('xs', 'sm')} {
        max-width: 100%;
        overflow-x: auto;
      }
    }
  }
  .ant-table-title {
    padding: 0 !important;
  }
  .action-cell {
    .anticon {
      font-size: ${theme.font_size.size_m};
      color: ${theme.colors.grey_dark};
    }
  }
  
`;

const ChooseClient = () => {
  const [showHeaderBookMarkIconFilled, setHeaderBookMarkIconFilled] = useState(false);
  const programsClientsSummaryFavorites = useDispatch();
  const [showSearchandProgramComponents,setSearchandProgramComponents]=useState(true);
  const siteConfigurationInfo = useDispatch();
  const [selectedValues, setSelectedValues] = useState([]);
  const [pagination, setPagination] = useState({});
  const [inputSearchText, setInputText] = useState('');
  const [filterClass, setFilterClass] = useState('hide');
  const history = useHistory();
  const clientPrograms = useSelector(
    (state) => state.programsClientsSlice.programClients
  );
  const [filteredProgramClients, setfilteredProgramClients] = useState(clientPrograms);
  console.log(filteredProgramClients);
  const setFavorites = useSelector(
    (state) => state.programsClientsSlice.favoriteClients
  );
  const programClientsSummary = useDispatch();
  const options = useSelector(
    (state) => state.programsClientsSlice.programName
  );
  const webUserKey = useSelector((state) => state.userSlice.user.profile.name);
  const webuserAccessType = useSelector((state) => state.userSlice.webUserType);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalFavoritesText,setModalFavoritesText]=useState("");
 

//Insert or update User Favorites and enable/disable search,program components and favorites header icon.
 function CreateOrUpdateClientFavorites(ssnortin,programID,modalFavoritesText,favoriteStatus,showSearchandProgramList,showHeaderBookMarkIconFilled) {
  CreateOrUpdateClientFavorite(webUserKey,ssnortin,programID,favoriteStatus)
  .then((response) => {
    if (response.data!=null){
      programsClientsSummaryFavorites(getProgramsClientsSummary());
      setSearchandProgramComponents(showSearchandProgramList);
      setHeaderBookMarkIconFilled(showHeaderBookMarkIconFilled);
      setIsModalVisible(true); 
      setModalFavoritesText(modalFavoritesText);
    }
  })
  .catch((error) => {
    console.log(error);
  });
  }
  const handleToggleFavoriteRecord=(record)=>{
    let modalText="";
    //Favorite Clients Grid
    if(showHeaderBookMarkIconFilled){
       if(setFavorites.length != 1 && record.IsFavorite==true){
          modalText = "You have removed " + record.clientname + " as a favorite."
          CreateOrUpdateClientFavorites(record.ssnortin,record.programid,modalText,false,false,true);
        }
      else if(setFavorites.length == 1 && record.IsFavorite==true){
        modalText = "You have removed " + record.clientname + " as a favorite."
        CreateOrUpdateClientFavorites(record.ssnortin,record.programid,modalText,false,true,false);
        handleTableChange(1);
        setInputText('');
        setSelectedValues([]);
      }
        else {
          modalText = "You have selected " + record.clientname + " as a favorite."
          CreateOrUpdateClientFavorites(record.ssnortin,record.programid,modalText,false,true,false);
        }
    }
    //All Clients Grid
    else
    {
        //select favorites and should not exceed 10
         if(setFavorites.length < 10 && record.IsFavorite==null || setFavorites.length < 10 && record.IsFavorite==false){
          modalText = "You have selected " + record.clientname + " as a favorite." 
          CreateOrUpdateClientFavorites(record.ssnortin,record.programid,modalText,true,true,false);
          }
        //deselect favorites
        else if(record.IsFavorite==true){
            modalText = "You have removed " + record.clientname + " as a favorite."
            CreateOrUpdateClientFavorites(record.ssnortin,record.programid,modalText,false,true,false);
          }
        else
        {
          setIsModalVisible(true); 
          setModalFavoritesText("You cannot select more than 10 Favorite Clients.");
        }
    }
  };
  
  const ReadOnlyUserTypecolumns = [
    {
      title: 'Client Name',
      dataIndex: 'clientname',
      key: 'organization',
      width: 517,
    },
    {
      title: 'Program',
      dataIndex: 'programname',
      key: 'program',
      width: 331,
    },
    {
      title: 'Account Value',
      dataIndex: 'current_balance',
      key: 'accountvalue',
      width: 283,
      align: 'right',
    },
    {
      title:()=>{return showHeaderBookMarkIconFilled?<img src={FBookmark} alt="FLogo" />: <img src={Ubookmark} alt="ULogo" />} ,
      dataIndex: 'righticon',
      key: 'righticon',
      width: 80,
      align: 'center',
      className: 'action-cell',
      hidden: true,
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            if(setFavorites.length!=0 && setFavorites.length > 0){
              if(showHeaderBookMarkIconFilled==false){
                setHeaderBookMarkIconFilled(true);
                setSearchandProgramComponents(false);
                setfilteredProgramClients(setFavorites);
              }
              else
              {
                setHeaderBookMarkIconFilled(false);
                setSearchandProgramComponents(true);
                setfilteredProgramClients(clientPrograms);
                setInputText('');
                setSelectedValues([]);
                handleTableChange(1);
              }
            } 
            else{
              setIsModalVisible(true); 
              setModalFavoritesText("There are no Favorite Clients.");
            }  
          }
        };
      },
      onCell: record => {
        return {
          onClick: event => {
            event.stopPropagation(); // this will avoid onRow being called
            // go to placeUrl(routes.platformDetails, {})
            
          }
        }
      },
    render: (_, record) => 
     {return   record.IsFavorite==null || record.IsFavorite==false ?
      <img onClick={() => handleToggleFavoriteRecord(record)} src={Ubookmark} alt="Logo3" /> :
      <img onClick={() => handleToggleFavoriteRecord(record)} src={FBookmark} alt="Logo2" />   
      }
    },
  ];
  const StandardUserTypecolumns = [
    {
      title: 'Client Name',
      dataIndex: 'clientname',
      key: 'organization',
      width: 517,
    },
    {
      title: 'Program',
      dataIndex: 'programname',
      key: 'program',
      width: 331,
    },
    {
      title: 'Account Value',
      dataIndex: 'current_balance',
      key: 'accountvalue',
      width: 283,
      align: 'right',
    },
  ];
  const rowClicked = (value) => {
    //looking up ssnortin based on client name and program
    const selectedClientName = value.currentTarget.cells[0].innerText;
    const selectedProgramName = value.currentTarget.cells[1].innerText;
    const dollarValue = value.currentTarget.cells[2].innerText;
    let returnArr = clientPrograms.filter((value) => {
      return (
        value.clientname.replace('  ',' ').includes(selectedClientName) &&
        value.programname.includes(selectedProgramName) &&
        value.current_balance.includes(dollarValue)
      );
    });
    siteConfigurationInfo(updateSiteinfo(false));
    programClientsSummary(clientsFilteredSearch(returnArr));
    history.push('/dashboard');
  };

  const updateTableWithClients = (selectedPrograms) => {
    let filteredData = [];
    //filtering client programs based on selected programs
    clientPrograms.forEach(function (key) {
      for (let i = 0; i < selectedPrograms.length; i++) {
        if (
          selectedPrograms[i] == key['programname'] &&
          key['clientname']
            .toLowerCase()
            .includes(inputSearchText.toLowerCase().trim())
        ) {
          filteredData.push(key);
        }
      }
    });
    setfilteredProgramClients(filteredData);
    handleTableChange(1);
  };
  useEffect(() => {
    if(inputSearchText === "" && selectedValues.length === 0)
    {
      setfilteredProgramClients(clientPrograms);
       // handleTableChange(1);
    }
    else{
      searchClients(inputSearchText,null);
    }
  }, [clientPrograms]);

  useEffect(() => {
    if (selectedValues.length != 0) {
      updateTableWithClients(selectedValues);
    } else {
      searchClients(inputSearchText,1);
    }
  }, [selectedValues]);

  const handleProgramChange = (name) => {
    if (selectedValues.includes(name)) {
      const values = selectedValues.filter(
        (selectedValue) => selectedValue !== name
      );
      setSelectedValues([...values]);
    } else {
      setSelectedValues([...selectedValues, name]);
      handleTableChange(1);
    }
  };

  const getFilterOptions = () => {
    const filterOptions = [];
    options.map((option) => {
      filterOptions.push(
        <li key={option.id} onClick={() => handleProgramChange(option)}>
          <img
            src={
              selectedValues.includes(option) ? CheckboxCheckedImg : CheckboxImg
            }
            alt="Checkbox"
          />{' '}
          {option}
        </li>
      );
    });
    return filterOptions;
  };

  const searchClients = (searchText,pageCountfromSearch) => {
    searchText = searchText.trim();
    if(pageCountfromSearch!=null){
      handleTableChange(pageCountfromSearch);
    }
    //search functionality if no clients are selected
    if (selectedValues.length == 0) {
      const filteredArray = clientPrograms.filter((value) =>
        value.clientname.toLowerCase().includes(searchText.toLowerCase())
      );
      setfilteredProgramClients(filteredArray);
    }
    //search functionality if 1 or more clients are selected
    else {
      const filteredArrayWithClient = [];
      const filteredArray = clientPrograms.filter((value) =>
        value.clientname.toLowerCase().includes(searchText.toLowerCase())
      );
      filteredArray.forEach(function (key) {
        for (let i = 0; i < selectedValues.length; i++) {
          if (selectedValues[i] == key['programname']) {
            filteredArrayWithClient.push(key);
          }
        }
      });
      setfilteredProgramClients(filteredArrayWithClient);
    }
  };
  function handleTableChange(current) {
    console.log(current);
    pagination.current = current;
    pagination.position = ['bottomRight'];
    pagination.showQuickJumper = true;
    pagination.size = 'default';
  }
  return (
    <>
    <LayoutWrapper>
      <Wrpaper>
        <HeaderComponent timelineHeader={true} />
        {/* <Banner
          bgcolor={`${theme.colors.black_primay}`}
          height="124px"
          margin="0 0 32px 0"
          positionrevert
        >
          <Container>
            <Text
              color={`${theme.colors.white}`}
              fontSize="36px"
              lineheight="44px"
              label="Client Search"
              padding="44px 0 0"
            />
          </Container>
        </Banner> */}
        <Container>
        {showSearchandProgramComponents?
      (<Space size={8}>
        <Box
          minwidth="332px"
          maxwidth="332px"
          padding="18px 0 0"
          margin="0 0 18px 0"
          className="input-wrapper"
        >
          <InputComponent
            type="search"
            placeholder="Search Clients"
            maxLength={25}
            onInput={(e) => setInputText(e.target.value)}
            onSearch={(value) => {
              searchClients(value,1);
            }}
            value={inputSearchText}
          />
        </Box>
        <MultiSelect
          filterClass={filterClass}
          setFilterClass={setFilterClass}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          options={getFilterOptions()}
          buttonLabel={'Program'}
          selectedBgColor={`${theme.colors.black_primay}`}
          selectedColor={`${theme.colors.white}`}
        />
      </Space>
  ):null}  
          <TableWrapper>
            <TableComponent
              onRow={(value) => ({
                onClick: (value) => {
                  rowClicked(value);
                },
              })}
              columns={webuserAccessType ==1 || webuserAccessType == 2  ? ReadOnlyUserTypecolumns:StandardUserTypecolumns}
              //dataSource={data}
              dataSource={showHeaderBookMarkIconFilled?setFavorites:filteredProgramClients}
              onChange={(e) => handleTableChange(e.current)}
              pagination={pagination}
              className="table-primary"
              title={
                <Box
                  display="flex"
                  justifycontent="space-between"
                  alignitems="center"
                  padding="24px 16px 24px 32px"
                  border={`1px solid ${theme.colors.grey_fossil}`}
                  borderwidth="0 0 1px 0"
                >
                  <Text
                    label={showHeaderBookMarkIconFilled?"Bookmarked Clients":"Clients"}
                    fontSize={`${theme.font_size.size_3xl}`}
                    lineheight="32px"
                    color={`${theme.colors.black_tertiary}`}
                  />
                </Box>
              }
            />
          </TableWrapper>
        </Container>
      </Wrpaper>
      <FooterComponent />
    </LayoutWrapper>
        <Modal title={<strong>{"Message"}</strong>}
          visible={isModalVisible} 
          onOk={() => { 
            setIsModalVisible(false); 
          }} 
          width={500}
          bodyStyle={{
              padding: '32px 32px 48px 32px',
          }}
          maskStyle={{
              backgroundColor: `${theme.rgba.modal}`,
              zIndex: '1051',
          }}
          closable={false}
          cancelButtonProps={{ style: { display: 'none' } }}
          maskClosable={false}
          centered={true}> 
          <p>{modalFavoritesText}</p> 
  
        </Modal> 
    </>
  );
};

export default ChooseClient;








