import React, { useEffect, useState } from 'react';
import {
  InputComponent,
  InputNumberComponent,
  theme,
  Text,
  Modal,
  ButtonAnt,
} from '@pfmam/pfmam';
import { Form, Space, Radio } from 'antd';
import styled from 'styled-components';
import {
  PlusCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { SuffixButton } from '../purchaseFunds/forms/purchaseForm';
import { Box } from '../../styles/globalStyle';
import { validateAmount } from '../../utils/common';
import { numberFormat, validateCheckAmount } from '../common/commonFunctions';
import { amountFormat } from '../../utils/numberFormat';
import TransactionInstructionTable from '../common/transactionInstructionsTable';
const ActionButton = styled(ButtonAnt)`
  height: 50px;
  width: 32px;
  padding: 7px 9px;
  color: ${(props) => props.color};
  &.ant-btn-link[disabled],
  &.ant-btn-link[disabled]:hover,
  &.ant-btn-link[disabled]:focus,
  &.ant-btn-link[disabled]:active {
    opacity: 1;
    color: ${theme.colors.grey_stone};
  }
`;

const ActionWrapper = styled(Box)`
  .ant-form-item {
    margin-bottom: 0;
  }
`;
const GreyBox = styled(Box)`
  &.multi-banks-acc-content {
    .ant-input-number-input {
      text-align: right;
      padding: 0 20px;
    }
  }
  .field-wrapper {
    align-items: flex-start;
  }
`;
const EditButton = styled(ButtonAnt)`
  &.ant-btn {
    font-size: ${theme.font_size.size_xl};
    color: ${theme.colors.grey_stone};
    line-height: 24px;
    padding: 0;
    border: 0;
    height: 20px;
    width: 20px;
  }
`;
const MultiAccount = ({
  vals,
  formTotal,
  setFormTotal,
  form,
  availableBal,
  bankAccounts = null,
  type = null
}) => {
  const [showAccountsModal, setShowAccountsModal] = useState(false);
  const [keyField, setKeyField] = useState(null);
  const fieldAdd = () => {
    const fields = form.getFieldsValue();
    fields.multiAccount[fields.multiAccount.length] = {
      multiBankAccount: '',
      amount: '',
    };
    form.setFieldsValue(fields);
  };

  const fieldRemove = (index) => {
    const fields = form.getFieldsValue();
    const amt = fields.multiAccount[index].amount;
    setFormTotal(formTotal - amt);
    fields.multiAccount.splice(index, 1);
    form.setFieldsValue(fields);
  };
  const AccountsModal = () => {

    const achInstructionsColumns = [
      {
        title: 'Bank Name',
        dataIndex: 'DisplayName',
        key: 'DisplayName',
        width: 400,
        align: 'left',
        className: 'sort-space-none',
        sorter: (a, b) => a.DisplayName.localeCompare(b.DisplayName),
      },
      {
        title: 'Nickname',
        dataIndex: 'NickName',
        key: 'NickName',
        width: 300,
        align: 'right',
        className: 'sort-space-none',
        sorter: (a, b) => type === 'ACHPurchase' ? (a.NickName+a.AddendumPurchaseDescription).localeCompare(b.NickName+b.AddendumPurchaseDescription) : (a.NickName+a.AddendumRedemptionDescription).localeCompare(b.NickName+b.AddendumRedemptionDescription),
        render: (text, record) => (
          <>
            {text}{' '}
            <Text
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.grey_tertiary}`}
              label={type === 'ACHPurchase' ? record.AddendumPurchaseDescription : record.AddendumRedemptionDescription}
              lineheight="24px"
            />
          </>
        ),
      },
    ];

    const onAccountsChange = (e) => {
      const fields = form.getFieldsValue();
      fields.multiAccount[keyField].multiBankAccount = e;
      form.setFieldsValue(fields);
      setShowAccountsModal(!showAccountsModal);
    };

    return (
      <>
        <TransactionInstructionTable
          columns={achInstructionsColumns}
          handleAction={onAccountsChange}
          data={bankAccounts}
          noRecordsText="No Banking instructions found"
        />
      </>
    );
  };
  useEffect(() => {
    let data = form.getFieldsValue().multiAccount;
    let sum = 0;
    if (data) {
      data.forEach((obj) => {
        if (obj && obj.amount) {
          sum += obj.amount;
        }
      });
    }
    setFormTotal(sum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vals]);

  return (
    <GreyBox
      boxcolor={`${theme.colors.grey_faint}`}
      padding="24px 32px 24px"
      margin="0 -32px 32px"
      className="multi-banks-acc-content"
    >
      <Form.List name="multiAccount">
        {(fields) => (
          <>
            <Box
              display="flex"
              justifycontent="space-between"
              width="calc(100% - 45%)"
            >
              <Text
                label="Bank Account"
                fontSize={`${theme.font_size.size_l}`}
                color={`${theme.colors.black_secondary}`}
                lineheight="20px"
                margin="0 0 8px"
              />
              <Text
                label="Amount"
                fontSize={`${theme.font_size.size_l}`}
                color={`${theme.colors.black_secondary}`}
                lineheight="20px"
                margin="0 0 8px"
              />
            </Box>
            {fields.map((field, index) => (
              <Box display="flex" alignitems="flex-start">
                <Space key={field.key} size={12} className="field-wrapper">
                  <Form.Item
                    //{...field}
                    name={[field.key, 'multiBankAccount']}
                    fieldKey={[field.fieldKey, 'multiBankAccount']}
                    rules={[
                      {
                        required: true,
                        message: 'Enter Account Details',
                      },
                    ]}
                  >
                    {form.getFieldValue('multiAccount')[index].multiBankAccount ? (
                      <Box
                        display="flex"
                        alignitems="center"
                        justifycontent="space-between"
                        padding="18px 20px"
                        border={`1px solid ${theme.colors.grey}`}
                        radius="2px"
                      >
                        <Box flex="1" display="flex" alignitems="center">
                          <Box
                            minwidth={'65%'}
                            maxwidth={'75%'}
                            padding="0 20px 0 0"
                          >
                            <Text
                              label={form.getFieldValue('multiAccount')[index].multiBankAccount.DisplayName}
                              fontSize={`${theme.font_size.size_xl}`}
                              color={`${theme.colors.grey_tertiary}`}
                              lineheight="24px"
                            />
                          </Box>
                        </Box>
                        <EditButton
                          label={false}
                          icon={<EditOutlined style={{ color: `${theme.colors.grey_stone}` }} />}
                          onClick={() => {
                            setShowAccountsModal(!showAccountsModal);
                            setKeyField(field.fieldKey);
                          }}
                        />
                      </Box>



                    ) : (
                        <InputComponent
                          type="default"
                          placeholder=""
                          suffix={
                            <SuffixButton
                              label={
                                form.getFieldValue('multiAccount')[index]
                                  .multiBankAccount
                                  ? null
                                  : 'Select Account'
                              }
                              icon={
                                form.getFieldValue('multiAccount')[index]
                                  .multiBankAccount ? (
                                    <EditOutlined
                                      style={{ color: `${theme.colors.grey_stone}` }}
                                    />
                                  ) : null
                              }
                              onClick={() => {
                                setShowAccountsModal(!showAccountsModal);
                                setKeyField(field.fieldKey);
                              }}
                              type="link"
                              color={theme.colors.grey_tertiary}
                              btnblock={
                                !form.getFieldValue('multiAccount')[index]
                                  .multiBankAccount
                              }
                              btnedit={
                                form.getFieldValue('multiAccount')[index]
                                  .multiBankAccount
                              }
                              disabled={
                                form.getFieldValue('accountinvestmentType')
                                  ? false
                                  : true
                              }
                            />
                          }
                        />

                      )

                    }

                  </Form.Item>

                  <Form.Item
                    {...field}
                    label=""
                    name={[field.name, 'amount']}
                    fieldKey={[field.fieldKey, 'amount']}
                    rules={[
                      {
                        validator: (_, value) =>
                          value && validateAmount(value)
                            ? validateCheckAmount(
                              value,
                              formTotal,
                              availableBal
                            )
                            : value
                              ? Promise.reject('Please enter a valid amount')
                              : Promise.reject('Amount Required'),
                      },
                    ]}
                  >
                    <InputNumberComponent
                      placeholder="$ Enter Amount"
                      showHandleButton={false}
                      defaultValue={null}
                      formatter={(value, info) => {
                        if(!info.userTyping)
                        {
                          let val = value !== '' ? numberFormat(value) : null;
                          if(val != null){
                            if (String(value).includes('.')) {
                              let length = String(value).split('.')[1].length;
                              if(length > 2){
                                return "$" + val;
                              }
                            }
                            val = amountFormat(val.toString().replace(/,/g,''));
                          }
                          return val;
                        } else {
                          return value !== '' ? '$' + numberFormat(value) : null;
                        }
                      }}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                  <ActionWrapper alingself="flex-start" flex="1" display="flex">
                    <Form.Item
                      {...field}
                      label=""
                      name={[field.name, 'add']}
                      fieldKey={[field.fieldKey, 'add']}
                    >
                      <ActionButton
                        type="link"
                        label={false}
                        icon={<PlusCircleOutlined />}
                        onClick={() => {
                          fieldAdd();
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label=""
                      name={[field.name, 'remove']}
                      fieldKey={[field.fieldKey, 'remove']}
                    >
                      <ActionButton
                        type="link"
                        label={false}
                        icon={<CloseCircleOutlined />}
                        onClick={() => {
                          fieldRemove(field.fieldKey);
                        }}
                        disabled={field.fieldKey === 0}
                      />
                    </Form.Item>
                  </ActionWrapper>
                </Space>
              </Box>
            ))}
          </>
        )}
      </Form.List>
      <Box
        display="flex"
        alignitems="center"
        justifycontent="space-between"
        padding="0 95px 0 20px"
      >
        <Text
          label="Transaction Total"
          fontSize={`${theme.font_size.size_xl}`}
          color={`${theme.colors.grey_tertiary}`}
          lineheight="24px"
        />
        <Box>
          <Text
            label={amountFormat(formTotal)}
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.black_secondary}`}
            lineheight="24px"
          />
        </Box>
      </Box>
      {showAccountsModal && (
        <Modal
          title="Bank Account"
          width={590}
          bodyStyle={{
            padding: '0 0 0 0',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          content={<AccountsModal />}
          visible={showAccountsModal}
          onCancel={() => {
            setKeyField(null);
            setShowAccountsModal(!showAccountsModal);
          }}
          footer={null}
          centered={true}
        />
      )}
    </GreyBox>
  );
};

export default MultiAccount;
