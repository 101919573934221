/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { Button, Space, Table, Form } from 'antd';
import {
  theme,
  Text,
  TableComponent,
  SelectComponent,
} from '@pfmam/pfmam';
import { CaretDownOutlined } from '@ant-design/icons';
import { Box, Container } from '../../styles/globalStyle';
import NoDataFound from '../../common/components/noDataFound';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import MultiSelect from '../../common/components/multiSelect';
import CheckboxImg from '../../assets/images/checkbox-blue.svg';
import CheckboxCheckedImg from '../../assets/images/checkbox-blue-checked.svg';
import { GetDailyConfirms, DownloadDailyConfirms } from '../../actions/actions';
import { useSelector, useDispatch } from 'react-redux';
import {
  storeFilteredRecipients,
  storeFilteredAccounts,
} from '../../services/dailyConfirmsSlice';

const OverrideStyle = createGlobalStyle`
  .ant-form-item-control-input-content {
    & > span {
      width: 180px;
    }
    .primary.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 52px;
        width: 180px;
        background: ${theme.colors.white};
        color: ${theme.colors.black_secondary};
        border: 1px solid ${theme.colors.grey};
        padding: 14px 20px;
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: 20px;
        }
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
      .ant-select-arrow {
        color: ${theme.colors.grey_stone};
        font-size: 17px;
        margin-top: 0;
        top: 45%;
        transform: translateY(-50%);
        right: 20px;
      }
      &.ant-select-focused {
        .ant-select-selector {
          box-shadow: ${theme.shadows.input_focus};
          border-color: ${theme.colors.blue_primay};
        }
          .ant-select-arrow {
          color: ${theme.colors.black_secondary};
        }
      } 
    }
  }
  
  .form-select-dropdown {
    box-shadow:  0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      .ant-select-item {
        &.ant-select-item-group {
          color: ${theme.colors.grey_tertiary};
          min-height: 22px;
          line-height: 22px;
          padding: 6px 12px;
          &:hover {
            background: none;
          }
        }
      }
      .ant-select-item-option-selected,
      .ant-select-item-option-active {
        &:not(.ant-select-item-option-disabled) {
          background: ${theme.colors.white};
          font-weight: normal;
        }
      }
      .ant-select-item {
        min-height: 36px;
        line-height: 20px;
        padding: 8px 12px;
        font-size: ${theme.font_size.size_l};
        color: ${theme.colors.black_secondary};
        font-family: 'Inter';
        &:hover {
        background: ${theme.colors.light_grey};
        font-family: 'Inter';
      }
    }
  }
  .forms {
    .ant-input {
      &.textarea {
        min-height: 108px;
      }
    }
  }
  }
`;
const Wrapper = styled.div`
  padding-top: 22px;
`;
const LinkButton = styled(Button)`
  padding: 0;
  display: inline-block;
  font-size: ${theme.font_size.size_lg};
  line-height: 20px;
  color: ${theme.colors.blue_primay};
  height: 20px;
  border: 0;
  background: none;
  box-shadow: none;
  :hover {
    opacity: 0.6;
    color: ${theme.colors.blue_primay};
  }
  :focus,
  :active {
    opacity: 1;
    color: ${theme.colors.blue_primay};
    background: none;
  }
  .anticon + span {
    margin-left: 8px;
  }
  ${(props) =>
    props.cancel &&
    css`
      color: ${theme.colors.danger_red};
      :hover {
        opacity: 0.6;
        color: ${theme.colors.danger_red};
      }
      :focus,
      :active {
        opacity: 1;
        color: ${theme.colors.danger_red};
        background: none;
      }
    `}
`;
const ExpandButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  min-width: 32px;
  cursor: pointer;
  transition: 0.3s;
  margin-top: -2px;
  & > span {
    height: 19px;
    min-width: 19px;
    width: 19px;
    margin: 0 auto;
    border: 2px solid ${theme.colors.grey};
    border-radius: 2px;
    padding: 5px;
    position: relative;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: '';
      position: absolute;
      top: 3px;
      background: ${theme.colors.black_secondary};
      height: 10px;
      width: 2px;
      transition: transform 0.3s ease-out;
      transform: rotate(0deg);
    }
    &::after {
      content: '';
      position: absolute;
      top: 7px;
      right: 2.3px;
      background: ${theme.colors.black_secondary};
      height: 2px;
      width: 10px;
      transition: transform 0.3s ease-out;
      transform: rotate(-180deg);
    }
  }
  &:focus {
    & > span {
      box-shadow: ${theme.shadows.input_focus};
      border-color: ${theme.colors.blue_primay};
    }
  }
  &.expanded {
    & > span {
      &::after {
        transform: rotate(0deg);
      }
      &::before {
        transform: rotate(90deg);
      }
    }
  }
`;
const DailyConfirmsComponent = ({ recipientType }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const checkedRecipients = useSelector(
    (state) => state.dailyConfirmsSlice.filteredRecipients
  );

  const checkedAccounts = useSelector(
    (state) => state.dailyConfirmsSlice.filteredAccounts
  );
  const [searchData, setSearchData] = useState(null);
  const [inputClassName, setInputClassName] = useState('hide');

  const [accountSelectedValues, setAccountSelectedValues] = useState([]);
  const [accountFilterClass, setAccountFilterClass] = useState('hide');
  const [recipientSelectedValues, setRecipientSelectedValues] = useState([]);
  const [recipientFilterClass, setRecipientFilterClass] = useState('hide');
  //DATE PICKER
  const [dates, setDates] = useState(null);
  const [dateValues, setDateValues] = useState([moment(), moment()]);
  const [dateOption, setDateOption] = useState('last60days');
  const [dateLabel, setDateLabel] = useState('Last 60 Days');
  const [dateOpen, setDateOpen] = useState(false);
  const ssnorTIN = clientDetails.payload[0].ssnortin;
  const [dailyConfirmsData, setDailyConfirmsData] = useState([]);
  const [filteredDailyConfirmsData, setFilteredDailyConfirmsData] = useState(
    []
  );

  useEffect(() => {
    return function cleanup() {
      dispatch(storeFilteredRecipients([]));
      dispatch(storeFilteredAccounts([]));
    };
  }, []);

  useEffect(() => {
    var endDate = new Date();
    var prior = new Date().setDate(endDate.getDate() - 30);
    var startDate = new Date(prior).toDateString();
    //On Load, by default pull last 30 days data
    GetDailyConfirms(ssnorTIN, startDate, endDate)
      .then((response) => {
        if (response.data != null && response.data.DailyConfirms.length > 0) {
          setDailyConfirmsData(response.data.DailyConfirms);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    applyFiltersToData();
  }, [checkedRecipients, checkedAccounts, dailyConfirmsData]);

  function applyFiltersToData() {
    let FilteredDailyConfirms = [];
    if (checkedAccounts.length > 0 || checkedRecipients.length > 0) {
      FilteredDailyConfirms = dailyConfirmsData.filter(function (elem) {
        // the current value is an object, so you can check on its properties
        let Recipientfilter = true;
        if (checkedRecipients.length > 0) {
          Recipientfilter = checkedRecipients.includes(elem.recipientName);
        }

        return Recipientfilter;
      });

      if (checkedAccounts.length > 0) {
        if (FilteredDailyConfirms.length > 0) {
          //First filter confirms(i.e recipients) which has selected account in it
          FilteredDailyConfirms = FilteredDailyConfirms.filter(function (
            confirms
          ) {
            var returnVal = false;
            confirms.accounts.map((account) => {
              if (checkedAccounts.includes(account.accName)) {
                returnVal = true;
              }
            });
            return returnVal;
          });
          //On the resultant now keep only the accounts that are selected
          let resultantDailyConfirms = [];
          FilteredDailyConfirms.map((conf) => {
            //conf.accounts = [];
            let resAccArray = conf.accounts.filter((f) =>
              checkedAccounts.includes(f.accName)
            );
            resultantDailyConfirms.push({
              key: conf.key,
              recipientName: conf.recipientName,
              accounts: resAccArray,
              date: conf.date
            });
          });
          FilteredDailyConfirms = resultantDailyConfirms;
        }
      }
    } else {
      FilteredDailyConfirms = dailyConfirmsData;
    }

    setFilteredDailyConfirmsData(FilteredDailyConfirms);
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'left',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      className: 'sort-space-none',
      width: 180,
      render: (text) => (
        <Text
          label={moment(text).format('MMM DD, YYYY')}
          lineheight="20px"
          margin="4px 0 0"
        />
      ),
    },
    {
      title: 'Account',
      dataIndex: 'accounts',
      key: 'accounts',
      align: 'left',
      sorter: false,
      className: 'sort-space-none',
      width: 500,
      render: (text, record) => (
        <Box>
          <Text
            label={text.length + ' Accounts'}
            lineheight="24px"
            fontSize={`${theme.font_size.size_xl}`}
          />
          {recipientType === 'multiple' ? (
            <Text
              label={record.recipientName ? record.recipientName : ''}
              lineheight="24px"
              color={`${theme.colors.grey_tertiary}`}
              fontSize={`${theme.font_size.size_xl}`}
            />
          ) : null}
        </Box>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <LinkButton
          type="link"
          onClick={() => {
            handleAction('parent', record);
          }}
        >
          <DownloadOutlined />
          Download All
        </LinkButton>
      ),
    },
  ];

  const childColumns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 80,
    },
    {
      title: '',
      dataIndex: '',
      key: 'date',
      width: 180,
    },
    {
      title: '',
      dataIndex: 'accName',
      key: 'accName',
      width: 500,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <LinkButton
          type="link"
          onClick={(e) => {
            e.stopPropagation();
            handleAction('child', record);
          }}
        >
          <DownloadOutlined />
          Download
        </LinkButton>
      ),
    },
  ];

  const handleAccountChange = (name) => {
    if (accountSelectedValues.includes(name)) {
      const values = accountSelectedValues.filter(
        (selectedValue) => selectedValue !== name
      );
      setAccountSelectedValues([...values]);
      dispatch(storeFilteredAccounts([...values]));
    } else {
      setAccountSelectedValues([...accountSelectedValues, name]);
      dispatch(storeFilteredAccounts([...accountSelectedValues, name]));
    }
  };

  const handleRecipientChange = (name) => {
    if (recipientSelectedValues.includes(name)) {
      const values = recipientSelectedValues.filter(
        (selectedValue) => selectedValue !== name
      );
      setRecipientSelectedValues([...values]);
      dispatch(storeFilteredRecipients([...values]));
    } else {
      setRecipientSelectedValues([...recipientSelectedValues, name]);
      dispatch(storeFilteredRecipients([...recipientSelectedValues, name]));
    }
  };

  const getUniqueAccountUniqueIDs = (data) => {
    const uniqueIDList = [];
    data.map((confirms) => {
      confirms.accounts.map((account) => {
        if (!uniqueIDList.some((item) => item.id === account.accountUniqueID)) {
          uniqueIDList.push({
            id: account.accountUniqueID,
            name: account.accName,
          });
        }
      });
    });
    return uniqueIDList.sort((a, b) => (a.name > b.name ? 1 : -1));
  };

  const getUniqueRecipients = (data) => {
    const uniqueRecipientList = [];
    data.map((confirms) => {
      if (
        !uniqueRecipientList.some(
          (item) => item.name === confirms.recipientName
        )
      ) {
        uniqueRecipientList.push({
          name: confirms.recipientName,
        });
      }
    });
    return uniqueRecipientList.sort((a, b) => (a.name > b.name ? 1 : -1));
  };

  const getAccountFilterOptions = () => {
    const filterOptions = [];
    getUniqueAccountUniqueIDs(dailyConfirmsData).map((option) => {
      filterOptions.push(
        <li key={option.name} onClick={() => handleAccountChange(option.name)}>
          <img
            src={
              accountSelectedValues.includes(option.name)
                ? CheckboxCheckedImg
                : CheckboxImg
            }
            alt="Checkbox"
          />{' '}
          {option.name}
        </li>
      );
    });
    return filterOptions;
  };

  const getRecipientFilterOptions = () => {
    const filterOptions = [];
    getUniqueRecipients(dailyConfirmsData).map((option) => {
      filterOptions.push(
        <li
          key={option.name}
          onClick={() => handleRecipientChange(option.name)}
        >
          <img
            src={
              recipientSelectedValues.includes(option.name)
                ? CheckboxCheckedImg
                : CheckboxImg
            }
            alt="Checkbox"
          />{' '}
          {option.name}
        </li>
      );
    });
    return filterOptions;
  };

  const handleAction = (type, data) => {
    if (type === 'parent') {
      data.accounts.map(function (account) {
        DownloadDailyConfirms(account.filePath, account.fileName)
          .then((response) => {
            if (response.data != null) {
              const arrayBuffer = base64ToArrayBuffer(response.data);
              createAndDownloadBlobFile(arrayBuffer, 'Statement');
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } else {
      DownloadDailyConfirms(data.filePath, data.fileName)
        .then((response) => {
          if (response.data != null) {
            const arrayBuffer = base64ToArrayBuffer(response.data);
            createAndDownloadBlobFile(arrayBuffer, 'Statement');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  function createAndDownloadBlobFile(body, filename, extension = 'pdf') {
    const blob = new Blob([body]);
    const fileName = `${filename}.${extension}`;
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  function dropDownOnChange(value) {
    let startDate = null;
    let endDate = null;

    if (value === '1') {
      endDate = new Date();
      var prior = new Date().setDate(endDate.getDate() - 30);
      startDate = new Date(prior).toDateString();
    }
    if (value === '2') {
      endDate = new Date();
      startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
    }
    if (value === '3') {
      let now = new Date();
      startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      endDate = new Date(now.getFullYear(), now.getMonth(), 0);
    }

    GetDailyConfirms(ssnorTIN, startDate, endDate)
      .then((response) => {
        if (response.data != null) {
          setDailyConfirmsData(response.data.DailyConfirms);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const expandable = {
    expandedRowRender: (record) => (
      <Table
        showHeader={false}
        columns={childColumns}
        dataSource={record.accounts}
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            handleAction('child', record);
          },
        })}
      />
    ),
    expandIcon: ({ expanded, onExpand, record }) => (
      <ExpandButton
        className={expanded ? 'expanded' : ''}
        onClick={(e) => {
          e.stopPropagation();
          onExpand(record, e);
        }}
      >
        <span></span>
      </ExpandButton>
    ),
  };

  return (
    <>
      <OverrideStyle />
      <Container>
        <Wrapper>
          <Box margin="0 0 24px">
            <Space size={8}>
              <Form.Item>
                <SelectComponent
                  defaultValue={'1'}
                  option={[
                    {
                      label: 'Last 30 days',
                      value: '1',
                    },
                    {
                      label: 'Current Month',
                      value: '2',
                    },
                    {
                      label: 'Prior Month',
                      value: '3',
                    },
                  ]}
                  suffixIcon={
                    <CaretDownOutlined className="ant-select-suffix" />
                  }
                  dropdownClassName="form-select-dropdown"
                  bordered={true}
                  onChange={(value) => dropDownOnChange(value)}
                />
              </Form.Item>
              {recipientType === 'multiple' && (
                <MultiSelect
                  storeArray={'clearAllRecipients'}
                  filterClass={recipientFilterClass}
                  setFilterClass={setRecipientFilterClass}
                  selectedValues={recipientSelectedValues}
                  setSelectedValues={setRecipientSelectedValues}
                  options={getRecipientFilterOptions()}
                  buttonLabel={'Recipient'}
                  height={'auto'}
                  selectedBgColor={`${primary_color}`}
                  selectedColor={`${theme.colors.white}`}
                />
              )}
              <MultiSelect
                storeArray={'clearAllAccounts'}
                filterClass={accountFilterClass}
                setFilterClass={setAccountFilterClass}
                selectedValues={accountSelectedValues}
                setSelectedValues={setAccountSelectedValues}
                options={getAccountFilterOptions()}
                buttonLabel={'Account'}
                height={'180px'}
                selectedBgColor={`${primary_color}`}
                selectedColor={`${theme.colors.white}`}
              />
            </Space>
          </Box>
          <TableComponent
            className="table-primary"
            title={
              <Box
                display="flex"
                justifycontent="space-between"
                alignitems="center"
                padding="24px 48px 24px 32px"
                border={`1px solid ${theme.colors.grey_fossil}`}
                borderwidth="0 0 1px 0"
              >
                <Text
                  label="Daily Confirms"
                  fontSize={`${theme.font_size.size_3xl}`}
                  lineheight="32px"
                  color={`${theme.colors.black_tertiary}`}
                />
              </Box>
            }
            expandable={expandable}
            columns={columns}
            dataSource={filteredDailyConfirmsData}
            locale={{
              emptyText: <NoDataFound text="No Daily Confirms" />,
            }}
            showSorterTooltip={false}
            pagination={
              filteredDailyConfirmsData.length > 10
                ? {
                    position: ['bottomRight'],
                    showQuickJumper: true,
                    size: 'default',
                  }
                : false
            }
          />
        </Wrapper>
      </Container>
    </>
  );
};

export default DailyConfirmsComponent;
