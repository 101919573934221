import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { theme } from '@pfmam/pfmam';
import HeaderComponent from '../components/Header';
import SidebarComponent from '../components/Sidebar';
import FooterComponent from '../components/footer';
import AlertComponent from '../../common/components/alert';
import { sizes, breakpoints } from '../../styles/mediaQuery';
const LayoutContainer = styled(Layout)`
  flex-direction: row;
  flex-wrap: nowrap;
  .ant-layout-has-sider {
    flex: 0 0 auto;
  }
  @media ${sizes.mediaQuery('xs', 'md')} {
    width: 1024px;
    overflow-x: auto;
  }
`;
const LayoutWrapper = styled(Layout)`
  background-color: ${theme.colors.light_grey};
  padding-left: 244px;
  min-height: 100vh;
  @media ${sizes.mediaQuery('xs', 'xl')} {
    padding-left: 0;
  }
`;
const Wrpaper = styled.div`
  padding-bottom: 64px;
  min-height: calc(100vh - 200px);
`;

const AppLayout = ({ children, alertObject = null }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  if (deviceWidth > breakpoints.xl && isSidebarOpen) {
    setIsSidebarOpen(false);
  }
  const resizeHandler = () => {
    setDeviceWidth(window.innerWidth);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <>
      <LayoutContainer>
        <SidebarComponent
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <LayoutWrapper>
          <HeaderComponent
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
          <AlertComponent alertObjects={alertObject} />
          <Wrpaper>{children}</Wrpaper>
          <FooterComponent />
        </LayoutWrapper>
      </LayoutContainer>
      {/* {isSidebarOpen ? <Overlay /> : null} */}
    </>
  );
};

export default AppLayout;
