import styled from 'styled-components';
import React, { useState } from 'react';
import { Box } from '../../styles/globalStyle';
import { Space, Radio } from 'antd';
import { theme, Text } from '@pfmam/pfmam';
import { useHistory } from 'react-router-dom';

const Ul = styled.ul`
  list-style-type: disc;
  padding-left: 28px;
`;
const Li = styled.li`
  font-size: ${theme.font_size.size_xl};
  color: ${theme.colors.grey_secondary};
  line-height: 24px;
  margin-bottom: 10px;
`;

export const ExistingUserSearch = ({ setStep,fundname }) => {
  const history = useHistory();
  const onChange = (e) => {
    if (e.target.value === 1) {
      setStep(2);
    }
    else {
      history.push("/create-contact", { canNavigate : true});
    }
  };
  return (
    <>
      <Box className="forms">
        <Text
          label={`Does this contact have an existing ${fundname} login ?`}
          fontSize={`${theme.font_size.size_2xl}`}
          lineheight="42px"
          color={`${theme.colors.black_secondary}`}
        />

        <Radio.Group onChange={onChange}>
          <Space direction="vertical">
            <Radio value={1}>Yes</Radio>
            <Radio value={2}>No</Radio>
          </Space>
        </Radio.Group>
      </Box>
    </>
  )
}