/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Layout, Form, Modal as antmodal } from 'antd';
import { theme, Text, Tabs, Modal } from '@pfmam/pfmam';
import { Container, Box, Banner } from '../styles/globalStyle';
import AppLayout from '../common/layouts/appLayout';
import CreateContactComponent from './components/createContactComponent';
import CreateContactPermissionsComponent from './components/createContactPermissionsComponent';
import {
  GetThirdPartyOptions, ThirdPartyDomainCheck, GetContactsAcctPortPermissions,GetNewContactsPendingAcctPortPermissions,
  CreateContactInformation, IsWebUserExists, GetStatesWithDescription,
  HasConnectUserAccessToAllPortfolios, HasDuplicateContacts,

} from '../actions/actions';
import { useSelector } from 'react-redux';
import LoadSpinner from '../common/components/LoadingSpinner/LoadSpinner';
import ThirdPartyCheckComponentModal from './components/thirdPartyCheckComponentModal';
import { useHistory } from 'react-router-dom';
import DuplicateContactModalContent from './components/duplicateContactModalContent';
import { Prompt } from 'react-router';
const { Content } = Layout;

export const OverrideStyles = createGlobalStyle`
  .helpful-dropdown-menu {
    .ant-dropdown-menu {
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px rgba(0, 0, 0, 0.08);
      border-radius: 2px;
      padding: 4px 0;
      .ant-dropdown-menu-item {
        font-size: ${theme.font_size.size_l};
        line-height: 20px;
        color: ${theme.colors.black_secondary};
        padding: 8px 12px;
        &:hover {
          background: ${theme.colors.light_grey};
        }
        .anticon {
          margin-right: 8px;
        }
      }
      .
    }
  }
  .rc-virtual-list-scrollbar-thumb {
    background: rgb(0, 0, 0, 0.2) !important;
  }
  .profile-card {
    border-color: ${theme.colors.transparent} !important;
    .ant-card-head {
      padding: 0 32px;
      border-color: ${theme.colors.grey_fossil};
    }
    .ant-card-head-title {
      font-size: ${theme.font_size.size_3xl};
      line-height: 32px;
      padding: 24px 0;
    }
    .ant-card-body {
      padding: 32px;
    }
  }
  .statement-dropdown {
    width: 210px !important;
    .ant-dropdown-menu {
      padding: 0;
      box-shadow: ${theme.shadows.dropdown};
    }
    .ant-select-item {
      font-weight: normal;
      font-size: ${theme.font_size.size_l};
      color: ${theme.colors.black_secondary};
      line-height: 20px;
      padding: 8px 12px;
      &:hover {
        background: ${theme.colors.light_grey} !important;
      }
      &.ant-select-item-option-active,
      &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        :not(.ant-select-item-option-disabled) {
          background: ${theme.colors.transparent};
        }
      }
    }
  }
`;

const ContentWrapper = styled(Content)`
  .ant-table {
    border-radius: 4px;
    .ant-table-tbody {
      & > tr {
        cursor: pointer;
        &:hover {
          &.ant-table-row:not(.ant-table-expanded-row) {
            background: linear-gradient(
                0deg,
                rgba(23, 54, 93, 0.07),
                rgba(23, 54, 93, 0.07)
              ),
              ${theme.colors.white};
          }
        }
        & > td {
          vertical-align: top;
          padding: 20px 32px;
          &.ant-table-column-sort {
            background: ${theme.colors.transparent};
          }
        }
        &:last-child {
          & > td {
            border-bottom-color: ${theme.colors.transparent};
          }
        }
      }
    }
    .ant-table-thead {
      & > tr {
        & > th {
          background: ${theme.colors.light_grey};
          font-family: 'Inter';
          padding: 0 32px;
          font-weight: normal;
          line-height: 20px;
          &:not(:last-child) {
            &:not(.ant-table-selection-column) {
              &:not(.ant-table-row-expand-icon-cell) {
                &:not([colspan]) {
                  &::before {
                    background-color: ${theme.colors.transparent};
                  }
                }
              }
            }
          }
        }
      }
    }
    &.ant-table.ant-table-middle {
      .ant-table-thead {
        .ant-table-column-sorters {
          padding: 0;
          .ant-table-column-sorter-inner .anticon {
            color: ${theme.colors.grey_stone};
          }
          .ant-table-column-sorter-up.active,
          .ant-table-column-sorter-down.active {
            color: ${theme.colors.black_sorted};
          }
        }
        .ant-table-column-sorter-inner {
          margin-top: 0;
        }
      }
    }
    .sort-space-none {
      .ant-table-column-sorters {
        justify-content: flex-start;
      }
      .ant-table-column-title {
        flex: 0 0 auto;
      }
      &.flex-end {
        .ant-table-column-sorters {
          justify-content: flex-end;
        }
      }
    }
    .ant-avatar {
      min-width: 40px;
    }
  }
  .ant-table-title {
    padding: 0 !important;
  }
  .ant-table {
    &.ant-table-middle {
      .ant-table-tbody {
        .ant-table-wrapper {
          &:only-child {
            .ant-table {
              margin: -19px -32px;
            }
          }
        }
        .ant-table-container {
          .ant-table-tbody {
            .ant-table-row {
              .ant-table-cell {
                padding: 20px 32px;
                background-color: ${theme.colors.grey_faint};
              }
              &:hover {
                .ant-table-cell {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const CreateContactContainer = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  if (!props?.location?.state?.canNavigate) {
    history.push("/404");
  }

  const clientDetails = useSelector((state) => state.programsClientsSlice.selectedClient);
  const connectContactId = useSelector((state) => state.userSlice.user.profile.sub);
  const editingUserContactId = 0;

  const ssnorTIN = clientDetails.payload[0].ssnortin;
  const programId = clientDetails.payload[0].programid;
  //const [activeTab, setActiveTab] = useState('editcontact');
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  //const webuserAccessType = useSelector((state) => state.userSlice.webUserType);
  const [formDisabled, setFormDisabled] = useState(false);
  const [contactInitialState, setContactInitialState] = useState(null);
  const [permisionsInitialState, setPermissionsInitialState] = useState(null);
  const [isPendingContact, setIsPendingContact] = useState(false);
  const [pendingContactId, setPendingContactId] = useState(0);
  const [loading, setloading] = useState(true);
  const [permissionsModalVisible, setPermissionsModalVisible] = useState(false);
  const [permissionsDataSource, setPermissionsDataSource] = useState([]);
  const [isStatementRecipient, setIsStatementRecipient] = useState(false);
  const [isConnectUsernameMandatory, setIsConnectUsernameMandatory] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedThirdPartyRecordOption, setThirdPartySelectedRecordOption] = useState();
  const [isThirdPartyModalVisible, setIsThirdPartyModalVisible] = useState(false);
  const [isFinalConfirmation, setIsFinalConfirmation] = useState(false);
  const [statementRecipientProfilesCount, setStatementRecipientProfilesCount] = useState(0);
  const [thirdpartyOptionsData, setThirdPartyOptionsData] = useState([]);
  const [hasContactinfoChanged, setHasContactinfoChanged] = useState(false);
  const [hasAcctPortPermissionChanged, setHasAcctPortPermissionChanged] = useState(false);
  const [hasStatementRecipientPermissionsChanged, setHasStatementRecipientPermissionsChanged] = useState(false);
  const [hasStatementRecipientAddressChanged, setHasStatementRecipientAddressChanged] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [canShowClientLevelPortfolio, setCanShowClientLevelPortfolio] = useState(false);
  const [clientLevelPortfolioInitialPermission, setClientLevelPortfolioInitialPermission] = useState(0);
  const [clientLevelPortfolioPermission, setClientLevelPortfolioPermission] = useState(0);
  const [hasClientLevelPortfolioPermissionChanged, setHasClientLevelPortfolioPermissionChanged] = useState(false);
  const [canSubmitEditedContact, setCanSubmitEditedContact] = useState(false);
  const [isDuplicateContactModalVisible, setIsDuplicateContactModalVisible] = useState(false);
  const [duplicateModalContactData, setDuplicateModalContactData] = useState([]);
  const [promptEnabled, setPromptEnabled]  = useState(true);
  const [isTouched, setIsTouched] = useState(false);
  const [hasCheckVerifierPermission, setHasCheckVerifierPermission] = useState(false);
  const [checkVerifierCheckedPrimaryDisclaimer, setCheckVerifierCheckedPrimaryDisclaimer] = useState('I certify that the entered contact information is correct. Upon submitting, I acknowledge that PFM Asset Management reserves the right to request proof of authority in the form of election certification, board minutes, resolutions, fiduciary trusts agreement, etc. when updating permissions. I understand it is my sole responsibility to promptly notify PFM Asset Management of any changes to authorized Contacts. ');
  const [checkVerifierCheckedSecondaryDisclaimer, setCheckVerifierCheckedSecondaryDisclaimer] = useState('I agree that with the assignation of Check Verifier: (1) the Investor is responsible for designating check verifiers and reviewing checks presented against the account in accordance with the terms and conditions contained in the Pennsylvania Local Government Investment Trust Information Statement as well as any additional updates that may occur; (2) the Investor shall notify PLGIT Client Services of any checks that should not be paid by 12pm on the business day following the presentment of checks; (3) no action taken by the Investor by 12pm on the business day following the presentment of checks shall be understood as an affirmation that the checks presented should be paid.');
  const [checkVerifierUnCheckedDisclaimer, setCheckVerifierUnCheckedDisclaimer] = useState('I certify that the entered contact information is correct. Upon submitting, I acknowledge that PFM Asset Management reserves the right to request proof of authority in the form of election certification, board minutes, resolutions, fiduciary trusts agreement, etc. when updating permissions. I understand it is my sole responsibility to promptly notify PFM Asset Management of any changes to authorized Contacts.');
 

  const duplicateContactsColumns = [
    {
      title: 'First Name',
      dataIndex: 'First Name',
      key: 'First Name',
      width: 210,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={text}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    },
    {
      title: 'Last Name',
      dataIndex: 'Last Name',
      key: 'Last Name',
      width: 210,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={text}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    },
    {
      title: 'Email Address',
      dataIndex: 'Email Address',
      key: 'Email Address',
      width: 210,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={text}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    },
    {
      title: 'Phone No',
      dataIndex: 'Phone No',
      key: 'Phone No',
      width: 210,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={text}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    },
    {
      title: 'Phone AddInfo',
      dataIndex: 'Phone AddInfo',
      key: 'Phone AddInfo',
      width: 210,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={text}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    },
    {
      title: 'Mobile No',
      dataIndex: 'Mobile No',
      key: 'Mobile No',
      width: 210,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={text}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    }
  ];

  const setFormValues = (values) => {
    return new Promise((resolve) => {
      form.setFieldsValue(values);
      setTimeout(() => {
        resolve();
      }, 0);
    });
  };

  const getFormValues = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(form.getFieldsValue());
      }, 0);
    });
  }

  useEffect(() => {

    var contactProfile = {
      ext: undefined,
      mvarEmailAddress: undefined,
      mvarFaxNumber: undefined,
      mvarPhoneNumber: undefined,
      mvarMobileNumber: undefined,
      mvarSalutation: undefined,
      mvarTitle: undefined,
      mvarFirstName: undefined,
      mvarLastName: undefined,
      mvarLoginName: undefined,
      mvarIsTrustee: undefined,
      mvarAddressOne: undefined,
      mvarAddressTwo: undefined,
      mvarAgency: undefined,
      mvarState: undefined,
      mvarCity: undefined,
      mvarZipCode: undefined
    };

    setContactInitialState(contactProfile);

    if (programId == 999) {
      HasConnectUserAccessToAllPortfolios(ssnorTIN).then((response) => {
        if (response.data[0].HasAllPortfolioAccess) {
          setCanShowClientLevelPortfolio(true);
        }
      }).catch((err) => {
        handleError(err);
      });
    }

    GetThirdPartyOptions().then((response) => {
      if (response.data.Table.length > 0) {
        setThirdPartyOptionsData(response.data.Table)
      }
    }).catch((err) => {
      handleError(err);
    });


    //Get all US states
    GetStatesWithDescription().then((response) => {
      const stateList = [];
      if (response.data != null && response.data.length > 0) {
        response.data.map((val) => {
          const option = {};
          option.label = val.Description;
          option.value = val.Code;
          stateList.push(option);
        });
        setStates(stateList);
      }
    }).catch((err) => {
      handleError(err);
    });


    //Get Account/Portfolio permissions
    GetNewContactsPendingAcctPortPermissions(connectContactId, 0, ssnorTIN, '', programId).then((response) => {
      if (response.data.Table.length > 0) {
        setloading(false);
        setPermissionsInitialState(response.data.Table);
        setPermissionsDataSource(response.data.Table);
        SetIfStatementRecipient(response.data.Table);

        var isConnectUsernameMandatory = false;
        response.data.Table.map(items => {
          if (programId !== 999) {
            if (items.IsViewReports || items.IsEnterTransactions || items.IsModifyBankingInstructions || items.IsCloseAccounts || items.IsManageUsers || items.IsCheckVerifier || items.IsCheckSigner) {
              isConnectUsernameMandatory = true;
            }
          } else {
            if (items.IsViewReports) {
              isConnectUsernameMandatory = true
            }
          }
        });
        setIsConnectUsernameMandatory(isConnectUsernameMandatory);
      } else {
        setloading(false);
      }
    }).catch((err) => {
      handleError(err);
    });

  }, []);



  const SetIfStatementRecipient = (data) => {
    var isStatementReipient = false;
    data.map((item) => {
      if (item.IsStatementRecipient === 1) {
        isStatementReipient = true;
      }
    });
    setIsStatementRecipient(isStatementReipient);
  }

  const onSubmit = () => {
    setPromptEnabled(false);
    setFormDisabled(true);
    setSubmitted(true);
    
    const currentContactInformationValues = form.getFieldsValue();
    
    HasDuplicateContacts(currentContactInformationValues.mvarFirstName,
      currentContactInformationValues.mvarLastName,
      currentContactInformationValues.mvarEmailAddress,
      currentContactInformationValues.mvarPhoneNumber,
      currentContactInformationValues.ext,
      currentContactInformationValues.mvarMobileNumber).then((response) => {
        if (response.data.length > 0) {
          setDuplicateModalContactData(response.data);
          setIsDuplicateContactModalVisible(true);
        } else {
          onFinalSubmit();
        }
      }).catch((err) => {
        handleError(err);
      });
  };

  const onFinalSubmit = () => {
    setFormDisabled(true);
    setSubmitted(true);
    var localhasContactinfoChanged = false;
    var localhasAcctPortPermissionChanged = false;
    var localhasStatementRecipientPermissionsChanged = false;
    var localhasStatementRecipientAddressChanged = false;
    var localhasClientLevelPortfolioPermissionChanged = false;

    const currentContactInformationValues = form.getFieldsValue();

    if (programId == 999) {
      if (clientLevelPortfolioInitialPermission !== clientLevelPortfolioPermission) {
        localhasClientLevelPortfolioPermissionChanged = true;
        setHasClientLevelPortfolioPermissionChanged(true);
      }
    }

    //Check if Account or Portfolios permssions have changed
    if (JSON.stringify(permissionsDataSource) !== JSON.stringify(permisionsInitialState)) {
      localhasAcctPortPermissionChanged = true;
      setHasAcctPortPermissionChanged(localhasAcctPortPermissionChanged);
    }

    const initialStatementRecipientsState = permisionsInitialState.map(({ IsStatementRecipient }) => ({ IsStatementRecipient }));
    const finalStatementRecipeintsState = permissionsDataSource.map(({ IsStatementRecipient }) => ({ IsStatementRecipient }));

    //Check if StatementRecipient permissions has changes
    if (JSON.stringify(finalStatementRecipeintsState) !== JSON.stringify(initialStatementRecipientsState)) {
      localhasStatementRecipientPermissionsChanged = true;
      setHasStatementRecipientPermissionsChanged(localhasStatementRecipientPermissionsChanged);
    }

    if (statementRecipientProfilesCount < 2 && (currentContactInformationValues['mvarAgency'] !== contactInitialState['mvarAgency']
      || currentContactInformationValues['mvarAddressOne'] !== contactInitialState['mvarAddressOne']
      || currentContactInformationValues['mvarAddressTwo'] !== contactInitialState['mvarAddressTwo']
      || currentContactInformationValues['mvarState'] !== contactInitialState['mvarState']
      || currentContactInformationValues['mvarCity'] !== contactInitialState['mvarCity']
      || currentContactInformationValues['mvarZipCode'] !== contactInitialState['mvarZipCode'])) {
      localhasStatementRecipientAddressChanged = true;
      setHasStatementRecipientAddressChanged(localhasStatementRecipientAddressChanged);
    }

    //Check if Contact information has changes
    if (Object.keys(currentContactInformationValues).some(key => (currentContactInformationValues[key] !== null ? currentContactInformationValues[key] : '') !== (contactInitialState[key] !== null ? contactInitialState[key] : ''))) {
      localhasContactinfoChanged = true;
      setHasContactinfoChanged(localhasContactinfoChanged);
    }

    if (((currentContactInformationValues.mvarLoginName != null && currentContactInformationValues.mvarLoginName != '') || isConnectUsernameMandatory)) {
      IsWebUserExists(currentContactInformationValues.mvarLoginName).then((response) => {
        if (response.data) {
          antmodal.warning({
            title: 'Entered Connect username already exists. Please try another username.',
            //content: 'Entered Connect username already exists. Please try another username.',
            okText: 'Ok',
            onOk: handleDuplicateWebuserOk()
          });
        } else {
          //Check if Contact EmailAddress information has changes
          if (Object.keys(currentContactInformationValues).some(key => (currentContactInformationValues['mvarEmailAddress'] !== contactInitialState['mvarEmailAddress']))) {
            ThirdPartyDomainCheck(ssnorTIN, currentContactInformationValues.mvarEmailAddress).then((response) => {
              if (response.data == true) {
                //Call the api to update contact information
                CreateContactInformation(isPendingContact,
                  pendingContactId,
                  currentContactInformationValues,
                  permissionsDataSource,
                  localhasContactinfoChanged,
                  localhasAcctPortPermissionChanged,
                  true,
                  programId,
                  ssnorTIN,
                  editingUserContactId,
                  statementRecipientProfilesCount,
                  localhasStatementRecipientPermissionsChanged,
                  localhasStatementRecipientAddressChanged,
                  false,
                  0,
                  localhasClientLevelPortfolioPermissionChanged,
                  clientLevelPortfolioPermission).then((response) => {
                    setSubmitted(false);
                    setIsFinalConfirmation(true);
                  }).catch((err) => {
                    handleError(err);
                  });
              } else {
                setSubmitted(false);
                setIsThirdPartyModalVisible(true);
              }
            });
          } else {
            //Call the api to update contact information
            CreateContactInformation(isPendingContact,
              pendingContactId,
              currentContactInformationValues,
              permissionsDataSource,
              localhasContactinfoChanged,
              localhasAcctPortPermissionChanged,
              true,
              programId,
              ssnorTIN,
              editingUserContactId,
              statementRecipientProfilesCount,
              localhasStatementRecipientPermissionsChanged,
              localhasStatementRecipientAddressChanged,
              false,
              0,
              localhasClientLevelPortfolioPermissionChanged,
              clientLevelPortfolioPermission).then((response) => {
                setSubmitted(false);
                setIsFinalConfirmation(true);
              }).catch((err) => {
                handleError(err);
              });
          }
        }
      }).catch((err) => {
        handleError(err);
      });
    } else {
      //Check if Contact EmailAddress information has changes
      if (Object.keys(currentContactInformationValues).some(key => (currentContactInformationValues['mvarEmailAddress'] !== contactInitialState['mvarEmailAddress']))) {
        ThirdPartyDomainCheck(ssnorTIN, currentContactInformationValues.mvarEmailAddress).then((response) => {
          if (response.data == true) {
            //Call the api to update contact information
            CreateContactInformation(isPendingContact,
              pendingContactId,
              currentContactInformationValues,
              permissionsDataSource,
              localhasContactinfoChanged,
              localhasAcctPortPermissionChanged,
              false,
              programId,
              ssnorTIN,
              editingUserContactId,
              statementRecipientProfilesCount,
              localhasStatementRecipientPermissionsChanged,
              localhasStatementRecipientAddressChanged,
              false,
              0,
              localhasClientLevelPortfolioPermissionChanged,
              clientLevelPortfolioPermission).then((response) => {
                setSubmitted(false);
                setIsFinalConfirmation(true);
              }).catch((err) => {
                handleError(err);
              });
          }
          else {
            setSubmitted(false);
            setIsThirdPartyModalVisible(true);
          }
        }
        ).catch((err) => {
          handleError(err);
        });
      } else {
        CreateContactInformation(isPendingContact,
          pendingContactId,
          currentContactInformationValues,
          permissionsDataSource,
          localhasContactinfoChanged,
          localhasAcctPortPermissionChanged,
          false,
          programId,
          ssnorTIN,
          editingUserContactId,
          statementRecipientProfilesCount,
          localhasStatementRecipientPermissionsChanged,
          localhasStatementRecipientAddressChanged,
          false,
          0,
          localhasClientLevelPortfolioPermissionChanged,
          clientLevelPortfolioPermission).then((response) => {
            setSubmitted(false);
            setIsFinalConfirmation(true);
          }).catch((err) => {
            handleError(err);
          });
      }
    }
  };

  const handleError = (err) => {
    console.log(err);
    antmodal.warning({
      title: 'There was as an error processing the request. Please try again after sometime.',
      okText: 'Ok',
      onOk: () => {
        history.push("/dashboard");
      },
    });
  };

  const handleDuplicateWebuserOk = () => {
    setFormDisabled(false);
    setSubmitted(false);
    setPromptEnabled(true);
  };

  const getDetailsModalTitle = () => {
    return 'Verify Email domain';
  };

  const handleModalCancel = () => {
    setIsThirdPartyModalVisible(!isThirdPartyModalVisible);
    setThirdPartySelectedRecordOption(null);
    setSubmitted(false);
    setFormDisabled(false);
    setPromptEnabled(true);
  };

  const handleConfirmationModalCancel = () => {
    setIsFinalConfirmation(false);
    //navigate to Team Members screen.
    history.push("/settings", { showTeamMembers: true });
  };

  const handleThirdPartySubmit = (ThirdPartyCheckedOptionId) => {
    const currentContactInformationValues = form.getFieldsValue();
    if (ThirdPartyCheckedOptionId == 2) {
      currentContactInformationValues.mvarIsTrustee = 'Yes';
    }

    if (((currentContactInformationValues.mvarLoginName != null && currentContactInformationValues.mvarLoginName != '') || isConnectUsernameMandatory)) {
      CreateContactInformation(isPendingContact,
        pendingContactId,
        currentContactInformationValues,
        permissionsDataSource,
        ThirdPartyCheckedOptionId == 2 ? true : hasContactinfoChanged,
        hasAcctPortPermissionChanged,
        true,
        programId,
        ssnorTIN,
        editingUserContactId,
        statementRecipientProfilesCount,
        hasStatementRecipientPermissionsChanged,
        hasStatementRecipientAddressChanged,
        true,
        ThirdPartyCheckedOptionId,
        hasClientLevelPortfolioPermissionChanged,
        clientLevelPortfolioPermission).then((response) => {
          setIsFinalConfirmation(true);
        }).catch((err) => {
          handleError(err);
        });
    } else {
      CreateContactInformation(isPendingContact,
        pendingContactId,
        currentContactInformationValues,
        permissionsDataSource,
        ThirdPartyCheckedOptionId == 2 ? true : hasContactinfoChanged,
        hasAcctPortPermissionChanged,
        false,
        programId,
        ssnorTIN,
        editingUserContactId,
        statementRecipientProfilesCount,
        hasStatementRecipientPermissionsChanged,
        hasStatementRecipientAddressChanged,
        true,
        ThirdPartyCheckedOptionId,
        hasClientLevelPortfolioPermissionChanged,
        clientLevelPortfolioPermission).then((response) => {
          setIsFinalConfirmation(true);
        }).catch((err) => {
          handleError(err);
        });
    }

    setIsThirdPartyModalVisible(false);
    setIsFinalConfirmation(true);
  };

  const OnPermissionsOkClick = () => {
    //If user has atleast one permissions other than statement recipient, then connect username s mandatory.
    var isConnectUsernameMandatory = false;
    var hasUserGivenViewReportsOrSPAccess = false;
    var hasCheckVerifierPermission = false;
    permissionsDataSource.map(items => {
      if (programId !== 999) {
        if (items.IsViewReports || items.IsEnterTransactions || items.IsModifyBankingInstructions || items.IsCloseAccounts || items.IsManageUsers || items.IsCheckVerifier || items.IsCheckSigner) {
          isConnectUsernameMandatory = true;
        }
      } else {
        if (items.IsViewReports) {
          isConnectUsernameMandatory = true
        }
      }


      if (items.IsViewReports || items.IsStatementRecipient) {
        hasUserGivenViewReportsOrSPAccess = true;
      }

      if(items.IsCheckVerifier){
        hasCheckVerifierPermission = true;
      }

    });

    if (clientLevelPortfolioPermission) {
      hasUserGivenViewReportsOrSPAccess = true;
      isConnectUsernameMandatory = true;
    }

    setHasCheckVerifierPermission(hasCheckVerifierPermission);
    setCanSubmitEditedContact(hasUserGivenViewReportsOrSPAccess);
    setIsConnectUsernameMandatory(isConnectUsernameMandatory);
    SetIfStatementRecipient(permissionsDataSource);
    setPermissionsModalVisible(false);
  };

  const OnPermissionsCancelClick = () => {
    var isConnectUsernameMandatory = false;
    var hasUserGivenViewReportsOrSPAccess = false;
    var hasCheckVerifierPermission = false;

    permisionsInitialState.map(items => {
      if (programId !== 999) {
        if (items.IsViewReports || items.IsEnterTransactions || items.IsModifyBankingInstructions || items.IsCloseAccounts || items.IsManageUsers || items.IsCheckVerifier || items.IsCheckSigner) {
          isConnectUsernameMandatory = true;
        }
      } else {
        if (items.IsViewReports) {
          isConnectUsernameMandatory = true
        }
      }


      if (items.IsViewReports || items.IsStatementRecipient) {
        hasUserGivenViewReportsOrSPAccess = true;
      }

      if(items.IsCheckVerifier){
        hasCheckVerifierPermission = true;
      }

    });

    if (clientLevelPortfolioPermission) {
      hasUserGivenViewReportsOrSPAccess = true;
      isConnectUsernameMandatory = true;
    }

    setHasCheckVerifierPermission(hasCheckVerifierPermission);
    setCanSubmitEditedContact(hasUserGivenViewReportsOrSPAccess);
    setIsConnectUsernameMandatory(isConnectUsernameMandatory);
    setPermissionsDataSource(permisionsInitialState);
    setClientLevelPortfolioPermission(clientLevelPortfolioInitialPermission);
    SetIfStatementRecipient(permisionsInitialState);
    setPermissionsModalVisible(false);
  };

  function onDuplicateModalCancel() {
    setIsDuplicateContactModalVisible(false);
    setDuplicateModalContactData([]);
    setSubmitted(false);
    setFormDisabled(false);
    setPromptEnabled(true);
  }

  const handleDuplicateModalSubmit = () => {
    setIsDuplicateContactModalVisible(false);
    onFinalSubmit();
  };

  const handleDuplicateModalCancel = () => {
    setIsDuplicateContactModalVisible(false);
    setSubmitted(false);
    setFormDisabled(false);
    setPromptEnabled(true);
  };

  return (
    <AppLayout>
       <Prompt
        when={isTouched && promptEnabled}
        message={JSON.stringify({
          header: 'Discard Changes?',
          content:
            'Changes have not been submitted, leaving this page will result in no change of contact or permissions. Are you sure you want to leave this page?',
        })}
      />
      <OverrideStyles />
      <ContentWrapper>
        <Banner bgcolor={primary_color} height="124px">
          <Box
            display="flex"
            alignitems="center"
            height="100%"
            minwidth="100%"
            width="100%"
            zindex="3"
            position="relative"
            padding="44px 0 36px 0"
          >
            <Container>
              <Box
                display="flex"
                justifycontent="space-between"
                alignitems="flex-start"
                margin="0"
              >
                <Box flex="1">
                  <Text
                    color={`${theme.colors.white}`}
                    fontSize="36px"
                    lineheight="44px"
                    label="New Contact Information"
                  />
                </Box>
              </Box>
            </Container>
          </Box>
        </Banner>
        <>
          {loading === false ? (
            <>
              <CreateContactComponent
                form={form}
                onSubmit={onSubmit}
                formDisabled={formDisabled}
                setPermissionsModalVisible={setPermissionsModalVisible}
                isStatementRecipient={isStatementRecipient}
                isConnectUsernameMandatory={isConnectUsernameMandatory}
                states={states}
                selectedState={selectedState}
                submitted={submitted}
                canSubmitEditedContact={canSubmitEditedContact}
                setIsTouched={setIsTouched}
                hasCheckVerifierPermission={hasCheckVerifierPermission}
                checkVerifierCheckedPrimaryDisclaimer={checkVerifierCheckedPrimaryDisclaimer}
                checkVerifierCheckedSecondaryDisclaimer={checkVerifierCheckedSecondaryDisclaimer}
                checkVerifierUnCheckedDisclaimer={checkVerifierUnCheckedDisclaimer}
              />

              <Modal
                // title="Permissions"
                width="auto"
                onOk={() => setPermissionsModalVisible(!permissionsModalVisible)}
                bodyStyle={{
                }}
                maskStyle={{
                  backgroundColor: `${theme.rgba.modal}`,
                }}
                content={
                  <CreateContactPermissionsComponent
                    permissionsDataSource={permissionsDataSource}
                    setPermissionsDataSource={setPermissionsDataSource}
                    OnPermissionsOkClick={OnPermissionsOkClick}
                    formDisabled={formDisabled}
                    OnPermissionsCancelClick={OnPermissionsCancelClick}
                    canShowClientLevelPortfolio={canShowClientLevelPortfolio}
                    clientLevelPortfolioPermission={clientLevelPortfolioPermission}
                    setClientLevelPortfolioPermission={setClientLevelPortfolioPermission}
                    setIsTouched={setIsTouched}
                  />
                }
                visible={permissionsModalVisible}
                closable={false}
                footer={null}
                centered={true}
              />
            </>

          ) : (
            <LoadSpinner></LoadSpinner>
          )}

        </>
        <>
        </>
      </ContentWrapper>
      {isThirdPartyModalVisible && thirdpartyOptionsData.length > 0 && (
        <Modal
          title={getDetailsModalTitle()}
          width={800}
          bodyStyle={{
            padding: '32px 32px 34px 32px',
          }}

          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          content={<ThirdPartyCheckComponentModal
            selectedThirdPartyRecordOption={selectedThirdPartyRecordOption}
            setThirdPartySelectedRecordOption={setThirdPartySelectedRecordOption}
            handleModalCancel={handleModalCancel}
            handleThirdPartySubmit={handleThirdPartySubmit}
            thirdpartyOptionsData={thirdpartyOptionsData}
            isThirdPartyModalVisible={isThirdPartyModalVisible}
          />}
          visible={isThirdPartyModalVisible}
          onCancel={() => handleModalCancel()}
          footer={null}
          centered={true}
        />

      )}
      {isFinalConfirmation && (
        <Modal
          title={'Contact Changes Saved-Pending Approval'}
          width={590}
          bodyStyle={{
            padding: '32px 32px 34px 32px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          content={'Changes have been sent to PFM Asset Management for approval. Allow up to 48 hours for processing.'}
          visible={isFinalConfirmation}
          onCancel={handleConfirmationModalCancel}
          okText={'Ok'}
          footer={null}
          centered={true}
          closable={true}
          cancelButtonProps={{ style: { display: 'none' } }}
          maskClosable={false}
        />

      )}

      {isDuplicateContactModalVisible && (
        <Modal
          title="Possible Duplicate Contact"
          width={950}
          bodyStyle={{
            padding: '32px 0 40px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          footer={null}
          visible={isDuplicateContactModalVisible}
          onCancel={onDuplicateModalCancel}
          content={
            <DuplicateContactModalContent
              columns={duplicateContactsColumns}
              data={duplicateModalContactData}
              handleDuplicateModalCancel={handleDuplicateModalCancel}
              handleDuplicateModalSubmit={handleDuplicateModalSubmit}
            />
          }
          centered={true}
        />
      )}
    </AppLayout>

  );
};

export default CreateContactContainer;
