import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../config';
import hexRgb from 'hex-rgb';

const baseApiUrl = config.BASE_API_URL;

export const getsiteConfigurationInfo = createAsyncThunk(
  'siteInfo/getsiteConfigurationInfo',
  async ({ programId }, { dispatch, getstate }) => {
    const req1 = await axios
      .get(baseApiUrl + 'Programs/' + programId + '/ProgramDetails')
      .then((res) => res.data);
    return req1;
  }
);

export const getUserPermissions = createAsyncThunk(
  'permissions/getUserPermissions',
  async ({ ssnorTIN }, { dispatch, getstate }) => {
    const req2 = await axios
      .get(baseApiUrl + 'Clients/' + ssnorTIN + '/TransactionPermissions')
      .then((res) => res.data);
    return req2;
  }
);

const siteConfigurationSlice = createSlice({
  name: 'siteInfo',
  initialState: {
    allowOnlineTransact: false,
    showTermInfoOnline: false,
    allowCDTransactions: false,
    primaryColor: '',
    secondaryColor: '',
    rgbaPrimary: '',
    webBannerImage: '',
    userAccess: false,
    status: null,
    fundGroupID: '',
    phoneNumber: '',
  },
  reducers: {
    updateSiteinfo: (state, payload) => {
      state.allowOnlineTransact = payload;
    },
  },
  extraReducers: {
    [getsiteConfigurationInfo.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getsiteConfigurationInfo.fulfilled]: (state, { payload }) => {
      state.allowOnlineTransact = payload.AllowOnlineTransact;
      state.showTermInfoOnline = payload.ShowTermInfoOnline;
      state.allowCDTransactions = payload.AllowCDTransactions;
      state.enableCheckNotificationForFund =
        payload.EnableCheckNotificationForFund;
      state.fundGroupID = payload.FundGroupID;
      state.primaryColor = payload.PrimaryColor.trim();
      let hexrgb = hexRgb(payload.PrimaryColor.trim(), { format: 'object' });
      state.rgbaPrimary =
        'rgba(' + [hexrgb.red, hexrgb.green, hexrgb.blue].join(',') + ',0.07)';
      state.secondaryColor = payload.SecondaryColor;
      state.webBannerImage = payload.WebBannerNewImage;
      state.phoneNumber = payload.PhoneNumber;
      state.status = 'success';
    },
    [getsiteConfigurationInfo.rejected]: (state, action) => {
      state.status = 'failed';
    },
    [getUserPermissions.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getUserPermissions.fulfilled]: (state, { payload }) => {
      state.userAccess = payload;
      state.status = 'success';
    },
    [getUserPermissions.rejected]: (state, action) => {
      state.status = 'failed';
    },
  },
});

export const { updateSiteinfo } = siteConfigurationSlice.actions;
export default siteConfigurationSlice.reducer;
