/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
import { theme } from '@pfmam/pfmam';
import * as XLSX from 'xlsx';
export const sortArrayOfObjectsInDesc = (arr, keyVal) => {
  let sortedList = [];
  if (arr.length > 0) {
    sortedList = arr.sort((a, b) =>
      parseFloat(b[`${keyVal}`]) > parseFloat(a[`${keyVal}`])
        ? 1
        : parseFloat(a[`${keyVal}`]) > parseFloat(b[`${keyVal}`])
        ? -1
        : 0
    );
  }
  return sortedList;
};


const unique = (value, index, self) => {
  return self.indexOf(value) === index
}

export const ObjectToCsv = (data, fundgroupId) => {
  data = dataReArrange(data);
  data = data.map(item => DownloadCleanup(item,fundgroupId));
  const csvrows = [];
  if (data.length > 0) {
    var Tempheaders=[];
    data.forEach(function (item, index) {
      const keyArray=Object.keys(item)
        Tempheaders=Tempheaders.concat(keyArray);
    });
    const headers = Tempheaders.filter(unique);
    csvrows.push(headers.join(','));
    for (const row of data) {
      const values = headers.map((header) => {
        let rowvalue='';
        if (row[header])
        {
          rowvalue=row[header];

        }
        const escaped = ('' + rowvalue ).replace(/"/g, '\\""');
        return `"${escaped}"`;
      });
      csvrows.push(values.join(','));
    }
    return csvrows.join('\n');
  }

  return csvrows;
};


export const ObjectToXLS = (data,fundgroup_Id) => {
   data = dataReArrange(data);
  data = data.map(item => DownloadCleanup(item, fundgroup_Id));
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  return XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
};

export const colorsOrder = [
  `${theme.colors.anchor_blue}`,
  `${theme.colors.pie_yellow}`,
  `${theme.colors.pie_green}`,
  `${theme.colors.pie_aquablue}`,
  `${theme.colors.pie_orange}`,
  `${theme.colors.pie_red}`,
  `${theme.colors.bar_pink}`,
  `${theme.colors.bar_purple}`,
  `${theme.colors.pie_taupe}`,
];

export const colorAllocator = (data) => {
  let i = 0;
  data.forEach(function (elem) {
    elem.color = colorsOrder[i++];
  });
  return data;
};

export const groupByProperty = (objectArray, property) => {
  let previousClientName = '';
  let sortedArr = [];
  //making clientname unique by adding ssnortin and programname for duplicate client names
  objectArray.forEach(function (key) {
    if (key.clientname === previousClientName) {
      let lastfourSSN = '~' + key.ssnortin.slice(-4);
      let clientProgramName = '~' + key.programname;
      let thisRowvalue = [
        key.ssnortin,
        key.clientname + clientProgramName + lastfourSSN,
        key.programid,
        key.programname,
        key.current_balance,
      ];
      sortedArr.push(thisRowvalue);
    } else {
      let thisRowvalue = [
        key.ssnortin,
        key.clientname,
        key.programid,
        key.programname,
        key.current_balance,
      ];
      sortedArr.push(thisRowvalue);
    }
    previousClientName = key.clientname;
  });
  //sorting objectArray based on two keys, first key is programname ascending and second is clientname ascending
  let sortedArr1 = sortedArr.sort(
    (a, b) => a[3].localeCompare(b[3]) || a[1] - b[1]
  );
  return sortedArr1.reduce((acc, obj) => {
    const key = obj[3];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj[1]);
    return acc;
  }, {});
};

const lenCounter = (obj) => {
  let count = 0;
  for (const prop in obj) {
    count += obj[prop].length;
  }
  return count;
};

const dataReArrange = (data) => {
  const ret = []; 
  const exists = Boolean(data.find(x => x.CheckDetails));
  if (exists) {
    data.forEach(function(item) {  
      if(item.hasOwnProperty('CheckDetails')){
         item.CheckDetails.forEach(function(arrChkDet){          
          let chkArr={'AccountName': arrChkDet.AccountName,
         'InvestmentTypeDesc': arrChkDet.InvestmentTypeDesc,
         'InvestmentTypeClassDesc': arrChkDet.InvestmentTypeClassDesc,  
         'TradeDate':arrChkDet.TradeDate, 
         'SettlementDate': arrChkDet.SettlementDate,
         'TransDesc': 'Check Redemption',
         'TransactionType': arrChkDet.TransactionType,
         'TransactionSource': arrChkDet.TransactionSource,
         'TotalAmount': arrChkDet.TotalAmount,
         'CheckNumber':arrChkDet.TransDesc.split('# ')[1]}        
          ret.push(chkArr); 
        })       
      }  
      else{
        ret.push(item);
      } 
    })
    return ret; 
  }
  else{
    return data;
  }
  
};

export const objReducer = (obj, limit) => {
  let ret = {};
  let maxLen = limit;

  for (const prop in obj) {
    if (lenCounter(ret) === 4) {
      return ret;
    } else if (obj[prop].length > maxLen) {
      ret[prop] = obj[prop].slice(0, maxLen);
      return ret;
    } else if (obj[prop].length <= maxLen) {
      ret[prop] = obj[prop];
      maxLen = maxLen - obj[prop].length;
    }
  }
  return ret;
};

export const objCleaner = (arr, keep) => {
  arr.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (keep.indexOf(key) === -1) {
        delete obj[key];
      }
    });
  });
  return arr;
};

export const DownloadCleanup = (item, fundgroupId) => {
  let activityobj = {};
  Object.keys(item).forEach((key) => {   
    activityobj[key] = item[key];
  });
  
  //Transaction Source column should be present only for Pool accounts.
  if(fundgroupId == '999'){
    delete activityobj.TransactionSource;
  } 

  delete activityobj.id;
  delete activityobj.AccountUniqueID;

 if(activityobj.hasOwnProperty('CheckDetails'))
 {  
  delete activityobj.CheckDetails;
 } 
  return activityobj;
};

export const removeNull = (obj) => {
  Object.keys(obj).forEach(
    (k) =>
      (obj[k] !== null && typeof obj[k] === 'object' && removeNull(obj[k])) ||
      (obj[k] === null && delete obj[k])
  );
  return obj;
};

export const aggregator = (arr) => {
  arr = colorAllocator(arr);
  let val = arr.length - 6;
  let result = arr.splice(0, 6);
  var total = arr.reduce(function (acc, data) {
    return acc + data.y;
  }, 0);
  let str = `${val} Others`;
  let obj = {
    AccName: str,
    CurrentBalance: total,
    y: total,
    color: `${theme.colors.pie_taupe}`,
  };
  result.push(obj);

  return result;
};

export const getNameInitials = (user) => {
  
  if (user.firstName === null || user.lastName === null)
  {
    return null;
  }
  return (user.firstName.charAt(0) + user.lastName.charAt(0)).toUpperCase();
};

const nullLocations = (arr) => {
  let nullLocations = [];
  let i = 0;
  arr.forEach((val) => {
    if (val === 0) {
      nullLocations.push(i);
      i++;
    } else {
      i++;
    }
  });
  return nullLocations;
};

export const barDetailsFormatter = (arr) => {
  if (arr.length > 0) {
    arr[arr.length - 1].borderRadiusTopLeft = '5';
    arr[arr.length - 1].borderRadiusTopRight = '5';
    arr.forEach((elem) => {
      elem.showInLegend = false;
    });
    // colorAllocator(arr);
    const nullPositions = nullLocations(arr[arr.length - 1].data);
    if (arr.length > 2) {
      let i = arr.length - 2;
      while (i >= 0 && nullPositions.length > 0) {
        let curr = arr[i];
        let loc = i;
        let vals = nullPositions.length - 1;
        while (vals >= 0) {
          let pos = nullPositions[vals];
          if (curr.data[pos] > 0) {
            const obj = {};
            obj.data = [];
            let axis = {};
            obj.name = curr.name;
            obj.color = curr.color;
            axis.x = pos;
            axis.y = curr.data[pos];
            obj.showInLegend = false;
            obj.data.push(axis);
            obj.borderRadiusTopLeft = '5';
            obj.borderRadiusTopRight = '5';
            arr[loc].data[pos] = null;
            let index = nullPositions.indexOf(pos);
            if (index > -1) {
              nullPositions.splice(index, 1);
            }
            arr.push(obj);
          }
          vals--;
        }
        i--;
      }
    }
  }
  return arr;
};

export function thousandsSeperator(x) {
  return x
    ? '$' +
        x
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : null;
}

const state_abbrevations = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const getStateHash = () => {
  const list = [];
  state_abbrevations.map((abbr) => {
    const option = {};
    option.label = abbr;
    option.value = abbr;
    list.push(option);
  });
  return list;
};

export const validateEmail = (email) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

export const validateAmount = (amount) => {
  const number = Number(amount);
  return /^(\d+)?([.]?\d+)?$/.test(number);
};

export const validateP2PAmount = (amount) => {
  if(amount!=="null")
  {
    const number = Number(amount);
    if(number===0){
      return false;
    }
    return /^(\d+)?([.]?\d+)?$/.test(number);
  }
  else{
    return true;
  }
};

export const isNumber = (strValue) => {
  return /^[0-9]*$/.test(strValue);
};

export function getCookie(cname) {
  let name = cname + '=';
  const ca = decodeURIComponent(document.cookie).split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
