/* eslint-disable react/jsx-no-undef */
import React from 'react';
import { CardAnt, theme } from '@pfmam/pfmam';
import { Container, Box, Anchor } from '../../../styles/globalStyle';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding-top: 40px;
  .ant-card-head-title {
    font-size: 24px;
  }
  .ant-card {
    border: none;
    .ant-card-bordered {
      border: none;
    }
    .product-card {
      border: none;
    }
  }
  .card-content {
    margin-bottom: 20px;
  }
`;

const ListWrapper = styled.ul`
  padding-left: 20px;
  list-style-type: disc;
  padding-bottom: 24px;
`;

const BulletPointText = styled.li`
  padding-top: 10px;
  color: ${theme.colors.grey_tertiary};
  font-size: 16px;
`;

const RegularText = styled.span`
  color: ${(props) => props.color};
  margin-left: 20px;
  font-size: 16px;
`;

const Number = styled.span`
  color: ${(props) => props.color};
  font-size: 16px;
`;

const CD = ({ phoneNumber }) => {
  return (
    <>
      <Container>
        <Wrapper>
          <CardAnt
            title={'CD Details'}
            cardContent={
              <Box>
                <ListWrapper>
                  <BulletPointText>
                    Fixed-rate investment with a bank that meets the Fund’s
                    minimum capitalization standards
                  </BulletPointText>
                  <BulletPointText>
                    Investment can range from the Fund’s minimum up to the
                    maximum amount that allows principal & interest to be under
                    the FDIC insurance for that institution
                  </BulletPointText>
                  <BulletPointText>
                    <Anchor
                      color={`${theme.colors.blue_primay}`}
                      display="inline-block"
                      href="/dashboard"
                    >
                      View additional details
                    </Anchor>{' '}
                    and information in Funds Information Statement
                  </BulletPointText>
                </ListWrapper>
                <RegularText color={`${theme.colors.grey_tertiary}`}>
                  To purchase, contact us at{' '}
                  <Number color={`${theme.colors.blue_primay}`}>
                    {phoneNumber}
                  </Number>
                </RegularText>
              </Box>
            }
            className="basic-card"
          />
        </Wrapper>
      </Container>
    </>
  );
};

export default CD;
