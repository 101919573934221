import React from 'react';
import { theme, Text } from '@pfmam/pfmam';

const NoDataFound = ({
  text,
  padding = '12px 16px',
  textalign = 'left',
  fontSize = `${theme.font_size.size_xl}`,
  lineheight = '24px',
  color = `${theme.colors.grey_tertiary}`,
  button = null,
}) => {
  return (
    <>
      <Text
        padding={padding}
        color={color}
        label={text}
        lineheight={lineheight}
        textalign={textalign}
        fontSize={fontSize}
      />
      {button ? button : null}
    </>
  );
};

export default NoDataFound;
