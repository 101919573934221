import React from 'react';
import styled from 'styled-components';
import { Layout, Space } from 'antd';
import { theme, Text } from '@pfmam/pfmam';
import { Container } from '../../styles/globalStyle';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import config from '../../config';
const { Footer } = Layout;

const FooterWrapper = styled(Footer)`
  background-color: ${theme.colors.light_grey};
  border-top: 1px solid ${theme.colors.grey};
  height: 104px;
  padding: 20px 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
const FooterLink = styled(Link)`
  color: ${(props) => props.linkTextColor};
  font-size: ${theme.font_size.size_l};
  line-height: 20px;
  :hover {
    :hover {
      color: ${(props) => props.linkTextColor};
      opacity: 0.6;
    }
  }
`;

const footerLabel = config.FooterLabel;
//new Date().toJSON().slice(0, 10).replace(/-/g, '/');

const FooterComponent = () => {
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  return (
    <Container>
      <FooterWrapper>
        <Text
          label={footerLabel}
          color={`${theme.colors.grey_tertiary}`}
          fontSize={`${theme.font_size.size_l}`}
          lineheight="20px"
        />
        <Space size={24}>
          <FooterLink linkTextColor={primary_color} to="/important-disclosures">
            Important Disclosures
          </FooterLink>
          <FooterLink linkTextColor={primary_color} to="/terms-and-conditions">
            Terms & Conditions
          </FooterLink>
          <FooterLink linkTextColor={primary_color} to="/privacy-policy">
            Privacy Policy
          </FooterLink>
        </Space>
      </FooterWrapper>
    </Container>
  );
};

export default FooterComponent;
