import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  theme,
  ButtonAnt,
  InputComponent,
  Text,
  Modal,
  Tabs,
} from '@pfmam/pfmam';
import { EditOutlined } from '@ant-design/icons';
import { Form, Space, Radio, DatePicker } from 'antd';
import { Anchor, Box } from '../../../styles/globalStyle';
import { InvestmentSetlectStyle } from '../../../activityHistory/components/stopPayments/initiateStopPayments';
import { OverrideStyle } from '../../../common/components/datePicker';
import { amountFormat } from '../../../utils/numberFormat';
import MultiDate from '../../common/multiDate';
import SingleDate from '../../common/singleDate';
import MultiAccount from '../../common/multiAccount';
import DuplicateTransactionModalContent from '../../common/duplicateTransactionModalContent';
import { IconBg } from '../../moveFunds';
import SinglBankICon from '../../../assets/images/Transaction Icons/single-account.png';
import MultipleAccIcon from '../../../assets/images/Transaction Icons/multiple-accounts.png';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  disabledDate,
  getAccInvestmentTitle,
} from '../../common/commonFunctions';
import transferData from '../../../data/transferData.json';
import InvestmentModalContent from '../../common/accInvestmentTypeModal';
import AccInvestmentTypeInput from '../../common/accInvestmentTypeInput';
import { Prompt } from 'react-router';
import {
  GetTransactionDefaultDay,
  GetAccountWireInstructions,
  GetACHInstructionsFilteredData,
  IsDuplicateTransactionSingleAccount,
  IsFundValidBusinessDay,
  ValidateTransaction
} from '../../../actions/actions';
import TransactionInstructionTable from '../../common/transactionInstructionsTable';
export const SuffixButton = styled(ButtonAnt)`
  &.ant-btn {
    font-size: ${theme.font_size.size_xl};
    color: ${(props) => props.color};
    line-height: 24px;
    padding: 0;
    border: 0;
    height: 100%;
  }
`;
const ButtonWrapper = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
`;

const TextSemibold = styled(Text)`
  font-family: 'Inter Semibold';
`;

const TabsWrapper = styled.div`
  margin: 0 -32px;
  .nav-pills {
    .ant-tabs-nav {
      margin-bottom: 32px;
    }
  }
  .ant-tabs-nav-list {
    padding-left: 32px;
  }
  .ant-input-number-input {
    padding: 0 20px;
  }
  .multiple-dates-tab-content {
    .ant-input-number-input {
      text-align: right;
      padding: 0 20px;
    }
  }
  .field-wrapper {
    align-items: flex-start;
  }
`;
const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
  }
`;
const RegularText = styled.span`
  color: ${(props) => props.color};
  font-size: 16px;
  line-height: 24px;  
`;
const Wrapper = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;

const momentBusinessDays = require('moment-business-days');

const RedemptionForm = ({
  form,
  selected,
  setSelected,
  showInvestmentTypeModal,
  setShowInvestmentTypeModal,
  setFormType,
  setFormData,
  setAmount,
  setSelectedPage,
  selectedPage,
  setDefaultDate,
  defaultDate
}) => {
  const [showAccountsModal, setShowAccountsModal] = useState(false);
  const [vals, setVals] = useState({});
  const [formTotal, setFormTotal] = useState();
  const [availableBal, setAvailableBal] = useState(0);
  const wireData = transferData.wire;
  const [isDuplicateTrans, setIsDuplicateTrans] = useState();
  const [isDateInValid, setIsDateInValid] = useState();
  const [isTransactionInValid, setIsTransactionInValid] = useState();

  const [isInvalidDateModalVisible, setIsInvalidDateModalVisible] = useState(false);
  const [isTransactionInvalidModalVisible, setIsTransactionInvalidModalVisible] = useState(false);
  const [isDuplicateTransModalVisible, setIsDuplicateTransModalVisible] = useState(false);

  const [validationMessage, setvalidationMessage] = useState("");
  const [duplicateTransData, setDuplicateTransData] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const onFinish = (values) => {
    var x = form;
    setFormData(values);
    setDuplicateTransData([]);
    if (values.hasOwnProperty('amount')) {
      setAmount(values['amount']);
    }
    else {
      setAmount(formTotal);
    }

    if (selected === 'single' && selectedPage === 'RedemptionForm') { //Single account single Date redemption form 
      IsDuplicateTransactionSingleAccount(values.date.format('YYYY-MM-DD'), values.accountinvestmentType.AccountUniqueID, values.amount, 2)  // 2 is ACH redemption
        .then((response) => {
          if (response.data !== null && response.data.Table.length > 0) {
            setDuplicateTransData(response.data.Table);
            setIsDuplicateTransModalVisible(true);
            setIsDuplicateTrans(true);
          }
          else {
            setIsDuplicateTrans(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      //Checking if the date is valid
      IsFundValidBusinessDay(values.date.format('YYYY-MM-DD'), values.accountinvestmentType.type.FundUniqueID)
        .then((response) => {
          if (!response.data) {
            setIsInvalidDateModalVisible(true);
            setIsDateInValid(true);
          }
          else {
            setIsDateInValid(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      ValidateTransaction(values.date.format('YYYY-MM-DD'), defaultDate.format('YYYY-MM-DD'), values.amount, values.accountinvestmentType.type.FundUniqueID, values.accountinvestmentType.AccountUniqueID, 2) // 2 mean ACH redemption
        .then((response) => {
          if (!response.data.IsValid) {
            setIsTransactionInvalidModalVisible(true);
            setvalidationMessage(response.data.ErrorMessage);
            setIsTransactionInValid(true);
          }
          else {
            setIsTransactionInvalidModalVisible(false);
            setIsTransactionInValid(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (selected === 'multiple' && selectedPage === 'RedemptionForm') { //  Single account multiple date redemption form 
      var dup = false;
      var invalidDate = false;
      var invalidTrans = false;
      var dupList = [];
      var duplicateIncrement = 0;
      var invalidDateIncrement = 0;
      var invalidTransIncrement = 0;
      var AggrigatedMuiltiDate = [];
      values.multiDate.reduce(function (res, value) {
        if (!res[value.date.format("YYYY-MM-DD")]) {
          res[value.date.format("YYYY-MM-DD")] = { date: value.date.format("YYYY-MM-DD"), amount: 0 };
          AggrigatedMuiltiDate.push(res[value.date.format("YYYY-MM-DD")])
        }
        res[value.date.format("YYYY-MM-DD")].amount = Math.round((res[value.date.format("YYYY-MM-DD")].amount + value.amount + Number.EPSILON) * 100) / 100;
        return res;
      }, {});

      AggrigatedMuiltiDate.map((obj, index) => {
        ValidateTransaction(obj.date, defaultDate.format("YYYY-MM-DD"), obj.amount, values.accountinvestmentType.type.FundUniqueID, values.accountinvestmentType.AccountUniqueID, 2) // 2 mean ACH redemption
          .then((response) => {
            invalidTransIncrement++;
            if (!response.data.IsValid) {
              invalidTrans = true;
              setvalidationMessage(response.data.ErrorMessage);
            }
            if (invalidTransIncrement === AggrigatedMuiltiDate.length) {
              setIsTransactionInvalidModalVisible(invalidTrans);
              setIsTransactionInValid(invalidTrans);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });

      values.multiDate.map((obj, index) => {
        IsDuplicateTransactionSingleAccount(obj.date.format("YYYY-MM-DD"), values.accountinvestmentType.AccountUniqueID, obj.amount, 2)  // 2 is ACH redemption
          .then((response) => {
            duplicateIncrement++;
            if (response.data !== null && response.data.Table.length > 0) {
              dupList = dupList.concat(response.data.Table);
              setDuplicateTransData(dupList);
              dup = true;
            }
            if (duplicateIncrement === values.multiDate.length) {
              setIsDuplicateTransModalVisible(dup);
              setIsDuplicateTrans(dup);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        IsFundValidBusinessDay(obj.date.format("YYYY-MM-DD"), values.accountinvestmentType.type.FundUniqueID)
          .then((response) => {
            invalidDateIncrement++;
            if (!response.data) {
              invalidDate = true;
            }
            if (invalidDateIncrement === values.multiDate.length) {
              setIsInvalidDateModalVisible(invalidDate);
              setIsDateInValid(invalidDate);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
    }

    if (selectedPage === 'MultiRedemptionForm') { // Muiltple account single date redemption form 
      var dup = false;
      var dupList = [];
      var duplicateIncrement = 0;

      function amount(item) {
        return item.amount;
      }

      function sum(prev, next) {
        return Math.round((prev + next + Number.EPSILON) * 100) / 100;
      }


      values.multiAccount.map((obj, index) => {
        IsDuplicateTransactionSingleAccount(values.date.format('YYYY-MM-DD'), values.accountinvestmentType.AccountUniqueID, obj.amount, 2)  // 2 is ACH redemption
          .then((response) => {
            duplicateIncrement++;
            if (response.data !== null && response.data.Table.length > 0) {
              dupList = dupList.concat(response.data.Table);
              setDuplicateTransData(dupList);
              dup = true;
            }
            if (duplicateIncrement === values.multiAccount.length) {
              setIsDuplicateTransModalVisible(dup);
              setIsDuplicateTrans(dup);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      IsFundValidBusinessDay(values.date.format('YYYY-MM-DD'), values.accountinvestmentType.type.FundUniqueID)
        .then((response) => {
          if (!response.data) {
            setIsInvalidDateModalVisible(true);
            setIsDateInValid(true);
          }
          else {
            setIsDateInValid(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      var totalAmount = values.multiAccount.map(amount).reduce(sum);
      ValidateTransaction(values.date.format('YYYY-MM-DD'), defaultDate.format('YYYY-MM-DD'), totalAmount, values.accountinvestmentType.type.FundUniqueID, values.accountinvestmentType.AccountUniqueID, 2) // 2 mean ACH redemption
        .then((response) => {
          if (!response.data.IsValid) {
            setIsTransactionInvalidModalVisible(true);
            setvalidationMessage(response.data.ErrorMessage);
            setIsTransactionInValid(true);
          }
          else {
            setIsTransactionInvalidModalVisible(false);
            setIsTransactionInValid(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (selectedPage === 'WireRedemptionForm') { //Single account single Date Wire redemption form 
      IsDuplicateTransactionSingleAccount(values.date.format('YYYY-MM-DD'), values.accountinvestmentType.AccountUniqueID, values.amount, 4)  // 4 is Wire redemption
        .then((response) => {
          if (response.data !== null && response.data.Table.length > 0) {
            setDuplicateTransData(response.data.Table);
            setIsDuplicateTransModalVisible(true);
            setIsDuplicateTrans(true);
          }
          else {
            setIsDuplicateTrans(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      IsFundValidBusinessDay(values.date.format('YYYY-MM-DD'), values.accountinvestmentType.type.FundUniqueID)
        .then((response) => {
          if (!response.data) {
            setIsInvalidDateModalVisible(true);
            setIsDateInValid(true);
          }
          else {
            setIsDateInValid(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      ValidateTransaction(values.date.format('YYYY-MM-DD'), defaultDate.format('YYYY-MM-DD'), values.amount, values.accountinvestmentType.type.FundUniqueID, values.accountinvestmentType.AccountUniqueID, 4) // 4 mean Wire redemption
        .then((response) => {
          if (!response.data.IsValid) {
            setIsTransactionInvalidModalVisible(true);
            setvalidationMessage(response.data.ErrorMessage);
            setIsTransactionInValid(true);
          }
          else {
            setIsTransactionInvalidModalVisible(false);
            setIsTransactionInValid(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }


  };

  const buttonHandler = (type) => {
    return setSelected(type);
  };

  useEffect(() => {
    if (isDateInValid === false && isDuplicateTrans === false && isTransactionInValid === false) {
      setFormType('review');
    }
  }, [isDateInValid, isDuplicateTrans, isTransactionInValid]);
  useEffect(() => {
    if(form.getFieldValue('accountinvestmentType'))
    {
      getInstructions();
    }
  },[]);

  const duplicateTransColumns = [
    {
      title: 'Confirmation #',
      dataIndex: 'Confirm #',
      key: 'Confirm #',
      width: 180,
      align: 'left',
    },
    {
      title: 'Account Number',
      dataIndex: 'Account Number',
      key: 'Account Number',
      width: 210,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={text}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    },
    {
      title: 'Posting Date',
      dataIndex: 'Posting Date',
      key: 'Posting Date',
      width: 212,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={moment(text).format('MMM DD, YYYY')}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'Created Date',
      key: 'Created Date',
      width: 212,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={moment(text).format('MMM DD, YYYY h:mm A')}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
          <Text
            label={record['Created By']}
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_xl}`}
            margin="4px 0 0"
            lineheight="20px"
          />
        </>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      width: 148,
      align: 'right',
      render: (text) => <>{amountFormat(text)}</>,
    },
  ];

  const handleDuplicateModalSubmit = () => {
    setIsDuplicateTransModalVisible(false);
    if (isDateInValid === false) {
      setFormType('review');
    }

  };

  const handleDuplicateModalCancel = () => {
    setIsDuplicateTransModalVisible(false);
  };


  const AccountsModal = () => {

    const achInstructionsColumns = [
      {
        title: 'Bank Name',
        dataIndex: 'DisplayName',
        key: 'DisplayName',
        width: 400,
        align: 'left',
        className: 'sort-space-none',
        sorter: (a, b) => a.DisplayName.localeCompare(b.DisplayName),
      },
      {
        title: 'Nickname',
        dataIndex: 'NickName',
        key: 'NickName',
        width: 300,
        align: 'right',
        className: 'sort-space-none',
        sorter: (a, b) => a.NickName.localeCompare(b.NickName),
        render: (text, record) => (
          <>
            {text}{' '}
            <Text
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.grey_tertiary}`}
              label={record.AddendumRedemptionDescription}
              lineheight="24px"
            />
          </>
        ),
      },
    ];

    const wireInstructionsColumns = [
      {
        title: 'Bank Name',
        dataIndex: 'BankName',
        key: 'BankName',
        width: 250,
        align: 'left',
        className: 'sort-space-none',
        sorter: (a, b) => a.BankName.localeCompare(b.BankName),
      },
      {
        title: 'Bank Account Number',
        dataIndex: 'BankAccountNumber',
        key: 'BankAccountNumber',
        width: 200,
        align: 'right',
        className: 'sort-space-none',
        sorter: (a, b) => a.BankAccountNumber.localeCompare(b.BankAccountNumber),
        render: (text, record) => (
          <>
            {text.trim().length > 4? '*' + text.trim().substring(text.trim().length - 4) : '*' + text.trim()}{' '}
            <Text
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.grey_tertiary}`}
              label={record.NickName}
              lineheight="24px"
            />
          </>
        ),
      },
      {
        title: 'BeneName / BeneAccNumber / BeneDetails',
        dataIndex: 'BeneName',
        key: 'BeneName',
        width: 400,
        align: 'right',
        className: 'sort-space-none',
        sorter: (a,b) => (a.BeneName+a.BeneAccountNumber+a.BeneDetails).localeCompare(b.BeneName+b.BeneAccountNumber+b.BeneDetails),
        render: (text, record) => (
          <>
            {text}{' '}
            <Text
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.black_secondary}`}
              label={record.BeneAccountNumber}
              lineheight="24px"
              textalign="right"
            />
            <Text
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.black_secondary}`}
              label={record.BeneDetails}
              lineheight="24px"
              textalign="right"
            />
          </>
        ),
      },
    ];

    const onAccountsChange = (e) => {
      form.setFieldsValue({
        bankAccount:
          selectedPage === 'WireRedemptionForm'
            ? { ...e }
            : e.DisplayName,
      });

      form.setFieldsValue({
        instructionID:
          selectedPage === 'WireRedemptionForm'
            ? e.WireInstructionID
            : e.ACHInstructionID,
      });

      form.setFieldsValue({
          addendumRedemptionDescription: 
            selectedPage === 'WireRedemptionForm'
              ? null
              : e.AddendumRedemptionDescription,
      });

      form.setFieldsValue({
        NickName: 
          selectedPage == 'RedemptionForm'
            ? e.NickName
            : null,
    });
      
      setShowAccountsModal(!showAccountsModal);
    };

    return (
      <>
        {selectedPage === 'WireRedemptionForm' ? (
          <TransactionInstructionTable
            columns={wireInstructionsColumns}
            handleAction={onAccountsChange}
            data={bankAccounts}
            noRecordsText="No Banking instructions found"
          />
        ) : (
            <TransactionInstructionTable
              columns={achInstructionsColumns}
              handleAction={onAccountsChange}
              data={bankAccounts}
              noRecordsText="No Banking instructions found"
            />
          )
        }
      </>
    );
  };

  const onInvestmentTypeChange = (e) => {
    console.log('target', e.target.value);
    form.resetFields();
    setVals(0);
    setAvailableBal(e.target.value.type.AvailBalance);
    form.setFieldsValue({
      accountinvestmentType: { ...e.target.value },
    });
    form.setFieldsValue({
      bankAccount: null,
      // ACHOrWireInstructionsId:null,
    });
    setShowInvestmentTypeModal(!showInvestmentTypeModal);

    var PendingTransactionTypeClassID = selectedPage === 'WireRedemptionForm' ? 4 : 2 // 4 for wireredemption and 2 for ACh redemption
    GetTransactionDefaultDay(form.getFieldValue('accountinvestmentType').type.FundUniqueID, PendingTransactionTypeClassID).then((response) => {
      if (response.data !== null) {
        //var date1 = new Date(response.data);
        var defaultMoment = moment(response.data.toString());//.add(1,'days');//moment(year+'-'+month+'-'+day,'YYYY-MM-DD')
        form.setFieldsValue({
          date: defaultMoment,
        });
        form.setFieldsValue({
          multiDate: [
            { date: defaultMoment, amount: '' },
            { date: defaultMoment, amount: '' },
          ]
        });

        setDefaultDate(defaultMoment);
      }
    })
      .catch((error) => {
        console.log(error);
      });

   getInstructions();
   
  };

  const getInstructions = ()=> {
    if (selectedPage === 'WireRedemptionForm') {
      GetAccountWireInstructions(form.getFieldValue('accountinvestmentType').AccountUniqueID)
        .then((response) => {
          if (response.data !== null) {
            setBankAccounts(response.data);
            // setAccountWireInstructions(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      GetACHInstructionsFilteredData(form.getFieldValue('accountinvestmentType').AccountUniqueID)
        .then((response) => {
          if (response.data !== null) {
            const sortByKey = (key) => (a, b) => a[key] > b[key] ? 1 : -1;
            setBankAccounts(response.data.sort(sortByKey('DisplayName')));
            //setACHInstructions(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });

    }
  }
  const disabledDate = (current) => {
    var x = moment().endOf("day");
    // Can not select days before default date and weekends
    return (current.isoWeekday() === 7 || current.isoWeekday() === 6 || current < defaultDate.startOf('day')); //&& current.isoWeekday() ===6;
  }

  const handleTotal = (_, values) => {
    setVals(values);
  };

  const getTitle = () => {
    switch (selectedPage) {
      case 'RedemptionForm':
        return 'Single Bank Account';
      case 'MultiRedemptionForm':
        return 'Multiple Bank Accounts';
      case 'WireRedemptionForm':
        return 'Wire Redemption';
      default:
        return 'Single Bank Account';
    }
  };
  return (
    <>
      <Prompt
        when={form.isFieldsTouched()}
        message={JSON.stringify({
          header: 'Discard Transaction?',
          content:
            'Leaving this page will discard this transaction. Are you sure you want to discard this transaction?',
        })}
      />
      <OverrideStyle />
      <InvestmentSetlectStyle />
      <Text
        label={
          selectedPage === 'RedemptionForm' ||
            selectedPage === 'MultiRedemptionForm'
            ? 'ACH Redemption'
            : 'Wire Redemption'
        }
        fontSize="36px"
        color={`${theme.colors.black_secondary}`}
        lineheight="44px"
        margin="0 0 32px"
      />
      <Box
        border={`1px solid ${theme.colors.grey}`}
        borderwidth="0 0 1px 0"
        display="flex"
        padding="20px 32px 24px"
        boxcolor={theme.colors.white}
        radius="4px 4px 0 0"
      >
        {/* <Box display="flex" alignitems="flex-start"> */}
        <IconBg bgColor={`${primary_color}`}>
          <img
            src={
              selectedPage === 'RedemptionForm' ||
                selectedPage === 'WireRedemptionForm'
                ? SinglBankICon
                : MultipleAccIcon
            }
            alt="Single Bank Account"
          />
        </IconBg>
        <Box flex="1">
          <TextSemibold
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.black_secondary}`}
            label={getTitle()}
            lineheight="24px"
            margin="0 0 4px"
          />
          <Anchor
            fontsize={`${theme.font_size.size_l}`}
            lineheight="20px"
            color={`${theme.colors.blue_primay}`}
            display="inline-block"
            onClick={() => setSelectedPage('TransferTypeCard')}
          >
            Change
          </Anchor>
        </Box>
        {/* </Box> */}
      </Box>
      <Box
        boxcolor={`${theme.colors.white}`}
        padding="32px 32px 48px"
        radius="0 0 4px 4px"
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          requiredMark={false}
          className="forms"
          initialValues={{
            multiDate: [
              { date: momentBusinessDays().businessAdd(1), amount: '' },
              { date: momentBusinessDays().businessAdd(1), amount: '' },
            ],
            multiAccount: [
              { multiBankAccount: '', amount: '' },
              { multiBankAccount: '', amount: '' },
            ],
            date: selectedPage === 'WireRedemptionForm' ? moment() : momentBusinessDays().businessAdd(1),
            //bankAccount: //{BankName: 'Select Account'},
          }}
          onFinish={onFinish}
          onValuesChange={handleTotal}
        >
          <>
            <AccInvestmentTypeInput
              form={form}
              showInvestmentTypeModal={showInvestmentTypeModal}
              setShowInvestmentTypeModal={setShowInvestmentTypeModal}
              amount={
                form.getFieldValue('accountinvestmentType')
                  ? form.getFieldValue('accountinvestmentType').type.AvailBalance
                  : null
              }
            />
            <Form.Item
              hidden={true}
              name="instructionID"
            />
            <Form.Item
              hidden={true}
              name="addendumRedemptionDescription"
            />
            <Form.Item
              hidden={true}
              name="NickName"
            />
            {selectedPage === 'RedemptionForm' ||
              selectedPage === 'WireRedemptionForm' ? (
                <Form.Item
                  label={
                    selectedPage === 'WireRedemptionForm'
                      ? 'Wire Account'
                      : 'Bank Account'
                  }
                  name="bankAccount"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Account Details',
                    },
                  ]}
                  className={
                    form.getFieldValue('accountinvestmentType')
                      ? 'read-only-input'
                      : ''
                  }
                >
                  {form.getFieldValue('bankAccount') &&
                    selectedPage === 'WireRedemptionForm' ? (
                      getAccInvestmentTitle(
                        form.getFieldValue('bankAccount').BankName,
                        form.getFieldValue('bankAccount').BeneName,
                        showAccountsModal,
                        setShowAccountsModal,
                        null,
                        form.getFieldValue('bankAccount').NickName
                      )
                    ) : (
                      <InputComponent
                        type="default"
                        placeholder="None Selected"
                        readOnly={true}
                        disabled={
                          form.getFieldValue('accountinvestmentType')
                            ? false
                            : true
                        }
                        onClick={() => {
                          setShowAccountsModal(!showAccountsModal);
                        }}
                        suffix={
                          <SuffixButton
                            label={
                              form.getFieldValue('bankAccount')
                                ? null
                                : 'Select Account'
                            }
                            icon={
                              form.getFieldValue('bankAccount') ? (
                                <EditOutlined
                                  style={{ color: `${theme.colors.grey_stone}` }}
                                />
                              ) : null
                            }
                            onClick={() => {
                              setShowAccountsModal(!showAccountsModal);
                            }}
                            type="link"
                            disabled={
                              form.getFieldValue('accountinvestmentType')
                                ? false
                                : true
                            }
                            color={
                              form.getFieldValue('accountinvestmentType')
                                ? theme.colors.black_secondary
                                : theme.colors.grey_stone
                            }
                          />
                        }
                      />
                    )}
                </Form.Item>
              ) : null}

            {selectedPage === 'RedemptionForm' ? (
              <>
                <TabsWrapper>
                  <Tabs
                    defaultActiveKey={selected}
                    activeKey={selected}
                    activeTabColor={primary_color}
                    onTabClick={(key) => buttonHandler(key)}
                    tabsOptions={[
                      {
                        content: (
                          <Box padding="0 32px" margin="0 0 32px">
                            {selected === 'multiple' ? null : (
                              <SingleDate
                                vals={vals}
                                setFormTotal={setFormTotal}
                                dateLabel="Transaction Date"
                                formTotal={formTotal}
                                availableBal={form.getFieldValue('accountinvestmentType') !== undefined ? form.getFieldValue('accountinvestmentType').type.AvailBalance : availableBal}
                                disabledDate={disabledDate}
                              />
                            )}
                          </Box>
                        ),
                        key: 'single',
                        label: 'Single Date',
                      },
                      {
                        content: (
                          <Box
                            className="multiple-dates-tab-content"
                            padding="0 0 16px"
                          >
                            {selected === 'single' ? null : (
                              <MultiDate
                                vals={vals}
                                formTotal={formTotal}
                                setFormTotal={setFormTotal}
                                selectedPage={selectedPage}
                                availableBal={form.getFieldValue('accountinvestmentType') !== undefined ? form.getFieldValue('accountinvestmentType').type.AvailBalance : availableBal}
                                isRedemption={true}
                                disabledDate={disabledDate}
                                form={form}
                              />
                            )}
                          </Box>
                        ),
                        key: 'multiple',
                        label: 'Multiple Date',
                      },
                    ]}
                    type="nav-pills"
                  />
                </TabsWrapper>
              </>
            ) : selectedPage === 'WireRedemptionForm' ? (
              <Box margin="0 0 48px">
                {selected === 'multiple' ? null : (
                  <SingleDate
                    vals={vals}
                    setFormTotal={setFormTotal}
                    dateLabel="Transaction Date"
                    formTotal={formTotal}
                    availableBal={form.getFieldValue('accountinvestmentType') !== undefined ? form.getFieldValue('accountinvestmentType').type.AvailBalance : availableBal}
                    disabledDate={disabledDate}
                  />
                )}
              </Box>
            ) : (
                  <Box margin="0 0 48px">
                    <MultiAccount
                      vals={vals}
                      formTotal={formTotal}
                      setFormTotal={setFormTotal}
                      form={form}
                      availableBal={form.getFieldValue('accountinvestmentType') !== undefined ? form.getFieldValue('accountinvestmentType').type.AvailBalance : availableBal}
                      bankAccounts={bankAccounts}
                      type={'ACHRedemption'}
                    />

                    <Form.Item
                      label="Transaction Date"
                      name="date"
                      rules={[
                        {
                          type: 'object',
                          required: true,
                          message: 'Transaction Date Required',
                        },
                      ]}
                    >
                      <DatePicker
                        format="MMM DD, YYYY"
                        allowClear={false}
                        className="suffix-left"
                        dropdownClassName="single-date-picker"
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  </Box>
                )}
          </>
          <ButtonWrapper size={12}>
            <ButtonAnt
              label="Cancel"
              type="secondary"
              htmlType="button"
              onClick={() => setSelectedPage('TransferTypeCard')}
            />
            <OverrideButtonAnt linkTextColor={primary_color} label="Review" type="primary" htmlType="submit" />
          </ButtonWrapper>
        </Form>
      </Box>
      {showInvestmentTypeModal && (
        <Modal
          title="Account/Investment Type"
          width={660}
          bodyStyle={{
            padding: '0 0 64px 0',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          content={
            <InvestmentModalContent
              form={form}
              onInvestmentTypeChange={onInvestmentTypeChange}
              //disableZeroAccounts={true}
              pendingTransactionTypeClassID={ selectedPage === 'WireRedemptionForm' ? 4 : 2}
            />
          }
          visible={showInvestmentTypeModal}
          onCancel={() => {
            setShowInvestmentTypeModal(!showInvestmentTypeModal);
          }}
          footer={null}
          centered={true}
        />
      )}

      {showAccountsModal && (
        <Modal
          title={
            selectedPage === 'WireRedemptionForm'
              ? 'Wire Account'
              : 'Bank Account'
          }
          width={selectedPage === 'WireRedemptionForm' ? 800 : 610}
          bodyStyle={{
            padding: '0 0 64px 0',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          content={<AccountsModal />}
          visible={showAccountsModal}
          onCancel={() => setShowAccountsModal(!showAccountsModal)}
          footer={null}
          centered={true}
        />
      )}

      {isDuplicateTransModalVisible && (
        <Modal
          title="Possible Duplicate Transaction"
          width={750}
          bodyStyle={{
            padding: '32px 0 40px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          footer={null}
          visible={isDuplicateTransModalVisible}
          onCancel={() => setIsDuplicateTransModalVisible(false)}
          content={
            <DuplicateTransactionModalContent
              columns={duplicateTransColumns}
              data={duplicateTransData}
              handleDuplicateModalCancel={handleDuplicateModalCancel}
              handleDuplicateModalSubmit={handleDuplicateModalSubmit}
            />
          }
          centered={true}
        />
      )}

      {isInvalidDateModalVisible && (
        <Modal
          title="Invalid Date"
          width={750}
          bodyStyle={{
            padding: '32px 0 40px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          footer={null}
          visible={isInvalidDateModalVisible}
          onCancel={() => setIsInvalidDateModalVisible(false)}
          content={
            <Wrapper>
              <RegularText>
                {"The Date you selected is Invalid or a Holiday for the Investment Type you selected. Please select other Date"}
              </RegularText>
            </Wrapper>

          }
          centered={true}
        />
      )}

      {isTransactionInvalidModalVisible && (
        <Modal
          title="Invalid Transaction"
          width={750}
          bodyStyle={{
            padding: '32px 0 40px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          footer={null}
          visible={isTransactionInvalidModalVisible}
          onCancel={() => setIsTransactionInvalidModalVisible(false)}
          content={
            <Wrapper>
              <RegularText>{validationMessage}</RegularText>
            </Wrapper>

          }
          centered={true}
        />
      )}
    </>
  );
};

export default RedemptionForm;
