/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  theme,
  ButtonAnt,
  Modal,
} from '@pfmam/pfmam';
// import { Space, Tree, Switch } from 'antd';
import { Space, Tree, Divider, Tooltip } from 'antd';
import { Box, Container } from '../../styles/globalStyle';
import MultiSelect from '../../common/components/multiSelect';
import CheckboxImg from '../../assets/images/checkbox-blue.svg';
import CheckboxCheckedImg from '../../assets/images/checkbox-blue-checked.svg';
import { DownloadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import DownloadModal from '../../common/components/downloadModal';
import moment from 'moment';

const FilterCol = styled(Space) `
  flex: 1;
  white-space: nowrap;
  overflow: ${(props) => (props.overflow ? 'hidden' : '')};
`;
const Wrapper = styled.div`
  padding-top: 22px;
`;

const ProductTreeFilter = styled(Tree) `
  .ant-tree-treenode {
    padding: 8px 0;
  }
  .ant-tree-switcher {
    height: 20px;
    width: 20px;
    line-height: 20px;
    margin-right: 3px;
    margin-left: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    .anticon {
      font-size: ${theme.font_size.size_l};
      color: ${theme.colors.grey_tertiary};
    }
  }
  .ant-tree-checkbox {
    margin-top: 2px;
    z-index: 2;
    &:hover {
    }
    &.ant-tree-checkbox-checked {
      &:after {
        border-color: ${(props) => props.checkboxCheckedColor};
      }
      .ant-tree-checkbox-inner {
        background-color: ${(props) => props.checkboxCheckedColor};
        border-color: ${(props) => props.checkboxCheckedColor};
        &::after {
          left: 20%;
          height: 10px;
          transform: rotate(40deg) scale(1) translate(-50%, -50%);
        }
      }
    }
  }
  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
    border-color: ${(props) => props.checkboxCheckedColor};
  }
  .ant-tree-checkbox-indeterminate {
    .ant-tree-checkbox-inner {
      &::after {
        background-color: ${(props) => props.checkboxCheckedColor};
      }
    }
  }
  .ant-tree-node-content-wrapper {
    color: ${theme.colors.black_secondary};
    line-height: 22px;
    min-height: 22px;
    padding: 0 2px;
    margin-top: -1px;
    z-index: 2;
    &:hover {
      background-color: ${theme.colors.transparent};
    }
    &.ant-tree-node-selected {
      background-color: ${theme.colors.transparent};
    }
  }
  .ant-tree-switcher-noop {
    & + {
      .ant-tree-checkbox {
        margin-left: 2px;
      }
    }
  }
`;


const WrapperLi = styled.li`
  max-width: 100%;
  overflow: hidden;
  word-wrap: normal !important;
  white-space: normal;
`;

function createPendingActivityDownloadData(data) {
  var resultantData = [];
  data.map(function (item) {
    resultantData.push({
      ConfirmationNumber: item.ReferenceID,
      ClientName : item.ClientName,
      FundName: item.FundName,
      SettlementDate: moment(item.Date).format('MM/DD/YYYY'),
      Details: item.Details,
      Amount : item.Amount,
      TransactionType : item.TransactionType,
      TransactionSource: item.TransactionSource,
      CreatedSource: item.CreatedSource,
      SubmittedBy: item.SubmittedBy,
      Status: item.Status
    });
  });
  return resultantData;
}

const RenderFiltersPending = ({
  data,
  isGlobalActivityHistoryPage,
  handleTransactionTypeSelection,
  handleAccountSelection,
}) => {
  const [accountSelectedValues, setAccountSelectedValues] = useState([]);
  const [accountFilterClass, setAccountFilterClass] = useState('hide');
  const [transactionTypeSelectedValuesKey, setTransactionTypeSelectedValuesKey] =
    useState([]);
  const [transactionTypeSelectedValues, setTransactionTypeSelectedValues] =
    useState([]);

  const [transactionTypeFilterClass, setTransactionTypeFilterClass] =
    useState('hide');

  const primary_color = useSelector((state) => state.siteInfo.primaryColor);

  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [inputClassName, setInputClassName] = useState('hide');
  const [tooltip, setTooltip] = useState(false);


  const handleAccountChange = (AccountUniqueID) => {
    if (AccountUniqueID === undefined) {
      setAccountSelectedValues([]);
      handleAccountSelection([]);
    }
    else {
      if (accountSelectedValues.includes(AccountUniqueID)) {
        const values = accountSelectedValues.filter(
          (selectedValue) => selectedValue !== AccountUniqueID
        );
        setAccountSelectedValues([...values]);
        handleAccountSelection([...values])

      } else {
        setAccountSelectedValues([...accountSelectedValues, AccountUniqueID]);
        handleAccountSelection([...accountSelectedValues, AccountUniqueID]);
      }
    }
  };

  function getCheckedValues(checkedNodes, type) {
    const checkedValues = [];
    const checkedValuesKey = [];
    checkedNodes.map((node) => {
      if (!node.isParentNode) {
        checkedValues.push(node.title);
        checkedValuesKey.push(node.key);
      }
    });
    type([...checkedValuesKey]);
    setTransactionTypeSelectedValues([...checkedValues]);
    handleTransactionTypeSelection([...checkedValues]);


  }

  const handleTransactionTypeChange = (checkedNodes) => {
    if (checkedNodes === undefined) {
      setTransactionTypeSelectedValuesKey([]);
      setTransactionTypeSelectedValues([]);
      handleTransactionTypeSelection([]);
    }
    else {
      getCheckedValues(
        checkedNodes,
        setTransactionTypeSelectedValuesKey
      );
    }

  };


  const buildTreeData = (data) => {
    const list = [];
    data.map((element) => {
      const treeNode = {
        title: element.toString(),
        key: data.indexOf(element),
      };
      list.push(treeNode);
    });
    return list;
  };


  function unique(arr, keyProps) {
    const kvArray = arr.map((entry) => {
      const key = keyProps.map((k) => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }
  const getAccountFilterOptions = () => {
    const filterOptions = [];
    let uniqueAccounts = [];
    let accountHistory = data;//filterActivityHistory();
    uniqueAccounts = unique(accountHistory, ['AccountDisplayName', 'AccountUniqueID']);
    uniqueAccounts.sort((a, b) =>
      a.AccountDisplayName > b.AccountDisplayName ? 1 : b.AccountDisplayName > a.AccountDisplayName ? -1 : 0
    );
    uniqueAccounts.map((account) => {
      filterOptions.push(
        <WrapperLi>
          <li
            key={account.AccountUniqueID}
            onClick={() => handleAccountChange(account.AccountUniqueID)}
          >
            <img
              src={
                accountSelectedValues.includes(account.AccountUniqueID)
                  ? CheckboxCheckedImg
                  : CheckboxImg
              }
              alt="Checkbox"
            />{' '}
            {account.AccountDisplayName.toString()}
          </li>
        </WrapperLi>
      );
    });
    return filterOptions;
  };


  const getTransFilterOptions = () => {
    let uniqueTransactionTypes = [];
    let accountHistory = data;//filterActivityHistory();
    accountHistory.map((x) => {
      if (uniqueTransactionTypes.indexOf(x.TransactionType) === -1) {
        uniqueTransactionTypes.push(x.TransactionType);
      }
    });
    uniqueTransactionTypes.sort();
    return (
      <ProductTreeFilter
        checkable
        selectedKeys={transactionTypeSelectedValuesKey}
        checkedKeys={transactionTypeSelectedValuesKey}
        onCheck={(_, info) => handleTransactionTypeChange(info.checkedNodes)}
        treeData={buildTreeData(uniqueTransactionTypes)}
        checkboxCheckedColor={`${theme.colors.blue_primay}`}
      />
    );
  };
  const updateModalStatus = () => {
    setIsDownloadModalVisible(false);
  };

  const showModal = () => {
    setIsDownloadModalVisible(true);
  };

  const handleCancel = () => {
    setIsDownloadModalVisible(false);
  };

  function applyFiltersToData() {
    let Activities = [...data];
    let FilteredActivity = [];
    if (
      transactionTypeSelectedValues.length > 0 ||
      accountSelectedValues.length > 0
    ) {
      FilteredActivity = Activities.filter(function (elem) {
        // the current value is an object, so you can check on its properties
        let TransactionTypefilter = true;
        let Accountfilter = true;

        if (transactionTypeSelectedValues.length > 0) {
          TransactionTypefilter = transactionTypeSelectedValues.includes(
            elem.TransactionType
          );
        }
        if (accountSelectedValues.length > 0 && isGlobalActivityHistoryPage) {
          Accountfilter = accountSelectedValues.includes(elem.AccountUniqueID);
        }
        return (
          TransactionTypefilter &&
          Accountfilter
        );
      });
    } else {
      FilteredActivity = Activities;
    }

    return FilteredActivity;
  }

  return (
    <Container>
      <Wrapper>
        <Box
          margin="0 0 24px 0"
          display="flex"
          justifycontent="space-between"
          alignitems="center"
          minwidth="100%"
          width="100%"
          className={inputClassName === 'hide' ? '' : 'expanded'}
        >
          <FilterCol size={8} overflow={inputClassName === 'show'}>
            {isGlobalActivityHistoryPage && (
              <MultiSelect
                storeArray={'clearAllAccounts'}
                filterClass={accountFilterClass}
                setFilterClass={setAccountFilterClass}
                selectedValues={accountSelectedValues}
                setSelectedValues={setAccountSelectedValues}
                options={getAccountFilterOptions()}
                buttonLabel={'Account'}
                height={'180px'}
                selectedBgColor={`${primary_color}`}
                selectedColor={`${theme.colors.white}`}
                closeButton={handleAccountChange}
              />
            )}
            <MultiSelect
              storeArray={'clearAllInvestmentTypes'}
              filterClass={transactionTypeFilterClass}
              setFilterClass={setTransactionTypeFilterClass}
              selectedValues={transactionTypeSelectedValuesKey}
              setSelectedValues={setTransactionTypeSelectedValuesKey}
              options={getTransFilterOptions()}
              buttonLabel={'Transaction Type'}
              minHeight={'150px'}
              height={'auto'}
              borderRadius={'2px'}
              dropdownPadding={'4px 0 4px 0'}
              selectedBgColor={primary_color}
              selectedColor={`${theme.colors.white}`}
              closeButton={handleTransactionTypeChange}
            />
          </FilterCol>
          <Box display="flex" alignitems="center">
            <Divider type="vertical" className="filter-divider" />
            <Tooltip
              placement="top"
              title="Download"
              overlayClassName="tooltip"
              defaultVisible={inputClassName === 'hide' ? false : true}
              visible={tooltip}
            >
              <ButtonAnt
                icon={<DownloadOutlined />}
                label={inputClassName === 'hide' ? 'Download' : ''}
                type="secondary"
                onClick={showModal}
                onMouseEnter={() =>
                  inputClassName === 'hide'
                    ? setTooltip(false)
                    : setTooltip(true)
                }
                onMouseLeave={() => setTooltip(false)}
              />
            </Tooltip>
          </Box>
        </Box>

        <Modal
          title="Download File Type"
          width={590}
          bodyStyle={{
            padding: '32px 32px 34px 32px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          content={
            <DownloadModal downloadData={createPendingActivityDownloadData(applyFiltersToData())} onCancel={updateModalStatus} />
          }
          visible={isDownloadModalVisible}
          onCancel={handleCancel}
          footer={null}
          centered={true}
        />
      </Wrapper>
    </Container>
  );
};

export default RenderFiltersPending;
