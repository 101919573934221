import React, { useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { theme, Text, Modal } from '@pfmam/pfmam';
import { ArrowLeftOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Radio, Form } from 'antd';
import { Anchor, Box } from '../../../styles/globalStyle';
import { OverrideStyle } from '../../../common/components/datePicker';
import ReviewStopPayment from './reviewStopPayments';
import InitiateStopPaymentForm from './initiateStopPaymentForm';
import accountDetails from '../../../data/AccountDetails.json';
import InvestmentModalContent from '../../../transactionPages/common/accInvestmentTypeModal';
import { useSelector } from 'react-redux';
export const InvestmentSetlectStyle = createGlobalStyle`
  .ant-radio-group {
    display: flex;
    flex-direction: column;
  } 
  .ant-radio-group {
    .ant-radio-button-wrapper {
      height: auto;
      padding: 8px 32px;
      border-color: ${theme.colors.grey};
      border-width: 0 0 1px 0;
      color: ${theme.colors.black_secondary};
      line-height: 24px;
      &.ant-radio-button-wrapper-checked {
        border-color: ${theme.colors.grey};
        
      }
      &:hover {
        color: ${theme.colors.black_secondary};
        background-color: ${theme.rgba.gradient_blue};
      }
      &:focus-within,
      &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: none;  
      }
      &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: ${theme.colors.transparent};
      }
    }
  } 
  .investment-input {
    .ant-input-affix-wrapper-disabled {
      background-color: ${theme.colors.transparent};
      cursor: default;
      .ant-input[disabled] {
        cursor: default;
      }
    }
  }
`;

const InitiateStopPaymentsComponent = ({
  initiateStopPayment,
  setInitiateStopPayment,
  accData,
  isGlobalStopPaymentsPage,
}) => {
  const [form] = Form.useForm();

  const [isReviewStopPayment, setIsReviewStopPayment] = useState(false);
  const [isStopPaymentCompleted, setIsStopPaymentCompleted] = useState(false);
  const [stopPaymentsData, setStopPaymentsData] = useState(null);
  const [showInvestmentTypeModal, setShowInvestmentTypeModal] = useState(false);
  const [accountName, setAccountName] = useState(
    accData ? accData.accName : ''
  );
  const [fundUniqueID,setFundUniqueID] = useState(0);
  const [accountUniqueID,setAccountUniqueID] = useState(0);
  const webuserAccessType = useSelector((state) => state.userSlice.webUserType);
  const getTitle = () => {
    let label = '';
    if (isStopPaymentCompleted) {
      label = 'Stop Payment Submitted';
    } else if (isReviewStopPayment) {
      label = 'Review Stop Payments';
    } else {
      label = 'Initiate Stop Payment';
    }
    return (
      <>
        <Text
          fontSize="36px"
          color={`${theme.colors.black_secondary}`}
          lineheight="44px"
          label={label}
          icon={
            isStopPaymentCompleted ? (
              <CheckCircleOutlined
                style={{ color: `${theme.colors.pie_green}` }}
              />
            ) : null
          }
          margin={
            isReviewStopPayment || isStopPaymentCompleted
              ? '12px 0 32px'
              : '12px 0 16px'
          }
        />
        {isReviewStopPayment || isStopPaymentCompleted ? null : (
          <Text
            label="Request a stop payment on a specific check. Please note that this request will not be in effect until the next business day. This request will automatically expire 90 days after check date."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_tertiary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
        )}
      </>
    );
  };
  const onInvestmentTypeChange = (e) => {
       form.setFieldsValue({
      accountinvestmentType:  { ...e.target.value },    
    });
    setFundUniqueID(e.target.value.type.FundUniqueID);
    setAccountUniqueID(e.target.value.AccountUniqueID);
    setAccountName(e.target.value.AccName);
    setShowInvestmentTypeModal(!showInvestmentTypeModal);
  }; 

  const transactioTypeId = 9;

  return (
    <>
      <OverrideStyle />
      <InvestmentSetlectStyle />
      <Box maxwidth="590px" width="100%" margin="0 auto" padding="80px 0 0">
        <Anchor
          onClick={() => setInitiateStopPayment(false)}
          fontsize={`${theme.font_size.size_l}`}
          color={`${theme.colors.black_secondary}`}
          lineheight="20px"
          display="inline-block"
          href= {isGlobalStopPaymentsPage ? '/activity/stop-payments' : ''}
        >
          <ArrowLeftOutlined style={{ marginRight: '6px' }} />
          Back to Stop Payments
        </Anchor>
        {getTitle()}

        <Box
          boxcolor={`${theme.colors.white}`}
          padding="32px 32px 48px"
          radius="4px"
        >
          {isReviewStopPayment || isStopPaymentCompleted ? (
            <ReviewStopPayment
              stopPaymentsData={stopPaymentsData}
              accountName={accountName}
              isStopPaymentCompleted={isStopPaymentCompleted}
              setIsStopPaymentCompleted={setIsStopPaymentCompleted}
              isReviewStopPayment={isReviewStopPayment}
              setIsReviewStopPayment={setIsReviewStopPayment}
              initiateStopPayment={initiateStopPayment}
              setInitiateStopPayment={setInitiateStopPayment}
              form={form}
              fundUniqueID ={fundUniqueID}
              accountUniqueID ={accountUniqueID}
              webuserAccessType = {webuserAccessType}
            />
          ) : (
            <InitiateStopPaymentForm
              stopPaymentsData={stopPaymentsData}
              setStopPaymentsData={setStopPaymentsData}
              setIsReviewStopPayment={setIsReviewStopPayment}
              setIsStopPaymentCompleted={setIsStopPaymentCompleted}
              showInvestmentTypeModal={showInvestmentTypeModal}
              setShowInvestmentTypeModal={setShowInvestmentTypeModal}
              setInitiateStopPayment={setInitiateStopPayment}
              form={form}
              accountName={accountName}
              isGlobalStopPaymentsPage={isGlobalStopPaymentsPage}
              fundUniqueID ={fundUniqueID}
              accountUniqueID ={accountUniqueID}
              webuserAccessType = {webuserAccessType}
            />
          )}
        </Box>
      </Box>
      {showInvestmentTypeModal && (
        <Modal
          title="Investment Type"
          width={590}
          bodyStyle={{
            padding: '0 0 64px 0',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          content={<InvestmentModalContent
            form={form}
            onInvestmentTypeChange={onInvestmentTypeChange}
            showAmount={false}
            pendingTransactionTypeClassID = {transactioTypeId}
            disableZeroAccounts={false}
          />}
          visible={showInvestmentTypeModal}
          onCancel={() => setShowInvestmentTypeModal(!showInvestmentTypeModal)}
          footer={null}
          centered={true}
        />
      )}
    </>
  );
};

export default InitiateStopPaymentsComponent;
