import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Layout, Space } from 'antd';
import {
  theme,
  Text,
  InputComponent,
  TableComponent,
} from '@pfmam/pfmam';
import HeaderComponent from '../common/components/Header';
import { Box } from '../styles/globalStyle';
import { GetProgramPhoneNumber } from '../actions/actions';

const { Content } = Layout;

const LayoutWrapper = styled(Layout)`
  background-color: ${theme.colors.light_grey};
  min-height: 100vh;
`;
const Wrpaper = styled(Content)`
  min-height: calc(100vh - 200px);
  padding-bottom: 44px;
  .search {
    .ant-input {
      height: 100%;
      &:hover {
        border-color: ${theme.colors.grey};
      }
      &:focus {
        box-shadow: ${theme.shadows.input_focus};
        border-color: ${theme.colors.blue_primay};
      }
    }
  }
`;

const NoClient = () => {
  const [noClientDisplayText, setNoClientDisplayText] = useState('');

  useEffect(() => {
    GetProgramPhoneNumber(1)
      .then((response) => {
        if (response.data != null)
          setNoClientDisplayText(
            'Please call client services at ' +
              response.data +
              ' to correct the issue.'
          );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <LayoutWrapper>
      <Wrpaper>
        <HeaderComponent timelineHeader={true} hasClients={false} />
        <Box
          padding="18px 0 0 246px"
          margin="0 0 18px 0"
          className="input-wrapper"
        >
          <Text
            color={`${theme.colors.clean_black}`}
            fontSize="26px"
            lineheight="44px"
            label="There is a problem with the setup of your account."
          />
          <Text
            color={`${theme.colors.clean_black}`}
            fontSize="26px"
            lineheight="44px"
            label={noClientDisplayText}
          />
        </Box>
      </Wrpaper>
    </LayoutWrapper>
  );
};

export default NoClient;
