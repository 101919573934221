import React from 'react';
import styled from 'styled-components';
import {
  theme,
  Text,
  TableComponent,
  ButtonAnt,
} from '@pfmam/pfmam';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Box, Container } from '../../../styles/globalStyle';
import NoDataFound from '../../../common/components/noDataFound';
import AccountActions from '../accountActions';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  padding-top: 40px;
  .table-primary {
    .ant-table {
      .ant-table-tbody {
        & > tr {
          & > td {
            vertical-align: middle;
          }
        }
      }
    }
  }
`;
const PeerAccountsContainer = ({
  peerAccounts,
  handleAction,
  handleMenuClick,
  isGlobalSettingsPage,
}) => {
  const columns = [
    {
      title: 'Participant Name',
      dataIndex: 'participantName',
      key: 'participantName',
      align: 'left',
      sorter: (a, b) => a.participantName.localeCompare(b.participantName),
      className: 'sort-space-none',
    },
    {
      title: 'Product Types',
      dataIndex: 'productType',
      key: 'productType',
      sorter: (a, b) => a.productType.localeCompare(b.productType),
      className: 'sort-space-none',
      // render: (text) => (
      //   <>
      //     {text && text.length > 0 ? (
      //       text.map((product) => {
      //         return (
      //           <Text
      //             fontSize={`${theme.font_size.size_xl}`}
      //             lineheight="20px"
      //             label={product}
      //           />
      //         );
      //       })
      //     ) : (
      //       <Text
      //         fontSize={`${theme.font_size.size_xl}`}
      //         lineheight="20px"
      //         label={'- - - -'}
      //       />
      //     )}
      //   </>
      // ),
    },
    {
      title: '',
      dataIndex: 'righticon',
      key: 'righticon',
      align: 'right',
      className: 'action-cell',
      render: (_, record) => (
        <AccountActions
          type="Participant"
          record={record}
          handleMenuClick={handleMenuClick}
          isGlobalSettingsPage={isGlobalSettingsPage}
        />
      ),
    },
  ];
  return (
    <Container>
      <Wrapper>
        <TableComponent
          className="table-primary"
          title={
            <Box
              display="flex"
              justifycontent="space-between"
              alignitems="center"
              padding="24px 48px 24px 32px"
              border={`1px solid ${theme.colors.grey_fossil}`}
              borderwidth="0 0 1px 0"
            >
              <Text
                label="Linked Participant Accounts"
                fontSize={`${theme.font_size.size_3xl}`}
                lineheight="32px"
                color={`${theme.colors.black_tertiary}`}
              />
              {/* {isGlobalSettingsPage ? (
                <ButtonAnt
                  type="link"
                  label="Add Participant Account"
                  icon={<PlusCircleOutlined />}
                  onClick={(e) => {
                    handleMenuClick(e, null, 'Participant');
                  }}
                />
              ) : (
                <Link
                  to={{
                    pathname: '/settings',
                    accType: 'Participant',
                  }}
                >
                  <ButtonAnt type="link" label="Manage Participant Accounts" />
                </Link>
              )} */}
            </Box>
          }
          columns={columns}
          dataSource={peerAccounts}
          locale={{
            emptyText: <NoDataFound text="No Participant accounts" />,
          }}
          showSorterTooltip={false}
          pagination={
            peerAccounts.length > 10
              ? {
                  position: ['bottomRight'],
                  showQuickJumper: true,
                  size: 'default',
                }
              : false
          }
          onRow={(record) => ({
            onClick: () => {
              handleAction('Participant', 'details', record);
            },
          })}
        />
      </Wrapper>
    </Container>
  );
};

export default PeerAccountsContainer;
