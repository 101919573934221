/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Form, Input,Space, Divider, Row, Col, message } from 'antd';
import styled, { css } from 'styled-components';
import {
  theme,
  InputComponent,
  Text,
  ButtonAnt,
} from '@pfmam/pfmam';
import { Box, Anchor } from '../../styles/globalStyle';
import { GetContactEmailIds,UpdateConnectFailedLoginAttempts } from '../../actions/actions';
import { useSelector, useDispatch } from 'react-redux';
import { signoutRedirect } from '../../services/userService';

const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
  }
`;
const ButtonWrapper = styled(Space)`
  justify-content: start;
  width: 100%;
  min-width: 100%;
  margin-top: 16px;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
`;
const SearchButtonWrapper= styled(Space)`
  justify-content: space-between;
  width: 100%;
  min-width: 100%;
  margin-top: 16px;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
`;

const ButtonWrapper1 = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 16px;
    `}
`;
const Title = styled(Text)`
  font-family: 'Inter Semibold';
`;
const Wrapper = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;
const RegularText = styled.span`
  color: ${(props) => props.color};
  font-size: 16px;
  line-height: 24px;  
`;
export const UserSearch = ({ setUsers,setStep,errorCount,setErrorCount}) => {
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const [disableUsername, setdisableUsername] = useState(false);
  const [disablename, setdisablename] = useState(false);
  const [disableSubmit, setdisableSubmit] = useState(true);
  const [validationMessage, setvalidationMessage] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSlice.user);
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const programId = clientDetails.payload[0].programid;
  
  const handleBlur = (e) => {
    const value = e.target.value;
    const name = e.target.getAttribute('data-name');
    const trimmedValue = value.trim();
    form.setFieldsValue({ [name]: trimmedValue });
  }
  const onFinishwithAsync = async (values) => {
    console.log(values);
      if (values.firstName === undefined && values.lastName === undefined && values.uname === undefined) {
        setvalidationMessage("Please enter First & Last Name OR Username");
      }
      else if (values.firstName === '' && values.lastName === '' && values.uname === undefined) {
        setvalidationMessage("Please enter First & Last Name OR Username");
      }
      else if (disableUsername && (values.firstName === undefined || values.firstName === '')) {
        setvalidationMessage("Please enter First Name");
      }
      else if (disableUsername && (values.lastName === undefined || values.lastName === '')) {
        setvalidationMessage("Please enter Last Name");
      }
      else if (disablename && (values.uname === undefined || values.uname === '')) {
        setvalidationMessage("Please enter Username");
      }
      else{
        GetContactEmailIds(values.uname, values.firstName, values.lastName,programId).then((response) => {
          if (response.data.Table.length > 0) {
            setUsers(response.data.Table);
            setStep(3);
          }
          else{
            if(errorCount< 5){
              setErrorCount(errorCount+1);
              setStep(4);
            }
            else{
              UpdateConnectFailedLoginAttempts(true).then((response) => {
                if(response.data){
                  signoutRedirect(user.id_token, dispatch);
                }
              }).catch((err) => {
                console.log(err);
              });
            }
          }
        }).catch((err) => {
          console.log(err);
        });
      }
  };
  return (
    <Box>
      <Text
        label={'Search By Name or Username'}
        fontSize="25px"
        lineheight="42px"
        color={`${theme.colors.black_secondary}`}
        margin="0 0 32px"
      />
      <Form
        form={form}
        name="basic"
        layout="vertical"
        onFinish={onFinishwithAsync}
        className="forms"
      >
        <Row gutter="10">
          <>
            <Col span={24}>
              <Form.Item
                label="First Name"
                name="firstName"
              >
                <InputComponent
                  type="default"
                  placeholder="Search By First Name"
                  MaxLength="50"
                  onChange={(e) => {
                    const newData = form.getFieldValue();
                    const fname1=newData["firstName"];
                    const lname1=newData["lastName"];
                    if(lname1!=undefined && lname1.length > 0 && (fname1==undefined || fname1=='') || fname1!=undefined && fname1.length > 0 && (lname1==undefined || lname1=='') || fname1!=undefined && fname1.length > 0 || fname1!='' && fname1.length > 0)
                    {
                        setdisableUsername(true);
                        setdisableSubmit(false);
                        setvalidationMessage('');
                    }
                    else {
                      setdisableUsername(false);
                      setdisableSubmit(true);
                      setvalidationMessage('');
                    }
                  }}
                  data-name="firstName"
                  onBlur={handleBlur}
                  disabled={disablename}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Last Name"
                name="lastName"
              >
                <InputComponent
                  type="default"
                  placeholder="Search By Last Name"
                  MaxLength="50"
                  onChange={(e) => {
                    const newData = form.getFieldValue();
                    const fname1=newData["firstName"];
                    const lname1=newData["lastName"];
                    if(fname1!=undefined && fname1.length > 0 && (lname1==undefined || lname1=='') || lname1!=undefined && lname1.length > 0 && (fname1==undefined || fname1=='') || lname1!=undefined && lname1.length > 0 || lname1!='' && lname1.length > 0)
                    {
                        setdisableUsername(true);
                        setdisableSubmit(false);
                        setvalidationMessage('');
                    }
                    else {
                      setdisableUsername(false);
                      setdisableSubmit(true);
                      setvalidationMessage('');
                    }
                  }}
                  data-name="lastName"
                  onBlur={handleBlur}
                  disabled={disablename}
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <p style={{ color: 'red' }}>{validationMessage}</p>
            </Col>
            <Col span={24}>
            <Divider orientation="middle">OR</Divider>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Username"
                name="uname">
                <InputComponent
                  onBlur={handleBlur}
                  data-name="uname"
                  type="default"
                  placeholder="Search By Username"
                  MaxLength="25"
                  disabled={disableUsername}
                  onChange={(e) => {
                    const newData = form.getFieldValue();
                    const username=newData["uname"];
                    if (username!='' && username.length >0 || username!=undefined && username.length >0) {
                      setdisablename(true);
                      setdisableSubmit(false);
                      setvalidationMessage('');
                    }
                    else {
                      setdisablename(false);
                      setdisableSubmit(true);
                      setvalidationMessage('');
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </>
        </Row>
        <ButtonWrapper size={12}>
          <Anchor
            fontsize={`${theme.font_size.size_l}`}
            lineheight="20px"
            color={`${theme.colors.blue_primay}`}
            display="inline-block"
            onClick={() => {
              form.resetFields();
              setdisableUsername(false);
              setdisablename(false);
              setvalidationMessage('');
            }}
          >
            Reset Search
          </Anchor>
        </ButtonWrapper>
        <SearchButtonWrapper size={12} spacing>
        <ButtonAnt
              label="Back"
              type="secondary"
              htmlType="button"
              onClick={() => {
                setStep(1);
              }}
            />
          <OverrideButtonAnt
            linkTextColor={primary_color} label="Search" type="primary"
            disabled={disableSubmit}
            htmlType="submit"
          />
        </SearchButtonWrapper>
      </Form>
    </Box>

  );
};


