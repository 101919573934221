/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import {
  theme,
  Text,
  ButtonAnt,
  Tabs,
} from '@pfmam/pfmam';
import {
  storeFilteredProductTypes,
  storeFilteredTransactionTypes,
  storeInputSearch,
} from '../services/accountProfileSlice';
import { Link } from 'react-router-dom';
import { Container, Box, Banner } from '../styles/globalStyle';
import AppLayout from '../common/layouts/appLayout';
import ActivityHistoryContainer from '../activityHistory/activityHistoryContainer';
import PendingActivityContainer from '../activityHistory/pendingActivityContainer';
import DividendHistoryContainer from '../activityHistory/dividendHistoryContainer';
import HistoricalHoldingsContainer from './historicalHoldingsContainer';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  GetClientOrAccountLevelTransPermission,
} from '../actions/actions';
import StopPaymentsContainer from '../activityHistory/stopPaymentsContainer';
const { Content } = Layout;

const ContentWrapper = styled(Content)`
  .cards {
    &:first-child {
      margin-bottom: 24px;
    }
    .ant-card-head {
      padding: 0 32px;
      font-weight: normal;
      border-color: ${theme.colors.grey_fossil};
    }
    .ant-card-head-title {
      font-size: ${theme.font_size.size_3xl};
      color: ${theme.colors.black_tertiary};
      line-height: 32px;
      padding: 24px 0;
    }
    .ant-card-body {
      padding: 12px 32px 32px;
    }
    &.ant-card {
      border-radius: 4px;
      border: 0;
    }
  }

  .pending-activity-card {
    .ant-card-body {
      padding: 0;
    }
  }
`;


const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
  }
`;

const ClientActivityHistoryContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState('1');

  const webuserAccessType = useSelector((state) => state.userSlice.webUserType);
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const selectedAccount = useSelector(
    (state) => state.accountProfileSlice.selectedAccount
  );
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const rgbaPrimary = useSelector((state) => state.siteInfo.rgbaPrimary);
  const secondary_color = useSelector((state) => state.siteInfo.secondaryColor);
  const ssnorTIN = clientDetails.payload[0].ssnortin;

  const [isMoveFundsVisible, setIsMoveFundsVisible] = useState(false);


  const ProgramLevelTransPermission = useSelector(
    (state) => state.siteInfo.allowOnlineTransact
  );
  const isStopPaymentEnabledForFund = useSelector((state) =>
    state.menuPermissions.menuPermissionOptions.includes(9)
  );
  const accountProfile = useSelector(
    (state) => state.accountProfileSlice.accountProfile
  );
  const clientHasAccounts = accountProfile.AccountDetails.length > 0 ? true : false;
  const isZeroBalClient=useSelector((state)=>state.programsClientsSlice.selectedClient.payload[0].current_balance!=="( $0.00 )");
  const [showStopPayment, setShowStopPayment] = useState(false);

  useEffect(() => {
    if (ProgramLevelTransPermission == true) {
      //Check if Fund Group allows stop payment
      if(isStopPaymentEnabledForFund){
        if (webuserAccessType == 0) {
          if (props.isGlobal) {
            GetClientOrAccountLevelTransPermission(ssnorTIN, null)
              .then((response) => {
                if (response.data === true) {
                  setShowStopPayment(true && (isZeroBalClient || clientHasAccounts));
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            GetClientOrAccountLevelTransPermission(
              ssnorTIN,
              selectedAccount.AccountUniqueID
            )
              .then((response) => {
                if (response.data === true) {
                  setShowStopPayment(true && (isZeroBalClient || clientHasAccounts));
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } else {
          setShowStopPayment(true && (isZeroBalClient || clientHasAccounts));
        }
      }
    }
  }, [ProgramLevelTransPermission, props.isGlobal,isStopPaymentEnabledForFund]);
  

  function setActiveTabAndStore(key) {
    setActiveTab(key);
    dispatch(storeInputSearch(''));
    dispatch(storeFilteredProductTypes([]));
    dispatch(storeFilteredTransactionTypes([]));
  }


  return (
    <AppLayout>
       <Banner bgcolor={primary_color} height="124px">
          <Box
            display="flex"
            alignitems="center"
            height="100%"
            minwidth="100%"
            width="100%"
            zindex="3"
            position="relative"
            padding="44px 0 36px 0"
          >
            <Container>
              <Box
                display="flex"
                justifycontent="space-between"
                alignitems="flex-start"
                margin="0"
              >
                <Box flex="1">
                  <Text
                    color={`${theme.colors.white}`}
                    fontSize="36px"
                    lineheight="44px"
                    label="Activity History"
                  />
                </Box>
                {showStopPayment && activeTab==='1' ? (
                        <Link
                          to={{
                            pathname: '/activity/stop-payments',
                          }}
                        >
                          <OverrideButtonAnt
                            icon={false}
                            label="Stop Payments"
                            type="button"
                            linkTextColor={secondary_color}
                          />
                        </Link>
                      ) : null}
               
              </Box>
            </Container>
          </Box>
        </Banner>
        <ContentWrapper>
          <>
            <Tabs
              activeTabColor={primary_color}
              defaultActiveKey={activeTab}
              activeKey={activeTab}
              onTabClick={(key) => setActiveTabAndStore(key)}
              tabsOptions={ProgramLevelTransPermission? [
                
                {
                  content: (
                    <ActivityHistoryContainer
                      {...props}
                    />
                  ),
                  key: '1',
                  label: 'Activity History',
                },
                {
                  content: (
                    <PendingActivityContainer
                      linkTextColor={primary_color}
                      rgbaPrimaryHover={rgbaPrimary}
                      setActiveTab={setActiveTab}
                      isGlobal={true}
                    />
                  ),
                  key: '2',
                  label: 'Pending Activity',
                },
                {
                  content: (
                    <DividendHistoryContainer
                      linkTextColor={primary_color}
                      rgbaPrimaryHover={rgbaPrimary}
                      setActiveTab={setActiveTab}
                    />
                  ),
                  key: '3',
                  label: 'Dividend History',
                },
                {
                  content: (
                    <HistoricalHoldingsContainer
                      linkTextColor={primary_color}
                      rgbaPrimaryHover={rgbaPrimary}
                      setActiveTab={setActiveTab}
                    />
                  ),
                  key: '4',
                  label: 'Historical Holdings',
                },
              ]: [
                
                {
                  content: (
                    <ActivityHistoryContainer
                      {...props}
                    />
                  ),
                  key: '1',
                  label: 'Activity History',
                },
                {
                  content: (
                    <HistoricalHoldingsContainer
                      linkTextColor={primary_color}
                      rgbaPrimaryHover={rgbaPrimary}
                      setActiveTab={setActiveTab}
                    />
                  ),
                  key: '2',
                  label: 'Historical Holdings',
                },
              ]}
              type="horizontal-tab"
            />
          </>
        </ContentWrapper>
    </AppLayout>
  );
};

export default ClientActivityHistoryContainer;
