import React,{useState} from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { theme, Text, ButtonAnt, Modal } from '@pfmam/pfmam';
import { Space,message } from 'antd';
import { Box } from '../../../styles/globalStyle';
import ListWrapperComponent from '../../../common/components/listWrapper';
import moment from 'moment';
import { amountFormat } from '../../../utils/numberFormat';
import {
  IsDuplicateTransactionStopPayments,
  SubmitStopPayment,  
} from '../../../actions/actions';
import DuplicateTransactionModalContent from '../../../transactionPages/common/duplicateTransactionModalContent';
const ButtonWrapper = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
`;

const ReviewStopPayment = ({
  stopPaymentsData,
  accountName,
  isStopPaymentCompleted,
  setIsStopPaymentCompleted,
  isReviewStopPayment,
  setIsReviewStopPayment,
  initiateStopPayment,
  setInitiateStopPayment,
  form,
  fundUniqueID,
  accountUniqueID,
  webuserAccessType,
}) => {  
  const [confirmationNumber, setConfirmationNumber] = useState();
  var date = new Date();
  const history = useHistory();
  const [isDuplicateTransModalVisible, setIsDuplicateTransModalVisible] = useState(false);
  const [duplicateTransData, setDuplicateTransData] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const duplicateTransColumns = [
    {
      title: 'Confirmation #',
      dataIndex: 'Confirm #',
      key: 'Confirm #',
      width: 175,
      align: 'left',
    },
    {
      title: 'Account Number',
      dataIndex: 'Account Number',
      key: 'Account Number',
      width: 120,
      align: 'left',
      render: (text, record) => (
        <>
        <Text
          label={text}
          color={`${theme.colors.black_secondary}`}
          fontSize={`${theme.font_size.size_xl}`}
          lineheight="24px"
        />
        </>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      width: 70,
      align: 'left',
      render: (text) => <>{amountFormat(text)}</>,
    },
    {
      title: 'Transaction Type',
      dataIndex: 'Transaction Type',
      key: 'Transaction Type',
      width: 130,
      align: 'left',  
    },
    {
      title: 'Check Date',
      dataIndex: 'Check Date',
      key: 'Check Date',
      width: 120,
      align: 'left',
      render: (text, record) => (
        <>
        <Text
          label={moment(text).format('MMM DD, YYYY')}
          color={`${theme.colors.black_secondary}`}
          fontSize={`${theme.font_size.size_xl}`}
          lineheight="24px"
        />        
        </>
      ),
    },  
    {
      title: 'Created By',
      dataIndex: 'Created By',
      key: 'Created By',
      width: 120,
      align: 'left',      
    },
    {
      title: 'Created',
      dataIndex: 'Created Date',
      key: 'Created Date',
      width: 148,
      align: 'left', 
      render: (text, record) => (
        <>
        <Text
          label={new Date(text).toLocaleString()}
          color={`${theme.colors.black_secondary}`}
          fontSize={`${theme.font_size.size_xl}`}
          lineheight="24px"
        />        
        </>
      ),     
    }, 
    {
      title: 'Created Source',
      dataIndex: 'Created Source',
      key: 'Created Source',
      width: 170,
      align: 'right',      
    }, 
  ];

  const handleDuplicateModalSubmit = () => {
    setIsDuplicateTransModalVisible(false);    
    submitTransaction();
  };

  const handleDuplicateModalCancel = () => {
    setIsDuplicateTransModalVisible(false);
    setDisableSubmit(false);     
  };

  function onDuplicateModalCancel() {
    setIsDuplicateTransModalVisible(false);
    setDisableSubmit(false);     
  }


  const onFinish= () => {
    setDisableSubmit(true);
    //Checking for duplicate Transactions
    IsDuplicateTransactionStopPayments(new Date(stopPaymentsData.date).toDateString(), accountUniqueID,accountUniqueID ,stopPaymentsData.amount, 9)
    .then((response) => {
      if (response.data !== null && response.data.Table.length > 0) {
        setDuplicateTransData(response.data.Table);
        setIsDuplicateTransModalVisible(true);
      }
      else {
        //Submit transaction
        submitTransaction();
      }
    })
    .catch((error) => {
      console.log(error);
      setDisableSubmit(false);  
    });
  }

  const submitTransaction = () => {
    SubmitStopPayment(accountUniqueID,fundUniqueID,stopPaymentsData.date,stopPaymentsData.checkNumber,stopPaymentsData.payee,stopPaymentsData.reasonForStop,webuserAccessType,stopPaymentsData.amount)
    .then((response) => {
      if (response.data !== null && response.data.length > 0) {
        setConfirmationNumber(response.data);
        setIsStopPaymentCompleted(true);
        setDisableSubmit(false);  
      }     
    })
    .catch((error) => {
      console.log(error);
    }); 
  }
  return (
    <>
      <Box>
        {isStopPaymentCompleted && (
          <ListWrapperComponent label="Confirmation #" value={confirmationNumber} />
        )}
        <ListWrapperComponent
          label="Account/Investment"
          value={
            <>
              <Text
                label={accountName}
                color={`${theme.colors.black_secondary}`}
                fontSize={`${theme.font_size.size_xl}`}
                lineheight="24px"
              />
              <Text
                label={stopPaymentsData.accountinvestmentType.type.FundShortName}
                color={`${theme.colors.grey_tertiary}`}
                fontSize={`${theme.font_size.size_xl}`}
                margin="4px 0 0"
                lineheight="20px"
              />
            </>
          }
        />
        <ListWrapperComponent
          label="Check Payee"
          value={stopPaymentsData.payee}
        />
        <ListWrapperComponent
          label="Check Number"
          value={stopPaymentsData.checkNumber}
        />
        <ListWrapperComponent
          label="Check Amount"
          value={amountFormat(stopPaymentsData.amount, 2)}
        />
        <ListWrapperComponent
          label="Reason for Stop"
          value={stopPaymentsData.reasonForStop}
        />
        <ListWrapperComponent
          label="Check Date"
          value={moment(stopPaymentsData.date).format('MMM DD, YYYY')}
        />
        <ListWrapperComponent
          label="Entry Date"
          value={moment(date).format('MMM DD, YYYY')}
        />
      </Box>
      <ButtonWrapper size={12} spacing>
        <ButtonAnt
          label={
            isStopPaymentCompleted ? 'Initiate Another Stop Payment' : 'Cancel'
          }
          type="secondary"
          htmlType="button"
          onClick={() => {
            if (isStopPaymentCompleted) {
              form.resetFields();
              setIsStopPaymentCompleted(false);
              setIsReviewStopPayment(false);
            } else {
              setIsReviewStopPayment(false);
            }
          }}
        />
        <ButtonAnt
          label={isStopPaymentCompleted ? 'Done' : 'Submit'}
          type="primary"
          htmlType="submit"
          disabled={disableSubmit}
          onClick={() => {
            if (isStopPaymentCompleted) {
              form.resetFields();
              setInitiateStopPayment(false);
              history.push('/dashboard');
            } else {
              
              onFinish();
            }
          }}
        />
      </ButtonWrapper>
      {isDuplicateTransModalVisible && (
      <Modal
        title="Possible Duplicate Transaction"
        width='77%'
        bodyStyle={{
          padding: '32px 0 40px',
        }}
        maskStyle={{
          backgroundColor: `${theme.rgba.modal}`,
          zIndex: '1051',
        }}
        footer={null}
        visible={isDuplicateTransModalVisible}
        onCancel={onDuplicateModalCancel}
        content={
          <DuplicateTransactionModalContent
            columns={duplicateTransColumns}
            data={duplicateTransData}
            handleDuplicateModalCancel={handleDuplicateModalCancel}
            handleDuplicateModalSubmit={handleDuplicateModalSubmit}
          />
        }
        centered={true}
      />
    )}
    </>
  );
};

export default ReviewStopPayment;
