import moment from 'moment';

export function GetPendingActivityDetailsData(data, additionalData) {
  var responseData = {};
  responseData['Amount'] = data.Amount;
  responseData['Confirmation Number'] = data.ReferenceID;
  responseData['LinkID'] = data.ReferenceID;
  responseData['PendingTransactionTypeID'] = data.PendingTransactionTypeID;
  responseData['Cancellable'] = data.Cancellable;
  responseData['Approved'] = data.Approved;
  responseData['IsInitiator'] = data.IsInitiator;
  var transactionDate = moment(data.Date).format('YYYY-MM-DD');
  var submittedDateTime = moment(data.SubmittedDateTime).format(
    'YYYY-MM-DD HH:mm:ss A'
  );

  switch (data.PendingTransactionTypeID) {
    case '1': //ACH Purchase
      responseData['Client'] = data.ClientName;
      responseData['Account'] = data.AccountDisplayName;
      responseData['Investment Type'] = data.FundName;
      responseData['Date Entered'] = submittedDateTime;
      responseData['Entered From'] = data.CreatedSource;
      responseData['Entered By'] = data.SubmittedBy;
      responseData['Description'] = 'ACH Purchase';
      responseData['ACH Instruction'] = additionalData.Instructions;
      responseData['NickName'] = additionalData.NickName;
      responseData['Status'] = data.Status;
      break;
    case '2': //ACH Redemption
      responseData['Client'] = data.ClientName;
      responseData['Account'] = data.AccountDisplayName;
      responseData['Investment Type'] = data.FundName;
      responseData['Date Entered'] = submittedDateTime;
      responseData['Transaction Date'] = transactionDate;
      responseData['Entered From'] = data.CreatedSource;
      responseData['Entered By'] = data.SubmittedBy;
      responseData['Description'] = 'ACH Redemption';
      responseData['ACH Redemption'] = additionalData.Instructions;
      responseData['NickName'] = additionalData.NickName;
      responseData['Status'] = data.Status;
      break;
    case '3': //Wire Purchase
      responseData['Client'] = data.ClientName;
      responseData['Account'] = data.AccountDisplayName;
      responseData['Investment Type'] = data.FundName;
      responseData['Date Entered'] = submittedDateTime;
      responseData['Transaction Date'] = transactionDate;
      responseData['Entered From'] = data.CreatedSource;
      responseData['Entered By'] = data.SubmittedBy;
      responseData['Description'] = 'Wire Purchase';
      responseData['Status'] = data.Status;
      break;
    case '4': //Wire Redemption
      responseData['Client'] = data.ClientName;
      responseData['Account'] = data.AccountDisplayName;
      responseData['Investment Type'] = data.FundName;
      responseData['Date Entered'] = submittedDateTime;
      responseData['Transaction Date'] = transactionDate;
      responseData['Entered From'] = data.CreatedSource;
      responseData['Entered By'] = data.SubmittedBy;
      responseData['Wire Instruction'] = additionalData.Instructions;
      responseData['NickName'] = additionalData.NickName;
      responseData['Description'] = 'Wire Redemption';
      responseData['Status'] = data.Status;
      break;
    case '5':
    case '6': //Transfer
      responseData['Client'] = additionalData.Client;
      responseData['Date Entered'] = submittedDateTime;
      responseData['Transaction Date'] = transactionDate;
      responseData['Entered From'] = data.CreatedSource;
      responseData['Entered By'] = data.SubmittedBy;
      responseData['From Account'] = additionalData.FromAccount;
      responseData['Investment Type'] = additionalData.InvestmentType;
      responseData['Transfer To Account'] = additionalData.ToAccount;
      responseData['Description'] = 'Transfer';
      responseData['Status'] = data.Status;
      break;
    case '7':
    case '8': //Exchange
      responseData['Client'] = additionalData.Client;
      responseData['Date Entered'] = submittedDateTime;
      responseData['Transaction Date'] = transactionDate;
      responseData['Entered From'] = data.CreatedSource;
      responseData['Entered By'] = data.SubmittedBy;
      responseData['From Account'] = additionalData.FromAccount;
      responseData['Investment Type'] = additionalData.InvestmentType;
      responseData['Exchange To Investment Type'] =
        additionalData.ExchangeToInvestmentType;
      responseData['Description'] = 'Exchange';
      responseData['Status'] = data.Status;
      break;
    case '17': //ACH Purchase Notification
      responseData['Client'] = data.ClientName;
      responseData['Account'] = data.AccountDisplayName;
      responseData['Investment Type'] = data.FundName;
      responseData['Date Entered'] = submittedDateTime;
      responseData['Transaction Date'] = transactionDate;
      responseData['Entered From'] = data.CreatedSource;
      responseData['Entered By'] = data.SubmittedBy;
      responseData['Description'] = 'ACH Purchase Notification';
      responseData['Status'] = data.Status;
      break;
    case '18': //ACH Redemption Notification
      responseData['Transaction Type'] = 'ACH Redemption Notification';
      responseData['Entry Date'] = new Date();
      responseData['Transaction Date'] = transactionDate;
      responseData['Account'] = data.AccountDisplayName;
      responseData['Investment Type'] = data.FundName;
      break;
    case '21': //User Defined
      responseData['Client'] = data.ClientName;
      responseData['Account'] = data.AccountDisplayName;
      responseData['Investment Type'] = data.FundName;
      responseData['Date Entered'] = submittedDateTime;
      responseData['Entered From'] = data.CreatedSource;
      responseData['Entered By'] = data.SubmittedBy;
      responseData['Description'] = 'User Defined';
      responseData['Status'] = data.Status;
      break;
    case '25':
    case '26': //Participant to Participant Transfer
      responseData['Client'] = additionalData.Client;
      responseData['Date Entered'] = submittedDateTime;
      responseData['Transaction Date'] = transactionDate;
      responseData['Entered From'] = data.CreatedSource;
      responseData['Entered By'] = data.SubmittedBy;
      responseData['From Account'] = additionalData.FromAccount;
      responseData['Investment Type'] = additionalData.InvestmentType;
      responseData['Transfer To Account'] = additionalData.ToAccount;
      responseData['P2P Instruction'] = additionalData.Instructions;
      responseData['Description'] = 'Participant to Participant Transfer';
      responseData['Status'] = data.Status;
      break;
    case '100': //Redemption for CD Purchase
      responseData['Client'] = data.ClientName;
      responseData['Account'] = data.AccountDisplayName;
      responseData['Investment Type'] = data.FundName;
      responseData['Date Entered'] = submittedDateTime;
      responseData['Entered From'] = data.CreatedSource;
      responseData['Entered By'] = data.SubmittedBy;
      responseData['Description'] = 'Redemption for CD Purchase';
      responseData['Status'] = data.Status;
      break;
    case '101': //Redemption for TERM Purchase
      responseData['Client'] = data.ClientName;
      responseData['Account'] = data.AccountDisplayName;
      responseData['Investment Type'] = data.FundName;
      responseData['Date Entered'] = submittedDateTime;
      responseData['Entered From'] = data.CreatedSource;
      responseData['Entered By'] = data.SubmittedBy;
      responseData['Description'] = 'Redemption for TERM Purchase';
      responseData['Status'] = data.Status;
      break;
    case '102': //Purchase from CD Maturity
      responseData['Client'] = data.ClientName;
      responseData['Account'] = data.AccountDisplayName;
      responseData['Investment Type'] = data.FundName;
      responseData['Date Entered'] = submittedDateTime;
      responseData['Entered From'] = data.CreatedSource;
      responseData['Entered By'] = data.SubmittedBy;
      responseData['Description'] = 'Purchase from CD Maturity';
      responseData['Status'] = data.Status;
      break;
    case '103': //Purchase from CD Early Redemption
      responseData['Client'] = data.ClientName;
      responseData['Account'] = data.AccountDisplayName;
      responseData['Investment Type'] = data.FundName;
      responseData['Date Entered'] = submittedDateTime;
      responseData['Entered From'] = data.CreatedSource;
      responseData['Entered By'] = data.SubmittedBy;
      responseData['Description'] = 'Purchase from CD Early Redemption';
      responseData['Status'] = data.Status;
      break;
    default:
  }

  return responseData;
}

export function GetEnvisionPendingActivityDetailsData(data) {
  var responseData = {};
  var submittedDateTime = moment(data.SubmittedDateTime).format(
    'YYYY-MM-DD HH:mm:ss A'
  );
  responseData['Cancellable'] = data.Cancellable;
  responseData['Amount'] = data.Amount;
  responseData['Confirmation Number'] = data.ReferenceID;
  responseData['Date Entered'] = submittedDateTime;
  responseData['Entered From'] = data.CreatedSource;
  responseData['Entered By'] = data.SubmittedBy;
  responseData['Status'] = data.Status;
  return responseData;
}
