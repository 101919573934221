import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { theme, ButtonAnt, Text } from '@pfmam/pfmam';
import ListWrapperComponent from '../../../common/components/listWrapper';
import { Box } from '../../../styles/globalStyle';
const ListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.grey};
  transition: 0.3s;
  &:last-of-type {
    border-bottom: 0;
  }
  .strong {
    font-family: 'Inter Semibold';
  }
`;
const EditBtn = styled(ButtonAnt)`
  border: 1px solid ${theme.colors.grey};
  border-radius: 2px;
  height: 52px;
  padding: 12px 24px;
  color: ${theme.colors.black_secondary};
  font-size: ${theme.font_size.size_xl};
  display: block;
  width: 100%;
  margin-top: 48px;
  &:hover {
    color: ${theme.colors.black_secondary};
    border-color: ${theme.colors.grey};
  }
`;

const DetailsModalContent = ({
  selectedRecord,
  handleModalCancel,
  type,
  addEditAccountObject,
  setAddEditAccountObject,
  isGlobalSettingsPage,
}) => {
  const permissions =
    type === 'Participant'
      ? selectedRecord.peerPermissions
      : selectedRecord.permissions;

  return (
    <>
      {type === 'Participant' && (
        <ListWrapperComponent
          label="Participant Name"
          value={selectedRecord.participantName}
          className="auto-width-text"
        />
      )}
      {type !== 'Participant' ? (
        <>
          <ListWrapperComponent
            label="Bank Name"
            value={selectedRecord.bankName}
            className="auto-width-text"
          />
          {type === 'Wire' ? (
            <ListWrapperComponent
              label="Bank City and State"
              value={selectedRecord.bankCity + ', ' + selectedRecord.bankState}
            />
          ) : null}
          <ListWrapperComponent
            label="Nickname"
            value={
              selectedRecord.nickName ? selectedRecord.nickName : '- - - -'
            }
            className="auto-width-text"
          />
          <ListWrapperComponent
            label={
              type === 'ACH'
                ? 'ACH, ABA, or Routing #'
                : 'ABA or Routing Number'
            }
            value={selectedRecord.routing ? selectedRecord.routing : '- - - -'}
            className="auto-width-text"
          />
        </>
      ) : null}
      <ListWrapperComponent
        label={
          type === 'Wire' ? 'Bank Account Number' : 'Bank Account Number'
        }
        value={selectedRecord.accnumber ? selectedRecord.accnumber : '- - - -'}
        className="auto-width-text"
      />
      {type === 'ACH' ? (
        <>
          <ListWrapperComponent
            label="Name of Account"
            value={
              selectedRecord.nameOnAccount
                ? selectedRecord.nameOnAccount
                : '- - - -'
            }
            className="auto-width-text"
          />
          <ListWrapperComponent
            label="Account Type"
            value={selectedRecord.acctype ? selectedRecord.acctype : '- - - -'}
            className="auto-width-text"
          />
          <ListWrapperComponent
            label="Addendum Purchase Information"
            value={
              selectedRecord.addendumPurchaseDescription
                ? selectedRecord.addendumPurchaseDescription
                : '- - - -'
            }
            className="auto-width-text"
          />
          <ListWrapperComponent
            label="Addendum Redemption Information"
            value={
              selectedRecord.addendumRedemptionDescription
                ? selectedRecord.addendumRedemptionDescription
                : '- - - -'
            }
            className="auto-width-text"
          />
        </>
      ) : null}
      {type === 'Wire' ? (
        <>
          <ListWrapperComponent
            label="Beneficiary Name"
            value={selectedRecord.beneficiaryName}
          />
          <ListWrapperComponent
            label="Beneficiary Account Number"
            value={
              selectedRecord.beneficiaryAccNumber
                ? selectedRecord.beneficiaryAccNumber
                : '- - - -'
            }
            minwidth="239px"
            maxwidth="239px"
          />
          <ListWrapperComponent
            label="Beneficiary Details"
            value={
              selectedRecord.beneficiaryDetails
                ? selectedRecord.beneficiaryDetails
                : '- - - -'
            }
          />
        </>
      ) : null}
      {isGlobalSettingsPage ? (
        <ListWrapper>
          <Box minwidth="215px" maxwidth="215px" textalign="left">
            <Text
              label={'Account Permissions'}
              padding="14px 0 10px 0"
              color={`${theme.colors.grey_tertiary}`}
              fontSize={`${theme.font_size.size_xl}`}
              lineheight="24px"
            />
          </Box>
          <Box minwidth="215px" maxwidth="215px" textalign="right">
            {permissions && permissions.length ? (
              permissions.map((permission) => {
                return (
                  <Text
                    label={permission}
                    color={`${theme.colors.black_secondary}`}
                    fontSize={`${theme.font_size.size_xl}`}
                  />
                );
              })
            ) : (
              <Text
                label="- - - -"
                padding="14px 0 14px 0"
                color={`${theme.colors.black_secondary}`}
                fontSize={`${theme.font_size.size_xl}`}
              />
            )}
          </Box>
        </ListWrapper>
      ) : null}
      {/* <EditBtn
        label="Edit Account"
        type="secondary"
        onClick={() => {
          setAddEditAccountObject({
            isAddEditAcc: !addEditAccountObject.isAddEditAcc,
            selectedRecord: selectedRecord,
            type: type,
          });
          handleModalCancel('details');
        }}
      /> */}
    </>
  );
};

export default DetailsModalContent;
