import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Box } from '../../../styles/globalStyle';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Form, Space, Checkbox } from 'antd';
import {
  theme,
  ButtonAnt,
  InputComponent,
  Text,
} from '@pfmam/pfmam';
import ReviewPeerAccounts from './reviewPeerAccounts';
const ButtonWrapper = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 16px;
    `}
`;

const AddEditPeerAccounts = ({
  defaultAddEditAccObj,
  addEditAccountObject,
  setAddEditAccountObject,
}) => {
  const [isAddAccount, setIsAddAccount] = useState(true);
  const [peerAccountData, setPeerAccountData] = useState(null);
  const [isReviewPeerAccount, setIsReviewPeerAccount] = useState(false);
  const [isPeerAccountCompleted, setIsPeerAccountCompleted] = useState(false);

  const [form] = Form.useForm();

  const [investmentTypes] = useState([
    'PLGIT Prime',
    'PLGIT Class',
    'PLGIT Reserve',
  ]);

  const [checkedList, setCheckedList] = React.useState([]);
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(false);

  useEffect(() => {
    if (addEditAccountObject.selectedRecord) {
      const selectedRecord = addEditAccountObject.selectedRecord;
      setIsAddAccount(false);
      setCheckedList(
        selectedRecord.productType && selectedRecord.productType.length > 0
          ? selectedRecord.productType
          : []
      );
      form.setFieldsValue({ ...selectedRecord });
    }
  }, [addEditAccountObject, form]);

  useEffect(() => {
    form.setFieldsValue({ productType: checkedList });
    setCheckAll(
      checkedList.length > 0 && investmentTypes.length === checkedList.length
    );
    setIndeterminate(
      checkedList.length === 0 || investmentTypes.length === checkedList.length
        ? false
        : true
    );
  }, [checkedList, investmentTypes, form]);

  const onFinish = (values) => {
    setPeerAccountData(values);
    setIsReviewPeerAccount(true);
  };
  const handleBack = () => {
    setAddEditAccountObject({ ...defaultAddEditAccObj });
  };

  const getTitle = () => {
    let label = '';
    if (isPeerAccountCompleted) {
      label = 'Participant Account Submitted';
    } else if (isReviewPeerAccount) {
      label = 'Review Participant Account ';
    } else {
      label = `${isAddAccount ? 'Add' : 'Edit'} Participant Account`;
    }
    return (
      <>
        <Text
          fontSize="36px"
          color={`${theme.colors.black_secondary}`}
          lineheight="44px"
          label={label}
          icon={
            isPeerAccountCompleted ? (
              <CheckCircleOutlined
                style={{ color: `${theme.colors.pie_green}`, marginRight: '0' }}
              />
            ) : null
          }
          margin={isPeerAccountCompleted ? '0 0 16px' : '0 0 32px'}
        />
        {isPeerAccountCompleted && (
          <Text
            label="You will be notified when this linked account is approved for transactions"
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_tertiary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
        )}
      </>
    );
  };

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < investmentTypes.length);
    setCheckAll(list.length === investmentTypes.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? investmentTypes : []);

    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  return (
    <Box maxwidth="590px" width="100%" margin="0 auto" padding="80px 0 0">
      {getTitle()}
      <Box
        boxcolor={`${theme.colors.white}`}
        padding="32px 32px 48px"
        radius="4px"
      >
        {isReviewPeerAccount ? (
          <ReviewPeerAccounts
            form={form}
            peerAccountData={peerAccountData}
            isPeerAccountCompleted={isPeerAccountCompleted}
            setIsPeerAccountCompleted={setIsPeerAccountCompleted}
            setIsReviewPeerAccount={setIsReviewPeerAccount}
            handleBack={handleBack}
          />
        ) : (
          <Form
            form={form}
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
            className="forms block-level-checkbox marginable-checkbox error-msg-as-left"
          >
            <Form.Item
              label="Participant Name"
              name="participantName"
              rules={[
                {
                  required: true,
                  message: 'Participant Name Required',
                },
              ]}
            >
              <InputComponent
                type="default"
                placeholder="Add Participant Name"
              />
            </Form.Item>

            <Form.Item
              label="Account Number"
              name="accnumber"
              rules={[
                {
                  required: true,
                  message: 'Account Number Required',
                },
              ]}
            >
              <InputComponent type="default" placeholder="Add Account Number" />
            </Form.Item>

            <Form.Item
              label=""
              name="productType"
              rules={[
                {
                  required: true,
                  message: 'Investment Type Required',
                },
              ]}
            >
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                className="checkbox-boss"
              >
                All Investment Types
              </Checkbox>
              <Checkbox.Group
                style={{ width: '100%' }}
                value={checkedList}
                onChange={onChange}
              >
                {investmentTypes.length > 0 &&
                  investmentTypes.map((type) => {
                    return <Checkbox value={type}>{type}</Checkbox>;
                  })}
              </Checkbox.Group>
            </Form.Item>

            <ButtonWrapper size={12} spacing>
              <ButtonAnt
                label="Cancel"
                type="secondary"
                htmlType="button"
                onClick={() => handleBack()}
              />
              <ButtonAnt label="Review" type="primary" htmlType="submit" />
            </ButtonWrapper>
          </Form>
        )}
      </Box>
    </Box>
  );
};

export default AddEditPeerAccounts;
