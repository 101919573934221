import React from 'react';
import styled, { css } from 'styled-components';
import { theme, Text, ButtonAnt } from '@pfmam/pfmam';
import { Space } from 'antd';
import { Box } from '../../../styles/globalStyle';
import ListWrapperComponent from '../../../common/components/listWrapper';

const ListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.grey};
  transition: 0.3s;
  &:last-of-type {
    border-bottom: 0;
  }
  .strong {
    font-family: 'Inter Semibold';
  }
`;
const ButtonWrapper = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
`;

const ReviewACHAccounts = ({
  form,
  achAccountData,
  isACHAccountCompleted,
  setIsACHAccountCompleted,
  setIsReviewACHAccount,
  handleBack,
  isReviewACHAccount,
}) => {
  return (
    <>
      <Box>
        <ListWrapperComponent
          label="Bank Name"
          value={achAccountData.bankName}
        />
        <ListWrapperComponent
          label="Nickname"
          value={achAccountData.nickName}
        />
        <ListWrapperComponent
          label="ACH, ABA, or Routing #"
          value={achAccountData.routing}
        />
        <ListWrapperComponent
          label="Account Number"
          value={achAccountData.accnumber}
        />
        <ListWrapperComponent
          label="Name on Account"
          value={achAccountData.nameOnAccount}
        />
        <ListWrapperComponent
          label="Account Type"
          value={achAccountData.acctype}
        />
        <ListWrapperComponent
          label="Addendum"
          value={achAccountData.addendum ? achAccountData.addendum : '- - - -'}
        />
        <ListWrapper>
          <Box minwidth="215px" maxwidth="215px" textalign="left">
            <Text
              label="Account Permissions"
              padding="14px 0 10px 0"
              color={`${theme.colors.grey_tertiary}`}
              fontSize={`${theme.font_size.size_xl}`}
              lineheight="24px"
            />
          </Box>
          <Box flex="1" textalign="right" padding="14px 0 0 16px">
            {achAccountData.permissions && achAccountData.permissions.length ? (
              achAccountData.permissions.map((permission) => {
                return (
                  <Text
                    label={permission}
                    color={`${theme.colors.black_secondary}`}
                    fontSize={`${theme.font_size.size_xl}`}
                    margin="0 0 4px 0"
                  />
                );
              })
            ) : (
              <Text
                label="- - - -"
                padding="14px 0 14px 0"
                color={`${theme.colors.black_secondary}`}
                fontSize={`${theme.font_size.size_xl}`}
              />
            )}
          </Box>
        </ListWrapper>
      </Box>
      <ButtonWrapper size={12} spacing>
        <ButtonAnt
          label={
            isACHAccountCompleted ? 'Back to Organizational Settings' : 'Back'
          }
          type="secondary"
          htmlType="button"
          onClick={() => {
            if (isACHAccountCompleted) {
              form.resetFields();
              handleBack();
            } else {
              setIsReviewACHAccount(false);
            }
          }}
        />
        {!isACHAccountCompleted && (
          <ButtonAnt
            label={'Submit'}
            type="primary"
            htmlType="submit"
            onClick={() => setIsACHAccountCompleted(true)}
          />
        )}
      </ButtonWrapper>
    </>
  );
};

export default ReviewACHAccounts;
