import React, { useRef } from 'react';
import IdleTimer, { useIdleTimer } from 'react-idle-timer';
import { signoutRedirect } from '../../services/userService';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
function IdleTimerContainer() {
  const IdleTimerRef = useRef(null);
  const user_logout = useSelector((state) => state.userSlice.user.id_token);
  const dispatch = useDispatch();
  const onIdle = () => {
    signoutRedirect(user_logout, dispatch);
  };
  const onActive = () => {
    localStorage.setItem(
      'CSG',
      moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
    );
  };
  return (
    <div>
      <IdleTimer
        ref={IdleTimerRef}
        timeout={1000 * 60 * 19}
        onIdle={onIdle}
        onAction={onActive}
        crossTab={{
          emitOnAllTabs: true,
        }}
      ></IdleTimer>
    </div>
  );
}

export default IdleTimerContainer;
