import React from 'react';
import { Box } from '../../styles/globalStyle';
import { theme, Text } from '@pfmam/pfmam';
import styled from 'styled-components';
import { IconBg } from '../moveFunds';
import { useSelector } from 'react-redux';
const Title = styled(Text)`
  font-family: 'Inter Semibold';
`;

const TransferTypeBox = ({ icon, title, subTitle, disable }) => {
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  return (
    <Box
      border={`1px solid ${theme.colors.grey}`}
      borderwidth="0 0 1px 0"
      display="flex"
      padding="20px 32px 24px"
      minwidth="100%"
      maxwidth="100%"
      flexdirection="column"
      boxcolor={disable ? theme.colors.grey_fossil : theme.colors.white}
      className="transfer-type-box"
    >
      <Box
        minwidth="100%"
        maxwidth="100%"
        display="flex"
        alignitems="flex-start"
      >
        <IconBg bgColor={`${primary_color}`}>
          <img src={icon} alt="Single Bank Account" />
        </IconBg>
        <Box flex="1">
          <Title
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.black_tertiary}`}
            label={title}
            lineheight="24px"
            margin="0 0 4px"
          />
          <Text
            fontSize={`${theme.font_size.size_l}`}
            color={`${theme.colors.grey_tertiary}`}
            label={subTitle}
            lineheight="20px"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TransferTypeBox;
