const dev = {
  BASE_API_URL: process.env.REACT_APP_BASE_API_URL,
  Public_API_URL: process.env.REACT_APP_Public_API_URL,
  Public_ClientId: process.env.REACT_APP_Public_ClientId,
  Public_ClientSecret: process.env.REACT_APP_Public_ClientSecret,
  AUTH_URL: process.env.REACT_APP_AUTH_URL,
  CONNECT_REDIRECT_URI: process.env.REACT_APP_CONNECT_REDIRECT_URI,
  CONNECT_POSTLOGOUT_REDIRECT_URI: process.env.REACT_APP_CONNECT_POSTLOGOUT_REDIRECT_URI,
  ChangePasswordRedirectUrl: process.env.REACT_APP_ChangePasswordRedirectUrl,
  SecureAuth: process.env.REACT_APP_SecureAuth,
  ReCaptchaSiteKey: process.env.REACT_APP_ReCaptchaSiteKey,
  RecaptchaTag: process.env.REACT_APP_RecaptchaTag,
  EnableTransaction: process.env.REACT_APP_EnableTransaction,
  EON_URL: process.env.REACT_APP_EON_URL,
  CSI_DOMAIN: process.env.REACT_APP_CSI_DOMAIN,
  FooterLabel: process.env.REACT_APP_FooterLabel
};

const prod = {
  BASE_API_URL: 'PROD',
};

const config = {
  ...(process.env.REACT_APP_STAGE === 'prod' ? prod : dev),
};

export default config;
