/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Form, Space, Checkbox } from 'antd';
import { theme, ButtonAnt, Text } from '@pfmam/pfmam';
import accountData from '../../data/AccountDetails.json';

const ButtonWrapper = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 16px;
    `}
`;

const RenderSelectAccountsComponent = ({
  setSelectAccounts,
  setIsReviewAccount,
  form,
  onFinish,
}) => {
  const defaultPermissions = form.getFieldValue('permissions')
    ? form.getFieldValue('permissions')
    : [];
  const [checkedList, setCheckedList] = React.useState(defaultPermissions);
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(false);
  const [accDetails, setAccDetails] = useState([]);
  useEffect(() => {
    const filteredData = [];
    accountData[0].accountDetails.length > 0 &&
      accountData[0].accountDetails.map((data) => {
        if (data.status === 'active') {
          filteredData.push(data.accName);
        }
      });
    setAccDetails([...filteredData]);
  }, []);

  useEffect(() => {
    form.setFieldsValue({ permissions: checkedList });
    setCheckAll(
      checkedList.length > 0 && accDetails.length === checkedList.length
    );
    setIndeterminate(
      checkedList.length === 0 || accDetails.length === checkedList.length
        ? false
        : true
    );
  }, [checkedList, accDetails, form]);
  const onSubmit = (values) => {
    onFinish(values);
    setIsReviewAccount(true);
  };
  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < accDetails.length);
    setCheckAll(list.length === accDetails.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? accDetails : []);

    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  return (
    <>
      <Text
        fontSize={`${theme.font_size.size_xl}`}
        color={`${theme.colors.grey_tertiary}`}
        lineheight="24px"
        label="Select accounts that will have access to this linked ACH account"
        margin="0 0 48px 0"
      />
      <Form
        form={form}
        name="basic"
        layout="vertical"
        onFinish={onSubmit}
        requiredMark={false}
        className="forms block-level-checkbox marginable-checkbox error-msg-as-left"
      >
        <Form.Item
          label=""
          name="permissions"
          rules={[
            {
              required: true,
              message: 'Permissions Required',
            },
          ]}
        >
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
            className="checkbox-boss"
          >
            Permission All Accounts
          </Checkbox>
          <Checkbox.Group value={checkedList} onChange={onChange}>
            {accDetails.length > 0 &&
              accDetails.map((account) => {
                return <Checkbox value={account}>{account}</Checkbox>;
              })}
          </Checkbox.Group>
        </Form.Item>
        <ButtonWrapper size={12} spacing>
          <ButtonAnt
            label="Back"
            type="secondary"
            htmlType="button"
            onClick={() => setSelectAccounts(false)}
          />
          <ButtonAnt label="Review" type="primary" htmlType="submit" />
        </ButtonWrapper>
      </Form>
    </>
  );
};

export default RenderSelectAccountsComponent;
