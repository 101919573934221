import React from 'react';
import AddEditACHAccounts from './achAccounts/addEditACHAccounts';
import AddEditPeerAccounts from './peerAccounts/addEditPeerAccounts';
import AddEditTeamMembers from './teamMembers/addEditTeamMember';
import AddEditWireAccounts from './wireAccounts/addEditWireAccounts';
const AddEditAccounts = ({
  defaultAddEditAccObj,
  addEditAccountObject,
  setAddEditAccountObject,
}) => {
  const renderAddEditForm = () => {
    if (addEditAccountObject.type === 'ACH') {
      return (
        <AddEditACHAccounts
          defaultAddEditAccObj={defaultAddEditAccObj}
          addEditAccountObject={addEditAccountObject}
          setAddEditAccountObject={setAddEditAccountObject}
        />
      );
    } else if (addEditAccountObject.type === 'Participant') {
      return (
        <AddEditPeerAccounts
          defaultAddEditAccObj={defaultAddEditAccObj}
          addEditAccountObject={addEditAccountObject}
          setAddEditAccountObject={setAddEditAccountObject}
        />
      );
    } else if (addEditAccountObject.type === 'Wire') {
      return (
        <AddEditWireAccounts
          defaultAddEditAccObj={defaultAddEditAccObj}
          addEditAccountObject={addEditAccountObject}
          setAddEditAccountObject={setAddEditAccountObject}
        />
      );
    } else {
      return (
        <AddEditTeamMembers
          defaultAddEditAccObj={defaultAddEditAccObj}
          addEditAccountObject={addEditAccountObject}
          setAddEditAccountObject={setAddEditAccountObject}
        />
      );
    }
  };
  return renderAddEditForm();
};

export default AddEditAccounts;
