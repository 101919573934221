import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Tooltip } from 'antd';
import { Box, Anchor } from '../../styles/globalStyle';
import {
  theme,
  Text,
  ButtonAnt,
  Modal,
} from '@pfmam/pfmam';
import WireForm from './wireNotificationsForm';
import ReviewForm from './reviewForm';
import AppLayout from '../../common/layouts/appLayout';
import SendWireModalContent from './sendWireModalContent';
import { InfoCircleOutlined } from '@ant-design/icons';
import MoveFundsModal from '../../dashboard/components/moveFundsModal';
import { useSelector } from 'react-redux';
import NotFoundContainer from '../../notFound/notFoundContainer';

const WireContainer = () => {
  const [selected, setSelected] = useState('single');
  const [showInvestmentTypeModal, setShowInvestmentTypeModal] = useState(false);
  const [form] = Form.useForm();
  const [formType, setFormType] = useState('wire');
  const [formData, setFormData] = useState({});
  const [amount, setAmount] = useState(0);
  const [fundDetails, setFundDetails] = useState({ 'FundUniqueID': 0, 'FundShortName': '' });
  const [showSendWireModal, setShowSendWireModal] = useState(false);

  const showWireNotify = useSelector((state) =>
    state.menuPermissions.menuPermissionOptions.includes(3)
  );

  const [isMoveFundsVisible, setIsMoveFundsVisible] = useState(false);
  const handleFundsModalCancel = () => {
    setIsMoveFundsVisible(!isMoveFundsVisible);
  };

  const SendWireButton = styled(ButtonAnt) `
    font-size: ${theme.font_size.size_xl};
    margin: 0 0 32px;
    color: ${(props) => props.color};
    padding: 0;
    height: auto;
    line-height: 24px;
    border: 0;
    pointer-events: ${fundDetails.FundUniqueID === 0 ? 'none' : 'fill'};
    &:hover {
      color: ${(props) => props.color};
    }
  `;

  const getTitle = () => {

    return (
      <>
      <Text
        fontSize="36px"
        color={`${theme.colors.black_secondary}`}
        lineheight="44px"
        label={'Wire Purchase Notification'}
        margin={'0 0 16px'}
      />
      <Anchor
        fontsize={`${theme.font_size.size_l}`}
        lineheight="20px"
        color={`${theme.colors.blue_primay}`}
        display="inline-block"
        margin="0 0 12px"
        onClick={() => {
          setIsMoveFundsVisible(!isMoveFundsVisible);
        }}
      >
        Change
          </Anchor>

      <Text
        label="Notify the Fund that you are wiring monies to purchase Fund shares. This transaction requires you to instruct your local institution to initiate the transfer. See below for wire transfer instructions to the Fund’s custodian bank."
        fontSize={`${theme.font_size.size_xl}`}
        color={`${theme.colors.grey_tertiary}`}
        lineheight="24px"
        margin="0 0 8px"
      />
      <SendWireButton
        type="link"
        label="Where should my bank send the wire?"
        icon={false}
        color={`${theme.colors.blue_primay}`}
        onClick={() => {
          setShowSendWireModal(!showSendWireModal);
        }}
      />
      {' '}
      <Tooltip
        placement="top"
        title="Please select Account/Investment Type to see appropriate wiring instructions"
        overlayClassName="tooltip"
      >
        <InfoCircleOutlined style={{ cursor: 'pointer' }} />
      </Tooltip>

      </>
    );
  };

  return (
    <>
    {showWireNotify ? (
      <AppLayout>
        <>
        <Box maxwidth="590px" width="100%" margin="0 auto" padding="80px 0 0">
          {formType !== 'review' && getTitle()}
          {formType === 'review' ? (
            <ReviewForm
              form={form}
              formData={formData}
              selected={selected}
              amount={amount}
              setSelected={setSelected}
              setFormType={setFormType}
              showSendWireModal={showSendWireModal}
              setShowSendWireModal={setShowSendWireModal}
              setFundDetails={setFundDetails}
            />
          ) : (
              <WireForm
                form={form}
                showInvestmentTypeModal={showInvestmentTypeModal}
                setShowInvestmentTypeModal={setShowInvestmentTypeModal}
                setFormType={setFormType}
                formType={formType}
                formData={formData}
                setFormData={setFormData}
                setAmount={setAmount}
                setFundDetails={setFundDetails}
              />
            )}
        </Box>
        {showSendWireModal && (
          <Modal
            title={fundDetails.FundShortName + ' Wire Instructions'}
            width={590}
            bodyStyle={{
              padding: '32px 32px 34px 32px',
            }}
            maskStyle={{
              backgroundColor: `${theme.rgba.modal}`,
              zIndex: '1051',
            }}
            content={<SendWireModalContent fundDetails={fundDetails} />}
            visible={showSendWireModal}
            onCancel={() => {
              setShowSendWireModal(!showSendWireModal);
            }}
            footer={null}
            centered={true}
          />
        )}
        </>
      </AppLayout>
    ) : (
        <NotFoundContainer />
      )
    }
    {isMoveFundsVisible && (
      <Modal
        title="Move Funds"
        width={590}
        bodyStyle={{
          padding: '0 0 64px 0',
        }}
        maskStyle={{
          backgroundColor: `${theme.rgba.modal}`,
          zIndex: '1051',
        }}
        content={<MoveFundsModal />}
        visible={isMoveFundsVisible}
        onCancel={() => handleFundsModalCancel()}
        footer={null}
        centered={true}
      />
    )}
    </>
  );
};

export default WireContainer;
