import React from 'react';
import { Box } from '../styles/globalStyle';
import { theme, Text } from '@pfmam/pfmam';
import { ClickOn } from '../styles/globalStyle';
import styled from 'styled-components';

const Title = styled(Text)`
  font-weight: bold;
`;

export const IconBg = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  height: 48px;
  min-width: 48px;
  width: 48px;
  margin-right: 24px;
`;

const MoveFunds = () => {
  return (
    <>
      <Box
        border={`1px solid ${theme.colors.grey}`}
        borderwidth="0 0 1px 0"
        display="flex"
        padding="20px 32px"
        minwidth="100%"
        maxwidth="100%"
        flexdirection="column"
        boxcolor={theme.colors.white}
      >
        <Box minwidth="100%" maxwidth="100%" textalign="left">
          <Text
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.black_tertiary}`}
            label={'Move Funds'}
            lineheight="24px"
          />
        </Box>
      </Box>
      <ClickOn
        type="button"
        onClick={() => {
          console.log('Single acc Selected');
        }}
      >
        <Box
          border={`1px solid ${theme.colors.grey}`}
          borderwidth="0 0 1px 0"
          display="flex"
          padding="20px 32px"
          minwidth="100%"
          maxwidth="100%"
          flexdirection="column"
          boxcolor={theme.colors.white}
        >
          <Box minwidth="100%" maxwidth="100%" textalign="left">
            <Title
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.black_tertiary}`}
              label={'Transfer Between Investment Types'}
              lineheight="24px"
            />
          </Box>
          <Box minwidth="100%" maxwidth="100%" textalign="left">
            <Text
              fontSize={`${theme.font_size.size_m}`}
              color={`${theme.colors.grey_tertiary}`}
              label={
                'Move funds to a different investment type within the same account'
              }
              lineheight="20px"
            />
          </Box>
        </Box>
      </ClickOn>

      <ClickOn
        type="button"
        onClick={() => {
          console.log('Multiple acc Selected');
        }}
      >
        <Box
          border={`1px solid ${theme.colors.grey}`}
          borderwidth="0 0 1px 0"
          display="flex"
          padding="20px 32px"
          minwidth="100%"
          maxwidth="100%"
          flexdirection="column"
          boxcolor={theme.colors.white}
        >
          <Box minwidth="100%" maxwidth="100%" textalign="left">
            <Title
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.black_tertiary}`}
              label={'Transfer Between Accounts'}
              lineheight="24px"
            />
          </Box>
          <Box minwidth="100%" maxwidth="100%" textalign="left">
            <Text
              fontSize={`${theme.font_size.size_m}`}
              color={`${theme.colors.grey_tertiary}`}
              label={
                'Move funds to a different account within the same investment type'
              }
              lineheight="20px"
            />
          </Box>
        </Box>
      </ClickOn>

      <ClickOn type="button" onClick={() => {}}>
        <Box
          border={`1px solid ${theme.colors.grey}`}
          borderwidth="0 0 1px 0"
          display="flex"
          padding="20px 32px"
          minwidth="100%"
          maxwidth="100%"
          flexdirection="column"
          boxcolor={theme.colors.white}
        >
          <Box minwidth="100%" maxwidth="100%" textalign="left">
            <Title
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.black_secondary}`}
              label={'Transfer to Program Participant'}
              lineheight="24px"
            />
          </Box>
          <Box minwidth="100%" maxwidth="100%" textalign="left">
            <Text
              fontSize={`${theme.font_size.size_m}`}
              color={`${theme.colors.grey_tertiary}`}
              label={'Move funds to one or more program participants'}
              lineheight="20px"
            />
          </Box>
        </Box>
      </ClickOn>
    </>
  );
};

export default MoveFunds;
