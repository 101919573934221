import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { theme, Text, ButtonAnt, Modal } from '@pfmam/pfmam';
import { Space } from 'antd';
import { Box } from '../../../styles/globalStyle';
import ListWrapperComponent from '../../../common/components/listWrapper';
import moment from 'moment';
import { amountFormat } from '../../../utils/numberFormat';
import { CheckCircleOutlined, PrinterOutlined } from '@ant-design/icons';
import { Prompt } from 'react-router';
import { useSelector } from 'react-redux';
import { SubmitACHPurchase, GetTransactionDefaultDay, IsDuplicateTransactionSingleAccount } from '../../../actions/actions';
import { useHistory } from 'react-router-dom';
import DuplicateTransactionModalContent from '../../common/duplicateTransactionModalContent';

const ButtonWrapper = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
`;

const SendWireButton = styled(ButtonAnt)`
  font-size: ${theme.font_size.size_xl};
  margin: 0 0 32px;
  color: ${(props) => props.color};
  padding: 0;
  height: auto;
  line-height: 24px;
  border: 0;
  &:hover {
    color: ${(props) => props.color};
  }
  ${(props) =>
    props.spacing &&
    css`
      padding: 0 0 0 54px;
    `}
  ${(props) =>
    props.spacingnone &&
    css`
      margin: 0;
    `}
`;

const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
  }
`;

const ReviewForm = ({
  form,
  formData,
  amount,
  setSelectedPage,
  selected,
  setSelected,
  selectedPage,
  setFormType,
}) => {
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const ssnorTIN = clientDetails.payload[0].ssnortin;
  const [singleEntryConfirmationNumber, setSingleEntryConfirmationNumber] = useState();
  const [multiEntryConfirmationNumbers, setMultiEntryConfirmationNumbers] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [cutoffTimeDetails, setCutOffTimeDetails] = useState({
    isCutOffTimePassed: false,
    curOffTime: ''
  });
  const [isDuplicateTransModalVisible, setIsDuplicateTransModalVisible] = useState(false);
  const [duplicateModalTransData, setDuplicateModalTransData] = useState([]);

  const duplicateTransColumns = [
    {
      title: 'Confirmation #',
      dataIndex: 'Confirm #',
      key: 'Confirm #',
      width: 190,
      align: 'left',
    },
    {
      title: 'Account Number',
      dataIndex: 'Account Number',
      key: 'Account Number',
      width: 210,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={text}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    },
    {
      title: 'Posting Date',
      dataIndex: 'Posting Date',
      key: 'Posting Date',
      width: 212,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={moment(text).format('MMM DD, YYYY')}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'Created Date',
      key: 'Created Date',
      width: 212,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={moment(text).format('MMM DD, YYYY h:mm A')}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
          <Text
            label={record['Created By']}
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_xl}`}
            margin="4px 0 0"
            lineheight="20px"
          />
        </>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      width: 148,
      align: 'right',
      render: (text) => <>{amountFormat(text)}</>,
    },
  ];

  const validateDuplicateTransaction = () => {
    if(!submitted){
    setDisableSubmit(true);
    if (selectedPage !== 'MultiPurchaseForm') {
      //Check for multidate entry
      if(formData.multiDate){
        let entryLength = formData.multiDate.length;
        let resultantLength = 0;
        let resultantData = [];
        formData.multiDate.map((obj) => {
          IsDuplicateTransactionSingleAccount(obj.date.format('YYYY-MM-DD'), formData.accountinvestmentType.AccountUniqueID, obj.amount, 1)
            .then((response) => {
              resultantLength = resultantLength + 1;
              if (response.data !== null && response.data.Table.length > 0) {
                resultantData = buildDuplicateModalData(response.data.Table, resultantData);
              }

              if (entryLength === resultantLength && resultantData.length > 0) {
                let sortedData = resultantData.sort((a, b) => b["Confirm #"] - a["Confirm #"]);
                setDuplicateModalTransData(sortedData);
                setIsDuplicateTransModalVisible(true);
              }
              else if (entryLength === resultantLength) {
                VerifyCutOffTime();
              }
            })
            .catch((error) => {
              console.log(error);
            });
      });

      } 
      else {
        let resultantData = [];
        IsDuplicateTransactionSingleAccount(formData.date.format('YYYY-MM-DD'), formData.accountinvestmentType.AccountUniqueID, formData.amount, 1)
          .then((response) => {
            if (response.data !== null && response.data.Table.length > 0) {
              resultantData = buildDuplicateModalData(response.data.Table, resultantData);
              let sortedData = resultantData.sort((a, b) => b["Confirm #"] - a["Confirm #"]);
              setDuplicateModalTransData(sortedData);
              setIsDuplicateTransModalVisible(true);
            }
            else {
              VerifyCutOffTime();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    else {
      let entryLength = formData.multiAccount.length;
      let resultantLength = 0;
      let resultantData = [];
      formData.multiAccount.map((obj) => {
        IsDuplicateTransactionSingleAccount(formData.date.format('YYYY-MM-DD'), formData.accountinvestmentType.AccountUniqueID, obj.amount, 1)
          .then((response) => {
            resultantLength = resultantLength + 1;
            if (response.data !== null && response.data.Table.length > 0) {
              resultantData = buildDuplicateModalData(response.data.Table, resultantData);
            }

            if (entryLength === resultantLength && resultantData.length > 0) {
              let sortedData = resultantData.sort((a, b) => b["Confirm #"] - a["Confirm #"]);
              setDuplicateModalTransData(sortedData);
              setIsDuplicateTransModalVisible(true);
            }
            else if (entryLength === resultantLength) {
              VerifyCutOffTime();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });

    }
  }
   else {
    history.push('/dashboard');
  }

  }

  const handleDuplicateModalSubmit = () => {
    setIsDuplicateTransModalVisible(false);
    VerifyCutOffTime();
  };

  const handleDuplicateModalCancel = () => {
    setIsDuplicateTransModalVisible(false);
    setDisableSubmit(false);
  };

  function buildDuplicateModalData(duplicateData, resultantData) {
    duplicateData.map(function (item) {
      if (!(resultantData.filter(e => e["Confirm #"] === item["Confirm #"]).length > 0)) {
        resultantData.push({
          "Confirm #": item["Confirm #"],
          "Account Number": item["Account Number"],
          "Posting Date": item["Posting Date"],
          "Created Date": item["Created Date"],
          "Created By": item["Created By"],
          "Amount": item["Amount"]
        });
      }
    });
    return resultantData;
  }

  const submitACHPurchaseTransaction = () => {
    if (!submitted) {
      if (selectedPage !== 'MultiPurchaseForm') {

        //Check for multi entry
        if (formData.multiDate) {
          let multiEntryConfirs = [];
          //Submit multi entry
          formData.multiDate.map((obj) => {
            SubmitACHPurchase(formData.bankAccount.ACHInstructionID, formData.accountinvestmentType.AccountUniqueID, obj.amount, formData.accountinvestmentType.type.FundUniqueID, ssnorTIN, obj.date.format('YYYY-MM-DD'))
              .then((response) => {
                if (response.data !== null && response.data.length > 0) {
                  multiEntryConfirs.push({ confirmationNumber: response.data, date: obj.date, amount: obj.amount });
                  if (formData.multiDate.length === multiEntryConfirs.length) {
                    setMultiEntryConfirmationNumbers(multiEntryConfirs.sort((a, b) => (a.confirmationNumber > b.confirmationNumber ? 1 : -1)));
                    setSubmitted(true);
                    setDisableSubmit(false);
                  }
                }
              })
              .catch((error) => {
                setDisableSubmit(false);
                console.log(error);
              });
          });
        }
        else {
          //Submit single entry
          SubmitACHPurchase(formData.bankAccount.ACHInstructionID, formData.accountinvestmentType.AccountUniqueID, formData.amount, formData.accountinvestmentType.type.FundUniqueID, ssnorTIN, formData.date.format('YYYY-MM-DD'))
            .then((response) => {
              if (response.data !== null && response.data.length > 0) {
                setSingleEntryConfirmationNumber(response.data);
                setSubmitted(true);
                setDisableSubmit(false);
              }
            })
            .catch((error) => {
              setDisableSubmit(false);
              console.log(error);
            });
        }
      } else {
        //Submit multi account
        let multiAccountConfirms = [];
        formData.multiAccount.map((obj) => {
          SubmitACHPurchase(obj.multiBankAccount.ACHInstructionID, formData.accountinvestmentType.AccountUniqueID, obj.amount, formData.accountinvestmentType.type.FundUniqueID, ssnorTIN, formData.date.format('YYYY-MM-DD'))
            .then((response) => {
              if (response.data !== null && response.data.length > 0) {
                multiAccountConfirms.push({ confirmationNumber: response.data, DisplayName: obj.multiBankAccount.DisplayName,NickName:obj.multiBankAccount.NickName, amount: obj.amount });
                if (formData.multiAccount.length === multiAccountConfirms.length) {
                  setMultiEntryConfirmationNumbers(multiAccountConfirms.sort((a, b) => (a.confirmationNumber > b.confirmationNumber ? 1 : -1)));
                  setSubmitted(true);
                  setDisableSubmit(false);
                }
              }
            })
            .catch((error) => {
              setDisableSubmit(false);
              console.log(error);
            });
        });
      }
    }


    if (submitted) {
      //window.history.back()
      history.push('/dashboard');
    }
  }

  function onDuplicateModalCancel() {
    setIsDuplicateTransModalVisible(false);
    setDisableSubmit(false);
    setDuplicateModalTransData([]);
  }

  function VerifyCutOffTime() {
    setDisableSubmit(true);
    setCutOffTimeDetails({ isCutOffTimePassed: false, curOffTime: '' });
    
    GetTransactionDefaultDay(formData.accountinvestmentType.type.FundUniqueID, 1)
      .then((response) => {
        if (response.data !== null) {
          let invalidTransactions = [];
          let defaultDay = new Date(response.data);
          defaultDay.setHours(0, 0, 0, 0);

          if (selectedPage !== 'MultiPurchaseForm') {
            if (formData.multiDate) {
              let multiEntryConfirmsLength = 0;
              formData.multiDate.map((obj) => {
                multiEntryConfirmsLength = multiEntryConfirmsLength + 1;
                let transEntryDateString = obj.date.format('MMM DD, YYYY');
                let transEntryDate = new Date(transEntryDateString);
                transEntryDate.setHours(0, 0, 0, 0);
                if (transEntryDate < defaultDay) {
                  invalidTransactions.push(false);
                  setCutOffTimeDetails({ isCutOffTimePassed: true, curOffTime: response.data });
                  setDisableSubmit(false);
                }
                if (multiEntryConfirmsLength === formData.multiDate.length && invalidTransactions.length === 0) {
                  submitACHPurchaseTransaction();
                }
              });
            } else {
              let transEntryDateString = formData.date.format('MMM DD, YYYY');
              let transEntryDate = new Date(transEntryDateString);
              transEntryDate.setHours(0, 0, 0, 0);
              if (transEntryDate < defaultDay) {
                setCutOffTimeDetails({ isCutOffTimePassed: true, curOffTime: response.data });
                setDisableSubmit(false);
              } else {
                submitACHPurchaseTransaction();
              }
            }
          } else {
            let transEntryDateString = formData.date.format('MMM DD, YYYY');
            let transEntryDate = new Date(transEntryDateString);
            transEntryDate.setHours(0, 0, 0, 0);
            if (transEntryDate < defaultDay) {
              setCutOffTimeDetails({ isCutOffTimePassed: true, curOffTime: response.data });
              setDisableSubmit(false);
            } else {
              submitACHPurchaseTransaction();
            }
          }
        }
      })
      .catch((error) => {
        setDisableSubmit(false);
        console.log(error);
      });
  }

  return (
    <>
      <Prompt
        when={submitted === false}
        message={JSON.stringify({
          header: 'Discard Transaction?',
          content:
            'Leaving this page will discard this transaction. Are you sure you want to discard this transaction?',
        })}
      />
      <Box display="flex" alignitems="flex-start">
        {submitted && (
          <CheckCircleOutlined
            style={{
              color: `${theme.colors.pie_green}`,
              fontSize: '38px',
              marginTop: '6px',
            }}
          />
        )}
        <Text
          fontSize="36px"
          color={`${theme.colors.black_secondary}`}
          lineheight="44px"
          label={
            submitted && selected === 'single'
              ? 'Transfer ACH Submitted'
              : submitted &&
                (selected === 'multiple' ||
                  selectedPage === 'MultiPurchaseForm')
                ? 'ACH Purchase Submitted'
                : 'Review ACH Purchase'
          }
          icon={false}
          margin={submitted ? '0 0 32px 15px' : '0 0 32px'}
        />
      </Box>

      <Box
        boxcolor={`${theme.colors.white}`}
        padding="32px 32px 48px"
        radius="4px"
      >
        <Box
          display="flex"
          justifycontent="space-between"
          alignitems="flex-start"
          width="100%"
        >
          <Box flex="1" margin="0 0 8px">
            <Text
              label="ACH Purchase Amount"
              color={`${theme.colors.black_tertiary}`}
              fontSize={`${theme.font_size.size_xl}`}
              lineheight="24px"
            />
          </Box>
          {submitted && (
            <SendWireButton
              type="link"
              label="Print Confirmation"
              icon={<PrinterOutlined />}
              color={`${theme.colors.blue_primay}`}
              onClick={() => window.print()}
              spacingnone
            />
          )}
        </Box>
        <Text
          label={amountFormat(amount)}
          color={`${theme.colors.clean_black}`}
          fontSize="36px"
          lineheight="44px"
          margin="0 0 32px"
        />
        <Box>
          {selected === 'single' && submitted && (
            <ListWrapperComponent label="Confirmation #" value={singleEntryConfirmationNumber} />
          )}
          <ListWrapperComponent label="Transaction Type" value="ACH Purchase" />
          <ListWrapperComponent
            label="Account/Investment Type"
            value={
              <>
                <Text
                  label={formData.accountinvestmentType.AccName}
                  color={`${theme.colors.black_secondary}`}
                  fontSize={`${theme.font_size.size_xl}`}
                  lineheight="24px"
                />
                <Text
                  label={formData.accountinvestmentType.type.FundShortName}
                  color={`${theme.colors.grey_tertiary}`}
                  fontSize={`${theme.font_size.size_xl}`}
                  margin="4px 0 0"
                  lineheight="20px"
                />
              </>
            }
          />
          {selected === 'MultiPurchaseForm' ? (
            <ListWrapperComponent
              label="Date"
              value={moment(formData.date).format('MMM DD, YYYY')}
            />
          ) : (
            <>
              <ListWrapperComponent
                label="Bank Account"
                value={formData.bankAccount.DisplayName}
              />
              <ListWrapperComponent
                label="Nick Name"
                value={formData.bankAccount.NickName}
              />
              </>
            )}
          {selected === 'single' && (
            <>
              <ListWrapperComponent
                label="Date Scheduled"
                value={moment(formData.date).format('MMM DD, YYYY')}
              />
            </>
          )}
        </Box>
        {selected === 'single' ? null : selected === 'multiple' ? (
          <Box padding="50px 0 20px 0">
            {!submitted && (
              formData.multiDate.map((obj) => {
                return (
                  <Box
                    boxcolor={`${theme.colors.grey_faint}`}
                    margin="16px 0 0"
                    padding="8px 16px"
                    radius="4px"
                  >
                    <ListWrapperComponent
                      label="Date"
                      value={moment(obj.date).format('MMM DD, YYYY')}
                    />
                    <ListWrapperComponent
                      label="Amount"
                      value={amountFormat(obj.amount, null)}
                    />
                  </Box>
                );
              }))}
            {submitted && (
              multiEntryConfirmationNumbers.map((obj) => {
                return (
                  <Box
                    boxcolor={`${theme.colors.grey_faint}`}
                    margin="16px 0 0"
                    padding="8px 16px"
                    radius="4px"
                  >
                    <ListWrapperComponent
                      label="Confirmation #"
                      value={obj.confirmationNumber}
                    />
                    <ListWrapperComponent
                      label="Date"
                      value={moment(obj.date).format('MMM DD, YYYY')}
                    />
                    <ListWrapperComponent
                      label="Amount"
                      value={amountFormat(obj.amount, null)}
                    />
                  </Box>
                );
              }))}
          </Box>
        ) : (
            <Box padding="16px 0 0 0">
              {submitted && (
                multiEntryConfirmationNumbers.map((obj) => {
                  return (
                    <Box
                      boxcolor={`${theme.colors.grey_faint}`}
                      margin="16px 0 0"
                      padding="8px 16px"
                      radius="4px"
                    >
                      <ListWrapperComponent
                        label="Confirmation #"
                        value={obj.confirmationNumber}
                      />
                      <ListWrapperComponent
                        label="Bank Account"
                        value={obj.DisplayName}
                      />
                      <ListWrapperComponent
                        label="Nick Name"
                        value={obj.NickName}
                      />
                      <ListWrapperComponent
                        label="Amount"
                        value={amountFormat(obj.amount, null)}
                      />
                    </Box>
                  );
                })
              )
              }
              {!submitted && (
                formData.multiAccount.map((obj) => {
                  return (
                    <Box
                      boxcolor={`${theme.colors.grey_faint}`}
                      margin="16px 0 0"
                      padding="8px 16px"
                      radius="4px"
                    >
                      {submitted && (
                        <ListWrapperComponent
                          label="Confirmation #"
                          value="984398439"
                        />
                      )}
                      <ListWrapperComponent
                        label="Bank Account"
                        value={obj.multiBankAccount.DisplayName}
                      />
                      <ListWrapperComponent
                        label="Nick Name"
                        value={obj.multiBankAccount.NickName}
                      />
                      <ListWrapperComponent
                        label="Amount"
                        value={amountFormat(obj.amount, null)}
                      />
                    </Box>
                  );
                })
              )
              }
            </Box>
          )}

        {cutoffTimeDetails.isCutOffTimePassed && (
          <Box display="flex" alignitems="flex-start">
            <Text
              fontSize="16px"
              color={`${theme.colors.danger_red}`}
              lineheight="18px"
              label={'The cutoff time for the date of this transaction has passed. Please enter a date on or after ' + moment(cutoffTimeDetails.curOffTime).format('MMM DD, YYYY')}
              icon={false}
              margin={'0 0 0 0'}
            />
          </Box>
        )

        }

{isDuplicateTransModalVisible && (
        <Modal
          title="Possible Duplicate Transaction"
          width={750}
          bodyStyle={{
            padding: '32px 0 40px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          footer={null}
          visible={isDuplicateTransModalVisible}
          onCancel={onDuplicateModalCancel}
          content={
            <DuplicateTransactionModalContent
              columns={duplicateTransColumns}
              data={duplicateModalTransData}
              handleDuplicateModalCancel={handleDuplicateModalCancel}
              handleDuplicateModalSubmit={handleDuplicateModalSubmit}
            />
          }
          centered={true}
        />
      )}

        <ButtonWrapper size={12} spacing>
          <ButtonAnt
            label={submitted ? 'Make Another Transaction' : 'Back'}
            type="secondary"
            htmlType="button"
            onClick={() => {
              if (submitted) {
                form.resetFields();
                sessionStorage.setItem('showMoveFundsModal', true);
                window.location.reload();
              } else {
                setFormType('purchase');
              }
            }}
          />
          <OverrideButtonAnt linkTextColor={primary_color} 
            label={submitted ? 'Done' : 'Submit'}
            type="primary"
            htmlType="submit"
            onClick={validateDuplicateTransaction}
            disabled={disableSubmit}
          />
        </ButtonWrapper>
      </Box>


    </>
  );
};

export default ReviewForm;
