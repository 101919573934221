import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import {
  theme,
  Text,
  ButtonAnt,
  Modal,
} from '@pfmam/pfmam';
import { Space } from 'antd';
import { Box } from '../../../styles/globalStyle';
import ListWrapperComponent from '../../../common/components/listWrapper';
import moment from 'moment';
import { amountFormat } from '../../../utils/numberFormat';
import { CheckCircleOutlined, PrinterOutlined } from '@ant-design/icons';

import { GetTransactionDefaultDay, SubmitExchangeTransaction, SubmitTransferTransaction, SubmitProgramTransferRequest, IsDuplicateTransferTransactionSingleAccount } from "../../../actions/actions";
import { Prompt } from 'react-router';
import { useHistory } from 'react-router-dom';
import DuplicateTransactionModalContent from '../../common/duplicateTransactionModalContent';
const ButtonWrapper = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
  &.done-button-wrapper {
    .primary {
      width: 120px;
    }
  }
`;

const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
  }
`;
const SendWireButton = styled(ButtonAnt)`
  font-size: ${theme.font_size.size_xl};
  margin: 0 0 32px;
  color: ${(props) => props.color};
  padding: 0;
  height: auto;
  line-height: 24px;
  border: 0;
  &:hover {
    color: ${(props) => props.color};
  }
  ${(props) =>
    props.spacing &&
    css`
      padding: 0 0 0 54px;
    `}
  ${(props) =>
    props.spacingnone &&
    css`
      margin: 0;
    `}
`;

const ReviewForm = ({
  amount,
  formData,
  selectedPage,
  setSelected,
  selected,
  form,
  setFormType,
  defaultDate,
  setDefaultDate,
  setLoading,
  participantData,
  setParticipantData
}) => {
  const history = useHistory();
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const [submitted, setSubmitted] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const [multiEntryConfirmationNumbers, setMultiEntryConfirmationNumbers] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [cutoffTimeDetails, setCutOffTimeDetails] = useState({
    isCutOffTimePassed: false,
    curOffTime: ''
  });
  const [isDuplicateTrans, setIsDuplicateTrans] = useState();
  const [isDuplicateTransModalVisible, setIsDuplicateTransModalVisible] =
    useState(false);
  const [duplicateTransData, setDuplicateTransData] = useState([]);
  const duplicateTransColumns = [
    {
      title: 'Confirmation #',
      dataIndex: 'Confirm #',
      key: 'Confirm #',
      width: 190,
      align: 'left',
    },
    {
      title: 'Account Number',
      dataIndex: 'Account Number',
      key: 'Account Number',
      width: 210,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={text}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    },
    {
      title: 'Posting Date',
      dataIndex: 'Posting Date',
      key: 'Posting Date',
      width: 212,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={moment(text).format('MMM DD, YYYY')}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'Created Date',
      key: 'Created Date',
      width: 212,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={moment(text).format('MMM DD, YYYY h:mm A')}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
          <Text
            label={record['Created By']}
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_xl}`}
            margin="4px 0 0"
            lineheight="20px"
          />
        </>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      width: 148,
      align: 'right',
      render: (text) => <>{amountFormat(text)}</>,
    },
  ];



  const getTransactionPendingType = () => {
    //5-transfer
    //7-exchnage
    //25-program to program
    if (selectedPage === 'InvestmentTypeTransfer') {
      return 7;
    } else if (selectedPage === 'SingleAccountTransferForm' || selectedPage === 'MultiAccountTransferForm') {
      return 5;
    } else if (selectedPage === 'ProgramTransfer') {
      return 25;
    }
  };

  const validateDuplicateTransaction = () => {
    if(!submitted){
      setDisableSubmit(true);
      if (!submitted && selected === 'single' && formData.hasOwnProperty('date') && selectedPage === 'SingleAccountTransferForm') {
        IsDuplicateTransferTransactionSingleAccount(false, formData.date.format('YYYY-MM-DD'), formData.accountinvestmentType.AccountUniqueID, formData.bankAccount.AccountUniqueID, amount, 6, 5)
          .then((response) => {
            if (response.data !== null && response.data.Table.length > 0) {
              setDuplicateTransData(response.data.Table);
              setIsDuplicateTransModalVisible(true);
              setIsDuplicateTrans(true);
            }
            else {
              VerifyCutOffTime();
            }
          })
          .catch((error) => {
            console.log(error);
            setDisableSubmit(false);
          });
      }
      else if (!submitted && selected === 'multiple' && formData.hasOwnProperty('multiDate') && selectedPage === 'SingleAccountTransferForm') {
        var dup = false;
        var dupList = [];
        var duplicateIncrement = 0;
        var dupListFinalData = [];
        formData.multiDate.map((obj, index) => {
          IsDuplicateTransferTransactionSingleAccount(false, obj.date.format('YYYY-MM-DD'), formData.accountinvestmentType.AccountUniqueID, formData.bankAccount.AccountUniqueID, obj.amount, 6, 5)
            .then((response) => {
              duplicateIncrement++;
              if (response.data !== null && response.data.Table.length > 0) {
                dupList = dupList.concat(response.data.Table);
                //setDuplicateTransData(dupList);
                dup = true;
              }
              if (duplicateIncrement === formData.multiDate.length) {
                if (dup) {
                  //remove the repeated duplicate data row
                  buildDuplicateModalData(dupList,dupListFinalData);
                  setDuplicateTransData(dupListFinalData);
                  setIsDuplicateTransModalVisible(dup);
                  setIsDuplicateTrans(dup);
                } else {
                  VerifyCutOffTime();
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        });
      }
      else if (!submitted && selected === 'multiple' && formData.hasOwnProperty('multiTransferAccount') && selectedPage === 'MultiAccountTransferForm') {
        var dup = false;
        var dupList = [];
        var duplicateIncrement = 0;
        var dupListFinalData = [];
        formData.multiTransferAccount.map((obj, index) => {
          IsDuplicateTransferTransactionSingleAccount(false, formData.date.format('YYYY-MM-DD'), formData.accountinvestmentType.AccountUniqueID, obj.multiAccount.AccountUniqueID, obj.amount, 6, 5)
            .then((response) => {
              duplicateIncrement++;
              if (response.data !== null && response.data.Table.length > 0) {
                dupList = dupList.concat(response.data.Table);
                //setDuplicateTransData(dupList);
                dup = true;
              }
              if (duplicateIncrement === formData.multiTransferAccount.length) {
                if (dup) {
                  //remove the repeated duplicate data row
                  buildDuplicateModalData(dupList,dupListFinalData);
                  setDuplicateTransData(dupListFinalData);
                  setIsDuplicateTransModalVisible(dup);
                  setIsDuplicateTrans(dup);
                } else {
                  VerifyCutOffTime();
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        });
      }
      else if (!submitted && selected === 'single' && formData.hasOwnProperty('date') && selectedPage === 'InvestmentTypeTransfer') {
        IsDuplicateTransferTransactionSingleAccount(true, formData.date.format('YYYY-MM-DD'), formData.accountinvestmentType.AccountUniqueID, formData.accountinvestmentType.AccountUniqueID, amount, 8, 0)
          .then((response) => {
            if (response.data !== null && response.data.Table.length > 0) {
              setDuplicateTransData(response.data.Table);
              setIsDuplicateTransModalVisible(true);
              setIsDuplicateTrans(true);
            }
            else {
              VerifyCutOffTime();
            }
          })
          .catch((error) => {
            console.log(error);
            setDisableSubmit(false);
          });
      }
      else if (!submitted && selected === 'multiple' && formData.hasOwnProperty('date') && selectedPage === 'ProgramTransfer') {
        let entryLength = formData.Participant.length;
        var dup = false;
        let resultantLength = 0;
        let resultantData = [];

        formData.Participant.map((obj, index) => {
          IsDuplicateTransferTransactionSingleAccount(false, formData.date.format('YYYY-MM-DD'), formData.accountinvestmentType.AccountUniqueID, obj.participant.RecipientAccountUniqueID, obj.amount, 26, 25)
            .then((response) => {
              resultantLength = resultantLength + 1;
              if (response.data !== null && response.data.Table.length > 0) {
                resultantData = resultantData.concat(response.data.Table);
                setDuplicateTransData(resultantData);
                dup = true;
              }
              if (entryLength === resultantLength && resultantData.length > 0) {
                setIsDuplicateTransModalVisible(dup);
                setIsDuplicateTrans(true);
              }
              else if (entryLength === resultantLength) {
                VerifyCutOffTime();
              }
            })
            .catch((error) => {
              console.log(error);
              setDisableSubmit(false);
            });
        })
      }
   }
    else {
      history.push('/dashboard');
    }
  }

  function buildDuplicateModalData(duplicateData, resultantData) {
    duplicateData.map(function (item) {
      if (!(resultantData.filter(e => e["Confirm #"] === item["Confirm #"]).length > 0)) {
        resultantData.push(item);
      }
    });
    return resultantData;
  }

  const handleDuplicateModalSubmit = () => {
    setIsDuplicateTransModalVisible(false);
    VerifyCutOffTime();
  };

  const handleDuplicateModalCancel = () => {
    setIsDuplicateTransModalVisible(false);
    setDisableSubmit(false)
  };

  function onDuplicateModalCancel() {
    setIsDuplicateTransModalVisible(false);
    setDisableSubmit(false);
    setDuplicateTransData([]);
  }



  const VerifyCutOffTime = () => {
    setCutOffTimeDetails({ isCutOffTimePassed: false, curOffTime: '' });
    var PendingTransactionTypeClassID = getTransactionPendingType();
    GetTransactionDefaultDay(formData.accountinvestmentType.type.FundUniqueID, PendingTransactionTypeClassID)
      .then((response) => {
        if (response.data !== null) {
          let invalidTransactions = [];
          let defaultDay = new Date(response.data);
          defaultDay.setHours(0, 0, 0, 0);
          if (formData.hasOwnProperty('multiDate')) {
            let multiEntryConfirmsLength = 0;
            formData.multiDate.map((obj) => {
              multiEntryConfirmsLength = multiEntryConfirmsLength + 1;
              let transEntryDateString = obj.date.format('MMM DD, YYYY');
              let transEntryDate = new Date(transEntryDateString);
              transEntryDate.setHours(0, 0, 0, 0);
              if (transEntryDate < defaultDay) {
                invalidTransactions.push(false);
                setCutOffTimeDetails({ isCutOffTimePassed: true, curOffTime: response.data });
                setDisableSubmit(false);
              }
              if (multiEntryConfirmsLength === formData.multiDate.length && invalidTransactions.length === 0) {
                submitTransfer();
              }
            });

          } else {
            let transEntryDateString = formData.date.format('MMM DD, YYYY');
            let transEntryDate = new Date(transEntryDateString);
            transEntryDate.setHours(0, 0, 0, 0);
            if (transEntryDate < defaultDay) {
              setCutOffTimeDetails({ isCutOffTimePassed: true, curOffTime: response.data });
              setDisableSubmit(false);
            } else {
              submitTransfer();
            }
          }
        }
      })
      .catch((error) => {
        setDisableSubmit(false);
        console.log(error);
      });
  }
  const submitTransfer = () => {
    if (!submitted && selected === 'single' && formData.hasOwnProperty('date') && selectedPage === 'SingleAccountTransferForm') {
      SubmitTransferTransaction(formData.date.format('YYYY-MM-DD'), amount, formData.accountinvestmentType.type.FundUniqueID, formData.accountinvestmentType.AccountUniqueID, formData.bankAccount.AccountUniqueID)
        .then((response) => {
          if (response.data !== null && response.data.length > 0) {
            setConfirmation(response.data);
            setDisableSubmit(false);
            submitted ? history.push('/dashboard') : setSubmitted(true);
          }
        })
        .catch((error) => {
          setDisableSubmit(false);
          console.log(error);
        });
    }
    else if (!submitted && selected === 'multiple' && formData.hasOwnProperty('multiDate') && selectedPage === 'SingleAccountTransferForm') {
      let multiEntryConfirs = [];
      formData.multiDate.map((obj, index) => {
        SubmitTransferTransaction(obj.date.format('YYYY-MM-DD'), obj.amount, formData.accountinvestmentType.type.FundUniqueID, formData.accountinvestmentType.AccountUniqueID, formData.bankAccount.AccountUniqueID)
          .then((response) => {
            if (response.data !== null && response.data.length > 0) {
              multiEntryConfirs.push({ confirmationNumber: response.data, date: obj.date, amount: obj.amount });
              if (formData.multiDate.length === multiEntryConfirs.length) {
                setMultiEntryConfirmationNumbers(multiEntryConfirs.sort((a, b) => (a.confirmationNumber > b.confirmationNumber ? 1 : -1)));
                setSubmitted(true);
                setDisableSubmit(false);
                submitted ? history.push('/dashboard') : setSubmitted(true);
              }
            }
          })
          .catch((error) => {
            setDisableSubmit(false);
            console.log(error);
          });

      })
    }
    // For Multiple Account
    else if (!submitted && selected === 'multiple' && formData.hasOwnProperty('multiTransferAccount') && selectedPage === 'MultiAccountTransferForm') { 
      let multiEntryConfirs = [];
      formData.multiTransferAccount.map((obj, index) => {
      SubmitTransferTransaction(formData.date.format('YYYY-MM-DD'), obj.amount, formData.accountinvestmentType.type.FundUniqueID, formData.accountinvestmentType.AccountUniqueID, obj.multiAccount.AccountUniqueID)
        .then((response) => {
          if(response.data !== null && response.data.length > 0) {     
            multiEntryConfirs.push({confirmationID: response.data, DisplayTransferTo: obj.multiAccount.AccName, amount: obj.amount});
            if (formData.multiTransferAccount.length === multiEntryConfirs.length) {
              setMultiEntryConfirmationNumbers(multiEntryConfirs.sort((a, b) => (a.confirmationID > b.confirmationID ? 1 : -1)));
              setSubmitted(true);
              setDisableSubmit(false);
              submitted ? history.push('/dashboard') : setSubmitted(true);
            }
          }
        })
        .catch((error) => {
          setDisableSubmit(false);
          console.log(error);
        });
      })     
    }
    else if (!submitted && selected === 'single' && formData.hasOwnProperty('date') && selectedPage === 'InvestmentTypeTransfer') {
      SubmitExchangeTransaction(formData.date.format('YYYY-MM-DD'), amount, formData.accountinvestmentType.type.FundUniqueID, formData.bankAccount.type.FundUniqueID, formData.accountinvestmentType.AccountUniqueID)
        .then((response) => {
          if (response.data !== null && response.data.length > 0) {
            setConfirmation(response.data);
            setDisableSubmit(false);
            //submitted ? window.history.back() : setSubmitted(true);
            submitted ? history.push('/dashboard') : setSubmitted(true);
          }
        })
        .catch((error) => {
          setDisableSubmit(false);
          console.log(error);
        });
    }
    else if (!submitted && selected === 'single' && formData.hasOwnProperty('date') && selectedPage === 'ProgramTransfer') {
      SubmitProgramTransferRequest(formData.date.format('YYYY-MM-DD'), amount, formData.accountinvestmentType.type.FundUniqueID, formData.participant.RecipientFundUniqueID, formData.accountinvestmentType.AccountUniqueID, formData.participant.RecipientAccountUniqueID
        , formData.participant.E2EInstructionID)
        .then((response) => {
          if (response.data !== null && response.data.length > 0) {
            setConfirmation(response.data);
            setDisableSubmit(false);
            //submitted ? window.history.back() : setSubmitted(true);
            submitted ? history.push('/dashboard') : setSubmitted(true);
          }
        })
        .catch((error) => {
          setDisableSubmit(false);
          console.log(error);
        });
    }
    // For Multiple Participant
    else if (!submitted && selected === 'multiple' && formData.hasOwnProperty('date') && selectedPage === 'ProgramTransfer') {
      let multiAccountConfirms = [];
      formData.Participant.map((obj, index) => {
        SubmitProgramTransferRequest(formData.date.format('YYYY-MM-DD'), obj.amount, formData.accountinvestmentType.type.FundUniqueID, obj.participant.RecipientFundUniqueID, formData.accountinvestmentType.AccountUniqueID, obj.participant.RecipientAccountUniqueID
          , obj.participant.E2EInstructionID)
          .then((response) => {
            multiAccountConfirms.push({ confirmationNumber: response.data, EntityName: obj.participant.EntityName, amount: obj.amount });
            if (formData.Participant.length === multiAccountConfirms.length) {
              setMultiEntryConfirmationNumbers(multiAccountConfirms);
              setSubmitted(true);
              setDisableSubmit(false);
            }
          })
          .catch((error) => {
            setDisableSubmit(false);
            console.log(error);
          });

      })
    }
    else {
      //window.history.back()
      history.push('/dashboard');
    }
  }
  return (
    <>
      <Prompt
        when={submitted === false}
        message={JSON.stringify({
          header: 'Discard Transaction?',
          content:
            'Leaving this page will discard this transaction. Are you sure you want to discard this transaction?',
        })}
      />
      <Box display="flex" alignitems="flex-start">
        {submitted && (
          <CheckCircleOutlined
            style={{
              color: `${theme.colors.pie_green}`,
              fontSize: '38px',
              marginTop: '6px',
            }}
          />
        )}
        <Text
          fontSize="36px"
          color={`${theme.colors.black_secondary}`}
          lineheight="44px"
          label={submitted ? 'Transfer Submitted' : 'Review Transfer'}
          icon={false}
          margin={submitted ? '0 0 32px 15px' : '0 0 32px'}
        />
      </Box>
      <Box
        boxcolor={`${theme.colors.white}`}
        padding="32px 32px 48px"
        radius="4px"
      >
        <Box
          display="flex"
          justifycontent="space-between"
          alignitems="flex-start"
          width="100%"
        >
          <Box flex="1" margin="0 0 8px">
            <Text
              label="Transfer Amount"
              color={`${theme.colors.black_tertiary}`}
              fontSize={`${theme.font_size.size_xl}`}
              lineheight="24px"
            />
          </Box>
          {submitted && (
            <SendWireButton
              type="link"
              label="Print Confirmation"
              icon={<PrinterOutlined />}
              color={`${theme.colors.blue_primay}`}
              onClick={() => window.print()}
              spacingnone
            />
          )}
        </Box>
        <Text
          label={amountFormat(amount)}
          color={`${theme.colors.clean_black}`}
          fontSize="36px"
          lineheight="44px"
          margin="0 0 32px"
        />
        <Box>
          {selected != 'multiple' && submitted && (
            <ListWrapperComponent label="Confirmation #" value={confirmation} />
          )}
          <ListWrapperComponent
            label="Transaction Type"
            value={
              selectedPage === 'InvestmentTypeTransfer'
                ? 'Transfer Between Products'
                : selectedPage === 'SingleAccountTransferForm'
                  || selectedPage === 'MultiAccountTransferForm'
                  ? 'Transfer Between Accounts'
                  : 'Transfer to Program Participant'
            }
          />
          <ListWrapperComponent
            label="Transfer From"
            value={
              <>
                <Text
                  label={formData.accountinvestmentType.AccName}
                  color={`${theme.colors.black_secondary}`}
                  fontSize={`${theme.font_size.size_xl}`}
                  lineheight="24px"
                />
                <Text
                  label={formData.accountinvestmentType.type.FundShortName}
                  color={`${theme.colors.grey_tertiary}`}
                  fontSize={`${theme.font_size.size_xl}`}
                  margin="4px 0 0"
                  lineheight="20px"
                />
              </>
            }
          />
          {selectedPage === 'ProgramTransfer' && selected === 'multiple' || selectedPage === 'MultiAccountTransferForm' ? (
            <Box>
              <ListWrapperComponent
                label="Date"
                value={moment(formData.date).format('MMM DD, YYYY')}
              />
            </Box>
          ) : (
            <ListWrapperComponent
              label="Transfer To"
              value={
                <>
                  <Text
                    label={
                      selectedPage === 'ProgramTransfer' &&
                        selected === 'single'
                        ? formData.participant.EntityName
                        : formData.bankAccount.AccName
                    }
                    color={`${theme.colors.black_secondary}`}
                    fontSize={`${theme.font_size.size_xl}`}
                    lineheight="24px"
                  />
                  <Text
                    label={
                      selectedPage === 'ProgramTransfer' &&
                        selected === 'single'
                        ? null
                        : formData.bankAccount.type.FundShortName
                    }
                    color={`${theme.colors.grey_tertiary}`}
                    fontSize={`${theme.font_size.size_xl}`}
                    margin="4px 0 0"
                    lineheight="20px"
                  />
                </>
              }
            />
          )}
          {selected === 'single' ? (
            <>
              <ListWrapperComponent
                label="Date Scheduled"
                value={moment(formData.date).format('MMM DD, YYYY')}
              />
            </>
          ) :selected === 'multiple' && !submitted ? selectedPage==='MultiAccountTransferForm'?(<Box padding="34px 0 0">
          {!submitted && (
            formData.multiTransferAccount.map((obj) => {
              return (
                <Box
                  boxcolor={`${theme.colors.grey_faint}`}
                  margin="16px 0 0"
                  padding="8px 16px"
                  radius="4px"
                >
                  {submitted && (
                    <ListWrapperComponent
                      label="Confirmation #"
                      value="984398439"
                    />
                  )}
                  {selectedPage === 'MultiAccountTransferForm' ? (
                    <>
                      <ListWrapperComponent
                        label="Bank Account"
                        value={obj.multiAccount.AccName}
                      />
                    </>
                  ) : <ListWrapperComponent
                    label="Bank Account"
                    value={obj.DisplayName}
                  />}
                  <ListWrapperComponent
                    label="Amount"
                    value={amountFormat(obj.amount, null)}
                  />
                </Box>
              );
            })
          )
          }
        </Box>): (
            <Box padding="34px 0 0">     
              {!submitted && selectedPage === 'SingleAccountTransferForm' && selected === 'multiple' && (
                formData.multiDate.map((obj, index) => {
                  return (
                    <Box
                      boxcolor={`${theme.colors.grey_faint}`}
                      padding="8px 16px"
                      radius="4px"
                      margin="16px 0 0"
                    >
                      {submitted && (
                        <ListWrapperComponent
                          label="Confirmation #"
                          value={obj.confirmationNumber}
                        />
                      )}
                      <ListWrapperComponent
                        label="Date"
                        value={moment(obj.date).format('MMM DD, YYYY')}
                      />
                      <ListWrapperComponent
                        label="Amount"
                        value={amountFormat(obj.amount, null)}
                      />
                    </Box>
                  );
                })
              )
              }
            </Box>
          ) : selected === 'multiple' && selectedPage === 'MultiAccountTransferForm' && submitted ? (
            <Box margin="40px 0 0">
              {multiEntryConfirmationNumbers.map((obj) => {
                return (
                  <Box
                    boxcolor={`${theme.colors.grey_faint}`}
                    margin="16px 0 0"
                    padding="8px 16px"
                  >
                    {submitted && (
                      <ListWrapperComponent
                        label="Confirmation #"
                        value={obj.confirmationID}
                      />
                    )}
                    <ListWrapperComponent
                      label="Transfer To"
                      value={obj.DisplayTransferTo}
                    />
                    <ListWrapperComponent
                      label="Amount"
                      value={amountFormat(obj.amount, null)}
                    />
                  </Box>
                );
              })}
            </Box>
          ) :selected === 'multiple' && submitted?(  <Box margin="40px 0 0">
          {multiEntryConfirmationNumbers.map((obj) => {
            return (
              <Box
                boxcolor={`${theme.colors.grey_faint}`}
                margin="16px 0 0"
                padding="8px 16px"
              >
                {submitted && (
                  <ListWrapperComponent
                    label="Confirmation #"
                    value={obj.confirmationNumber}
                  />
                )}
                {selectedPage === 'ProgramTransfer' ? (
                  <ListWrapperComponent
                  label="Transfer To"
                  value={obj.EntityName}
                />
                ) : (
                <ListWrapperComponent
                  label="Date"
                  value={moment(obj.date).format('MMM DD, YYYY')}
                />
                )
                }
                
                <ListWrapperComponent
                  label="Amount"
                  value={amountFormat(obj.amount, null)}
                />
              </Box>
            );
          })}
        </Box>): null}

        {!submitted && selected === 'multiple' && selectedPage === 'ProgramTransfer' && formData.Participant.map((obj) => {
            return (
              <Box
                boxcolor={`${theme.colors.grey_faint}`}
                margin="16px 0 0"
                padding="8px 16px"
              >
                <ListWrapperComponent
                  label="Transfer To"
                  value={obj.participant.EntityName}
                />
                <ListWrapperComponent
                  label="Amount"
                  value={amountFormat(obj.amount, null)}
                />
              </Box>
            );
          })

          }
        </Box>
        <ButtonWrapper
          size={12}
          spacing
          className={submitted ? 'done-button-wrapper' : ''}
        >

          {cutoffTimeDetails.isCutOffTimePassed && (
            <Box display="flex" alignitems="flex-start">
              <Text
                fontSize="16px"
                color={`${theme.colors.danger_red}`}
                lineheight="18px"
                label={'The cutoff time for the date of this transaction has passed. Please enter a date on or after ' + moment(cutoffTimeDetails.curOffTime).format('MMM DD, YYYY')}
                icon={false}
                margin={'0 0 0 0'}
              />
            </Box>
          )

          }

          {isDuplicateTransModalVisible && (
            <Modal
              title="Possible Duplicate Transaction"
              width={750}
              bodyStyle={{
                padding: '32px 0 40px',
              }}
              maskStyle={{
                backgroundColor: `${theme.rgba.modal}`,
                zIndex: '1051',
              }}
              footer={null}
              visible={isDuplicateTransModalVisible}
              onCancel={onDuplicateModalCancel}
              content={
                <DuplicateTransactionModalContent
                  columns={duplicateTransColumns}
                  data={duplicateTransData}
                  handleDuplicateModalCancel={handleDuplicateModalCancel}
                  handleDuplicateModalSubmit={handleDuplicateModalSubmit}
                />
              }
              centered={true}
            />
          )}
          <ButtonAnt
            label={submitted ? 'Make Another Transaction' : 'Back'}
            type="secondary"
            htmlType="button"
            onClick={() => {
              if (submitted) {
                form.resetFields();
                sessionStorage.setItem('showMoveFundsModal', true);
                // window.history.back();
                window.location.reload();
              } else {
                setLoading(true);
                setDefaultDate(defaultDate);
                setParticipantData(participantData);
                setFormType('transfer');
              }
            }}
          />
          <OverrideButtonAnt linkTextColor={primary_color}
            label={submitted ? 'Done' : 'Submit'}
            type="primary"
            htmlType="submit"
            // onClick={() => {          
            //   if (submitted) {
            //     window.history.back();
            //   } else {
            //     if (duplicateTransactions) {
            //       setIsDuplicateTransModalVisible(true);
            //     } else {

            //       setSubmitted(true);
            //     }
            //   }
            // }}
            onClick={validateDuplicateTransaction}
            disabled={disableSubmit}
            isDone={false}
          />
        </ButtonWrapper>
      </Box>
    </>
  );
};

export default ReviewForm;