import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AppLayout from '../../common/layouts/appLayout';
import TransferTypeCard from './transferTypeCard';
import TransferFlow from './transferFlow';
import { Box } from '../../styles/globalStyle';
import transferData from '../../data/transferData.json';
import { OverrideStyles } from '../purchaseFunds/purchaseContainer';
import NotFoundContainer from '../../notFound/notFoundContainer';
import AccountTypeTransferCard from './accountTypeTransferCard';

const Transfer = () => {
  const [selectedPage, setSelectedPage] = useState('TransferTypeCard');
  const menuPermissions = useSelector((state) =>
    state.menuPermissions.menuPermissionOptions
  );

  const showTransfer = useSelector((state) =>
  state.menuPermissions.menuPermissionOptions.includes(5) || state.menuPermissions.menuPermissionOptions.includes(7) || state.menuPermissions.menuPermissionOptions.includes(25)
  );
  return (
    <>
    <OverrideStyles />
    {showTransfer ? (
      <AppLayout>
        <Box maxwidth={selectedPage==='ProgramTransfer'||selectedPage==='MultiAccountTransferForm'?"790px":"590px"} width="100%" margin="0 auto" padding="80px 0 0">
          {selectedPage === 'TransferTypeCard' ? (
            <TransferTypeCard
              setSelectedPage={setSelectedPage}
              transferData={transferData}
              menuPermissions={menuPermissions}
            />
          ) : selectedPage === 'AccountsTransfer'? (<AccountTypeTransferCard setSelectedPage={setSelectedPage}/>):(
              <TransferFlow
                setSelectedPage={setSelectedPage}
                selectedPage={selectedPage}
                transferData={transferData}
              />
            )}
        </Box>
      </AppLayout>
    ) : (
        <NotFoundContainer />
      )
    }
    </>
  );
};

export default Transfer;
