import React, { useEffect } from 'react';
import AppLayout from '../common/layouts/appLayout';
import ImportantDisclosureContent from './components/importantDisclosureContent';
import PublicFooterLayout from './components/publicLayout';
import { useSelector, useDispatch } from 'react-redux';
import { clientsFilteredSearch } from '../services/programClientsSlice';
import { getsiteConfigurationInfo } from '../services/siteConfigurationSlice';

const ImportantDisclosure = ({ isAuthenticatedUser }) => {
  const dispatch = useDispatch();
  const selectedClients = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const clientPrograms = useSelector(
    (state) => state.programsClientsSlice.programClients
  );
  if (!selectedClients.payload && isAuthenticatedUser) {
    dispatch(clientsFilteredSearch(clientPrograms));
    const programId = clientPrograms[0].programid;
    dispatch(getsiteConfigurationInfo({ programId }));
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {isAuthenticatedUser ? (
        <AppLayout>
          <ImportantDisclosureContent
            isAuthenticatedUser={isAuthenticatedUser}
          />
        </AppLayout>
      ) : (
        <PublicFooterLayout title="Important Disclosures">
          <ImportantDisclosureContent
            isAuthenticatedUser={isAuthenticatedUser}
          />
        </PublicFooterLayout>
      )}
    </>
  );
};

export default ImportantDisclosure;
