import React, { useState } from 'react';
import { createGlobalStyle } from 'styled-components';

import AppLayout from '../../common/layouts/appLayout';
import TransferTypeCard from './transferTypeCard';
import PurchaseFlow from './purchaseFlow';
import { Box } from '../../styles/globalStyle';
import { useSelector } from 'react-redux';
import NotFoundContainer from '../../notFound/notFoundContainer';

export const OverrideStyles = createGlobalStyle`
  .forms { 
    .nav-pills .ant-tabs-tab {
      padding: 7px 16px;
    }
  }
  .last-transfer-type {
    & > div {
      border-bottom: none;
      padding-bottom: 36px
    }
  }
`;

const Purchase = () => {
  const [selectedPage, setSelectedPage] = useState('TransferTypeCard');
  const showACHPurchase = useSelector((state) =>
    state.menuPermissions.menuPermissionOptions.includes(1)
  );
  return (
    <>
      <OverrideStyles />
      {showACHPurchase ? (
        <AppLayout>
          <Box maxwidth="590px" width="100%" margin="0 auto" padding="80px 0 0">
            {selectedPage === 'TransferTypeCard' ? (
              <TransferTypeCard setSelectedPage={setSelectedPage} />
            ) : (
                <PurchaseFlow
                  setSelectedPage={setSelectedPage}
                  selectedPage={selectedPage}
                />
              )}
          </Box>
        </AppLayout>
      ) : (
          <NotFoundContainer />
        )
      }
    </>
  );
};

export default Purchase;
