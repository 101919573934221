import React, { useState, useEffect } from 'react';
import ListWrapperComponent from './listWrapper';
import moment from 'moment';
import { useSelector } from 'react-redux';

const AccountInformationCard = () => {
  const [accData, setAccData] = useState({
    AccName: '',
    AccNumber: '',
    AccountInceptionDate: '',
    AccountClosingDate: '',
  });
  const selectedAccount = useSelector(
    (state) => state.accountProfileSlice.selectedAccount
  );
  const accountProfile = useSelector(
    (state) => state.accountProfileSlice.accountProfile
  );

  useEffect(() => {
    if (selectedAccount.AccNumber != '') {
      const data = accountProfile.AccountDetails.filter(
        (acc) => acc.AccNumber === selectedAccount.AccNumber
      );
      if (data.length > 0) {
        setAccData(data[0]);
      }
    }
  }, []);

  return (
    <>
      <ListWrapperComponent label={'Account Name '} value={accData.AccName} />
      <ListWrapperComponent
        label={'Account Number'}
        value={accData.AccNumber}
      />
      <ListWrapperComponent
        label={'Account Opened'}
        value={moment(accData.AccountInceptionDate).format('MMM DD, YYYY')}
      />
       {(accData.AccountClosingDate != "") ? (
                        <ListWrapperComponent
                        label={'Account Closed'}
                        value={moment(accData.AccountClosingDate).format('MMM DD, YYYY')}
                      />
                    ) : null}    
    </>
  );
};

export default AccountInformationCard;
